import axios from "axios";

axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("token")}`;

export const getApi = async (api_url, inputs) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("token")}`;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}` + api_url,
      inputs,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const postApi = async (api_url, inputs, formData) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("token")}`;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}` + api_url,
      inputs,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const putApi = async (api_url, inputs) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("token")}`;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}` + api_url,
      inputs,
    );
    return response;
  } catch (error) {
    return error;
  }
};
