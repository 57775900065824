const ros_hashmap_mehak = [
  //gait
  ["ros_gaitNormal", "gait.normal"],
  ["ros_gaitLimp", "gait.limp"],
  ["ros_gaitWideBased", "gait.wideBased"],
  ["ros_gaitAbductorLurch", "gait.abductorLurch"],
  ["ros_gaitParetic", "gait.paretic"],
  ["ros_gaitShuffling", "gait.shuffling"],
  ["ros_gaitAtaxic", "gait.ataxic"],
  ["ros_gaitOther", "gait.other"],
  ["ros_gaitComment", "gait.comment"],
  [
    "gait_recoTakeMedicationsAsPescribedVal",
    "recommendationsGait.recommendationsGaitSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "gait_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsGait.recommendationsGaitSubAnswers[0].comment",
  ],
  [
    "gait_recoOtherVal",
    "recommendationsGait.recommendationsGaitSubAnswers[1].other",
  ],
  [
    "gait_RecommendationsotherCommentVal",
    "recommendationsGait.recommendationsGaitSubAnswers[1].comment",
  ],
  // [
  //   "gait_RecommendationsComment",
  //   "recommendationsGait.recommendationsGaitSubAnswers[0].comment",
  // ],

  //respiratory
  ["ros_respiratoryVal", "respiratory.isRespiratory"],
  //["respiratoryNo","respiratory.isRespiratory"],
  [
    "ros_respiratoryDiagnosesComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.comment",
  ],
  //

  [
    "ros_respiratoryDiagnosesAcutePulmonary",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonary",
  ],
  [
    "ros_respiratoryAcutePulmonaryActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.active",
  ],

  [
    "ros_respiratoryAcutePulmonaryHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryAcutePulmonaryruleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryAcutePulmonarySubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.describe.comment",
  ],
  [
    "ros_respiratoryAcutePulmonaryHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_respiratoryAcutePulmonaryCtAngiogram",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.ctAngiogram",
  ],
  [
    "ros_respiratoryAcutePulmonaryVenousDoppler",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.venousDoppler",
  ],
  [
    "ros_respiratoryAcutePulmonaryDDimer",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.dDimer",
  ],
  [
    "ros_respiratoryAcutePulmonaryVqScan",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.vqScan",
  ],
  [
    "ros_respiratoryAcutePulmonaryUseOfAnticoagulation",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.useOfAnticoagulation",
  ],
  [
    "ros_respiratoryAcutePulmonaryShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryAcutePulmonaryWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryAcutePulmonaryChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryAcutePulmonarySubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryAcutePulmonarySubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryAcutePulmonarySubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acutePulmonarySubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesAcuteUpper",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpper",
  ],
  [
    "ros_respiratoryAcuteUpperActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryAcuteUpperHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryAcuteUpperRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryAcuteUpperSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryAcuteUpperFever",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.fever",
  ],
  [
    "ros_respiratoryAcuteUpperChills",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chills",
  ],
  [
    "ros_respiratoryAcuteUpperCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.cough",
  ],
  [
    "ros_respiratoryAcuteUpperDecreasedBreathe",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.decreasedBreathe",
  ],
  [
    "ros_respiratoryAcuteUpperRales",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.rales",
  ],
  [
    "ros_respiratoryAcuteUpperWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryAcuteUpperChestXRay",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chestXRay",
  ],
  [
    "ros_respiratoryAcuteUpperShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.shortnessOfBreath",
  ],

  [
    "ros_respiratoryAcuteUpperChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryAcuteUpperOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryAcuteUpperSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryAcuteUpperSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryAcuteUpperSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.acuteUpperSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesAsthma",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthma",
  ],
  [
    "ros_respiratoryAsthmaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryAsthmaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryAsthmaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryAsthmaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryAsthmaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryAsthmaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryAsthmaCyanosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.cyanosis",
  ],
  [
    "ros_respiratoryAsthmaUseOfBronchodilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfBronchodilator",
  ],
  [
    "ros_respiratoryAsthmaUseOfInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfInhaled",
  ],
  [
    "ros_respiratoryAsthmaUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_respiratoryAsthmaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.shortnessOfBreath",
  ],

  [
    "ros_respiratoryAsthmaSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryAsthmaSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryAsthmaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.patientController.isPatientController",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.patientController.comment",
  ],
  [
    "ros_respiratoryAsthmaRescueMedicationsVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.rescueMedications.isRescueMedications",
  ],
  [
    "ros_respiratoryAsthmaRescueMedicationsComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.rescueMedications.comment",
  ],
  [
    "ros_respiratoryAsthmaCurrentExacerbationVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbation",
  ],
  [
    "ros_respiratoryAsthmaCurrentExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.comment",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.wheezing",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.shortnessOfBreath",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.useOfRescue",
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.useOfVentilator",
  ],

  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.asthmaSubAnswer.currentExacerbation.isCurrentExacerbationSubAnswers.comment",
  ],

  [
    "ros_respiratoryDiagnosesChronicPulmonaryEmbolism",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolism",
  ],

  [
    "ros_respiratoryChronicPulmonaryEmbolismActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryChronicPulmonaryEmbolismHistoryOfPul",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.historyOfPul",
  ],

  [
    "ros_respiratoryChronicPulmonaryEmbolismInsersionOfVena",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.insersionOfVena",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismAnticoagulation",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicPulmonaryEmbolismSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesChronicRespiratoryFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailure",
  ],

  [
    "ros_respiratoryChronicRespiratoryFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryChronicRespiratoryFailureHistoryOfHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.historyOfHospitalization",
  ],

  [
    "ros_respiratoryChronicRespiratoryFailureChronicUseOfO2",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.chronicUseOfO2",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.chronicCough",
  ],

  [
    "ros_respiratoryChronicRespiratoryFailureSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicRespiratoryFailureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesChronicSputumFailure",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailure",
  ],

  [
    "ros_respiratoryChronicSputumFailureActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryChronicSputumFailureHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryChronicSputumFailureRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryChronicSputumFailureSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryChronicSputumFailureHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.history",
  ],
  [
    "ros_respiratoryChronicSputumFailureSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratoryChronicSputumFailurePhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryChronicSputumFailureMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryChronicSputumFailureTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratoryChronicSputumFailureImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_respiratoryChronicSputumFailureBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryChronicSputumFailureDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratoryChronicSputumFailureShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryChronicSputumFailureWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.wheezing",
  ],

  [
    "ros_respiratoryChronicSputumFailureChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.chronicCough",
  ],

  [
    "ros_respiratoryChronicSputumFailureSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryChronicSputumFailureSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.chronicSputumFailureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesCopd",
    "respiratory.isRespiratorySubAnswer.diagnoses.copd",
  ],
  [
    "ros_respiratoryCopdActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryCopdHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryCopdRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryCopdSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryCopdXuseOfAccessaryMuscles",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.xuseOfAccessaryMuscles",
  ],

  [
    "ros_respiratoryCopdBarrelChest",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.barrelChest",
  ],
  [
    "ros_respiratoryCopdXrResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.xrResults",
  ],
  [
    "ros_respiratoryCopdWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryCopdClubbing",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.clubbing",
  ],
  [
    "ros_respiratoryCopdDecreasedOrProlongedSounds",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.decreasedOrProlongedSounds",
  ],
  [
    "ros_respiratoryCopdDyspneaOnExertion",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.dyspneaOnExertion",
  ],
  [
    "ros_respiratoryCopdOxygenUse",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.oxygenUse",
  ],
  [
    "ros_respiratoryCopdBronchodilatorMedication",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.bronchodilatorMedication",
  ],
  [
    "ros_respiratoryCopdShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryCopdChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryCopdSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryCopdSubOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryCopdSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryPatientChronicBronchitisVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientChronicBronchitis.ispatientChronicBronchitis",
  ],
  [
    "ros_respiratoryPatientChronicBronchitisComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientChronicBronchitis.comment",
  ],
  [
    "ros_respiratoryPatientHaveEmphysemaVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientHaveEmphysema.isPatientHaveEmphysema",
  ],
  [
    "ros_respiratoryPatientHaveEmphysemaComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientHaveEmphysema.comment",
  ],
  [
    "ros_respiratoryPatientBronchodilatorVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilator",
  ],
  [
    "ros_respiratoryPatientBronchodilatorComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.comment",
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilatorInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilatorSubAnswer.inhaled",
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilatorNebulizer",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilatorSubAnswer.nebulizer",
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilatorOral",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilatorSubAnswer.oral",
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilatorComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientBronchodilator.ispatientBronchodilatorSubAnswer.comment",
  ],
  [
    "ros_respiratoryPatientSteroidsVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroids",
  ],
  [
    "ros_respiratoryPatientSteroidsComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.comment",
  ],

  [
    "ros_respiratoryCopdSubPatientSteroidsInhaled",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroidsSubAnswer.inhaled",
  ],
  [
    "ros_respiratoryCopdSubPatientSteroidsNebulizer",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroidsSubAnswer.nebulizer",
  ],
  [
    "ros_respiratoryCopdSubPatientSteroidsOral",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroidsSubAnswer.oral",
  ],
  [
    "ros_respiratoryCopdSubPatientSteroidsComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientSteroids.isPatientSteroidsSubAnswer.comment",
  ],

  [
    "ros_respiratoryPatientExacerbationVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbation",
  ],
  [
    "ros_respiratoryPatientExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.comment",
  ],

  [
    "ros_respiratoryCopdSubPatientExacerbationUseOfAntibiotics",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.useOfAntibiotics",
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbationFever",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.fever",
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbationIncreasedSputumProduction",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.increasedSputumProduction",
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbationAdventitiousSoundsOnLung",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.adventitiousSoundsOnLung",
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbationComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.copdSubAnswer.patientExacerbation.isPatientExacerbationSubAnswer.comment",
  ],

  [
    "ros_respiratoryDiagnosesCysticFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosis",
  ],
  [
    "ros_respiratoryCysticFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryCysticFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryCysticFibrosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryCysticFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryCysticFibrosisHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_respiratoryCysticFibrosisSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratoryCysticFibrosisPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryCysticFibrosisMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryCysticFibrosisTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratoryCysticFibrosisImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_respiratoryCysticFibrosisBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryCysticFibrosisDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratoryCysticFibrosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.shortnessOfBreath",
  ],

  [
    "ros_respiratoryCysticFibrosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryCysticFibrosisChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.chronicCough",
  ],

  [
    "ros_respiratoryCysticFibrosisSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryCysticFibrosisSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryCysticFibrosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.cysticFibrosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesHypoventilation",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilation",
  ],
  [
    "ros_respiratoryHypoventilationActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryHypoventilationHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryHypoventilationRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryHypoventilationSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryHypoventilationMorbidObesity",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.morbidObesity",
  ],

  [
    "ros_respiratoryHypoventilationUseOfOxygen",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.useOfOxygen",
  ],
  [
    "ros_respiratoryHypoventilationCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_respiratoryHypoventilationShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryHypoventilationWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryHypoventilationChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryHypoventilationSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryHypoventilationSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryHypoventilationSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoventilationSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesHypoxemia",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemia",
  ],
  [
    "ros_respiratoryHypoxemiaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryHypoxemiaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryHypoxemiaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryHypoxemiaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryHypoxemiaOxygenSaturation",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.oxygenSaturation",
  ],

  [
    "ros_respiratoryHypoxemiaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryHypoxemiaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryHypoxemiaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryHypoxemiaSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryHypoxemiaSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryHypoxemiaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.hypoxemiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryDiagnosesPneumonia",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumonia",
  ],
  [
    "ros_respiratoryPneumoniaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryPneumoniaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryPneumoniaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryPneumoniaHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.hospitalization",
  ],

  [
    "ros_respiratoryPneumoniaPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryPneumoniaImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_respiratoryPneumoniaLabStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.labStudies",
  ],
  [
    "ros_respiratoryPneumoniaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryPneumoniaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryPneumoniaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryPneumoniaSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryPneumoniaSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryPneumoniaViral",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.viral",
  ],

  [
    "ros_respiratoryPneumoniaPneumococcal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.pneumococcal",
  ],
  [
    "ros_respiratoryPneumoniaStaph",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.staph",
  ],
  [
    "ros_respiratoryPneumoniaOtherBacterial",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.otherBacterial",
  ],
  [
    "ros_respiratoryPneumoniaAspiration",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.aspiration",
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerEtiologyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.etiology.comment",
  ],
  [
    "ros_respiratoryHistoryOfLungAbscessVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfLungAbscess.isHistoryOfLungAbscess",
  ],
  [
    "ros_respiratoryHistoryOfLungAbscessComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfLungAbscess.comment",
  ],
  [
    "ros_respiratoryHistoryOfEmpyemaVal",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfEmpyema.isHistoryOfEmpyema",
  ],
  [
    "ros_respiratoryHistoryOfEmpyemaComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pneumoniaSubAnswer.historyOfEmpyema.comment",
  ],

  [
    "ros_respiratoryDiagnosesPulmonaryFibrosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosis",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryPulmonaryFibrosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryPulmonaryFibrosisXRay",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.xRay",
  ],

  [
    "ros_respiratoryPulmonaryFibrosisPft",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.pft",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.pulmonaryFibrosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesRespiratorDependence",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependence",
  ],
  [
    "ros_respiratoryRespiratorDependenceActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryRespiratorDependenceHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryRespiratorDependenceRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryRespiratorDependenceHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.history",
  ],

  [
    "ros_respiratoryRespiratorDependenceSymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratoryRespiratorDependencePhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryRespiratorDependenceMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryRespiratorDependenceTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratoryRespiratorDependenceImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_respiratoryRespiratorDependenceBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryRespiratorDependenceDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratoryRespiratorDependenceShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryRespiratorDependenceWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryRespiratorDependenceChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratorDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryDiagnosesRespiratoryArrest",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrest",
  ],

  [
    "ros_respiratoryRespiratoryArrestActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.active",
  ],
  [
    "ros_respiratoryRespiratoryArrestHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryRespiratoryArrestRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryRespiratoryArrestHistoryOfHospitalization",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.historyOfHospitalization",
  ],

  [
    "ros_respiratoryRespiratoryArrestUseOfVentilator",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.useOfVentilator",
  ],
  [
    "ros_respiratoryRespiratoryArrestCarbonDioxideRetention",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.carbonDioxideRetention",
  ],
  [
    "ros_respiratoryRespiratoryArrestShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryRespiratoryArrestTestWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratoryRespiratoryArrestChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.respiratoryArrestSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesSarcoidosis",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosis",
  ],
  [
    "ros_respiratorySarcoidosisActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.active",
  ],

  [
    "ros_respiratorySarcoidosisHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratorySarcoidosisRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.describe.comment",
  ],

  [
    "ros_respiratorySarcoidosisLung",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lung",
  ],

  [
    "ros_respiratorySarcoidosisLymphNodes",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.lymphNodes",
  ],
  [
    "ros_respiratorySarcoidosisSkin",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.skin",
  ],
  [
    "ros_respiratorySarcoidosisCranialNerves",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.cranialNerves",
  ],
  [
    "ros_respiratorySarcoidosisTestUnKnown",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.unKnown",
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSpecifyComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.specify.comment",
  ],

  [
    "ros_respiratorySarcoidosisDiagnosticResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.diagnosticResults",
  ],

  [
    "ros_respiratorySarcoidosisCutaneousLesions",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.cutaneousLesions",
  ],
  [
    "ros_respiratorySarcoidosisShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratorySarcoidosisWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratorySarcoidosisTestChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratorySarcoidosisSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_respiratorySarcoidosisSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sarcoidosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesSleepApnea",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApnea",
  ],
  [
    "ros_respiratorySleepApneaActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.active",
  ],

  [
    "ros_respiratorySleepApneaHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratorySleepApneaRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratorySleepApneaSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.describe.comment",
  ],

  [
    "ros_respiratorySleepApneaUseOfCpap",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.useOfCpap",
  ],

  [
    "ros_respiratorySleepApneaPositiveSleepStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.positiveSleepStudies",
  ],
  [
    "ros_respiratorySleepApneaHistoryOfSleepiness",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.historyOfSleepiness",
  ],
  [
    "ros_respiratorySleepApneaHeavySnoring",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.heavySnoring",
  ],
  [
    "ros_respiratorySleepApneaShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratorySleepApneaWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_respiratorySleepApneaChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_respiratorySleepApneaSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.other",
  ],
  [
    "ros_respiratorySleepApneaSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratorySleepApneaSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.sleepApneaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_respiratoryDiagnosesOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.other",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeActive",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_respiratoryRespOtherSubAnswerDescribeHistoryOf",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeRuleOut",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerDescribeComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_respiratoryRespOtherSubAnswerSupportedByHistory",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedBySymptoms",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByMedications",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByTestResults",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByImageStudies",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_respiratoryRespOtherSubAnswerSupportedByBiopsy",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByDme",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByWheezing",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.wheezing",
  ],

  [
    "ros_respiratoryRespOtherSubAnswerSupportedByChronicCough",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.chronicCough",
  ],

  [
    "ros_respiratoryOtherSubOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],

  [
    "ros_respiratoryOtherSubDesc",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedByComment",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_respiratoryOtherSubAnswerOther",
    "respiratory.isRespiratorySubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_respiratoryComment", "respiratory.comment"],

  [
    "respiratory_recoTakeMedicationsVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].takeMedications",
  ],
  [
    "respiratory_RecommendationstakeMedicationsCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[0].comment",
  ],
  [
    "respiratory_recoOtherVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].other",
  ],
  [
    "respiratory_RecommendationsotherCommentVal",
    "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].comment",
  ],
  // [
  //   "respiratory_RecommendationsComment",
  //   "recommendationsRespiratory.recommendationsRespiratorySubAnswers[1].comment",
  // ],
  //integument

  ["ros_integumentVal", "integument.isIntegument"],

  [
    "ros_integumentDiagnosesComment",
    "integument.isIntegumentSubAnswer.diagnoses.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentBasilCellCarcinoma",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinoma",
  ],
  [
    "ros_integumentBasilCellCarcinomaActive",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.active",
  ],

  [
    "ros_integumentBasilCellCarcinomaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentBasilCellCarcinomaRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.describe.comment",
  ],
  [
    "ros_integumentBasilCellCarcinomaHistory",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentBasilCellCarcinomaSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentBasilCellCarcinomaPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentBasilCellCarcinomaMedications",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentBasilCellCarcinomaTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentBasilCellCarcinomaImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentBasilCellCarcinomaBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentBasilCellCarcinomaDme",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.basilCellCarcinomaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentDermatitis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitis",
  ],
  [
    "ros_integumentDermatitisActive",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.active",
  ],

  [
    "ros_integumentDermatitisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentDermatitisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentDermatitisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentDermatitisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentDermatitisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentDermatitisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentDermatitisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentDermatitisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentDermatitisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentDermatitisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentDermatitisDme",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentDermatitisSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentDermatitisSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentDermatitisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentDermatitisContact",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.contact",
  ],

  [
    "ros_integumentDermatitisStasis",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.stasis",
  ],
  [
    "ros_integumentDermatitisDrugInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.drugInduced",
  ],
  [
    "ros_integumentDermatitisDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.diseaseInduced",
  ],
  [
    "ros_integumentDermatitisUnspecified",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.unspecified",
  ],
  [
    "ros_integumentDermatitisSubAnswerWhatTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.dermatitisSubAnswer.whatType.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentEczema",
    "integument.isIntegumentSubAnswer.diagnoses.eczema",
  ],
  [
    "ros_integumentEczemaActive",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.active",
  ],

  [
    "ros_integumentEczemaHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentEczemaRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentEczemaSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.describe.comment",
  ],
  [
    "ros_integumentEczemaHistory",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentEczemaSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentEczemaPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentEczemaMedications",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentEczemaTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentEczemaImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentEczemaBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentEczemaDme",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentEczemaSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentEczemaSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentEczemaSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.eczemaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentOnychomysosis",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosis",
  ],
  [
    "ros_integumentOnychomycosisActive",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.active",
  ],

  [
    "ros_integumentOnychomycosisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentOnychomycosisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentOnychomycosisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentOnychomycosisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentOnychomycosisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentOnychomycosisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentOnychomycosisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentOnychomycosisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentOnychomycosisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentOnychomycosisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentOnychomycosisDme",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentOnychomycosisSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentOnychomycosisSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentOnychomycosisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.onychomycosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentDiagnosesIntegumentPsoriasis",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasis",
  ],
  [
    "ros_integumentPsoriasisActive",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.active",
  ],

  [
    "ros_integumentPsoriasisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentPsoriasisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentPsoriasisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentPsoriasisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentPsoriasisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentPsoriasisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentPsoriasisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentPsoriasisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentPsoriasisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentPsoriasisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentPsoriasisDme",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentPsoriasisSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentPsoriasisSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentPsoriasisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.psoriaticArthritis.isPsoriaticArthritis",
  ],
  [
    "ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment",
    "integument.isIntegumentSubAnswer.diagnoses.psoriasisSubAnswer.psoriaticArthritis.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentSkinUlcer",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcer",
  ],

  [
    "ros_integumentSkinUlcerActive",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.active",
  ],
  [
    "ros_integumentSkinUlcerHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentSkinUlcerRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentSkinUlcerSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.describe.comment",
  ],
  [
    "ros_integumentSkinUlcerHistory",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentSkinUlcerSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentSkinUlcerPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentSkinUlcerMedications",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentSkinUlcerTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentSkinUlcerImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentSkinUlcerBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentSkinUlcerDme",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentSkinUlcerSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentSkinUlcerSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentSkinUlcerSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentSkinUlcerPressure",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.pressure",
  ],
  [
    "ros_integumentSkinUlcerSubEtiologySubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.pressureSubAnswer.stageAndLocation",
  ],
  [
    "ros_integumentSkinUlcerVenousStatis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.venousStatis",
  ],

  [
    "ros_integumentSkinUlcerPeripheralVascular",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.peripheralVascular",
  ],
  [
    "ros_integumentSkinUlcerDiseaseInduced",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diseaseInduced",
  ],
  [
    "ros_integumentSkinUlcerDiabeticVasculitis",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticVasculitis",
  ],
  [
    "ros_integumentSkinUlcerDiabeticNeuropathy",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.diabeticNeuropathy",
  ],
  [
    "ros_integumentSkinUlcerSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.skinUlcerSubAnswer.etiology.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentTineaPedis",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedis",
  ],
  [
    "ros_integumentTineaPedisActive",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.active",
  ],
  [
    "ros_integumentTineaPedisHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.historyOf",
  ],

  [
    "ros_integumentTineaPedisRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentTineaPedisSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.describe.comment",
  ],
  [
    "ros_integumentTineaPedisHistory",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentTineaPedisSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentTineaPedisPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentTineaPedisMedications",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentTineaPedisTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentTineaPedisImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentTineaPedisBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentTineaPedisDme",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentTineaPedisSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentTineaPedisSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentTineaPedisSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.tineaPedisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentUrticarialDisease",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDisease",
  ],
  [
    "ros_integumentUrticarialDiseaseActive",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_integumentUrticarialDiseaseHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentUrticarialDiseaseRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseHistory",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentUrticarialDiseaseSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentUrticarialDiseasePhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentUrticarialDiseaseMedications",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentUrticarialDiseaseTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentUrticarialDiseaseImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentUrticarialDiseaseBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentUrticarialDiseaseDme",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentUrticarialDiseaseSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentUrticarialDiseaseSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseAcute",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.acute",
  ],

  [
    "ros_integumentUrticarialDiseaseChronic",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.chronic",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerTypeComment",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.type.comment",
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerEtiology",
    "integument.isIntegumentSubAnswer.diagnoses.urticarialDiseaseSubAnswer.etiology",
  ],

  [
    "ros_integumentDiagnosesIntegumentWound",
    "integument.isIntegumentSubAnswer.diagnoses.wound",
  ],
  [
    "ros_integumentWoundActive",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.active",
  ],
  [
    "ros_integumentWoundHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.historyOf",
  ],

  [
    "ros_integumentWoundRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentWoundSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.describe.comment",
  ],
  [
    "ros_integumentWoundHistory",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentWoundSymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_integumentWoundPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_integumentWoundMedications",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentWoundTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentWoundImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentWoundBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentWoundDme",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentWoundSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentWoundSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentWoundSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentWoundSurgical",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.surgical",
  ],

  [
    "ros_integumentWoundTraumatic",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.traumatic",
  ],
  [
    "ros_integumentWoundBurn",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.burn",
  ],
  [
    "ros_integumentWoundSubAnswerEtiologyComment",
    "integument.isIntegumentSubAnswer.diagnoses.woundSubAnswer.etiology.comment",
  ],

  [
    "ros_integumentDiagnosesIntegumentOther",
    "integument.isIntegumentSubAnswer.diagnoses.other",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeActive",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_integumentOthersSubAnswerDescribeHistoryOf",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeRuleOut",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_integumentOthersSubAnswerDescribeComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByHistory",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedBySymptoms",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByPhysicalFindings",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_integumentOthersSubAnswerSupportedByMedications",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByTestResults",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByImageStudies",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByBiopsy",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByDme",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_integumentIntegumentOtherSubOther",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_integumentIntegumentOtherSubOtherSubDesc",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_integumentOthersSubAnswerSupportedByComment",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_integumentOthersSubAnswerOther",
    "integument.isIntegumentSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_integumentComment", "integument.comment"],

  [
    "integument_recoTakeMedicationsAsPescribedVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "integument_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[0].comment",
  ],
  [
    "integument_recoOtherVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].other",
  ],
  [
    "integument_RecommendationsotherCommentVal",
    "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].comment",
  ],
  // [
  //   "integument_RecommendationsComment",
  //   "recommendationsIntegument.recommendationsIntegumentSubAnswers[1].comment",
  // ],

  //musko
  // ["musculoskeletalYes", "musculoskeletal.isMusculoskeletal"],
  // ["musculoskeletalNo", "musculoskeletal.isMusculoskeletal"],
  ["ros_musculoskeletalVal", "musculoskeletal.isMusculoskeletal"],

  [
    "ros_musculoskeletalDiagnosesComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoCollagen",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagen",
  ],
  [
    "ros_musculoskeletalCollagenActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalCollagenHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalCollagenRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalCollagenSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalCollagenPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_musculoskeletalCollagenLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_musculoskeletalCollagenBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_musculoskeletalCollagenImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalCollagenSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalCollagenSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalCollagenSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalCollagenSleLupus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.collagenDescribe.sleLupus",
  ],

  [
    "ros_musculoskeletalCollagenScleroderma",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.collagenDescribe.scleroderma",
  ],
  [
    "ros_musculoskeletalCollagenDermatomyositis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.collagenDescribe.dermatomyositis",
  ],
  [
    "ros_musculoskeletalCollagenSubAnswercollagenDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.collagenSubAnswer.collagenDescribe.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDisease",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseasePhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_musculoskeletalDegenerativeDiscDiseaseImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.normalBladder.comment",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseCervical",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.cervical",
  ],

  [
    "ros_musculoskeletalSiteOfDiseaseThoracic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.thoracic",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseLumbar",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.lumbar",
  ],
  [
    "ros_musculoskeletalSiteOfDiseaseLumbosacral",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.lumbosacral",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.other",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.degenerativeDiscDiseaseSubAnswer.siteOfDisease.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoExtremityFracture",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFracture",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeTypeSubAnswer.traumatic",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeTypeSubAnswer.pathological",
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.activeTypeSubAnswer.comment",
  ],

  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.traumatic",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.pathological",
  ],
  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.historyOfSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.describe.comment",
  ],

  [
    "ros_musculoskeletalExtremityFractureHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.history",
  ],
  [
    "ros_musculoskeletalExtremityFractureSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalExtremityFracturePhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_musculoskeletalExtremityFractureMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalExtremityFractureTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_musculoskeletalExtremityFractureImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalExtremityFractureBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_musculoskeletalExtremityFractureDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.dme",
  ],
  [
    "ros_musculoskeletalExtremityFractureOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulder",
  ],
  [
    "ros_musculoskeletalExtremityFractureShoulderRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureShoulderLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureShoulderSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.shoulderSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityArm",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.arm",
  ],
  [
    "ros_musculoskeletalExtremityFractureArmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureArmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureArmSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.armSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearm",
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureForearmSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.forearmSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wrist",
  ],
  [
    "ros_musculoskeletalExtremityFractureWristRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureWristLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureWristSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.wristSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityHand",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hand",
  ],
  [
    "ros_musculoskeletalExtremityFractureHandRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureHandLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.left",
  ],

  [
    "ros_musculoskeletalHandSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.handSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaft",
  ],
  [
    "ros_musculoskeletalExtremityFractureFemoralShaftRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureFemoralShaftLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.left",
  ],

  [
    "ros_musculoskeletalFemoralShaftSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.femoralShaftSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibia",
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureTibiaSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.tibiaSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibula",
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureFibulaSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.fibulaSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankle",
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureAnkleSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.ankleSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.foot",
  ],
  [
    "ros_musculoskeletalExtremityFractureFootRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureFootLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureFootSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.footSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityHip",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hip",
  ],

  [
    "ros_musculoskeletalExtremityFractureHipRight",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.right",
  ],
  [
    "ros_musculoskeletalExtremityFractureHipLeft",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.left",
  ],

  [
    "ros_musculoskeletalExtremityFractureHipSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.hipSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremityComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.extremity.comment",
  ],

  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.isCurrentWithinTwelveWeeks",
  ],
  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.extremityFractureSubAnswer.currentWithinTwelveWeeks.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoGout",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.gout",
  ],
  [
    "ros_musculoskeletalGoutActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalGoutHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalGoutRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalGoutSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.describe.comment",
  ],

  [
    "ros_musculoskeletalGoutHistoryOfAttacks",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.historyOfAttacks",
  ],
  [
    "ros_musculoskeletalGoutLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_musculoskeletalGoutMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalGoutSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalGoutSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalGoutSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.goutSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoHalluxValgus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgus",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalHalluxValgusHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.history",
  ],
  [
    "ros_musculoskeletalHalluxValgusSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_musculoskeletalHalluxValgusPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_musculoskeletalHalluxValgusMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalHalluxValgusTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_musculoskeletalHalluxValgusImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalHalluxValgusBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_musculoskeletalHalluxValgusDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.dme",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.halluxValgusSubAnswer.supportedBy.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoHammerToes",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToes",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalHammerToesHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.history",
  ],
  [
    "ros_musculoskeletalHammerToesSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_musculoskeletalHammerToesPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_musculoskeletalHammerToesMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalHammerToesTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_musculoskeletalHammerToesImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalHammerToesBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_musculoskeletalHammerToesDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.dme",
  ],
  [
    "ros_musculoskeletalHammerToesSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalHammerToesSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.hammerToesSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoOsteoarthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritis",
  ],
  [
    "ros_musculoskeletalOsteoarthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.active",
  ],
  [
    "ros_musculoskeletalOsteoarthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.historyOf",
  ],

  [
    "ros_musculoskeletalOsteoarthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.describe.comment",
  ],
  // [
  //   "ros_musculoskeletalOsteoarthritisHistory",
  //   "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.history",
  // ],
  [
    "ros_musculoskeletalOsteoarthritisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointSwelling",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.jointSwelling",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointStiffness",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.jointStiffness",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerPain",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerLimitedRom",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.limitedRom",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalOsteoarthritisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_musculoskeletalOsteoarthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoarthritisSubAnswer.whichJoints",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoOsteomyelitis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitis",
  ],
  [
    "ros_musculoskeletalOsteomyelitisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalOsteomyelitisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalOsteomyelitisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalOsteomyelitisHospitalization",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.hospitalization",
  ],

  [
    "ros_musculoskeletalOsteomyelitisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalOsteomyelitisCultures",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_musculoskeletalOsteomyelitisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteomyelitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoOsteoporosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosis",
  ],
  [
    "ros_musculoskeletalOsteoporosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalOsteoporosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalOsteoporosisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalOsteoporosisDexaScan",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.dexaScan",
  ],

  [
    "ros_musculoskeletalOsteoporosisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalOsteoporosisImagingStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_musculoskeletalOsteoporosisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalOsteoporosisFractureHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.fractureHistory",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.osteoporosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoPyogenicArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritis",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalPyogenicArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisHospitalization",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.hospitalization",
  ],

  [
    "ros_musculoskeletalPyogenicArthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisCultures",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.pyogenicArthritisSubAnswer.whichJoints",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritis",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalRheumatoidArthritisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisJointSwelling",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.jointSwelling",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisJointStiffness",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.jointStiffness",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisPain",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.pain",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisLimitedRom",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.limitedRom",
  ],

  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.isSymptomsSubAnswer.comment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisLabTests",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.rheumatoidArthritisSubAnswer.whichJoints",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoSpinalStenosis",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosis",
  ],
  [
    "ros_musculoskeletalSpinalStenosisActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalSpinalStenosisHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSpinalStenosisRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_musculoskeletalSpinalStenosisPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_musculoskeletalSpinalStenosisImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalSpinalStenosisMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.normalBladder.isNormalBladder",
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.spinalStenosisSubAnswer.normalBladder.comment",
  ],

  [
    "ros_musculoskeletalDiagnosesMuskoSystemicLupus",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupus",
  ],
  [
    "ros_musculoskeletalSystemicLupusActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalSystemicLupusHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalSystemicLupusRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalSystemicLupusSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalSystemicLupusLabs",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.labs",
  ],

  [
    "ros_musculoskeletalSystemicLupusMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalSystemicLupusHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.history",
  ],
  [
    "ros_musculoskeletalSystemicLupusSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.other",
  ],

  [
    "ros_musculoskeletalSystemicLupusSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.systemicLupusSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalDiagnosesMuskoOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.other",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeActive",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeRuleOut",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribeComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByHistory",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedBySymptoms",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByPhysicalFindings",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByMedications",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByTestResults",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],

  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByImageStudies",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByBiopsy",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByDme",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_musculoskeletalMuskoSubOther",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_musculoskeletalMuskoSubOtherSubDesc",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerother",
    "musculoskeletal.isMusculoskeletalSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_musculoskeletalMuskoHadAnAmputationVal",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputation",
  ],
  [
    "ros_musculoskeletalHadAnAmputationSubAnswer",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.isHadAnAmputationSubAnswer.describe",
  ],

  [
    "ros_musculoskeletalMuskoHadAnAmputationComment",
    "musculoskeletal.isMusculoskeletalSubAnswer.hadAnAmputation.comment",
  ],
  ["ros_musculoskeletalComment", "musculoskeletal.comment"],

  [
    "musko_recoDiscussPtOtVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].discussPtOt",
  ],
  [
    "musko_RecommendationsdiscussPtOtCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[0].comment",
  ],
  [
    "musko_recoTakeMedicationsAsPescribedVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].takeMedicationsAsPescribed",
  ],
  [
    "musko_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].comment",
  ],

  [
    "musko_recoOtherVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].other",
  ],
  [
    "musko_RecommendationsotherCommentVal",
    "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[2].comment",
  ],
  // [
  //   "musko_RecommendationsComment",
  //   "recommendationsMusculoskeletal.recommendationsMusculoskeletalSubAnswers[1].comment",
  // ],

  //hematology

  [
    "ros_hematologyVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDisease",
  ],

  [
    "ros_hematologyDiagnosesComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.comment",
  ],
  [
    "ros_hematologyDiagnosesHemaAids",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aids",
  ],
  [
    "ros_hematologyAidsActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.active",
  ],

  [
    "ros_hematologyAidsHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyAidsRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyAidsSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyAidsSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_hematologyAidsPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyAidsHistoryOfOpportunistic",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.historyOfOpportunistic",
  ],
  [
    "ros_hematologyAidsMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyAidsSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyAidsSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyAidsSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyAidsSubAnswerPatientUnderTreatmentVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.patientUnderTreatment.isPatientUnderTreatment",
  ],
  [
    "ros_hematologyAidsSubPatientUnderTreatmentSubWhere",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.patientUnderTreatment.isPatientUnderTreatmentSubAnswer.where",
  ],

  [
    "ros_hematologyAidsSubAnswerPatientUnderTreatmentComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.aidsSubAnswer.patientUnderTreatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaAnemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemia",
  ],
  [
    "ros_hematologyAnemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.active",
  ],

  [
    "ros_hematologyAnemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyAnemiaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyAnemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyAnemiaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_hematologyAnemiaSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_hematologyAnemiaHistoryOfBloodTransfusion",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.historyOfBloodTransfusion",
  ],
  [
    "ros_hematologyAnemiaSubother",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyAnemiaSubotherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyAnemiaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyAnemiaIronDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.ironDeficiency",
  ],
  [
    "ros_hematologyAnemiaPernicious",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.pernicious",
  ],

  [
    "ros_hematologyAnemiaKidneyDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.kidneyDisease",
  ],
  [
    "ros_hematologyAnemiaHemolysis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.hemolysis",
  ],
  [
    "ros_hematologyAnemiaAplastic",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.aplastic",
  ],
  [
    "ros_hematologyAnemiaChemotherapy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.chemotherapy",
  ],
  [
    "ros_hematologyAnemiaBloodLoss",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.bloodLoss",
  ],
  [
    "ros_hematologyAnemiaChronicDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.chronicDisease",
  ],
  [
    "ros_hematologyAnemiaFolateDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.folateDeficiency",
  ],
  [
    "ros_hematologyAnemiaSubEtiologySubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.other",
  ],
  [
    "ros_hematologyAnemiaSubEtiologySubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyAnemiaSubAnswerEtiologyComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.etiology.comment",
  ],
  [
    "ros_hematologyAnemiaIron",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.iron",
  ],

  [
    "ros_hematologyAnemiaBTwelve",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.bTwelve",
  ],
  [
    "ros_hematologyAnemiaFolicAcid",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.folicAcid",
  ],
  [
    "ros_hematologyAnemiaBloodTransfusions",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.bloodTransfusions",
  ],
  [
    "ros_hematologyAnemiaSubYesPatientOn",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.other",
  ],
  [
    "ros_hematologyAnemiaSubYesPatientOnSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyAnemiaSubAnswerYesPatientOnComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.anemiaSubAnswer.yesPatientOn.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaCDifficile",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficile",
  ],
  [
    "ros_hematologyCDifficileActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.active",
  ],

  [
    "ros_hematologyCDifficileHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyCDifficileRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyCDifficileSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyCDifficilePositiveLabTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.positiveLabTest",
  ],

  [
    "ros_hematologyCDifficileSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyCDifficileHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_hematologyCDifficileMedication",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.medication",
  ],
  [
    "ros_hematologyCDifficileSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyCDifficileSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyCDifficileSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.cDifficileSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaCommunityAcquired",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquired",
  ],
  [
    "ros_hematologyCommunityAcquiredActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.active",
  ],

  [
    "ros_hematologyCommunityAcquiredHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyCommunityAcquiredRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyCommunityAcquiredSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.describe.comment",
  ],

  [
    "ros_hematologyCommunityAcquiredCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_hematologyCommunityAcquiredHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_hematologyCommunityAcquiredMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyCommunityAcquiredPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyCommunityAcquiredSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyCommunityAcquiredSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyCommunityAcquiredSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.communityAcquiredSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaHiv",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hiv",
  ],
  [
    "ros_hematologyHivActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.active",
  ],

  [
    "ros_hematologyHivHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyHivRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyHivSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyHivLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.labTests",
  ],

  [
    "ros_hematologyHivSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyHivdMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyHivSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyHivSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyHivSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyHivSubAnswerViralLoad",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.viralLoad",
  ],
  [
    "ros_hematologyHivSubAnswerCFour",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.cFour",
  ],
  [
    "ros_hematologyHivSubAnswerPatientSymptomaticVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.isPatientSymptomatic",
  ],
  [
    "ros_hematologyHivSubAnswerPatientSymptomaticComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.patientSymptomatic.comment",
  ],
  [
    "ros_hematologyHivSubAnswerActiveTreatmentVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_hematologyHivSubAnswerActiveTreatmentComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.hivSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaHerpesZoster",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZoster",
  ],
  [
    "ros_hematologyHerpesZosterActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.active",
  ],

  [
    "ros_hematologyHerpesZosterHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyHerpesZosterRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyHerpesZosterSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyHerpesZosterRash",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.rash",
  ],

  [
    "ros_hematologyHerpesZosterSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyHerpesZosterMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyHerpesZosterSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyHerpesZosterSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyHerpesZosterSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.herpesZosterSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaMrsaInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfection",
  ],
  [
    "ros_hematologyMrsaInfectionActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.active",
  ],

  [
    "ros_hematologyMrsaInfectionHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyMrsaInfectionRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyMrsaInfectionSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyMrsaInfectionCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.cultures",
  ],

  [
    "ros_hematologyMrsaInfectionHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_hematologyMrsaInfectionMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyMrsaInfectionPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyMrsaInfectionSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyMrsaInfectionSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyMrsaInfectionSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.mrsaInfectionSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaImmuneDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiency",
  ],
  [
    "ros_hematologyImmuneDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.active",
  ],

  [
    "ros_hematologyImmuneDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyImmuneDeficiencyRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.describe.comment",
  ],
  [
    "ros_hematologyImmuneDeficiencyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyImmuneDeficiencySymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyImmuneDeficiencyPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_hematologyImmuneDeficiencyMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyImmuneDeficiencyImageStudies",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_hematologyImmuneDeficiencyTestResults",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.testResults",
  ],
  [
    "ros_hematologyImmuneDeficiencySubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyImmuneDeficiencySubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerEtiology",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.etiology",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.historyOfOpportunistic.isHistoryOfOpportunistic",
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.immuneDeficiencySubAnswer.historyOfOpportunistic.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaSepsis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsis",
  ],
  [
    "ros_hematologySepsisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.active",
  ],

  [
    "ros_hematologySepsisHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySepsisRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologySepsisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.describe.comment",
  ],
  [
    "ros_hematologySepsisBloodCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.bloodCultures",
  ],

  [
    "ros_hematologySepsisOtherCultures",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.otherCultures",
  ],
  [
    "ros_hematologySepsisSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologySepsisHospitalization",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_hematologySepsisUnstableVitalSigns",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.unstableVitalSigns",
  ],
  [
    "ros_hematologySepsisSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologySepsisSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologySepsisSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sepsisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaSickleCellDisease",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDisease",
  ],
  [
    "ros_hematologySickleCellDiseaseActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_hematologySickleCellDiseaseHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySickleCellDiseaseRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologySickleCellDiseaseSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_hematologySickleCellDiseaseFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.familyHistory",
  ],

  [
    "ros_hematologySickleCellDiseaseSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologySickleCellDiseaseHistoryOfInfections",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.historyOfInfections",
  ],
  [
    "ros_hematologySickleCellDiseaseHospitalizations",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.hospitalizations",
  ],
  [
    "ros_hematologySickleCellDiseaseSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologySickleCellDiseaseSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologySickleCellDiseaseSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyDiagnosesHemaSickleCellTrait",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTrait",
  ],
  [
    "ros_hematologySickleCellTraitActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.active",
  ],

  [
    "ros_hematologySickleCellTraitHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologySickleCellTraitRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologySickleCellTraitSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.describe.comment",
  ],

  [
    "ros_hematologySickleCellTraitFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_hematologySickleCellTraitLabTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.labTest",
  ],
  [
    "ros_hematologySickleCellTraitSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologySickleCellTraitSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologySickleCellTraitSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.sickleCellTraitSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyDiagnosesHemaThalassemia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemia",
  ],
  [
    "ros_hematologyThalassemiaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.active",
  ],

  [
    "ros_hematologyThalassemiaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyThalassemiaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyThalassemiaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyThalassemiaFamilyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.familyHistory",
  ],

  [
    "ros_hematologyThalassemiaSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyThalassemiaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_hematologyThalassemiaHistoryOfInfections",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.historyOfInfections",
  ],
  [
    "ros_hematologyThalassemiaSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyThalassemiaSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyThalassemiaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thalassemiaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaThrombocytopenia",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopenia",
  ],
  [
    "ros_hematologyThrombocytopeniaActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.active",
  ],

  [
    "ros_hematologyThrombocytopeniaHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyThrombocytopeniaRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyThrombocytopeniaLabTests",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.labTests",
  ],

  [
    "ros_hematologyThrombocytopeniaPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyThrombocytopeniaHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyThrombocytopeniaSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyThrombocytopeniaSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerEtiology",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.thrombocytopeniaSubAnswer.etiology",
  ],

  [
    "ros_hematologyDiagnosesHemaTuberculosis",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosis",
  ],
  [
    "ros_hematologyTuberculosisActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.active",
  ],
  [
    "ros_hematologyTuberculosishistoryOfActiveTb",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.historyOfActiveTb",
  ],

  [
    "ros_hematologyTuberculosistbInfection",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.tbInfection",
  ],
  [
    "ros_hematologyTuberculosisruleOutActiveTb",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.ruleOutActiveTb",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyTuberculosisHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyTuberculosisMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyTuberculosisImaginingStudy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.imaginingStudy",
  ],

  [
    "ros_hematologyTuberculosisSkinTest",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.skinTest",
  ],
  [
    "ros_hematologyTuberculosisSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyTuberculosisPositiveCulture",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.positiveCulture",
  ],
  [
    "ros_hematologyTuberculosisSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyTuberculosisSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerGivenBCG",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.givenBCG.isGivenBCG",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerGivenBCGComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.givenBCG.comment",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerActiveTuberculosisVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.activeTuberculosis.isActiveTuberculosis",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.activeTuberculosis.comment",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerTbInfectionVal",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.tbInfection.isTbInfection",
  ],
  [
    "ros_hematologyTuberculosisSubAnswerTbInfectionComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.tuberculosisSubAnswer.tbInfection.comment",
  ],

  [
    "ros_hematologyDiagnosesHemaVitaminDDeficiency",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiency",
  ],
  [
    "ros_hematologyVitaminDDeficiencyActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.active",
  ],

  [
    "ros_hematologyVitaminDDeficiencyHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyVitaminDDeficiencyRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.describe.comment",
  ],
  [
    "ros_hematologyVitaminDDeficiencyLabs",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.labs",
  ],

  [
    "ros_hematologyVitaminDDeficiencyMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.medications",
  ],
  [
    "ros_hematologyVitaminDDeficiencyHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.vitaminDDeficiencySubAnswer.supportedBy.comment",
  ],

  [
    "ros_hematologyHemaDiagnosesOtherSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.other",
  ],
  [
    "ros_hematologyHemaOtherSubOtherSubDesc",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_hematologyOtherActive",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_hematologyOtherHistoryOf",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_hematologyOtherRuleOut",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerDescribeComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_hematologyOtherHistory",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_hematologyOtherSymptoms",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_hematologyOtherPhysicalFindings",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_hematologyOtherMedications",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],

  [
    "ros_hematologyOtherTestResults",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_hematologyOtherImageStudies",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_hematologyOtherBiopsy",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_hematologyOtherDme",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_hematologyHemaOtherSubOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerSupportedByComment",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_hematologyHemaOtherSubAnswerOther",
    "hematologyImmunologyInfectiousDisease.isHematologyImmunologyInfectiousDiseaseSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  ["ros_hematologyComment", "hematologyImmunologyInfectiousDisease.comment"],
  [
    "hema_recoTakeMedicationsAsPescribedVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "hema_RecommendationstakeMedicationsAsPescribedCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].comment",
  ],
  [
    "hema_recoReportAbnormalBruisingVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].reportAbnormalBruising",
  ],
  [
    "hema_RecommendationsreportAbnormalBruisingCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[1].comment",
  ],
  [
    "hema_recoFollowUpWithDoctorVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].followUpWithDoctor",
  ],
  [
    "hema_RecommendationsfollowUpWithDoctorCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[2].comment",
  ],

  [
    "hema_recoOtherVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].other",
  ],
  [
    "hema_RecommendationsotherCommentVal",
    "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[3].comment",
  ],

  // [
  //   "hema_RecommendationsComment",
  //   "recommendationsHematologyImmunologyInfectiousDisease.recommendationsHematologyImmunologyInfectiousDiseaseSubAnswers[0].comment",
  // ],
];

const ros_optionsTreeMapObject_mehak = {
  //gait
  ros_gaitNormal: "normal",
  ros_gaitLimp: "limp",
  ros_gaitWideBased: "wideBased",
  ros_gaitAbductorLurch: "abductorLurch",
  ros_gaitParetic: "paretic",
  ros_gaitShuffling: "shuffling",
  ros_gaitAtaxic: "ataxic",
  ros_gaitOther: "other",

  //respiratory
  // respiratoryYes: "yes",
  // respiratoryNo: "no",

  ros_respiratoryDiagnosesAcutePulmonary: "acutePulmonary",
  ros_respiratoryAcutePulmonaryActive: "active",
  ros_respiratoryAcutePulmonaryHistoryOf: "historyOf",
  ros_respiratoryAcutePulmonaryruleOut: "ruleOut",
  ros_respiratoryAcutePulmonaryChronicCough: "chronicCough",
  ros_respiratoryAcutePulmonaryCtAngiogram: "ctAngiogram",
  ros_respiratoryAcutePulmonaryDDimer: "dDimer",
  ros_respiratoryAcutePulmonaryShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryAcutePulmonaryUseOfAnticoagulation: "useOfAnticoagulation",
  ros_respiratoryAcutePulmonaryVenousDoppler: "venousDoppler",
  ros_respiratoryAcutePulmonaryVqScan: "vqScan",
  ros_respiratoryAcutePulmonaryWheezing: "wheezing",
  ros_respiratoryAcutePulmonaryHospitalization: "hospitalization",
  ros_respiratoryAcutePulmonarySubOther: "other",

  ros_respiratoryDiagnosesAcuteUpper: "acuteUpper",
  ros_respiratoryAcuteUpperActive: "active",
  ros_respiratoryAcuteUpperHistoryOf: "historyOf",
  ros_respiratoryAcuteUpperRuleOut: "ruleOut",
  ros_respiratoryAcuteUpperChestXRay: "chestXRay",
  ros_respiratoryAcuteUpperChills: "chills",
  ros_respiratoryAcuteUpperChronicCough: "chronicCough",
  ros_respiratoryAcuteUpperCough: "cough",
  ros_respiratoryAcuteUpperDecreasedBreathe: "decreasedBreathe",
  ros_respiratoryAcuteUpperFever: "fever",
  ros_respiratoryAcuteUpperRales: "rales",
  ros_respiratoryAcuteUpperShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryAcuteUpperWheezing: "wheezing",
  ros_respiratoryAcuteUpperSubOther: "other",

  ros_respiratoryDiagnosesAsthma: "asthma",
  ros_respiratoryAsthmaActive: "active",
  ros_respiratoryAsthmaHistoryOf: "historyOf",
  ros_respiratoryAsthmaRuleOut: "ruleOut",
  ros_respiratoryAsthmaChronicCough: "chronicCough",
  ros_respiratoryAsthmaCyanosis: "cyanosis",
  ros_respiratoryAsthmaUseOfBronchodilator: "useOfBronchodilator",
  ros_respiratoryAsthmaUseOfInhaled: "useOfInhaled",
  ros_respiratoryAsthmaUseOfVentilator: "useOfVentilator",
  ros_respiratoryAsthmaShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryAsthmaWheezing: "wheezing",
  ros_respiratoryAsthmaSubOther: "other",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing:
    "wheezing",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath:
    "shortnessOfBreath",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue:
    "useOfRescue",
  ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator:
    "useOfVentilator",

  ros_respiratoryAsthmaPatientControllerYes: "yes",
  ros_respiratoryAsthmaPatientControllerNo: "no",
  ros_respiratoryAsthmaRescueMedicationsYes: "yes",
  ros_respiratoryAsthmaRescueMedicationsNo: "no",
  ros_respiratoryAsthmaCurrentExacerbationYes: "yes",
  ros_respiratoryAsthmaCurrentExacerbationNo: "no",

  ros_respiratoryDiagnosesChronicPulmonaryEmbolism: "chronicPulmonaryEmbolism",
  ros_respiratoryChronicPulmonaryEmbolismActive: "active",
  ros_respiratoryChronicPulmonaryEmbolismHistoryOf: "historyOf",
  ros_respiratoryChronicPulmonaryEmbolismRuleOut: "ruleOut",
  ros_respiratoryChronicPulmonaryEmbolismHistoryOfPul: "historyOfPul",
  ros_respiratoryChronicPulmonaryEmbolismInsersionOfVena: "insersionOfVena",
  ros_respiratoryChronicPulmonaryEmbolismAnticoagulation: "anticoagulation",
  ros_respiratoryChronicPulmonaryEmbolismShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryChronicPulmonaryEmbolismWheezing: "wheezing",
  ros_respiratoryChronicPulmonaryEmbolismChronicCough: "chronicCough",
  ros_respiratoryChronicPulmonaryEmbolismSubOther: "other",

  ros_respiratoryDiagnosesChronicRespiratoryFailure:
    "chronicRespiratoryFailure",
  ros_respiratoryChronicRespiratoryFailureActive: "active",
  ros_respiratoryChronicRespiratoryFailureHistoryOf: "historyOf",
  ros_respiratoryChronicRespiratoryFailureRuleOut: "ruleOut",
  ros_respiratoryChronicRespiratoryFailureHistoryOfHospitalization:
    "historyOfHospitalization",
  ros_respiratoryChronicRespiratoryFailureChronicUseOfO2: "chronicUseOfO2",
  ros_respiratoryChronicRespiratoryFailureCarbonDioxideRetention:
    "carbonDioxideRetention",
  ros_respiratoryChronicRespiratoryFailureShortnessOfBreath:
    "shortnessOfBreath",
  ros_respiratoryChronicRespiratoryFailureWheezing: "wheezing",
  ros_respiratoryChronicRespiratoryFailureChronicCough: "chronicCough",
  ros_respiratoryChronicRespiratoryFailureUseOfVentilator: "useOfVentilator",
  ros_respiratoryChronicRespiratoryFailureSubOther: "other",

  ros_respiratoryDiagnosesChronicSputumFailure: "chronicSputumFailure",
  ros_respiratoryChronicSputumFailureActive: "active",
  ros_respiratoryChronicSputumFailureHistoryOf: "historyOf",
  ros_respiratoryChronicSputumFailureRuleOut: "ruleOut",
  ros_respiratoryChronicSputumFailureHistory: "history",
  ros_respiratoryChronicSputumFailureSymptoms: "symptoms",
  ros_respiratoryChronicSputumFailurePhysicalFindings: "physicalFindings",
  ros_respiratoryChronicSputumFailureMedications: "medications",
  ros_respiratoryChronicSputumFailureTestResults: "testResults",
  ros_respiratoryChronicSputumFailureImageStudies: "imageStudies",
  ros_respiratoryChronicSputumFailureBiopsy: "biopsy",
  ros_respiratoryChronicSputumFailureDme: "dme",
  ros_respiratoryChronicSputumFailureShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryChronicSputumFailureChronicCough: "chronicCough",
  ros_respiratoryChronicSputumFailureWheezing: "wheezing",
  ros_respiratoryChronicSputumFailureSubOther: "other",

  ros_respiratoryDiagnosesCopd: "copd",
  ros_respiratoryCopdActive: "active",
  ros_respiratoryCopdHistoryOf: "historyOf",
  ros_respiratoryCopdRuleOut: "ruleOut",
  ros_respiratoryCopdXuseOfAccessaryMuscles: "xuseOfAccessaryMuscles",
  ros_respiratoryCopdBarrelChest: "barrelChest",
  ros_respiratoryCopdXrResults: "xrResults",
  ros_respiratoryCopdWheezing: "wheezing",
  ros_respiratoryCopdClubbing: "clubbing",
  ros_respiratoryCopdDecreasedOrProlongedSounds: "decreasedOrProlongedSounds",
  ros_respiratoryCopdDyspneaOnExertion: "dyspneaOnExertion",
  ros_respiratoryCopdOxygenUse: "oxygenUse",
  ros_respiratoryCopdBronchodilatorMedication: "bronchodilatorMedication",
  ros_respiratoryCopdShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryCopdChronicCough: "chronicCough",
  ros_respiratoryCopdSubOther: "other",
  ros_respiratoryPatientChronicBronchitisYes: "yes",
  ros_respiratoryPatientChronicBronchitisNo: "no",
  ros_respiratoryPatientHaveEmphysemaYes: "yes",
  ros_respiratoryPatientHaveEmphysemaNo: "no",
  ros_respiratoryPatientBronchodilatorYes: "yes",
  ros_respiratoryPatientBronchodilatorNo: "no",
  ros_respiratoryPatientSteroidsYes: "yes",
  ros_respiratoryPatientSteroidsNo: "no",
  ros_respiratoryPatientExacerbationYes: "yes",
  ros_respiratoryPatientExacerbationNo: "no",
  ros_respiratoryCopdSubPatientBronchodilatorInhaled: "inhaled",
  ros_respiratoryCopdSubPatientBronchodilatorNebulizer: "nebulizer",
  ros_respiratoryCopdSubPatientBronchodilatorOral: "oral",
  ros_respiratoryCopdSubPatientSteroidsInhaled: "inhaled",
  ros_respiratoryCopdSubPatientSteroidsNebulizer: "nebulizer",
  ros_respiratoryCopdSubPatientSteroidsOral: "oral",
  ros_respiratoryCopdSubPatientExacerbationUseOfAntibiotics: "useOfAntibiotics",
  ros_respiratoryCopdSubPatientExacerbationFever: "fever",
  ros_respiratoryCopdSubPatientExacerbationIncreasedSputumProduction:
    "increasedSputumProduction",
  ros_respiratoryCopdSubPatientExacerbationAdventitiousSoundsOnLung:
    "adventitiousSoundsOnLung",

  ros_respiratoryDiagnosesCysticFibrosis: "cysticFibrosis",
  ros_respiratoryCysticFibrosisActive: "active",
  ros_respiratoryCysticFibrosisHistoryOf: "historyOf",
  ros_respiratoryCysticFibrosisRuleOut: "ruleOut",
  ros_respiratoryCysticFibrosisHistory: "history",
  ros_respiratoryCysticFibrosisSymptoms: "symptoms",
  ros_respiratoryCysticFibrosisPhysicalFindings: "physicalFindings",
  ros_respiratoryCysticFibrosisMedications: "medications",
  ros_respiratoryCysticFibrosisTestResults: "testResults",
  ros_respiratoryCysticFibrosisImageStudies: "imageStudies",
  ros_respiratoryCysticFibrosisBiopsy: "biopsy",
  ros_respiratoryCysticFibrosisDme: "dme",
  ros_respiratoryCysticFibrosisShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryCysticFibrosisChronicCough: "chronicCough",
  ros_respiratoryCysticFibrosisWheezing: "wheezing",
  ros_respiratoryCysticFibrosisSubOther: "other",

  ros_respiratoryDiagnosesHypoventilation: "hypoventilation",
  ros_respiratoryHypoventilationActive: "active",
  ros_respiratoryHypoventilationHistoryOf: "historyOf",
  ros_respiratoryHypoventilationRuleOut: "ruleOut",
  ros_respiratoryHypoventilationMorbidObesity: "morbidObesity",
  ros_respiratoryHypoventilationUseOfOxygen: "useOfOxygen",
  ros_respiratoryHypoventilationCarbonDioxideRetention:
    "carbonDioxideRetention",
  ros_respiratoryHypoventilationShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryHypoventilationWheezing: "wheezing",
  ros_respiratoryHypoventilationChronicCough: "chronicCough",
  ros_respiratoryHypoventilationSubOther: "other",

  ros_respiratoryDiagnosesHypoxemia: "hypoxemia",
  ros_respiratoryHypoxemiaActive: "active",
  ros_respiratoryHypoxemiaHistoryOf: "historyOf",
  ros_respiratoryHypoxemiaRuleOut: "ruleOut",
  ros_respiratoryHypoxemiaOxygenSaturation: "oxygenSaturation",
  ros_respiratoryHypoxemiaShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryHypoxemiaWheezing: "wheezing",
  ros_respiratoryHypoxemiaChronicCough: "chronicCough",
  ros_respiratoryHypoxemiaSubOther: "other",

  ros_respiratoryDiagnosesPneumonia: "pneumonia",
  ros_respiratoryPneumoniaActive: "active",
  ros_respiratoryPneumoniaHistoryOf: "historyOf",
  ros_respiratoryPneumoniaRuleOut: "ruleOut",
  ros_respiratoryPneumoniaHospitalization: "hospitalization",
  ros_respiratoryPneumoniaPhysicalFindings: "physicalFindings",
  ros_respiratoryPneumoniaImageStudies: "imageStudies",
  ros_respiratoryPneumoniaLabStudies: "labStudies",
  ros_respiratoryPneumoniaShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryPneumoniaWheezing: "wheezing",
  ros_respiratoryPneumoniaChronicCough: "chronicCough",
  ros_respiratoryPneumoniaSubOther: "other",
  ros_respiratoryPneumoniaViral: "viral",
  ros_respiratoryPneumoniaPneumococcal: "pneumococcal",
  ros_respiratoryPneumoniaStaph: "staph",
  ros_respiratoryPneumoniaOtherBacterial: "otherBacterial",
  ros_respiratoryPneumoniaAspiration: "aspiration",
  ros_respiratoryHistoryOfLungAbscessYes: "yes",
  ros_respiratoryHistoryOfLungAbscessNo: "no",
  ros_respiratoryHstoryOfEmpyemaYes: "yes",
  ros_respiratoryHistoryOfEmpyemaNo: "no",

  ros_respiratoryDiagnosesPulmonaryFibrosis: "pulmonaryFibrosis",
  ros_respiratoryPulmonaryFibrosisActive: "active",
  ros_respiratoryPulmonaryFibrosisHistoryOf: "historyOf",
  ros_respiratoryPulmonaryFibrosisRuleOut: "ruleOut",
  ros_respiratoryPulmonaryFibrosisXRay: "xRay",
  ros_respiratoryPulmonaryFibrosisPft: "pft",
  ros_respiratoryPulmonaryFibrosisBiopsy: "biopsy",
  ros_respiratoryPulmonaryFibrosisMedications: "medications",
  ros_respiratoryPulmonaryFibrosisShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryPulmonaryFibrosisWheezing: "wheezing",
  ros_respiratoryPulmonaryFibrosisChronicCough: "chronicCough",
  ros_respiratoryPulmonaryFibrosisSubOther: "other",

  ros_respiratoryDiagnosesRespiratorDependence: "respiratorDependence",
  ros_respiratoryRespiratorDependenceActive: "active",
  ros_respiratoryRespiratorDependenceHistoryOf: "historyOf",
  ros_respiratoryRespiratorDependenceRuleOut: "ruleOut",
  ros_respiratoryRespiratorDependenceHistory: "history",
  ros_respiratoryRespiratorDependenceSymptoms: "symptoms",
  ros_respiratoryRespiratorDependencePhysicalFindings: "physicalFindings",
  ros_respiratoryRespiratorDependenceMedications: "medications",
  ros_respiratoryRespiratorDependenceTestResults: "testResults",
  ros_respiratoryRespiratorDependenceImageStudies: "imageStudies",
  ros_respiratoryRespiratorDependenceBiopsy: "biopsy",
  ros_respiratoryRespiratorDependenceDme: "dme",
  ros_respiratoryRespiratorDependenceShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryRespiratorDependenceWheezing: "wheezing",
  ros_respiratoryRespiratorDependenceChronicCough: "chronicCough",
  ros_respiratoryRespiratorDependenceSubOther: "other",

  ros_respiratoryDiagnosesRespiratoryArrest: "respiratoryArrest",
  ros_respiratoryRespiratoryArrestActive: "active",
  ros_respiratoryRespiratoryArrestHistoryOf: "historyOf",
  ros_respiratoryRespiratoryArrestRuleOut: "ruleOut",
  ros_respiratoryRespiratoryArrestHistoryOfHospitalization:
    "historyOfHospitalization",
  ros_respiratoryRespiratoryArrestUseOfVentilator: "useOfVentilator",
  ros_respiratoryRespiratoryArrestCarbonDioxideRetention:
    "carbonDioxideRetention",
  ros_respiratoryRespiratoryArrestShortnessOfBreath: "shortnessOfBreath",
  ros_respiratoryRespiratoryArrestTestWheezing: "wheezing",
  ros_respiratoryRespiratoryArrestChronicCough: "chronicCough",
  ros_respiratoryRespiratoryArrestSubOther: "other",

  ros_respiratoryDiagnosesSarcoidosis: "sarcoidosis",
  ros_respiratorySarcoidosisActive: "active",
  ros_respiratorySarcoidosisHistoryOf: "historyOf",
  ros_respiratorySarcoidosisRuleOut: "ruleOut",
  ros_respiratorySarcoidosisLung: "lung",
  ros_respiratorySarcoidosisLymphNodes: "lymphNodes",
  ros_respiratorySarcoidosisSkin: "skin",
  ros_respiratorySarcoidosisCranialNerves: "cranialNerves",
  ros_respiratorySarcoidosisTestUnKnown: "unKnown",
  ros_respiratorySarcoidosisDiagnosticResults: "diagnosticResults",
  ros_respiratorySarcoidosisCutaneousLesions: "cutaneousLesions",
  ros_respiratorySarcoidosisShortnessOfBreath: "shortnessOfBreath",
  ros_respiratorySarcoidosisWheezing: "wheezing",
  ros_respiratorySarcoidosisTestChronicCough: "chronicCough",
  ros_respiratorySarcoidosisSubOther: "other",

  ros_respiratoryDiagnosesSleepApnea: "sleepApnea",
  ros_respiratorySleepApneaActive: "active",
  ros_respiratorySleepApneaHistoryOf: "historyOf",
  ros_respiratorySleepApneaRuleOut: "ruleOut",
  ros_respiratorySleepApneaUseOfCpap: "useOfCpap",
  ros_respiratorySleepApneaPositiveSleepStudies: "positiveSleepStudies",
  ros_respiratorySleepApneaHistoryOfSleepiness: "historyOfSleepiness",
  ros_respiratorySleepApneaHeavySnoring: "heavySnoring",
  ros_respiratorySleepApneaShortnessOfBreath: "shortnessOfBreath",
  ros_respiratorySleepApneaWheezing: "wheezing",
  ros_respiratorySleepApneaChronicCough: "chronicCough",
  ros_respiratorySleepApneaSubOther: "other",

  ros_respiratoryDiagnosesOther: "other",
  ros_respiratoryRespOtherSubAnswerDescribeActive: "active",
  ros_respiratoryRespOtherSubAnswerDescribeHistoryOf: "historyOf",
  ros_respiratoryRespOtherSubAnswerDescribeRuleOut: "ruleOut",
  ros_respiratoryRespOtherSubAnswerSupportedByBiopsy: "biopsy",
  ros_respiratoryRespOtherSubAnswerSupportedByDme: "dme",
  ros_respiratoryRespOtherSubAnswerSupportedByHistory: "history",
  ros_respiratoryRespOtherSubAnswerSupportedByImageStudies: "imageStudies",
  ros_respiratoryRespOtherSubAnswerSupportedByMedications: "medications",
  ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings:
    "physicalFindings",
  ros_respiratoryRespOtherSubAnswerSupportedBySymptoms: "symptoms",
  ros_respiratoryRespOtherSubAnswerSupportedByTestResults: "testResults",
  ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath:
    "shortnessOfBreath",
  ros_respiratoryRespOtherSubAnswerSupportedByChronicCough: "chronicCough",
  ros_respiratoryRespOtherSubAnswerSupportedByWheezing: "wheezing",
  ros_respiratoryOtherSubOther: "other",

  //integument
  ros_integumentYes: "yes",
  ros_integumentNo: "no",

  ros_integumentDiagnosesIntegumentBasilCellCarcinoma: "basilCellCarcinoma",
  ros_integumentBasilCellCarcinomaActive: "active",
  ros_integumentBasilCellCarcinomaHistoryOf: "historyOf",
  ros_integumentBasilCellCarcinomaRuleOut: "ruleOut",
  ros_integumentBasilCellCarcinomaHistory: "history",
  ros_integumentBasilCellCarcinomaSymptoms: "symptoms",
  ros_integumentBasilCellCarcinomaPhysicalFindings: "physicalFindings",
  ros_integumentBasilCellCarcinomaMedications: "medications",
  ros_integumentBasilCellCarcinomaTestResults: "testResults",
  ros_integumentBasilCellCarcinomaImageStudies: "imageStudies",
  ros_integumentBasilCellCarcinomaBiopsy: "biopsy",
  ros_integumentBasilCellCarcinomaDme: "dme",
  ros_integumentBasilCellCarcinomaSubOther: "other",

  ros_integumentDiagnosesIntegumentDermatitis: "dermatitis",
  ros_integumentDermatitisActive: "active",
  ros_integumentDermatitisHistoryOf: "historyOf",
  ros_integumentDermatitisRuleOut: "ruleOut",
  ros_integumentDermatitisHistory: "history",
  ros_integumentDermatitisSymptoms: "symptoms",
  ros_integumentDermatitisPhysicalFindings: "physicalFindings",
  ros_integumentDermatitisMedications: "medications",
  ros_integumentDermatitisTestResults: "testResults",
  ros_integumentDermatitisImageStudies: "imageStudies",
  ros_integumentDermatitisBiopsy: "biopsy",
  ros_integumentDermatitisDme: "dme",
  ros_integumentDermatitisSubOther: "other",
  ros_integumentDermatitisContact: "contact",
  ros_integumentDermatitisStasis: "stasis",
  ros_integumentDermatitisDrugInduced: "drugInduced",
  ros_integumentDermatitisDiseaseInduced: "diseaseInduced",
  ros_integumentDermatitisUnspecified: "unspecified",

  ros_integumentDiagnosesIntegumentEczema: "eczema",
  ros_integumentEczemaActive: "active",
  ros_integumentEczemaHistoryOf: "historyOf",
  ros_integumentEczemaRuleOut: "ruleOut",
  ros_integumentEczemaHistory: "history",
  ros_integumentEczemaSymptoms: "symptoms",
  ros_integumentEczemaPhysicalFindings: "physicalFindings",
  ros_integumentEczemaMedications: "medications",
  ros_integumentEczemaTestResults: "testResults",
  ros_integumentEczemaImageStudies: "imageStudies",
  ros_integumentEczemaBiopsy: "biopsy",
  ros_integumentEczemaDme: "dme",
  ros_integumentEczemaSubOther: "other",

  ros_integumentDiagnosesIntegumentOnychomysosis: "onychomycosis",
  ros_integumentOnychomycosisActive: "active",
  ros_integumentOnychomycosisHistoryOf: "historyOf",
  ros_integumentOnychomycosisRuleOut: "ruleOut",
  ros_integumentOnychomycosisHistory: "history",
  ros_integumentOnychomycosisSymptoms: "symptoms",
  ros_integumentOnychomycosisPhysicalFindings: "physicalFindings",
  ros_integumentOnychomycosisMedications: "medications",
  ros_integumentOnychomycosisTestResults: "testResults",
  ros_integumentOnychomycosisImageStudies: "imageStudies",
  ros_integumentOnychomycosisBiopsy: "biopsy",
  ros_integumentOnychomycosisDme: "dme",
  ros_integumentOnychomycosisSubOther: "other",

  ros_integumentDiagnosesIntegumentPsoriasis: "psoriasis",
  ros_integumentPsoriasisActive: "active",
  ros_integumentPsoriasisHistoryOf: "historyOf",
  ros_integumentPsoriasisRuleOut: "ruleOut",
  ros_integumentPsoriasisHistory: "history",
  ros_integumentPsoriasisSymptoms: "symptoms",
  ros_integumentPsoriasisPhysicalFindings: "physicalFindings",
  ros_integumentPsoriasisMedications: "medications",
  ros_integumentPsoriasisTestResults: "testResults",
  ros_integumentPsoriasisImageStudies: "imageStudies",
  ros_integumentPsoriasisBiopsy: "biopsy",
  ros_integumentPsoriasisDme: "dme",
  ros_integumentPsoriasisSubOther: "other",
  ros_integumentPsoriaticArthritisYes: "yes",
  ros_integumentPsoriaticArthritisNo: "no",

  ros_integumentDiagnosesIntegumentSkinUlcer: "skinUlcer",
  ros_integumentSkinUlcerActive: "active",
  ros_integumentSkinUlcerHistoryOf: "historyOf",
  ros_integumentSkinUlcerRuleOut: "ruleOut",
  ros_integumentSkinUlcerHistory: "history",
  ros_integumentSkinUlcerSymptoms: "symptoms",
  ros_integumentSkinUlcerPhysicalFindings: "physicalFindings",
  ros_integumentSkinUlcerMedications: "medications",
  ros_integumentSkinUlcerTestResults: "testResults",
  ros_integumentSkinUlcerImageStudies: "imageStudies",
  ros_integumentSkinUlcerBiopsy: "biopsy",
  ros_integumentSkinUlcerDme: "dme",
  ros_integumentSkinUlcerSubOther: "other",
  ros_integumentSkinUlcerPressure: "pressure",
  ros_integumentSkinUlcerVenousStatis: "venousStatis",
  ros_integumentSkinUlcerPeripheralVascular: "peripheralVascular",
  ros_integumentSkinUlcerDiseaseInduced: "diseaseInduced",
  ros_integumentSkinUlcerDiabeticVasculitis: "diabeticVasculitis",
  ros_integumentSkinUlcerDiabeticNeuropathy: "diabeticNeuropathy",

  ros_integumentDiagnosesIntegumentTineaPedis: "tineaPedis",
  ros_integumentTineaPedisActive: "active",
  ros_integumentTineaPedisHistoryOf: "historyOf",
  ros_integumentTineaPedisRuleOut: "ruleOut",
  ros_integumentTineaPedisHistory: "history",
  ros_integumentTineaPedisSymptoms: "symptoms",
  ros_integumentTineaPedisPhysicalFindings: "physicalFindings",
  ros_integumentTineaPedisMedications: "medications",
  ros_integumentTineaPedisTestResults: "testResults",
  ros_integumentTineaPedisImageStudies: "imageStudies",
  ros_integumentTineaPedisBiopsy: "biopsy",
  ros_integumentTineaPedisDme: "dme",
  ros_integumentTineaPedisSubOther: "other",

  ros_integumentDiagnosesIntegumentUrticarialDisease: "urticarialDisease",
  ros_integumentUrticarialDiseaseActive: "active",
  ros_integumentUrticarialDiseaseHistoryOf: "historyOf",
  ros_integumentUrticarialDiseaseRuleOut: "ruleOut",
  ros_integumentUrticarialDiseaseHistory: "history",
  ros_integumentUrticarialDiseaseSymptoms: "symptoms",
  ros_integumentUrticarialDiseasePhysicalFindings: "physicalFindings",
  ros_integumentUrticarialDiseaseMedications: "medications",
  ros_integumentUrticarialDiseaseTestResults: "testResults",
  ros_integumentUrticarialDiseaseImageStudies: "imageStudies",
  ros_integumentUrticarialDiseaseBiopsy: "biopsy",
  ros_integumentUrticarialDiseaseDme: "dme",
  ros_integumentUrticarialDiseaseSubOther: "other",
  ros_integumentUrticarialDiseaseAcute: "acute",
  ros_integumentUrticarialDiseaseChronic: "chronic",

  ros_integumentDiagnosesIntegumentWound: "wound",
  ros_integumentWoundActive: "active",
  ros_integumentWoundHistoryOf: "historyOf",
  ros_integumentWoundRuleOut: "ruleOut",
  ros_integumentWoundHistory: "history",
  ros_integumentWoundSymptoms: "symptoms",
  ros_integumentWoundPhysicalFindings: "physicalFindings",
  ros_integumentWoundMedications: "medications",
  ros_integumentWoundTestResults: "testResults",
  ros_integumentWoundImageStudies: "imageStudies",
  ros_integumentWoundBiopsy: "biopsy",
  ros_integumentWoundDme: "dme",
  ros_integumentWoundSubOther: "other",
  ros_integumentWoundSurgical: "surgical",
  ros_integumentWoundTraumatic: "traumatic",
  ros_integumentWoundBurn: "burn",

  ros_integumentDiagnosesIntegumentOther: "other",
  ros_integumentIntegumentOtherSubOther: "other",
  ros_integumentOthersSubAnswerDescribeActive: "active",
  ros_integumentOthersSubAnswerDescribeHistoryOf: "historyOf",
  ros_integumentOthersSubAnswerDescribeRuleOut: "ruleOut",
  ros_integumentOthersSubAnswerSupportedByHistory: "history",
  ros_integumentOthersSubAnswerSupportedBySymptoms: "symptoms",
  ros_integumentOthersSubAnswerSupportedByPhysicalFindings: "physicalFindings",
  ros_integumentOthersSubAnswerSupportedByMedications: "medications",
  ros_integumentOthersSubAnswerSupportedByTestResults: "testResults",
  ros_integumentOthersSubAnswerSupportedByImageStudies: "imageStudies",
  ros_integumentOthersSubAnswerSupportedByBiopsy: "biopsy",
  ros_integumentOthersSubAnswerSupportedByDme: "dme",

  //musko
  ros_musculoskeletalYes: "yes",
  ros_musculoskeletalNo: "no",

  ros_musculoskeletalDiagnosesMuskoCollagen: "collagen",
  ros_musculoskeletalCollagenActive: "active",
  ros_musculoskeletalCollagenHistoryOf: "historyOf",
  ros_musculoskeletalCollagenRuleOut: "ruleOut",
  ros_musculoskeletalCollagenPhysicalFindings: "physicalFindings",
  ros_musculoskeletalCollagenLabTests: "labTests",
  ros_musculoskeletalCollagenBiopsy: "biopsy",
  ros_musculoskeletalCollagenImageStudies: "imageStudies",
  ros_musculoskeletalCollagenSubOther: "other",
  ros_musculoskeletalCollagenSleLupus: "sleLupus",
  ros_musculoskeletalCollagenScleroderma: "scleroderma",
  ros_musculoskeletalCollagenDermatomyositis: "dermatomyositis",

  ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease:
    "degenerativeDiscDisease",
  ros_musculoskeletalDegenerativeDiscDiseaseActive: "active",
  ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf: "historyOf",
  ros_musculoskeletalDegenerativeDiscDiseaseRuleOut: "ruleOut",
  ros_musculoskeletalDegenerativeDiscDiseaseSymptoms: "symptoms",
  ros_musculoskeletalDegenerativeDiscDiseasePhysicalFindings:
    "physicalFindings",
  ros_musculoskeletalDegenerativeDiscDiseaseImageStudies: "imageStudies",
  ros_musculoskeletalDegenerativeDiscDiseaseMedications: "medications",
  ros_musculoskeletalDegenerativeDiscDiseaseSubOther: "other",
  ros_musculoskeletalNormalBladderYes: "yes",
  ros_musculoskeletalNormalBladderNo: "no",
  ros_musculoskeletalSiteOfDiseaseCervical: "cervical",
  ros_musculoskeletalSiteOfDiseaseThoracic: "thoracic",
  ros_musculoskeletalSiteOfDiseaseLumbar: "lumbar",
  ros_musculoskeletalSiteOfDiseaseLumbosacral: "lumbosacral",
  ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoExtremityFracture: "extremityFracture",
  ros_musculoskeletalExtremityFractureSubAnswerDescribeActive: "active",
  ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalExtremityFractureSubAnswerDescribeRuleOut: "ruleOut",
  ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic: "traumatic",
  ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological:
    "pathological",
  ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic: "traumatic",
  ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological:
    "pathological",
  ros_musculoskeletalExtremityFractureHistory: "history",
  ros_musculoskeletalExtremityFractureSymptoms: "symptoms",
  ros_musculoskeletalExtremityFracturePhysicalFindings: "physicalFindings",
  ros_musculoskeletalExtremityFractureMedications: "medications",
  ros_musculoskeletalExtremityFractureTestResults: "testResults",
  ros_musculoskeletalExtremityFractureImageStudies: "imageStudies",
  ros_musculoskeletalExtremityFractureBiopsy: "biopsy",
  ros_musculoskeletalExtremityFractureDme: "dme",
  ros_musculoskeletalExtremityFractureOther: "other",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder: "shoulder",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityArm: "arm",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm: "forearm",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist: "wrist",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityHand: "hand",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft:
    "femoralShaft",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia: "tibia",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula: "fibula",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle: "ankle",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot: "foot",
  ros_musculoskeletalExtremityFractureSubAnswerExtremityHip: "hip",
  ros_musculoskeletalExtremityFractureShoulderLeft: "left",
  ros_musculoskeletalExtremityFractureShoulderRight: "right",
  ros_musculoskeletalExtremityFractureArmLeft: "left",
  ros_musculoskeletalExtremityFractureArmRight: "right",
  ros_musculoskeletalExtremityFractureForearmLeft: "left",
  ros_musculoskeletalExtremityFractureForearmRight: "right",
  ros_musculoskeletalExtremityFractureWristLeft: "left",
  ros_musculoskeletalExtremityFractureWristRight: "right",
  ros_musculoskeletalExtremityFractureHandLeft: "left",
  ros_musculoskeletalExtremityFractureHandRight: "right",
  ros_musculoskeletalExtremityFractureFemoralShaftLeft: "left",
  ros_musculoskeletalExtremityFractureFemoralShaftRight: "right",
  ros_musculoskeletalExtremityFractureTibiaLeft: "left",
  ros_musculoskeletalExtremityFractureTibiaRight: "right",
  ros_musculoskeletalExtremityFractureFibulaLeft: "left",
  ros_musculoskeletalExtremityFractureFibulaRight: "right",
  ros_musculoskeletalExtremityFractureAnkleLeft: "left",
  ros_musculoskeletalExtremityFractureAnkleRight: "right",
  ros_musculoskeletalExtremityFractureFootLeft: "left",
  ros_musculoskeletalExtremityFractureFootRight: "right",
  ros_musculoskeletalExtremityFractureHipLeft: "left",
  ros_musculoskeletalExtremityFractureHipRight: "right",
  ros_musculoskeletalCurrentWithinTwelveWeeksYes: "yes",
  ros_musculoskeletalCurrentWithinTwelveWeeksNo: "no",

  ros_musculoskeletalDiagnosesMuskoGout: "gout",
  ros_musculoskeletalGoutActive: "active",
  ros_musculoskeletalGoutHistoryOf: "historyOf",
  ros_musculoskeletalGoutRuleOut: "ruleOut",
  ros_musculoskeletalGoutHistoryOfAttacks: "historyOfAttacks",
  ros_musculoskeletalGoutLabTests: "labTests",
  ros_musculoskeletalGoutMedications: "medications",
  ros_musculoskeletalGoutSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoHalluxValgus: "halluxValgus",
  ros_musculoskeletalHalluxValgusSubAnswerDescribeActive: "active",
  ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalHalluxValgusSubAnswerDescribeRuleOut: "ruleOut",
  ros_musculoskeletalHalluxValgusHistory: "history",
  ros_musculoskeletalHalluxValgusSymptoms: "symptoms",
  ros_musculoskeletalHalluxValgusPhysicalFindings: "physicalFindings",
  ros_musculoskeletalHalluxValgusMedications: "medications",
  ros_musculoskeletalHalluxValgusTestResults: "testResults",
  ros_musculoskeletalHalluxValgusImageStudies: "imageStudies",
  ros_musculoskeletalHalluxValgusBiopsy: "biopsy",
  ros_musculoskeletalHalluxValgusDme: "dme",
  ros_musculoskeletalHalluxValgusSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoHammerToes: "hammerToes",
  ros_musculoskeletalHammerToesSubAnswerDescribeActive: "active",
  ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalHammerTsoesSubAnswerDescribeRuleOut: "ruleOut",
  ros_musculoskeletalHammerToesHistory: "history",
  ros_musculoskeletalHammerToesSymptoms: "symptoms",
  ros_musculoskeletalHammerToesPhysicalFindings: "physicalFindings",
  ros_musculoskeletalHammerToesMedications: "medications",
  ros_musculoskeletalHammerToesTestResults: "testResults",
  ros_musculoskeletalHammerToesImageStudies: "imageStudies",
  ros_musculoskeletalHammerToesBiopsy: "biopsy",
  ros_musculoskeletalHammerToesDme: "dme",
  ros_musculoskeletalHammerToesSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoOsteoarthritis: "osteoarthritis",
  ros_musculoskeletalOsteoarthritisActive: "active",
  ros_musculoskeletalOsteoarthritisHistoryOf: "historyOf",
  ros_musculoskeletalOsteoarthritisRuleOut: "ruleOut",
  // ros_musculoskeletalOsteoarthritisHistory: "history",
  ros_musculoskeletalOsteoarthritisSymptoms: "symptoms",
  ros_musculoskeletalOsteoarthritisPhysicalFindings: "physicalFindings",
  ros_musculoskeletalOsteoarthritisImageStudies: "imageStudies",
  ros_musculoskeletalOsteoarthritisSubOther: "other",
  ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointSwelling:
    "jointSwelling",
  ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointStiffness:
    "jointStiffness",
  ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerPain: "pain",
  ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerLimitedRom:
    "limitedRom",

  ros_musculoskeletalDiagnosesMuskoOsteomyelitis: "osteomyelitis",
  ros_musculoskeletalOsteomyelitisActive: "active",
  ros_musculoskeletalOsteomyelitisHistoryOf: "historyOf",
  ros_musculoskeletalOsteomyelitisRuleOut: "ruleOut",
  ros_musculoskeletalOsteomyelitisHospitalization: "hospitalization",
  ros_musculoskeletalOsteomyelitisImageStudies: "imageStudies",
  ros_musculoskeletalOsteomyelitisCultures: "cultures",
  ros_musculoskeletalOsteomyelitisMedications: "medications",
  ros_musculoskeletalOsteomyelitisSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoOsteoporosis: "osteoporosis",
  ros_musculoskeletalOsteoporosisActive: "active",
  ros_musculoskeletalOsteoporosisHistoryOf: "historyOf",
  ros_musculoskeletalOsteoporosisRuleOut: "ruleOut",
  ros_musculoskeletalOsteoporosisDexaScan: "dexaScan",
  ros_musculoskeletalOsteoporosisMedications: "medications",
  ros_musculoskeletalOsteoporosisImagingStudies: "imagingStudies",
  ros_musculoskeletalOsteoporosisSymptoms: "symptoms",
  ros_musculoskeletalOsteoporosisFractureHistory: "fractureHistory",
  ros_musculoskeletalOsteoporosisSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoPyogenicArthritis: "pyogenicArthritis",
  ros_musculoskeletalPyogenicArthritisActive: "active",
  ros_musculoskeletalPyogenicArthritisHistoryOf: "historyOf",
  ros_musculoskeletalPyogenicArthritisRuleOut: "ruleOut",
  ros_musculoskeletalPyogenicArthritisHospitalization: "hospitalization",
  ros_musculoskeletalPyogenicArthritisImageStudies: "imageStudies",
  ros_musculoskeletalPyogenicArthritisCultures: "cultures",
  ros_musculoskeletalPyogenicArthritisMedications: "medications",
  ros_musculoskeletalPyogenicArthritisSubOther: "other",
  ros_musculoskeletalPyogenicArthritisWhichJoints: "whichJoints",

  ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis: "rheumatoidArthritis",
  ros_musculoskeletalRheumatoidArthritisActive: "active",
  ros_musculoskeletalRheumatoidArthritisHistoryOf: "historyOf",
  ros_musculoskeletalRheumatoidArthritisRuleOut: "ruleOut",
  ros_musculoskeletalRheumatoidArthritisSymptoms: "symptoms",
  ros_musculoskeletalRheumatoidArthritisPhysicalFindings: "physicalFindings",
  ros_musculoskeletalRheumatoidArthritisMedications: "medications",
  ros_musculoskeletalRheumatoidArthritisLabTests: "labTests",
  ros_musculoskeletalRheumatoidArthritisImageStudies: "imageStudies",
  ros_musculoskeletalRheumatoidArthritisSubOther: "other",
  ros_musculoskeletalRheumatoidArthritisJointSwelling: "jointSwelling",
  ros_musculoskeletalRheumatoidArthritisJointStiffness: "jointStiffness",
  ros_musculoskeletalRheumatoidArthritisPain: "pain",
  ros_musculoskeletalRheumatoidArthritisLimitedRom: "limitedRom",

  ros_musculoskeletalDiagnosesMuskoSpinalStenosis: "spinalStenosis",
  ros_musculoskeletalSpinalStenosisActive: "active",
  ros_musculoskeletalSpinalStenosisHistoryOf: "historyOf",
  ros_musculoskeletalSpinalStenosisRuleOut: "ruleOut",
  ros_musculoskeletalSpinalStenosisSymptoms: "symptoms",
  ros_musculoskeletalSpinalStenosisPhysicalFindings: "physicalFindings",
  ros_musculoskeletalSpinalStenosisImageStudies: "imageStudies",
  ros_musculoskeletalSpinalStenosisMedications: "medications",
  ros_musculoskeletalSpinalStenosisSubOther: "other",
  ros_musculoskeletalSpinalStenosisNormalBladderYes: "yes",
  ros_musculoskeletalSpinalStenosisNormalBladderNo: "no",

  ros_musculoskeletalDiagnosesMuskoSystemicLupus: "systemicLupus",
  ros_musculoskeletalSystemicLupusActive: "active",
  ros_musculoskeletalSystemicLupusHistoryOf: "historyOf",
  ros_musculoskeletalSystemicLupusRuleOut: "ruleOut",
  ros_musculoskeletalSystemicLupusLabs: "labs",
  ros_musculoskeletalSystemicLupusMedications: "medications",
  ros_musculoskeletalSystemicLupusHistory: "history",
  ros_musculoskeletalSystemicLupusSubOther: "other",

  ros_musculoskeletalDiagnosesMuskoOther: "other",
  ros_musculoskeletalMuskoSubOther: "other",
  ros_musculoskeletalMuskoOtherSubAnswerDescribeActive: "active",
  ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf: "historyOf",
  ros_musculoskeletalMuskoOtherSubAnswerDescribeRuleOut: "ruleOut",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByHistory: "history",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedBySymptoms: "symptoms",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByPhysicalFindings:
    "physicalFindings",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByMedications: "medications",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByTestResults: "testResults",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByImageStudies: "imageStudies",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByBiopsy: "biopsy",
  ros_musculoskeletalMuskoOtherSubAnswerSupportedByDme: "dme",

  ros_musculoskeletalHadAnAmputationYes: "yes",
  ros_musculoskeletalHadAnAmputationNo: "no",

  //hematology
  ros_hematologyYes: "yes",
  ros_hematologyNo: "no",

  ros_hematologyDiagnosesHemaAids: "aids",
  ros_hematologyAidsActive: "active",
  ros_hematologyAidsHistoryOf: "historyOf",
  ros_hematologyAidsRuleOut: "ruleOut",
  ros_hematologyAidsSymptoms: "symptoms",
  ros_hematologyAidsPhysicalFindings: "physicalFindings",
  ros_hematologyAidsHistoryOfOpportunistic: "historyOfOpportunistic",
  ros_hematologyAidsMedications: "medications",
  ros_hematologyAidsSubOther: "other",
  ros_hematologyAidsPatientUnderTreatmentYes: "yes",
  ros_hematologyAidsPatientUnderTreatmentNo: "no",

  ros_hematologyDiagnosesHemaAnemia: "anemia",
  ros_hematologyAnemiaActive: "active",
  ros_hematologyAnemiaHistoryOf: "historyOf",
  ros_hematologyAnemiaRuleOut: "ruleOut",
  ros_hematologyAnemiaLabTests: "labTests",
  ros_hematologyAnemiaSymptoms: "symptoms",
  ros_hematologyAnemiaHistoryOfBloodTransfusion: "historyOfBloodTransfusion",
  ros_hematologyAnemiaSubother: "other",
  ros_hematologyAnemiaIronDeficiency: "ironDeficiency",
  ros_hematologyAnemiaPernicious: "pernicious",
  ros_hematologyAnemiaKidneyDisease: "kidneyDisease",
  ros_hematologyAnemiaHemolysis: "hemolysis",
  ros_hematologyAnemiaAplastic: "aplastic",
  ros_hematologyAnemiaChemotherapy: "chemotherapy",
  ros_hematologyAnemiaBloodLoss: "bloodLoss",
  ros_hematologyAnemiaChronicDisease: "chronicDisease",
  ros_hematologyAnemiaFolateDeficiency: "folateDeficiency",
  ros_hematologyAnemiaSubEtiologySubOther: "other",
  ros_hematologyAnemiaIron: "iron",
  ros_hematologyAnemiaBTwelve: "bTwelve",
  ros_hematologyAnemiaFolicAcid: "folicAcid",
  ros_hematologyAnemiaBloodTransfusions: "bloodTransfusions",
  ros_hematologyAnemiaSubYesPatientOn: "other",

  ros_hematologyDiagnosesHemaCDifficile: "cDifficile",
  ros_hematologyCDifficileActive: "active",
  ros_hematologyCDifficileHistoryOf: "historyOf",
  ros_hematologyCDifficileRuleOut: "ruleOut",
  ros_hematologyCDifficilePositiveLabTest: "positiveLabTest",
  ros_hematologyCDifficileSymptoms: "symptoms",
  ros_hematologyCDifficileHospitalization: "hospitalization",
  ros_hematologyCDifficileMedication: "medication",
  ros_hematologyCDifficileSubOther: "other",

  ros_hematologyDiagnosesHemaCommunityAcquired: "communityAcquired",
  ros_hematologyCommunityAcquiredActive: "active",
  ros_hematologyCommunityAcquiredHistoryOf: "historyOf",
  ros_hematologyCommunityAcquiredRuleOut: "ruleOut",
  ros_hematologyCommunityAcquiredCultures: "cultures",
  ros_hematologyCommunityAcquiredHospitalization: "hospitalization",
  ros_hematologyCommunityAcquiredMedications: "medications",
  ros_hematologyCommunityAcquiredPhysicalFindings: "physicalFindings",
  ros_hematologyCommunityAcquiredSubOther: "other",

  ros_hematologyDiagnosesHemaHiv: "hiv",
  ros_hematologyHivActive: "active",
  ros_hematologyHivHistoryOf: "historyOf",
  ros_hematologyHivRuleOut: "ruleOut",
  ros_hematologyHivLabTests: "labTests",
  ros_hematologyHivSymptoms: "symptoms",
  ros_hematologyHivdMedications: "medications",
  ros_hematologyHivSubOther: "other",
  //  hivViralLoad: "viralLoad",
  //  hivdC4: "C4",
  ros_hematologyHivPatientSymptomaticYes: "yes",
  ros_hematologyHivPatientSymptomaticNo: "no",
  ros_hematologyHivActiveTreatmentYes: "yes",
  ros_hematologyHivActiveTreatmentNo: "no",

  ros_hematologyDiagnosesHemaHerpesZoster: "herpesZoster",
  ros_hematologyHerpesZosterActive: "active",
  ros_hematologyHerpesZosterHistoryOf: "historyOf",
  ros_hematologyHerpesZosterRuleOut: "ruleOut",
  ros_hematologyHerpesZosterRash: "rash",
  ros_hematologyHerpesZosterSymptoms: "symptoms",
  ros_hematologyHerpesZosterMedications: "medications",
  ros_hematologyHerpesZosterSubOther: "other",

  ros_hematologyDiagnosesHemaMrsaInfection: "mrsaInfection",
  ros_hematologyMrsaInfectionActive: "active",
  ros_hematologyMrsaInfectionHistoryOf: "historyOf",
  ros_hematologyMrsaInfectionRuleOut: "ruleOut",
  ros_hematologyMrsaInfectionCultures: "cultures",
  ros_hematologyMrsaInfectionHospitalization: "hospitalization",
  ros_hematologyMrsaInfectionMedications: "medications",
  ros_hematologyMrsaInfectionPhysicalFindings: "physicalFindings",
  ros_hematologyMrsaInfectionSubOther: "other",

  ros_hematologyDiagnosesHemaImmuneDeficiency: "immuneDeficiency",
  ros_hematologyImmuneDeficiencyActive: "active",
  ros_hematologyImmuneDeficiencyHistoryOf: "historyOf",
  ros_hematologyImmuneDeficiencyRuleOut: "ruleOut",
  ros_hematologyImmuneDeficiencyHistory: "history",
  ros_hematologyImmuneDeficiencySymptoms: "symptoms",
  ros_hematologyImmuneDeficiencyPhysicalFindings: "physicalFindings",
  ros_hematologyImmuneDeficiencyMedications: "medications",
  ros_hematologyImmuneDeficiencyTestResults: "testResults",

  ros_hematologyImmuneDeficiencyImageStudies: "imageStudies",
  ros_hematologyImmuneDeficiencySubOther: "other",
  //  immuneDeficiencyEtiology: "etiology",
  ros_hematologyImmuneDeficiencyHistoryOfOpportunisticYes: "yes",
  ros_hematologyImmuneDeficiencyHistoryOfOpportunisticNo: "no",

  ros_hematologyDiagnosesHemaSepsis: "sepsis",
  ros_hematologySepsisActive: "active",
  ros_hematologySepsisHistoryOf: "historyOf",
  ros_hematologySepsisRuleOut: "ruleOut",
  ros_hematologySepsisBloodCultures: "bloodCultures",
  ros_hematologySepsisOtherCultures: "otherCultures",
  ros_hematologySepsisSymptoms: "symptoms",
  ros_hematologySepsisHospitalization: "hospitalization",
  ros_hematologySepsisUnstableVitalSigns: "unstableVitalSigns",
  ros_hematologySepsisSubOther: "other",

  ros_hematologyDiagnosesHemaSickleCellDisease: "sickleCellDisease",
  ros_hematologySickleCellDiseaseActive: "active",
  ros_hematologySickleCellDiseaseHistoryOf: "historyOf",
  ros_hematologySickleCellDiseaseRuleOut: "ruleOut",
  ros_hematologySickleCellDiseaseFamilyHistory: "familyHistory",
  ros_hematologySickleCellDiseaseSymptoms: "symptoms",
  ros_hematologySickleCellDiseaseHistoryOfInfections: "historyOfInfections",
  ros_hematologySickleCellDiseaseHospitalizations: "hospitalizations",
  ros_hematologySickleCellDiseaseSubOther: "other",

  ros_hematologyDiagnosesHemaSickleCellTrait: "sickleCellTrait",
  ros_hematologySickleCellTraitActive: "active",
  ros_hematologySickleCellTraitHistoryOf: "historyOf",
  ros_hematologySickleCellTraitRuleOut: "ruleOut",
  ros_hematologySickleCellTraitFamilyHistory: "familyHistory",
  ros_hematologySickleCellTraitLabTest: "labTest",
  ros_hematologySickleCellTraitSubOther: "other",

  ros_hematologyDiagnosesHemaThalassemia: "thalassemia",
  ros_hematologyThalassemiaActive: "active",
  ros_hematologyThalassemiaHistoryOf: "historyOf",
  ros_hematologyThalassemiaRuleOut: "ruleOut",
  ros_hematologyThalassemiaFamilyHistory: "familyHistory",
  ros_hematologyThalassemiaSymptoms: "symptoms",
  ros_hematologyThalassemiaLabTests: "labTests",
  ros_hematologyThalassemiaHistoryOfInfections: "historyOfInfections",
  ros_hematologyThalassemiaSubOther: "other",

  ros_hematologyDiagnosesHemaThrombocytopenia: "thrombocytopenia",
  ros_hematologyThrombocytopeniaActive: "active",
  ros_hematologyThrombocytopeniaHistoryOf: "historyOf",
  ros_hematologyThrombocytopeniaRuleOut: "ruleOut",
  ros_hematologyThrombocytopeniaLabTests: "labTests",
  ros_hematologyThrombocytopeniaPhysicalFindings: "physicalFindings",
  ros_hematologyThrombocytopeniaHistory: "history",
  ros_hematologyThrombocytopeniaSubOther: "other",
  //  thrombocytopeniaEtiology: "etiology",

  ros_hematologyDiagnosesHemaTuberculosis: "tuberculosis",
  ros_hematologyTuberculosisActive: "active",
  ros_hematologyTuberculosishistoryOfActiveTb: "historyOfActiveTb",
  ros_hematologyTuberculosistbInfection: "tbInfection",
  ros_hematologyTuberculosisruleOutActiveTb: "ruleOutActiveTb",
  ros_hematologyTuberculosisHistory: "history",
  ros_hematologyTuberculosisMedications: "medications",
  ros_hematologyTuberculosisImaginingStudy: "imaginingStudy",
  ros_hematologyTuberculosisSkinTest: "skinTest",
  ros_hematologyTuberculosisSymptoms: "symptoms",
  ros_hematologyTuberculosisPositiveCulture: "positiveCulture",
  ros_hematologyTuberculosisSubOther: "other",
  ros_hematologyTuberculosisGivenBCGYes: "yes",
  ros_hematologyTuberculosisGivenBCGNo: "no",
  ros_hematologyTuberculosisActiveTuberculosisYes: "yes",
  ros_hematologyTuberculosisActiveTuberculosisNo: "no",
  ros_hematologyTuberculosisTbInfectionYes: "yes",
  ros_hematologyTuberculosisTbInfectionNo: "no",

  ros_hematologyDiagnosesHemaVitaminDDeficiency: "vitaminDDeficiency",
  ros_hematologyVitaminDDeficiencyActive: "active",
  ros_hematologyVitaminDDeficiencyHistoryOf: "historyOf",
  ros_hematologyVitaminDDeficiencyRuleOut: "ruleOut",
  ros_hematologyVitaminDDeficiencyLabs: "labs",
  ros_hematologyVitaminDDeficiencyMedications: "medications",
  ros_hematologyVitaminDDeficiencyHistory: "history",
  ros_hematologyVitaminDDeficiencySubOther: "other",

  ros_hematologyHemaOtherSubOther: "other",
  ros_hematologyHemaDiagnosesOtherSubOther: "other",
  ros_hematologyOtherActive: "active",
  ros_hematologyOtherHistoryOf: "historyOf",
  ros_hematologyOtherRuleOut: "ruleOut",
  ros_hematologyOtherHistory: "history",
  ros_hematologyOtherSymptoms: "symptoms",
  ros_hematologyOtherPhysicalFindings: "physicalFindings",
  ros_hematologyOtherMedications: "medications",
  ros_hematologyOtherTestResults: "testResults",
  ros_hematologyOtherImageStudies: "imageStudies",
  ros_hematologyOtherBiopsy: "biopsy",
  ros_hematologyOtherDme: "dme",
};

const ros_OptionsTreeWrappers_mehak = [
  //gait
  [
    "ros_gait",
    [
      "ros_gaitNormal",
      "ros_gaitLimp",
      "ros_gaitWideBased",
      "ros_gaitAbductorLurch",
      "ros_gaitParetic",
      "ros_gaitShuffling",
      "ros_gaitAtaxic",
      "ros_gaitOther",
    ],
  ],

  //respiratory
  ["ros_respiratory", ["ros_respiratoryYes", "ros_respiratoryNo"]],

  [
    "ros_respiratoryDiagnoses",
    [
      "ros_respiratoryDiagnosesAcutePulmonary",
      "ros_respiratoryDiagnosesAcuteUpper",
      "ros_respiratoryDiagnosesAsthma",
      "ros_respiratoryDiagnosesChronicPulmonaryEmbolism",
      "ros_respiratoryDiagnosesChronicRespiratoryFailure",
      "ros_respiratoryDiagnosesChronicSputumFailure",
      "ros_respiratoryDiagnosesCopd",
      "ros_respiratoryDiagnosesCysticFibrosis",
      "ros_respiratoryDiagnosesHypoventilation",
      "ros_respiratoryDiagnosesHypoxemia",
      "ros_respiratoryDiagnosesPneumonia",
      "ros_respiratoryDiagnosesPulmonaryFibrosis",
      "ros_respiratoryDiagnosesRespiratorDependence",
      "ros_respiratoryDiagnosesRespiratoryArrest",
      "ros_respiratoryDiagnosesSarcoidosis",
      "ros_respiratoryDiagnosesSleepApnea",
      "ros_respiratoryDiagnosesOther",
    ],
  ],

  [
    "ros_respiratoryAcutePulmonarySubAnswerDescribe",
    [
      "ros_respiratoryAcutePulmonaryActive",
      "ros_respiratoryAcutePulmonaryHistoryOf",
      "ros_respiratoryAcutePulmonaryruleOut",
    ],
  ],
  [
    "ros_respiratoryAcutePulmonarySubAnswerSupportedBy",
    [
      "ros_respiratoryAcutePulmonaryChronicCough",
      "ros_respiratoryAcutePulmonaryCtAngiogram",
      "ros_respiratoryAcutePulmonaryDDimer",
      "ros_respiratoryAcutePulmonaryHospitalization",
      "ros_respiratoryAcutePulmonaryShortnessOfBreath",
      "ros_respiratoryAcutePulmonaryUseOfAnticoagulation",
      "ros_respiratoryAcutePulmonaryVenousDoppler",
      "ros_respiratoryAcutePulmonaryVqScan",
      "ros_respiratoryAcutePulmonaryWheezing",
      "ros_respiratoryAcutePulmonarySubOther",
    ],
  ],

  [
    "ros_respiratoryAcuteUpperSubAnswerDescribe",
    [
      "ros_respiratoryAcuteUpperActive",
      "ros_respiratoryAcuteUpperHistoryOf",
      "ros_respiratoryAcuteUpperRuleOut",
    ],
  ],
  [
    "ros_respiratoryAcuteUpperSubAnswerSupportedBy",
    [
      "ros_respiratoryAcuteUpperChestXRay",
      "ros_respiratoryAcuteUpperChills",
      "ros_respiratoryAcuteUpperChronicCough",
      "ros_respiratoryAcuteUpperCough",
      "ros_respiratoryAcuteUpperDecreasedBreathe",
      "ros_respiratoryAcuteUpperFever",
      "ros_respiratoryAcuteUpperRales",
      "ros_respiratoryAcuteUpperShortnessOfBreath",
      "ros_respiratoryAcuteUpperWheezing",
      "ros_respiratoryAcuteUpperSubOther",
    ],
  ],

  [
    "ros_respiratoryAsthmaSubAnswerDescribe",
    [
      "ros_respiratoryAsthmaActive",
      "ros_respiratoryAsthmaHistoryOf",
      "ros_respiratoryAsthmaRuleOut",
    ],
  ],
  [
    "ros_respiratoryAsthmaSubAnswerSupportedBy",
    [
      "ros_respiratoryAsthmaChronicCough",
      "ros_respiratoryAsthmaCyanosis",
      "ros_respiratoryAsthmaUseOfBronchodilator",
      "ros_respiratoryAsthmaUseOfInhaled",
      "ros_respiratoryAsthmaUseOfVentilator",
      "ros_respiratoryAsthmaShortnessOfBreath",
      "ros_respiratoryAsthmaWheezing",
      "ros_respiratoryAsthmaSubOther",
    ],
  ],
  [
    "ros_respiratoryAsthmaPatientController",
    [
      "ros_respiratoryAsthmaPatientControllerYes",
      "ros_respiratoryAsthmaPatientControllerNo",
    ],
  ],
  [
    "ros_respiratoryAsthmaRescueMedications",
    [
      "ros_respiratoryAsthmaRescueMedicationsYes",
      "ros_respiratoryAsthmaRescueMedicationsNo",
    ],
  ],
  [
    "ros_respiratoryAsthmaCurrentExacerbation",
    [
      "ros_respiratoryAsthmaCurrentExacerbationYes",
      "ros_respiratoryAsthmaCurrentExacerbationNo",
    ],
  ],
  [
    "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbation",
    [
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationWheezing",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationShortnessOfBreath",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfRescue",
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationUseOfVentilator",
    ],
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilator",
    [
      "ros_respiratoryCopdSubPatientBronchodilatorInhaled",
      "ros_respiratoryCopdSubPatientBronchodilatorNebulizer",
      "ros_respiratoryCopdSubPatientBronchodilatorOral",
    ],
  ],

  [
    "ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribe",
    [
      "ros_respiratoryChronicPulmonaryEmbolismActive",
      "ros_respiratoryChronicPulmonaryEmbolismHistoryOf",
      "ros_respiratoryChronicPulmonaryEmbolismRuleOut",
    ],
  ],
  [
    "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedBy",
    [
      "ros_respiratoryChronicPulmonaryEmbolismHistoryOfPul",
      "ros_respiratoryChronicPulmonaryEmbolismInsersionOfVena",
      "ros_respiratoryChronicPulmonaryEmbolismAnticoagulation",
      "ros_respiratoryChronicPulmonaryEmbolismShortnessOfBreath",
      "ros_respiratoryChronicPulmonaryEmbolismWheezing",
      "ros_respiratoryChronicPulmonaryEmbolismChronicCough",
      "ros_respiratoryChronicPulmonaryEmbolismSubOther",
    ],
  ],

  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe",
    [
      "ros_respiratoryChronicRespiratoryFailureActive",
      "ros_respiratoryChronicRespiratoryFailureHistoryOf",
      "ros_respiratoryChronicRespiratoryFailureRuleOut",
    ],
  ],
  [
    "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedBy",
    [
      "ros_respiratoryChronicRespiratoryFailureHistoryOfHospitalization",
      "ros_respiratoryChronicRespiratoryFailureChronicUseOfO2",
      "ros_respiratoryChronicRespiratoryFailureCarbonDioxideRetention",
      "ros_respiratoryChronicRespiratoryFailureShortnessOfBreath",
      "ros_respiratoryChronicRespiratoryFailureWheezing",
      "ros_respiratoryChronicRespiratoryFailureChronicCough",
      "ros_respiratoryChronicRespiratoryFailureUseOfVentilator",
      "ros_respiratoryChronicRespiratoryFailureSubOther",
    ],
  ],

  [
    "ros_respiratoryChronicSputumFailureSubAnswerDescribe",
    [
      "ros_respiratoryChronicSputumFailureActive",
      "ros_respiratoryChronicSputumFailureHistoryOf",
      "ros_respiratoryChronicSputumFailureRuleOut",
    ],
  ],
  [
    "ros_respiratoryChronicSputumFailureSubAnswerSupportedBy",
    [
      "ros_respiratoryChronicSputumFailureHistory",
      "ros_respiratoryChronicSputumFailureSymptoms",
      "ros_respiratoryChronicSputumFailurePhysicalFindings",
      "ros_respiratoryChronicSputumFailureMedications",
      "ros_respiratoryChronicSputumFailureTestResults",
      "ros_respiratoryChronicSputumFailureImageStudies",
      "ros_respiratoryChronicSputumFailureBiopsy",
      "ros_respiratoryChronicSputumFailureDme",
      "ros_respiratoryChronicSputumFailureShortnessOfBreath",
      "ros_respiratoryChronicSputumFailureChronicCough",
      "ros_respiratoryChronicSputumFailureWheezing",
      "ros_respiratoryChronicSputumFailureSubOther",
    ],
  ],

  [
    "ros_respiratoryCopdSubAnswerDescribe",
    [
      "ros_respiratoryCopdActive",
      "ros_respiratoryCopdHistoryOf",
      "ros_respiratoryCopdRuleOut",
    ],
  ],
  [
    "ros_respiratoryCopdSubAnswerSupportedBy",
    [
      "ros_respiratoryCopdXuseOfAccessaryMuscles",
      "ros_respiratoryCopdBarrelChest",
      "ros_respiratoryCopdXrResults",
      "ros_respiratoryCopdWheezing",
      "ros_respiratoryCopdClubbing",
      "ros_respiratoryCopdDecreasedOrProlongedSounds",
      "ros_respiratoryCopdDyspneaOnExertion",
      "ros_respiratoryCopdOxygenUse",
      "ros_respiratoryCopdBronchodilatorMedication",
      "ros_respiratoryCopdShortnessOfBreath",
      "ros_respiratoryCopdChronicCough",
      "ros_respiratoryCopdSubOther",
    ],
  ],
  [
    "ros_respiratoryPatientChronicBronchitis",
    [
      "ros_respiratoryPatientChronicBronchitisYes",
      "ros_respiratoryPatientChronicBronchitisrNo",
    ],
  ],
  [
    "ros_respiratoryCopdSubPatientBronchodilator",
    [
      "ros_respiratoryCopdSubPatientBronchodilatorInhaled",
      "ros_respiratoryCopdSubPatientBronchodilatorNebulizer",
      "ros_respiratoryCopdSubPatientBronchodilatorOral",
    ],
  ],
  [
    "ros_respiratoryCopdSubPatientSteroids",
    [
      "ros_respiratoryCopdSubPatientSteroidsInhaled",
      "ros_respiratoryCopdSubPatientSteroidsNebulizer",
      "ros_respiratoryCopdSubPatientSteroidsOral",
    ],
  ],
  [
    "ros_respiratoryCopdSubPatientExacerbation",
    [
      "ros_respiratoryCopdSubPatientExacerbationUseOfAntibiotics",
      "ros_respiratoryCopdSubPatientExacerbationFever",
      "ros_respiratoryCopdSubPatientExacerbationIncreasedSputumProduction",
      "ros_respiratoryCopdSubPatientExacerbationAdventitiousSoundsOnLung",
    ],
  ],
  [
    "ros_respiratoryPatientHaveEmphysema",
    [
      "ros_respiratoryPatientHaveEmphysemaYes",
      "ros_respiratoryPatientHaveEmphysemaNo",
    ],
  ],
  [
    "ros_respiratoryPatientBronchodilator",
    [
      "ros_respiratoryPatientBronchodilatorYes",
      "ros_respiratoryPatientBronchodilatorNo",
    ],
  ],
  [
    "ros_respiratoryPatientSteroids",
    ["ros_respiratoryPatientSteroidsYes", "ros_respiratoryPatientSteroidsNo"],
  ],
  [
    "ros_respiratoryPatientExacerbation",
    [
      "ros_respiratoryPatientExacerbationYes",
      "ros_respiratoryPatientExacerbationNo",
    ],
  ],

  [
    "ros_respiratoryCysticFibrosisSubAnswerDescribe",
    [
      "ros_respiratoryCysticFibrosisActive",
      "ros_respiratoryCysticFibrosisHistoryOf",
      "ros_respiratoryCysticFibrosisRuleOut",
    ],
  ],
  [
    "ros_respiratoryCysticFibrosisSubAnswerSupportedBy",
    [
      "ros_respiratoryCysticFibrosisHistory",
      "ros_respiratoryCysticFibrosisSymptoms",
      "ros_respiratoryCysticFibrosisPhysicalFindings",
      "ros_respiratoryCysticFibrosisMedications",
      "ros_respiratoryCysticFibrosisTestResults",
      "ros_respiratoryCysticFibrosisImageStudies",
      "ros_respiratoryCysticFibrosisBiopsy",
      "ros_respiratoryCysticFibrosisDme",
      "ros_respiratoryCysticFibrosisShortnessOfBreath",
      "ros_respiratoryCysticFibrosisChronicCough",
      "ros_respiratoryCysticFibrosisWheezing",
      "ros_respiratoryCysticFibrosisSubOther",
    ],
  ],

  [
    "ros_respiratoryHypoventilationSubAnswerDescribe",
    [
      "ros_respiratoryHypoventilationActive",
      "ros_respiratoryHypoventilationHistoryOf",
      "ros_respiratoryHypoventilationRuleOut",
    ],
  ],
  [
    "ros_respiratoryHypoventilationSubAnswerSupportedBy",
    [
      "ros_respiratoryHypoventilationMorbidObesity",
      "ros_respiratoryHypoventilationUseOfOxygen",
      "ros_respiratoryHypoventilationCarbonDioxideRetention",
      "ros_respiratoryHypoventilationShortnessOfBreath",
      "ros_respiratoryHypoventilationWheezing",
      "ros_respiratoryHypoventilationChronicCough",
      "ros_respiratoryHypoventilationSubOther",
    ],
  ],

  [
    "ros_respiratoryHypoxemiaSubAnswerDescribe",
    [
      "ros_respiratoryHypoxemiaActive",
      "ros_respiratoryHypoxemiaHistoryOf",
      "ros_respiratoryHypoxemiaRuleOut",
    ],
  ],
  [
    "ros_respiratoryHypoxemiaSubAnswerSupportedBy",
    [
      "ros_respiratoryHypoxemiaOxygenSaturation",
      "ros_respiratoryHypoxemiaShortnessOfBreath",
      "ros_respiratoryHypoxemiaWheezing",
      "ros_respiratoryHypoxemiaChronicCough",
      "ros_respiratoryHypoxemiaSubOther",
    ],
  ],

  [
    "ros_respiratoryPneumoniaSubAnswerDescribe",
    [
      "ros_respiratoryPneumoniaActive",
      "ros_respiratoryPneumoniaHistoryOf",
      "ros_respiratoryPneumoniaRuleOut",
    ],
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerSupportedBy",
    [
      "ros_respiratoryPneumoniaHospitalization",
      "ros_respiratoryPneumoniaPhysicalFindings",
      "ros_respiratoryPneumoniaImageStudies",
      "ros_respiratoryPneumoniaLabStudies",
      "ros_respiratoryPneumoniaShortnessOfBreath",
      "ros_respiratoryPneumoniaWheezing",
      "ros_respiratoryPneumoniaChronicCough",
      "ros_respiratoryPneumoniaSubOther",
    ],
  ],
  [
    "ros_respiratoryPneumoniaSubAnswerEtiology",
    [
      "ros_respiratoryPneumoniaViral",
      "ros_respiratoryPneumoniaPneumococcal",
      "ros_respiratoryPneumoniaStaph",
      "ros_respiratoryPneumoniaOtherBacterial",
      "ros_respiratoryPneumoniaAspiration",
    ],
  ],
  [
    "ros_respiratoryHistoryOfLungAbscess",
    [
      "ros_respiratoryHistoryOfLungAbscessYes",
      "ros_respiratoryHistoryOfLungAbscessNo",
    ],
  ],
  [
    "ros_respiratoryHistoryOfEmpyema",
    ["ros_respiratoryHistoryOfEmpyemaYes", "ros_respiratoryHistoryOfEmpyemaNo"],
  ],

  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerDescribe",
    [
      "ros_respiratoryPulmonaryFibrosisActive",
      "ros_respiratoryPulmonaryFibrosisHistoryOf",
      "ros_respiratoryPulmonaryFibrosisRuleOut",
    ],
  ],
  [
    "ros_respiratoryPulmonaryFibrosisSubAnswerSupportedBy",
    [
      "ros_respiratoryPulmonaryFibrosisXRay",
      "ros_respiratoryPulmonaryFibrosisPft",
      "ros_respiratoryPulmonaryFibrosisBiopsy",
      "ros_respiratoryPulmonaryFibrosisMedications",
      "ros_respiratoryPulmonaryFibrosisShortnessOfBreath",
      "ros_respiratoryPulmonaryFibrosisWheezing",
      "ros_respiratoryPulmonaryFibrosisChronicCough",
      "ros_respiratoryPulmonaryFibrosisSubOther",
    ],
  ],

  [
    "ros_respiratoryRespiratorDependenceSubAnswerDescribe",
    [
      "ros_respiratoryRespiratorDependenceActive",
      "ros_respiratoryRespiratorDependenceHistoryOf",
      "ros_respiratoryRespiratorDependenceRuleOut",
    ],
  ],
  [
    "ros_respiratoryRespiratorDependenceSubAnswerSupportedBy",
    [
      "ros_respiratoryRespiratorDependenceHistory",
      "ros_respiratoryRespiratorDependenceSymptoms",
      "ros_respiratoryRespiratorDependencePhysicalFindings",
      "ros_respiratoryRespiratorDependenceMedications",
      "ros_respiratoryRespiratorDependenceTestResults",
      "ros_respiratoryRespiratorDependenceImageStudies",
      "ros_respiratoryRespiratorDependenceBiopsy",
      "ros_respiratoryRespiratorDependenceDme",
      "ros_respiratoryRespiratorDependenceShortnessOfBreath",
      "ros_respiratoryRespiratorDependenceWheezing",
      "ros_respiratoryRespiratorDependenceChronicCough",
      "ros_respiratoryRespiratorDependenceSubOther",
    ],
  ],

  [
    "ros_respiratoryRespiratoryArrestSubAnswerDescribe",
    [
      "ros_respiratoryRespiratoryArrestActive",
      "ros_respiratoryRespiratoryArrestHistoryOf",
      "ros_respiratoryRespiratoryArrestRuleOut",
    ],
  ],
  [
    "ros_respiratoryRespiratoryArrestSubAnswerSupportedBy",
    [
      "ros_respiratoryRespiratoryArrestHistoryOfHospitalization",
      "ros_respiratoryRespiratoryArrestUseOfVentilator",
      "ros_respiratoryRespiratoryArrestCarbonDioxideRetention",
      "ros_respiratoryRespiratoryArrestShortnessOfBreath",
      "ros_respiratoryRespiratoryArrestTestWheezing",
      "ros_respiratoryRespiratoryArrestChronicCough",
      "ros_respiratoryRespiratoryArrestSubOther",
    ],
  ],

  [
    "ros_respiratorySarcoidosisSubAnswerDescribe",
    [
      "ros_respiratorySarcoidosisActive",
      "ros_respiratorySarcoidosisHistoryOf",
      "ros_respiratorySarcoidosisRuleOut",
    ],
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSpecify",
    [
      "ros_respiratorySarcoidosisLung",
      "ros_respiratorySarcoidosisLymphNodes",
      "ros_respiratorySarcoidosisSkin",
      "ros_respiratorySarcoidosisCranialNerves",
      "ros_respiratorySarcoidosisTestUnKnown",
    ],
  ],
  [
    "ros_respiratorySarcoidosisSubAnswerSupportedBy",
    [
      "ros_respiratorySarcoidosisDiagnosticResults",
      "ros_respiratorySarcoidosisCutaneousLesions",
      "ros_respiratorySarcoidosisShortnessOfBreath",
      "ros_respiratorySarcoidosisWheezing",
      "ros_respiratorySarcoidosisTestChronicCough",
      "ros_respiratorySarcoidosisSubOther",
    ],
  ],

  [
    "ros_respiratorySleepApneaSubAnswerDescribe",
    [
      "ros_respiratorySleepApneaActive",
      "ros_respiratorySleepApneaHistoryOf",
      "ros_respiratorySleepApneaRuleOut",
    ],
  ],
  [
    "ros_respiratorySleepApneaSubAnswerSupportedBy",
    [
      "ros_respiratorySleepApneaUseOfCpap",
      "ros_respiratorySleepApneaPositiveSleepStudies",
      "ros_respiratorySleepApneaHistoryOfSleepiness",
      "ros_respiratorySleepApneaHeavySnoring",
      "ros_respiratorySleepApneaShortnessOfBreath",
      "ros_respiratorySleepApneaWheezing",
      "ros_respiratorySleepApneaChronicCough",
      "ros_respiratorySleepApneaSubOther",
    ],
  ],

  [
    "ros_respiratoryRespOtherSubAnswerDescribe",
    [
      "ros_respiratoryRespOtherSubAnswerDescribeActive",
      "ros_respiratoryRespOtherSubAnswerDescribeHistoryOf",
      "ros_respiratoryRespOtherSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_respiratoryRespOtherSubAnswerSupportedBy",
    [
      "ros_respiratoryRespOtherSubAnswerSupportedByHistory",
      "ros_respiratoryRespOtherSubAnswerSupportedBySymptoms",
      "ros_respiratoryRespOtherSubAnswerSupportedByPhysicalFindings",
      "ros_respiratoryRespOtherSubAnswerSupportedByMedications",
      "ros_respiratoryRespOtherSubAnswerSupportedByTestResults",
      "ros_respiratoryRespOtherSubAnswerSupportedByImageStudies",
      "ros_respiratoryRespOtherSubAnswerSupportedByBiopsy",
      "ros_respiratoryRespOtherSubAnswerSupportedByDme",
      "ros_respiratoryRespOtherSubAnswerSupportedByShortnessOfBreath",
      "ros_respiratoryRespOtherSubAnswerSupportedByChronicCough",
      "ros_respiratoryRespOtherSubAnswerSupportedByWheezing",
      "ros_respiratoryOtherSubOther",
    ],
  ],

  //integument
  ["ros_integument", ["ros_integumentYes", "ros_integumentNo"]],

  [
    "ros_integumentDiagnoses",
    [
      "ros_integumentDiagnosesIntegumentBasilCellCarcinoma",
      "ros_integumentDiagnosesIntegumentDermatitis",
      "ros_integumentDiagnosesIntegumentEczema",
      "ros_integumentDiagnosesIntegumentOnychomysosis",
      "ros_integumentDiagnosesIntegumentPsoriasis",
      "ros_integumentDiagnosesIntegumentSkinUlcer",
      "ros_integumentDiagnosesIntegumentTineaPedis",
      "ros_integumentDiagnosesIntegumentUrticarialDisease",
      "ros_integumentDiagnosesIntegumentWound",
      "ros_integumentDiagnosesIntegumentOther",
    ],
  ],

  [
    "ros_integumentBasilCellCarcinomaSubAnswerDescribe",
    [
      "ros_integumentBasilCellCarcinomaActive",
      "ros_integumentBasilCellCarcinomaHistoryOf",
      "ros_integumentBasilCellCarcinomaRuleOut",
    ],
  ],
  [
    "ros_integumentBasilCellCarcinomaSubAnswerSupportedBy",
    [
      "ros_integumentBasilCellCarcinomaHistory",
      "ros_integumentBasilCellCarcinomaSymptoms",
      "ros_integumentBasilCellCarcinomaPhysicalFindings",
      "ros_integumentBasilCellCarcinomaMedications",
      "ros_integumentBasilCellCarcinomaTestResults",
      "ros_integumentBasilCellCarcinomaImageStudies",
      "ros_integumentBasilCellCarcinomaBiopsy",
      "ros_integumentBasilCellCarcinomaDme",
      "ros_integumentBasilCellCarcinomaSubOther",
    ],
  ],

  [
    "ros_integumentDermatitisSubAnswerDescribe",
    [
      "ros_integumentDermatitisActive",
      "ros_integumentDermatitisHistoryOf",
      "ros_integumentDermatitisRuleOut",
    ],
  ],
  [
    "ros_integumentDermatitisSubAnswerSupportedBy",
    [
      "ros_integumentDermatitisHistory",
      "ros_integumentDermatitisSymptoms",
      "ros_integumentDermatitisPhysicalFindings",
      "ros_integumentDermatitisMedications",
      "ros_integumentDermatitisTestResults",
      "ros_integumentDermatitisImageStudies",
      "ros_integumentDermatitisBiopsy",
      "ros_integumentDermatitisDme",
      "ros_integumentDermatitisSubOther",
    ],
  ],
  [
    "ros_integumentDermatitisSubAnswerWhatType",
    [
      "ros_integumentDermatitisContact",
      "ros_integumentDermatitisStasis",
      "ros_integumentDermatitisDrugInduced",
      "ros_integumentDermatitisDiseaseInduced",
      "ros_integumentDermatitisUnspecified",
    ],
  ],

  [
    "ros_integumentEczemaSubAnswerDescribe",
    [
      "ros_integumentEczemaActive",
      "ros_integumentEczemaHistoryOf",
      "ros_integumentEczemaRuleOut",
    ],
  ],
  [
    "ros_integumentEczemaSubAnswerSupportedBy",
    [
      "ros_integumentEczemaHistory",
      "ros_integumentEczemaSymptoms",
      "ros_integumentEczemaPhysicalFindings",
      "ros_integumentEczemaMedications",
      "ros_integumentEczemaTestResults",
      "ros_integumentEczemaImageStudies",
      "ros_integumentEczemaBiopsy",
      "ros_integumentEczemaDme",
      "ros_integumentEczemaSubOther",
    ],
  ],

  [
    "ros_integumentOnychomycosisSubAnswerDescribe",
    [
      "ros_integumentOnychomycosisActive",
      "ros_integumentOnychomycosisHistoryOf",
      "ros_integumentOnychomycosisRuleOut",
    ],
  ],
  [
    "ros_integumentOnychomycosisSubAnswerSupportedBy",
    [
      "ros_integumentOnychomycosisHistory",
      "ros_integumentOnychomycosisSymptoms",
      "ros_integumentOnychomycosisPhysicalFindings",
      "ros_integumentOnychomycosisMedications",
      "ros_integumentOnychomycosisTestResults",
      "ros_integumentOnychomycosisImageStudies",
      "ros_integumentOnychomycosisBiopsy",
      "ros_integumentOnychomycosisDme",
      "ros_integumentOnychomycosisSubOther",
    ],
  ],

  [
    "ros_integumentPsoriasisSubAnswerDescribe",
    [
      "ros_integumentPsoriasisActive",
      "ros_integumentPsoriasisHistoryOf",
      "ros_integumentPsoriasisRuleOut",
    ],
  ],
  [
    "ros_integumentPsoriasisSubAnswerSupportedBy",
    [
      "ros_integumentPsoriasisHistory",
      "ros_integumentPsoriasisSymptoms",
      "ros_integumentPsoriasisPhysicalFindings",
      "ros_integumentPsoriasisMedications",
      "ros_integumentPsoriasisTestResults",
      "ros_integumentPsoriasisImageStudies",
      "ros_integumentPsoriasisBiopsy",
      "ros_integumentPsoriasisDme",
      "ros_integumentPsoriasisSubOther",
    ],
  ],
  [
    "ros_integumentPsoriasisSubAnswerPsoriaticArthritis",
    [
      "ros_integumentPsoriaticArthritisYes",
      "ros_integumentPsoriaticArthritisNo",
    ],
  ],

  [
    "ros_integumentSkinUlcerSubAnswerDescribe",
    [
      "ros_integumentSkinUlcerActive",
      "ros_integumentSkinUlcerHistoryOf",
      "ros_integumentSkinUlcerRuleOut",
    ],
  ],
  [
    "ros_integumentSkinUlcerSubAnswerSupportedBy",
    [
      "ros_integumentSkinUlcerHistory",
      "ros_integumentSkinUlcerSymptoms",
      "ros_integumentSkinUlcerPhysicalFindings",
      "ros_integumentSkinUlcerMedications",
      "ros_integumentSkinUlcerTestResults",
      "ros_integumentSkinUlcerImageStudies",
      "ros_integumentSkinUlcerBiopsy",
      "ros_integumentSkinUlcerDme",
      "ros_integumentSkinUlcerSubOther",
    ],
  ],
  [
    "ros_integumentSkinUlcerSubAnswerEtiology",
    [
      "ros_integumentSkinUlcerPressure",
      "ros_integumentSkinUlcerVenousStatis",
      "ros_integumentSkinUlcerPeripheralVascular",
      "ros_integumentSkinUlcerDiseaseInduced",
      "ros_integumentSkinUlcerDiabeticVasculitis",
      "ros_integumentSkinUlcerDiabeticNeuropathy",
    ],
  ],

  [
    "ros_integumentTineaPedisSubAnswerDescribe",
    [
      "ros_integumentTineaPedisActive",
      "ros_integumentTineaPedisHistoryOf",
      "ros_integumentTineaPedisRuleOut",
    ],
  ],
  [
    "ros_integumentTineaPedisSubAnswerSupportedBy",
    [
      "ros_integumentTineaPedisHistory",
      "ros_integumentTineaPedisSymptoms",
      "ros_integumentTineaPedisPhysicalFindings",
      "ros_integumentTineaPedisMedications",
      "ros_integumentTineaPedisTestResults",
      "ros_integumentTineaPedisImageStudies",
      "ros_integumentTineaPedisBiopsy",
      "ros_integumentTineaPedisDme",
      "ros_integumentTineaPedisSubOther",
    ],
  ],

  [
    "ros_integumentUrticarialDiseaseSubAnswerDescribe",
    [
      "ros_integumentUrticarialDiseaseActive",
      "ros_integumentUrticarialDiseaseHistoryOf",
      "ros_integumentUrticarialDiseaseRuleOut",
    ],
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerSupportedBy",
    [
      "ros_integumentUrticarialDiseaseHistory",
      "ros_integumentUrticarialDiseaseSymptoms",
      "ros_integumentUrticarialDiseasePhysicalFindings",
      "ros_integumentUrticarialDiseaseMedications",
      "ros_integumentUrticarialDiseaseTestResults",
      "ros_integumentUrticarialDiseaseImageStudies",
      "ros_integumentUrticarialDiseaseBiopsy",
      "ros_integumentUrticarialDiseaseDme",
      "ros_integumentUrticarialDiseaseSubOther",
    ],
  ],
  [
    "ros_integumentUrticarialDiseaseSubAnswerType",
    [
      "ros_integumentUrticarialDiseaseAcute",
      "ros_integumentUrticarialDiseaseChronic",
    ],
  ],

  [
    "ros_integumentWoundSubAnswerDescribe",
    [
      "ros_integumentWoundActive",
      "ros_integumentWoundHistoryOf",
      "ros_integumentWoundRuleOut",
    ],
  ],
  [
    "ros_integumentWoundSubAnswerSupportedBy",
    [
      "ros_integumentWoundHistory",
      "ros_integumentWoundSymptoms",
      "ros_integumentWoundPhysicalFindings",
      "ros_integumentWoundMedications",
      "ros_integumentWoundTestResults",
      "ros_integumentWoundImageStudies",
      "ros_integumentWoundBiopsy",
      "ros_integumentWoundDme",
      "ros_integumentWoundSubOther",
    ],
  ],
  [
    "ros_integumentWoundSubAnswerEtiology",
    [
      "ros_integumentWoundSurgical",
      "ros_integumentWoundTraumatic",
      "ros_integumentWoundBurn",
    ],
  ],

  [
    "ros_integumentOthersSubAnswerDescribe",
    [
      "ros_integumentOthersSubAnswerDescribeActive",
      "ros_integumentOthersSubAnswerDescribeHistoryOf",
      "ros_integumentOthersSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_integumentOthersSubAnswerSupportedBy",
    [
      "ros_integumentOthersSubAnswerSupportedByHistory",
      "ros_integumentOthersSubAnswerSupportedBySymptoms",
      "ros_integumentOthersSubAnswerSupportedByPhysicalFindings",
      "ros_integumentOthersSubAnswerSupportedByMedications",
      "ros_integumentOthersSubAnswerSupportedByTestResults",
      "ros_integumentOthersSubAnswerSupportedByImageStudies",
      "ros_integumentOthersSubAnswerSupportedByBiopsy",
      "ros_integumentOthersSubAnswerSupportedByDme",
      "ros_integumentIntegumentOtherSubOther",
    ],
  ],

  //musko
  ["ros_musculoskeletal", ["ros_musculoskeletalYes", "ros_musculoskeletalNo"]],

  [
    "ros_musculoskeletalDiagnoses",
    [
      "ros_musculoskeletalDiagnosesMuskoCollagen",
      "ros_musculoskeletalDiagnosesMuskoDegenerativeDiscDisease",
      "ros_musculoskeletalDiagnosesMuskoExtremityFracture",
      "ros_musculoskeletalDiagnosesMuskoGout",
      "ros_musculoskeletalDiagnosesMuskoHalluxValgus",
      "ros_musculoskeletalDiagnosesMuskoHammerToes",
      "ros_musculoskeletalDiagnosesMuskoOsteoarthritis",
      "ros_musculoskeletalDiagnosesMuskoOsteomyelitis",
      "ros_musculoskeletalDiagnosesMuskoOsteoporosis",
      "ros_musculoskeletalDiagnosesMuskoPyogenicArthritis",
      "ros_musculoskeletalDiagnosesMuskoRheumatoidArthritis",
      "ros_musculoskeletalDiagnosesMuskoSpinalStenosis",
      "ros_musculoskeletalDiagnosesMuskoSystemicLupus",
      "ros_musculoskeletalDiagnosesMuskoOther",
    ],
  ],

  [
    "ros_musculoskeletalCollagenSubAnswerDescribe",
    [
      "ros_musculoskeletalCollagenActive",
      "ros_musculoskeletalCollagenHistoryOf",
      "ros_musculoskeletalCollagenRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalCollagenSubAnswerSupportedBy",
    [
      "ros_musculoskeletalCollagenPhysicalFindings",
      "ros_musculoskeletalCollagenLabTests",
      "ros_musculoskeletalCollagenBiopsy",
      "ros_musculoskeletalCollagenImageStudies",
      "ros_musculoskeletalCollagenSubOther",
    ],
  ],
  [
    "ros_musculoskeletalCollagenSubAnswercollagenDescribe",
    [
      "ros_musculoskeletalCollagenSleLupus",
      "ros_musculoskeletalCollagenScleroderma",
      "ros_musculoskeletalCollagenDermatomyositis",
    ],
  ],

  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe",
    [
      "ros_musculoskeletalDegenerativeDiscDiseaseActive",
      "ros_musculoskeletalDegenerativeDiscDiseaseHistoryOf",
      "ros_musculoskeletalDegenerativeDiscDiseaseRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedBy",
    [
      "ros_musculoskeletalDegenerativeDiscDiseaseSymptoms",
      "ros_musculoskeletalDegenerativeDiscDiseasePhysicalFindings",
      "ros_musculoskeletalDegenerativeDiscDiseaseImageStudies",
      "ros_musculoskeletalDegenerativeDiscDiseaseMedications",
      "ros_musculoskeletalDegenerativeDiscDiseaseSubOther",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder",
    [
      "ros_musculoskeletalNormalBladderNo",
      "ros_musculoskeletalNormalBladderNo",
    ],
  ],
  [
    "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease",
    [
      "ros_musculoskeletalSiteOfDiseaseCervical",
      "ros_musculoskeletalSiteOfDiseaseThoracic",
      "ros_musculoskeletalSiteOfDiseaseLumbar",
      "ros_musculoskeletalSiteOfDiseaseLumbosacral",
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureSubAnswerDescribe",
    [
      "ros_musculoskeletalExtremityFractureSubAnswerDescribeActive",
      "ros_musculoskeletalExtremityFractureSubAnswerDescribeHistoryOf",
      "ros_musculoskeletalExtremityFractureSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureActiveTypeSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerTraumatic",
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerPathological",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureHistoryOfSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerTraumatic",
      "ros_musculoskeletalExtremityFractureHistoryOfSubAnswerPathological",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureSubAnswerSupportedBy",
    [
      "ros_musculoskeletalExtremityFractureHistory",
      "ros_musculoskeletalExtremityFractureSymptoms",
      "ros_musculoskeletalExtremityFracturePhysicalFindings",
      "ros_musculoskeletalExtremityFractureMedications",
      "ros_musculoskeletalExtremityFractureTestResults",
      "ros_musculoskeletalExtremityFractureImageStudies",
      "ros_musculoskeletalExtremityFractureBiopsy",
      "ros_musculoskeletalExtremityFractureDme",
      "ros_musculoskeletalExtremityFractureOther",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer",
    [
      "ros_musculoskeletalCurrentWithinTwelveWeeksYes",
      "ros_musculoskeletalCurrentWithinTwelveWeeksNo",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureSubAnswerExtremity",
    [
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityShoulder",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityArm",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityForearm",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityWrist",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityHand",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFemoralShaft",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityTibia",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFibula",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityAnkle",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityFoot",
      "ros_musculoskeletalExtremityFractureSubAnswerExtremityHip",
    ],
  ],

  [
    "ros_musculoskeletalExtremityFractureShoulderSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureShoulderLeft",
      "ros_musculoskeletalExtremityFractureShoulderRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureArmSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureArmLeft",
      "ros_musculoskeletalExtremityFractureArmRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureForearmSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureForearmLeft",
      "ros_musculoskeletalExtremityFractureForearmRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureWristSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureWristLeft",
      "ros_musculoskeletalExtremityFractureWristRight",
    ],
  ],
  [
    "ros_musculoskeletalHandSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHandLeft",
      "ros_musculoskeletalExtremityFractureHandRight",
    ],
  ],
  [
    "ros_musculoskeletalFemoralShaftSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFemoralShaftLeft",
      "ros_musculoskeletalExtremityFractureFemoralShaftRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureTibiaSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureTibiaLeft",
      "ros_musculoskeletalExtremityFractureTibiaRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureFibulaSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFibulaLeft",
      "ros_musculoskeletalExtremityFractureFibulaRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureAnkleSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureAnkleLeft",
      "ros_musculoskeletalExtremityFractureAnkleRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureFootSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureFootLeft",
      "ros_musculoskeletalExtremityFractureFootRight",
    ],
  ],
  [
    "ros_musculoskeletalExtremityFractureHipSubAnswer",
    [
      "ros_musculoskeletalExtremityFractureHipLeft",
      "ros_musculoskeletalExtremityFractureHipRight",
    ],
  ],

  [
    "ros_musculoskeletalGoutSubAnswerDescribe",
    [
      "ros_musculoskeletalGoutActive",
      "ros_musculoskeletalGoutHistoryOf",
      "ros_musculoskeletalGoutRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalGoutSubAnswerSupportedBy",
    [
      "ros_musculoskeletalGoutHistoryOfAttacks",
      "ros_musculoskeletalGoutLabTests",
      "ros_musculoskeletalGoutMedications",
      "ros_musculoskeletalGoutSubOther",
    ],
  ],

  [
    "ros_musculoskeletalHalluxValgusSubAnswerDescribe",
    [
      "ros_musculoskeletalHalluxValgusSubAnswerDescribeActive",
      "ros_musculoskeletalHalluxValgusSubAnswerDescribeHistoryOf",
      "ros_musculoskeletalHalluxValgusSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalHalluxValgusSubAnswerSupportedBy",
    [
      "ros_musculoskeletalHalluxValgusHistory",
      "ros_musculoskeletalHalluxValgusSymptoms",
      "ros_musculoskeletalHalluxValgusPhysicalFindings",
      "ros_musculoskeletalHalluxValgusMedications",
      "ros_musculoskeletalHalluxValgusTestResults",
      "ros_musculoskeletalHalluxValgusImageStudies",
      "ros_musculoskeletalHalluxValgusBiopsy",
      "ros_musculoskeletalHalluxValgusDme",
      "ros_musculoskeletalHalluxValgusSubOther",
    ],
  ],

  [
    "ros_musculoskeletalHammerToesSubAnswerDescribe",
    [
      "ros_musculoskeletalHammerToesSubAnswerDescribeActive",
      "ros_musculoskeletalHammerToesSubAnswerDescribeHistoryOf",
      "ros_musculoskeletalHammerToesSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalHammerToesSubAnswerSupportedBy",
    [
      "ros_musculoskeletalHammerToesHistory",
      "ros_musculoskeletalHammerToesSymptoms",
      "ros_musculoskeletalHammerToesPhysicalFindings",
      "ros_musculoskeletalHammerToesMedications",
      "ros_musculoskeletalHammerToesTestResults",
      "ros_musculoskeletalHammerToesImageStudies",
      "ros_musculoskeletalHammerToesBiopsy",
      "ros_musculoskeletalHammerToesDme",
      "ros_musculoskeletalHammerToesSubOther",
    ],
  ],

  [
    "ros_musculoskeletalOsteoarthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteoarthritisActive",
      "ros_musculoskeletalOsteoarthritisHistoryOf",
      "ros_musculoskeletalOsteoarthritisRuleOut",
    ],
  ],

  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalOsteoarthritisSymptoms",
      "ros_musculoskeletalOsteoarthritisPhysicalFindings",
      "ros_musculoskeletalOsteoarthritisImageStudies",
      "ros_musculoskeletalOsteoarthritisSubOther",
    ],
  ],
  [
    "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswer",
    [
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointSwelling",
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerJointStiffness",
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerPain",
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerLimitedRom",
    ],
  ],

  [
    "ros_musculoskeletalOsteomyelitisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteomyelitisActive",
      "ros_musculoskeletalOsteomyelitisHistoryOf",
      "ros_musculoskeletalOsteomyelitisRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalOsteomyelitisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalOsteomyelitisHospitalization",
      "ros_musculoskeletalOsteomyelitisImageStudies",
      "ros_musculoskeletalOsteomyelitisCultures",
      "ros_musculoskeletalOsteomyelitisMedications",
      "ros_musculoskeletalOsteomyelitisSubOther",
    ],
  ],

  [
    "ros_musculoskeletalOsteoporosisSubAnswerDescribe",
    [
      "ros_musculoskeletalOsteoporosisActive",
      "ros_musculoskeletalOsteoporosisHistoryOf",
      "ros_musculoskeletalOsteoporosisRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalOsteoporosisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalOsteoporosisDexaScan",
      "ros_musculoskeletalOsteoporosisMedications",
      "ros_musculoskeletalOsteoporosisImagingStudies",
      "ros_musculoskeletalOsteoporosisSymptoms",
      "ros_musculoskeletalOsteoporosisFractureHistory",
      "ros_musculoskeletalOsteoporosisSubOther",
    ],
  ],

  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalPyogenicArthritisActive",
      "ros_musculoskeletalPyogenicArthritisHistoryOf",
      "ros_musculoskeletalPyogenicArthritisRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalPyogenicArthritisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalPyogenicArthritisHospitalization",
      "ros_musculoskeletalPyogenicArthritisImageStudies",
      "ros_musculoskeletalPyogenicArthritisCultures",
      "ros_musculoskeletalPyogenicArthritisMedications",
      "ros_musculoskeletalPyogenicArthritisSubOther",
      "ROS_musculoskeletalPyogenicArthritisWhichJoints",
    ],
  ],

  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe",
    [
      "ros_musculoskeletalRheumatoidArthritisActive",
      "ros_musculoskeletalRheumatoidArthritisHistoryOf",
      "ros_musculoskeletalRheumatoidArthritisRuleOut",
    ],
  ],

  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalRheumatoidArthritisPhysicalFindings",
      "ros_musculoskeletalRheumatoidArthritisMedications",
      "ros_musculoskeletalRheumatoidArthritisLabTests",
      "ros_musculoskeletalRheumatoidArthritisImageStudies",
      "ros_musculoskeletalRheumatoidArthritisSubOther",
      "ros_musculoskeletalRheumatoidArthritisSymptoms",
    ],
  ],
  [
    "ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms",
    [
      "ros_musculoskeletalRheumatoidArthritisJointSwelling",
      "ros_musculoskeletalRheumatoidArthritisJointStiffness",
      "ros_musculoskeletalRheumatoidArthritisPain",
      "ros_musculoskeletalRheumatoidArthritisLimitedRom",
    ],
  ],

  [
    "ros_musculoskeletalSpinalStenosisSubAnswerDescribe",
    [
      "ros_musculoskeletalSpinalStenosisActive",
      "ros_musculoskeletalSpinalStenosisHistoryOf",
      "ros_musculoskeletalSpinalStenosisRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerSupportedBy",
    [
      "ros_musculoskeletalSpinalStenosisSymptoms",
      "ros_musculoskeletalSpinalStenosisPhysicalFindings",
      "ros_musculoskeletalSpinalStenosisImageStudies",
      "ros_musculoskeletalSpinalStenosisMedications",
      "ros_musculoskeletalSpinalStenosisSubOther",
    ],
  ],
  [
    "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder",
    [
      "ros_musculoskeletalSpinalStenosisNormalBladderYes",
      "ros_musculoskeletalSinalStenosisNormalBladderNo",
    ],
  ],

  [
    "ros_musculoskeletalSystemicLupusSubAnswerDescribe",
    [
      "ros_musculoskeletalSystemicLupusActive",
      "ros_musculoskeletalSystemicLupusHistoryOf",
      "ros_musculoskeletalSystemicLupusRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalSystemicLupusSubAnswerSupportedBy",
    [
      "ros_musculoskeletalSystemicLupusLabs",
      "ros_musculoskeletalSystemicLupusMedications",
      "ros_musculoskeletalSystemicLupusHistory",
      "ros_musculoskeletalSystemicLupusSubOther",
    ],
  ],

  [
    "ros_musculoskeletalMuskoOtherSubAnswerDescribe",
    [
      "ros_musculoskeletalMuskoOtherSubAnswerDescribeActive",
      "ros_musculoskeletalMuskoOtherSubAnswerDescribeHistoryOf",
      "ros_musculoskeletalMuskoOtherSubAnswerDescribeRuleOut",
    ],
  ],
  [
    "ros_musculoskeletalMuskoOtherSubAnswerSupportedBy",
    [
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByHistory",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedBySymptoms",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByPhysicalFindings",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByMedications",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByTestResults",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByImageStudies",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByBiopsy",
      "ros_musculoskeletalMuskoOtherSubAnswerSupportedByDme",
      "ros_musculoskeletalMuskoSubOther",
    ],
  ],

  [
    "ros_musculoskeletalMuskoHadAnAmputation",
    ["ros_hadAnAmputationYes", "ros_hadAnAmputationNo"],
  ],

  //hematology
  ["ros_hematology", ["ros_ematologyYes", "ros_hematologyNo"]],

  [
    "ros_hematologyDiagnoses",
    [
      "ros_hematologyDiagnosesHemaAids",
      "ros_hematologyDiagnosesHemaAnemia",
      "ros_hematologyDiagnosesHemaCDifficile",
      "ros_hematologyDiagnosesHemaCommunityAcquired",
      "ros_hematologyDiagnosesHemaHiv",
      "ros_hematologyDiagnosesHemaHerpesZoster",
      "ros_hematologyDiagnosesHemaMrsaInfection",
      "ros_hematologyDiagnosesHemaImmuneDeficiency",
      "ros_hematologyDiagnosesHemaSepsis",
      "ros_hematologyDiagnosesHemaSickleCellDisease",
      "ros_hematologyDiagnosesHemaSickleCellTrait",
      "ros_hematologyDiagnosesHemaThalassemia",
      "ros_hematologyDiagnosesHemaThrombocytopenia",
      "ros_hematologyDiagnosesHemaTuberculosis",
      "ros_hematologyDiagnosesHemaVitaminDDeficiency",
      "ros_hematologyHemaDiagnosesOtherSubOther",
    ],
  ],

  [
    "ros_hematologyAidsSubAnswerDescribe",
    [
      "ros_hematologyAidsActive",
      "ros_hematologyAidsHistoryOf",
      "ros_hematologyAidsRuleOut",
    ],
  ],
  [
    "ros_hematologyAidsSubAnswerSupportedBy",
    [
      "ros_hematologyAidsSymptoms",
      "ros_hematologyAidsPhysicalFindings",
      "ros_hematologyAidsHistoryOfOpportunistic",
      "ros_hematologyAidsMedications",
      "ros_hematologyAidsSubOther",
    ],
  ],
  [
    "ros_hematologyAidsSubAnswerPatientUnderTreatment",
    [
      "ros_hematologyAidsPatientUnderTreatmentYes",
      "ros_hematologyAidsPatientUnderTreatmentNo",
    ],
  ],

  [
    "ros_hematologyAnemiaSubAnswerDescribe",
    [
      "ros_hematologyAnemiaActive",
      "ros_hematologyAnemiaHistoryOf",
      "ros_hematologyAnemiaRuleOut",
    ],
  ],
  [
    "ros_hematologyAnemiaSubAnswerSupportedBy",
    [
      "ros_hematologyAnemiaLabTests",
      "ros_hematologyAnemiaSymptoms",
      "ros_hematologyAnemiaHistoryOfBloodTransfusion",
      "ros_hematologyAnemiaSubother",
    ],
  ],
  [
    "ros_hematologyAnemiaSubAnswerEtiology",
    [
      "ros_hematologyAnemiaIronDeficiency",
      "ros_hematologyAnemiaPernicious",
      "ros_hematologyAnemiaKidneyDisease",
      "ros_hematologyAnemiaHemolysis",
      "ros_hematologyAnemiaAplastic",
      "ros_hematologyAnemiaChemotherapy",
      "ros_hematologyAnemiaBloodLoss",
      "ros_hematologyAnemiaChronicDisease",
      "ros_hematologyAnemiaFolateDeficiency",
      "ros_hematologyAnemiaSubEtiologySubOther",
    ],
  ],
  [
    "ros_hematologyAnemiaSubAnswerYesPatientOn",
    [
      "ros_hematologyAnemiaIron",
      "ros_hematologyAnemiaBTwelve",
      "ros_hematologyAnemiaFolicAcid",
      "ros_hematologyAnemiaBloodTransfusions",
      "ros_hematologyAnemiaSubYesPatientOn",
    ],
  ],

  [
    "ros_hematologyCDifficileSubAnswerDescribe",
    [
      "ros_hematologyCDifficileActive",
      "ros_hematologyCDifficileHistoryOf",
      "ros_hematologyCDifficileRuleOut",
    ],
  ],
  [
    "ros_hematologyCDifficileSubAnswerSupportedBy",
    [
      "ros_hematologyCDifficilePositiveLabTest",
      "ros_hematologyCDifficileSymptoms",
      "ros_hematologyCDifficileHospitalization",
      "ros_hematologyCDifficileMedication",
      "ros_hematologyCDifficileSubOther",
    ],
  ],

  [
    "ros_hematologyCommunityAcquiredSubAnswerDescribe",
    [
      "ros_hematologyCommunityAcquiredActive",
      "ros_hematologyCommunityAcquiredHistoryOf",
      "ros_hematologyCommunityAcquiredRuleOut",
    ],
  ],
  [
    "ros_hematologyCommunityAcquiredSubAnswerSupportedBy",
    [
      "ros_hematologyCommunityAcquiredCultures",
      "ros_hematologyCommunityAcquiredHospitalization",
      "ros_hematologyCommunityAcquiredMedications",
      "ros_hematologyCommunityAcquiredPhysicalFindings",
      "ros_hematologyCommunityAcquiredSubOther",
    ],
  ],

  [
    "ros_hematologyHivSubAnswerDescribe",
    [
      "ros_hematologyHivActive",
      "ros_hematologyHivHistoryOf",
      "ros_hematologyHivRuleOut",
    ],
  ],
  [
    "ros_hematologyHivSubAnswerSupportedBy",
    [
      "ros_hematologyHivLabTests",
      "ros_hematologyHivdMedications",
      "ros_hematologyHivSymptoms",
      "ros_hematologyHivSubOther",
    ],
  ],
  [
    "ros_hematologyHivSubAnswerPatientSymptomatic",
    [
      "ros_hematologyHivPatientSymptomaticYes",
      "ros_hematologyHivPatientSymptomaticNo",
    ],
  ],
  [
    "ros_hematologyHivSubAnswerActiveTreatment",
    [
      "ros_hematologyHivActiveTreatmentYes",
      "ros_hematologyHivActiveTreatmentNo",
    ],
  ],

  [
    "ros_hematologyHerpesZosterSubAnswerDescribe",
    [
      "ros_hematologyHerpesZosterActive",
      "ros_hematologyHerpesZosterHistoryOf",
      "ros_hematologyHerpesZosterRuleOut",
    ],
  ],
  [
    "ros_hematologyHerpesZosterSubAnswerSupportedBy",
    [
      "ros_hematologyHerpesZosterRash",
      "ros_hematologyHerpesZosterSymptoms",
      "ros_hematologyHerpesZosterMedications",
      "ros_hematologyHerpesZosterSubOther",
    ],
  ],

  [
    "ros_hematologyMrsaInfectionSubAnswerDescribe",
    [
      "ros_hematologyMrsaInfectionActive",
      "ros_hematologyMrsaInfectionHistoryOf",
      "ros_hematologyMrsaInfectionRuleOut",
    ],
  ],
  [
    "ros_hematologyMrsaInfectionSubAnswerSupportedBy",
    [
      "ros_hematologyMrsaInfectionCultures",
      "ros_hematologyMrsaInfectionHospitalization",
      "ros_hematologyMrsaInfectionMedications",
      "ros_hematologyMrsaInfectionPhysicalFindings",
      "ros_hematologyMrsaInfectionSubOther",
    ],
  ],

  [
    "ros_hematologyImmuneDeficiencySubAnswerDescribe",
    [
      "ros_hematologyImmuneDeficiencyActive",
      "ros_hematologyImmuneDeficiencyHistoryOf",
      "ros_hematologyImmuneDeficiencyRuleOut",
    ],
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerSupportedBy",
    [
      "ros_hematologyImmuneDeficiencyHistory",
      "ros_hematologyImmuneDeficiencySymptoms",
      "ros_hematologyImmuneDeficiencyPhysicalFindings",
      "ros_hematologyImmuneDeficiencyMedications",
      "ros_hematologyImmuneDeficiencyTestResults",
      "ros_hematologyImmuneDeficiencyImageStudies",
      "ros_hematologyImmuneDeficiencySubOther",
    ],
  ],
  [
    "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic",
    [
      "ros_hematologyImmuneDeficiencyHistoryOfOpportunisticYes",
      "ros_hematologyImmuneDeficiencyHistoryOfOpportunisticNo",
    ],
  ],

  [
    "ros_hematologySepsisSubAnswerDescribe",
    [
      "ros_hematologySepsisActive",
      "ros_hematologySepsisHistoryOf",
      "ros_hematologySepsisRuleOut",
    ],
  ],
  [
    "ros_hematologySepsisSubAnswerSupportedBy",
    [
      "ros_hematologySepsisBloodCultures",
      "ros_hematologySepsisOtherCultures",
      "ros_hematologySepsisSymptoms",
      "ros_hematologySepsisHospitalization",
      "ros_hematologySepsisUnstableVitalSigns",
      "ros_hematologySepsisSubOther",
    ],
  ],

  [
    "ros_hematologySickleCellDiseaseSubAnswerDescribe",
    [
      "ros_hematologySickleCellDiseaseActive",
      "ros_hematologySickleCellDiseaseHistoryOf",
      "ros_hematologySickleCellDiseaseRuleOut",
    ],
  ],
  [
    "ros_hematologySickleCellDiseaseSubAnswerSupportedBy",
    [
      "ros_hematologySickleCellDiseaseFamilyHistory",
      "ros_hematologySickleCellDiseaseSymptoms",
      "ros_hematologySickleCellDiseaseHistoryOfInfections",
      "ros_hematologySickleCellDiseaseHospitalizations",
      "ros_hematologySickleCellDiseaseSubOther",
    ],
  ],

  [
    "ros_hematologySickleCellTraitSubAnswerDescribe",
    [
      "ros_hematologySickleCellTraitActive",
      "ros_hematologySickleCellTraitHistoryOf",
      "ros_hematologySickleCellTraitRuleOut",
    ],
  ],
  [
    "ros_hematologySickleCellTraitSubAnswerSupportedBy",
    [
      "ros_hematologySickleCellTraitFamilyHistory",
      "ros_hematologySickleCellTraitLabTest",
      "ros_hematologySickleCellTraitSubOther",
    ],
  ],

  [
    "ros_hematologyThalassemiaSubAnswerDescribe",
    [
      "ros_hematologyThalassemiaActive",
      "ros_hematologyThalassemiaHistoryOf",
      "ros_hematologyThalassemiaRuleOut",
    ],
  ],
  [
    "ros_hematologyThalassemiaSubAnswerSupportedBy",
    [
      "ros_hematologyThalassemiaFamilyHistory",
      "ros_hematologyThalassemiaSymptoms",
      "ros_hematologyThalassemiaLabTests",
      "ros_hematologyThalassemiaHistoryOfInfections",
      "ros_hematologyThalassemiaSubOther",
    ],
  ],

  [
    "ros_hematologyThrombocytopeniaSubAnswerDescribe",
    [
      "ros_hematologyThrombocytopeniaActive",
      "ros_hematologyThrombocytopeniaHistoryOf",
      "ros_hematologyThrombocytopeniaRuleOut",
    ],
  ],
  [
    "ros_hematologyThrombocytopeniaSubAnswerSupportedBy",
    [
      "ros_hematologyThrombocytopeniaLabTests",
      "ros_hematologyThrombocytopeniaPhysicalFindings",
      "ros_hematologyThrombocytopeniaHistory",
      "ros_hematologyThrombocytopeniaSubOther",
    ],
  ],

  [
    "ros_hematologyTuberculosisSubAnswerDescribe",
    [
      "ros_hematologyTuberculosisActive",
      "ros_hematologyTuberculosishistoryOfActiveTb",
      "ros_hematologyTuberculosistbInfection",
      "ros_hematologyTuberculosisruleOutActiveTb",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerSupportedBy",
    [
      "ros_hematologyTuberculosisHistory",
      "ros_hematologyTuberculosisMedications",
      "ros_hematologyTuberculosisImaginingStudy",
      "ros_hematologyTuberculosisSkinTest",
      "ros_hematologyTuberculosisSymptoms",
      "ros_hematologyTuberculosisPositiveCulture",
      "ros_hematologyTuberculosisSubOther",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerGivenBCG",
    [
      "ros_hematologyTuberculosisGivenBCGYes",
      "ros_hematologyTuberculosisGivenBCGNo",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerActiveTuberculosis",
    [
      "ros_hematologyTuberculosisActiveTuberculosisYes",
      "ros_hematologyTuberculosisActiveTuberculosisNo",
    ],
  ],
  [
    "ros_hematologyTuberculosisSubAnswerTbInfection",
    [
      "ros_hematologyTuberculosisTbInfectionYes",
      "ros_hematologyTuberculosisTbInfectionNo",
    ],
  ],

  [
    "ros_hematologyVitaminDDeficiencySubAnswerDescribe",
    [
      "ros_hematologyVitaminDDeficiencyActive",
      "ros_hematologyVitaminDDeficiencyHistoryOf",
      "ros_hematologyVitaminDDeficiencyRuleOut",
    ],
  ],
  [
    "ros_hematologyVitaminDDeficiencySubAnswerSupportedBy",
    [
      "ros_hematologyVitaminDDeficiencyLabs",
      "ros_hematologyVitaminDDeficiencyMedications",
      "ros_hematologyVitaminDDeficiencyHistory",
      "ros_hematologyVitaminDDeficiencySubOther",
    ],
  ],

  [
    "ros_hematologyHemaOtherSubAnswerDescribe",
    [
      "ros_hematologyOtherActive",
      "ros_hematologyOtherHistoryOf",
      "ros_hematologyOtherRuleOut",
    ],
  ],
  [
    "ros_hematologyHemaOtherSubAnswerSupportedBy",
    [
      "ros_hematologyOtherHistory",
      "ros_hematologyOtherSymptoms",
      "ros_hematologyOtherPhysicalFindings",
      "ros_hematologyOtherMedications",
      "ros_hematologyOtherTestResults",
      "ros_hematologyOtherImageStudies",
      "ros_hematologyOtherBiopsy",
      "ros_hematologyOtherDme",
      "ros_hematologyHemaOtherSubOther",
    ],
  ],
];
const ros_hashmap_srilatha = [
  //cancer
  ["ros_diagnosisOfCancerVal", "diagnosisOfCancer.isDiagnosisOfCancer"],
  ["ros_diagnosisOfCancerCommentVal", "diagnosisOfCancer.comment"],

  [
    "ros_takemedicationsasprescribedCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].takeMedicationsAsPescribed",
  ],

  [
    "ros_otherCancerVal",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].other",
  ],
  [
    "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[0].comment",
  ],
  [
    "ros_diagnosisOfCancerRecommendationsotherCancerComment",
    "recommendationsDiagnosisOfCancer.recommendationsDiagnosisOfCancerSubAnswers[1].comment",
  ],

  [
    "ros_diagnosisOfCancerSubDescActiveVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.active",
  ],
  [
    "ros_diagnosisOfCancerSubDescHistoryOfVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.historyOf",
  ],
  [
    "ros_diagnosisOfCancerSubDescRuleOutVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.ruleOut",
  ],

  [
    "ros_diagnosisOfCancerSubDescComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.describe.comment",
  ],
  [
    "ros_diagnosisOfCancerSubSupPhyFinVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_diagnosisOfCancerSubSuphospVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.hospitalization",
  ],

  [
    "ros_diagnosisOfCancerSubSuptreatVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.treatments",
  ],
  [
    "ros_diagnosisOfCancerSubSuplabTestsVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_diagnosisOfCancerSubSupimagVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_diagnosisOfCancerSubSupSurVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.surgery",
  ],
  [
    "ros_diagnosisOfCancerSubSupBioVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_diagnosisOfCancerSubSupOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.other",
  ],
  [
    "ros_diagnosisOfCancerSubSupOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_diagnosisOfCancerSubSupComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.supportedBy.comment",
  ],

  [
    "ros_diagnosisOfCancerSubTypeBrainVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.brain",
  ],
  [
    "ros_diagnosisOfCancerSubTypeHeadVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.head",
  ],
  [
    "ros_diagnosisOfCancerSubTypeNeckVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.neck",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBreastVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.breast",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLungVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lung",
  ],
  [
    "ros_diagnosisOfCancerSubTypeEsophagusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.esophagus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeStomachVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.stomach",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLiverVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.liver",
  ],
  [
    "ros_diagnosisOfCancerSubTypePancreasVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.pancreas",
  ],
  [
    "ros_diagnosisOfCancerSubTypeColonVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.colon",
  ],
  [
    "ros_diagnosisOfCancerSubTypeRectumVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.rectum",
  ],
  [
    "ros_diagnosisOfCancerSubTypeKidyneyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.kidney",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBladderVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bladder",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOvariesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.ovaries",
  ],
  [
    "ros_diagnosisOfCancerSubTypeUterusVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.uterus",
  ],
  [
    "ros_diagnosisOfCancerSubTypeProstateVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.prostate",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.bone",
  ],
  [
    "ros_diagnosisOfCancerSubTypeBloodVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.blood",
  ],
  [
    "ros_diagnosisOfCancerSubTypeLymphNodesVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.lymphNodes",
  ],

  [
    "ros_diagnosisOfCancerSubTypeSkinVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.skin",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.other",
  ],
  [
    "ros_diagnosisOfCancerSubTypeOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.otherSubAnswer.describe",
  ],

  [
    "ros_diagnosisOfCancerSubTypeComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.type.comment",
  ],

  [
    "ros_diagnosisOfCancerSubSpecTyVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.specialTypes",
  ],

  [
    "ros_diagnosisOfCancerSubStageSpecCanVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.stageSpecificToTheCancer",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatment",
  ],
  [
    "ros_diagnosisOfCancerros_activeTreatmentComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatChemoVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.chemotherapy",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.radiation",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatStemVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.stemCell",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatBoneVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.boneMarrow",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSurgVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.surgery",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatImmuVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.immuneSystem",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.other",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.otherSubAnswer.describe",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.activeTreatment.comment",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.nausea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.vomiting",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.diarrhea",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.anemia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.neutropenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.thrombocytopenia",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.weakness",
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.lossOfAppetite",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.other",
  ],
  [
    "ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.otherSubAnswer.describe",
  ],

  [
    "ros_diagnosisOfCancerSubSideEffComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.activeTreatment.isActiveTreatmentSubAnswer.sideEffects.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasis",
  ],

  [
    "ros_diagnosisOfCancerros_MetastasisComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.comment",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisLocVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.location",
  ],

  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.isCachexia",
  ],
  [
    "ros_diagnosisOfCancerSubMetastasisCachexiaValComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.findingOfMetastasis.isFindingOfMetastasisSubAnswer.cachexia.comment",
  ],

  [
    "ros_diagnosisOfCancerSubSeeASpecVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialist",
  ],
  [
    "ros_diagnosisOfCancerSubSeeASpecProVal",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.isSeeASpecialistSubAnswer.Provider",
  ],
  [
    "ros_diagnosisOfCancerros_SeeASpecComment",
    "diagnosisOfCancer.isDiagnosisOfCancerSubAnswer.seeASpecialist.comment",
  ],

  //cardio

  ["ros_cardioVal", "cardiovascular.isCardiovascular"],
  ["ros_cardioComment", "cardiovascular.comment"],

  [
    "ros_cardioSubDiagnoses",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses",
  ],

  // [
  //   "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
  //   "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe",
  // ],

  [
    "ros_cardioSubDiagnosesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythm",
  ],
  [
    "ros_cardioSubDiagnosesaneurysmVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysm",
  ],
  [
    "ros_cardioSubDiagnosesanginaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.angina",
  ],
  [
    "ros_cardioSubDiagnosesatrialFibrillationVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillation",
  ],
  [
    "ros_cardioSubDiagnosescardioRespiratoryShockVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShock",
  ],
  [
    "ros_cardioSubDiagnosescardiomyopathyVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathy",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.dilated",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.hypertrophic",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.restrictive",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.unknown",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.type.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupEcho",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.echo",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupCardiacCath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.cardiacCath",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupChestPain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCardiomyopathySupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiompathysecondaryToHypertension",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.hypertension.isHypertension",
  ],
  [
    "ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardiomyopathySubAnswer.hypertension.comment",
  ],

  [
    "ros_cardioSubDiagnosescongestiveHeartFailureVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailure",
  ],
  [
    "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosis",
  ],
  [
    "ros_cardioSubDiagnoseshyperlipidemiaVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemia",
  ],
  [
    "ros_cardioSubDiagnoseshypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertension",
  ],
  [
    "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDisease",
  ],
  [
    "ros_cardioSubDiagnosesmyocardialInfarctionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarction",
  ],
  [
    "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDisease",
  ],
  [
    "ros_cardioSubDiagnosespulmonaryHypertensionVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertension",
  ],
  [
    "ros_cardioSubDiagnosesvalvularDiseaseVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDisease",
  ],
  [
    "ros_cardioSubDiagnosesotherVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.other",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.rateControllingDrug",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.anticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.electrophysiology",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chestpain",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedPacemaker",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.bradycardia",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.tachycardia",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregular",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.ispatientHaveAtrial",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.regularlyIrregularSubAnswer.comment",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.irregularlyIrregular",
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.prematureContractures",
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.abnormalCardiacRhythmSubAnswer.crDescribe.comment",
  ],

  //aneurysm

  [
    "ros_cardioSubDiagnosesAneurysmDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.historyOf",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupPhyExam",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupultra",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.ultraSoundLastStudy",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupImage",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.ImageStudies",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.ChestPain",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.abdominal",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.thoracic",
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.peripheral",
  ],

  [
    "ros_cardioSubDiagnosesAneurysmCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.aneurysmSubAnswer.aneurysmDescribe.comment",
  ],

  //

  //angina

  [
    "ros_cardioSubDiagnosesAnginaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAnginaDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupMedi",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSuphistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.historyCharacterizingChestPain",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupstressTest",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.stressTest",
  ],

  [
    "ros_cardioSubDiagnosesAnginaSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAnginaSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAnginaSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesStable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.stable",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesUnstable",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.unstable",
  ],

  [
    "ros_cardioSubDiagnosesAnginaCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.anginaSubAnswer.anginaDescribe.comment",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.paroxysmal",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.chronic",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.unknown",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationTypeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.type.comment",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupMedi",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.history",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupelectroCardio",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.electricCardioversion",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.anticoagulant",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.rateControllingMedication",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.other",
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.otherSubAnswer.describe",
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.atrialFibrillationSubAnswer.patientTaking.comment",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.history",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.implantedDefibrillator",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.cardiacArrest.isCardiacArrest",
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.cardioRespiratoryShockSubAnswer.cardiacArrest.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acute",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.chronic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.acuteOnChronic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.specify.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.ejectionFraction",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.cardiomegaly",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.orthopnea",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.doe",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.pnd",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupS3",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.sthree",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.peripheralEdema",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.diastolic",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.systolic",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.unknown",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.heartFailureDescribe.comment",
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.isSecondaryToHypertension",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.secondaryToHypertension.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnAce.ispatientOnAce",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnAce.comment",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnBetaBlocker.isPatientOnBetaBlocker",
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.congestiveHeartFailureSubAnswer.patientOnBetaBlocker.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.acute",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.chronic",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.describe.comment",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.active",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.dvtDescribe.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubprophylactic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.prophylactic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubtherapeutic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.therapeutic",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.useOfAnticoagulationSubAnswer.comment",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.vascularStudies",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.venaCavaFilter",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.edema",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisPersistent",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.persistentForThreeMonths.isPersistentForThreeMonths",
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.deepVeinThrombosisSubAnswer.persistentForThreeMonths.comment",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupLabResults",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.labResults",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.chestPain",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.lightHeadedness",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.isPatientOnStatin",
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hyperlipidemiaSubAnswer.patientOnStatin.comment",
  ],

  //hypertension
  [
    "ros_cardioSubDiagnosesHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupPhysicalExam",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.isAdequatelyControlled",
  ],

  [
    "ros_cardioSubDiagnosesHypertensionadequatelyControlledComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.hypertensionSubAnswer.adequatelyControlled.comment",
  ],

  //ischemicheartdisease

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupCardiacCath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.cardiacCath",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.historyOfCoronaryStent",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupDiagnosis",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.diagnosisOfAngina",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupCabgs",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.historyOfCabg",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupEcg",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.ecg",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.other",
  ],

  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.ischemicHeartDiseaseSubAnswer.supportedBy.comment",
  ],

  //myocard

  [
    "ros_cardioSubDiagnosesMyocardialDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.date",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupECG",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.ecgChanges",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupLabResults",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.labResults",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.historyOfHospitalization",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialBetaBlocker",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientBetaBlocker.isPatientBetaBlocker",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialBetaBlockerComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientBetaBlocker.comment",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintAspirin",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.aspirin",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintPlavix",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.plavix",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintNitrate",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.nitrate",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintOther",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.other",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateintComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.myocardialInfarctionSubAnswer.patientTaking.comment",
  ],

  //peripheralvasculardisease
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupVascular",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.vascularStudies",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupClaudication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.claudication",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupExtremity",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.extremityUlcers",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupDiminished",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.diminished",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupAmputation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.amputation",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardiohistoryDiabetesisHistoryDiabetes",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.historyDiabetes.isHistoryDiabetes",
  ],
  [
    "ros_cardiohistoryDiabetesisHistoryDiabetesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.historyDiabetes.comment",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cardiopvdDescribeUlceration",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.pvdDescribe.ulceration",
  ],
  [
    "ros_cardiopvdDescribeGangrene",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.pvdDescribe.gangrene",
  ],
  [
    "ros_cardiopvdDescribeComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.pvdDescribe.comment",
  ],

  // [
  //   "ros_cardioSubDiagnosesPeripheralVascularDiseaseCrDesComment",
  //   "cardiovascular.isCardiovascularSubAnswer.diagnoses.peripheralVascularDiseaseSubAnswer.crDescribe.comment",
  // ],

  //pulmonaryhypertension

  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupCardiacCath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.cardiacCath",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupO2",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.useOfOxygen",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupEdema",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.edema",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupMedication",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.medication",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.pulmonaryHypertensionSubAnswer.supportedBy.comment",
  ],

  //valvulardisease
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupPhysicalFindings",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupTestResults",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupImageStudies",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupBiopsy",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupDME",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.dme",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupchestpain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.other",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.supportedBy.comment",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.mitralStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.aorticStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.tricuspidStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.pulmonaryInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.pulmonaryStenosis",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.aorticInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.mitralInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.tricuspidInsufficiency",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.vdDescribe.comment",
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseValveReplacement",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valveReplacement.isValveReplacement",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valveReplacement.comment",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.valueTypeOfReplacement",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.patientAnticoagulation.isPatientAnticoagulation",
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.valvularDiseaseSubAnswer.patientAnticoagulation.comment",
  ],
  //other
  [
    "ros_cardioSubDiagnosesOtherDesActive",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesHistoryOf",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_cardioSubDiagnosesOtherDesruleOut",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],

  [
    "ros_cardioSubDiagnosesOtherSupHistory",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupSymptoms",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupPhysicalFindings",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupMedications",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupTestResults",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupImageStudies",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupBiopsy",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupDME",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupChestPain",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.chestPain",
  ],
  [
    "ros_cardioSubDiagnosesOtherSuplightHeadedness",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.lightHeadedness",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupshortnessOfBreath",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.shortnessOfBreath",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupwheezing",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.wheezing",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupchronicCough",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.chronicCough",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupother",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupComment",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_cardioSubDiagnosesOtherSupotherSubAnswer",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_cardioSubDiagnosesOtherOtherSub",
    "cardiovascular.isCardiovascularSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  //

  [
    "ros_takemedicationsasprescribedCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].takeMedicationsAsPescribed",
  ],

  [
    "ros_otherCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].other",
  ],
  [
    "ros_ThirtyCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].exerciseThirtyMinADay",
  ],
  [
    "ros_bloodPreCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].bloodPressureChecks",
  ],
  [
    "ros_heartHeCardioVal",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].heartHealthyDiet",
  ],
  [
    "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[3].comment",
  ],

  [
    "ros_cardiovascularRecommendationsotherComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[4].comment",
  ],

  [
    "ros_cardiovascularRecommendationsexerciseThirtyMinADayComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[2].comment",
  ],
  [
    "ros_cardiovascularRecommendationsbloodPressureChecksComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[0].comment",
  ],
  [
    "ros_cardiovascularRecommendationsheartHealthyDietComment",
    "recommendationsCardiovascular.recommendationsCardiovascularSubAnswers[1].comment",
  ],

  ["ros_gastro", "gastrointestinal.isGastrointestinal"],
  ["ros_gastroComment", "gastrointestinal.comment"],

  [
    "ros_gastroSubDiagnoses",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses",
  ],
  [
    "ros_gastroSubDiagnosesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstruction",
  ],
  [
    "ros_gastroSubDiagnosescachexiaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexia",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDisease",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosis",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolyps",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitis",
  ],
  [
    "ros_gastroSubDiagnosesgallBladderDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDisease",
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesis",
  ],
  [
    "ros_gastroSubDiagnosesgerdVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerd",
  ],
  [
    "ros_gastroSubDiagnosesglBleedVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleed",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitis",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDisease",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitis",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDisease",
  ],
  [
    "ros_gastroSubDiagnosesotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.other",
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesbowelObstructionComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.bowelObstructionSubAnswer.supportedBy.comment",
  ],

  //cachexia

  [
    "ros_gastroSubDiagnosescachexiadesactive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosescachexiadeshistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescachexiadesruleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosescachexiadesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupalbuminVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.albumin",
  ],
  [
    "ros_gastroSubDiagnoscachexiaSupmuscleWastingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.muscleWasting",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.historyOfSevere",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosescachexiaSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cachexiaSubAnswer.supportedBy.comment",
  ],
  //celiac
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesceliacDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.weightLoss",
  ],
  [
    "ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.anemia",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.changeInBowelMovements",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.osteoporosis",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.diet",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseGlueten",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.glutenFreeDiet.isGlutenFreeDiet",
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseGluetenComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.celiacDiseaseSubAnswer.glutenFreeDiet.comment",
  ],

  //cirhosis

  [
    "ros_gastroSubDiagnosesdescirrhosisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdescirrhosisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupsymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupLabStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.labStudies",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupMriVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.mri",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupBoipsyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupabdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupOtherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStage",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDisease",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisEndStageComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.comment",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.comment",
  ],
  [
    "ros_gastroSubDiagnosescirrhosisEndStageAscites",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.ascites",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStageVarices",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.varices",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStageEncephalopathy",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.encephalopathy",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStageHepatorenalSyndrome",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.hepatorenalSyndrome",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisEndStageOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.endStageLiverDisease.isEndStageLiverDiseaseSubAnswer.other",
  ],

  [
    "ros_gastroSubDiagnosescirrhosisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.cirrhosisSubAnswer.supportedBy.comment",
  ],

  //colon
  [
    "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosescolonSuphistoryVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.history",
  ],
  [
    "ros_gastroSubDiagnosescolonSupsymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosescolonSupPhysicalfindingsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_gastroSubDiagnosescolonSupmedicationsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.medications",
  ],
  [
    "ros_gastroSubDiagnosescolonSupTestResultsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_gastroSubDiagnosescolonSupImageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_gastroSubDiagnosescolonSupBiopsyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.biopsy",
  ],

  [
    "ros_gastroSubDiagnosescolonDmeVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.dme",
  ],

  [
    "ros_gastroSubDiagnosescolonAbdominalPainVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosescolonNausaVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosescolonOtherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosescolonOtherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosescolonSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosescolonCrDesBengirVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.benign",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.carcinomaInSitu",
  ],
  [
    "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.familialPolyposis",
  ],

  [
    "ros_gastroSubDiagnosescolonCrDesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.colonPolypsShockSubAnswer.cpDescribe.comment",
  ],

  //diverticultis
  [
    "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesdiverticulitisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.describe.comment",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.colonoscopy",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.diet",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.abdominalPain",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupotherVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesdiverticulitisabscessVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.abscess.isAbscess",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisabscessValComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.abscess.comment",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisperforationVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.perforation.isPerforation",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisperforationValComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.perforation.comment",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitishighFiberDietVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.highFiberDiet.ishighFiberDiet",
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.diverticulitisSubAnswer.highFiberDiet.comment",
  ],

  //gallbladder
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseRuleOutVal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_gastroSubDiagnosesdesgallBladderDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupSymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupPassingOfStones",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.passingOfStones",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupErcp",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.ercp",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupHidaScan",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.hidaScan",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupMri",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.mri",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupTreatmentHistory",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.treatmentHistory",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSupComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gallBladderDiseaseSubAnswer.supportedBy.comment",
  ],
  //gastroparesis

  [
    "ros_gastroSubDiagnosesgastroparesisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.gastricEmptyingSets",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByHistory",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.history",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgastroparesisSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gastroparesisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesgerdDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesgerdDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesgerdDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesgerdDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByHeartburn",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.heartburn",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByRegurgitation",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.regurgitation",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.gerdSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosesglBleedDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesglBleedDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesglBleedDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesglBleedDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByBloodInStool",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.bloodInStool",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.glBleedSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByPhysicalFindings",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByLabStudies",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.labStudies",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByJuandice",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.juandice",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisTypeA",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.type.a",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeB",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.type.b",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisTypeC",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.type.c",
  ],

  [
    "ros_gastroSubDiagnoseshepatitisTypeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.type.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisAcute",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.hepatitisDescribe.acute",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisChronic",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.hepatitisDescribe.chronic",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDessComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.hepatitisDescribe.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisCirrhosis",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.cirrhosis.isCirrhosis",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisCirrhosisComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.cirrhosis.comment",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.hepatocellularCarcinoma.isHepatocellularCarcinoma",
  ],
  [
    "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.hepatitisSubAnswer.hepatocellularCarcinoma.comment",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByColonoscopy",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.colonoscopy",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByPhysicalFindings",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.ibdDescribe.ulcerativeColitis",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.ibdDescribe.crohnsDisease",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.ibdDescribe.other",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.ibdDescribe.comment",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.specificDiet.isSpecificDiet",
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.inflammatoryBowelDiseaseSubAnswer.specificDiet.comment",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByLabStudies",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.labStudies",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByErcp",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.ercp",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByAlcoholism",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.alcoholism",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByUseOfCertainMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.useOfCertainMedications",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByMri",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.mri",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByFamilyHistory",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.familyHistory",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodes",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.recurrentEpisodes.isRecurrentEpisodes",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.recurrentEpisodes.comment",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSpecificDiet",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.specificDiet.isSpecificDiet",
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSpecificDietComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.pancreatitisSubAnswer.specificDiet.comment",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByEndoscopicFindings",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.endoscopicFindings",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByCulture",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.culture",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseEsophageal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.esophageal",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseGastric",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.gastric",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseDuodenal",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.duodenal",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.other",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.otherSubAnswer.describe",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.ulcerDescribe.comment",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseHeliobactria",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.heliobactria.isHeliobactria",
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.ulcerDiseaseSubAnswer.heliobactria.comment",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeActive",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeHistoryOf",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeRuleOut",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],

  [
    "ros_gastroSubDiagnosesotherDescribeComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByHistory",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedBySymptoms",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByPhysicalFindings",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByMedications",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByTestResults",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByImageStudies",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByBiopsy",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByDme",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByAbdominalPain",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.abdominalPain",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.nauseaAndVomiting",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedByComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_gastroSubDiagnosesotherOther",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "ros_gastroSubDiagnosesComment",
    "gastrointestinal.isGastrointestinalSubAnswer.diagnoses.comment",
  ],

  [
    "ros_gastrorecommendationstakemedicationsasprescribed",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_gastrorecommendationstakemedicationsasprescribedComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[0].comment",
  ],

  [
    "ros_gastrorecommendationsother",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].other",
  ],
  [
    "ros_gastrorecommendationsotherComment",
    "recommendationsGastrointestinal.recommendationsGastrointestinalSubAnswers[1].comment",
  ],

  ["ros_genito", "genitourinary.isGenitourinary"],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailure",
  ],
  [
    "ros_genitoSubDiagnosesbphVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bph",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDisease",
  ],
  [
    "ros_genitoSubDiagnosesesrdVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrd",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunction",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUti",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecological",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStones",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosis",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinence",
  ],
  [
    "ros_genitoSubDiagnosesotherVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.other",
  ],

  [
    "ros_genitoSubDiagnosesComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesactive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesruleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailuredesComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupLabTests",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupCalculatedGFR",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.calculatedGFR",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupHospitalization",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureSupComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesacuteRenalFailureEtiology",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.acuteRenalFailureSubAnswer.etiology",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByPhysicalExam",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByLabTest",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.labTest",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByBiopsy",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByMedication",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.medication",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByHospitalization",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesbphDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.bphSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByLabTests",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByCalculatedGfr",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.supportedBy.calculatedGfr",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageOne",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageOne",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageTwo",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageTwo",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageThreeA",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageThreeA",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageThreeB",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageThreeB",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageFour",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageFour",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageStageFive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.stageFive",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageUnknown",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.unknown",
  ],
  [
    "ros_genitoSubDiagnoseswhatStageComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.whatStage.comment",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.secondaryToDiabetes.isSecondaryToDiabetes",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.secondaryToDiabetes.comment",
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.secondaryToHypertension.isSecondaryToHypertension",
  ],

  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.chronicKidneyDiseaseSubAnswer.secondaryToHypertension.comment",
  ],

  [
    "ros_genitoSubDiagnosesesrdDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByLabTests",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByCalculatedGfr",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.calculatedGfr",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByHospitalization",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysis",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysis",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.comment",
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.type.hemodialysis",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.type.peritonealDialysis",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.type.comment",
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.location.inHome",
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.location.dialysisCenter",
  ],

  [
    "ros_genitoSubDiagnosesesrddialysisSchedule",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.dialysisSchedule",
  ],
  [
    "ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.typeAndLocationOfAccessDevice",
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.patientOnDialysis.isPatientOnDialysisSubAnswer.location.comment",
  ],
  [
    "ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.specialDiet.isspecialDiet",
  ],

  [
    "ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.esrdSubAnswer.specialDiet.comment",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.history",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByPhysicalFindings",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByTestResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.erectileDysfunctionSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedByCultures",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.cultures",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedByLaboratoryResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.laboratoryResults",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.frequentUtiSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnosesgynecologicalSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.history",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByDiagnosticResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.diagnosticResults",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByVaginalBleeding",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.vaginalBleeding",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_genitoSubDiagnosesgynecologicalSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.gynecologicalSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.describe.ruleOut",
  ],

  [
    "ros_genitoSubDiagnoseskidneyStonesDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.history",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByPhysicalFindings",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByTestResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByImageStudies",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeUrate",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.type.urate",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.type.calciumOxalate",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeMagnesium",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.type.magnesium",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.type.other",
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesTypeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.kidneyStonesSubAnswer.type.comment",
  ],

  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.describe.comment",
  ],

  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedicalHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.medicalHistory",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByLabTests",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByImagingStudies",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByBiopsy",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.other",
  ],

  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.nephritisOrNephrosisSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.history",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByPhysicalFindings",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByTestResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByDME",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.dme",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.other",
  ],

  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceRelatedToStressIsRelatedToStress",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStress",
  ],
  [
    "ros_genitoStressSubDribbling",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.dribbling",
  ],
  [
    "ros_genitoStressSubUrgency",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.urgency",
  ],
  [
    "ros_genitoStressSubOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.other",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.uiDescribe.daily",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.uiDescribe.fewTimesAWeek",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.uiDescribe.lessThanOnceAWeek",
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.uiDescribe.comment",
  ],
  [
    "ros_genitoStress",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStress",
  ],
  [
    "ros_genitoStressComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.comment",
  ],
  [
    "ros_genitoStressSubComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.urinaryIncontinenceSubAnswer.relatedToStress.isRelatedToStressSubAnswer.comment",
  ],

  [
    "ros_genitoSubDiagnosesotherDescribeActive",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeHistoryOf",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeRuleOut",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_genitoSubDiagnosesotherDescribeComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByHistory",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedBySymptoms",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByPhysicalFindings",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByMedications",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByTestResults",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByImageStudies",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByBiopsy",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByDME",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_genitoSubDiagnosesotherOther",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "ros_genitoSubDiagnosesotherSupportedByComment",
    "genitourinary.isGenitourinarySubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  ["ros_genitoComment", "genitourinary.comment"],

  [
    "ros_takemedicationsasprescribedGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].takeMedicationsAsPescribed",
  ],

  [
    "ros_otherGenitoVal",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].other",
  ],
  [
    "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[0].comment",
  ],

  [
    "ros_genitoRecommendationsotherGenitoComment",
    "recommendationsGenitourinary.recommendationsGenitourinarySubAnswers[1].comment",
  ],

  ["ros_endocrineIsEndocrine", "endocrine.isEndocrine"],
  ["ros_endocrineIsEndocrineComment", "endocrine.comment"],
  [
    "ros_endocrineIsEndocrineDiagnoseComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosechronicKidneyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidney",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubRuleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdecreasedGFR",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.decreasedGFR",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubalbuminuria",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.albuminuria",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubelevatedBun",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.elevatedBun",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdialysis",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.dialysis",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.patientOnAce.isPatientOnAce",
  ],
  [
    "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.chronicKidneySubAnswer.patientOnAce.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosecoronaryArteryVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArtery",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.describe.active",
  ],

  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubhistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.history",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubsymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubphysicalFindings",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubmedications",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.medications",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubtestResults",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.testResults",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubimageStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubbiopsy",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubdme",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.dme",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.patientOnAStatin.isPatientOnAStatin",
  ],
  [
    "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.patientOnAStatin.comment",
  ],
  [
    "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.patientOnAAspirin.isPatientOnAAspirin",
  ],
  [
    "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.coronaryArterySubAnswer.patientOnAAspirin.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDisease",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubphysicalExam",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySublabTests",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubsuppressionTest",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.suppressionTest",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.cushingsDiseaseSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosediabetesVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetes",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptoms",
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubincreasedThirst",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptomsSubAnswer.increasedThirst",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubshakiness",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptomsSubAnswer.shakiness",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubsweating",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptomsSubAnswer.sweating",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubblurredVision",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptomsSubAnswer.blurredVision",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],

  [
    "endocrineSubdiabetesSubsupportedByphysicalFindings",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "endocrineSubdiabetesSubsupportedBylabTests",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.labTests",
  ],
  [
    "endocrineSubdiabetesSubsupportedBymedications",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.medications",
  ],
  [
    "endocrineSubdiabetesSubsupportedByother",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubtypetypeOne",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.type.typeOne",
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesSubtypetypeOnePointFive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.type.typeOnePointFive",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubtypetypeTwo",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.type.typeTwo",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubtypegestational",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.type.gestational",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubtypeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.type.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.recentHbAonecValue",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubandDate",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.andDate",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.dietician.isDietician",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.dietician.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducator",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubdiet",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.diet",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSuboralHypoglycemic",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.oralHypoglycemic",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubinsulin",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.insulin",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubexercise",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.exercise",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.isDiabeticEducatorSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.diabeticEducator.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.bloodSugar.isbloodSugar",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabetesSubAnswer.bloodSugar.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosediabeticRetinopathyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathy",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.describe.historyOf",
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubproliferative",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.type.proliferative",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubnonProliferative",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.type.nonProliferative",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubunknown",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.type.unknown",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.type.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubrightEye",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.specify.rightEye",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubleftEye",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.specify.leftEye",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubbilateral",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.specify.bilateral",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.specify.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubfunduscopicExam",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.funduscopicExam",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubvisionLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.visionLoss",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySublaserTherapy",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.laserTherapy",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubretinalInjections",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.retinalInjections",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubsurgicalProcedure",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.surgicalProcedure",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSuboccasionally",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.ophthalmologist.occasionally",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubonceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.ophthalmologist.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubtwiceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.ophthalmologist.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreaterTwiceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.ophthalmologist.greaterTwiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.diabeticRetinopathySubAnswer.ophthalmologist.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubchronicKidneyDisease",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.chronicKidneyDisease",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryCeliacDisease",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.historyCeliacDisease",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubvitaminDDeficiency",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.vitaminDDeficiency",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubmalabsorption",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.malabsorption",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubbariatricSurgery",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.bariatricSurgery",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySublabTests",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfKidneyStones",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.historyOfKidneyStones",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfFractures",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.historyOfFractures",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubimagingStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.kidneyDisease.isKidneyDisease",
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.secondaryHyperparathyroidismSubAnswer.kidneyDisease.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehypertensionDiabetesVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetes",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubhistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.history",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubsymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubphysicalFindings",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubmedications",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubtestResults",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubimageStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubbiopsy",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubdme",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.dme",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.patientOnAce.isPatientOnAce",
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypertensionDiabetesSubAnswer.patientOnAce.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehyperthyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubrapidHeartBeat",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.rapidHeartBeat",
  ],

  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubnervousness",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.nervousness",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubweightLoss",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.weightLoss",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubheatIntolerance",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.heatIntolerance",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubtremor",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.tremor",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySublabData",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.labData",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhistoryOfTreatment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.historyOfTreatment",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhyperReflexes",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.hyperReflexes",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperthyroidismSubAnswer.supportedBy.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehypothyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidism",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubruleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.describe.comment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubweightGain",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.weightGain",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.fatigue",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubhairChanges",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.hairChanges",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubdepression",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.depression",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubtreatment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.treatment",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySublabData",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.labData",
  ],

  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubother",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hypothyroidismSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathy",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeRuleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupPhysical",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupMedication",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.medication",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubnumbness",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptomsSubAnswer.numbness",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubpain",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubburning",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptomsSubAnswer.burning",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubdecreasedSensation",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptomsSubAnswer.decreasedSensation",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.patientSeesPodiatrist",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubonceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubtwiceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubquarterly",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.quarterly",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralNeuropathySubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascular",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeRuleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupPhysical",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupVascularStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.vascularStudies",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupskinLesions",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.skinLesions",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupfootDeformity",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.footDeformity",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupsurgicalProcedures",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.surgicalProcedures",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupintermittentClaudication",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.intermittentClaudication",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.patientSeesPodiatrist",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubonceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.onceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubtwiceAYear",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.twiceAYear",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubquarterly",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.quarterly",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.peripheralVascularSubAnswer.patientSeesPodiatrist.patientSeesPodiatristSubAnswer.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidism",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeRuleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuplabTests",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.labTests",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfKidneyStones",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.historyOfKidneyStones",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfFractures",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.historyOfFractures",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfimagingStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.imagingStudies",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupfatigue",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.fatigue",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.hyperparathyroidismSubAnswer.supportedBy.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseotherVal",
    "endocrine.isEndocrineSubAnswer.diagnoses.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeActive",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeRuleOut",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribeComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseotherSuphistory",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupSymptoms",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupMedication",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSuptestResults",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupimageStudies",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupbiopsy",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupdme",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],

  [
    "ros_endocrineIsEndocrineDiagnoseotherSupComment",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherOther",
    "endocrine.isEndocrineSubAnswer.diagnoses.otherSubAnswer.other",
  ],
  [
    "ros_takeMedicationsAsPescribedEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].takeMedicationsAsPescribed",
  ],
  [
    "ros_checkBloodSugarEndoEndorineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].checkBloodSugar",
  ],
  [
    "ros_otherEndoEndocrineVal",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].other",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[0].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[1].comment",
  ],
  [
    "ros_endocrineIsEndocrineRecommendationsotherEndoComment",
    "recommendationsEndocrine.recommendationsEndocrineSubAnswers[2].comment",
  ],
];

const ros_optionsTreeMapObject_srilatha = {
  ros_diagnosisOfCancerSubDescActiveVal: "active",
  ros_diagnosisOfCancerSubDescHistoryOfVal: "historyOf",
  ros_diagnosisOfCancerSubDescRuleOutVal: "ruleOut",

  ros_diagnosisOfCancerSubSupPhyFinVal: "physicalFindings",
  ros_diagnosisOfCancerSubSuphospVal: "hospitalization",
  ros_diagnosisOfCancerSubSuptreatVal: "treatments",
  ros_diagnosisOfCancerSubSuplabTestsVal: "labTests",
  ros_diagnosisOfCancerSubSupimagVal: "imagingStudies",
  ros_diagnosisOfCancerSubSupSurVal: "surgery",
  ros_diagnosisOfCancerSubSupBioVal: "biopsy",
  ros_diagnosisOfCancerSubSupOtherVal: "other",

  ros_diagnosisOfCancerSubTypeBrainVal: "brain",
  ros_diagnosisOfCancerSubTypeHeadVal: "head",
  ros_diagnosisOfCancerSubTypeNeckVal: "neck",
  ros_diagnosisOfCancerSubTypeBreastVal: "breast",
  ros_diagnosisOfCancerSubTypeLungVal: "lung",
  ros_diagnosisOfCancerSubTypeEsophagusVal: "esophagus",
  ros_diagnosisOfCancerSubTypeStomachVal: "stomach",
  ros_diagnosisOfCancerSubTypeLiverVal: "liver",
  ros_diagnosisOfCancerSubTypePancreasVal: "pancreas",
  ros_diagnosisOfCancerSubTypeColonVal: "colon",
  ros_diagnosisOfCancerSubTypeRectumVal: "rectum",
  ros_diagnosisOfCancerSubTypeKidyneyVal: "kidney",
  ros_diagnosisOfCancerSubTypeBladderVal: "bladder",
  ros_diagnosisOfCancerSubTypeOvariesVal: "ovaries",
  ros_diagnosisOfCancerSubTypeUterusVal: "uterus",
  ros_diagnosisOfCancerSubTypeProstateVal: "prostate",
  ros_diagnosisOfCancerSubTypeBoneVal: "bone",
  ros_diagnosisOfCancerSubTypeBloodVal: "blood",
  ros_diagnosisOfCancerSubTypeLymphNodesVal: "lymphNodes",
  ros_diagnosisOfCancerSubTypeSkinVal: "skin",
  ros_diagnosisOfCancerSubTypeOtherVal: "other",

  ros_diagnosisOfCancerSubActiveTreatChemoVal: "chemotherapy",
  ros_diagnosisOfCancerSubActiveTreatRadiaVal: "radiation",
  ros_diagnosisOfCancerSubActiveTreatStemVal: "stemCell",
  ros_diagnosisOfCancerSubActiveTreatBoneVal: "boneMarrow",
  ros_diagnosisOfCancerSubActiveTreatSurgVal: "surgery",
  ros_diagnosisOfCancerSubActiveTreatImmuVal: "immuneSystem",
  ros_diagnosisOfCancerSubActiveTreatOtherVal: "other",
  ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal: "nausea",
  ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal: "vomiting",
  ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal: "diarrhea",
  ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal: "anemia",
  ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal: "neutropenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal:
    "thrombocytopenia",
  ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal: "weakness",
  ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal: "lossOfAppetite",
  ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmVal: "abnormalCardiacRhythm",
  ros_cardioSubDiagnosesaneurysmVal: "aneursym",
  ros_cardioSubDiagnosesanginaVal: "angina",
  ros_cardioSubDiagnosesatrialFibrillationVal: "atrialFibrillation",
  ros_cardioSubDiagnosescardioRespiratoryShockVal:
    "cardioRespiratoryFailureShock",
  ros_cardioSubDiagnosescardiomyopathyVal: "cardiomyopathy",
  ros_cardioSubDiagnosescongestiveHeartFailureVal: "congestiveHeartFailure",
  ros_cardioSubDiagnosesdeepVeinThrombosisVal: "deepVeinThrombosis",
  ros_cardioSubDiagnoseshyperlipidemiaVal: "hyperlipidemia",
  ros_cardioSubDiagnoseshypertensionVal: "hypertension",
  ros_cardioSubDiagnosesischemicHeartDiseaseVal: "ischemicHeartDisease",
  ros_cardioSubDiagnosesmyocardialInfarctionVal: "myocardialInfarction",
  ros_cardioSubDiagnosesperipheralVascularDiseaseVal:
    "peripheralVascularDisease",
  ros_cardioSubDiagnosespulmonaryHypertensionVal: "pulmonaryHypertension",
  ros_cardioSubDiagnosesvalvularDiseaseVal: "valvularDisease",
  ros_cardioSubDiagnosesotherVal: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal: "active",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal: "historyOf",
  ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal: "ruleOut",

  ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG: "ecg",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug:
    "useofratecontrollingdrug",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation:
    "useOfAnticoagulation",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology:
    "electrophysiologyProcedureCard",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain: "chestPain",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker:
    "implantedPacemaker",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing: "wheezing",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesabnormalCardiacRhythmSupother: "other",

  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia: "bradycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia: "tachycardia",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular:
    "regularlyIrregular",
  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular:
    "irregularlyIrregular",

  ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures:
    "prematureContractures",

  ros_cardioSubDiagnosesAneurysmDesActive: "active",
  ros_cardioSubDiagnosesAneurysmDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAneurysmDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesAneurysmSupPhyExam: "physicalExam",
  ros_cardioSubDiagnosesAneurysmSupultra: "ultrasoundlaststudydate&size",
  ros_cardioSubDiagnosesAneurysmSupImage: "imageStudies",
  ros_cardioSubDiagnosesAneurysmSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAneurysmSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath: "shortnessofbreath",
  ros_cardioSubDiagnosesAneurysmSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAneurysmSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAneurysmSupother: "other",

  ros_cardioSubDiagnosesAneurysmCrDesAbdominal: "abdominal",
  ros_cardioSubDiagnosesAneurysmCrDesThoracic: "thoracic",
  ros_cardioSubDiagnosesAneurysmCrDesPeripheral: "peripheral",

  ros_cardioSubDiagnosesAnginaDesActive: "active",
  ros_cardioSubDiagnosesAnginaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAnginaDesruleOut: "ruleOut",

  //sup

  ros_cardioSubDiagnosesAnginaSupMedi: "medications",
  ros_cardioSubDiagnosesAnginaSuphistory: "history",
  ros_cardioSubDiagnosesAnginaSupstressTest: "stressTest",
  ros_cardioSubDiagnosesAnginaSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAnginaSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAnginaSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesAnginaSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAnginaSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAnginaSupother: "other",

  //cr
  ros_cardioSubDiagnosesAnginaCrDesStable: "stable",
  ros_cardioSubDiagnosesAnginaCrDesUnstable: "unstable",

  ros_cardioSubDiagnosesAtrialFibrillationDesActive: "active",
  ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesAtrialFibrillationDesruleOut: "ruleOut",

  //type
  ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal: "paroxysmal",
  ros_cardioSubDiagnosesAtrialFibrillationTypeChronic: "chronic",
  ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown: "unknown",

  ros_cardioSubDiagnosesAtrialFibrillationSupMedi: "medications",
  ros_cardioSubDiagnosesAtrialFibrillationSupECG: "ecg",
  ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesAtrialFibrillationSupHistory: "history",
  ros_cardioSubDiagnosesAtrialFibrillationSupelectroCardio:
    "electricCardioversion",
  ros_cardioSubDiagnosesAtrialFibrillationSupchestpain: "chestPain",
  ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesAtrialFibrillationSupwheezing: "wheezing",
  ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesAtrialFibrillationSupother: "other",

  //ispatient taking
  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti: "anticoagulant",

  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate:
    "rateControllingMedication",
  ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther: "other",

  ros_cardioSubDiagnosesCardiorespiratoryShockDesActive: "active",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory: "history",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant:
    "implantedDefibrillator",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupchestpain: "chestPain",
  ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing: "wheezing",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCardiorespiratoryShockSupother: "other",

  ros_cardioSubDiagnosesCardiomyopathyDesActive: "active",
  ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCardiomyopathyDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCardiomyopathyTypeDilated: "dilated",
  ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic: "hypertrophic",
  ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive: "restrictive",
  ros_cardioSubDiagnosesCardiomyopathyTypeUnknown: "unknown",

  ros_cardioSubDiagnosesCardiomyopathySupEcho: "echo",
  ros_cardioSubDiagnosesCardiomyopathySupCardiacCath: "cardiacCath",
  ros_cardioSubDiagnosesCardiomyopathySupChestPain: "chestPain",
  ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesCardiomyopathySupwheezing: "wheezing",
  ros_cardioSubDiagnosesCardiomyopathySupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCardiomyopathySupother: "other",

  ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection: "ejectionFraction",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio: "cardiomegaly",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho: "orthopnea",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE: "doe",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd: "pnd",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupS3: "s3",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications: "medications",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema:
    "peripheralEdema",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain: "chestPain",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing: "wheezing",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesCongestiveHeartFailureSupother: "other",

  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute: "acute",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic: "chronic",
  ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic:
    "acuteOnChronic",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesActive: "active",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias: "diastolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys: "systolic",
  ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown: "unknown",

  ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute: "acute",
  ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic: "chronic",

  ros_cardioSubDiagnosesDeepVeinThrombosisDesActive: "active",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings:
    "physicalFindings",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation:
    "useOfAnticoagulation",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubprophylactic:
    "prophylactic",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubtherapeutic:
    "therapeutic",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies: "vascularStudies",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter: "venaCavaFilter",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema: "edema",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain: "chestPain",
  ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing: "wheezing",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesDeepVeinThrombosisSupother: "other",

  ros_cardioSubDiagnosesHyperlipidemiaDesActive: "active",
  ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHyperlipidemiaDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesHyperlipidemiaSupLabResults: "labResults",
  ros_cardioSubDiagnosesHyperlipidemiaSupMedications: "medication",
  ros_cardioSubDiagnosesHyperlipidemiaSupchestpain: "chestPain",
  ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesHyperlipidemiaSupwheezing: "wheezing",
  ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesHyperlipidemiaSupother: "other",

  ros_cardioSubDiagnosesHypertensionDesActive: "active",
  ros_cardioSubDiagnosesHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesHypertensionDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesHypertensionSupPhysicalExam: "physicalExam",
  ros_cardioSubDiagnosesHypertensionSupMedications: "medications",
  ros_cardioSubDiagnosesHypertensionSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesHypertensionSupchestpain: "chestPain",
  ros_cardioSubDiagnosesHypertensionSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesHypertensionSupwheezing: "wheezing",
  ros_cardioSubDiagnosesHypertensionSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesHypertensionSupother: "other",

  ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive: "active",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesIschemicHeartDiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesIschemicHeartDiseaseSupCardiacCath: "cardiacCath",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupHistory:
    "historyOfCoronaryStent",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupDiagnosis: "diagnosisOfAngina",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupMedications: "medications",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupCabgs: "historyOfCabg",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupEcg: "ecg",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupchestpain: "chestPain",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupwheezing: "wheezing",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesIschemicHeartDiseaseSupother: "other",

  ros_cardioSubDiagnosesMyocardialDesActive: "active",
  ros_cardioSubDiagnosesMyocardialDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesMyocardialDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesMyocardialSupECG: "ecgChanges",
  ros_cardioSubDiagnosesMyocardialSupLabResults: "labResults",
  ros_cardioSubDiagnosesMyocardialSupHistory: "historyOfHospitalizationProced",
  ros_cardioSubDiagnosesMyocardialSupMedications: "medications",
  ros_cardioSubDiagnosesMyocardialSupchestpain: "chestPain",
  ros_cardioSubDiagnosesMyocardialSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesMyocardialSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesMyocardialSupwheezing: "wheezing",
  ros_cardioSubDiagnosesMyocardialSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesMyocardialSupother: "other",

  ros_cardioSubDiagnosesMyocardialIspateintAspirin: "aspirin",
  ros_cardioSubDiagnosesMyocardialIspateintPlavix: "plavix",
  ros_cardioSubDiagnosesMyocardialIspateintNitrate: "nitrate",
  ros_cardioSubDiagnosesMyocardialIspateintOther: "other",

  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive: "active",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupVascular: "vascularStudies",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupClaudication:
    "claudication",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupExtremity: "extemityUlcers",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupDiminished:
    "diminishedOrAbsentPulses",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupAmputation: "amputation",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchestpain: "chestPain",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupwheezing: "wheezing",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchronicCough:
    "chronicCough",
  ros_cardioSubDiagnosesPeripheralVascularDiseaseSupother: "other",

  ros_cardiopvdDescribeUlceration: "ulceration",
  ros_cardiopvdDescribeGangrene: "gangrene",

  ros_cardioSubDiagnosesPulmonaryHypertensionDesActive: "active",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesPulmonaryHypertensionDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesPulmonaryHypertensionSupCardiacCath: "cardiacCath",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupO2: "useOfO2",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupEdema: "edema",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupMedication: "medication",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupchestpain: "chestPain",
  ros_cardioSubDiagnosesPulmonaryHypertensionSuplightHeadedness:
    "lightHeadedness",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupwheezing: "wheezing",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesPulmonaryHypertensionSupother: "other",

  ros_cardioSubDiagnosesvalvulardiseaseDesActive: "active",
  ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesvalvulardiseaseDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesvalvulardiseaseSupHistory: "history",
  ros_cardioSubDiagnosesvalvulardiseaseSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesvalvulardiseaseSupPhysicalFindings: "physicalFindings",
  ros_cardioSubDiagnosesvalvulardiseaseSupMedications: "medications",
  ros_cardioSubDiagnosesvalvulardiseaseSupTestResults: "testResults",
  ros_cardioSubDiagnosesvalvulardiseaseSupImageStudies: "imageStudies",
  ros_cardioSubDiagnosesvalvulardiseaseSupBiopsy: "biopsy",
  ros_cardioSubDiagnosesvalvulardiseaseSupDME: "dme",

  ros_cardioSubDiagnosesvalvulardiseaseSupchestpain: "chestPain",
  ros_cardioSubDiagnosesvalvulardiseaseSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesvalvulardiseaseSupshortnessOfBreath:
    "shortnessOfBreath",
  ros_cardioSubDiagnosesvalvulardiseaseSupwheezing: "wheezing",
  ros_cardioSubDiagnosesvalvulardiseaseSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesvalvulardiseaseSupother: "other",

  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral: "mitralStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic: "aorticStenosisSclerosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid: "tricuspidStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff:
    "pulmonaryInsufficiency",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis:
    "pulmonaryStenosis",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff: "aroticInsufficiency",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff:
    "mitralInsufficiencyProlapse",
  ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff:
    "tricuspidInsufficiency",

  // //   //other

  ros_cardioSubDiagnosesOtherDesActive: "active",
  ros_cardioSubDiagnosesOtherDesHistoryOf: "historyOf",
  ros_cardioSubDiagnosesOtherDesruleOut: "ruleOut",

  ros_cardioSubDiagnosesOtherSupHistory: "history",
  ros_cardioSubDiagnosesOtherSupSymptoms: "symptoms",
  ros_cardioSubDiagnosesOtherSupPhysicalFindings: "physicalFindings",
  ros_cardioSubDiagnosesOtherSupMedications: "medications",
  ros_cardioSubDiagnosesOtherSupTestResults: "testResults",
  ros_cardioSubDiagnosesOtherSupImageStudies: "imageStudies",
  ros_cardioSubDiagnosesOtherSupBiopsy: "biopsy",
  ros_cardioSubDiagnosesOtherSupDME: "dme",
  ros_cardioSubDiagnosesOtherSupChestPain: "chestPain",
  ros_cardioSubDiagnosesOtherSuplightHeadedness: "lightHeadedness",
  ros_cardioSubDiagnosesOtherSupshortnessOfBreath: "shortnessOfBreath",
  ros_cardioSubDiagnosesOtherSupwheezing: "wheezing",
  ros_cardioSubDiagnosesOtherSupchronicCough: "chronicCough",
  ros_cardioSubDiagnosesOtherSupother: "other",

  ros_genitoSubDiagnosesacuteRenalFailureVal: "acuteRenalFailure",
  ros_genitoSubDiagnosesbphVal: "bphgenito",
  ros_genitoSubDiagnoseschronicKidneyDiseaseVal: "chronicKidneyDisease",
  ros_genitoSubDiagnosesesrdVal: "esrd",
  ros_genitoSubDiagnoseserectileDysfunctionVal: "erectileDysfunction",
  ros_genitoSubDiagnosesfrequentUtiVal: "frequentUti",
  ros_genitoSubDiagnosesgynecologicalVal: "gynecological",
  ros_genitoSubDiagnoseskidneyStonesVal: "kidneyStonesgenito",
  ros_genitoSubDiagnosesnephritisOrNephrosisVal: "nephritisOrNephrosis",
  ros_genitoSubDiagnosesurinaryIncontinenceVal: "urinaryIncontinence",
  ros_genitoSubDiagnosesotherVal: "other",

  ros_gastroSubDiagnosesbowelObstructionVal: "bowelobstruction",
  ros_gastroSubDiagnosescachexiaVal: "cachexia",
  ros_gastroSubDiagnosesceliacDiseaseVal: "celiacdisease",
  ros_gastroSubDiagnosescirrhosisVal: "cirrhosis",
  ros_gastroSubDiagnosescolonPolypsVal: "colonpolyps",
  ros_gastroSubDiagnosesdiverticulitisVal: "diverticultis",
  ros_gastroSubDiagnosesgallBladderDiseaseVal: "gallbladderdisease",
  ros_gastroSubDiagnosesgastroparesisVal: "gastroparesis",
  ros_gastroSubDiagnosesgerdVal: "gerd",
  ros_gastroSubDiagnosesglBleedVal: "gibleed",
  ros_gastroSubDiagnoseshepatitisVal: "hepatitis",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal: "inflammatoryboweldisease",
  ros_gastroSubDiagnosespancreatitisVal: "pancreatitis",
  ros_gastroSubDiagnosesulcerDiseaseVal: "ulcerdisease",
  ros_gastroSubDiagnosesotherVal: "other",

  ros_gastroSubDiagnosesdesbowelObstructionActiveVal: "active",
  ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescachexiadesactive: "active",
  ros_gastroSubDiagnosescachexiadeshistoryOf: "historyOf",
  ros_gastroSubDiagnosescachexiadesruleOut: "ruleOut",

  ros_gastroSubDiagnosescachexiaSupalbuminVal: "albumin<3.5g/dl",
  ros_gastroSubDiagnoscachexiaSupmuscleWastingVal: "muscleWasting",
  ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal:
    "historyofsevereweightloss",
  ros_gastroSubDiagnosescachexiaSupabdominalPainVal: "abdominalpain",
  ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescachexiaSupotherVal: "other",

  ros_gastroSubDiagnosesdesceliacDiseaseActiveVal: "active",
  ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal: "weightLoss",
  ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal: "anemia",
  ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal:
    "changeinbowelmovements",
  ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal: "osteoporosis",
  ros_gastroSubDiagnosesceliacDiseaseSupDietVal: "diet",
  ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesceliacDiseaseSupotherVal: "other",

  ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal:
    "hospitalization",
  ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal:
    "physicalFindings",
  ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal:
    "nauseandvomiting",
  ros_gastroSubDiagnosesbowelObstructionSupotherVal: "other",

  ros_gastroSubDiagnosesdescirrhosisActiveVal: "active",
  ros_gastroSubDiagnosesdescirrhosisHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdescirrhosisRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescirrhosisSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescirrhosisSupLabStudiesVal: "labStudies",
  ros_gastroSubDiagnosescirrhosisSupMriVal: "mri",
  ros_gastroSubDiagnosescirrhosisSupBoipsyVal: "biopsy",
  ros_gastroSubDiagnosescirrhosisSupabdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescirrhosisSupOtherVal: "other",

  ros_gastroSubDiagnosescirrhosisEndStageAscites: "ascites",
  ros_gastroSubDiagnosescirrhosisEndStageVarices: "varices",
  ros_gastroSubDiagnosescirrhosisEndStageEncephalopathy: "encephalopathy",
  ros_gastroSubDiagnosescirrhosisEndStageHepatorenalSyndrome:
    "hepatorenalSyndrome",
  ros_gastroSubDiagnosescirrhosisEndStageOther: "other",

  ros_gastroSubDiagnosescolonPolypsDesActiveVal: "active",
  ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosescolonPolypsDesRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosescolonSuphistoryVal: "history",
  ros_gastroSubDiagnosescolonSupsymptomsVal: "symptoms",
  ros_gastroSubDiagnosescolonSupPhysicalfindingsVal: "physicalFindings",
  ros_gastroSubDiagnosescolonSupmedicationsVal: "medications",
  ros_gastroSubDiagnosescolonSupTestResultsVal: "testResults",
  ros_gastroSubDiagnosescolonSupImageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosescolonSupBiopsyVal: "biopsy",
  ros_gastroSubDiagnosescolonDmeVal: "dme",
  ros_gastroSubDiagnosescolonAbdominalPainVal: "abdominalPain",
  ros_gastroSubDiagnosescolonNausaVomitingVal: "nauseaandvomiting",
  ros_gastroSubDiagnosescolonOtherVal: "other",

  ros_gastroSubDiagnosescolonCrDesBengirVal: "bengir",

  ros_gastroSubDiagnosescolonCrDesCarcinomaVal: "carcinomainsitu",

  ros_gastroSubDiagnosescolonCrDesPolyposisVal: "fmilialpolyposis",

  ros_gastroSubDiagnosesdesdiverticulitisActiveVal: "active",
  ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal: "colonoscopy",
  ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal: "imageStudies",
  ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal: "symptoms",
  ros_gastroSubDiagnosesdiverticulitisSupDietVal: "diet",
  ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal: "abdominalPain",
  ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesdiverticulitisSupotherVal: "other",

  ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal: "active",
  ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal: "historyOf",
  ros_gastroSubDiagnosesdesgallBladderDiseaseRuleOutVal: "ruleOut",

  ros_gastroSubDiagnosesgallBladderSupSymptoms: "symptoms",
  ros_gastroSubDiagnosesgallBladderSupPassingOfStones: "passingofstones",
  ros_gastroSubDiagnosesgallBladderSupErcp: "ercp",
  ros_gastroSubDiagnosesgallBladderSupHidaScan: "hidascan",
  ros_gastroSubDiagnosesgallBladderSupMri: "mri",
  ros_gastroSubDiagnosesgallBladderSupTreatmentHistory: "treatmentHistory",
  ros_gastroSubDiagnosesgallBladderSupAbdominalPain: "abdominalPain",
  ros_gastroSubDiagnosesgallBladderSupNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesgallBladderSupOther: "other",

  ros_gastroSubDiagnosesgastroparesisDescribeActive: "active",
  ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesgastroparesisDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets:
    "gastricemptyingtest",
  ros_gastroSubDiagnosesgastroparesisSupportedByMedications: "medications",
  ros_gastroSubDiagnosesgastroparesisSupportedByHistory: "history",
  ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesgastroparesisSupportedByOther: "other",

  ros_gastroSubDiagnosesgerdDescribeActive: "active",
  ros_gastroSubDiagnosesgerdDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesgerdDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesgerdSupportedByHeartburn: "heartburn",
  ros_gastroSubDiagnosesgerdSupportedByRegurgitation: "regurgitation",
  ros_gastroSubDiagnosesgerdSupportedByMedications: "medications",
  ros_gastroSubDiagnosesgerdSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesgerdSupportedByNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesgerdSupportedByOther: "other",

  ros_gastroSubDiagnosesglBleedDescribeActive: "active",
  ros_gastroSubDiagnosesglBleedDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesglBleedDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesglBleedSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesglBleedSupportedByBloodInStool: "bloodinstool",
  ros_gastroSubDiagnosesglBleedSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesglBleedSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesglBleedSupportedByOther: "other",

  ros_gastroSubDiagnoseshepatitisDescribeActive: "active",
  ros_gastroSubDiagnoseshepatitisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnoseshepatitisDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnoseshepatitisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnoseshepatitisSupportedByPhysicalFindings:
    "physicalfindings",
  ros_gastroSubDiagnoseshepatitisSupportedByLabStudies: "labstudies",
  ros_gastroSubDiagnoseshepatitisSupportedByJuandice: "jaundice",
  ros_gastroSubDiagnoseshepatitisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnoseshepatitisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnoseshepatitisSupportedByOther: "other",

  ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive: "active",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByColonoscopy:
    "colonoscopy",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByPhysicalFindings:
    "physicalfindings",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByMedications:
    "medications",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByAbdominalPain:
    "abdominalpain",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOther: "other",

  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis:
    "uicerativecolitis",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease:
    "croh'nsdisease",
  ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther: "other",

  ros_gastroSubDiagnosespancreatitisDescribeActive: "active",
  ros_gastroSubDiagnosespancreatitisDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosespancreatitisDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosespancreatitisSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosespancreatitisSupportedByLabStudies: "labstudies",
  ros_gastroSubDiagnosespancreatitisSupportedByErcp: "ercp",
  ros_gastroSubDiagnosespancreatitisSupportedByAlcoholism: "alcoholism",
  ros_gastroSubDiagnosespancreatitisSupportedByUseOfCertainMedications:
    "useofcertainmedications",
  ros_gastroSubDiagnosespancreatitisSupportedByMri: "mri",
  ros_gastroSubDiagnosespancreatitisSupportedByFamilyHistory: "familyhistory",
  ros_gastroSubDiagnosespancreatitisSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosespancreatitisSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosespancreatitisSupportedByOther: "other",

  ros_gastroSubDiagnosesulcerDiseaseDescribeActive: "active",
  ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesulcerDiseaseDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesulcerDiseaseSupportedByEndoscopicFindings:
    "endoscopicfindings",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByCulture: "culture",
  ros_gastroSubDiagnosesulcerDiseaseSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByMedications: "medications",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByNauseaAndVomiting:
    "nauseaandvomiting",
  ros_gastroSubDiagnosesulcerDiseaseSupportedByOther: "other",

  ros_gastroSubDiagnosesulcerDiseaseEsophageal: "esophageal",
  ros_gastroSubDiagnosesulcerDiseaseGastric: "gastric",
  ros_gastroSubDiagnosesulcerDiseaseDuodenal: "duodenal",
  ros_gastroSubDiagnosesulcerDiseaseOther: "other",

  ros_gastroSubDiagnosesotherDescribeActive: "active",
  ros_gastroSubDiagnosesotherDescribeHistoryOf: "historyOf",
  ros_gastroSubDiagnosesotherDescribeRuleOut: "ruleOut",

  ros_gastroSubDiagnosesotherSupportedByHistory: "history",
  ros_gastroSubDiagnosesotherSupportedBySymptoms: "symptoms",
  ros_gastroSubDiagnosesotherSupportedByPhysicalFindings: "physicalfindings",
  ros_gastroSubDiagnosesotherSupportedByMedications: "medications",
  ros_gastroSubDiagnosesotherSupportedByTestResults: "testresults",
  ros_gastroSubDiagnosesotherSupportedByImageStudies: "imagestudies",
  ros_gastroSubDiagnosesotherSupportedByBiopsy: "biopsy",
  ros_gastroSubDiagnosesotherSupportedByDme: "dme",
  ros_gastroSubDiagnosesotherSupportedByAbdominalPain: "abdominalpain",
  ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting: "nauseaandvomiting",
  ros_gastroSubDiagnosesotherSupportedByOther: "other",

  ros_endocrineIsEndocrineDiagnosechronicKidneyVal: "ckdstDiabetes",
  ros_endocrineIsEndocrineDiagnosecoronaryArteryVal:
    "coronaryArteryDiseaseAndDiabet",
  ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal: "cushingsDisease",
  ros_endocrineIsEndocrineDiagnosediabetesVal: "diabetes",
  ros_endocrineIsEndocrineDiagnosediabeticRetinopathyVal: "diabeticRetinopathy",
  ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal:
    "secondaryHyperparathyroidism",
  ros_endocrineIsEndocrineDiagnosehypertensionDiabetesVal:
    "hypertensionAndDiabetes",
  ros_endocrineIsEndocrineDiagnosehyperthyroidismVal: "hyperthyroidism",
  ros_endocrineIsEndocrineDiagnosehypothyroidismVal: "hypothyroidism",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyVal:
    "peripheralNeuropathySecondaryT",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularVal: "pvdstDiabetes",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal: "hyperparathyroidism",
  ros_endocrineIsEndocrineDiagnoseotherVal: "other",

  ros_gastroSubDiagnoseshepatitisTypeA: "a",

  ros_gastroSubDiagnoseshepatitisTypeB: "b",

  ros_gastroSubDiagnoseshepatitisTypeC: "c",

  ros_gastroSubDiagnoseshepatitisAcute: "acute",

  ros_gastroSubDiagnoseshepatitisChronic: "chronic",

  ros_genitoSubDiagnosesacuteRenalFailuredesactive: "active",
  ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf: "historyOf",
  ros_genitoSubDiagnosesacuteRenalFailuredesruleOut: "ruleOut",

  ros_genitoSubDiagnosesacuteRenalFailureSupLabTests: "labTests",
  ros_genitoSubDiagnosesacuteRenalFailureSupCalculatedGFR: "calculatedGfr",
  ros_genitoSubDiagnosesacuteRenalFailureSupHospitalization: "hospitalization",
  ros_genitoSubDiagnosesacuteRenalFailureSupOther: "other",

  ros_genitoSubDiagnosesbphDescribeActive: "active",
  ros_genitoSubDiagnosesbphDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesbphDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesbphSupportedByPhysicalExam: "physicalExam",
  ros_genitoSubDiagnosesbphSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnosesbphSupportedByLabTest: "labTest",
  ros_genitoSubDiagnosesbphSupportedByBiopsy: "biopsy",
  ros_genitoSubDiagnosesbphSupportedByMedication: "medication",
  ros_genitoSubDiagnosesbphSupportedByHospitalization: "hospitalization",
  ros_genitoSubDiagnosesbphSupportedByOther: "other",

  ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive: "active",
  ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByLabTests: "labTests",
  ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByCalculatedGfr:
    "calculatedGfrX3",
  ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOther: "other",

  ros_genitoSubDiagnoseswhatStageStageOne: "stage1",

  ros_genitoSubDiagnoseswhatStageStageTwo: "stage2",

  ros_genitoSubDiagnoseswhatStageStageThreeA: "stage3A",

  ros_genitoSubDiagnoseswhatStageStageThreeB: "stage3B",

  ros_genitoSubDiagnoseswhatStageStageFour: "stage4",

  ros_genitoSubDiagnoseswhatStageStageFive: "stage5",

  ros_genitoSubDiagnoseswhatStageUnknown: "unknown",

  ros_genitoSubDiagnosesesrdDescribeActive: "active",
  ros_genitoSubDiagnosesesrdDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesesrdDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesesrdSupportedByLabTests: "labTests",
  ros_genitoSubDiagnosesesrdSupportedByCalculatedGfr: "calculatedGfrX3",
  ros_genitoSubDiagnosesesrdSupportedByHospitalization: "symptoms",
  ros_genitoSubDiagnosesesrdSupportedByOther: "other",

  ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis: "hemodialysis",
  ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis:
    "peritonealDialysis",

  ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome: "inHome",
  ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter:
    "dialysisCenter",

  ros_genitoSubDiagnoseserectileDysfunctionDescribeActive: "active",
  ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnoseserectileDysfunctionDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnoseserectileDysfunctionSupportedByHistory: "history",
  ros_genitoSubDiagnoseserectileDysfunctionSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnoseserectileDysfunctionSupportedByPhysicalFindings:
    "physicalFindings",
  ros_genitoSubDiagnoseserectileDysfunctionSupportedByMedications:
    "medications",
  ros_genitoSubDiagnoseserectileDysfunctionSupportedByTestResults:
    "testResults",
  ros_genitoSubDiagnoseserectileDysfunctionSupportedByOther: "other",

  ros_genitoSubDiagnosesfrequentUtiDescribeActive: "active",
  ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesfrequentUtiDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesfrequentUtiSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnosesfrequentUtiSupportedByCultures: "cultures",
  ros_genitoSubDiagnosesfrequentUtiSupportedByLaboratoryResults:
    "laboratoryResults",
  ros_genitoSubDiagnosesfrequentUtiSupportedByOther: "other",

  ros_genitoSubDiagnosesgynecologicalDescribeActive: "active",
  ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesgynecologicalDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesgynecologicalSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnosesgynecologicalSupportedByHistory: "history",
  ros_genitoSubDiagnosesgynecologicalSupportedByDiagnosticResults:
    "diagnosticResults",
  ros_genitoSubDiagnosesgynecologicalSupportedByMedications: "medications",
  ros_genitoSubDiagnosesgynecologicalSupportedByVaginalBleeding:
    "vaginalBleeding",
  ros_genitoSubDiagnosesgynecologicalSupportedByOther: "other",

  ros_genitoSubDiagnoseskidneyStonesDescribeActive: "active",
  ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnoseskidneyStonesDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnoseskidneyStonesSupportedByHistory: "history",
  ros_genitoSubDiagnoseskidneyStonesSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnoseskidneyStonesSupportedByPhysicalFindings:
    "physicalFindings",
  ros_genitoSubDiagnoseskidneyStonesSupportedByMedications: "medications",
  ros_genitoSubDiagnoseskidneyStonesSupportedByTestResults: "testResults",
  ros_genitoSubDiagnoseskidneyStonesSupportedByImageStudies: "imageStudies",
  ros_genitoSubDiagnoseskidneyStonesSupportedByOther: "other",

  ros_genitoSubDiagnoseskidneyStonesTypeUrate: "urate",
  ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate: "calciumOxalate",
  ros_genitoSubDiagnoseskidneyStonesTypeMagnesium: "magnesium",
  ros_genitoSubDiagnoseskidneyStonesTypeOther: "other",

  ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive: "active",
  ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesnephritisOrNephrosisDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedicalHistory:
    "medicalHistory",
  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByLabTests: "labTests",
  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByImagingStudies:
    "imagingStudies",
  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByBiopsy: "biopsy",
  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedications:
    "medications",
  ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOther: "other",

  ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive: "active",
  ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesurinaryIncontinenceDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByHistory: "history",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByPhysicalFindings:
    "physicalFindings",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByMedications:
    "medications",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByTestResults:
    "testResults",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByDME: "dme",
  ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOther: "other",

  ros_genitoStressSubDribbling: "dribbling",
  ros_genitoStressSubUrgency: "urgency",
  ros_genitoStressSubOther: "other",

  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily: "daily",
  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek:
    "fewTimesAWeek",
  ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek:
    "lessThanOnceAWeek",

  ros_genitoSubDiagnosesotherDescribeActive: "active",
  ros_genitoSubDiagnosesotherDescribeHistoryOf: "historyOf",
  ros_genitoSubDiagnosesotherDescribeRuleOut: "ruleOut",

  ros_genitoSubDiagnosesotherSupportedByHistory: "history",
  ros_genitoSubDiagnosesotherSupportedBySymptoms: "symptoms",
  ros_genitoSubDiagnosesotherSupportedByPhysicalFindings: "physicalFindings",
  ros_genitoSubDiagnosesotherSupportedByMedications: "medications",
  ros_genitoSubDiagnosesotherSupportedByTestResults: "testResults",
  ros_genitoSubDiagnosesotherSupportedByImageStudies: "imageStudies",
  ros_genitoSubDiagnosesotherSupportedByBiopsy: "biopsy",
  ros_genitoSubDiagnosesotherSupportedByDME: "dme",
  ros_genitoSubDiagnosesotherSupportedByOther: "other",

  ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive: "active",
  ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubRuleOut: "ruleOut",

  ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdecreasedGFR:
    "decreasedGfr",
  ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubalbuminuria:
    "albuminuria",
  ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubelevatedBun:
    "elevatedBunCreatinine",
  ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdialysis: "dialysis",
  ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubother: "other",

  ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubhistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubruleOut: "ruleOut",

  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubhistory: "history",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubsymptoms:
    "symptoms",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubphysicalFindings:
    "physicalFindings",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubmedications:
    "medications",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubtestResults:
    "testResults",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubimageStudies:
    "imageStudies",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubbiopsy: "biopsy",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubdme: "dme",
  ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubother: "other",

  ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubruleOut: "ruleOut",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubphysicalExam:
    "physicalExam",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySublabTests:
    "labTests",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubsuppressionTest:
    "suppressionTest",
  ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubother: "other",

  ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf: "historyOf",
  ros_endocrineIsEndocrineSubdiabetesSubdescribeSubruleOut: "ruleOut",
  ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptoms: "symptoms",

  ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubincreasedThirst:
    "increasedThirst",

  ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubshakiness:
    "shakiness",

  ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubsweating:
    "sweating",

  ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubblurredVision:
    "blurredVision",

  endocrineSubdiabetesSubsupportedByphysicalFindings: "physicalFindings",
  endocrineSubdiabetesSubsupportedBylabTests: "labTests",
  endocrineSubdiabetesSubsupportedBymedications: "medications",
  endocrineSubdiabetesSubsupportedByother: "other",
  ros_endocrineIsEndocrineSubdiabetesSubtypetypeOne: "type1",
  ros_endocrineIsEndocrineSubdiabetesSubtypetypeOnePointFive: "type15",
  ros_endocrineIsEndocrineSubdiabetesSubtypetypeTwo: "type2",
  ros_endocrineIsEndocrineSubdiabetesSubtypegestational: "gestational",

  ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubdiet: "diet",

  ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSuboralHypoglycemic:
    "oralHypoglycemic",

  ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubinsulin: "insulin",

  ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubexercise: "exercise",

  ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubweightLoss:
    "weightloss",

  ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubactive: "active",
  ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubhistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubruleOut:
    "ruleOut",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubproliferative:
    "proliferative",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubnonProliferative:
    "nonProliferative",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubunknown: "unknown",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubrightEye:
    "rightEye",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubleftEye: "leftEye",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubbilateral:
    "bilateral",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubfunduscopicExam:
    "funduscopicExam",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubvisionLoss:
    "visionLoss",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySublaserTherapy:
    "laserTherapy",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubretinalInjections:
    "retinalInjections",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubsurgicalProcedure:
    "surgicalProcedure",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubother: "other",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSuboccasionally:
    "occasionally",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubonceAYear:
    "onceAYear",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubtwiceAYear:
    "twiceAYear",

  ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreaterTwiceAYear:
    "gtwiceAYear",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive:
    "active",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf:
    "historyOf",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubruleOut:
    "ruleOut",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubchronicKidneyDisease:
    "historyChronicKidneyDisease",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryCeliacDisease:
    "historyCeliacDisease",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubvitaminDDeficiency:
    "vitaminDDeficiency",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubmalabsorption:
    "malabsorption",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubbariatricSurgery:
    "bariatricSurgery",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySublabTests:
    "labTests",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfKidneyStones:
    "histroyOfKidneyStones",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfFractures:
    "historyOfFractures",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubimagingStudies:
    "imagingStudies",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubfatigue:
    "fatigue",

  ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubother:
    "other",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubactive: "active",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubhistoryOf:
    "historyOf",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubruleOut:
    "ruleOut",
  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubhistory:
    "history",
  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubsymptoms:
    "symptoms",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubphysicalFindings:
    "phyiscalFindings",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubmedications:
    "medications",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubtestResults:
    "testResults",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubimageStudies:
    "imageStudies",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubbiopsy:
    "biopsy",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubdme: "dme",

  ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubother:
    "other",

  ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive: "active",

  ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf:
    "historyOf",

  ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubruleOut: "ruleOut",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubrapidHeartBeat:
    "rapidHeartBeat",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubnervousness:
    "nervousness",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubweightLoss:
    "weightLoss",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubheatIntolerance:
    "heatIntolerance",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubtremor: "tremor",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySublabData: "labData",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhistoryOfTreatment:
    "historyOfTreatmentForHyperthyr",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhyperReflexes:
    "hyperReflexes",
  ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubother: "other",

  ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive: "active",

  ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf: "historyOf",

  ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubruleOut: "ruleOut",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubweightGain:
    "weightGain",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubfatigue: "fatigue",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubhairChanges:
    "hairChanges",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubdepression:
    "depression",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubtreatment:
    "treatmentForHypothyroidism",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySublabData: "labData",

  ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubother: "other",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeActive: "active",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeHistoryOf:
    "historyOf",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeRuleOut:
    "ruleOut",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupPhysical:
    "physicalExam",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupMedication:
    "medication",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptoms: "symptoms",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOther: "other",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubnumbness:
    "numbness",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubpain:
    "pain",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubburning:
    "burning",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubdecreasedSensation:
    "decreasedSensation",

  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubonceAYear:
    "onceAYear",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubtwiceAYear:
    "twiceAYear",
  ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubquarterly:
    "quarterly",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubonceAYear:
    "onceAYear",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubtwiceAYear:
    "twiceAYear",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubquarterly:
    "quarterly",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeRuleOut: "ruleOut",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupPhysical: "physicalExam",
  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupVascularStudies:
    "vascularStudies",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupskinLesions:
    "skinLesions",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupfootDeformity:
    "footDeformity",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupsurgicalProcedures:
    "surgicalProcedures",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupintermittentClaudication:
    "intermittentClaudication",

  ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOther: "other",

  ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive: "active",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf:
    "historyOf",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeRuleOut: "ruleOut",

  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuplabTests: "labTests",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfKidneyStones:
    "historyOfKidneyStones",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfFractures:
    "historyOfFractures",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfimagingStudies:
    "imagingStudies",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupfatigue: "fatigue",
  ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOther: "other",

  ros_endocrineIsEndocrineDiagnoseotherDescribeActive: "active",

  ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf: "historyOf",

  ros_endocrineIsEndocrineDiagnoseotherDescribeRuleOut: "ruleOut",

  ros_endocrineIsEndocrineDiagnoseotherSuphistory: "history",

  ros_endocrineIsEndocrineDiagnoseotherSupMedication: "medications",

  ros_endocrineIsEndocrineDiagnoseotherSupSymptoms: "symptoms",

  ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings: "physicalFindings",

  ros_endocrineIsEndocrineDiagnoseotherSuptestResults: "testResults",

  ros_endocrineIsEndocrineDiagnoseotherSupimageStudies: "imageStudies",
  ros_endocrineIsEndocrineDiagnoseotherSupbiopsy: "biopsy",
  ros_endocrineIsEndocrineDiagnoseotherSupdme: "dme",
  ros_endocrineIsEndocrineDiagnoseotherSupOther: "other",
};

const ros_OptionsTreeWrappers_srilatha = [
  [
    "ros_diagnosisOfCancerSubDesc",
    [
      "ros_diagnosisOfCancerSubDescActiveVal",
      "ros_diagnosisOfCancerSubDescHistoryOfVal",
      "ros_diagnosisOfCancerSubDescRuleOutVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubSup",
    [
      "ros_diagnosisOfCancerSubSupPhyFinVal",
      "ros_diagnosisOfCancerSubSuphospVal",
      "ros_diagnosisOfCancerSubSuptreatVal",
      "ros_diagnosisOfCancerSubSuplabTestsVal",
      "ros_diagnosisOfCancerSubSupimagVal",

      "ros_diagnosisOfCancerSubSupSurVal",

      "ros_diagnosisOfCancerSubSupBioVal",

      "ros_diagnosisOfCancerSubSupOtherVal",
    ],
  ],
  [
    "ros_diagnosisOfCancerSubType",
    [
      "ros_diagnosisOfCancerSubTypeBrainVal",
      "ros_diagnosisOfCancerSubTypeHeadVal",
      "ros_diagnosisOfCancerSubTypeNeckVal",
      "ros_diagnosisOfCancerSubTypeBreastVal",
      "ros_diagnosisOfCancerSubTypeLungVal",
      "ros_diagnosisOfCancerSubTypeEsophagusVal",
      "ros_diagnosisOfCancerSubTypeStomachVal",
      "ros_diagnosisOfCancerSubTypeLiverVal",
      "ros_diagnosisOfCancerSubTypePancreasVal",
      "ros_diagnosisOfCancerSubTypeColonVal",
      "ros_diagnosisOfCancerSubTypeRectumVal",
      "ros_diagnosisOfCancerSubTypeKidyneyVal",
      "ros_diagnosisOfCancerSubTypeBladderVal",
      "ros_diagnosisOfCancerSubTypeOvariesVal",
      "ros_diagnosisOfCancerSubTypeUterusVal",
      "ros_diagnosisOfCancerSubTypeProstateVal",
      "ros_diagnosisOfCancerSubTypeBoneVal",
      "ros_diagnosisOfCancerSubTypeBloodVal",
      "ros_diagnosisOfCancerSubTypeLymphNodesVal",
      "ros_diagnosisOfCancerSubTypeSkinVal",
      "ros_diagnosisOfCancerSubTypeOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubActiveTreat",
    [
      "ros_diagnosisOfCancerSubActiveTreatChemoVal",
      "ros_diagnosisOfCancerSubActiveTreatRadiaVal",
      "ros_diagnosisOfCancerSubActiveTreatStemVal",
      "ros_diagnosisOfCancerSubActiveTreatBoneVal",
      "ros_diagnosisOfCancerSubActiveTreatSurgVal",
      "ros_diagnosisOfCancerSubActiveTreatImmuVal",
      "ros_diagnosisOfCancerSubActiveTreatOtherVal",
    ],
  ],

  [
    "ros_diagnosisOfCancerSubSideEff",
    [
      "ros_diagnosisOfCancerSubActiveTreatSideEffnauseaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffvomitingVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffdiarrheaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffanemiaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffneutropeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffthrombocytopeniaVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffweaknessVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffLossOfAppVal",
      "ros_diagnosisOfCancerSubActiveTreatSideEffOtherVal",
    ],
  ],

  [
    "ros_cardioSubDiagnoses",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmVal",
      "ros_cardioSubDiagnosesaneurysmVal",
      "ros_cardioSubDiagnosesanginaVal",
      "ros_cardioSubDiagnosesatrialFibrillationVal",
      "ros_cardioSubDiagnosescardioRespiratoryShockVal",
      "ros_cardioSubDiagnosescardiomyopathyVal",
      "ros_cardioSubDiagnosescongestiveHeartFailureVal",
      "ros_cardioSubDiagnosesdeepVeinThrombosisVal",
      "ros_cardioSubDiagnoseshyperlipidemiaVal",
      "ros_cardioSubDiagnoseshypertensionVal",
      "ros_cardioSubDiagnosesischemicHeartDiseaseVal",
      "ros_cardioSubDiagnosesmyocardialInfarctionVal",
      "ros_cardioSubDiagnosesperipheralVascularDiseaseVal",
      "ros_cardioSubDiagnosespulmonaryHypertensionVal",
      "ros_cardioSubDiagnosesvalvularDiseaseVal",
      "ros_cardioSubDiagnosesotherVal",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveVal",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribehistoryOfVal",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescriberuleOutVal",
    ],
  ],

  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmSup",
    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupECG",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuprateControllingDrug",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupanticoagulation",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupelectrophysiology",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchestpain",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSuplightHeadedness",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedPacemaker",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupimplantedDefibrillator",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupwheezing",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupchronicCough",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",

    [
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesbradycardia",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDestachycardia",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregular",
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesirregularlyIrregular",

      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesprematureContractures",
    ],
  ],

  [
    "ros_genitoSubDiagnoses",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureVal",
      "ros_genitoSubDiagnosesbphVal",
      "ros_genitoSubDiagnoseschronicKidneyDiseaseVal",
      "ros_genitoSubDiagnosesesrdVal",
      "ros_genitoSubDiagnoseserectileDysfunctionVal",
      "ros_genitoSubDiagnosesfrequentUtiVal",
      "ros_genitoSubDiagnosesgynecologicalVal",
      "ros_genitoSubDiagnoseskidneyStonesVal",
      "ros_genitoSubDiagnosesnephritisOrNephrosisVal",
      "ros_genitoSubDiagnosesurinaryIncontinenceVal",
      "ros_genitoSubDiagnosesotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnoses",
    [
      "ros_gastroSubDiagnoses",
      "ros_gastroSubDiagnosesbowelObstructionVal",
      "ros_gastroSubDiagnosescachexiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseVal",
      "ros_gastroSubDiagnosescirrhosisVal",
      "ros_gastroSubDiagnosescolonPolypsVal",
      "ros_gastroSubDiagnosesdiverticulitisVal",
      "ros_gastroSubDiagnosesgallBladderDiseaseVal",
      "ros_gastroSubDiagnosesgastroparesisVal",
      "ros_gastroSubDiagnosesgerdVal",
      "ros_gastroSubDiagnosesglBleedVal",
      "ros_gastroSubDiagnoseshepatitisVal",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseVal",
      "ros_gastroSubDiagnosespancreatitisVal",
      "ros_gastroSubDiagnosesulcerDiseaseVal",
      "ros_gastroSubDiagnosesotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesbowelObstruction",
    [
      "ros_gastroSubDiagnosesdesbowelObstructionActiveVal",
      "ros_gastroSubDiagnosesdesbowelObstructiondesHistoryOfVal",
      "ros_gastroSubDiagnosesdesbowelObstructionRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesbowelObstructionSup",
    [
      "ros_gastroSubDiagnosesbowelObstructionSuphospitalizationVal",
      "ros_gastroSubDiagnosbowelObstructionSupimageStudiesVal",
      "ros_gastroSubDiagnosesbowelObstructionFailureSupphysicalFindingsVal",
      "ros_gastroSubDiagnosesbowelObstructionSupabdominalPainVal",
      "ros_gastroSubDiagnosesbowelObstructionSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesbowelObstructionSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiaSup",
    [
      "ros_gastroSubDiagnosescachexiaSupalbuminVal",
      "ros_gastroSubDiagnoscachexiaSupmuscleWastingVal",
      "ros_gastroSubDiagnosescachexiaSupSevereWeightLossVal",
      "ros_gastroSubDiagnosescachexiaSupabdominalPainVal",
      "ros_gastroSubDiagnosescachexiaSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosescachexiaSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescachexiades",
    [
      "ros_gastroSubDiagnosescachexiadesactive",
      "ros_gastroSubDiagnosescachexiadeshistoryOf",
      "ros_gastroSubDiagnosescachexiadesruleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesceliacDisease",
    [
      "ros_gastroSubDiagnosesdesceliacDiseaseActiveVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseHistoryOfVal",
      "ros_gastroSubDiagnosesdesceliacDiseaseRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesceliacDiseaseSup",
    [
      "ros_gastroSubDiagnosesceliacDiseaseSupWeightLossVal",
      "ros_gastroSubDiagnosceliacDiseaseSupAnemiaVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupChangeBowelVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupOsteoporosisVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupDietVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupAbdominalPainVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesceliacDiseaseSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdescirrhosis",
    [
      "ros_gastroSubDiagnosesdescirrhosisActiveVal",
      "ros_gastroSubDiagnosesdescirrhosisHistoryOfVal",
      "ros_gastroSubDiagnosesdescirrhosisRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescirrhosisSup",

    [
      "ros_gastroSubDiagnosescirrhosisSupsymptomsVal",
      "ros_gastroSubDiagnoscirrhosisSupphysicalFindingsVal",
      "ros_gastroSubDiagnosescirrhosisSupLabStudiesVal",
      "ros_gastroSubDiagnosescirrhosisSupMriVal",
      "ros_gastroSubDiagnosescirrhosisSupBoipsyVal",
      "ros_gastroSubDiagnosescirrhosisSupabdominalPainVal",
      "ros_gastroSubDiagnosescirrhosisSupNauseaAndVomitingVal",
      "ros_gastroSubDiagnosescirrhosisSupOtherVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosescolonPolypsDes",
    [
      "ros_gastroSubDiagnosescolonPolypsDesActiveVal",
      "ros_gastroSubDiagnosescolonPolypsDesHistoryOfVal",
      "ros_gastroSubDiagnosescolonPolypsDesRuleOutVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonSup",
    [
      "ros_gastroSubDiagnosescolonSuphistoryVal",
      "ros_gastroSubDiagnosescolonSupsymptomsVal",
      "ros_gastroSubDiagnosescolonSupPhysicalfindingsVal",
      "ros_gastroSubDiagnosescolonSupmedicationsVal",
      "ros_gastroSubDiagnosescolonSupTestResultsVal",
      "ros_gastroSubDiagnosescolonSupImageStudiesVal",
      "ros_gastroSubDiagnosescolonSupBiopsyVal",
      "ros_gastroSubDiagnosescolonDmeVal",
      "ros_gastroSubDiagnosescolonAbdominalPainVal",
      "ros_gastroSubDiagnosescolonNausaVomitingVal",
      "ros_gastroSubDiagnosescolonOtherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosescolonCrDes",
    [
      "ros_gastroSubDiagnosescolonCrDesBengirVal",
      "ros_gastroSubDiagnosescolonCrDesCarcinomaVal",
      "ros_gastroSubDiagnosescolonCrDesPolyposisVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesdiverticulitis",
    [
      "ros_gastroSubDiagnosesdesdiverticulitisActiveVal",
      "ros_gastroSubDiagnosesdesdiverticulitisHistoryOfVal",
      "ros_gastroSubDiagnosesdesdiverticulitisRuleOutVal",
    ],
  ],
  [
    "ros_gastroSubDiagnosesdiverticulitisSup",
    [
      "ros_gastroSubDiagnosesdiverticulitisSupColonoscopyVal",
      "ros_gastroSubDiagnosesdiverticulitisSupimageStudiesVal",
      "ros_gastroSubDiagnosesdiverticulitisSupSymptomsVal",
      "ros_gastroSubDiagnosesdiverticulitisSupDietVal",
      "ros_gastroSubDiagnosesdiverticulitisSupAbdominalVal",
      "ros_gastroSubDiagnosesdiverticulitisSupnauseaAndVomitingVal",
      "ros_gastroSubDiagnosesdiverticulitisSupotherVal",
    ],
  ],

  [
    "ros_gastroSubDiagnosesgallBladderDiseaseSup",

    [
      "ros_gastroSubDiagnosesgallBladderSupSymptoms",
      "ros_gastroSubDiagnosesgallBladderSupPassingOfStones",
      "ros_gastroSubDiagnosesgallBladderSupErcp",
      "ros_gastroSubDiagnosesgallBladderSupHidaScan",
      "ros_gastroSubDiagnosesgallBladderSupMri",
      "ros_gastroSubDiagnosesgallBladderSupTreatmentHistory",
      "ros_gastroSubDiagnosesgallBladderSupAbdominalPain",
      "ros_gastroSubDiagnosesgallBladderSupNauseaAndVomiting",
      "ros_gastroSubDiagnosesgallBladderSupOther",
    ],
  ],

  [
    "ros_gastroSubDiagnosesdesgallBladderDisease",
    [
      "ros_gastroSubDiagnosesdesgallBladderDiseaseActiveVal",
      "ros_gastroSubDiagnosesdesgallBladderDiseaseHistoryOfVal",
      "ros_gastroSubDiagnosesdesgallBladderDiseaseRuleOutVal",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmDes",
    [
      "ros_cardioSubDiagnosesAneurysmDesActive",
      "ros_cardioSubDiagnosesAneurysmDesHistoryOf",
      "ros_cardioSubDiagnosesAneurysmDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAneurysmSup",

    [
      "ros_cardioSubDiagnosesAneurysmSupPhyExam",
      "ros_cardioSubDiagnosesAneurysmSupultra",
      "ros_cardioSubDiagnosesAneurysmSupImage",
      "ros_cardioSubDiagnosesAneurysmSupchestpain",
      "ros_cardioSubDiagnosesAneurysmSuplightHeadedness",
      "ros_cardioSubDiagnosesAneurysmSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAneurysmSupwheezing",
      "ros_cardioSubDiagnosesAneurysmSupchronicCough",
      "ros_cardioSubDiagnosesAneurysmSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAneurysmCrDes",
    [
      "ros_cardioSubDiagnosesAneurysmCrDesAbdominal",
      "ros_cardioSubDiagnosesAneurysmCrDesThoracic",
      "ros_cardioSubDiagnosesAneurysmCrDesPeripheral",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAnginaDes",
    [
      "ros_cardioSubDiagnosesAnginaDesActive",
      "ros_cardioSubDiagnosesAnginaDesHistoryOf",
      "ros_cardioSubDiagnosesAnginaDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaSup",
    [
      "ros_cardioSubDiagnosesAnginaSupMedi",
      "ros_cardioSubDiagnosesAnginaSuphistory",
      "ros_cardioSubDiagnosesAnginaSupstressTest",
      "ros_cardioSubDiagnosesAnginaSupchestpain",
      "ros_cardioSubDiagnosesAnginaSuplightHeadedness",
      "ros_cardioSubDiagnosesAnginaSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAnginaSupwheezing",
      "ros_cardioSubDiagnosesAnginaSupchronicCough",
      "ros_cardioSubDiagnosesAnginaSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAnginaCrDes",
    [
      "ros_cardioSubDiagnosesAnginaCrDesStable",
      "ros_cardioSubDiagnosesAnginaCrDesUnstable",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationDes",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationDesActive",
      "ros_cardioSubDiagnosesAtrialFibrillationDesHistoryOf",
      "ros_cardioSubDiagnosesAtrialFibrillationDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationType",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationTypeParoxysmal",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeChronic",
      "ros_cardioSubDiagnosesAtrialFibrillationTypeUnknown",
    ],
  ],
  [
    "ros_cardioSubDiagnosesAtrialFibrillationSup",

    [
      "ros_cardioSubDiagnosesAtrialFibrillationSupMedi",
      "ros_cardioSubDiagnosesAtrialFibrillationSupECG",
      "ros_cardioSubDiagnosesAtrialFibrillationSupSymptoms",
      "ros_cardioSubDiagnosesAtrialFibrillationSupHistory",
      "ros_cardioSubDiagnosesAtrialFibrillationSupelectroCardio",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchestpain",
      "ros_cardioSubDiagnosesAtrialFibrillationSuplightHeadedness",
      "ros_cardioSubDiagnosesAtrialFibrillationSupshortnessOfBreath",
      "ros_cardioSubDiagnosesAtrialFibrillationSupwheezing",
      "ros_cardioSubDiagnosesAtrialFibrillationSupchronicCough",
      "ros_cardioSubDiagnosesAtrialFibrillationSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking",
    [
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingAnti",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingRate",
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOther",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesActive",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesHistoryOf",
      "ros_cardioSubDiagnosesCardiorespiratoryShockDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiorespiratoryShockSup",
    [
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupHistory",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupImplant",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupchestpain",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSuplightHeadedness",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupwheezing",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupchronicCough",
      "ros_cardioSubDiagnosesCardiorespiratoryShockSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyDes",
    [
      "ros_cardioSubDiagnosesCardiomyopathyDesActive",
      "ros_cardioSubDiagnosesCardiomyopathyDesHistoryOf",
      "ros_cardioSubDiagnosesCardiomyopathyDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathyType",
    [
      "ros_cardioSubDiagnosesCardiomyopathyTypeDilated",
      "ros_cardioSubDiagnosesCardiomyopathyTypeHypertropic",
      "ros_cardioSubDiagnosesCardiomyopathyTypeRestrictive",
      "ros_cardioSubDiagnosesCardiomyopathyTypeUnknown",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCardiomyopathySup",
    [
      "ros_cardioSubDiagnosesCardiomyopathySupEcho",
      "ros_cardioSubDiagnosesCardiomyopathySupCardiacCath",
      "ros_cardioSubDiagnosesCardiomyopathySupChestPain",
      "ros_cardioSubDiagnosesCardiomyopathySupLightHeadedness",
      "ros_cardioSubDiagnosesCardiomyopathySupshortnessOfBreath",
      "ros_cardioSubDiagnosesCardiomyopathySupwheezing",
      "ros_cardioSubDiagnosesCardiomyopathySupchronicCough",
      "ros_cardioSubDiagnosesCardiomyopathySupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcute",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyChronic",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyAcuteOnChronic",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActive",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesHistoryOf",
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureSup",
    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupEjection",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupCardio",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupOrtho",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupDOE",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPnd",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupS3",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupMedications",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupPeripheralEdema",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupChestPain",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupLightHeadedness",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupshortnessOfBreath",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupwheezing",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupchronicCough",
      "ros_cardioSubDiagnosesCongestiveHeartFailureSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesCongestiveHeartFailureCrDes",

    [
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesDias",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesSys",
      "ros_cardioSubDiagnosesCongestiveHeartFailureCrDesUnknown",
    ],
  ],
  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisCrDes",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesAcute",
      "ros_cardioSubDiagnosesDeepVeinThrombosisCrDesChronic",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesActive",
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesHistoryOf",
      "ros_cardioSubDiagnosesDeepVeinThrombosisDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSup",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupPhysicalFindings",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulation",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupVascularStudies",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupVenaFilter",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupEdema",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupchestpain",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSuplightHeadedness",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupshortnessOfBreath",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupwheezing",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupchronicCough",
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSub",
    [
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubprophylactic",

      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubtherapeutic",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHyperlipidemiaDes",

    [
      "ros_cardioSubDiagnosesHyperlipidemiaDesActive",
      "ros_cardioSubDiagnosesHyperlipidemiaDesHistoryOf",
      "ros_cardioSubDiagnosesHyperlipidemiaDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHyperlipidemiaSup",
    [
      "ros_cardioSubDiagnosesHyperlipidemiaSupLabResults",
      "ros_cardioSubDiagnosesHyperlipidemiaSupMedications",
      "ros_cardioSubDiagnosesHyperlipidemiaSupchestpain",
      "ros_cardioSubDiagnosesHyperlipidemiaSuplightHeadedness",
      "ros_cardioSubDiagnosesHyperlipidemiaSupshortnessOfBreath",
      "ros_cardioSubDiagnosesHyperlipidemiaSupwheezing",
      "ros_cardioSubDiagnosesHyperlipidemiaSupchronicCough",
      "ros_cardioSubDiagnosesHyperlipidemiaSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
      "ros_cardioSubDiagnosesHypertensionDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesHypertensionDes",
    [
      "ros_cardioSubDiagnosesHypertensionDesActive",
      "ros_cardioSubDiagnosesHypertensionDesHistoryOf",
      "ros_cardioSubDiagnosesHypertensionDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesHypertensionSup",
    [
      "ros_cardioSubDiagnosesHypertensionSupPhysicalExam",
      "ros_cardioSubDiagnosesHypertensionSupMedications",
      "ros_cardioSubDiagnosesHypertensionSupSymptoms",
      "ros_cardioSubDiagnosesHypertensionSupchestpain",
      "ros_cardioSubDiagnosesHypertensionSuplightHeadedness",
      "ros_cardioSubDiagnosesHypertensionSupshortnessOfBreath",
      "ros_cardioSubDiagnosesHypertensionSupwheezing",
      "ros_cardioSubDiagnosesHypertensionSupchronicCough",
      "ros_cardioSubDiagnosesHypertensionSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseDes",
    [
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActive",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesHistoryOf",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesIschemicHeartDiseaseSup",
    [
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupCardiacCath",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupHistory",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupDiagnosis",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupMedications",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupCabgs",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupEcg",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchestpain",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSuplightHeadedness",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupshortnessOfBreath",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupwheezing",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupchronicCough",
      "ros_cardioSubDiagnosesIschemicHeartDiseaseSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesMyocardialDes",
    [
      "ros_cardioSubDiagnosesMyocardialDesActive",
      "ros_cardioSubDiagnosesMyocardialDesHistoryOf",
      "ros_cardioSubDiagnosesMyocardialDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesMyocardialSup",
    [
      "ros_cardioSubDiagnosesMyocardialSupECG",
      "ros_cardioSubDiagnosesMyocardialSupLabResults",
      "ros_cardioSubDiagnosesMyocardialSupHistory",
      "ros_cardioSubDiagnosesMyocardialSupMedications",
      "ros_cardioSubDiagnosesMyocardialSupchestpain",
      "ros_cardioSubDiagnosesMyocardialSuplightHeadedness",
      "ros_cardioSubDiagnosesMyocardialSupshortnessOfBreath",
      "ros_cardioSubDiagnosesMyocardialSupwheezing",
      "ros_cardioSubDiagnosesMyocardialSupchronicCough",
      "ros_cardioSubDiagnosesMyocardialSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesMyocardialIspateint",
    [
      "ros_cardioSubDiagnosesMyocardialIspateintAspirin",
      "ros_cardioSubDiagnosesMyocardialIspateintPlavix",
      "ros_cardioSubDiagnosesMyocardialIspateintNitrate",
      "ros_cardioSubDiagnosesMyocardialIspateintOther",
    ],
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseSup",
    [
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupVascular",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupClaudication",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupExtremity",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupDiminished",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupAmputation",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchestpain",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSuplightHeadedness",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupshortnessOfBreath",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupwheezing",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupchronicCough",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupother",
    ],
  ],
  [
    "ros_cardiopvdDescribe",
    ["ros_cardiopvdDescribeUlceration", "ros_cardiopvdDescribeGangrene"],
  ],

  [
    "ros_cardioSubDiagnosesPeripheralVascularDiseaseDes",
    [
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActive",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesHistoryOf",
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionDes",
    [
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActive",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesHistoryOf",
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesPulmonaryHypertensionSup",
    [
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupCardiacCath",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupO2",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupEdema",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupMedication",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupchestpain",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSuplightHeadedness",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupshortnessOfBreath",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupwheezing",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupchronicCough",
      "ros_cardioSubDiagnosesPulmonaryHypertensionSupother",
    ],
  ],
  [
    "ros_cardioSubDiagnosesvalvulardiseaseDes",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseDesActive",
      "ros_cardioSubDiagnosesvalvulardiseaseDesHistoryOf",
      "ros_cardioSubDiagnosesvalvulardiseaseDesruleOut",
    ],
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseSup",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseSupHistory",
      "ros_cardioSubDiagnosesvalvulardiseaseSupSymptoms",
      "ros_cardioSubDiagnosesvalvulardiseaseSupPhysicalFindings",
      "ros_cardioSubDiagnosesvalvulardiseaseSupMedications",
      "ros_cardioSubDiagnosesvalvulardiseaseSupTestResults",
      "ros_cardioSubDiagnosesvalvulardiseaseSupImageStudies",
      "ros_cardioSubDiagnosesvalvulardiseaseSupBiopsy",
      "ros_cardioSubDiagnosesvalvulardiseaseSupDME",
      "ros_cardioSubDiagnosesvalvulardiseaseSupchestpain",
      "ros_cardioSubDiagnosesvalvulardiseaseSuplightHeadedness",
      "ros_cardioSubDiagnosesvalvulardiseaseSupshortnessOfBreath",
      "ros_cardioSubDiagnosesvalvulardiseaseSupwheezing",
      "ros_cardioSubDiagnosesvalvulardiseaseSupchronicCough",
      "ros_cardioSubDiagnosesvalvulardiseaseSupother",
    ],
  ],

  [
    "ros_cardioSubDiagnosesvalvulardiseaseCrDes",
    [
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitral",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesAortic",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspid",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesPulmonaryStenosis",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesMitralInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesAorticInsuff",
      "ros_cardioSubDiagnosesvalvulardiseaseCrDesTricuspidInsuff",
    ],
  ],

  [
    "ros_cardioSubDiagnosesOtherDes",
    [
      "ros_cardioSubDiagnosesOtherDesActive",
      "ros_cardioSubDiagnosesOtherDesHistoryOf",
      "ros_cardioSubDiagnosesOtherDesruleOut",
    ],
  ],
  [
    "ros_cardioSubDiagnosesOtherSup",
    [
      "ros_cardioSubDiagnosesOtherSupHistory",
      "ros_cardioSubDiagnosesOtherSupSymptoms",
      "ros_cardioSubDiagnosesOtherSupPhysicalFindings",
      "ros_cardioSubDiagnosesOtherSupMedications",
      "ros_cardioSubDiagnosesOtherSupTestResults",
      "ros_cardioSubDiagnosesOtherSupImageStudies",
      "ros_cardioSubDiagnosesOtherSupBiopsy",
      "ros_cardioSubDiagnosesOtherSupDME",
      "ros_cardioSubDiagnosesOtherSupChestPain",
      "ros_cardioSubDiagnosesOtherSuplightHeadedness",
      "ros_cardioSubDiagnosesOtherSupshortnessOfBreath",
      "ros_cardioSubDiagnosesOtherSupwheezing",
      "ros_cardioSubDiagnosesOtherSupchronicCough",
      "ros_cardioSubDiagnosesOtherSupother",
    ],
  ],
  [
    "ros_gastroSubDiagnosescirrhosisEndStageSubAnswer",
    [
      "ros_gastroSubDiagnosescirrhosisEndStageAscites",
      "ros_gastroSubDiagnosescirrhosisEndStageVarices",
      "ros_gastroSubDiagnosescirrhosisEndStageEncephalopathy",
      "ros_gastroSubDiagnosescirrhosisEndStageHepatorenalSyndrome",
      "ros_gastroSubDiagnosescirrhosisEndStageOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribe",
    [
      "ros_gastroSubDiagnosesgastroparesisDescribeActive",
      "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
      "ros_gastroSubDiagnosesgastroparesisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupportedBy",
    [
      "ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms",
      "ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets",
      "ros_gastroSubDiagnosesgastroparesisSupportedByMedications",
      "ros_gastroSubDiagnosesgastroparesisSupportedByHistory",
      "ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgastroparesisSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisDescribe",
    [
      "ros_gastroSubDiagnosesgastroparesisDescribeActive",
      "ros_gastroSubDiagnosesgastroparesisDescribeHistoryOf",
      "ros_gastroSubDiagnosesgastroparesisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgastroparesisSupportedBy",
    [
      "ros_gastroSubDiagnosesgastroparesisSupportedBySymptoms",
      "ros_gastroSubDiagnosesgastroparesisSupportedByGastricEmptyingSets",
      "ros_gastroSubDiagnosesgastroparesisSupportedByMedications",
      "ros_gastroSubDiagnosesgastroparesisSupportedByHistory",
      "ros_gastroSubDiagnosesgastroparesisSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgastroparesisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgastroparesisSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesgerdDescribe",
    [
      "ros_gastroSubDiagnosesgerdDescribeActive",
      "ros_gastroSubDiagnosesgerdDescribeHistoryOf",
      "ros_gastroSubDiagnosesgerdDescribeRuleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesgerdSupportedBy",
    [
      "ros_gastroSubDiagnosesgerdSupportedByHeartburn",
      "ros_gastroSubDiagnosesgerdSupportedByRegurgitation",
      "ros_gastroSubDiagnosesgerdSupportedByMedications",
      "ros_gastroSubDiagnosesgerdSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesgerdSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesgerdSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesglBleedDescribe",
    [
      "ros_gastroSubDiagnosesglBleedDescribeActive",
      "ros_gastroSubDiagnosesglBleedDescribeHistoryOf",
      "ros_gastroSubDiagnosesglBleedDescribeRuleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesglBleedSupportedBy",
    [
      "ros_gastroSubDiagnosesglBleedSupportedBySymptoms",
      "ros_gastroSubDiagnosesglBleedSupportedByBloodInStool",
      "ros_gastroSubDiagnosesglBleedSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesglBleedSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesglBleedSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDescribe",
    [
      "ros_gastroSubDiagnoseshepatitisDescribeActive",
      "ros_gastroSubDiagnoseshepatitisDescribeHistoryOf",
      "ros_gastroSubDiagnoseshepatitisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisSupportedBy",
    [
      "ros_gastroSubDiagnoseshepatitisSupportedBySymptoms",
      "ros_gastroSubDiagnoseshepatitisSupportedByPhysicalFindings",
      "ros_gastroSubDiagnoseshepatitisSupportedByLabStudies",
      "ros_gastroSubDiagnoseshepatitisSupportedByJuandice",
      "ros_gastroSubDiagnoseshepatitisSupportedByAbdominalPain",
      "ros_gastroSubDiagnoseshepatitisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnoseshepatitisSupportedByOther",
    ],
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeActive",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeHistoryOf",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeRuleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByColonoscopy",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBySymptoms",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByPhysicalFindings",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByMedications",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe",
    [
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeUlcerativeColitis",

      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeCrohnsDisease",

      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosespancreatitisDescribe",
    [
      "ros_gastroSubDiagnosespancreatitisDescribeActive",
      "ros_gastroSubDiagnosespancreatitisDescribeHistoryOf",
      "ros_gastroSubDiagnosespancreatitisDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosespancreatitisSupportedBy",
    [
      "ros_gastroSubDiagnosespancreatitisSupportedBySymptoms",
      "ros_gastroSubDiagnosespancreatitisSupportedByLabStudies",
      "ros_gastroSubDiagnosespancreatitisSupportedByErcp",
      "ros_gastroSubDiagnosespancreatitisSupportedByAlcoholism",
      "ros_gastroSubDiagnosespancreatitisSupportedByUseOfCertainMedications",
      "ros_gastroSubDiagnosespancreatitisSupportedByMri",
      "ros_gastroSubDiagnosespancreatitisSupportedByFamilyHistory",
      "ros_gastroSubDiagnosespancreatitisSupportedByAbdominalPain",
      "ros_gastroSubDiagnosespancreatitisSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosespancreatitisSupportedByOther",
    ],
  ],

  [
    "ros_gastroSubDiagnosesulcerDiseaseDescribe",
    [
      "ros_gastroSubDiagnosesulcerDiseaseDescribeActive",
      "ros_gastroSubDiagnosesulcerDiseaseDescribeHistoryOf",
      "ros_gastroSubDiagnosesulcerDiseaseDescribeRuleOut",
    ],
  ],
  [
    "ros_gastroSubDiagnosesulcerDiseaseSupportedBy",
    [
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByEndoscopicFindings",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByCulture",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedBySymptoms",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByMedications",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnosesotherDescribe",
    [
      "ros_gastroSubDiagnosesotherDescribeActive",
      "ros_gastroSubDiagnosesotherDescribeHistoryOf",
      "ros_gastroSubDiagnosesotherDescribeRuleOut",
    ],
  ],

  [
    "ros_gastroSubDiagnosesotherSupportedBy",
    [
      "ros_gastroSubDiagnosesotherSupportedByHistory",
      "ros_gastroSubDiagnosesotherSupportedBySymptoms",
      "ros_gastroSubDiagnosesotherSupportedByPhysicalFindings",
      "ros_gastroSubDiagnosesotherSupportedByMedications",
      "ros_gastroSubDiagnosesotherSupportedByTestResults",
      "ros_gastroSubDiagnosesotherSupportedByImageStudies",
      "ros_gastroSubDiagnosesotherSupportedByBiopsy",
      "ros_gastroSubDiagnosesotherSupportedByDme",
      "ros_gastroSubDiagnosesotherSupportedByAbdominalPain",
      "ros_gastroSubDiagnosesotherSupportedByNauseaAndVomiting",
      "ros_gastroSubDiagnosesotherSupportedByOther",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisType",
    [
      "ros_gastroSubDiagnoseshepatitisTypeA",

      "ros_gastroSubDiagnoseshepatitisTypeB",

      "ros_gastroSubDiagnoseshepatitisTypeC",
    ],
  ],
  [
    "ros_gastroSubDiagnoseshepatitisDess",
    [
      "ros_gastroSubDiagnoseshepatitisAcute",

      "ros_gastroSubDiagnoseshepatitisChronic",
    ],
  ],

  [
    "ros_gastroSubDiagnosesulcerDisease",
    [
      "ros_gastroSubDiagnosesulcerDiseaseEsophageal",
      "ros_gastroSubDiagnosesulcerDiseaseGastric",
      "ros_gastroSubDiagnosesulcerDiseaseDuodenal",
      "ros_gastroSubDiagnosesulcerDiseaseOther",
    ],
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailuredes",
    [
      "ros_genitoSubDiagnosesacuteRenalFailuredesactive",
      "ros_genitoSubDiagnosesacuteRenalFailuredeshistoryOf",
      "ros_genitoSubDiagnosesacuteRenalFailuredesruleOut",
    ],
  ],

  [
    "ros_genitoSubDiagnosesacuteRenalFailureSup",
    [
      "ros_genitoSubDiagnosesacuteRenalFailureSupLabTests",
      "ros_genitoSubDiagnosesacuteRenalFailureSupCalculatedGFR",
      "ros_genitoSubDiagnosesacuteRenalFailureSupHospitalization",
      "ros_genitoSubDiagnosesacuteRenalFailureSupOther",
    ],
  ],

  [
    "ros_genitoSubDiagnosesbphDescribe",
    [
      "ros_genitoSubDiagnosesbphDescribeActive",
      "ros_genitoSubDiagnosesbphDescribeHistoryOf",
      "ros_genitoSubDiagnosesbphDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnosesbphSupportedBy",
    [
      "ros_genitoSubDiagnosesbphSupportedByPhysicalExam",
      "ros_genitoSubDiagnosesbphSupportedBySymptoms",
      "ros_genitoSubDiagnosesbphSupportedByLabTest",
      "ros_genitoSubDiagnosesbphSupportedByBiopsy",
      "ros_genitoSubDiagnosesbphSupportedByMedication",
      "ros_genitoSubDiagnosesbphSupportedByHospitalization",
      "ros_genitoSubDiagnosesbphSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe",
    [
      "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeActive",

      "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeHistoryOf",

      "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedBy",
    [
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByLabTests",

      "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByCalculatedGfr",

      "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnoseswhatStage",
    [
      "ros_genitoSubDiagnoseswhatStageStageOne",

      "ros_genitoSubDiagnoseswhatStageStageTwo",

      "ros_genitoSubDiagnoseswhatStageStageThreeA",

      "ros_genitoSubDiagnoseswhatStageStageThreeB",

      "ros_genitoSubDiagnoseswhatStageStageFour",

      "ros_genitoSubDiagnoseswhatStageStageFive",

      "ros_genitoSubDiagnoseswhatStageUnknown",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdDescribe",
    [
      "ros_genitoSubDiagnosesesrdDescribeActive",

      "ros_genitoSubDiagnosesesrdDescribeHistoryOf",

      "ros_genitoSubDiagnosesesrdDescribeRuleOut",
    ],
  ],

  [
    "ros_genitoSubDiagnosesesrdSupportedBy",
    [
      "ros_genitoSubDiagnosesesrdSupportedByLabTests",

      "ros_genitoSubDiagnosesesrdSupportedByCalculatedGfr",

      "ros_genitoSubDiagnosesesrdSupportedByHospitalization",

      "ros_genitoSubDiagnosesesrdSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",

      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    ],
  ],

  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysis",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",

      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    ],
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionDescribe",
    [
      "ros_genitoSubDiagnoseserectileDysfunctionDescribeActive",
      "ros_genitoSubDiagnoseserectileDysfunctionDescribeHistoryOf",
      "ros_genitoSubDiagnoseserectileDysfunctionDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnoseserectileDysfunctionSupportedBy",
    [
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByHistory",
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedBySymptoms",
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByPhysicalFindings",
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByMedications",
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByTestResults",
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOther",
    ],
  ],

  [
    "ros_genitoSubDiagnosesfrequentUtiDescribe",
    [
      "ros_genitoSubDiagnosesfrequentUtiDescribeActive",
      "ros_genitoSubDiagnosesfrequentUtiDescribeHistoryOf",
      "ros_genitoSubDiagnosesfrequentUtiDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnosesfrequentUtiSupportedBy",
    [
      "ros_genitoSubDiagnosesfrequentUtiSupportedBySymptoms",
      "ros_genitoSubDiagnosesfrequentUtiSupportedByCultures",
      "ros_genitoSubDiagnosesfrequentUtiSupportedByLaboratoryResults",
      "ros_genitoSubDiagnosesfrequentUtiSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalDescribe",
    [
      "ros_genitoSubDiagnosesgynecologicalDescribeActive",
      "ros_genitoSubDiagnosesgynecologicalDescribeHistoryOf",
      "ros_genitoSubDiagnosesgynecologicalDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnosesgynecologicalSupportedBy",
    [
      "ros_genitoSubDiagnosesgynecologicalSupportedBySymptoms",
      "ros_genitoSubDiagnosesgynecologicalSupportedByHistory",
      "ros_genitoSubDiagnosesgynecologicalSupportedByDiagnosticResults",
      "ros_genitoSubDiagnosesgynecologicalSupportedByMedications",
      "ros_genitoSubDiagnosesgynecologicalSupportedByVaginalBleeding",
      "ros_genitoSubDiagnosesgynecologicalSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesDescribe",
    [
      "ros_genitoSubDiagnoseskidneyStonesDescribeActive",
      "ros_genitoSubDiagnoseskidneyStonesDescribeHistoryOf",
      "ros_genitoSubDiagnoseskidneyStonesDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesSupportedBy",
    [
      "ros_genitoSubDiagnoseskidneyStonesSupportedByHistory",
      "ros_genitoSubDiagnoseskidneyStonesSupportedBySymptoms",
      "ros_genitoSubDiagnoseskidneyStonesSupportedByPhysicalFindings",
      "ros_genitoSubDiagnoseskidneyStonesSupportedByMedications",
      "ros_genitoSubDiagnoseskidneyStonesSupportedByTestResults",
      "ros_genitoSubDiagnoseskidneyStonesSupportedByImageStudies",
      "ros_genitoSubDiagnoseskidneyStonesSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnoseskidneyStonesType",
    [
      "ros_genitoSubDiagnoseskidneyStonesTypeUrate",
      "ros_genitoSubDiagnoseskidneyStonesTypeCalciumOxalate",
      "ros_genitoSubDiagnoseskidneyStonesTypeMagnesium",
      "ros_genitoSubDiagnoseskidneyStonesTypeOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisDescribe",
    [
      "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeActive",
      "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeHistoryOf",
      "ros_genitoSubDiagnosesnephritisOrNephrosisDescribeRuleOut",
    ],
  ],

  [
    "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedBy",
    [
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedicalHistory",
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByLabTests",
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByImagingStudies",
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByBiopsy",
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByMedications",
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceDescribe",
    [
      "ros_genitoSubDiagnosesurinaryIncontinenceDescribeActive",
      "ros_genitoSubDiagnosesurinaryIncontinenceDescribeHistoryOf",
      "ros_genitoSubDiagnosesurinaryIncontinenceDescribeRuleOut",
    ],
  ],
  [
    "ros_genitoSubDiagnosesurinaryIncontinenceSupportedBy",
    [
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByHistory",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedBySymptoms",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByPhysicalFindings",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByMedications",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByTestResults",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByDME",
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOther",
    ],
  ],

  [
    "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe",
    [
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeDaily",
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeFewTimesAWeek",
      "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeLessThanOnceAWeek",
    ],
  ],
  [
    "ros_genitoStressSub",
    [
      "ros_genitoStressSubDribbling",
      "ros_genitoStressSubUrgency",
      "ros_genitoStressSubOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesotherDescribe",
    [
      "ros_genitoSubDiagnosesotherDescribeActive",
      "ros_genitoSubDiagnosesotherDescribeHistoryOf",
      "ros_genitoSubDiagnosesotherDescribeRuleOut",
    ],
  ],

  [
    "ros_genitoSubDiagnosesotherSupportedBy",
    [
      "ros_genitoSubDiagnosesotherSupportedByHistory",
      "ros_genitoSubDiagnosesotherSupportedBySymptoms",
      "ros_genitoSubDiagnosesotherSupportedByPhysicalFindings",
      "ros_genitoSubDiagnosesotherSupportedByMedications",
      "ros_genitoSubDiagnosesotherSupportedByTestResults",
      "ros_genitoSubDiagnosesotherSupportedByImageStudies",
      "ros_genitoSubDiagnosesotherSupportedByBiopsy",
      "ros_genitoSubDiagnosesotherSupportedByDME",
      "ros_genitoSubDiagnosesotherSupportedByOther",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypeHemodialysis",
      "ros_genitoSubDiagnosesesrdPatientOnDialysisTypePeritonealDialysis",
    ],
  ],
  [
    "ros_genitoSubDiagnosesesrdPatientOnDialysisLocation",
    [
      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationInHome",

      "ros_genitoSubDiagnosesesrdPatientOnDialysisLocationDialysisCenter",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubActive",
      "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubHistoryOf",
      "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubRuleOut",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdecreasedGFR",
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubalbuminuria",
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubelevatedBun",
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubdialysis",
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubactive",
      "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubhistory",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubsymptoms",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubphysicalFindings",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubmedications",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubtestResults",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubimageStudies",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubbiopsy",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubdme",
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubother",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubruleOut",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubphysicalExam",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySublabTests",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubsuppressionTest",
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubother",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubactive",

      "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubdiabetesSubdescribeSubruleOut",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptoms",
      "endocrineSubdiabetesSubsupportedByphysicalFindings",
      "endocrineSubdiabetesSubsupportedBylabTests",
      "endocrineSubdiabetesSubsupportedBymedications",
      "endocrineSubdiabetesSubsupportedByother",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubincreasedThirst",

      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubshakiness",

      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubsweating",

      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubblurredVision",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubtype",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubtypetypeOne",
      "ros_endocrineIsEndocrineSubdiabetesSubtypetypeOnePointFive",
      "ros_endocrineIsEndocrineSubdiabetesSubtypetypeTwo",
      "ros_endocrineIsEndocrineSubdiabetesSubtypegestational",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducator",
    [
      "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducatorDiet",
      "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducatorOralHypoglycemic",
      "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducatorInsulin",
      "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducatorExercise",
      "ros_endocrineIsEndocrineSubAnswerDiagnosesDiabetesSubAnswerDiabeticEducatorWeightLoss",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubactive",
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub",
    [
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubdiet",
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSuboralHypoglycemic",
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubinsulin",
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubexercise",
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubweightLoss",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubproliferative",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubnonProliferative",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubunknown",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubrightEye",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubleftEye",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubbilateral",
    ],
  ],

  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubfunduscopicExam",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubvisionLoss",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySublaserTherapy",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubretinalInjections",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubsurgicalProcedure",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub",
    [
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSuboccasionally",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubonceAYear",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubtwiceAYear",

      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubgreaterTwiceAYear",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubactive",

      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubhistoryOf",

      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubchronicKidneyDisease",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryCeliacDisease",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubvitaminDDeficiency",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubmalabsorption",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubbariatricSurgery",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySublabTests",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfKidneyStones",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubhistoryOfFractures",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubimagingStudies",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubfatigue",
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubhistory",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubsymptoms",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubphysicalFindings",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubmedications",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubtestResults",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubimageStudies",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubbiopsy",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubdme",
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubrapidHeartBeat",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubnervousness",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubweightLoss",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubheatIntolerance",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubtremor",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySublabData",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhistoryOfTreatment",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubhyperReflexes",
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub",
    [
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubactive",
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubhistoryOf",
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubruleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySub",
    [
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubweightGain",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubfatigue",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubhairChanges",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubdepression",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubtreatment",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySublabData",
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubother",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribe",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeActive",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeHistoryOf",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeRuleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySup",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupPhysical",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupMedication",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptoms",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeActive",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeHistoryOf",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeRuleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSub",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubnumbness",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubpain",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubburning",

      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubdecreasedSensation",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubonceAYear",
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubtwiceAYear",
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubquarterly",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubonceAYear",
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubtwiceAYear",
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubquarterly",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseperipheralVascularSup",
    [
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupPhysical",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupVascularStudies",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupskinLesions",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupfootDeformity",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupsurgicalProcedures",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupintermittentClaudication",

      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe",
    [
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeActive",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeHistoryOf",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeRuleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSup",
    [
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuplabTests",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfKidneyStones",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfFractures",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSuphistoryOfimagingStudies",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupfatigue",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherDescribe",
    [
      "ros_endocrineIsEndocrineDiagnoseotherDescribeActive",
      "ros_endocrineIsEndocrineDiagnoseotherDescribeHistoryOf",
      "ros_endocrineIsEndocrineDiagnoseotherDescribeRuleOut",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnoseotherSup",
    [
      "ros_endocrineIsEndocrineDiagnoseotherSuphistory",
      "ros_endocrineIsEndocrineDiagnoseotherSupMedication",
      "ros_endocrineIsEndocrineDiagnoseotherSupSymptoms",
      "ros_endocrineIsEndocrineDiagnoseotherSupphysicalFindings",
      "ros_endocrineIsEndocrineDiagnoseotherSuptestResults",
      "ros_endocrineIsEndocrineDiagnoseotherSupimageStudies",
      "ros_endocrineIsEndocrineDiagnoseotherSupbiopsy",
      "ros_endocrineIsEndocrineDiagnoseotherSupdme",
      "ros_endocrineIsEndocrineDiagnoseotherSupOther",
    ],
  ],
  [
    "ros_endocrineIsEndocrineDiagnose",
    [
      "ros_endocrineIsEndocrineDiagnosechronicKidneyVal",
      "ros_endocrineIsEndocrineDiagnosecoronaryArteryVal",
      "ros_endocrineIsEndocrineDiagnosecushingsDiseaseVal",
      "ros_endocrineIsEndocrineDiagnosediabetesVal",
      "ros_endocrineIsEndocrineDiagnosediabeticRetinopathyVal",
      "ros_endocrineIsEndocrineDiagnosesecondaryHyperparathyroidismVal",
      "ros_endocrineIsEndocrineDiagnosehypertensionDiabetesVal",
      "ros_endocrineIsEndocrineDiagnosehyperthyroidismVal",
      "ros_endocrineIsEndocrineDiagnosehypothyroidismVal",
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyVal",
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularVal",
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismVal",
      "ros_endocrineIsEndocrineDiagnoseotherVal",
    ],
  ],
];

export const ros_hashmap_Eyes = [
  ["ros_eyesVal", "eyes.isEyes"],

  ["ros_eyesDiagCataractVal", "eyes.isEyesSubAnswer.diagnoses.cataracts"],

  [
    "ros_eyesDiagCataractSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagCataractSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagCataractSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagCataractSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagCataractSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagCataractSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagCataractSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagCataractSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagCataractSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagCataractSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagCataractSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagCataractSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagCataractSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagCataractSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagCataractSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagCataractSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.secondaryDiabetes.isSeondaryDiabetes",
  ],
  [
    "ros_eyesDiagCataractSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.secondaryDiabetes.comment",
  ],

  ["ros_eyesDiagGlaucomaVal", "eyes.isEyesSubAnswer.diagnoses.glaucoma"],

  [
    "ros_eyesDiagGlaucomaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagGlaucomaSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetes",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubOpenVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetesSubAnswer.openWideAngle",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetesSubAnswer.closedNarrowAngle",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.isSecondaryDiabetesSubAnswer.comment",
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.secondaryDiabetes.comment",
  ],

  ["ros_eyesDiagHyperopiaVal", "eyes.isEyesSubAnswer.diagnoses.hyperopia"],

  [
    "ros_eyesDiagHyperopiaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagHyperopiaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagHyperopiaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.supportedBy.comment",
  ],

  ["ros_eyesDiagLegalBlindVal", "eyes.isEyesSubAnswer.diagnoses.legallyBlind"],

  [
    "ros_eyesDiagLegalBlindSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagLegalBlindSubDescTextVal",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.lbDescribe",
  ],

  [
    "ros_eyesDiagMascDegenVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegeneration",
  ],

  [
    "ros_eyesDiagMascDegenSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagMascDegenSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagMascDegenSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagMascDegenSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagMascDegenSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagMascDegenSubDesc2WetVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.wet",
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2DryVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.dry",
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2CommentVal",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.comment",
  ],

  ["ros_eyesDiagMyopiaVal", "eyes.isEyesSubAnswer.diagnoses.myopia"],
  [
    "ros_eyesDiagMyopiaSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagMyopiaSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagMyopiaSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagMyopiaSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagMyopiaSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagMyopiaSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagMyopiaSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.supportedBy.comment",
  ],
  ["ros_eyesDiagRetinalVal", "eyes.isEyesSubAnswer.diagnoses.retinalDisease"],
  [
    "ros_eyesDiagRetinalSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagRetinalSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagRetinalSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagRetinalSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagRetinalSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagRetinalSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagRetinalSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagRetinalSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagRetinalSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagRetinalSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagRetinalSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagRetinalSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagRetinalSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagRetinalSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagRetinalSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagRetinalSubDiabetesVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryToDiabetes.isSecondaryToDiabetes",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryToDiabetes.isSecondaryToDiabetesSubAnswer.isVitreousHemorrhage",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesSubIsVitHemmCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryToDiabetes.isSecondaryToDiabetesSubAnswer.comment",
  ],
  [
    "ros_eyesDiagRetinalSubDiabetesCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.secondaryToDiabetes.comment",
  ],
  ["ros_eyesDiagOtherVal", "eyes.isEyesSubAnswer.diagnoses.others"],

  [
    "ros_eyesDiagOtherSubWhEyeRightVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.rightEye",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeLeftVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.leftEye",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeBothVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.both",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.comment",
  ],

  [
    "ros_eyesDiagOtherSubDescActiveVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.active",
  ],
  [
    "ros_eyesDiagOtherSubDescHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.historyOf",
  ],
  [
    "ros_eyesDiagOtherSubDescRuleOutVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.ruleOut",
  ],
  [
    "ros_eyesDiagOtherSubDescCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.comment",
  ],

  [
    "ros_eyesDiagOtherSubSuppHistoryVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.history",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubPainVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.pain",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubDryEyeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.dryEye",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubTearVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.tearing",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubProbSeeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.problemSeeing",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubFloatVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.floaters",
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSubCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.symptomsSubAnswer.comment",
  ],
  [
    "ros_eyesDiagOtherSubSuppPhysicalFindingsVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_eyesDiagOtherSubSuppMedicationsVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.medications",
  ],
  [
    "ros_eyesDiagOtherSubSuppTestResultVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_eyesDiagOtherSubSuppImageStudiesVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_eyesDiagOtherSubSuppBiopsyVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_eyesDiagOtherSubSuppDmeVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.dme",
  ],
  [
    "ros_eyesDiagOtherSubSuppOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.other",
  ],
  [
    "ros_eyesDiagOtherSubSuppOtherSubDescVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_eyesDiagOtherSubSuppCommentVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.supportedBy.comment",
  ],

  [
    "ros_eyesDiagOtherSubOtherVal",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.other",
  ],

  ["ros_eyesDiagCommentVal", "eyes.isEyesSubAnswer.diagnoses.comment"],

  ["ros_eyesCommentVal", "eyes.comment"],
];

export const ros_optionsTreeMapObject_Eyes = {
  ros_eyesDiagCataractVal: "cataracts",

  ros_eyesDiagCataractSubWhEyeRightVal: "rightEye",
  ros_eyesDiagCataractSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagCataractSubWhEyeBothVal: "both",

  ros_eyesDiagCataractSubDescActiveVal: "active",
  ros_eyesDiagCataractSubDescHistoryVal: "historyOf",
  ros_eyesDiagCataractSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagCataractSubSuppHistoryVal: "history",
  ros_eyesDiagCataractSubSuppSympVal: "symptoms",
  ros_eyesDiagCataractSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagCataractSubSuppMedicationsVal: "medications",
  ros_eyesDiagCataractSubSuppTestResultVal: "testResults",
  ros_eyesDiagCataractSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagCataractSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagCataractSubSuppDmeVal: "dme",
  ros_eyesDiagCataractSubSuppOtherVal: "other",

  ros_eyesDiagCataractSubSuppSympSubPainVal: "pain",
  ros_eyesDiagCataractSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagCataractSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagCataractSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagCataractSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagGlaucomaVal: "glaucoma",

  ros_eyesDiagGlaucomaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagGlaucomaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagGlaucomaSubWhEyeBothVal: "both",

  ros_eyesDiagGlaucomaSubDescActiveVal: "active",
  ros_eyesDiagGlaucomaSubDescHistoryVal: "historyOf",
  ros_eyesDiagGlaucomaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagGlaucomaSubSuppHistoryVal: "history",
  ros_eyesDiagGlaucomaSubSuppSympVal: "symptoms",
  ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagGlaucomaSubSuppMedicationsVal: "medications",
  ros_eyesDiagGlaucomaSubSuppTestResultVal: "testResults",
  ros_eyesDiagGlaucomaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagGlaucomaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagGlaucomaSubSuppDmeVal: "dme",
  ros_eyesDiagGlaucomaSubSuppOtherVal: "other",

  ros_eyesDiagGlaucomaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagGlaucomaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagGlaucomaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagGlaucomaSubDiabetesSubOpenVal: "openWideAngle",
  ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal: "closedNarrowAngle",

  ros_eyesDiagHyperopiaVal: "hyperopia",

  ros_eyesDiagHyperopiaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagHyperopiaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagHyperopiaSubWhEyeBothVal: "both",

  ros_eyesDiagHyperopiaSubDescActiveVal: "active",
  ros_eyesDiagHyperopiaSubDescHistoryVal: "historyOf",
  ros_eyesDiagHyperopiaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagHyperopiaSubSuppHistoryVal: "history",
  ros_eyesDiagHyperopiaSubSuppSympVal: "symptoms",
  ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagHyperopiaSubSuppMedicationsVal: "medications",
  ros_eyesDiagHyperopiaSubSuppTestResultVal: "testResults",
  ros_eyesDiagHyperopiaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagHyperopiaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagHyperopiaSubSuppDmeVal: "dme",
  ros_eyesDiagHyperopiaSubSuppOtherVal: "other",

  ros_eyesDiagHyperopiaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagHyperopiaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagHyperopiaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagLegalBlindVal: "legallyBlind",
  ros_eyesDiagLegalBlindSubWhEyeRightVal: "rightEye",
  ros_eyesDiagLegalBlindSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagLegalBlindSubWhEyeBothVal: "both",

  ros_eyesDiagLegalBlindSubDescActiveVal: "active",
  ros_eyesDiagLegalBlindSubDescHistoryVal: "historyOf",
  ros_eyesDiagLegalBlindSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagLegalBlindSubSuppHistoryVal: "history",
  ros_eyesDiagLegalBlindSubSuppSympVal: "symptoms",
  ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagLegalBlindSubSuppMedicationsVal: "medications",
  ros_eyesDiagLegalBlindSubSuppTestResultVal: "testResults",
  ros_eyesDiagLegalBlindSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagLegalBlindSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagLegalBlindSubSuppDmeVal: "dme",
  ros_eyesDiagLegalBlindSubSuppOtherVal: "other",

  ros_eyesDiagLegalBlindSubSuppSympSubPainVal: "pain",
  ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagLegalBlindSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagLegalBlindSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagMascDegenVal: "mascularDegeneration",
  ros_eyesDiagMascDegenSubWhEyeRightVal: "rightEye",
  ros_eyesDiagMascDegenSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagMascDegenSubWhEyeBothVal: "both",

  ros_eyesDiagMascDegenSubDescActiveVal: "active",
  ros_eyesDiagMascDegenSubDescHistoryVal: "historyOf",
  ros_eyesDiagMascDegenSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagMascDegenSubSuppHistoryVal: "history",
  ros_eyesDiagMascDegenSubSuppSympVal: "symptoms",
  ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagMascDegenSubSuppMedicationsVal: "medications",
  ros_eyesDiagMascDegenSubSuppTestResultVal: "testResults",
  ros_eyesDiagMascDegenSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagMascDegenSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagMascDegenSubSuppDmeVal: "dme",
  ros_eyesDiagMascDegenSubSuppOtherVal: "other",

  ros_eyesDiagMascDegenSubSuppSympSubPainVal: "pain",
  ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagMascDegenSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagMascDegenSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagMascDegenSubDesc2WetVal: "wet",
  ros_eyesDiagMascDegenSubDesc2DryVal: "dry",

  ros_eyesDiagMyopiaVal: "myopia",

  ros_eyesDiagMyopiaSubWhEyeRightVal: "rightEye",
  ros_eyesDiagMyopiaSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagMyopiaSubWhEyeBothVal: "both",

  ros_eyesDiagMyopiaSubDescActiveVal: "active",
  ros_eyesDiagMyopiaSubDescHistoryVal: "historyOf",
  ros_eyesDiagMyopiaSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagMyopiaSubSuppHistoryVal: "history",
  ros_eyesDiagMyopiaSubSuppSympVal: "symptoms",
  ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagMyopiaSubSuppMedicationsVal: "medications",
  ros_eyesDiagMyopiaSubSuppTestResultVal: "testResults",
  ros_eyesDiagMyopiaSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagMyopiaSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagMyopiaSubSuppDmeVal: "dme",
  ros_eyesDiagMyopiaSubSuppOtherVal: "other",

  ros_eyesDiagMyopiaSubSuppSympSubPainVal: "pain",
  ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagMyopiaSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagMyopiaSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagRetinalVal: "retinalDisease",

  ros_eyesDiagRetinalSubWhEyeRightVal: "rightEye",
  ros_eyesDiagRetinalSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagRetinalSubWhEyeBothVal: "both",

  ros_eyesDiagRetinalSubDescActiveVal: "active",
  ros_eyesDiagRetinalSubDescHistoryVal: "historyOf",
  ros_eyesDiagRetinalSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagRetinalSubSuppHistoryVal: "history",
  ros_eyesDiagRetinalSubSuppSympVal: "symptoms",
  ros_eyesDiagRetinalSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagRetinalSubSuppMedicationsVal: "medications",
  ros_eyesDiagRetinalSubSuppTestResultVal: "testResults",
  ros_eyesDiagRetinalSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagRetinalSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagRetinalSubSuppDmeVal: "dme",
  ros_eyesDiagRetinalSubSuppOtherVal: "other",

  ros_eyesDiagRetinalSubSuppSympSubPainVal: "pain",
  ros_eyesDiagRetinalSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagRetinalSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagRetinalSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagRetinalSubSuppSympSubFloatVal: "floaters",

  ros_eyesDiagOtherVal: "others",
  ros_eyesDiagOtherSubWhEyeRightVal: "rightEye",
  ros_eyesDiagOtherSubWhEyeLeftVal: "leftEye",
  ros_eyesDiagOtherSubWhEyeBothVal: "both",

  ros_eyesDiagOtherSubDescActiveVal: "active",
  ros_eyesDiagOtherSubDescHistoryVal: "historyOf",
  ros_eyesDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_eyesDiagOtherSubSuppHistoryVal: "history",
  ros_eyesDiagOtherSubSuppSympVal: "symptoms",
  ros_eyesDiagOtherSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_eyesDiagOtherSubSuppMedicationsVal: "medications",
  ros_eyesDiagOtherSubSuppTestResultVal: "testResults",
  ros_eyesDiagOtherSubSuppImageStudiesVal: "imageStudies",
  ros_eyesDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_eyesDiagOtherSubSuppDmeVal: "dme",
  ros_eyesDiagOtherSubSuppOtherVal: "other",

  ros_eyesDiagOtherSubSuppSympSubPainVal: "pain",
  ros_eyesDiagOtherSubSuppSympSubDryEyeVal: "dryEye",
  ros_eyesDiagOtherSubSuppSympSubTearVal: "tearing",
  ros_eyesDiagOtherSubSuppSympSubProbSeeVal: "problemSeeing",
  ros_eyesDiagOtherSubSuppSympSubFloatVal: "floaters",
};

export const ros_OptionsTreeWrappers_Eyes = [
  [
    "ros_eyesDiag",
    [
      "ros_eyesDiagCataractVal",
      "ros_eyesDiagGlaucomaVal",
      "ros_eyesDiagHyperopiaVal",
      "ros_eyesDiagLegalBlindVal",
      "ros_eyesDiagMascDegenVal",
      "ros_eyesDiagMyopiaVal",
      "ros_eyesDiagRetinalVal",
      "ros_eyesDiagOtherVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubWhEye",
    [
      "ros_eyesDiagCataractSubWhEyeRightVal",
      "ros_eyesDiagCataractSubWhEyeLeftVal",
      "ros_eyesDiagCataractSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubDesc",
    [
      "ros_eyesDiagCataractSubDescActiveVal",
      "ros_eyesDiagCataractSubDescHistoryVal",
      "ros_eyesDiagCataractSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubSupp",
    [
      "ros_eyesDiagCataractSubSuppHistoryVal",
      "ros_eyesDiagCataractSubSuppSympVal",
      "ros_eyesDiagCataractSubSuppPhysicalFindingsVal",
      "ros_eyesDiagCataractSubSuppMedicationsVal",
      "ros_eyesDiagCataractSubSuppTestResultVal",
      "ros_eyesDiagCataractSubSuppImageStudiesVal",
      "ros_eyesDiagCataractSubSuppBiopsyVal",
      "ros_eyesDiagCataractSubSuppDmeVal",
      "ros_eyesDiagCataractSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagCataractSubSuppSympSub",
    [
      "ros_eyesDiagCataractSubSuppSympSubPainVal",
      "ros_eyesDiagCataractSubSuppSympSubDryEyeVal",
      "ros_eyesDiagCataractSubSuppSympSubTearVal",
      "ros_eyesDiagCataractSubSuppSympSubProbSeeVal",
      "ros_eyesDiagCataractSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEye",
    [
      "ros_eyesDiagGlaucomaSubWhEyeRightVal",
      "ros_eyesDiagGlaucomaSubWhEyeLeftVal",
      "ros_eyesDiagGlaucomaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubDesc",
    [
      "ros_eyesDiagGlaucomaSubDescActiveVal",
      "ros_eyesDiagGlaucomaSubDescHistoryVal",
      "ros_eyesDiagGlaucomaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubSupp",
    [
      "ros_eyesDiagGlaucomaSubSuppHistoryVal",
      "ros_eyesDiagGlaucomaSubSuppSympVal",
      "ros_eyesDiagGlaucomaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagGlaucomaSubSuppMedicationsVal",
      "ros_eyesDiagGlaucomaSubSuppTestResultVal",
      "ros_eyesDiagGlaucomaSubSuppImageStudiesVal",
      "ros_eyesDiagGlaucomaSubSuppBiopsyVal",
      "ros_eyesDiagGlaucomaSubSuppDmeVal",
      "ros_eyesDiagGlaucomaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubSuppSympSub",
    [
      "ros_eyesDiagGlaucomaSubSuppSympSubPainVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubTearVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagGlaucomaSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubDiabetesSub",
    [
      "ros_eyesDiagGlaucomaSubDiabetesSubOpenVal",
      "ros_eyesDiagGlaucomaSubDiabetesSubNarrowVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEye",
    [
      "ros_eyesDiagHyperopiaSubWhEyeRightVal",
      "ros_eyesDiagHyperopiaSubWhEyeLeftVal",
      "ros_eyesDiagHyperopiaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubDesc",
    [
      "ros_eyesDiagHyperopiaSubDescActiveVal",
      "ros_eyesDiagHyperopiaSubDescHistoryVal",
      "ros_eyesDiagHyperopiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubSupp",
    [
      "ros_eyesDiagHyperopiaSubSuppHistoryVal",
      "ros_eyesDiagHyperopiaSubSuppSympVal",
      "ros_eyesDiagHyperopiaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagHyperopiaSubSuppMedicationsVal",
      "ros_eyesDiagHyperopiaSubSuppTestResultVal",
      "ros_eyesDiagHyperopiaSubSuppImageStudiesVal",
      "ros_eyesDiagHyperopiaSubSuppBiopsyVal",
      "ros_eyesDiagHyperopiaSubSuppDmeVal",
      "ros_eyesDiagHyperopiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubSuppSympSub",
    [
      "ros_eyesDiagHyperopiaSubSuppSympSubPainVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubTearVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagHyperopiaSubSuppSympSubFloatVal",
    ],
  ],

  [
    "ros_eyesDiagLegalBlindSubWhEye",
    [
      "ros_eyesDiagLegalBlindSubWhEyeRightVal",
      "ros_eyesDiagLegalBlindSubWhEyeLeftVal",
      "ros_eyesDiagLegalBlindSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubDesc",
    [
      "ros_eyesDiagLegalBlindSubDescActiveVal",
      "ros_eyesDiagLegalBlindSubDescHistoryVal",
      "ros_eyesDiagLegalBlindSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubSupp",
    [
      "ros_eyesDiagLegalBlindSubSuppHistoryVal",
      "ros_eyesDiagLegalBlindSubSuppSympVal",
      "ros_eyesDiagLegalBlindSubSuppPhysicalFindingsVal",
      "ros_eyesDiagLegalBlindSubSuppMedicationsVal",
      "ros_eyesDiagLegalBlindSubSuppTestResultVal",
      "ros_eyesDiagLegalBlindSubSuppImageStudiesVal",
      "ros_eyesDiagLegalBlindSubSuppBiopsyVal",
      "ros_eyesDiagLegalBlindSubSuppDmeVal",
      "ros_eyesDiagLegalBlindSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubSuppSympSub",
    [
      "ros_eyesDiagLegalBlindSubSuppSympSubPainVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubDryEyeVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubTearVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubProbSeeVal",
      "ros_eyesDiagLegalBlindSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubWhEye",
    [
      "ros_eyesDiagMascDegenSubWhEyeRightVal",
      "ros_eyesDiagMascDegenSubWhEyeLeftVal",
      "ros_eyesDiagMascDegenSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubDesc",
    [
      "ros_eyesDiagMascDegenSubDescActiveVal",
      "ros_eyesDiagMascDegenSubDescHistoryVal",
      "ros_eyesDiagMascDegenSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubSupp",
    [
      "ros_eyesDiagMascDegenSubSuppHistoryVal",
      "ros_eyesDiagMascDegenSubSuppSympVal",
      "ros_eyesDiagMascDegenSubSuppPhysicalFindingsVal",
      "ros_eyesDiagMascDegenSubSuppMedicationsVal",
      "ros_eyesDiagMascDegenSubSuppTestResultVal",
      "ros_eyesDiagMascDegenSubSuppImageStudiesVal",
      "ros_eyesDiagMascDegenSubSuppBiopsyVal",
      "ros_eyesDiagMascDegenSubSuppDmeVal",
      "ros_eyesDiagMascDegenSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubSuppSympSub",
    [
      "ros_eyesDiagMascDegenSubSuppSympSubPainVal",
      "ros_eyesDiagMascDegenSubSuppSympSubDryEyeVal",
      "ros_eyesDiagMascDegenSubSuppSympSubTearVal",
      "ros_eyesDiagMascDegenSubSuppSympSubProbSeeVal",
      "ros_eyesDiagMascDegenSubSuppSympSubFloatVal",
    ],
  ],

  [
    "ros_eyesDiagMascDegenSubDesc2",
    [
      "ros_eyesDiagMascDegenSubDesc2WetVal",
      "ros_eyesDiagMascDegenSubDesc2DryVal",
    ],
  ],

  [
    "ros_eyesDiagMyopiaSubWhEye",
    [
      "ros_eyesDiagMyopiaSubWhEyeRightVal",
      "ros_eyesDiagMyopiaSubWhEyeLeftVal",
      "ros_eyesDiagMyopiaSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubDesc",
    [
      "ros_eyesDiagMyopiaSubDescActiveVal",
      "ros_eyesDiagMyopiaSubDescHistoryVal",
      "ros_eyesDiagMyopiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubSupp",
    [
      "ros_eyesDiagMyopiaSubSuppHistoryVal",
      "ros_eyesDiagMyopiaSubSuppSympVal",
      "ros_eyesDiagMyopiaSubSuppPhysicalFindingsVal",
      "ros_eyesDiagMyopiaSubSuppMedicationsVal",
      "ros_eyesDiagMyopiaSubSuppTestResultVal",
      "ros_eyesDiagMyopiaSubSuppImageStudiesVal",
      "ros_eyesDiagMyopiaSubSuppBiopsyVal",
      "ros_eyesDiagMyopiaSubSuppDmeVal",
      "ros_eyesDiagMyopiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubSuppSympSub",
    [
      "ros_eyesDiagMyopiaSubSuppSympSubPainVal",
      "ros_eyesDiagMyopiaSubSuppSympSubDryEyeVal",
      "ros_eyesDiagMyopiaSubSuppSympSubTearVal",
      "ros_eyesDiagMyopiaSubSuppSympSubProbSeeVal",
      "ros_eyesDiagMyopiaSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubWhEye",
    [
      "ros_eyesDiagRetinalSubWhEyeRightVal",
      "ros_eyesDiagRetinalSubWhEyeLeftVal",
      "ros_eyesDiagRetinalSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubDesc",
    [
      "ros_eyesDiagRetinalSubDescActiveVal",
      "ros_eyesDiagRetinalSubDescHistoryVal",
      "ros_eyesDiagRetinalSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubSupp",
    [
      "ros_eyesDiagRetinalSubSuppHistoryVal",
      "ros_eyesDiagRetinalSubSuppSympVal",
      "ros_eyesDiagRetinalSubSuppPhysicalFindingsVal",
      "ros_eyesDiagRetinalSubSuppMedicationsVal",
      "ros_eyesDiagRetinalSubSuppTestResultVal",
      "ros_eyesDiagRetinalSubSuppImageStudiesVal",
      "ros_eyesDiagRetinalSubSuppBiopsyVal",
      "ros_eyesDiagRetinalSubSuppDmeVal",
      "ros_eyesDiagRetinalSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubSuppSympSub",
    [
      "ros_eyesDiagRetinalSubSuppSympSubPainVal",
      "ros_eyesDiagRetinalSubSuppSympSubDryEyeVal",
      "ros_eyesDiagRetinalSubSuppSympSubTearVal",
      "ros_eyesDiagRetinalSubSuppSympSubProbSeeVal",
      "ros_eyesDiagRetinalSubSuppSympSubFloatVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubWhEye",
    [
      "ros_eyesDiagOtherSubWhEyeRightVal",
      "ros_eyesDiagOtherSubWhEyeLeftVal",
      "ros_eyesDiagOtherSubWhEyeBothVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubDesc",
    [
      "ros_eyesDiagOtherSubDescActiveVal",
      "ros_eyesDiagOtherSubDescHistoryVal",
      "ros_eyesDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubSupp",
    [
      "ros_eyesDiagOtherSubSuppHistoryVal",
      "ros_eyesDiagOtherSubSuppSympVal",
      "ros_eyesDiagOtherSubSuppPhysicalFindingsVal",
      "ros_eyesDiagOtherSubSuppMedicationsVal",
      "ros_eyesDiagOtherSubSuppTestResultVal",
      "ros_eyesDiagOtherSubSuppImageStudiesVal",
      "ros_eyesDiagOtherSubSuppBiopsyVal",
      "ros_eyesDiagOtherSubSuppDmeVal",
      "ros_eyesDiagOtherSubSuppOtherVal",
    ],
  ],
  [
    "ros_eyesDiagOtherSubSuppSympSub",
    [
      "ros_eyesDiagOtherSubSuppSympSubPainVal",
      "ros_eyesDiagOtherSubSuppSympSubDryEyeVal",
      "ros_eyesDiagOtherSubSuppSympSubTearVal",
      "ros_eyesDiagOtherSubSuppSympSubProbSeeVal",
      "ros_eyesDiagOtherSubSuppSympSubFloatVal",
    ],
  ],
];

export const ros_hashmap_Ears = [
  ["ros_earsVal", "ears.isEars"],

  [
    "ros_earsDiagDiffHearVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearing",
  ],

  [
    "ros_earsDiagDiffHearSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagDiffHearSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagDiffHearSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagDiffHearSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagDiffHearSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagDiffHearSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagDiffHearSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagDiffHearSubSuppHearAidsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.hearingAids",
  ],
  [
    "ros_earsDiagDiffHearSubSuppMedicVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.medication",
  ],
  [
    "ros_earsDiagDiffHearSubSuppReadLipsVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.readingLips",
  ],
  [
    "ros_earsDiagDiffHearSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagDiffHearSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagDiffHearSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagLegalDeafVal", "ears.isEarsSubAnswer.diagnoses.legallyDeaf"],

  [
    "ros_earsDiagLegalDeafSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagLegalDeafSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagLegalDeafSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagLegalDeafSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagLegalDeafSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagLegalDeafSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagLegalDeafSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagTinnitusVal", "ears.isEarsSubAnswer.diagnoses.tinnitus"],

  [
    "ros_earsDiagTinnitusSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagTinnitusSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagTinnitusSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagTinnitusSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagTinnitusSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagTinnitusSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagTinnitusSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagTinnitusSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagTinnitusSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagTinnitusSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagTinnitusSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagTinnitusSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagTinnitusSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagTinnitusSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagTinnitusSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.supportedBy.comment",
  ],

  ["ros_earsDiagVertigoVal", "ears.isEarsSubAnswer.diagnoses.vertigo"],

  [
    "ros_earsDiagVertigoSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagVertigoSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagVertigoSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagVertigoSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagVertigoSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagVertigoSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagVertigoSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagVertigoSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagVertigoSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagVertigoSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagVertigoSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagVertigoSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagVertigoSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagVertigoSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagVertigoSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.supportedBy.comment",
  ],

  [
    "ros_earsDiagVertigoSubLoseBalVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.isLoseYourBalance",
  ],
  [
    "ros_earsDiagVertigoSubLoseBalCommentVal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.comment",
  ],

  ["ros_earsDiagOtherVal", "ears.isEarsSubAnswer.diagnoses.other"],
  [
    "ros_earsDiagOtherSubDescActiveVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_earsDiagOtherSubDescHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_earsDiagOtherSubDescRuleOutVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_earsDiagOtherSubDescCommentVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_earsDiagOtherSubSuppHistoryVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_earsDiagOtherSubSuppSymptomsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_earsDiagOtherSubSuppPhysFindVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_earsDiagOtherSubSuppMedicsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_earsDiagOtherSubSuppTestResultsVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_earsDiagOtherSubSuppImageStudyVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_earsDiagOtherSubSuppBiopsyVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_earsDiagOtherSubSuppDmeVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_earsDiagOtherSubSuppOtherVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_earsDiagOtherSubSuppOtherSubDescVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_earsDiagOtherSubSuppCommentVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_earsDiagOtherSubOtherVal",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_earsDiagnosesCommentVal", "ears.isEarsSubAnswer.diagnoses.comment"],
  ["ros_earsCommentVal", "ears.comment"],
];

export const ros_optionsTreeMapObject_Ears = {
  ros_earsDiagDiffHearVal: "difficultyHearing",

  ros_earsDiagDiffHearSubDescActiveVal: "active",
  ros_earsDiagDiffHearSubDescHistoryVal: "historyOf",
  ros_earsDiagDiffHearSubDescRuleOutVal: "ruleOut",

  ros_earsDiagDiffHearSubSuppHistoryVal: "history",
  ros_earsDiagDiffHearSubSuppSymptomsVal: "symptoms",
  ros_earsDiagDiffHearSubSuppTestResultsVal: "testResults",
  ros_earsDiagDiffHearSubSuppHearAidsVal: "hearingAids",
  ros_earsDiagDiffHearSubSuppMedicVal: "medication",
  ros_earsDiagDiffHearSubSuppReadLipsVal: "readingLips",
  ros_earsDiagDiffHearSubSuppOtherVal: "other",

  ros_earsDiagLegalDeafVal: "legallyDeaf",

  ros_earsDiagLegalDeafSubDescActiveVal: "active",
  ros_earsDiagLegalDeafSubDescHistoryVal: "historyOf",
  ros_earsDiagLegalDeafSubDescRuleOutVal: "ruleOut",

  ros_earsDiagLegalDeafSubSuppHistoryVal: "history",
  ros_earsDiagLegalDeafSubSuppSymptomsVal: "symptoms",
  ros_earsDiagLegalDeafSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagLegalDeafSubSuppMedicsVal: "medications",
  ros_earsDiagLegalDeafSubSuppTestResultsVal: "testResults",
  ros_earsDiagLegalDeafSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagLegalDeafSubSuppBiopsyVal: "biopsy",
  ros_earsDiagLegalDeafSubSuppDmeVal: "dme",
  ros_earsDiagLegalDeafSubSuppOtherVal: "other",

  ros_earsDiagTinnitusVal: "tinnitus",

  ros_earsDiagTinnitusSubDescActiveVal: "active",
  ros_earsDiagTinnitusSubDescHistoryVal: "historyOf",
  ros_earsDiagTinnitusSubDescRuleOutVal: "ruleOut",

  ros_earsDiagTinnitusSubSuppHistoryVal: "history",
  ros_earsDiagTinnitusSubSuppSymptomsVal: "symptoms",
  ros_earsDiagTinnitusSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagTinnitusSubSuppMedicsVal: "medications",
  ros_earsDiagTinnitusSubSuppTestResultsVal: "testResults",
  ros_earsDiagTinnitusSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagTinnitusSubSuppBiopsyVal: "biopsy",
  ros_earsDiagTinnitusSubSuppDmeVal: "dme",
  ros_earsDiagTinnitusSubSuppOtherVal: "other",

  ros_earsDiagVertigoVal: "vertigo",

  ros_earsDiagVertigoSubDescActiveVal: "active",
  ros_earsDiagVertigoSubDescHistoryVal: "historyOf",
  ros_earsDiagVertigoSubDescRuleOutVal: "ruleOut",

  ros_earsDiagVertigoSubSuppHistoryVal: "history",
  ros_earsDiagVertigoSubSuppSymptomsVal: "symptoms",
  ros_earsDiagVertigoSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagVertigoSubSuppMedicsVal: "medications",
  ros_earsDiagVertigoSubSuppTestResultsVal: "testResults",
  ros_earsDiagVertigoSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagVertigoSubSuppBiopsyVal: "biopsy",
  ros_earsDiagVertigoSubSuppDmeVal: "dme",
  ros_earsDiagVertigoSubSuppOtherVal: "other",

  ros_earsDiagOtherVal: "other",

  ros_earsDiagOtherSubDescActiveVal: "active",
  ros_earsDiagOtherSubDescHistoryVal: "historyOf",
  ros_earsDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_earsDiagOtherSubSuppHistoryVal: "history",
  ros_earsDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_earsDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_earsDiagOtherSubSuppMedicsVal: "medications",
  ros_earsDiagOtherSubSuppTestResultsVal: "testResults",
  ros_earsDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_earsDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_earsDiagOtherSubSuppDmeVal: "dme",
  ros_earsDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Ears = [
  [
    "ros_earsDiagnoses",
    [
      "ros_earsDiagDiffHearVal",
      "ros_earsDiagLegalDeafVal",
      "ros_earsDiagTinnitusVal",
      "ros_earsDiagVertigoVal",
      "ros_earsDiagOtherVal",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubDesc",
    [
      "ros_earsDiagDiffHearSubDescActiveVal",
      "ros_earsDiagDiffHearSubDescHistoryVal",
      "ros_earsDiagDiffHearSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubSupp",
    [
      "ros_earsDiagDiffHearSubSuppHistoryVal",
      "ros_earsDiagDiffHearSubSuppSymptomsVal",
      "ros_earsDiagDiffHearSubSuppTestResultsVal",
      "ros_earsDiagDiffHearSubSuppHearAidsVal",
      "ros_earsDiagDiffHearSubSuppMedicVal",
      "ros_earsDiagDiffHearSubSuppReadLipsVal",
      "ros_earsDiagDiffHearSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagLegalDeafSubDesc",
    [
      "ros_earsDiagLegalDeafSubDescActiveVal",
      "ros_earsDiagLegalDeafSubDescHistoryVal",
      "ros_earsDiagLegalDeafSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubSupp",
    [
      "ros_earsDiagLegalDeafSubSuppHistoryVal",
      "ros_earsDiagLegalDeafSubSuppSymptomsVal",
      "ros_earsDiagLegalDeafSubSuppPhysFindVal",
      "ros_earsDiagLegalDeafSubSuppMedicsVal",
      "ros_earsDiagLegalDeafSubSuppTestResultsVal",
      "ros_earsDiagLegalDeafSubSuppImageStudyVal",
      "ros_earsDiagLegalDeafSubSuppBiopsyVal",
      "ros_earsDiagLegalDeafSubSuppDmeVal",
      "ros_earsDiagLegalDeafSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagTinnitusSubDesc",
    [
      "ros_earsDiagTinnitusSubDescActiveVal",
      "ros_earsDiagTinnitusSubDescHistoryVal",
      "ros_earsDiagTinnitusSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagTinnitusSubSupp",
    [
      "ros_earsDiagTinnitusSubSuppHistoryVal",
      "ros_earsDiagTinnitusSubSuppSymptomsVal",
      "ros_earsDiagTinnitusSubSuppPhysFindVal",
      "ros_earsDiagTinnitusSubSuppMedicsVal",
      "ros_earsDiagTinnitusSubSuppTestResultsVal",
      "ros_earsDiagTinnitusSubSuppImageStudyVal",
      "ros_earsDiagTinnitusSubSuppBiopsyVal",
      "ros_earsDiagTinnitusSubSuppDmeVal",
      "ros_earsDiagTinnitusSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagVertigoSubDesc",
    [
      "ros_earsDiagVertigoSubDescActiveVal",
      "ros_earsDiagVertigoSubDescHistoryVal",
      "ros_earsDiagVertigoSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagVertigoSubSupp",
    [
      "ros_earsDiagVertigoSubSuppHistoryVal",
      "ros_earsDiagVertigoSubSuppSymptomsVal",
      "ros_earsDiagVertigoSubSuppPhysFindVal",
      "ros_earsDiagVertigoSubSuppMedicsVal",
      "ros_earsDiagVertigoSubSuppTestResultsVal",
      "ros_earsDiagVertigoSubSuppImageStudyVal",
      "ros_earsDiagVertigoSubSuppBiopsyVal",
      "ros_earsDiagVertigoSubSuppDmeVal",
      "ros_earsDiagVertigoSubSuppOtherVal",
    ],
  ],

  [
    "ros_earsDiagOtherSubDesc",
    [
      "ros_earsDiagOtherSubDescActiveVal",
      "ros_earsDiagOtherSubDescHistoryVal",
      "ros_earsDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_earsDiagOtherSubSupp",
    [
      "ros_earsDiagOtherSubSuppHistoryVal",
      "ros_earsDiagOtherSubSuppSymptomsVal",
      "ros_earsDiagOtherSubSuppPhysFindVal",
      "ros_earsDiagOtherSubSuppMedicsVal",
      "ros_earsDiagOtherSubSuppTestResultsVal",
      "ros_earsDiagOtherSubSuppImageStudyVal",
      "ros_earsDiagOtherSubSuppBiopsyVal",
      "ros_earsDiagOtherSubSuppDmeVal",
      "ros_earsDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Nose = [
  ["ros_noseVal", "nose.isNose"],

  ["ros_noseDiagAllergyVal", "nose.isNoseSubAnswer.diagnoses.allergicRhinitis"],

  [
    "ros_noseDiagAllergySubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagAllergySubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagAllergySubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagAllergySubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagAllergySubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagAllergySubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagAllergySubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagAllergySubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagAllergySubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagAllergySubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagAllergySubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagAllergySubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagAllergySubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagAllergySubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagAllergySubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_noseDiagChronicNasalVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDrip",
  ],

  [
    "ros_noseDiagChronicNasalSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagChronicNasalSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagChronicNasalSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagChronicNasalSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagChronicNasalSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagChronicNasalSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagChronicNasalSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.supportedBy.comment",
  ],

  ["ros_noseDiagNoseBleedVal", "nose.isNoseSubAnswer.diagnoses.noseBleeds"],

  [
    "ros_noseDiagNoseBleedSubActiveVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.active",
  ],
  [
    "ros_noseDiagNoseBleedSubHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.historyOf",
  ],
  [
    "ros_noseDiagNoseBleedSubRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.ruleOut",
  ],
  [
    "ros_noseDiagNoseBleedSubCommentVal",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.comment",
  ],

  [
    "ros_noseDiagSeasonAllergyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergies",
  ],

  [
    "ros_noseDiagSeasonAllergySubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSeasonAllergySubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSeasonAllergySubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSeasonAllergySubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.supportedBy.comment",
  ],

  [
    "ros_noseDiagSinusInfectVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfections",
  ],

  [
    "ros_noseDiagSinusInfectSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSinusInfectSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSinusInfectSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSinusInfectSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSinusInfectSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSinusInfectSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSinusInfectSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.supportedBy.comment",
  ],
  [
    "ros_noseDiagSinusInfectSubExuClearVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.clear",
  ],
  [
    "ros_noseDiagSinusInfectSubExuPurulentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.purulent",
  ],
  [
    "ros_noseDiagSinusInfectSubExuCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.comment",
  ],

  ["ros_noseDiagSinustisVal", "nose.isNoseSubAnswer.diagnoses.sinusitis"],

  [
    "ros_noseDiagSinustisSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagSinustisSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagSinustisSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagSinustisSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagSinustisSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagSinustisSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagSinustisSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagSinustisSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagSinustisSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagSinustisSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagSinustisSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagSinustisSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagSinustisSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagSinustisSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagSinustisSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.supportedBy.comment",
  ],

  ["ros_noseDiagOtherVal", "nose.isNoseSubAnswer.diagnoses.other"],

  [
    "ros_noseDiagOtherSubDescActiveVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_noseDiagOtherSubDescHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_noseDiagOtherSubDescRuleOutVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_noseDiagOtherSubDescCommentVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_noseDiagOtherSubSuppHistoryVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_noseDiagOtherSubSuppSymptomsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_noseDiagOtherSubSuppPhysFindVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_noseDiagOtherSubSuppMedicsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_noseDiagOtherSubSuppTestResultsVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_noseDiagOtherSubSuppImageStudyVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_noseDiagOtherSubSuppBiopsyVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_noseDiagOtherSubSuppDmeVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_noseDiagOtherSubSuppOtherVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_noseDiagOtherSubSuppOtherSubDescVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_noseDiagOtherSubSuppCommentVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_noseDiagOtherSubOtherVal",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_noseDiagnosesCommentVal", "nose.isNoseSubAnswer.diagnoses.comment"],
  ["ros_noseCommentVal", "nose.comment"],
];

export const ros_optionsTreeMapObject_Nose = {
  ros_noseDiagAllergyVal: "allergicRhinitis",

  ros_noseDiagAllergySubDescActiveVal: "active",
  ros_noseDiagAllergySubDescHistoryVal: "historyOf",
  ros_noseDiagAllergySubDescRuleOutVal: "ruleOut",

  ros_noseDiagAllergySubSuppHistoryVal: "history",
  ros_noseDiagAllergySubSuppSymptomsVal: "symptoms",
  ros_noseDiagAllergySubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagAllergySubSuppMedicsVal: "medications",
  ros_noseDiagAllergySubSuppTestResultsVal: "testResults",
  ros_noseDiagAllergySubSuppImageStudyVal: "imageStudies",
  ros_noseDiagAllergySubSuppBiopsyVal: "biopsy",
  ros_noseDiagAllergySubSuppDmeVal: "dme",
  ros_noseDiagAllergySubSuppOtherVal: "other",

  ros_noseDiagChronicNasalVal: "chronicNasalDrip",

  ros_noseDiagChronicNasalSubDescActiveVal: "active",
  ros_noseDiagChronicNasalSubDescHistoryVal: "historyOf",
  ros_noseDiagChronicNasalSubDescRuleOutVal: "ruleOut",

  ros_noseDiagChronicNasalSubSuppHistoryVal: "history",
  ros_noseDiagChronicNasalSubSuppSymptomsVal: "symptoms",
  ros_noseDiagChronicNasalSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagChronicNasalSubSuppMedicsVal: "medications",
  ros_noseDiagChronicNasalSubSuppTestResultsVal: "testResults",
  ros_noseDiagChronicNasalSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagChronicNasalSubSuppBiopsyVal: "biopsy",
  ros_noseDiagChronicNasalSubSuppDmeVal: "dme",
  ros_noseDiagChronicNasalSubSuppOtherVal: "other",

  ros_noseDiagNoseBleedVal: "noseBleeds",

  ros_noseDiagNoseBleedSubActiveVal: "active",
  ros_noseDiagNoseBleedSubHistoryVal: "historyOf",
  ros_noseDiagNoseBleedSubRuleOutVal: "ruleOut",

  ros_noseDiagSeasonAllergyVal: "seasonalAllergies",

  ros_noseDiagSeasonAllergySubDescActiveVal: "active",
  ros_noseDiagSeasonAllergySubDescHistoryVal: "historyOf",
  ros_noseDiagSeasonAllergySubDescRuleOutVal: "ruleOut",

  ros_noseDiagSeasonAllergySubSuppHistoryVal: "history",
  ros_noseDiagSeasonAllergySubSuppSymptomsVal: "symptoms",
  ros_noseDiagSeasonAllergySubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSeasonAllergySubSuppMedicsVal: "medications",
  ros_noseDiagSeasonAllergySubSuppTestResultsVal: "testResults",
  ros_noseDiagSeasonAllergySubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSeasonAllergySubSuppBiopsyVal: "biopsy",
  ros_noseDiagSeasonAllergySubSuppDmeVal: "dme",
  ros_noseDiagSeasonAllergySubSuppOtherVal: "other",

  ros_noseDiagSinusInfectVal: "sinusInfections",

  ros_noseDiagSinusInfectSubDescActiveVal: "active",
  ros_noseDiagSinusInfectSubDescHistoryVal: "historyOf",
  ros_noseDiagSinusInfectSubDescRuleOutVal: "ruleOut",
  ros_noseDiagSinusInfectSubDescCommentVal: "comment",

  ros_noseDiagSinusInfectSubSuppHistoryVal: "history",
  ros_noseDiagSinusInfectSubSuppSymptomsVal: "symptoms",
  ros_noseDiagSinusInfectSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSinusInfectSubSuppMedicsVal: "medications",
  ros_noseDiagSinusInfectSubSuppTestResultsVal: "testResults",
  ros_noseDiagSinusInfectSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSinusInfectSubSuppBiopsyVal: "biopsy",
  ros_noseDiagSinusInfectSubSuppDmeVal: "dme",
  ros_noseDiagSinusInfectSubSuppOtherVal: "other",

  ros_noseDiagSinusInfectSubExuClearVal: "clear",
  ros_noseDiagSinusInfectSubExuPurulentVal: "purulent",
  ros_noseDiagSinusInfectSubExuCommentVal: "comment",

  ros_noseDiagSinustisVal: "sinusitis",

  ros_noseDiagSinustisSubDescActiveVal: "active",
  ros_noseDiagSinustisSubDescHistoryVal: "historyOf",
  ros_noseDiagSinustisSubDescRuleOutVal: "ruleOut",

  ros_noseDiagSinustisSubSuppHistoryVal: "history",
  ros_noseDiagSinustisSubSuppSymptomsVal: "symptoms",
  ros_noseDiagSinustisSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagSinustisSubSuppMedicsVal: "medications",
  ros_noseDiagSinustisSubSuppTestResultsVal: "testResults",
  ros_noseDiagSinustisSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagSinustisSubSuppBiopsyVal: "biopsy",
  ros_noseDiagSinustisSubSuppDmeVal: "dme",
  ros_noseDiagSinustisSubSuppOtherVal: "other",

  ros_noseDiagOtherVal: "other",

  ros_noseDiagOtherSubDescActiveVal: "active",
  ros_noseDiagOtherSubDescHistoryVal: "historyOf",
  ros_noseDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_noseDiagOtherSubSuppHistoryVal: "history",
  ros_noseDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_noseDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_noseDiagOtherSubSuppMedicsVal: "medications",
  ros_noseDiagOtherSubSuppTestResultsVal: "testResults",
  ros_noseDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_noseDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_noseDiagOtherSubSuppDmeVal: "dme",
  ros_noseDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Nose = [
  [
    "ros_noseDiagnoses",
    [
      "ros_noseDiagAllergyVal",
      "ros_noseDiagChronicNasalVal",
      "ros_noseDiagNoseBleedVal",
      "ros_noseDiagSeasonAllergyVal",
      "ros_noseDiagSinusInfectVal",
      "ros_noseDiagSinustisVal",
      "ros_noseDiagOtherVal",
    ],
  ],
  [
    "ros_noseDiagAllergySubDesc",
    [
      "ros_noseDiagAllergySubDescActiveVal",
      "ros_noseDiagAllergySubDescHistoryVal",
      "ros_noseDiagAllergySubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagAllergySubSupp",
    [
      "ros_noseDiagAllergySubSuppHistoryVal",
      "ros_noseDiagAllergySubSuppSymptomsVal",
      "ros_noseDiagAllergySubSuppPhysFindVal",
      "ros_noseDiagAllergySubSuppMedicsVal",
      "ros_noseDiagAllergySubSuppTestResultsVal",
      "ros_noseDiagAllergySubSuppImageStudyVal",
      "ros_noseDiagAllergySubSuppBiopsyVal",
      "ros_noseDiagAllergySubSuppDmeVal",
      "ros_noseDiagAllergySubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagChronicNasalSubDesc",
    [
      "ros_noseDiagChronicNasalSubDescActiveVal",
      "ros_noseDiagChronicNasalSubDescHistoryVal",
      "ros_noseDiagChronicNasalSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagChronicNasalSubSupp",
    [
      "ros_noseDiagChronicNasalSubSuppHistoryVal",
      "ros_noseDiagChronicNasalSubSuppSymptomsVal",
      "ros_noseDiagChronicNasalSubSuppPhysFindVal",
      "ros_noseDiagChronicNasalSubSuppMedicsVal",
      "ros_noseDiagChronicNasalSubSuppTestResultsVal",
      "ros_noseDiagChronicNasalSubSuppImageStudyVal",
      "ros_noseDiagChronicNasalSubSuppBiopsyVal",
      "ros_noseDiagChronicNasalSubSuppDmeVal",
      "ros_noseDiagChronicNasalSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagNoseBleedSub",
    [
      "ros_noseDiagNoseBleedSubActiveVal",
      "ros_noseDiagNoseBleedSubHistoryVal",
      "ros_noseDiagNoseBleedSubRuleOutVal",
    ],
  ],

  [
    "ros_noseDiagSeasonAllergySubDesc",
    [
      "ros_noseDiagSeasonAllergySubDescActiveVal",
      "ros_noseDiagSeasonAllergySubDescHistoryVal",
      "ros_noseDiagSeasonAllergySubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSeasonAllergySubSupp",
    [
      "ros_noseDiagSeasonAllergySubSuppHistoryVal",
      "ros_noseDiagSeasonAllergySubSuppSymptomsVal",
      "ros_noseDiagSeasonAllergySubSuppPhysFindVal",
      "ros_noseDiagSeasonAllergySubSuppMedicsVal",
      "ros_noseDiagSeasonAllergySubSuppTestResultsVal",
      "ros_noseDiagSeasonAllergySubSuppImageStudyVal",
      "ros_noseDiagSeasonAllergySubSuppBiopsyVal",
      "ros_noseDiagSeasonAllergySubSuppDmeVal",
      "ros_noseDiagSeasonAllergySubSuppOtherVal",
    ],
  ],

  [
    "ros_noseDiagSinusInfectSubDesc",
    [
      "ros_noseDiagSinusInfectSubDescActiveVal",
      "ros_noseDiagSinusInfectSubDescHistoryVal",
      "ros_noseDiagSinusInfectSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSinusInfectSubSupp",
    [
      "ros_noseDiagSinusInfectSubSuppHistoryVal",
      "ros_noseDiagSinusInfectSubSuppSymptomsVal",
      "ros_noseDiagSinusInfectSubSuppPhysFindVal",
      "ros_noseDiagSinusInfectSubSuppMedicsVal",
      "ros_noseDiagSinusInfectSubSuppTestResultsVal",
      "ros_noseDiagSinusInfectSubSuppImageStudyVal",
      "ros_noseDiagSinusInfectSubSuppBiopsyVal",
      "ros_noseDiagSinusInfectSubSuppDmeVal",
      "ros_noseDiagSinusInfectSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagSinusInfectSubExu",
    [
      "ros_noseDiagSinusInfectSubExuClearVal",
      "ros_noseDiagSinusInfectSubExuPurulentVal",
    ],
  ],

  [
    "ros_noseDiagSinustisSubDesc",
    [
      "ros_noseDiagSinustisSubDescActiveVal",
      "ros_noseDiagSinustisSubDescHistoryVal",
      "ros_noseDiagSinustisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagSinustisSubSupp",
    [
      "ros_noseDiagSinustisSubSuppHistoryVal",
      "ros_noseDiagSinustisSubSuppSymptomsVal",
      "ros_noseDiagSinustisSubSuppPhysFindVal",
      "ros_noseDiagSinustisSubSuppMedicsVal",
      "ros_noseDiagSinustisSubSuppTestResultsVal",
      "ros_noseDiagSinustisSubSuppImageStudyVal",
      "ros_noseDiagSinustisSubSuppBiopsyVal",
      "ros_noseDiagSinustisSubSuppDmeVal",
      "ros_noseDiagSinustisSubSuppOtherVal",
    ],
  ],
  [
    "ros_noseDiagOtherSubDesc",
    [
      "ros_noseDiagOtherSubDescActiveVal",
      "ros_noseDiagOtherSubDescHistoryVal",
      "ros_noseDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_noseDiagOtherSubSupp",
    [
      "ros_noseDiagOtherSubSuppHistoryVal",
      "ros_noseDiagOtherSubSuppSymptomsVal",
      "ros_noseDiagOtherSubSuppPhysFindVal",
      "ros_noseDiagOtherSubSuppMedicsVal",
      "ros_noseDiagOtherSubSuppTestResultsVal",
      "ros_noseDiagOtherSubSuppImageStudyVal",
      "ros_noseDiagOtherSubSuppBiopsyVal",
      "ros_noseDiagOtherSubSuppDmeVal",
      "ros_noseDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Mouth = [
  ["ros_mouthVal", "mouthAndThroat.isMouthAndThroat"],

  [
    "ros_mouthDiagBleedGumsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGums",
  ],
  [
    "ros_mouthDiagBleedGumsSubActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.active",
  ],
  [
    "ros_mouthDiagBleedGumsSubHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.historyOf",
  ],
  [
    "ros_mouthDiagBleedGumsSubRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.ruleOut",
  ],
  [
    "ros_mouthDiagBleedGumsSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.comment",
  ],

  [
    "ros_mouthDiagDiffChewVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewing",
  ],

  [
    "ros_mouthDiagDiffChewSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagDiffChewSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagDiffChewSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagDiffChewSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagDiffChewSubBcsPainVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPain",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.isDentures",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.comment",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.comment",
  ],

  [
    "ros_mouthDiagDiffSwallowVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowing",
  ],

  [
    "ros_mouthDiagDiffSwallowSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagDiffSwallowSubHadStrokeVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStroke",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.eatSpecialDiet",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.comment",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.comment",
  ],

  [
    "ros_mouthDiagOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.other",
  ],
  [
    "ros_mouthDiagOtherSubDescActiveVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_mouthDiagOtherSubDescHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_mouthDiagOtherSubDescRuleOutVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_mouthDiagOtherSubDescCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_mouthDiagOtherSubSuppHistoryVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_mouthDiagOtherSubSuppSymptomsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_mouthDiagOtherSubSuppPhysFindVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_mouthDiagOtherSubSuppMedicsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_mouthDiagOtherSubSuppTestResultsVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_mouthDiagOtherSubSuppImageStudyVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_mouthDiagOtherSubSuppBiopsyVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_mouthDiagOtherSubSuppDmeVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_mouthDiagOtherSubSuppOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_mouthDiagOtherSubSuppOtherSubDescVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_mouthDiagOtherSubSuppCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_mouthDiagOtherSubOtherVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  [
    "ros_mouthDiagCommentVal",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.comment",
  ],
  ["ros_mouthCommentVal", "mouthAndThroat.comment"],
];

export const ros_optionsTreeMapObject_Mouth = {
  ros_mouthDiagBleedGumsVal: "bleedingGums",
  ros_mouthDiagBleedGumsSubActiveVal: "active",
  ros_mouthDiagBleedGumsSubHistoryVal: "historyOf",
  ros_mouthDiagBleedGumsSubRuleOutVal: "ruleOut",

  ros_mouthDiagDiffChewVal: "difficultyChewing",

  ros_mouthDiagDiffChewSubDescActiveVal: "active",
  ros_mouthDiagDiffChewSubDescHistoryVal: "historyOf",
  ros_mouthDiagDiffChewSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagDiffSwallowVal: "difficultySwallowing",

  ros_mouthDiagDiffSwallowSubDescActiveVal: "active",
  ros_mouthDiagDiffSwallowSubDescHistoryVal: "historyOf",
  ros_mouthDiagDiffSwallowSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagOtherVal: "other",

  ros_mouthDiagOtherSubDescActiveVal: "active",
  ros_mouthDiagOtherSubDescHistoryVal: "historyOf",
  ros_mouthDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_mouthDiagOtherSubSuppHistoryVal: "history",
  ros_mouthDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_mouthDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_mouthDiagOtherSubSuppMedicsVal: "medications",
  ros_mouthDiagOtherSubSuppTestResultsVal: "testResults",
  ros_mouthDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_mouthDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_mouthDiagOtherSubSuppDmeVal: "dme",
  ros_mouthDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Mouth = [
  [
    "ros_mouthDiag",
    [
      "ros_mouthDiagBleedGumsVal",
      "ros_mouthDiagDiffChewVal",
      "ros_mouthDiagDiffSwallowVal",
      "ros_mouthDiagOtherVal",
    ],
  ],
  [
    "ros_mouthDiagBleedGumsSub",
    [
      "ros_mouthDiagBleedGumsSubActiveVal",
      "ros_mouthDiagBleedGumsSubHistoryVal",
      "ros_mouthDiagBleedGumsSubRuleOutVal",
    ],
  ],
  [
    "ros_mouthDiagDiffChewSubDesc",
    [
      "ros_mouthDiagDiffChewSubDescActiveVal",
      "ros_mouthDiagDiffChewSubDescHistoryVal",
      "ros_mouthDiagDiffChewSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagDiffSwallowSubDesc",
    [
      "ros_mouthDiagDiffSwallowSubDescActiveVal",
      "ros_mouthDiagDiffSwallowSubDescHistoryVal",
      "ros_mouthDiagDiffSwallowSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagOtherSubDesc",
    [
      "ros_mouthDiagOtherSubDescActiveVal",
      "ros_mouthDiagOtherSubDescHistoryVal",
      "ros_mouthDiagOtherSubDescRuleOutVal",
    ],
  ],

  [
    "ros_mouthDiagOtherSubSupp",
    [
      "ros_mouthDiagOtherSubSuppHistoryVal",
      "ros_mouthDiagOtherSubSuppSymptomsVal",
      "ros_mouthDiagOtherSubSuppPhysFindVal",
      "ros_mouthDiagOtherSubSuppMedicsVal",
      "ros_mouthDiagOtherSubSuppTestResultsVal",
      "ros_mouthDiagOtherSubSuppImageStudyVal",
      "ros_mouthDiagOtherSubSuppBiopsyVal",
      "ros_mouthDiagOtherSubSuppDmeVal",
      "ros_mouthDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_Neck = [
  ["ros_neckVal", "neck.isNeck"],

  ["ros_neckDiagCarotidVal", "neck.isNeckSubAnswer.diagnoses.carotidStenosis"],

  [
    "ros_neckDiagCarotidSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagCarotidSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagCarotidSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagCarotidSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagCarotidSubSuppBruitVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.bruits",
  ],
  [
    "ros_neckDiagCarotidSubSuppTiaHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.historyOfTia",
  ],
  [
    "ros_neckDiagCarotidSubSuppLabStudyVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.laboratoryStudies",
  ],
  [
    "ros_neckDiagCarotidSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagCarotidSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagCarotidSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.supportedBy.comment",
  ],

  [
    "ros_neckDiagCarotidSubCsDescRightVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.right",
  ],
  [
    "ros_neckDiagCarotidSubCsDescLeftVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.left",
  ],
  [
    "ros_neckDiagCarotidSubCsDescBilatVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.bilateral",
  ],
  [
    "ros_neckDiagCarotidSubCsDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.comment",
  ],

  ["ros_neckDiagParotidVal", "neck.isNeckSubAnswer.diagnoses.parotidDisease"],

  [
    "ros_neckDiagParotidSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagParotidSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagParotidSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagParotidSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagParotidSubSuppPhysFindVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neckDiagParotidSubSuppHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.history",
  ],
  [
    "ros_neckDiagParotidSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagParotidSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagParotidSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.supportedBy.comment",
  ],

  ["ros_neckDiagOtherVal", "neck.isNeckSubAnswer.diagnoses.other"],

  [
    "ros_neckDiagOtherSubDescActiveVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_neckDiagOtherSubDescHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_neckDiagOtherSubDescRuleOutVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neckDiagOtherSubDescCommentVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],

  [
    "ros_neckDiagOtherSubSuppHistoryVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_neckDiagOtherSubSuppSymptomsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neckDiagOtherSubSuppPhysFindVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neckDiagOtherSubSuppMedicsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neckDiagOtherSubSuppTestResultsVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neckDiagOtherSubSuppImageStudyVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],

  [
    "ros_neckDiagOtherSubSuppBiopsyVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neckDiagOtherSubSuppDmeVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_neckDiagOtherSubSuppOtherVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_neckDiagOtherSubSuppOtherSubDescVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neckDiagOtherSubSuppCommentVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neckDiagOtherSubOtherVal",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.other",
  ],

  ["ros_neckDiagCommentVal", "neck.isNeckSubAnswer.diagnoses.comment"],
  ["ros_neckCommentVal", "neck.comment"],
];

export const ros_optionsTreeMapObject_Neck = {
  ros_neckDiagCarotidVal: "carotidStenosis",
  ros_neckDiagParotidVal: "parotidDisease",
  ros_neckDiagOtherVal: "other",

  ros_neckDiagCarotidSubDescActiveVal: "active",
  ros_neckDiagCarotidSubDescHistoryVal: "historyOf",
  ros_neckDiagCarotidSubDescRuleOutVal: "ruleOut",

  ros_neckDiagCarotidSubSuppBruitVal: "bruits",
  ros_neckDiagCarotidSubSuppTiaHistoryVal: "historyOfTia",
  ros_neckDiagCarotidSubSuppLabStudyVal: "laboratoryStudies",
  ros_neckDiagCarotidSubSuppOtherVal: "other",

  ros_neckDiagCarotidSubCsDescRightVal: "right",
  ros_neckDiagCarotidSubCsDescLeftVal: "left",
  ros_neckDiagCarotidSubCsDescBilatVal: "bilateral",

  ros_neckDiagParotidSubDescActiveVal: "active",
  ros_neckDiagParotidSubDescHistoryVal: "historyOf",
  ros_neckDiagParotidSubDescRuleOutVal: "ruleOut",

  ros_neckDiagParotidSubSuppPhysFindVal: "physicalFindings",
  ros_neckDiagParotidSubSuppHistoryVal: "history",
  ros_neckDiagParotidSubSuppOtherVal: "other",

  ros_neckDiagOtherSubDescActiveVal: "active",
  ros_neckDiagOtherSubDescHistoryVal: "historyOf",
  ros_neckDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_neckDiagOtherSubSuppHistoryVal: "history",
  ros_neckDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_neckDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_neckDiagOtherSubSuppMedicsVal: "medications",
  ros_neckDiagOtherSubSuppTestResultsVal: "testResults",
  ros_neckDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_neckDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_neckDiagOtherSubSuppDmeVal: "dme",
  ros_neckDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_Neck = [
  [
    "ros_neckDiag",
    [
      "ros_neckDiagCarotidVal",
      "ros_neckDiagParotidVal",
      "ros_neckDiagOtherVal",
    ],
  ],
  [
    "ros_neckDiagCarotidSubDesc",
    [
      "ros_neckDiagCarotidSubDescActiveVal",
      "ros_neckDiagCarotidSubDescHistoryVal",
      "ros_neckDiagCarotidSubDescRuleOutVal",
      "ros_neckDiagCarotidSubDescCommentVal",
    ],
  ],
  [
    "ros_neckDiagCarotidSubSupp",
    [
      "ros_neckDiagCarotidSubSuppBruitVal",
      "ros_neckDiagCarotidSubSuppTiaHistoryVal",
      "ros_neckDiagCarotidSubSuppLabStudyVal",
      "ros_neckDiagCarotidSubSuppOtherVal",
    ],
  ],
  [
    "ros_neckDiagCarotidSubCsDesc",
    [
      "ros_neckDiagCarotidSubCsDescRightVal",
      "ros_neckDiagCarotidSubCsDescLeftVal",
      "ros_neckDiagCarotidSubCsDescBilatVal",
    ],
  ],
  [
    "ros_neckDiagParotidSubDesc",
    [
      "ros_neckDiagParotidSubDescActiveVal",
      "ros_neckDiagParotidSubDescHistoryVal",
      "ros_neckDiagParotidSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neckDiagParotidSubSupp",
    [
      "ros_neckDiagParotidSubSuppPhysFindVal",
      "ros_neckDiagParotidSubSuppHistoryVal",
      "ros_neckDiagParotidSubSuppOtherVal",
    ],
  ],
  [
    "ros_neckDiagOtherSubDesc",
    [
      "ros_neckDiagOtherSubDescActiveVal",
      "ros_neckDiagOtherSubDescHistoryVal",
      "ros_neckDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neckDiagOtherSubSupp",
    [
      "ros_neckDiagOtherSubSuppHistoryVal",
      "ros_neckDiagOtherSubSuppSymptomsVal",
      "ros_neckDiagOtherSubSuppPhysFindVal",
      "ros_neckDiagOtherSubSuppMedicsVal",
      "ros_neckDiagOtherSubSuppTestResultsVal",
      "ros_neckDiagOtherSubSuppImageStudyVal",
      "ros_neckDiagOtherSubSuppBiopsyVal",
      "ros_neckDiagOtherSubSuppDmeVal",
      "ros_neckDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_eyeENTNeckRecommendations = [
  [
    "ros_eyeENTNeckRecommendationshearingEvaluationVal",
    "recommendations.recommendationsSubAnswers[0].hearingEvaluation",
  ],
  [
    "ros_eyeENTNeckRecommendationsdentalExamVal",
    "recommendations.recommendationsSubAnswers[1].dentalExam",
  ],
  [
    "ros_eyeENTNeckRecommendationseyeExamVal",
    "recommendations.recommendationsSubAnswers[2].eyeExam",
  ],
  [
    "ros_eyeENTNeckRecommendationsswallowingEvaluationVal",
    "recommendations.recommendationsSubAnswers[3].swallowingEvaluation",
  ],
  [
    "ros_eyeENTNeckRecommendationstakeMedicationsVal",
    "recommendations.recommendationsSubAnswers[4].takeMedications",
  ],
  [
    "ros_eyeENTNeckRecommendationsotherVal",
    "recommendations.recommendationsSubAnswers[5].other",
  ],

  [
    "ros_eyeENTNeckRecommendationshearingEvaluationCommentVal",
    "recommendations.recommendationsSubAnswers[0].comment",
  ],
  [
    "ros_eyeENTNeckRecommendationsdentalExamCommentVal",
    "recommendations.recommendationsSubAnswers[1].comment",
  ],
  [
    "ros_eyeENTNeckRecommendationseyeExamCommentVal",
    "recommendations.recommendationsSubAnswers[2].comment",
  ],
  [
    "ros_eyeENTNeckRecommendationsswallowingEvaluationCommentVal",
    "recommendations.recommendationsSubAnswers[3].comment",
  ],
  [
    "ros_eyeENTNeckRecommendationstakeMedicationsCommentVal",
    "recommendations.recommendationsSubAnswers[4].comment",
  ],
  // [
  //   "ros_eyeENTRecoFoodDispCommentVal",
  //   "recommendations.recommendationsSubAnswers[5].comment",
  // ],
  [
    "ros_eyeENTNeckRecommendationsotherCommentVal",
    "recommendations.recommendationsSubAnswers[5].comment",
  ],
];

export const ros_hashmap_BowelMovement = [
  ["ros_bowelMoveNormalVal", "bowelMovements.normal"],
  ["ros_bowelMoveAbnormVal", "bowelMovements.abnormal"],
  ["ros_bowelMoveCommentVal", "bowelMovements.comment"],
  [
    "ros_bowelMoveAbnormSubConstVal",
    "bowelMovements.abnormalSubAnswer.constipation",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrVal",
    "bowelMovements.abnormalSubAnswer.diarrhea",
  ],
  [
    "ros_bowelMoveAbnormSubBowelVal",
    "bowelMovements.abnormalSubAnswer.bowelIncontinence",
  ],
  [
    "ros_bowelMoveAbnormSubCommentVal",
    "bowelMovements.abnormalSubAnswer.comment",
  ],
  [
    "ros_bowelMoveAbnormSubConstAcuteVal",
    "bowelMovements.abnormalSubAnswer.constipationSubAnswer.acute",
  ],
  [
    "ros_bowelMoveAbnormSubConstChronVal",
    "bowelMovements.abnormalSubAnswer.constipationSubAnswer.chronic",
  ],
  [
    "ros_bowelMoveAbnormSubConstSubIfConstCommentVal",
    "bowelMovements.abnormalSubAnswer.constipationSubAnswer.comment",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrIsdiaAcuteVal",
    "bowelMovements.abnormalSubAnswer.diarrheaSubAnswer.isDiarrhea.acute",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrIsdiaChronVal",
    "bowelMovements.abnormalSubAnswer.diarrheaSubAnswer.isDiarrhea.chronic",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrSubCommentVal",
    "bowelMovements.abnormalSubAnswer.diarrheaSubAnswer.isDiarrhea.comment",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrSubIshisVal",
    "bowelMovements.abnormalSubAnswer.diarrheaSubAnswer.isHistoryOfCDifficile.isHistoryOfCDifficile",
  ],
  [
    "ros_bowelMoveAbnormSubDiarrSubIshisCommentVal",
    "bowelMovements.abnormalSubAnswer.diarrheaSubAnswer.isHistoryOfCDifficile.comment",
  ],
];

export const ros_optionsTreeMapObject_BowelMovement = {
  ros_bowelMoveNormalVal: "normal",
  ros_bowelMoveAbnormVal: "abnormal",

  ros_bowelMoveAbnormSubConstVal: "constipation",
  ros_bowelMoveAbnormSubDiarrVal: "diarrhea",
  ros_bowelMoveAbnormSubBowelVal: "bowelIncontinence",

  ros_bowelMoveAbnormSubConstAcuteVal: "acute",
  ros_bowelMoveAbnormSubConstChronVal: "chronic",

  ros_bowelMoveAbnormSubDiarrIsdiaAcuteVal: "acute",
  ros_bowelMoveAbnormSubDiarrIsdiaChronVal: "chronic",
};

export const ros_OptionsTreeWrappers_BowelMovement = [
  ["ros_bowelMove", ["ros_bowelMoveNormalVal", "ros_bowelMoveAbnormVal"]],
  [
    "ros_bowelMoveAbnormSub",
    [
      "ros_bowelMoveAbnormSubConstVal",
      "ros_bowelMoveAbnormSubDiarrVal",
      "ros_bowelMoveAbnormSubBowelVal",
    ],
  ],
  [
    "ros_bowelMoveAbnormSubConstSubIfConst",
    [
      "ros_bowelMoveAbnormSubConstAcuteVal",
      "ros_bowelMoveAbnormSubConstChronVal",
    ],
  ],
  [
    "ros_bowelMoveAbnormSubDiarrSub",
    [
      "ros_bowelMoveAbnormSubDiarrIsdiaAcuteVal",
      "ros_bowelMoveAbnormSubDiarrIsdiaChronVal",
    ],
  ],
];

export const ros_hashmap_AbdominalOpening = [
  ["ros_abdominalVal", "abdominalOpenings.isAbdominalOpenings"],
  [
    "ros_abdominalIsyesIleostoVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.ileostomy",
  ],
  [
    "ros_abdominalIsyesColostoVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.colostomy",
  ],
  [
    "ros_abdominalIsyesUrostomVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.urostomy",
  ],
  [
    "ros_abdominalIsyesPegVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.peg",
  ],
  [
    "ros_abdominalIsyesCrytostVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.crytostomy",
  ],
  [
    "ros_abdominalIsyesCommentVal",
    "abdominalOpenings.isYesAbdominalOpeningsSubAnswer.comment",
  ],
  ["ros_abdominalCommentVal", "abdominalOpenings.comment"],
];

export const ros_optionsTreeMapObject_AbdominalOpening = {
  ros_abdominalIsyesIleostoVal: "ileostomy",
  ros_abdominalIsyesColostoVal: "colostomy",
  ros_abdominalIsyesUrostomVal: "urostomy",
  ros_abdominalIsyesPegVal: "peg",
  ros_abdominalIsyesCrytostVal: "crytostomy",
};

export const ros_OptionsTreeWrappers_AbdominalOpening = [
  [
    "ros_abdominalIsyes",
    [
      "ros_abdominalIsyesIleostoVal",
      "ros_abdominalIsyesColostoVal",
      "ros_abdominalIsyesUrostomVal",
      "ros_abdominalIsyesPegVal",
      "ros_abdominalIsyesCrytostVal",
    ],
  ],
];

export const ros_hashmap_rectalProblems = [
  ["ros_rectalVal", "rectalProblems.isRectalProblems"],
  [
    "ros_rectalIsrecIsyesfemHemorVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.hemorrhoids",
  ],
  [
    "ros_rectalIsrecIsyesfemFissuVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.fissure",
  ],
  [
    "ros_rectalIsrecIsyesfemMassVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.mass",
  ],
  [
    "ros_rectalIsrecIsyesmalHemorVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.hemorrhoids",
  ],
  [
    "ros_rectalIsrecIsyesmalFissuVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.fissure",
  ],
  [
    "ros_rectalIsrecIsyesmalMassVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.mass",
  ],
  [
    "ros_rectalIsrecIsyesmalBphVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.bph",
  ],
  [
    "ros_rectalIsrecIsyesmalProstVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.prostateMass",
  ],
  [
    "ros_rectalIsrecIsyesmalCommentVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.comment",
  ],
  [
    "ros_rectalIsrecIsyesfemCommentVal",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.comment",
  ],
  ["ros_rectalCommentVal", "rectalProblems.comment"],
];

export const ros_optionsTreeMapObject_rectalProblems = {
  ros_rectalIsrecIsyesfemHemorVal: "hemorrhoids",
  ros_rectalIsrecIsyesfemFissuVal: "fissure",
  ros_rectalIsrecIsyesfemMassVal: "mass",

  ros_rectalIsrecIsyesmalHemorVal: "hemorrhoids",
  ros_rectalIsrecIsyesmalFissuVal: "fissure",
  ros_rectalIsrecIsyesmalMassVal: "mass",
  ros_rectalIsrecIsyesmalBphVal: "bph",
  ros_rectalIsrecIsyesmalProstVal: "prostateMass",
};

export const ros_OptionsTreeWrappers_rectalProblems = [
  [
    "ros_rectalIsrecIsyesfem",
    [
      "ros_rectalIsrecIsyesfemHemorVal",
      "ros_rectalIsrecIsyesfemFissuVal",
      "ros_rectalIsrecIsyesfemMassVal",
    ],
  ],
  [
    "ros_rectalIsrecIsyesmal",
    [
      "ros_rectalIsrecIsyesmalHemorVal",
      "ros_rectalIsrecIsyesmalFissuVal",
      "ros_rectalIsrecIsyesmalMassVal",
      "ros_rectalIsrecIsyesmalBphVal",
      "ros_rectalIsrecIsyesmalProstVal",
    ],
  ],
];

export const ros_hashmap_lastBowel = [
  ["ros_lastBowelTodayVal", "lastBowelMovement.today"],
  ["ros_lastBowelOnetoVal", "lastBowelMovement.oneToThreeDaysAgo"],
  ["ros_lastBowelGreatVal", "lastBowelMovement.greaterThanThreeDaysAgo"],
  ["ros_lastBowelCommentVal", "lastBowelMovement.comment"],
];

export const ros_optionsTreeMapObject_lastBowel = {
  ros_lastBowelTodayVal: "today",
  ros_lastBowelOnetoVal: "oneToThreeDaysAgo",
  ros_lastBowelGreatVal: "greaterThanThreeDaysAgo",
};

export const ros_OptionsTreeWrappers_lastBowel = [
  [
    "ros_lastBowel",
    ["ros_lastBowelTodayVal", "ros_lastBowelOnetoVal", "ros_lastBowelGreatVal"],
  ],
];

export const ros_hashmap_meetFriends = [
  ["ros_meetWithOftenVal", "meetWithFamilyOrFriends.often"],
  ["ros_meetWithSometimeVal", "meetWithFamilyOrFriends.sometimes"],
  ["ros_meetWithNeverVal", "meetWithFamilyOrFriends.never"],
  ["ros_meetWithCommentVal", "meetWithFamilyOrFriends.comment"],
];

export const ros_optionsTreeMapObject_meetFriends = {
  ros_meetWithOftenVal: "often",
  ros_meetWithSometimeVal: "sometimes",
  ros_meetWithNeverVal: "never",
};

export const ros_OptionsTreeWrappers_meetFriends = [
  [
    "ros_meetWith",
    ["ros_meetWithOftenVal", "ros_meetWithSometimeVal", "ros_meetWithNeverVal"],
  ],
];

// no options tree in this section
export const ros_hashmap_gpCog = [
  ["ros_gpcogScoIsgpcogsVal", "gpcogScoreOrMmseScore.isGpcogScore"],
  ["ros_gpcogScoIsmmsescVal", "gpcogScoreOrMmseScore.isMmseScore"],
  ["ros_gpcogScoCommentVal", "gpcogScoreOrMmseScore.comment"],
];

export const ros_hashmap_gpCogNoScore = [
  [
    "ros_noGpcogMmsePatientOrientPersonVal",
    "gpcogOrMmseNotDone.patientOrientedToPerson.patientOrientedToPerson",
  ],
  [
    "ros_noGpcogMmsePatientOrientPlaceVal",
    "gpcogOrMmseNotDone.patientOrientedToPlace.patientOrientedToPerson",
  ],
  [
    "ros_noGpcogMmsePatientOrientTimeVal",
    "gpcogOrMmseNotDone.patientOrientedToTime.patientOrientedToPerson",
  ],
  ["ros_noGpcogMmseRecallGoodVal", "gpcogOrMmseNotDone.recall.good"],
  ["ros_noGpcogMmseRecallPoorVal", "gpcogOrMmseNotDone.recall.poor"],
  [
    "ros_noGpcogMmsePatientDescribeNewsVal",
    "gpcogOrMmseNotDone.patientDescribesRecentNews.patientDescribesRecentNews",
  ],
  ["ros_noGpcogMmseRecallCommentVal", "gpcogOrMmseNotDone.recall.comment"],
  [
    "ros_noGpcogMmsePatientdCommentVal",
    "gpcogOrMmseNotDone.patientDescribesRecentNews.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientPersonCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToPerson.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientPlaceCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToPlace.comment",
  ],
  [
    "ros_noGpcogMmsePatientOrientTimeCommentVal",
    "gpcogOrMmseNotDone.patientOrientedToTime.comment",
  ],
  [
    "ros_noGpcogMmsePatientDescribeNewsCommentVal",
    "gpcogOrMmseNotDone.patientDescribesRecentNews.comment",
  ],
];

export const ros_optionsTreeMapObject_gpCogNoScore = {
  ros_noGpcogMmseRecallGoodVal: "good",
  ros_noGpcogMmseRecallPoorVal: "poor",
};

export const ros_OptionsTreeWrappers_gpCogNoScore = [
  [
    "ros_noGpcogMmseRecall",
    ["ros_noGpcogMmseRecallGoodVal", "ros_noGpcogMmseRecallPoorVal"],
  ],
];

export const ros_hashmap_speech = [
  ["ros_speechNormalVal", "speech.normal"],
  ["ros_speechSlurredVal", "speech.slurred"],
  ["ros_speechAphasicVal", "speech.aphasic"],
  ["ros_speechApraxiaVal", "speech.apraxia"],
  ["ros_speechCommentVal", "speech.comment"],
];

export const ros_optionsTreeMapObject_speech = {
  ros_speechNormalVal: "normal",
  ros_speechSlurredVal: "slurred",
  ros_speechAphasicVal: "aphasic",
  ros_speechApraxiaVal: "apraxia",
};

export const ros_OptionsTreeWrappers_speech = [
  [
    "ros_speech",
    [
      "ros_speechNormalVal",
      "ros_speechSlurredVal",
      "ros_speechAphasicVal",
      "ros_speechApraxiaVal",
    ],
  ],
];

export const ros_hashmap_sitToStand = [
  ["ros_sitToStandNormalVal", "sittingToStanding.normal"],
  ["ros_sitToStandNeedAssistVal", "sittingToStanding.needsAssistance"],
  ["ros_sitToStandUnableVal", "sittingToStanding.unable"],
  ["ros_sitToStandCommentVal", "sittingToStanding.comment"],
];

export const ros_optionsTreeMapObject_sitToStand = {
  ros_sitToStandNormalVal: "normal",
  ros_sitToStandNeedAssistVal: "needsAssistance",
  ros_sitToStandUnableVal: "unable",
};

export const ros_OptionsTreeWrappers_sitToStand = [
  [
    "ros_sitToStand",
    [
      "ros_sitToStandNormalVal",
      "ros_sitToStandNeedAssistVal",
      "ros_sitToStandUnableVal",
    ],
  ],
];

export const ros_hashmap_fingerToNose = [
  ["ros_fingerToNoseNormalVal", "fingerToNose.normal"],
  ["ros_fingerToNoseAbnormalVal", "fingerToNose.abnormal"],
  ["ros_fingerToNoseAbnormalSubLeftVal", "fingerToNose.abnormalSubAnswer.left"],
  [
    "ros_fingerToNoseAbnormalSubRightVal",
    "fingerToNose.abnormalSubAnswer.right",
  ],
  ["ros_fingerToNoseAbnormalBothVal", "fingerToNose.abnormalSubAnswer.both"],
  [
    "ros_fingerToNoseAbnormalSubCommentVal",
    "fingerToNose.abnormalSubAnswer.comment",
  ],
  ["ros_fingerToNoseCommentVal", "fingerToNose.comment"],
];

export const ros_optionsTreeMapObject_fingerToNose = {
  ros_fingerToNoseNormalVal: "normal",
  ros_fingerToNoseAbnormalVal: "abnormal",

  ros_fingerToNoseAbnormalSubLeftVal: "left",
  ros_fingerToNoseAbnormalSubRightVal: "right",
  ros_fingerToNoseAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_fingerToNose = [
  [
    "ros_fingerToNose",
    ["ros_fingerToNoseNormalVal", "ros_fingerToNoseAbnormalVal"],
  ],
  [
    "ros_fingerToNoseAbnormalSub",
    [
      "ros_fingerToNoseAbnormalSubLeftVal",
      "ros_fingerToNoseAbnormalSubRightVal",
      "ros_fingerToNoseAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_heelToToe = [
  ["ros_heelToToeNormalVal", "heelToToe.normal"],
  ["ros_heelToToeAbnormalVal", "heelToToe.abnormal"],
  ["ros_heelToToeAbnormalSubLeftVal", "heelToToe.abnormalSubAnswer.left"],
  ["ros_heelToToeAbnormalSubRightVal", "heelToToe.abnormalSubAnswer.right"],
  ["ros_heelToToeAbnormalBothVal", "heelToToe.abnormalSubAnswer.both"],
  ["ros_heelToToeAbnormalSubCommentVal", "heelToToe.abnormalSubAnswer.comment"],
  ["ros_heelToToeCommentVal", "heelToToe.comment"],
];

export const ros_optionsTreeMapObject_heelToToe = {
  ros_heelToToeNormalVal: "normal",
  ros_heelToToeAbnormalVal: "abnormal",

  ros_heelToToeAbnormalSubLeftVal: "left",
  ros_heelToToeAbnormalSubRightVal: "right",
  ros_heelToToeAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_heelToToe = [
  ["ros_heelToToe", ["ros_heelToToeNormalVal", "ros_heelToToeAbnormalVal"]],
  [
    "ros_heelToToeAbnormalSub",
    [
      "ros_heelToToeAbnormalSubLeftVal",
      "ros_heelToToeAbnormalSubRightVal",
      "ros_heelToToeAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_thumbToFinger = [
  ["ros_thumbToFingerNormalVal", "thumbToFingerTips.normal"],
  ["ros_thumbToFingerAbnormalVal", "thumbToFingerTips.abnormal"],
  [
    "ros_thumbToFingerAbnormalSubLeftVal",
    "thumbToFingerTips.abnormalSubAnswer.left",
  ],
  [
    "ros_thumbToFingerAbnormalSubRightVal",
    "thumbToFingerTips.abnormalSubAnswer.right",
  ],
  [
    "ros_thumbToFingerAbnormalBothVal",
    "thumbToFingerTips.abnormalSubAnswer.both",
  ],
  [
    "ros_thumbToFingerAbnormalSubCommentVal",
    "thumbToFingerTips.abnormalSubAnswer.comment",
  ],
  ["ros_thumbToFingerCommentVal", "thumbToFingerTips.comment"],
];

export const ros_optionsTreeMapObject_thumbToFinger = {
  ros_thumbToFingerNormalVal: "normal",
  ros_thumbToFingerAbnormalVal: "abnormal",

  ros_thumbToFingerAbnormalSubLeftVal: "left",
  ros_thumbToFingerAbnormalSubRightVal: "right",
  ros_thumbToFingerAbnormalBothVal: "both",
};

export const ros_OptionsTreeWrappers_thumbToFinger = [
  [
    "ros_thumbToFinger",
    ["ros_thumbToFingerNormalVal", "ros_thumbToFingerAbnormalVal"],
  ],
  [
    "ros_thumbToFingerAbnormalSub",
    [
      "ros_thumbToFingerAbnormalSubLeftVal",
      "ros_thumbToFingerAbnormalSubRightVal",
      "ros_thumbToFingerAbnormalBothVal",
    ],
  ],
];

export const ros_hashmap_extremeMove = [
  ["ros_extremeMoveMotorTicVal", "extremityMovement.motorTic"],
  ["ros_extremeMoveVocalTicVal", "extremityMovement.vocalTic"],
  ["ros_extremeMoveBenignVal", "extremityMovement.benign"],
  ["ros_extremeMoveIntenTremorVal", "extremityMovement.intentionTremor"],
  ["ros_extremeMoveNonIntentionVal", "extremityMovement.nonIntention"],
  ["ros_extremeMoveRigidityVal", "extremityMovement.rigidity"],
  ["ros_extremeMoveSpasticityVal", "extremityMovement.spasticity"],
  ["ros_extremeMoveChoreaMoveVal", "extremityMovement.choreaMovement"],
  ["ros_extremeMoveCogWheelingVal", "extremityMovement.cogWheeling"],
  ["ros_extremeMoveNormalVal", "extremityMovement.normal"],

  ["ros_extremeMoveCommentVal", "extremityMovement.comment"],
];

export const ros_optionsTreeMapObject_extremeMove = {
  ros_extremeMoveMotorTicVal: "motorTic",
  ros_extremeMoveVocalTicVal: "vocalTic",
  ros_extremeMoveBenignVal: "benign",
  ros_extremeMoveIntenTremorVal: "intentionTremor",
  ros_extremeMoveNonIntentionVal: "nonIntention",
  ros_extremeMoveRigidityVal: "rigidity",
  ros_extremeMoveSpasticityVal: "spasticity",
  ros_extremeMoveChoreaMoveVal: "choreaMovement",
  ros_extremeMoveCogWheelingVal: "cogWheeling",
  ros_extremeMoveNormalVal: "normal",
};

export const ros_OptionsTreeWrappers_extremeMove = [
  [
    "ros_extremeMove",
    [
      "ros_extremeMoveMotorTicVal",
      "ros_extremeMoveVocalTicVal",
      "ros_extremeMoveBenignVal",
      "ros_extremeMoveIntenTremorVal",
      "ros_extremeMoveNonIntentionVal",
      "ros_extremeMoveRigidityVal",
      "ros_extremeMoveSpasticityVal",
      "ros_extremeMoveChoreaMoveVal",
      "ros_extremeMoveCogWheelingVal",
      "ros_extremeMoveNormalVal",
    ],
  ],
];

export const ros_hashmap_affect = [
  ["ros_affectNormalVal", "affect.normal"],
  ["ros_affectAbnormalVal", "affect.abnormal"],

  ["ros_affectAbnormalSubDescParanoiaVal", "affect.abnormalSubAnswer.paranoia"],
  [
    "ros_affectAbnormalSubDescDelusionalVal",
    "affect.abnormalSubAnswer.delusional",
  ],
  [
    "ros_affectAbnormalSubDescDisOrgThoughtVal",
    "affect.abnormalSubAnswer.disorganizedThought",
  ],
  ["ros_affectAbnormalSubDescFlatVal", "affect.abnormalSubAnswer.flat"],
  ["ros_affectAbnormalSubDescManicVal", "affect.abnormalSubAnswer.manic"],
  [
    "ros_affectAbnormalSubDescDepressedVal",
    "affect.abnormalSubAnswer.depressed",
  ],
  ["ros_affectAbnormalSubDescOtherVal", "affect.abnormalSubAnswer.other"],

  ["ros_affectAbnormalSubDescCommentVal", "affect.abnormalSubAnswer.comment"],
  ["ros_affectCommentVal", "affect.comment"],
];

export const ros_optionsTreeMapObject_affect = {
  ros_affectNormalVal: "normal",
  ros_affectAbnormalVal: "abnormal",

  ros_affectAbnormalSubDescParanoiaVal: "paranoia",
  ros_affectAbnormalSubDescDelusionalVal: "delusional",
  ros_affectAbnormalSubDescDisOrgThoughtVal: "disorganizedThought",
  ros_affectAbnormalSubDescFlatVal: "flat",
  ros_affectAbnormalSubDescManicVal: "manic",
  ros_affectAbnormalSubDescDepressedVal: "depressed",
  ros_affectAbnormalSubDescOtherVal: "other",
};

export const ros_OptionsTreeWrappers_affect = [
  ["ros_affect", ["ros_affectNormalVal", "ros_affectAbnormalVal"]],
  [
    "ros_affectAbnormalSubDesc",
    [
      "ros_affectAbnormalSubDescParanoiaVal",
      "ros_affectAbnormalSubDescDelusionalVal",
      "ros_affectAbnormalSubDescDisOrgThoughtVal",
      "ros_affectAbnormalSubDescFlatVal",
      "ros_affectAbnormalSubDescManicVal",
      "ros_affectAbnormalSubDescDepressedVal",
      "ros_affectAbnormalSubDescOtherVal",
    ],
  ],
];

export const ros_hashmap_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrstNotAtAllVal",
    "overPastTwoWeeks.littleInterestOrPleasure.notAtAll",
  ],
  [
    "ros_over2WeeksLittleIntrstSeveralDaysVal",
    "overPastTwoWeeks.littleInterestOrPleasure.severalDays",
  ],
  [
    "ros_over2WeeksLittleIntrstMoreHalfDaysVal",
    "overPastTwoWeeks.littleInterestOrPleasure.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksLittleIntrstNearlyEveryDayVal",
    "overPastTwoWeeks.littleInterestOrPleasure.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksLittleIntrstCommentVal",
    "overPastTwoWeeks.littleInterestOrPleasure.comment",
  ],

  [
    "ros_over2WeeksFeelDepressedNotAtAllVal",
    "overPastTwoWeeks.feelingDownDepressed.notAtAll",
  ],
  [
    "ros_over2WeeksFeelDepressedSeveralDaysVal",
    "overPastTwoWeeks.feelingDownDepressed.severalDays",
  ],
  [
    "ros_over2WeeksFeelDepressedMoreHalfDaysVal",
    "overPastTwoWeeks.feelingDownDepressed.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksFeelDepressedNearlyEveryDayVal",
    "overPastTwoWeeks.feelingDownDepressed.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksFeelDepressedCommentVal",
    "overPastTwoWeeks.feelingDownDepressed.comment",
  ],

  [
    "ros_over2WeeksPhq2LessThreeVal",
    "overPastTwoWeeks.phq2score.lessThanThree",
  ],
  ["ros_over2WeeksPhq2MoreThreeVal", "overPastTwoWeeks.phq2score.threeOrMore"],
  ["ros_over2WeeksPhq2ScoreVal", "overPastTwoWeeks.phq2score.phq2score"],
  ["ros_over2WeeksPhq9ScoreVal", "overPastTwoWeeks.phq2score.phq9score"],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.notAtAll",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.several",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.moreThanHalfTheDays",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.nearlyEveryDay",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.comment",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.minimalDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.mildDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.moderateDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.moderatelySevereDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.SevereDepression",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal",
    "overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.depressionSeverity.comment",
  ],
];

export const ros_optionsTreeMapObject_over2Weeks = {
  ros_over2WeeksLittleIntrstNotAtAllVal: "notAtAll",
  ros_over2WeeksLittleIntrstSeveralDaysVal: "severalDays",
  ros_over2WeeksLittleIntrstMoreHalfDaysVal: "moreThanHalfTheDays",
  ros_over2WeeksLittleIntrstNearlyEveryDayVal: "nearlyEveryDay",

  ros_over2WeeksFeelDepressedNotAtAllVal: "notAtAll",
  ros_over2WeeksFeelDepressedSeveralDaysVal: "severalDays",
  ros_over2WeeksFeelDepressedMoreHalfDaysVal: "moreThanHalfTheDays",
  ros_over2WeeksFeelDepressedNearlyEveryDayVal: "nearlyEveryDay",

  ros_over2WeeksPhqLessThreeVal: "lessThanThree",
  ros_over2WeeksPhq2MoreThreeVal: "threeOrMore",

  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal:
    "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal: "notAtAll",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal: "several",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal:
    "moreThanHalfTheDays",
  ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal:
    "nearlyEveryDay",

  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal:
    "minimalDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal:
    "mildDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal:
    "moderateDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal:
    "moderatelySevereDepression",
  ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal:
    "SevereDepression",
};

export const ros_OptionsTreeWrappers_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrst",
    [
      "ros_over2WeeksLittleIntrstNotAtAllVal",
      "ros_over2WeeksLittleIntrstSeveralDaysVal",
      "ros_over2WeeksLittleIntrstMoreHalfDaysVal",
      "ros_over2WeeksLittleIntrstNearlyEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksFeelDepressed",
    [
      "ros_over2WeeksFeelDepressedNotAtAllVal",
      "ros_over2WeeksFeelDepressedSeveralDaysVal",
      "ros_over2WeeksFeelDepressedMoreHalfDaysVal",
      "ros_over2WeeksFeelDepressedNearlyEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq",
    ["ros_over2WeeksPhqLessThreeVal", "ros_over2WeeksPhq2MoreThreeVal"],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAllVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrlVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDayVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDayVal",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.episodic",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.continuous",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.remission",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.drinkingHistory",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.hospitalizations",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.labResults",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDeliriumVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfDelirium.isHistoryOfDelirium",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfDelirium.comment",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubPsychVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfPsychosis.isHistoryOfPsychosis",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubPsychCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.historyOfPsychosis.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAlcohol = {
  ros_neuroPsychSubDiagAlcoholSubDescActiveVal: "active",
  ros_neuroPsychSubDiagAlcoholSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal: "episodic",
  ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal: "continuous",
  ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal: "remission",
  ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal: "drinkingHistory",
  ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal: "hospitalizations",
  ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal: "physicalFindings",
  ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal: "labResults",
  ros_neuroPsychSubDiagAlcoholSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDesc",
    [
      "ros_neuroPsychSubDiagAlcoholSubDescActiveVal",
      "ros_neuroPsychSubDiagAlcoholSubDescHistoryVal",
      "ros_neuroPsychSubDiagAlcoholSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubType",
    [
      "ros_neuroPsychSubDiagAlcoholSubTypeEpisodicVal",
      "ros_neuroPsychSubDiagAlcoholSubTypeContinuousVal",
      "ros_neuroPsychSubDiagAlcoholSubTypeRemissionVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSupp",
    [
      "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHistVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppHospitalVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppPhysicFindVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppLabResultVal",
      "ros_neuroPsychSubDiagAlcoholSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.progressiveWeekness",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.slurringOfSpeech",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.abnormalGait",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.difficultySwallowing",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAmyotrop = {
  ros_neuroPsychSubDiagAmyotropSubDescActiveVal: "active",
  ros_neuroPsychSubDiagAmyotropSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAmyotropSubDescCommentVal: "comment",
  ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal: "progressiveWeekness",
  ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal: "slurringOfSpeech",
  ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal: "abnormalGait",
  ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal: "difficultySwallowing",
  ros_neuroPsychSubDiagAmyotropSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagAmyotropSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDesc",
    [
      "ros_neuroPsychSubDiagAmyotropSubDescActiveVal",
      "ros_neuroPsychSubDiagAmyotropSubDescHistoryVal",
      "ros_neuroPsychSubDiagAmyotropSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubSupp",
    [
      "ros_neuroPsychSubDiagAmyotropSubSuppProgWeakVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppSpeechSlurVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppAbnormalGaitVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppDiffSwallowVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppEmgVal",
      "ros_neuroPsychSubDiagAmyotropSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.generalizedAnxietyDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.panicDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOCDVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.obsessiveCompulsiveDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypePTSDVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.postTraumaticStressDisorder",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.socialPhobia",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.other",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppSymptomVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.gadSeven",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.antianxietyMedication",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagAnxiety = {
  ros_neuroPsychSubDiagAnxietySubDescActiveVal: "active",
  ros_neuroPsychSubDiagAnxietySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagAnxietySubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal:
    "generalizedAnxietyDisorder",
  ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal: "panicDisorder",
  ros_neuroPsychSubDiagAnxietySubTypeOCDVal: "obsessiveCompulsiveDisorder",
  ros_neuroPsychSubDiagAnxietySubTypePTSDVal: "postTraumaticStressDisorder",
  ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal: "socialPhobia",
  ros_neuroPsychSubDiagAnxietySubTypeOtherVal: "other",
  ros_neuroPsychSubDiagAnxietySubSuppSymptomVal: "symptoms",
  ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal: "gadSeven",
  ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal: "antianxietyMedication",
  ros_neuroPsychSubDiagAnxietySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDesc",
    [
      "ros_neuroPsychSubDiagAnxietySubDescActiveVal",
      "ros_neuroPsychSubDiagAnxietySubDescHistoryVal",
      "ros_neuroPsychSubDiagAnxietySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubType",
    [
      "ros_neuroPsychSubDiagAnxietySubTypeGenAnxietyVal",
      "ros_neuroPsychSubDiagAnxietySubTypePanicDisorderVal",
      "ros_neuroPsychSubDiagAnxietySubTypeOCDVal",
      "ros_neuroPsychSubDiagAnxietySubTypePTSDVal",
      "ros_neuroPsychSubDiagAnxietySubTypeSocialPhobiaVal",
      "ros_neuroPsychSubDiagAnxietySubTypeOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubSupp",
    [
      "ros_neuroPsychSubDiagAnxietySubSuppSymptomVal",
      "ros_neuroPsychSubDiagAnxietySubSuppGadSevenVal",
      "ros_neuroPsychSubDiagAnxietySubSuppAntiAnxiMedicVal",
      "ros_neuroPsychSubDiagAnxietySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.manicDepression",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.bipolarDepression",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeMixedVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.mixed",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeUnknownVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.unknown",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.historyOfMoodSwings",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagBipolar = {
  ros_neuroPsychSubDiagBipolarSubDescActiveVal: "active",
  ros_neuroPsychSubDiagBipolarSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagBipolarSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal: "manicDepression",
  ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal: "bipolarDepression",
  ros_neuroPsychSubDiagBipolarSubTypeMixedVal: "mixed",
  ros_neuroPsychSubDiagBipolarSubTypeUnknownVal: "unknown",
  ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal: "historyOfMoodSwings",
  ros_neuroPsychSubDiagBipolarSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagBipolarSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDesc",
    [
      "ros_neuroPsychSubDiagBipolarSubDescActiveVal",
      "ros_neuroPsychSubDiagBipolarSubDescHistoryVal",
      "ros_neuroPsychSubDiagBipolarSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubType",
    [
      "ros_neuroPsychSubDiagBipolarSubTypeManicDepressVal",
      "ros_neuroPsychSubDiagBipolarSubTypeBipolarDepressVal",
      "ros_neuroPsychSubDiagBipolarSubTypeMixedVal",
      "ros_neuroPsychSubDiagBipolarSubTypeUnknownVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubSupp",
    [
      "ros_neuroPsychSubDiagBipolarSubSuppMoodSwingVal",
      "ros_neuroPsychSubDiagBipolarSubSuppMedicVal",
      "ros_neuroPsychSubDiagBipolarSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.coma",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.isPhysicalFindingsSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.paresthesias",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.sendoryFindingsSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagCerebHemm = {
  ros_neuroPsychSubDiagCerebHemmSubDescActiveVal: "active",
  ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal:
    "rightHemiparesis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal:
    "leftHemiparesis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal: "paraplegia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal:
    "quadriplegia",
  ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal: "coma",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal:
    "facialNumbness",
  ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal: "paresthesias",
  ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmSubDesc",
    [
      "ros_neuroPsychSubDiagCerebHemmSubDescActiveVal",
      "ros_neuroPsychSubDiagCerebHemmSubDescHistoryVal",
      "ros_neuroPsychSubDiagCerebHemmSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSupp",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppHospitalVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubParaplegiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubQuadriplegiaVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComaVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagCerebPalsy = {
  ros_neuroPsychSubDiagCerebPalsySubDescActiveVal: "active",
  ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal: "physicalExam",
  ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDesc",
    [
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveVal",
      "ros_neuroPsychSubDiagCerebPalsySubDescHistoryVal",
      "ros_neuroPsychSubDiagCerebPalsySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubSupp",
    [
      "ros_neuroPsychSubDiagCerebPalsySubSuppPhysExamVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppHistoryVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppLabTestVal",
      "ros_neuroPsychSubDiagCerebPalsySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.specificSymptoms",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDelusion = {
  ros_neuroPsychSubDiagDelusionSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDelusionSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDelusionSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDelusionSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal: "specificSymptoms",
  ros_neuroPsychSubDiagDelusionSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagDelusionSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagDelusionSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDesc",
    [
      "ros_neuroPsychSubDiagDelusionSubDescActiveVal",
      "ros_neuroPsychSubDiagDelusionSubDescHistoryVal",
      "ros_neuroPsychSubDiagDelusionSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubSupp",
    [
      "ros_neuroPsychSubDiagDelusionSubSuppAffectVal",
      "ros_neuroPsychSubDiagDelusionSubSuppSpecSymptomVal",
      "ros_neuroPsychSubDiagDelusionSubSuppMedicVal",
      "ros_neuroPsychSubDiagDelusionSubSuppHospitalVal",
      "ros_neuroPsychSubDiagDelusionSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDementia = [
  // [
  //   "ros_neuroPsychSubDiagDementiaSubDescAcuteVal",
  //   "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.acute",
  // ],
  // [
  //   "ros_neuroPsychSubDiagDementiaSubDescChronicVal",
  //   "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.chronic",
  // ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescRuleoutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.behavioralChanges",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.mentalTesting",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppMriVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.mri",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.functionalChanges",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascular",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.historyOfStrokes",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.riskFactors",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.vascularSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.alzheimersDisease",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.etiologyUnknown",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.typeOfDementia.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDementia = {
  // ros_neuroPsychSubDiagDementiaSubDescAcuteVal: "acute",
  // ros_neuroPsychSubDiagDementiaSubDescChronicVal: "chronic",
  ros_neuroPsychSubDiagDementiaSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDementiaSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDementiaSubDescRuleoutVal: "ruleOut",
  ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal: "behavioralChanges",
  ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal: "mentalTesting",
  ros_neuroPsychSubDiagDementiaSubSuppMriVal: "mri",
  ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal: "functionalChanges",
  ros_neuroPsychSubDiagDementiaSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagDementiaSubTypeVascVal: "vascular",
  ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal: "historyOfStrokes",
  ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal: "riskFactors",
  ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal: "alzheimersDisease",
  ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal: "etiologyUnknown",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDementia = [
  [
    "ros_neuroPsychSubDiagDementiaSubDesc",
    [
      // "ros_neuroPsychSubDiagDementiaSubDescAcuteVal",
      // "ros_neuroPsychSubDiagDementiaSubDescChronicVal",
      "ros_neuroPsychSubDiagDementiaSubDescActiveVal",
      "ros_neuroPsychSubDiagDementiaSubDescHistoryVal",
      "ros_neuroPsychSubDiagDementiaSubDescRuleoutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubSupp",
    [
      "ros_neuroPsychSubDiagDementiaSubSuppBehavChangesVal",
      "ros_neuroPsychSubDiagDementiaSubSuppMentalTestVal",
      "ros_neuroPsychSubDiagDementiaSubSuppMriVal",
      "ros_neuroPsychSubDiagDementiaSubSuppFuncChangesVal",
      "ros_neuroPsychSubDiagDementiaSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubType",
    [
      "ros_neuroPsychSubDiagDementiaSubTypeVascVal",
      "ros_neuroPsychSubDiagDementiaSubTypeAlzheiVal",
      "ros_neuroPsychSubDiagDementiaSubTypeEtiologyVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubTypeVascSubType",
    [
      "ros_neuroPsychSubDiagDementiaSubTypeVascSubHistoryVal",
      "ros_neuroPsychSubDiagDementiaSubTypeVascSubRiskVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.phqTwoByNine",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.antidepressantMedication",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.supportedBy.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubMajorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajor",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubPhqVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.phqnine",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.chronicUseOfAntidepressant",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSubEctVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.isMajorSubAnswer.useOfEct",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.major.comment",
  ],

  [
    "ros_neuroPsychSubDiagDepressSubEpisodeSingleVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.single",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeRecurVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.recurrent",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeRemissVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.inRemission",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.unknown",
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisodeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.episodes.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDepress = {
  ros_neuroPsychSubDiagDepressSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDepressSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDepressSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDepressSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal: "phqTwoByNine",
  ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal: "antidepressantMedication",
  ros_neuroPsychSubDiagDepressSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagDepressSubMajorSubPhqVal: "phqnine",
  ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal:
    "chronicUseOfAntidepressant",
  ros_neuroPsychSubDiagDepressSubMajorSubEctVal: "useOfEct",

  ros_neuroPsychSubDiagDepressSubEpisodeSingleVal: "single",
  ros_neuroPsychSubDiagDepressSubEpisodeRecurVal: "recurrent",
  ros_neuroPsychSubDiagDepressSubEpisodeRemissVal: "inRemission",
  ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal: "unknown",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressSubDesc",
    [
      "ros_neuroPsychSubDiagDepressSubDescActiveVal",
      "ros_neuroPsychSubDiagDepressSubDescHistoryVal",
      "ros_neuroPsychSubDiagDepressSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubSupp",
    [
      "ros_neuroPsychSubDiagDepressSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagDepressSubSuppPhqTwoVal",
      "ros_neuroPsychSubDiagDepressSubSuppAntiMedicVal",
      "ros_neuroPsychSubDiagDepressSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubMajorSub",
    [
      "ros_neuroPsychSubDiagDepressSubMajorSubPhqVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubHospitalVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubAntiDepressVal",
      "ros_neuroPsychSubDiagDepressSubMajorSubEctVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressSubEpisode",
    [
      "ros_neuroPsychSubDiagDepressSubEpisodeSingleVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeRecurVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeRemissVal",
      "ros_neuroPsychSubDiagDepressSubEpisodeUnknownVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.comment",
  ],

  [
    "ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.episodic",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeContinousVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.continuous",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeRemissionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.remission",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.type.comment",
  ],

  [
    "ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.recreationalDrugs",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.chronicUseOfPainMedication",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.historyOfHospitalization",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOPTVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.historyOutpatientTreatment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.withdrawalSymptoms",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.abnormalAffect",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubHistoryPsychVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.historyOfPsychosis.isHistoryOfPsychosis",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.historyOfPsychosis.comment",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubWhatDrugsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.whatDrugs",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDrugs = {
  ros_neuroPsychSubDiagDrugsSubDescActiveVal: "active",
  ros_neuroPsychSubDiagDrugsSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDrugsSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal: "episodic",
  ros_neuroPsychSubDiagDrugsSubTypeContinousVal: "continuous",
  ros_neuroPsychSubDiagDrugsSubTypeRemissionVal: "remission",

  ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal: "recreationalDrugs",
  ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal: "chronicUseOfPainMedication",
  ros_neuroPsychSubDiagDrugsSubSuppHospitalVal: "historyOfHospitalization",
  ros_neuroPsychSubDiagDrugsSubSuppOPTVal: "historyOutpatientTreatment",
  ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal: "withdrawalSymptoms",
  ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal: "abnormalAffect",
  ros_neuroPsychSubDiagDrugsSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDesc",
    [
      "ros_neuroPsychSubDiagDrugsSubDescActiveVal",
      "ros_neuroPsychSubDiagDrugsSubDescHistoryVal",
      "ros_neuroPsychSubDiagDrugsSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubType",
    [
      "ros_neuroPsychSubDiagDrugsSubTypeEpisodicVal",
      "ros_neuroPsychSubDiagDrugsSubTypeContinousVal",
      "ros_neuroPsychSubDiagDrugsSubTypeRemissionVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubSupp",
    [
      "ros_neuroPsychSubDiagDrugsSubSuppRecreaDrugsVal",
      "ros_neuroPsychSubDiagDrugsSubSuppPainMedicVal",
      "ros_neuroPsychSubDiagDrugsSubSuppHospitalVal",
      "ros_neuroPsychSubDiagDrugsSubSuppOPTVal",
      "ros_neuroPsychSubDiagDrugsSubSuppWithdrawalVal",
      "ros_neuroPsychSubDiagDrugsSubSuppAbnormalEffectVal",
      "ros_neuroPsychSubDiagDrugsSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagFibro = {
  ros_neuroPsychSubDiagFibroSubDescActiveVal: "active",
  ros_neuroPsychSubDiagFibroSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagFibroSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagFibroSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagFibroSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagFibroSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagFibroSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagFibroSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDesc",
    [
      "ros_neuroPsychSubDiagFibroSubDescActiveVal",
      "ros_neuroPsychSubDiagFibroSubDescHistoryVal",
      "ros_neuroPsychSubDiagFibroSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagFibroSubSupp",
    [
      "ros_neuroPsychSubDiagFibroSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagFibroSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagFibroSubSuppHistoryVal",
      "ros_neuroPsychSubDiagFibroSubSuppMedicVal",
      "ros_neuroPsychSubDiagFibroSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagGuill = {
  ros_neuroPsychSubDiagGuillSubDescActiveVal: "active",
  ros_neuroPsychSubDiagGuillSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagGuillSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagGuillSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagGuillSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagGuillSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagGuillSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDesc",
    [
      "ros_neuroPsychSubDiagGuillSubDescActiveVal",
      "ros_neuroPsychSubDiagGuillSubDescHistoryVal",
      "ros_neuroPsychSubDiagGuillSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGuillSubSupp",
    [
      "ros_neuroPsychSubDiagGuillSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagGuillSubSuppEmgVal",
      "ros_neuroPsychSubDiagGuillSubSuppHospitalVal",
      "ros_neuroPsychSubDiagGuillSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescLeftVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.leftSided",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescRightVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.rightSided",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.hemiparesisDescribe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagHemi = {
  ros_neuroPsychSubDiagHemiSubDescActiveVal: "active",
  ros_neuroPsychSubDiagHemiSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagHemiSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagHemiSubHemiDescLeftVal: "leftSided",
  ros_neuroPsychSubDiagHemiSubHemiDescRightVal: "rightSided",
  ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal: "physicalFindings",
  ros_neuroPsychSubDiagHemiSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagHemiSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDesc",
    [
      "ros_neuroPsychSubDiagHemiSubDescActiveVal",
      "ros_neuroPsychSubDiagHemiSubDescHistoryVal",
      "ros_neuroPsychSubDiagHemiSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubHemiDesc",
    [
      "ros_neuroPsychSubDiagHemiSubHemiDescLeftVal",
      "ros_neuroPsychSubDiagHemiSubHemiDescRightVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubSupp",
    [
      "ros_neuroPsychSubDiagHemiSubSuppPhysicFindVal",
      "ros_neuroPsychSubDiagHemiSubSuppHistoryVal",
      "ros_neuroPsychSubDiagHemiSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppFamilyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppChoreaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.choreaMovement",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagHunting = {
  ros_neuroPsychSubDiagHuntingSubDescActiveVal: "active",
  ros_neuroPsychSubDiagHuntingSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagHuntingSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagHuntingSubSuppFamilyVal: "familyHistory",
  ros_neuroPsychSubDiagHuntingSubSuppChoreaVal: "choreaMovement",
  ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagHuntingSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDesc",
    [
      "ros_neuroPsychSubDiagHuntingSubDescActiveVal",
      "ros_neuroPsychSubDiagHuntingSubDescHistoryVal",
      "ros_neuroPsychSubDiagHuntingSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubSupp",
    [
      "ros_neuroPsychSubDiagHuntingSubSuppFamilyVal",
      "ros_neuroPsychSubDiagHuntingSubSuppChoreaVal",
      "ros_neuroPsychSubDiagHuntingSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagHuntingSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagInsomnia = {
  ros_neuroPsychSubDiagInsomniaSubDescActiveVal: "active",
  ros_neuroPsychSubDiagInsomniaSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagInsomniaSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagInsomniaSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDesc",
    [
      "ros_neuroPsychSubDiagInsomniaSubDescActiveVal",
      "ros_neuroPsychSubDiagInsomniaSubDescHistoryVal",
      "ros_neuroPsychSubDiagInsomniaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubSupp",
    [
      "ros_neuroPsychSubDiagInsomniaSubSuppMedicVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppHistoryVal",
      "ros_neuroPsychSubDiagInsomniaSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppMedicationsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppTestResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.dme",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.downsSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.phychomotorRetardation",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.other",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.intellectualDescribe.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagIntel = {
  ros_neuroPsychSubDiagIntelSubDescActiveVal: "active",
  ros_neuroPsychSubDiagIntelSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagIntelSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagIntelSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagIntelSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal: "physicalFindings",
  ros_neuroPsychSubDiagIntelSubSuppMedicationsVal: "medications",
  ros_neuroPsychSubDiagIntelSubSuppTestResultVal: "testResults",
  ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal: "imageStudies",
  ros_neuroPsychSubDiagIntelSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagIntelSubSuppDmeVal: "dme",
  ros_neuroPsychSubDiagIntelSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal: "downsSyndrome",
  ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal: "phychomotorRetardation",
  ros_neuroPsychSubDiagIntelSubIntelDescOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDesc",
    [
      "ros_neuroPsychSubDiagIntelSubDescActiveVal",
      "ros_neuroPsychSubDiagIntelSubDescHistoryVal",
      "ros_neuroPsychSubDiagIntelSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubSupp",
    [
      "ros_neuroPsychSubDiagIntelSubSuppHistoryVal",
      "ros_neuroPsychSubDiagIntelSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagIntelSubSuppPhysicalFindingsVal",
      "ros_neuroPsychSubDiagIntelSubSuppMedicationsVal",
      "ros_neuroPsychSubDiagIntelSubSuppTestResultVal",
      "ros_neuroPsychSubDiagIntelSubSuppImageStudiesVal",
      "ros_neuroPsychSubDiagIntelSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagIntelSubSuppDmeVal",
      "ros_neuroPsychSubDiagIntelSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubIntelDesc",
    [
      "ros_neuroPsychSubDiagIntelSubIntelDescDownSyndromeVal",
      "ros_neuroPsychSubDiagIntelSubIntelDescPhychoVal",
      "ros_neuroPsychSubDiagIntelSubIntelDescOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagMigraine = {
  ros_neuroPsychSubDiagMigraineSubDescActiveVal: "active",
  ros_neuroPsychSubDiagMigraineSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagMigraineSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagMigraineSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagMigraineSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagMigraineSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDesc",
    [
      "ros_neuroPsychSubDiagMigraineSubDescActiveVal",
      "ros_neuroPsychSubDiagMigraineSubDescHistoryVal",
      "ros_neuroPsychSubDiagMigraineSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubSupp",
    [
      "ros_neuroPsychSubDiagMigraineSubSuppHistoryVal",
      "ros_neuroPsychSubDiagMigraineSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagMigraineSubSuppMedicVal",
      "ros_neuroPsychSubDiagMigraineSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppBowelVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.bowel",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSclerosis = {
  ros_neuroPsychSubDiagSclerosisSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSclerosisSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagSclerosisSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagSclerosisSubSuppBowelVal: "bowel",
  ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSclerosisSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDesc",
    [
      "ros_neuroPsychSubDiagSclerosisSubDescActiveVal",
      "ros_neuroPsychSubDiagSclerosisSubDescHistoryVal",
      "ros_neuroPsychSubDiagSclerosisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubSupp",
    [
      "ros_neuroPsychSubDiagSclerosisSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppLabTestVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppMedicVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppBowelVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSclerosisSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.emgs",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.historyOfProgressiveMuscle",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppFamilyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.familyHistory",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagDystrophy = {
  ros_neuroPsychSubDiagDystrophySubDescActiveVal: "active",
  ros_neuroPsychSubDiagDystrophySubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagDystrophySubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagDystrophySubSuppEmgVal: "emgs",
  ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal:
    "historyOfProgressiveMuscle",
  ros_neuroPsychSubDiagDystrophySubSuppFamilyVal: "familyHistory",
  ros_neuroPsychSubDiagDystrophySubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDesc",
    [
      "ros_neuroPsychSubDiagDystrophySubDescActiveVal",
      "ros_neuroPsychSubDiagDystrophySubDescHistoryVal",
      "ros_neuroPsychSubDiagDystrophySubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubSupp",
    [
      "ros_neuroPsychSubDiagDystrophySubSuppPhysFindVal",
      "ros_neuroPsychSubDiagDystrophySubSuppEmgVal",
      "ros_neuroPsychSubDiagDystrophySubSuppHistoryMuscleVal",
      "ros_neuroPsychSubDiagDystrophySubSuppFamilyVal",
      "ros_neuroPsychSubDiagDystrophySubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppPtosisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.ptosis",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.doubleVision",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDiffChewVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.difficultyChewing",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.difficultySwallowing",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppTensTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.tensilonTest",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagGravis = {
  ros_neuroPsychSubDiagGravisSubDescActiveVal: "active",
  ros_neuroPsychSubDiagGravisSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagGravisSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagGravisSubSuppPtosisVal: "ptosis",
  ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal: "doubleVision",
  ros_neuroPsychSubDiagGravisSubSuppDiffChewVal: "difficultyChewing",
  ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal: "difficultySwallowing",
  ros_neuroPsychSubDiagGravisSubSuppTensTestVal: "tensilonTest",
  ros_neuroPsychSubDiagGravisSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagGravisSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDesc",
    [
      "ros_neuroPsychSubDiagGravisSubDescActiveVal",
      "ros_neuroPsychSubDiagGravisSubDescHistoryVal",
      "ros_neuroPsychSubDiagGravisSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGravisSubSupp",
    [
      "ros_neuroPsychSubDiagGravisSubSuppPtosisVal",
      "ros_neuroPsychSubDiagGravisSubSuppDoubleVisionVal",
      "ros_neuroPsychSubDiagGravisSubSuppDiffChewVal",
      "ros_neuroPsychSubDiagGravisSubSuppDiffSwallowVal",
      "ros_neuroPsychSubDiagGravisSubSuppTensTestVal",
      "ros_neuroPsychSubDiagGravisSubSuppMedicVal",
      "ros_neuroPsychSubDiagGravisSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppGaitVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.gait",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.dementia",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagParkinson = {
  ros_neuroPsychSubDiagParkinsonSubDescActiveVal: "active",
  ros_neuroPsychSubDiagParkinsonSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagParkinsonSubSuppGaitVal: "gait",
  ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal: "dementia",
  ros_neuroPsychSubDiagParkinsonSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagParkinsonSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDesc",
    [
      "ros_neuroPsychSubDiagParkinsonSubDescActiveVal",
      "ros_neuroPsychSubDiagParkinsonSubDescHistoryVal",
      "ros_neuroPsychSubDiagParkinsonSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubSupp",
    [
      "ros_neuroPsychSubDiagParkinsonSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppGaitVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppDementiaVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppAffectVal",
      "ros_neuroPsychSubDiagParkinsonSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.emg",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.secondaryToDiabetes.isSecondaryToDiabetes",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.secondaryToDiabetes.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagPeriNeuro = {
  ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal: "active",
  ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal: "emg",
  ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDesc",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubDescActiveVal",
      "ros_neuroPsychSubDiagPeriNeuroSubDescHistoryVal",
      "ros_neuroPsychSubDiagPeriNeuroSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubSupp",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppEmgVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagRestlessLeg = {
  ros_neuroPsychSubDiagRestlessLegSubDescActiveVal: "active",
  ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDesc",
    [
      "ros_neuroPsychSubDiagRestlessLegSubDescActiveVal",
      "ros_neuroPsychSubDiagRestlessLegSubDescHistoryVal",
      "ros_neuroPsychSubDiagRestlessLegSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubSupp",
    [
      "ros_neuroPsychSubDiagRestlessLegSubSuppMedicVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppHistoryVal",
      "ros_neuroPsychSubDiagRestlessLegSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppAffectVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.affect",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.specificSymptomsForSixMonths",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.medication",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.psychosis",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSchizo = {
  ros_neuroPsychSubDiagSchizoSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSchizoSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSchizoSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSchizoSubSuppAffectVal: "affect",
  ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal:
    "specificSymptomsForSixMonths",
  ros_neuroPsychSubDiagSchizoSubSuppMedicVal: "medication",
  ros_neuroPsychSubDiagSchizoSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal: "psychosis",
  ros_neuroPsychSubDiagSchizoSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDesc",
    [
      "ros_neuroPsychSubDiagSchizoSubDescActiveVal",
      "ros_neuroPsychSubDiagSchizoSubDescHistoryVal",
      "ros_neuroPsychSubDiagSchizoSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubSupp",
    [
      "ros_neuroPsychSubDiagSchizoSubSuppAffectVal",
      "ros_neuroPsychSubDiagSchizoSubSuppSymptomSixMonthVal",
      "ros_neuroPsychSubDiagSchizoSubSuppMedicVal",
      "ros_neuroPsychSubDiagSchizoSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSchizoSubSuppPsychosisVal",
      "ros_neuroPsychSubDiagSchizoSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeDisorderVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.seizureDisorder",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.seizureUnspecified",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTypeCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.type.comment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubTreatmentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.treatment",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.historyOfRecurrentSeizures",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppLabTestVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.laboratoryTesting",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSeizure = {
  ros_neuroPsychSubDiagSeizureSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSeizureSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSeizureSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagSeizureSubTypeDisorderVal: "seizureDisorder",
  ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal: "seizureUnspecified",
  ros_neuroPsychSubDiagSeizureSubTreatmentVal: "treatment",
  ros_neuroPsychSubDiagSeizureSubSuppHistoryVal: "historyOfRecurrentSeizures",
  ros_neuroPsychSubDiagSeizureSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagSeizureSubSuppLabTestVal: "laboratoryTesting",
  ros_neuroPsychSubDiagSeizureSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDesc",
    [
      "ros_neuroPsychSubDiagSeizureSubDescActiveVal",
      "ros_neuroPsychSubDiagSeizureSubDescHistoryVal",
      "ros_neuroPsychSubDiagSeizureSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubType",
    [
      "ros_neuroPsychSubDiagSeizureSubTypeDisorderVal",
      "ros_neuroPsychSubDiagSeizureSubTypeUnspecifiedVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubSupp",
    [
      "ros_neuroPsychSubDiagSeizureSubSuppHistoryVal",
      "ros_neuroPsychSubDiagSeizureSubSuppMedicVal",
      "ros_neuroPsychSubDiagSeizureSubSuppLabTestVal",
      "ros_neuroPsychSubDiagSeizureSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.paresis",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.none",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.anteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.posteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.centralCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isParesisSubAnswer.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.lossOfSensation",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.none",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.anteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.posteriorCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.centralCordSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.isLossOfSensation.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.bowel",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.supportedBy.comment",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.fracture",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.dislocation",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.compressiveLesion",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSpinalCord = {
  ros_neuroPsychSubDiagSpinalCordSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal: "ruleOut",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal: "paresis",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal: "none",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal:
    "anteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal:
    "posteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal:
    "centralCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal: "lossOfSensation",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal: "none",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal:
    "anteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal:
    "posteriorCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal:
    "centralCordSyndrome",
  ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal: "bowel",
  ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal: "other",
  ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal: "fracture",
  ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal: "dislocation",
  ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal: "compressiveLesion",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDesc",
    [
      "ros_neuroPsychSubDiagSpinalCordSubDescActiveVal",
      "ros_neuroPsychSubDiagSpinalCordSubDescHistoryVal",
      "ros_neuroPsychSubDiagSpinalCordSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSupp",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppBowelVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSub",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubNoneVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubParaVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubQuadVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubAnteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubPosteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCentralVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSub",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubNoneVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubParaVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubQuadVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubAnteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubPosteriorVal",
      "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCentralVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecTo",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSecToFractureVal",
      "ros_neuroPsychSubDiagSpinalCordSubSecToDislocateVal",
      "ros_neuroPsychSubDiagSpinalCordSubSecToCompLessonVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.functionalQuadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.parethesias",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.isSensoryFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagStroke = {
  ros_neuroPsychSubDiagStrokeSubDescActiveVal: "active",
  ros_neuroPsychSubDiagStrokeSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagStrokeSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagStrokeSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagStrokeSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal: "rightHemiparesis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal: "leftHemiparesis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal:
    "functionalQuadriplegia",

  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal: "facialNumbness",
  ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal: "paresthesias",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeSubDesc",
    [
      "ros_neuroPsychSubDiagStrokeSubDescActiveVal",
      "ros_neuroPsychSubDiagStrokeSubDescHistoryVal",
      "ros_neuroPsychSubDiagStrokeSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSupp",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppHospitalVal",
      "ros_neuroPsychSubDiagStrokeSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagStrokeSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubFuncQuadVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftArmParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftLegParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.rightHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.leftHemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.aphasia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.apraxia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.cranialNerveParalysis",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.coma",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.sensoryFindings",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessRightArm",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessRightLeg",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessLeftArm",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.numbnessLeftLeg",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.facialNumbness",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.parethesias",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.isSensoryFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagSubdural = {
  ros_neuroPsychSubDiagSubduralSubDescActiveVal: "active",
  ros_neuroPsychSubDiagSubduralSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagSubduralSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagSubduralSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal: "sensoryFindings",
  ros_neuroPsychSubDiagSubduralSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal:
    "rightArmParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal:
    "leftArmParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal:
    "rightLegParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal:
    "leftLegParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal:
    "rightHemiparesis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal: "leftHemiparesis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal: "aphasia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal: "apraxia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal:
    "cranialNerveParalysis",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal: "paraplegia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal: "coma",

  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal: "none",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal:
    "numbnessRightArm",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal:
    "numbnessRightLeg",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal:
    "numbnessLeftArm",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal:
    "numbnessLeftLeg",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal:
    "facialNumbness",
  ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal: "paresthesias",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralSubDesc",
    [
      "ros_neuroPsychSubDiagSubduralSubDescActiveVal",
      "ros_neuroPsychSubDiagSubduralSubDescHistoryVal",
      "ros_neuroPsychSubDiagSubduralSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSupp",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppHospitalVal",
      "ros_neuroPsychSubDiagSubduralSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindVal",
      "ros_neuroPsychSubDiagSubduralSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightArmParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftArmParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightLegParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftLegParaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubRightHemiVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubLeftHemiVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubAphasiaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubApraxiaVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCranNerveParalVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubParapVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubQuadVal",
      "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComaVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSub",
    [
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNoneVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightArmVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbRightLegVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftArmVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubNumbLeftLegVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubFacialNumbVal",
      "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubParesthesVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppPhysExamVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.physicalExam",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagTia = {
  ros_neuroPsychSubDiagTiaSubDescActiveVal: "active",
  ros_neuroPsychSubDiagTiaSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagTiaSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagTiaSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagTiaSubSuppPhysExamVal: "physicalExam",
  ros_neuroPsychSubDiagTiaSubSuppImageStudyVal: "imageStudies",
  ros_neuroPsychSubDiagTiaSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDesc",
    [
      "ros_neuroPsychSubDiagTiaSubDescActiveVal",
      "ros_neuroPsychSubDiagTiaSubDescHistoryVal",
      "ros_neuroPsychSubDiagTiaSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTiaSubSupp",
    [
      "ros_neuroPsychSubDiagTiaSubSuppHistoryVal",
      "ros_neuroPsychSubDiagTiaSubSuppPhysExamVal",
      "ros_neuroPsychSubDiagTiaSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagTiaSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.hospitalization",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.imageStudy",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.none",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.coma",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.quadriplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.paraplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.hemiplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.monoplegia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.pt",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.dme",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.isPhysicalFindings.comment",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.supportedBy.comment",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagTraumaBrain = {
  ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal: "active",
  ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal: "hospitalization",
  ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal: "imageStudy",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal: "other",

  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal: "none",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal: "coma",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal: "quadriplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal: "paraplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal: "hemiplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal: "monoplegia",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal: "pt",
  ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal: "dme",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainSubDesc",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubDescActiveVal",
      "ros_neuroPsychSubDiagTraumaBrainSubDescHistoryVal",
      "ros_neuroPsychSubDiagTraumaBrainSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSupp",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubSuppHospitalVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSub",
    [
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubNoneVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComaVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubQuadVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubParaVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubHemiVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubMonoVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubPtVal",
      "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubDmeVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDescActiveVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.active",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescRuleOutVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.ruleOut",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.comment",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppHistoryVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppSymptomsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppPhysFindVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppMedicVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppTestResultVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppImageStudyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppBiopsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppDmeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.other",
  ],
];

export const ros_optionsTreeMapObject_neuroPsychSubDiagOther = {
  ros_neuroPsychSubDiagOtherSubDescActiveVal: "active",
  ros_neuroPsychSubDiagOtherSubDescHistoryVal: "historyOf",
  ros_neuroPsychSubDiagOtherSubDescRuleOutVal: "ruleOut",

  ros_neuroPsychSubDiagOtherSubSuppHistoryVal: "history",
  ros_neuroPsychSubDiagOtherSubSuppSymptomsVal: "symptoms",
  ros_neuroPsychSubDiagOtherSubSuppPhysFindVal: "physicalFindings",
  ros_neuroPsychSubDiagOtherSubSuppMedicVal: "medications",
  ros_neuroPsychSubDiagOtherSubSuppTestResultVal: "testResults",
  ros_neuroPsychSubDiagOtherSubSuppImageStudyVal: "imageStudies",
  ros_neuroPsychSubDiagOtherSubSuppBiopsyVal: "biopsy",
  ros_neuroPsychSubDiagOtherSubSuppDmeVal: "dme",
  ros_neuroPsychSubDiagOtherSubSuppOtherVal: "other",
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDesc",
    [
      "ros_neuroPsychSubDiagOtherSubDescActiveVal",
      "ros_neuroPsychSubDiagOtherSubDescHistoryVal",
      "ros_neuroPsychSubDiagOtherSubDescRuleOutVal",
    ],
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSupp",
    [
      "ros_neuroPsychSubDiagOtherSubSuppHistoryVal",
      "ros_neuroPsychSubDiagOtherSubSuppSymptomsVal",
      "ros_neuroPsychSubDiagOtherSubSuppPhysFindVal",
      "ros_neuroPsychSubDiagOtherSubSuppMedicVal",
      "ros_neuroPsychSubDiagOtherSubSuppTestResultVal",
      "ros_neuroPsychSubDiagOtherSubSuppImageStudyVal",
      "ros_neuroPsychSubDiagOtherSubSuppBiopsyVal",
      "ros_neuroPsychSubDiagOtherSubSuppDmeVal",
      "ros_neuroPsychSubDiagOtherSubSuppOtherVal",
    ],
  ],
];

export const ros_hashmap_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiagAlcoholVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependence",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateral",
  ],
  [
    "ros_neuroPsychSubDiagAnxietyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxiety",
  ],
  [
    "ros_neuroPsychSubDiagBipolarVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorder",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhage",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsy",
  ],
  [
    "ros_neuroPsychSubDiagDelusionVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDisease",
  ],
  [
    "ros_neuroPsychSubDiagDementiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementia",
  ],
  [
    "ros_neuroPsychSubDiagDepressVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depression",
  ],
  [
    "ros_neuroPsychSubDiagDrugsVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependence",
  ],
  [
    "ros_neuroPsychSubDiagFibroVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgia",
  ],
  [
    "ros_neuroPsychSubDiagGuillVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDisease",
  ],
  [
    "ros_neuroPsychSubDiagHemiVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesis",
  ],
  [
    "ros_neuroPsychSubDiagHuntingVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChorea",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomnia",
  ],
  [
    "ros_neuroPsychSubDiagIntelVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisability",
  ],
  [
    "ros_neuroPsychSubDiagMigraineVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadaches",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosis",
  ],
  [
    "ros_neuroPsychSubDiagDystrophyVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophy",
  ],
  [
    "ros_neuroPsychSubDiagGravisVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravis",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDisease",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathy",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndrome",
  ],
  [
    "ros_neuroPsychSubDiagSchizoVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophrenia",
  ],
  [
    "ros_neuroPsychSubDiagSeizureVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizures",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjury",
  ],
  [
    "ros_neuroPsychSubDiagStrokeVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.stroke",
  ],
  [
    "ros_neuroPsychSubDiagSubduralVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematoma",
  ],
  [
    "ros_neuroPsychSubDiagTiaVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tia",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjury",
  ],
  [
    "ros_neuroPsychSubDiagOtherVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.other",
  ],
  [
    "ros_neuroPsychSubDiagCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.comment",
  ],
  ...ros_hashmap_neuroPsychSubDiagAlcohol,
  ...ros_hashmap_neuroPsychSubDiagAmyotrop,
  ...ros_hashmap_neuroPsychSubDiagAnxiety,
  ...ros_hashmap_neuroPsychSubDiagBipolar,
  ...ros_hashmap_neuroPsychSubDiagCerebHemm,
  ...ros_hashmap_neuroPsychSubDiagCerebPalsy,
  ...ros_hashmap_neuroPsychSubDiagDelusion,
  ...ros_hashmap_neuroPsychSubDiagDementia,
  ...ros_hashmap_neuroPsychSubDiagDepress,
  ...ros_hashmap_neuroPsychSubDiagDrugs,
  ...ros_hashmap_neuroPsychSubDiagFibro,
  ...ros_hashmap_neuroPsychSubDiagGuill,
  ...ros_hashmap_neuroPsychSubDiagHemi,
  ...ros_hashmap_neuroPsychSubDiagHunting,
  ...ros_hashmap_neuroPsychSubDiagInsomnia,
  ...ros_hashmap_neuroPsychSubDiagIntel,
  ...ros_hashmap_neuroPsychSubDiagMigraine,
  ...ros_hashmap_neuroPsychSubDiagSclerosis,
  ...ros_hashmap_neuroPsychSubDiagDystrophy,
  ...ros_hashmap_neuroPsychSubDiagGravis,
  ...ros_hashmap_neuroPsychSubDiagParkinson,
  ...ros_hashmap_neuroPsychSubDiagPeriNeuro,
  ...ros_hashmap_neuroPsychSubDiagRestlessLeg,
  ...ros_hashmap_neuroPsychSubDiagSchizo,
  ...ros_hashmap_neuroPsychSubDiagSeizure,
  ...ros_hashmap_neuroPsychSubDiagSpinalCord,
  ...ros_hashmap_neuroPsychSubDiagStroke,
  ...ros_hashmap_neuroPsychSubDiagSubdural,
  ...ros_hashmap_neuroPsychSubDiagTia,
  ...ros_hashmap_neuroPsychSubDiagTraumaBrain,
  ...ros_hashmap_neuroPsychSubDiagOther,
];

export const ros_optionsTreeMapObject_neuroPsychSubDiag = {
  ros_neuroPsychSubDiagAlcoholVal: "alcoholDependence",
  ros_neuroPsychSubDiagAmyotropVal: "amyotrophicLateral",
  ros_neuroPsychSubDiagAnxietyVal: "anxiety",
  ros_neuroPsychSubDiagBipolarVal: "bipolarDisorder",
  ros_neuroPsychSubDiagCerebHemmVal: "cerebralHemorrhage",
  ros_neuroPsychSubDiagCerebPalsyVal: "cerebralPalsy",
  ros_neuroPsychSubDiagDelusionVal: "delusionalDisease",
  ros_neuroPsychSubDiagDementiaVal: "dementia",
  ros_neuroPsychSubDiagDepressVal: "depression",
  ros_neuroPsychSubDiagDrugsVal: "drugDependence",
  ros_neuroPsychSubDiagFibroVal: "fibromyalgia",
  ros_neuroPsychSubDiagGuillVal: "guillainBarreDisease",
  ros_neuroPsychSubDiagHemiVal: "hemiparesis",
  ros_neuroPsychSubDiagHuntingVal: "huntingtonsChorea",
  ros_neuroPsychSubDiagInsomniaVal: "insomnia",
  ros_neuroPsychSubDiagIntelVal: "developmentalDisability",
  ros_neuroPsychSubDiagMigraineVal: "migraineHeadaches",
  ros_neuroPsychSubDiagSclerosisVal: "multipleSclerosis",
  ros_neuroPsychSubDiagDystrophyVal: "muscularDystrophy",
  ros_neuroPsychSubDiagGravisVal: "myastheniaGravis",
  ros_neuroPsychSubDiagParkinsonVal: "parkinsonsDisease",
  ros_neuroPsychSubDiagPeriNeuroVal: "peripheralNeuropathy",
  ros_neuroPsychSubDiagRestlessLegVal: "restlessLegSyndrome",
  ros_neuroPsychSubDiagSchizoVal: "schizophrenia",
  ros_neuroPsychSubDiagSeizureVal: "seizures",
  ros_neuroPsychSubDiagSpinalCordVal: "spinalCordInjury",
  ros_neuroPsychSubDiagStrokeVal: "stroke",
  ros_neuroPsychSubDiagSubduralVal: "subduralHematoma",
  ros_neuroPsychSubDiagTiaVal: "tia",
  ros_neuroPsychSubDiagTraumaBrainVal: "traumaticBrainInjury",
  ros_neuroPsychSubDiagOtherVal: "other",
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAlcohol,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAmyotrop,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagAnxiety,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagBipolar,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagCerebHemm,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagCerebPalsy,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDelusion,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDementia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDepress,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDrugs,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagFibro,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagGuill,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagHemi,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagHunting,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagInsomnia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagIntel,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagMigraine,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSclerosis,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagDystrophy,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagGravis,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagParkinson,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagPeriNeuro,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagRestlessLeg,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSchizo,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSeizure,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSpinalCord,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagStroke,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagSubdural,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagTia,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagTraumaBrain,
  ...ros_optionsTreeMapObject_neuroPsychSubDiagOther,
};

export const ros_OptionsTreeWrappers_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiag",
    [
      "ros_neuroPsychSubDiagAlcoholVal",
      "ros_neuroPsychSubDiagAmyotropVal",
      "ros_neuroPsychSubDiagAnxietyVal",
      "ros_neuroPsychSubDiagBipolarVal",
      "ros_neuroPsychSubDiagCerebHemmVal",
      "ros_neuroPsychSubDiagCerebPalsyVal",
      "ros_neuroPsychSubDiagDelusionVal",
      "ros_neuroPsychSubDiagDementiaVal",
      "ros_neuroPsychSubDiagDepressVal",
      "ros_neuroPsychSubDiagDrugsVal",
      "ros_neuroPsychSubDiagFibroVal",
      "ros_neuroPsychSubDiagGuillVal",
      "ros_neuroPsychSubDiagHemiVal",
      "ros_neuroPsychSubDiagHuntingVal",
      "ros_neuroPsychSubDiagInsomniaVal",
      "ros_neuroPsychSubDiagIntelVal",
      "ros_neuroPsychSubDiagMigraineVal",
      "ros_neuroPsychSubDiagSclerosisVal",
      "ros_neuroPsychSubDiagDystrophyVal",
      "ros_neuroPsychSubDiagGravisVal",
      "ros_neuroPsychSubDiagParkinsonVal",
      "ros_neuroPsychSubDiagPeriNeuroVal",
      "ros_neuroPsychSubDiagRestlessLegVal",
      "ros_neuroPsychSubDiagSchizoVal",
      "ros_neuroPsychSubDiagSeizureVal",
      "ros_neuroPsychSubDiagSpinalCordVal",
      "ros_neuroPsychSubDiagStrokeVal",
      "ros_neuroPsychSubDiagSubduralVal",
      "ros_neuroPsychSubDiagTiaVal",
      "ros_neuroPsychSubDiagTraumaBrainVal",
      "ros_neuroPsychSubDiagOtherVal",
    ],
  ],
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAlcohol,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAmyotrop,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagAnxiety,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagBipolar,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagCerebHemm,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagCerebPalsy,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDelusion,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDementia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDepress,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDrugs,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagFibro,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagGuill,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagHemi,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagHunting,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagInsomnia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagIntel,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagMigraine,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSclerosis,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagDystrophy,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagGravis,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagParkinson,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagPeriNeuro,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagRestlessLeg,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSchizo,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSeizure,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSpinalCord,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagStroke,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagSubdural,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagTia,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagTraumaBrain,
  ...ros_OptionsTreeWrappers_neuroPsychSubDiagOther,
];

export const ros_hashmap_neuroPsych = [
  ["ros_neuroPsychVal", "neuroPsych.isNeuroPsych"],
  ["ros_neuroPsychCommentVal", "neuroPsych.comment"],
  ["ros_nervousAnxiousVal", "neuroPsych.nervousFeel.isNervousFeel"],
  ["ros_nervousAnxiousCommentVal", "neuroPsych.nervousFeel.comment"],
  ["ros_worryMuchVal", "neuroPsych.worryTooMuch.isWorryTooMuch"],
  ["ros_worryMuchCommentVal", "neuroPsych.worryTooMuch.comment"],
  ["ros_feelAfraidVal", "neuroPsych.feelAfraid.isFeelAfraid"],
  ["ros_feelAfraidCommentVal", "neuroPsych.feelAfraid.comment"],
  [
    "ros_neuroPsychHistoryAudioVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfAuditory.isHistoryOfAuditory",
  ],
  [
    "ros_neuroPsychHistoryAudioCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfAuditory.comment",
  ],
  [
    "ros_neuroPsychHistoryVisualVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfVisual.isHistoryOfVisual",
  ],
  [
    "ros_neuroPsychHistoryVisualCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfVisual.comment",
  ],
  [
    "ros_neuroPsychBehaviourVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesBehavior.isHistoryOfEpisodesBehavior",
  ],
  [
    "ros_neuroPsychBehaviourCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesBehavior.comment",
  ],
  [
    "ros_neuroPsychDeliriumVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesDelirium.isHistoryOfEpisodesDelirium",
  ],
  [
    "ros_neuroPsychDeliriumCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.historyOfEpisodesDelirium.comment",
  ],
  [
    "ros_neuroPsychHearVoicesVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHearVoices.isDoYouHearVoices",
  ],
  [
    "ros_neuroPsychHearVoicesCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHearVoices.comment",
  ],
  [
    "ros_neuroPsychHighLowsVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHaveHighsAndLows.isDoYouHaveHighsAndLows",
  ],
  [
    "ros_neuroPsychHighLowsCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouHaveHighsAndLows.comment",
  ],
  [
    "ros_neuroPsychEverFeelVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouEverFeel.isDoYouEverFeel",
  ],
  [
    "ros_neuroPsychEverFeelCommentVal",
    "neuroPsych.isNeuroPsychSubAnswer.doYouEverFeel.comment",
  ],
  ...ros_hashmap_neuroPsychSubDiag,
];

export const ros_optionsTreeMapObject_neuroPsych = {
  ...ros_optionsTreeMapObject_neuroPsychSubDiag,
};

export const ros_OptionsTreeWrappers_neuroPsych = [
  ...ros_OptionsTreeWrappers_neuroPsychSubDiag,
];

export const ros_hashmap = [
  ...ros_hashmap_srilatha,
  ...ros_hashmap_mehak,
  ...ros_hashmap_Eyes,
  ...ros_hashmap_Ears,
  ...ros_hashmap_Nose,
  ...ros_hashmap_Mouth,
  ...ros_hashmap_Neck,
  ...ros_hashmap_eyeENTNeckRecommendations,
  ...ros_hashmap_BowelMovement,
  ...ros_hashmap_AbdominalOpening,
  ...ros_hashmap_rectalProblems,
  ...ros_hashmap_lastBowel,
  ...ros_hashmap_meetFriends,
  ...ros_hashmap_over2Weeks,
  ...ros_hashmap_gpCog,
  ...ros_hashmap_gpCogNoScore,
  ...ros_hashmap_speech,
  ...ros_hashmap_sitToStand,
  ...ros_hashmap_fingerToNose,
  ...ros_hashmap_heelToToe,
  ...ros_hashmap_thumbToFinger,
  ...ros_hashmap_extremeMove,
  ...ros_hashmap_affect,
  ...ros_hashmap_neuroPsych,
];

export const ros_optionsTreeMapObject = {
  ...ros_optionsTreeMapObject_mehak,
  ...ros_optionsTreeMapObject_srilatha,
  ...ros_optionsTreeMapObject_Eyes,
  ...ros_optionsTreeMapObject_Ears,
  ...ros_optionsTreeMapObject_Nose,
  ...ros_optionsTreeMapObject_Mouth,
  ...ros_optionsTreeMapObject_Neck,
  ...ros_optionsTreeMapObject_BowelMovement,
  ...ros_optionsTreeMapObject_AbdominalOpening,
  ...ros_optionsTreeMapObject_rectalProblems,
  ...ros_optionsTreeMapObject_lastBowel,
  ...ros_optionsTreeMapObject_meetFriends,
  ...ros_optionsTreeMapObject_over2Weeks,
  ...ros_optionsTreeMapObject_gpCogNoScore,
  ...ros_optionsTreeMapObject_speech,
  ...ros_optionsTreeMapObject_sitToStand,
  ...ros_optionsTreeMapObject_fingerToNose,
  ...ros_optionsTreeMapObject_heelToToe,
  ...ros_optionsTreeMapObject_thumbToFinger,
  ...ros_optionsTreeMapObject_extremeMove,
  ...ros_optionsTreeMapObject_affect,
  ...ros_optionsTreeMapObject_over2Weeks,
  ...ros_optionsTreeMapObject_neuroPsych,
};

export const ros_OptionsTreeWrappers = [
  ...ros_OptionsTreeWrappers_mehak,
  ...ros_OptionsTreeWrappers_srilatha,
  ...ros_OptionsTreeWrappers_Eyes,
  ...ros_OptionsTreeWrappers_Ears,
  ...ros_OptionsTreeWrappers_Nose,
  ...ros_OptionsTreeWrappers_Mouth,
  ...ros_OptionsTreeWrappers_Neck,
  ...ros_OptionsTreeWrappers_BowelMovement,
  ...ros_OptionsTreeWrappers_AbdominalOpening,
  ...ros_OptionsTreeWrappers_rectalProblems,
  ...ros_OptionsTreeWrappers_lastBowel,
  ...ros_OptionsTreeWrappers_meetFriends,
  ...ros_OptionsTreeWrappers_over2Weeks,
  ...ros_OptionsTreeWrappers_gpCogNoScore,
  ...ros_OptionsTreeWrappers_speech,
  ...ros_OptionsTreeWrappers_sitToStand,
  ...ros_OptionsTreeWrappers_fingerToNose,
  ...ros_OptionsTreeWrappers_heelToToe,
  ...ros_OptionsTreeWrappers_thumbToFinger,
  ...ros_OptionsTreeWrappers_extremeMove,
  ...ros_OptionsTreeWrappers_affect,
  ...ros_OptionsTreeWrappers_over2Weeks,
  ...ros_OptionsTreeWrappers_neuroPsych,
];
