const Image = (props) => {
  return (
    <div className="imageContainer">
      <img
        className={props.imgClass}
        src={props.imgPath}
        alt={props.imgName}
        style={props.style}
      />
    </div>
  );
};

export default Image;
