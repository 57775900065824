import {
  getObjectData,
  setObjectData,
  getOptionsSelected,
  setOptionsSelected,
  getOptionsSelectedYesNo,
  setOptionsSelectedYesNo,
  getOptionsSelectedYesNoDontKnow,
  setOptionsSelectedYesNoDontKnow,
  getSelectedCheckboxes,
  setSelectedCheckboxes,
  getSubTreeString,
  deleteSubTreeData,
  getOptionsSelectedYesNoUnknown,
  getOptionsSelectedYesNoPartially,
  setOptionsSelectedYesNoPartially,
  setOptionsSelectedYesNoUnknown,
} from "../../../../../../utils/helpers.DynamicForm";

/*

============= FLATMAP =============

for get/set the nested level DB-JSON data back & forth using lodash
It's an array of arrays. Inner array has 3 values -> [ flatmap_value,db_json_path,year_list_array ]
<- example ->
refer home safety

======= OPTIONS TREE MAPPER =======

for get/set the options tree selected value by comparing with formdata json
It's an object where key-value pair will be flatmap value-value to be assigned in options tree if it is "1"
it must match your options tree's corresponding toggle button's value field
<- example ->
refer home safety

======= OPTIONS TREE WRAPPER =======

for populate the selected value(s) of an options tree by combining list of flatmap values.
It's an array of arrays. Inner array has 2 elements -> [grouped_string,[list_of_flattened_values_to_be_grouped]]
this grouped string must be assigned to your options tree's name field.

<- example ->
refer home safety

*/

const self_flatmap = [
  ["self_schoolThirdGrade", "school.lessThanThirdGrade", ["2023", "2024"], "2"],
  [
    "self_schoolEighthGrade",
    "school.lessThanEighthGrade",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_schoolLessTwelthGrade",
    "school.lessThanTwelthGrade",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_schoolCompTwelthGrade",
    "school.completedTwelthGrade",
    ["2023", "2024"],
    "2",
  ],
  ["self_schoolAttendGradCollege", "school.attendedOrgraduated", ["2024"], "2"],
  ["self_schoolComment", "school.comment", ["2023", "2024"], "1"],
  ["self_writtenInfoVeryDifficult", "writtenInfo.veryDifficult", ["2023"], "2"],
  [
    "self_writtenInfoSomewhatDifficult",
    "writtenInfo.someWhatDifficult",
    ["2023"],
    "2",
  ],
  ["self_writtenInfoEasy", "writtenInfo.easy", ["2023"], "2"],
  ["self_writtenInfoVeryEasy", "writtenInfo.veryEasy", ["2023"], "2"],
  ["self_writtenInfoComment", "writtenInfo.comment", ["2023"], "1"],
  [
    "self_presciptionBottleVeryDifficult",
    "prescriptionBottle.veryDifficult",
    ["2023"],
    "2",
  ],
  [
    "self_presciptionBottleSomewhatDifficult",
    "prescriptionBottle.someWhatDifficult",
    ["2023"],
    "2",
  ],
  ["self_presciptionBottleEasy", "prescriptionBottle.easy", ["2023"], "2"],
  [
    "self_presciptionBottleVeryEasy",
    "prescriptionBottle.veryEasy",
    ["2023"],
    "2",
  ],
  [
    "self_presciptionBottleComment",
    "prescriptionBottle.comment",
    ["2023"],
    "1",
  ],
  [
    "self_fillingMedicalFormNotAtAllConfident",
    "fillingMedicalForm.notAtAllConfident",
    ["2023"],
    "2",
  ],
  [
    "self_fillingMedicalFormNotVeryConfident",
    "fillingMedicalForm.notVeryConfident",
    ["2023"],
    "2",
  ],
  [
    "self_fillingMedicalFormConfident",
    "fillingMedicalForm.confident",
    ["2023"],
    "2",
  ],
  [
    "self_fillingMedicalFormVeryConfident",
    "fillingMedicalForm.veryConfident",
    ["2023"],
    "2",
  ],
  [
    "self_fillingMedicalFormComment",
    "fillingMedicalForm.comment",
    ["2023"],
    "1",
  ],
  [
    "self_DifficultyReadUnderstandForms",
    "difficultyReading.fillingMedicalForms",
    ["2024"],
    "2",
  ],
  [
    "self_DifficultyReadUnderstandProvider",
    "difficultyReading.providerInstructions",
    ["2024"],
    "2",
  ],
  [
    "self_DifficultyReadUnderstandPrescription",
    "difficultyReading.prescriptionInstructions",
    ["2024"],
    "2",
  ],
  [
    "self_DifficultyReadUnderstandComment",
    "difficultyReading.comment",
    ["2024"],
    "1",
  ],
  [
    "self_concernedAboutTransportation",
    "concernAboutTransportation.isConcernAboutTransportation",
    ["2024"],
    "3",
  ],
  [
    "self_concernedAboutTransportationComment",
    "concernAboutTransportation.comment",
    ["2024"],
    "1",
  ],
  ["self_ratYourHealthExcellent", "rateYourHealth.excellent", ["2023"], "2"],
  ["self_ratYourHealthGood", "rateYourHealth.good", ["2023"], "2"],
  ["self_ratYourHealthFair", "rateYourHealth.fair", ["2023"], "2"],
  ["self_ratYourHealthPoor", "rateYourHealth.poor", ["2023"], "2"],
  ["self_ratYourHealthComment", "rateYourHealth.comment", ["2023"], "1"],
  ["self_socialActivitiesOften", "socialActivities.often", ["2023"], "2"],
  [
    "self_socialActivitiesSometimes",
    "socialActivities.sometimes",
    ["2023"],
    "2",
  ],
  [
    "self_socialActivitiesAlmostNever",
    "socialActivities.almostNever",
    ["2023"],
    "2",
  ],
  ["self_socialActivitiesNever", "socialActivities.never", ["2023"], "2"],
  ["self_socialActivitiesComment", "socialActivities.comment", ["2023"], "1"],

  [
    "self_ratYourHealthExcellent",
    "social.rateYourHealth.excellent",
    ["2024"],
    "2",
  ],
  ["self_ratYourHealthGood", "social.rateYourHealth.good", ["2024"], "2"],
  ["self_ratYourHealthFair", "social.rateYourHealth.fair", ["2024"], "2"],
  ["self_ratYourHealthPoor", "social.rateYourHealth.poor", ["2024"], "2"],
  ["self_ratYourHealthComment", "social.rateYourHealth.comment", ["2024"], "1"],
  ["self_relyOn", "social.relyOn.isRelyOn", ["2024"], "3"],
  ["self_relyOnComment", "social.relyOn.comment", ["2024"], "1"],
  [
    "self_socialActivitiesOften",
    "social.socialActivities.often",
    ["2024"],
    "2",
  ],
  [
    "self_socialActivitiesSometimes",
    "social.socialActivities.sometimes",
    ["2024"],
    "2",
  ],
  [
    "self_socialActivitiesAlmostNever",
    "social.socialActivities.almostNever",
    ["2024"],
    "2",
  ],
  [
    "self_socialActivitiesNever",
    "social.socialActivities.never",
    ["2024"],
    "2",
  ],
  [
    "self_socialActivitiesComment",
    "social.socialActivities.comment",
    ["2024"],
    "1",
  ],

  ["self_goOutOften", "social.goOutToMeet.often", ["2024"], "2"],
  ["self_goOutSometime", "social.goOutToMeet.sometimes", ["2024"], "2"],
  ["self_goOutAlmostNever", "social.goOutToMeet.almostNever", ["2024"], "2"],
  ["self_goOutNever", "social.goOutToMeet.never", ["2024"], "2"],
  ["self_goOutComment", "social.goOutToMeet.comment", ["2024"], "1"],

  ["self_careGiver", "social.careGiver.isCareGiver", ["2024"], "3"],
  [
    "self_careGiverSubDesc",
    "social.careGiver.isCareGiverSubAnswer.describe",
    ["2024"],
    "1",
  ],
  ["self_careGiverComment", "social.careGiver.comment", ["2024"], "1"],

  ["self_eduGuideNone", "educationGuidlines.pastYear.isNone", ["2024"], "2"],
  [
    "self_eduGuideOneMore",
    "educationGuidlines.pastYear.oneOrMore",
    ["2024"],
    "2",
  ],
  [
    "self_eduGuideComment",
    "educationGuidlines.pastYear.comment",
    ["2024"],
    "1",
  ],
  [
    "self_eduGuideOneMoreSubEduResource",
    "educationGuidlines.pastYear.oneOrMoreSubAnswers.educationOrResources",
    ["2024"],
    "7",
  ],
  [
    "self_eduGuideOneMoreSubEduResourceComment",
    "educationGuidlines.pastYear.oneOrMoreSubAnswers.comment",
    ["2024"],
    "1",
  ],

  [
    "self_currLiveSituation",
    "currentlyLiveSituation.currentlyLiveSituation",
    ["2024"],
    "1",
  ],
  [
    "self_currLiveSituationComment",
    "currentlyLiveSituation.comment",
    ["2024"],
    "1",
  ],

  ["self_currentLiveHome", "currentLive.home", ["2023", "2024"], "2"],
  ["self_currentLiveApart", "currentLive.apartment", ["2023", "2024"], "2"],
  [
    "self_currentLiveAssisLive",
    "currentLive.assistedLiving",
    ["2023", "2024"],
    "2",
  ],
  ["self_currentLiveNursing", "currentLive.nursingHome", ["2023", "2024"], "2"],
  ["self_currentLiveHomeless", "currentLive.homeless", ["2023", "2024"], "2"],
  ["self_currentLiveOther", "currentLive.other", ["2023", "2024"], "2"],
  [
    "self_currentLiveHomelessSubDesc",
    "currentLive.homelessSubAnswer.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "self_currentLiveOtherSubDesc",
    "currentLive.otherSubAnswer.describe",
    ["2023", "2024"],
    "1",
  ],
  ["self_currentLiveComment", "currentLive.comment", ["2023", "2024"], "1"],
  ["self_relyOn", "relyOn.isRelyOn", ["2023"], "3"],
  ["self_relyOnComment", "relyOn.comment", ["2023"], "1"],

  ["self_thinkPlaceBugs", "thinkAboutPlace.bugsAntsMice", ["2024"], "2"],
  ["self_thinkPlaceMold", "thinkAboutPlace.mold", ["2024"], "2"],
  ["self_thinkPlaceLead", "thinkAboutPlace.leadPaintOrPipes", ["2024"], "2"],
  ["self_thinkPlaceLackHeat", "thinkAboutPlace.lackOfHeat", ["2024"], "2"],
  ["self_thinkPlaceOven", "thinkAboutPlace.ovenOrStove", ["2024"], "2"],
  ["self_thinkPlaceWaterLeak", "thinkAboutPlace.waterLeaks", ["2024"], "2"],
  ["self_thinkPlaceNone", "thinkAboutPlace.none", ["2024"], "2"],
  ["self_thinkPlaceComment", "thinkAboutPlace.comment", ["2024"], "1"],

  [
    "self_currentlyLiveAlone",
    "currentlyLivingWith.alone",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLiveSpouse",
    "currentlyLivingWith.spouse",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLivePartner",
    "currentlyLivingWith.partner",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLiveRelative",
    "currentlyLivingWith.relative",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLiveFamily",
    "currentlyLivingWith.family",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLiveFriend",
    "currentlyLivingWith.friend",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLivePersCareWork",
    "currentlyLivingWith.personalCareWorker",
    ["2023", "2024"],
    "2",
  ],
  [
    "self_currentlyLiveAloneSubDesc",
    "currentlyLivingWith.aloneSubAnswer.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "self_currentlyLiveComment",
    "currentlyLivingWith.comment",
    ["2023", "2024"],
    "1",
  ],
  ["self_last12Months", "lastTwelveMonths.isLastTwelveMonths", ["2024"], "3"],
  [
    "self_last12MonthsSubDesc",
    "lastTwelveMonths.lastTwelveMonthsSubAnswers.lastTwelveMonthSelect",
    ["2024"],
    "1",
  ],
  [
    "self_last12MonthsSubDescComment",
    "lastTwelveMonths.lastTwelveMonthsSubAnswers.comment",
    ["2024"],
    "1",
  ],
  ["self_last12MonthsComment", "lastTwelveMonths.comment", ["2024"], "1"],

  ["self_dailyLiving", "dailyLiving.isDailyLiving", ["2024"], "3"],
  ["self_dailyLivingComment", "dailyLiving.comment", ["2024"], "1"],

  ["self_careGiver", "careGiver.isCareGiver", ["2023"], "3"],
  [
    "self_careGiverSubDesc",
    "careGiver.isCareGiverSubAnswer.describe",
    ["2023"],
    "1",
  ],
  ["self_careGiverComment", "careGiver.comment", ["2023"], "1"],
  [
    "self_currentlyEmploy",
    "currentlyEmployed.isCurrentlyEmployed",
    ["2023"],
    "3",
  ],
  ["self_currentlyEmployComment", "currentlyEmployed.comment", ["2023"], "1"],
  [
    "self_interestEmploy",
    "interestedEmployment.isInterestedEmployment",
    ["2023"],
    "3",
  ],
  ["self_interestEmployComment", "interestedEmployment.comment", ["2023"], "1"],
  [
    "self_volunCurrently",
    "volunteerCurrently.isVolunteerCurrently",
    ["2023"],
    "3",
  ],
  ["self_volunCurrentlyComment", "volunteerCurrently.comment", ["2023"], "1"],
  // [
  //   "self_currentlyEmploy",
  //   "employement.currentlyEmployed.isCurrentlyEmployed",
  //   ["2024"],
  //   "3",
  // ],
  // [
  //   "self_currentlyEmployComment",
  //   "employement.currentlyEmployed.comment",
  //   ["2024"],
  //   "1",
  // ],
  // [
  //   "self_interestEmploy",
  //   "employement.interestedEmployment.isInterestedEmployment",
  //   ["2024"],
  //   "3",
  // ],
  // [
  //   "self_interestEmployComment",
  //   "employement.interestedEmployment.comment",
  //   ["2024"],
  //   "1",
  // ],
  // [
  //   "self_volunCurrently",
  //   "employement.volunteerCurrently.isVolunteerCurrently",
  //   ["2024"],
  //   "3",
  // ],
  // [
  //   "self_volunCurrentlyComment",
  //   "employement.volunteerCurrently.comment",
  //   ["2024"],
  //   "1",
  // ],
  ["self_tobUseSubCurr", "tobaccoUse.current", ["2023"], "2"],
  ["self_tobUseSubFormer", "tobaccoUse.former", ["2023"], "2"],
  ["self_tobUseSubNever", "tobaccoUse.never", ["2023"], "2"],
  ["self_tobUseComment", "tobaccoUse.comment", ["2023"], "1"],
  [
    "self_tobUseSubCurrSubTypeCigarette",
    "tobaccoUse.currentSubAnswer.type.cigarettes",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigars",
    "tobaccoUse.currentSubAnswer.type.cigars",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeChewTobacco",
    "tobaccoUse.currentSubAnswer.type.chewingTobacco",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeVaping",
    "tobaccoUse.currentSubAnswer.type.vaping",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeOther",
    "tobaccoUse.currentSubAnswer.type.other",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeOtherSubDesc",
    "tobaccoUse.currentSubAnswer.type.otherSubAnswer.describe",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeComment",
    "tobaccoUse.currentSubAnswer.type.comment",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOneToThree",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.oneToThree",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyHalf",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.halfAPack",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOnePack",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.onePack",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyMoreOne",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.moreThanOne",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOther",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.other",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.otherSubAnswer.describe",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyComment",
    "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.comment",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubSmokeCess",
    "tobaccoUse.currentSubAnswer.smokingCessation.smokingCessation",
    ["2023"],
    "7",
  ],
  [
    "self_tobUseSubCurrSubSmokeCessComment",
    "tobaccoUse.currentSubAnswer.smokingCessation.comment",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubWhenSubLast",
    "tobaccoUse.formerSubAnswer.when.withinLastYear",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenSubLastThree",
    "tobaccoUse.formerSubAnswer.when.withinLastThreeYear",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenSubMoreThree",
    "tobaccoUse.formerSubAnswer.when.moreYearAgo",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenComment",
    "tobaccoUse.formerSubAnswer.when.comment",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigarette",
    "tobaccoUse.formerSubAnswer.type.cigarettes",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigars",
    "tobaccoUse.formerSubAnswer.type.cigars",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeChewTobacco",
    "tobaccoUse.formerSubAnswer.type.chewingTobacco",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeVaping",
    "tobaccoUse.formerSubAnswer.type.vaping",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeOther",
    "tobaccoUse.formerSubAnswer.type.other",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeOtherSubDesc",
    "tobaccoUse.formerSubAnswer.type.otherSubAnswer.describe",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeComment",
    "tobaccoUse.formerSubAnswer.type.comment",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.oneToThree",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.halfAPack",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.onePack",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.moreThanOne",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOther",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.other",
    ["2023"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.otherSubAnswer.describe",
    ["2023"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyComment",
    "tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.comment",
    ["2023"],
    "1",
  ],
  ["self_tobUseSubCurr", "substanceUse.tobaccoUse.current", ["2024"], "2"],
  ["self_tobUseSubFormer", "substanceUse.tobaccoUse.former", ["2024"], "2"],
  ["self_tobUseSubNever", "substanceUse.tobaccoUse.never", ["2024"], "2"],
  ["self_tobUseComment", "substanceUse.tobaccoUse.comment", ["2024"], "1"],
  [
    "self_tobUseSubCurrSubTypeCigarette",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettes",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigars",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigars",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeChewTobacco",
    "substanceUse.tobaccoUse.currentSubAnswer.type.chewingTobacco",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeVaping",
    "substanceUse.tobaccoUse.currentSubAnswer.type.vaping",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeOther",
    "substanceUse.tobaccoUse.currentSubAnswer.type.other",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeOtherSubDesc",
    "substanceUse.tobaccoUse.currentSubAnswer.type.otherSubAnswer.describe",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeComment",
    "substanceUse.tobaccoUse.currentSubAnswer.type.comment",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOneToThree",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.oneToThree",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyHalf",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.halfAPack",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOnePack",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.onePack",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyMoreOne",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.moreThanOne",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOther",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.other",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.otherSubAnswer.describe",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowManyComment",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.comment",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubSmokeCess",
    "substanceUse.tobaccoUse.currentSubAnswer.smokingCessation.smokingCessation",
    ["2024"],
    "7",
  ],
  [
    "self_tobUseSubCurrSubSmokeCessComment",
    "substanceUse.tobaccoUse.currentSubAnswer.smokingCessation.comment",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubWhenSubLast",
    "substanceUse.tobaccoUse.formerSubAnswer.when.withinLastYear",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenSubLastThree",
    "substanceUse.tobaccoUse.formerSubAnswer.when.withinLastThreeYear",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenSubMoreThree",
    "substanceUse.tobaccoUse.formerSubAnswer.when.moreYearAgo",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubWhenComment",
    "substanceUse.tobaccoUse.formerSubAnswer.when.comment",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigarette",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettes",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigars",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigars",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeChewTobacco",
    "substanceUse.tobaccoUse.formerSubAnswer.type.chewingTobacco",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeVaping",
    "substanceUse.tobaccoUse.formerSubAnswer.type.vaping",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeOther",
    "substanceUse.tobaccoUse.formerSubAnswer.type.other",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeOtherSubDesc",
    "substanceUse.tobaccoUse.formerSubAnswer.type.otherSubAnswer.describe",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeComment",
    "substanceUse.tobaccoUse.formerSubAnswer.type.comment",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.oneToThree",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.halfAPack",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.onePack",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.moreThanOne",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOther",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.other",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.otherSubAnswer.describe",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowManyComment",
    "substanceUse.tobaccoUse.formerSubAnswer.type.cigarettesSubAnswer.comment",
    ["2024"],
    "1",
  ],

  [
    "self_tobUseSubCurrSubTypeCigaretteSubInterestSmoke",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.interestedInSmoking.interestedInSmoking",
    ["2024"],
    "1",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubInterestSmokeComment",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.interestedInSmoking.comment",
    ["2024"],
    "1",
  ],
  ["self_currentlyEmployed", "employement.employmentStatus", ["2024"], "1"],
  ["self_currentlyEmployedComment", "employement.comment", ["2024"], "1"],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEduNeeded",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.educationNeeded",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSocial",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.socialSupport",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEnvFactors",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.environmentalFactors",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventStress",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.stress",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSelfConf",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.selfConfidence",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventLackMotive",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.lackOfMotivation",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNotPriority",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.notAPriority",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventOther",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.other",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNone",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.none",
    ["2024"],
    "2",
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventComment",
    "substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer.thingsPreventing.comment",
    ["2024"],
    "1",
  ],

  ["self_alcUseCurr", "alcoholUse.current", ["2023"], "2"],
  ["self_alcUseFormer", "alcoholUse.former", ["2023"], "2"],
  ["self_alcUseNever", "alcoholUse.never", ["2023"], "2"],
  ["self_alcUseComment", "alcoholUse.comment", ["2023"], "1"],
  [
    "self_alcUseCurrSubHowMany",
    "alcoholUse.currentSubAnswer[0].howManyDrinks",
    ["2023"],
    "1",
  ],
  [
    "self_alcUseCurrSubHowOften",
    "alcoholUse.currentSubAnswer[0].howOften",
    ["2023"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowMany",
    "alcoholUse.formerSubAnswer[0].howManyDrinks",
    ["2023"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowOften",
    "alcoholUse.formerSubAnswer[0].howOften",
    ["2023"],
    "1",
  ],

  ["self_alcUseCurr", "substanceUse.alcoholUse.current", ["2024"], "2"],
  ["self_alcUseFormer", "substanceUse.alcoholUse.former", ["2024"], "2"],
  ["self_alcUseNever", "substanceUse.alcoholUse.never", ["2024"], "2"],
  ["self_alcUseComment", "substanceUse.alcoholUse.comment", ["2024"], "1"],
  [
    "self_alcUseCurrSubHowManyNone",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.isNone",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMore",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMore",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyComment",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubWeeklyAverage",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.weeklyAverage",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubAuditScore",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.auditScore",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubScoreComment",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubWeeklyAverage",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.weeklyAverage",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubAuditScore",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.auditScore",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubScoreComment",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenNever",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.never",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenMonthly",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.monthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceMonth",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.twoTimesMonth",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceWeek",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.twoTimesWeek",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenFourWeek",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.fourTimesWeek",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOftenComment",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.comment",
    ["2024"],
    "1",
  ],

  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManyOne",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.oneOrTwo",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManyThree",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.threeOrFour",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManyFive",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.fiveOrSix",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManySeven",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.sevenEight",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManyTen",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.tenOrMore",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowManyComment",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.comment",
    ["2024"],
    "1",
  ],

  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreOne",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.never",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreThree",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.lessThanMonthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreFive",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.monthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreSeven",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.weekly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreTen",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.daily",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMoreComment",
    "substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseCurrSubCurrRecovery",
    "substanceUse.alcoholUse.currentSubAnswer.currentlyInRecovery.isCurrentlyInRecovery",
    ["2024"],
    "3",
  ],
  [
    "self_alcUseCurrSubCurrRecoveryComment",
    "substanceUse.alcoholUse.currentSubAnswer.currentlyInRecovery.comment",
    ["2024"],
    "1",
  ],

  [
    "self_alcUseFormerSubHowManyNone",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.isNone",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMore",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMore",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyComment",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenNever",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.never",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenMonthly",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.monthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceMonth",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.twoTimesMonth",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceWeek",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.twoTimesWeek",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenFourWeek",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.fourTimesWeek",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOftenComment",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften.comment",
    ["2024"],
    "1",
  ],

  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManyOne",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.oneOrTwo",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManyThree",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.threeOrFour",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManyFive",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.fiveOrSix",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManySeven",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.sevenEight",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManyTen",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.tenOrMore",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowManyComment",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howManyDrinks.comment",
    ["2024"],
    "1",
  ],

  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreOne",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.never",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreThree",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.lessThanMonthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreFive",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.monthly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreSeven",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.weekly",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreTen",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.daily",
    ["2024"],
    "2",
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMoreComment",
    "substanceUse.alcoholUse.formerSubAnswer.howMnyTimes.oneOrMoreSubAnswers.sixOrMore.comment",
    ["2024"],
    "1",
  ],
  [
    "self_alcUseFormerSubCurrRecovery",
    "substanceUse.alcoholUse.formerSubAnswer.currentlyInRecovery.isCurrentlyInRecovery",
    ["2024"],
    "3",
  ],
  [
    "self_alcUseFormerSubCurrRecoveryComment",
    "substanceUse.alcoholUse.formerSubAnswer.currentlyInRecovery.comment",
    ["2024"],
    "1",
  ],

  ["self_recreaDrugs", "recreationalDrugs.isRecreationalDrugs", ["2023"], "3"],
  [
    "self_recreaDrugsSubWhich",
    "recreationalDrugs.isRecreationalDrugsSubAnswer.whichDrugs",
    ["2023"],
    "1",
  ],
  ["self_recreaDrugsComment", "recreationalDrugs.comment", ["2023"], "1"],
  [
    "self_educatedOnAlcoholUse",
    "substanceUse.educationProvided.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "self_educatedOnAlcoholUseComment",
    "substanceUse.educationProvided.comment",
    ["2024"],
    "1",
  ],
  ["self_healthProxy", "healthCareProxy.isHealthCareProxy", ["2023"], "6"],
  [
    "self_healthProxySubName",
    "healthCareProxy.isHealthCareProxySubAnswer.name",
    ["2023"],
    "1",
  ],
  [
    "self_healthProxySubRelation",
    "healthCareProxy.isHealthCareProxySubAnswer.relationship",
    ["2023"],
    "1",
  ],
  ["self_healthProxyComment", "healthCareProxy.comment", ["2023"], "1"],
  ["self_powerAttny", "powerAttorney.isPowerAttorney", ["2023"], "6"],
  [
    "self_powerAttnySubName",
    "powerAttorney.isPowerAttorneySubAnswer.name",
    ["2023"],
    "1",
  ],
  [
    "self_powerAttnySubRelation",
    "powerAttorney.isPowerAttorneySubAnswer.relationship",
    ["2023"],
    "1",
  ],
  ["self_powerAttnyComment", "powerAttorney.comment", ["2023"], "1"],
  ["self_advDirect", "advanceDirective.isAdvanceDirective", ["2023"], "6"],
  [
    "self_advDirectSubKept",
    "advanceDirective.isAdvanceDirectiveSubAnswer.kept",
    ["2023"],
    "1",
  ],
  ["self_advDirectComment", "advanceDirective.comment", ["2023"], "1"],
  [
    "self_eduAdvPlan",
    "educatedOnAdvanceCarePlanning.isEducatedOnAdvanceCarePlanning",
    ["2023"],
    "7",
  ],
  [
    "self_eduAdvPlanComment",
    "educatedOnAdvanceCarePlanning.comment",
    ["2023"],
    "1",
  ],
  [
    "self_declineDisc",
    "declinesDiscussion.isDeclinesDiscussion",
    ["2023"],
    "7",
  ],
  ["self_declineDiscComment", "declinesDiscussion.comment", ["2023"], "1"],
  [
    "self_healthProxy",
    "advanceCarePlanning.healthCareProxy.isHealthCareProxy",
    ["2024"],
    "6",
  ],
  [
    "self_healthProxySubName",
    "advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer.name",
    ["2024"],
    "1",
  ],
  [
    "self_healthProxySubRelation",
    "advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer.relationship",
    ["2024"],
    "1",
  ],
  [
    "self_healthProxyComment",
    "advanceCarePlanning.healthCareProxy.comment",
    ["2024"],
    "1",
  ],
  [
    "self_powerAttny",
    "advanceCarePlanning.powerAttorney.isPowerAttorney",
    ["2024"],
    "6",
  ],
  [
    "self_powerAttnySubName",
    "advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer.name",
    ["2024"],
    "1",
  ],
  [
    "self_powerAttnySubRelation",
    "advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer.relationship",
    ["2024"],
    "1",
  ],
  [
    "self_powerAttnyComment",
    "advanceCarePlanning.powerAttorney.comment",
    ["2024"],
    "1",
  ],
  [
    "self_advDirect",
    "advanceCarePlanning.advanceDirective.isAdvanceDirective",
    ["2024"],
    "6",
  ],
  [
    "self_advDirectSubKept",
    "advanceCarePlanning.advanceDirective.isAdvanceDirectiveSubAnswer.kept",
    ["2024"],
    "1",
  ],
  [
    "self_advDirectComment",
    "advanceCarePlanning.advanceDirective.comment",
    ["2024"],
    "1",
  ],
  [
    "self_eduAdvPlan",
    "advanceCarePlanning.educatedOnAdvanceCarePlanning.isEducatedOnAdvanceCarePlanning",
    ["2024"],
    "7",
  ],
  [
    "self_eduAdvPlanComment",
    "advanceCarePlanning.educatedOnAdvanceCarePlanning.comment",
    ["2024"],
    "1",
  ],
  [
    "self_declineDisc",
    "advanceCarePlanning.declinesDiscussion.isDeclinesDiscussion",
    ["2024"],
    "7",
  ],
  [
    "self_declineDiscComment",
    "advanceCarePlanning.declinesDiscussion.comment",
    ["2024"],
    "1",
  ],

  ["self_foodRunOutOftenTrue", "foodWouldRunOut.oftenTrue", ["2023"], "2"],
  ["self_foodRunOutSomeTrue", "foodWouldRunOut.sometimesTrue", ["2023"], "2"],
  ["self_foodRunOutNeverTrue", "foodWouldRunOut.neverTrue", ["2023"], "2"],
  ["self_foodRunOutComment", "foodWouldRunOut.comment", ["2023"], "1"],

  ["self_foodRunOutOftenTrue", "food.foodWouldRunOut.oftenTrue", ["2024"], "2"],
  [
    "self_foodRunOutSomeTrue",
    "food.foodWouldRunOut.sometimesTrue",
    ["2024"],
    "2",
  ],
  ["self_foodRunOutNeverTrue", "food.foodWouldRunOut.neverTrue", ["2024"], "2"],
  [
    "self_foodRunOutOftenTrueSubSelectFoodWeBought",
    "food.foodWouldRunOut.oftenTrueSubAnswers.foodWeBought",
    ["2024"],
    "2",
  ],
  [
    "self_foodRunOutOftenTrueSubSelectDontHaveMoney",
    "food.foodWouldRunOut.oftenTrueSubAnswers.dontHaveMoney",
    ["2024"],
    "2",
  ],
  [
    "self_foodRunOutOftenTrueSubSelectComment",
    "food.foodWouldRunOut.oftenTrueSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "self_foodRunOutSomeTrueSubSelectFoodWeBought",
    "food.foodWouldRunOut.sometimesTrueSubAnswers.foodWeBought",
    ["2024"],
    "2",
  ],
  [
    "self_foodRunOutSomeTrueSubSelectDontHaveMoney",
    "food.foodWouldRunOut.sometimesTrueSubAnswers.dontHaveMoney",
    ["2024"],
    "2",
  ],
  [
    "self_foodRunOutSomeTrueSubSelectComment",
    "food.foodWouldRunOut.sometimesTrueSubAnswers.comment",
    ["2024"],
    "1",
  ],
  ["self_foodRunOutComment", "food.foodWouldRunOut.comment", ["2024"], "1"],
  ["self_foodBoughtOftenTrue", "foodWeBought.oftenTrue", ["2023"], "2"],
  ["self_foodBoughtSomeTrue", "foodWeBought.sometimesTrue", ["2023"], "2"],
  ["self_foodBoughtNeverTrue", "foodWeBought.neverTrue", ["2023"], "2"],

  ["self_foodBoughtComment", "foodWeBought.comment", ["2023"], "1"],
  [
    "self_recoSmokTobacco",
    "recommendations.recommendationsSubAnswer[0].smokingOrTobacco",
    ["2023"],
    "7",
  ],
  [
    "self_recoSusAbuse",
    "recommendations.recommendationsSubAnswer[1].substanceAbuse",
    ["2023"],
    "7",
  ],
  [
    "self_recoDurPower",
    "recommendations.recommendationsSubAnswer[2].durablePower",
    ["2023"],
    "7",
  ],
  [
    "self_recoHealthProxy",
    "recommendations.recommendationsSubAnswer[3].healthCareProxy",
    ["2023"],
    "7",
  ],
  [
    "self_recoAdvDir",
    "recommendations.recommendationsSubAnswer[4].advancedDirective",
    ["2023"],
    "7",
  ],
  [
    "self_recoFoodDisp",
    "recommendations.recommendationsSubAnswer[5].foodDisparity",
    ["2023"],
    "7",
  ],
  [
    "self_recoLiteracy",
    "recommendations.recommendationsSubAnswer[6].literacy",
    ["2023"],
    "7",
  ],
  [
    "self_recoSocSuprt",
    "recommendations.recommendationsSubAnswer[7].socialSupport",
    ["2023"],
    "7",
  ],
  [
    "self_RecommendationssmokingOrTobaccoComment",
    "recommendations.recommendationsSubAnswer[0].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationssubstanceAbuseComment",
    "recommendations.recommendationsSubAnswer[1].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationsdurablePowerComment",
    "recommendations.recommendationsSubAnswer[2].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationshealthcareProxyComment",
    "recommendations.recommendationsSubAnswer[3].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationsadvancedDirectiveComment",
    "recommendations.recommendationsSubAnswer[4].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationsfoodDisparityComment",
    "recommendations.recommendationsSubAnswer[5].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationsliteracyComment",
    "recommendations.recommendationsSubAnswer[6].comment",
    ["2023"],
    "1",
  ],
  [
    "self_RecommendationssocialSupportComment",
    "recommendations.recommendationsSubAnswer[7].comment",
    ["2023"],
    "1",
  ],
  [
    "self_recoSmokTobacco",
    "recommendations.recommendationsSubAnswer[0].smokingOrTobacco",
    ["2024"],
    "7",
  ],
  [
    "self_recoSubUse",
    "recommendations.recommendationsSubAnswer[1].substanceUse",
    ["2024"],
    "7",
  ],
  [
    "self_recoFoodDisp",
    "recommendations.recommendationsSubAnswer[2].foodDisparity",
    ["2024"],
    "7",
  ],
  [
    "self_recoLiteracy",
    "recommendations.recommendationsSubAnswer[3].literacy",
    ["2024"],
    "7",
  ],
  [
    "self_recoSocSupp",
    "recommendations.recommendationsSubAnswer[4].socialSupport",
    ["2024"],
    "7",
  ],
  [
    "self_recoAdvCare",
    "recommendations.recommendationsSubAnswer[5].advanceCare",
    ["2024"],
    "7",
  ],
  [
    "self_recoTransport",
    "recommendations.recommendationsSubAnswer[6].transportation",
    ["2024"],
    "7",
  ],
  [
    "self_recoCaseManage",
    "recommendations.recommendationsSubAnswer[7].careManagement",
    ["2024"],
    "7",
  ],
  [
    "self_recoFollowup",
    "recommendations.recommendationsSubAnswer[8].followUp",
    ["2024"],
    "7",
  ],
  [
    "self_RecommendationssmokingOrTobaccoComment",
    "recommendations.recommendationsSubAnswer[0].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationssubstanceUseComment",
    "recommendations.recommendationsSubAnswer[1].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationsfoodDisparityComment",
    "recommendations.recommendationsSubAnswer[2].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationsliteracyComment",
    "recommendations.recommendationsSubAnswer[3].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationssocialSupportComment",
    "recommendations.recommendationsSubAnswer[4].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationsadvanceCareComment",
    "recommendations.recommendationsSubAnswer[5].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationstransportationComment",
    "recommendations.recommendationsSubAnswer[6].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationscareManagementComment",
    "recommendations.recommendationsSubAnswer[7].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationsfollowUpComment",
    "recommendations.recommendationsSubAnswer[8].comment",
    ["2024"],
    "1",
  ],
  [
    "self_RecommendationsComment",
    "recommendations.comment",
    ["2023", "2024"],
    "1",
  ],
];

const self_optionsTreeMappers = [
  [{ self_schoolThirdGrade: "lessThanThirdGrade" }, ["2023", "2024"]],
  [{ self_schoolEighthGrade: "lessThanEighthGrade" }, ["2023", "2024"]],
  [{ self_schoolLessTwelthGrade: "lessThanTwelthGrade" }, ["2023", "2024"]],
  [{ self_schoolCompTwelthGrade: "completedTwelthGrade" }, ["2023", "2024"]],
  [{ self_schoolAttendGradCollege: "attendedOrgraduated" }, ["2023", "2024"]],
  [{ self_writtenInfoVeryDifficult: "veryDifficult" }, ["2023"]],
  [{ self_writtenInfoSomewhatDifficult: "someWhatDifficult" }, ["2023"]],
  [{ self_writtenInfoEasy: "easy" }, ["2023"]],
  [{ self_writtenInfoVeryEasy: "veryEasy" }, ["2023"]],
  [{ self_presciptionBottleVeryDifficult: "veryDifficult" }, ["2023"]],
  [{ self_presciptionBottleSomewhatDifficult: "someWhatDifficult" }, ["2023"]],
  [{ self_presciptionBottleEasy: "easy" }, ["2023"]],
  [{ self_presciptionBottleVeryEasy: "veryEasy" }, ["2023"]],
  [{ self_fillingMedicalFormNotAtAllConfident: "notAtAllConfident" }, ["2023"]],
  [{ self_DifficultyReadUnderstandForms: "FillingOutMedicalForms" }, ["2024"]],
  [
    { self_DifficultyReadUnderstandPrescription: "prescriptionInstructions" },
    ["2024"],
  ],
  [{ self_DifficultyReadUnderstandProvider: "providerInstructions" }, ["2024"]],
  [
    { self_fillingMedicalFormNotVeryConfident: "notVeryConfident" },
    ["2023", "2024"],
  ],
  [{ self_fillingMedicalFormConfident: "confident" }, ["2023", "2024"]],
  [{ self_fillingMedicalFormVeryConfident: "veryConfident" }, ["2023", "2024"]],
  [{ self_ratYourHealthExcellent: "excellent" }, ["2023", "2024"]],
  [{ self_ratYourHealthGood: "good" }, ["2023", "2024"]],
  [{ self_ratYourHealthFair: "fair" }, ["2023", "2024"]],
  [{ self_ratYourHealthPoor: "poor" }, ["2023", "2024"]],
  [{ self_socialActivitiesOften: "often" }, ["2023", "2024"]],
  [{ self_socialActivitiesSometimes: "sometimes" }, ["2023", "2024"]],
  [{ self_socialActivitiesAlmostNever: "almostNever" }, ["2023", "2024"]],
  [{ self_socialActivitiesNever: "never" }, ["2023", "2024"]],
  [{ self_currentLiveHome: "home" }, ["2023", "2024"]],
  [{ self_currentLiveApart: "apartment" }, ["2023", "2024"]],
  [{ self_currentLiveAssisLive: "assistedLiving" }, ["2023", "2024"]],
  [{ self_currentLiveNursing: "nursingHome" }, ["2023", "2024"]],
  [{ self_currentLiveHomeless: "homeless" }, ["2023", "2024"]],
  [{ self_currentLiveOther: "other" }, ["2023", "2024"]],
  [{ self_thinkPlaceBugs: "bugsAntsMice" }, ["2024"]],
  [{ self_thinkPlaceMold: "mold" }, ["2024"]],
  [{ self_thinkPlaceLead: "leadPaintOrPipes" }, ["2024"]],
  [{ self_thinkPlaceLackHeat: "lackOfHeat" }, ["2024"]],
  [{ self_thinkPlaceOven: "ovenOrStove" }, ["2024"]],
  [{ self_thinkPlaceWaterLeak: "waterLeaks" }, ["2024"]],
  [{ self_thinkPlaceNone: "none" }, ["2024"]],
  [{ self_currentlyLiveAlone: "alone" }, ["2023", "2024"]],
  [{ self_currentlyLiveSpouse: "spouse" }, ["2023", "2024"]],
  [{ self_currentlyLivePartner: "partner" }, ["2023", "2024"]],
  [{ self_currentlyLiveRelative: "relative" }, ["2023", "2024"]],
  [{ self_currentlyLiveFamily: "family" }, ["2023", "2024"]],
  [{ self_currentlyLiveFriend: "friend" }, ["2023", "2024"]],
  [{ self_currentlyLivePersCareWork: "personalCareWorker" }, ["2023", "2024"]],
  [{ self_goOutOften: "often" }, ["2024"]],
  [{ self_goOutSometime: "sometimes" }, ["2024"]],
  [{ self_goOutAlmostNever: "almostNever" }, ["2024"]],
  [{ self_goOutNever: "never" }, ["2024"]],
  [{ self_eduGuideNone: "isNone" }, ["2024"]],
  [{ self_eduGuideOneMore: "oneOrMore" }, ["2024"]],
  [{ self_tobUseSubCurr: "current" }, ["2023", "2024"]],
  [{ self_tobUseSubFormer: "former" }, ["2023", "2024"]],
  [{ self_tobUseSubNever: "never" }, ["2023", "2024"]],
  [{ self_tobUseSubCurrSubTypeCigarette: "cigarettes" }, ["2023", "2024"]],
  [{ self_tobUseSubCurrSubTypeCigars: "cigars" }, ["2023", "2024"]],
  [
    { self_tobUseSubCurrSubTypeChewTobacco: "chewingTobacco" },
    ["2023", "2024"],
  ],
  [{ self_tobUseSubCurrSubTypeVaping: "vaping" }, ["2023", "2024"]],
  [{ self_tobUseSubCurrSubTypeOther: "other" }, ["2023", "2024"]],
  [
    { self_tobUseSubCurrSubTypeCigaretteSubHowManyOneToThree: "oneToThree" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubCurrSubTypeCigaretteSubHowManyHalf: "halfAPack" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubCurrSubTypeCigaretteSubHowManyOnePack: "onePack" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubCurrSubTypeCigaretteSubHowManyMoreOne: "moreThanOne" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubCurrSubTypeCigaretteSubHowManyOther: "other" },
    ["2023", "2024"],
  ],
  [{ self_tobUseSubFormerSubWhenSubLast: "withinLastYear" }, ["2023", "2024"]],
  [
    { self_tobUseSubFormerSubWhenSubLastThree: "withinLastThreeYear" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubFormerSubWhenSubMoreThree: "moreYearAgo" },
    ["2023", "2024"],
  ],
  [{ self_tobUseSubFormerSubTypeCigarette: "cigarettes" }, ["2023", "2024"]],
  [{ self_tobUseSubFormerSubTypeCigars: "cigars" }, ["2023", "2024"]],
  [
    { self_tobUseSubFormerSubTypeChewTobacco: "chewingTobacco" },
    ["2023", "2024"],
  ],
  [{ self_tobUseSubFormerSubTypeVaping: "vaping" }, ["2023", "2024"]],
  [{ self_tobUseSubFormerSubTypeOther: "other" }, ["2023", "2024"]],
  [
    { self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree: "oneToThree" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf: "halfAPack" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack: "onePack" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne: "moreThanOne" },
    ["2023", "2024"],
  ],
  [
    { self_tobUseSubFormerSubTypeCigaretteSubHowManyOther: "other" },
    ["2023", "2024"],
  ],
  [{ self_alcUseCurr: "current" }, ["2023", "2024"]],
  [{ self_alcUseFormer: "former" }, ["2023", "2024"]],
  [{ self_alcUseNever: "never" }, ["2023", "2024"]],
  [{ self_foodRunOutOftenTrue: "oftenTrue" }, ["2023", "2024"]],
  [{ self_foodRunOutSomeTrue: "sometimesTrue" }, ["2023", "2024"]],
  [{ self_foodRunOutNeverTrue: "neverTrue" }, ["2023", "2024"]],
  [{ self_foodBoughtOftenTrue: "oftenTrue" }, ["2023", "2024"]],
  [{ self_foodBoughtSomeTrue: "sometimesTrue" }, ["2023", "2024"]],
  [{ self_foodBoughtNeverTrue: "neverTrue" }, ["2023", "2024"]],
  [{ self_foodRunOutOftenTrueSubSelectFoodWeBought: "foodWeBought" }, ["2024"]],
  [
    { self_foodRunOutOftenTrueSubSelectDontHaveMoney: "dontHaveMoney" },
    ["2024"],
  ],
  [{ self_foodRunOutSomeTrueSubSelectFoodWeBought: "foodWeBought" }, ["2024"]],
  [
    { self_foodRunOutSomeTrueSubSelectDontHaveMoney: "dontHaveMoney" },
    ["2024"],
  ],
  [{ self_alcUseCurrSubHowManyNone: "isNone" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMore: "oneOrMore" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowOftenNever: "never" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowOftenMonthly: "monthly" }, ["2024"]],
  [
    { self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceMonth: "twoTimesMonth" },
    ["2024"],
  ],
  [
    { self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceWeek: "twoTimesWeek" },
    ["2024"],
  ],
  [
    { self_alcUseCurrSubHowManyOneMoreSubHowOftenFourWeek: "fourTimesWeek" },
    ["2024"],
  ],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowManyOne: "oneOrTwo" }, ["2024"]],
  [
    { self_alcUseCurrSubHowManyOneMoreSubHowManyThree: "threeOrFour" },
    ["2024"],
  ],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowManyFive: "fiveOrSix" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowManySeven: "sevenEight" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubHowManyTen: "tenOrMore" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubSixMoreOne: "never" }, ["2024"]],
  [
    { self_alcUseCurrSubHowManyOneMoreSubSixMoreThree: "lessThanMonthly" },
    ["2024"],
  ],
  [{ self_alcUseCurrSubHowManyOneMoreSubSixMoreFive: "monthly" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubSixMoreSeven: "weekly" }, ["2024"]],
  [{ self_alcUseCurrSubHowManyOneMoreSubSixMoreTen: "daily" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyNone: "isNone" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyOneMore: "oneOrMore" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyOneMoreSubHowOftenNever: "never" }, ["2024"]],
  [
    { self_alcUseFormerSubHowManyOneMoreSubHowOftenMonthly: "monthly" },
    ["2024"],
  ],
  [
    {
      self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceMonth: "twoTimesMonth",
    },
    ["2024"],
  ],
  [
    { self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceWeek: "twoTimesWeek" },
    ["2024"],
  ],
  [
    { self_alcUseFormerSubHowManyOneMoreSubHowOftenFourWeek: "fourTimesWeek" },
    ["2024"],
  ],
  [{ self_alcUseFormerSubHowManyOneMoreSubHowManyOne: "oneOrTwo" }, ["2024"]],
  [
    { self_alcUseFormerSubHowManyOneMoreSubHowManyThree: "threeOrFour" },
    ["2024"],
  ],
  [{ self_alcUseFormerSubHowManyOneMoreSubHowManyFive: "fiveOrSix" }, ["2024"]],
  [
    { self_alcUseFormerSubHowManyOneMoreSubHowManySeven: "sevenEight" },
    ["2024"],
  ],
  [{ self_alcUseFormerSubHowManyOneMoreSubHowManyTen: "tenOrMore" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyOneMoreSubSixMoreOne: "never" }, ["2024"]],
  [
    { self_alcUseFormerSubHowManyOneMoreSubSixMoreThree: "lessThanMonthly" },
    ["2024"],
  ],
  [{ self_alcUseFormerSubHowManyOneMoreSubSixMoreFive: "monthly" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyOneMoreSubSixMoreSeven: "weekly" }, ["2024"]],
  [{ self_alcUseFormerSubHowManyOneMoreSubSixMoreTen: "daily" }, ["2024"]],
  [
    {
      self_tobUseSubCurrSubTypeCigarette: "cigarettes",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigars: "cigars",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeChewTobacco: "chewingTobacco",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeVaping: "vaping",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeOther: "other",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigarette: "cigarettes",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigars: "cigars",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeChewTobacco: "chewingTobacco",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeVaping: "vaping",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeOther: "other",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree: "oneToThree",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf: "halfAPack",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack: "onePack",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne: "moreThanOne",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubFormerSubTypeCigaretteSubHowManyOther: "other",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEduNeeded:
        "educationNeeded",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSocial: "socialSupport",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEnvFactors:
        "environmentalFactors",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventStress: "stress",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSelfConf:
        "selfConfidence",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventLackMotive:
        "lackOfMotivation",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNotPriority:
        "notAPriority",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventOther: "other",
    },
    ["2024"],
  ],
  [
    {
      self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNone: "none",
    },
    ["2024"],
  ],
];

const self_optionsTreeWrappers = [
  [
    "self_school",
    [
      "self_schoolThirdGrade",
      "self_schoolEighthGrade",
      "self_schoolLessTwelthGrade",
      "self_schoolCompTwelthGrade",
      "self_schoolAttendGradCollege",
    ],
    ["2023", "2024"],
  ],
  [
    "self_writtenInfo",
    [
      "self_writtenInfoVeryDifficult",
      "self_writtenInfoSomewhatDifficult",
      "self_writtenInfoEasy",
      "self_writtenInfoVeryEasy",
    ],
    ["2023"],
  ],
  [
    "self_presciptionBottle",
    [
      "self_presciptionBottleVeryDifficult",
      "self_presciptionBottleSomewhatDifficult",
      "self_presciptionBottleEasy",
      "self_presciptionBottleVeryEasy",
    ],
    ["2023"],
  ],
  [
    "self_fillingMedicalForm",
    [
      "self_fillingMedicalFormNotAtAllConfident",
      "self_fillingMedicalFormNotVeryConfident",
      "self_fillingMedicalFormConfident",
      "self_fillingMedicalFormVeryConfident",
    ],
    ["2023"],
  ],
  [
    "self_DifficultyReadUnderstand",
    [
      "self_DifficultyReadUnderstandForms",
      "self_DifficultyReadUnderstandPrescription",
      "self_DifficultyReadUnderstandProvider",
    ],
    ["2024"],
  ],

  [
    "self_ratYourHealth",
    [
      "self_ratYourHealthExcellent",
      "self_ratYourHealthGood",
      "self_ratYourHealthFair",
      "self_ratYourHealthPoor",
    ],
    ["2023", "2024"],
  ],
  [
    "self_socialActivities",
    [
      "self_socialActivitiesOften",
      "self_socialActivitiesSometimes",
      "self_socialActivitiesAlmostNever",
      "self_socialActivitiesNever",
    ],
    ["2023", "2024"],
  ],
  [
    "self_currentLive",
    [
      "self_currentLiveHome",
      "self_currentLiveApart",
      "self_currentLiveAssisLive",
      "self_currentLiveNursing",
      "self_currentLiveHomeless",
      "self_currentLiveOther",
    ],
    ["2023", "2024"],
  ],
  [
    "self_thinkPlace",
    [
      "self_thinkPlaceBugs",
      "self_thinkPlaceMold",
      "self_thinkPlaceLead",
      "self_thinkPlaceLackHeat",
      "self_thinkPlaceOven",
      "self_thinkPlaceWaterLeak",
      "self_thinkPlaceNone",
    ],
    ["2024"],
  ],
  [
    "self_currentlyLive",
    [
      "self_currentlyLiveAlone",
      "self_currentlyLiveSpouse",
      "self_currentlyLivePartner",
      "self_currentlyLiveRelative",
      "self_currentlyLiveFamily",
      "self_currentlyLiveFriend",
      "self_currentlyLivePersCareWork",
    ],
    ["2023", "2024"],
  ],
  [
    "self_goOut",
    [
      "self_goOutOften",
      "self_goOutSometime",
      "self_goOutAlmostNever",
      "self_goOutNever",
    ],
    ["2024"],
  ],
  ["self_eduGuide", ["self_eduGuideNone", "self_eduGuideOneMore"], ["2024"]],
  [
    "self_tobUse",
    ["self_tobUseSubCurr", "self_tobUseSubFormer", "self_tobUseSubNever"],
    ["2023", "2024"],
  ],
  [
    "self_tobUseSubCurrSubType",
    [
      "self_tobUseSubCurrSubTypeCigarette",
      "self_tobUseSubCurrSubTypeCigars",
      "self_tobUseSubCurrSubTypeChewTobacco",
      "self_tobUseSubCurrSubTypeVaping",
      "self_tobUseSubCurrSubTypeOther",
    ],
    ["2023", "2024"],
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubHowMany",
    [
      "self_tobUseSubCurrSubTypeCigaretteSubHowManyOneToThree",
      "self_tobUseSubCurrSubTypeCigaretteSubHowManyHalf",
      "self_tobUseSubCurrSubTypeCigaretteSubHowManyOnePack",
      "self_tobUseSubCurrSubTypeCigaretteSubHowManyMoreOne",
      "self_tobUseSubCurrSubTypeCigaretteSubHowManyOther",
    ],
    ["2023", "2024"],
  ],
  [
    "self_tobUseSubFormerSubWhen",
    [
      "self_tobUseSubFormerSubWhenSubLast",
      "self_tobUseSubFormerSubWhenSubLastThree",
      "self_tobUseSubFormerSubWhenSubMoreThree",
    ],
    ["2023", "2024"],
  ],
  [
    "self_tobUseSubFormerSubType",
    [
      "self_tobUseSubFormerSubTypeCigarette",
      "self_tobUseSubFormerSubTypeCigars",
      "self_tobUseSubFormerSubTypeChewTobacco",
      "self_tobUseSubFormerSubTypeVaping",
      "self_tobUseSubFormerSubTypeOther",
    ],
    ["2023", "2024"],
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowMany",
    [
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOther",
    ],
    ["2023", "2024"],
  ],
  [
    "self_alcUse",
    ["self_alcUseCurr", "self_alcUseFormer", "self_alcUseNever"],
    ["2023", "2024"],
  ],
  [
    "self_foodRunOut",
    [
      "self_foodRunOutOftenTrue",
      "self_foodRunOutSomeTrue",
      "self_foodRunOutNeverTrue",
    ],
    ["2023", "2024"],
  ],
  [
    "self_foodBought",
    [
      "self_foodBoughtOftenTrue",
      "self_foodBoughtSomeTrue",
      "self_foodBoughtNeverTrue",
    ],
    ["2023", "2024"],
  ],
  [
    "self_foodRunOutOftenTrueSubSelect",
    [
      "self_foodRunOutOftenTrueSubSelectFoodWeBought",
      "self_foodRunOutOftenTrueSubSelectDontHaveMoney",
    ],
    ["2024"],
  ],
  [
    "self_foodRunOutSomeTrueSubSelect",
    [
      "self_foodRunOutSomeTrueSubSelectFoodWeBought",
      "self_foodRunOutSomeTrueSubSelectDontHaveMoney",
    ],
    ["2024"],
  ],
  [
    "self_alcUseCurrSubHowMany",
    ["self_alcUseCurrSubHowManyNone", "self_alcUseCurrSubHowManyOneMore"],
    ["2024"],
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowOften",
    [
      "self_alcUseCurrSubHowManyOneMoreSubHowOftenNever",
      "self_alcUseCurrSubHowManyOneMoreSubHowOftenMonthly",
      "self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceMonth",
      "self_alcUseCurrSubHowManyOneMoreSubHowOftenTwiceWeek",
      "self_alcUseCurrSubHowManyOneMoreSubHowOftenFourWeek",
    ],
    ["2024"],
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubHowMany",
    [
      "self_alcUseCurrSubHowManyOneMoreSubHowManyOne",
      "self_alcUseCurrSubHowManyOneMoreSubHowManyThree",
      "self_alcUseCurrSubHowManyOneMoreSubHowManyFive",
      "self_alcUseCurrSubHowManyOneMoreSubHowManySeven",
      "self_alcUseCurrSubHowManyOneMoreSubHowManyTen",
    ],
    ["2024"],
  ],
  [
    "self_alcUseCurrSubHowManyOneMoreSubSixMore",
    [
      "self_alcUseCurrSubHowManyOneMoreSubSixMoreOne",
      "self_alcUseCurrSubHowManyOneMoreSubSixMoreThree",
      "self_alcUseCurrSubHowManyOneMoreSubSixMoreFive",
      "self_alcUseCurrSubHowManyOneMoreSubSixMoreSeven",
      "self_alcUseCurrSubHowManyOneMoreSubSixMoreTen",
    ],
    ["2024"],
  ],
  [
    "self_alcUseFormerSubHowMany",
    ["self_alcUseFormerSubHowManyNone", "self_alcUseFormerSubHowManyOneMore"],
    ["2024"],
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowOften",
    [
      "self_alcUseFormerSubHowManyOneMoreSubHowOftenNever",
      "self_alcUseFormerSubHowManyOneMoreSubHowOftenMonthly",
      "self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceMonth",
      "self_alcUseFormerSubHowManyOneMoreSubHowOftenTwiceWeek",
      "self_alcUseFormerSubHowManyOneMoreSubHowOftenFourWeek",
    ],
    ["2024"],
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubHowMany",
    [
      "self_alcUseFormerSubHowManyOneMoreSubHowManyOne",
      "self_alcUseFormerSubHowManyOneMoreSubHowManyThree",
      "self_alcUseFormerSubHowManyOneMoreSubHowManyFive",
      "self_alcUseFormerSubHowManyOneMoreSubHowManySeven",
      "self_alcUseFormerSubHowManyOneMoreSubHowManyTen",
    ],
    ["2024"],
  ],
  [
    "self_alcUseFormerSubHowManyOneMoreSubSixMore",
    [
      "self_alcUseFormerSubHowManyOneMoreSubSixMoreOne",
      "self_alcUseFormerSubHowManyOneMoreSubSixMoreThree",
      "self_alcUseFormerSubHowManyOneMoreSubSixMoreFive",
      "self_alcUseFormerSubHowManyOneMoreSubSixMoreSeven",
      "self_alcUseFormerSubHowManyOneMoreSubSixMoreTen",
    ],
    ["2024"],
  ],
  [
    "self_tobUseSubCurrSubType",
    [
      "self_tobUseSubCurrSubTypeCigarette",
      "self_tobUseSubCurrSubTypeCigars",
      "self_tobUseSubCurrSubTypeChewTobacco",
      "self_tobUseSubCurrSubTypeVaping",
      "self_tobUseSubCurrSubTypeOther",
    ],
    ["2024"],
  ],
  [
    "self_tobUseSubFormerSubType",
    [
      "self_tobUseSubFormerSubTypeCigarette",
      "self_tobUseSubFormerSubTypeCigars",
      "self_tobUseSubFormerSubTypeChewTobacco",
      "self_tobUseSubFormerSubTypeVaping",
      "self_tobUseSubFormerSubTypeOther",
    ],
    ["2024"],
  ],
  [
    "self_tobUseSubFormerSubTypeCigaretteSubHowMany",
    [
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOneToThree",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyHalf",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOnePack",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyMoreOne",
      "self_tobUseSubFormerSubTypeCigaretteSubHowManyOther",
    ],
    ["2024"],
  ],
  [
    "self_tobUseSubCurrSubTypeCigaretteSubThingsPrevent",
    [
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEduNeeded",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSocial",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventEnvFactors",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventStress",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventSelfConf",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventLackMotive",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNotPriority",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventOther",
      "self_tobUseSubCurrSubTypeCigaretteSubThingsPreventNone",
    ],
    ["2024"],
  ],
];

export const getSelfAssessData = (apiData, year) => {
  let nestedData = {};
  let flattenData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = getObjectData(apiData, questionPair[1]);

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedData, pointerLabels[1], year) || "";
  });

  const self_eduAdvPlan =
    miscData["self_eduAdvPlan"] === "1"
      ? ["educatedOnAdvanceCarePlanning"]
      : [];

  const self_declineDisc =
    miscData["self_declineDisc"] === "1" ? ["declinesDiscussion"] : [];

  let self_Recommendations = {};
  if (year === "2023") {
    self_Recommendations = getSelectedCheckboxes(
      [
        miscData["self_recoSmokTobacco"],
        miscData["self_recoSusAbuse"],
        miscData["self_recoDurPower"],
        miscData["self_recoHealthProxy"],
        miscData["self_recoAdvDir"],
        miscData["self_recoFoodDisp"],
        miscData["self_recoLiteracy"],
        miscData["self_recoSocSuprt"],
      ],
      [
        "smokingOrTobacco",
        "substanceAbuse",
        "durablePower",
        "healthcareProxy",
        "advancedDirective",
        "foodDisparity",
        "literacy",
        "socialSupport",
      ],
    );
  } else if (year === "2024") {
    self_Recommendations = getSelectedCheckboxes(
      [
        miscData["self_recoSmokTobacco"],
        miscData["self_recoSubUse"],
        miscData["self_recoFoodDisp"],
        miscData["self_recoLiteracy"],
        miscData["self_recoSocSupp"],
        miscData["self_recoAdvCare"],
        miscData["self_recoTransport"],
        miscData["self_recoCaseManage"],
        miscData["self_recoFollowup"],
      ],
      [
        "smokingOrTobacco",
        "substanceUse",
        "foodDisparity",
        "literacy",
        "socialSupport",
        "advanceCare",
        "transportation",
        "careManagement",
        "followUp",
      ],
    );
  }

  const self_tobUseSubCurrSubSmokeCess =
    miscData["self_tobUseSubCurrSubSmokeCess"] === "1"
      ? ["smokingCessation"]
      : [];

  const self_educatedOnAlcoholUse =
    miscData["self_educatedOnAlcoholUse"] === "1"
      ? ["educatedOnAlcoholUse"]
      : [];

  const self_eduGuideOneMoreSubEduResource =
    miscData["self_eduGuideOneMoreSubEduResource"] === "1"
      ? ["educatedOnRecDrugsUse"]
      : [];

  flattenData = {
    ...flattenData,
    self_eduAdvPlan,
    self_declineDisc,
    self_Recommendations,
    self_tobUseSubCurrSubSmokeCess,
    self_educatedOnAlcoholUse,
    self_eduGuideOneMoreSubEduResource,
  };

  return flattenData;
};

export const setSelfAssessData = (formData, year) => {
  let flattenData = {};
  let nestedData = {};
  let payloadData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = formData[quesUIVariable] || "";

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  const self_eduAdvPlanSelected = formData["self_eduAdvPlan"] || [];
  const self_eduAdvPlan = self_eduAdvPlanSelected?.includes(
    "educatedOnAdvanceCarePlanning",
  )
    ? "1"
    : "";

  const self_declineDiscSelected = formData["self_declineDisc"] || [];
  const self_declineDisc = self_declineDiscSelected?.includes(
    "declinesDiscussion",
  )
    ? "1"
    : "";

  const self_tobUseSubCurrSubSmokeCessSelected =
    formData["self_tobUseSubCurrSubSmokeCess"] || [];
  const self_tobUseSubCurrSubSmokeCess =
    self_tobUseSubCurrSubSmokeCessSelected?.includes("smokingCessation")
      ? "1"
      : "";

  const self_educatedOnAlcoholUseSelected =
    formData["self_educatedOnAlcoholUse"] || [];
  const self_educatedOnAlcoholUse = self_educatedOnAlcoholUseSelected?.includes(
    "educatedOnAlcoholUse",
  )
    ? "1"
    : "";

  const self_eduGuideOneMoreSubEduResourceSelected =
    formData["self_eduGuideOneMoreSubEduResource"] || [];
  const self_eduGuideOneMoreSubEduResource =
    self_eduGuideOneMoreSubEduResourceSelected?.includes(
      "educatedOnRecDrugsUse",
    )
      ? "1"
      : "";
  let self_Recommendations = {};
  if (year === "2023") {
    const self_alcUseCurrSubHowMany =
      flattenData?.["self_alcUseCurrSubHowMany"] &&
      flattenData?.["self_alcUseCurrSubHowMany"] !== "Select"
        ? flattenData?.["self_alcUseCurrSubHowMany"]
        : "";
    const self_alcUseCurrSubHowOften =
      flattenData?.["self_alcUseCurrSubHowOften"] &&
      flattenData?.["self_alcUseCurrSubHowOften"] !== "Select"
        ? flattenData?.["self_alcUseCurrSubHowOften"]
        : "";
    const self_alcUseFormerSubHowMany =
      flattenData?.["self_alcUseFormerSubHowMany"] &&
      flattenData?.["self_alcUseFormerSubHowMany"] !== "Select"
        ? flattenData?.["self_alcUseFormerSubHowMany"]
        : "";
    const self_alcUseFormerSubHowOften =
      flattenData?.["self_alcUseFormerSubHowOften"] &&
      flattenData?.["self_alcUseFormerSubHowOften"] !== "Select"
        ? flattenData?.["self_alcUseFormerSubHowOften"]
        : "";

    const self_RecommendationSelected = formData["self_Recommendations"] || [];
    self_Recommendations = setSelectedCheckboxes(
      self_RecommendationSelected,
      [
        "self_recoSmokTobacco",
        "self_recoSusAbuse",
        "self_recoDurPower",
        "self_recoHealthProxy",
        "self_recoAdvDir",
        "self_recoFoodDisp",
        "self_recoLiteracy",
        "self_recoSocSuprt",
      ],
      [
        "smokingOrTobacco",
        "substanceAbuse",
        "durablePower",
        "healthcareProxy",
        "advancedDirective",
        "foodDisparity",
        "literacy",
        "socialSupport",
      ],
    );

    flattenData = {
      ...flattenData,
      self_alcUseCurrSubHowMany,
      self_alcUseCurrSubHowOften,
      self_alcUseFormerSubHowMany,
      self_alcUseFormerSubHowOften,
    };
  } else if (year === "2024") {
    const self_RecommendationSelected = formData["self_Recommendations"] || [];
    self_Recommendations = setSelectedCheckboxes(
      self_RecommendationSelected,
      [
        "self_recoSmokTobacco",
        "self_recoSubUse",
        "self_recoFoodDisp",
        "self_recoLiteracy",
        "self_recoSocSupp",
        "self_recoAdvCare",
        "self_recoTransport",
        "self_recoCaseManage",
        "self_recoFollowup",
      ],
      [
        "smokingOrTobacco",
        "substanceUse",
        "foodDisparity",
        "literacy",
        "socialSupport",
        "advanceCare",
        "transportation",
        "careManagement",
        "followUp",
      ],
    );
  }

  flattenData = {
    ...flattenData,
    self_eduAdvPlan,
    self_declineDisc,
    ...self_Recommendations,
    self_tobUseSubCurrSubSmokeCess,
    self_educatedOnAlcoholUse,
    self_eduGuideOneMoreSubEduResource,
  };

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getFlatmapByYear = (year) => {
  let temp = [];
  self_flatmap.forEach((questionPair) => {
    const quesYear = questionPair?.[2] || ["2023"];
    if (quesYear.includes(year)) {
      temp.push(questionPair);
    }
  });

  return temp;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  self_optionsTreeMappers.forEach((mapper) => {
    const quesYear = mapper?.[1] || ["2023"];
    if (quesYear.includes(year)) {
      const quesMap = mapper?.[0];
      temp = { ...temp, ...quesMap };
    }
  });

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  self_optionsTreeWrappers.forEach((wrapper) => {
    const quesYear = wrapper?.[2] || 2023;
    if (quesYear.includes(year)) {
      const [quesWrapHead, quesWrapElements] = wrapper;
      temp.push([quesWrapHead, quesWrapElements]);
    }
  });

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = getOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = setOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  let getSubTreeRegex = getSubTreeString(
    optionName,
    optionValue,
    treeWrappers,
    treeMappers,
  );
  return getSubTreeRegex;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let updatedData = deleteSubTreeData(formData, subTreeRegex);
  return updatedData;
};
