import {
  deleteSubTreeData,
  getObjectData,
  getOptionsSelected,
  getOptionsSelectedYesNo,
  getOptionsSelectedYesNoDontKnow,
  getOptionsSelectedYesNoPartially,
  getOptionsSelectedYesNoUnknown,
  getSubTreeString,
  setObjectData,
  setOptionsSelected,
  setOptionsSelectedYesNo,
  setOptionsSelectedYesNoDontKnow,
  setOptionsSelectedYesNoPartially,
  setOptionsSelectedYesNoUnknown,
} from "../../../../../../utils/helpers.DynamicForm";

/*

============= FLATMAP =============

for get/set the nested level DB-JSON data back & forth using lodash
It's an array of arrays. Inner array has 3 values -> [ flatmap_value,db_json_path,year_list_array ]
<- example ->
[ home_fallenNoneVal, fallen.none, [2023,2024] ]

======= OPTIONS TREE MAPPER =======

for get/set the options tree selected value by comparing with formdata json
It's an object where key-value pair will be flatmap value-value to be assigned in options tree if it is "1"
it must match your options tree's corresponding toggle button's value field
<- example ->
{ home_fallenNone:none }

======= OPTIONS TREE WRAPPER =======

for populate the selected value(s) of an options tree by combining list of flatmap values.
It's an array of arrays. Inner array has 2 elements -> [grouped_string,[list_of_flattened_values_to_be_grouped]]
this grouped string must be assigned to your options tree's name field.

<- example ->
[
  "home_fallen",
  [
    "home_fallenNone",
    "home_fallenOnce",
    "home_fallenTwice",
    "home_fallenThree",
    "home_fallenMoreThree",
  ],
],

*/

const demographics_flatmap = [
  ["demograph_plan", "plan", ["2023", "2024"], "1"],
  ["demograph_program", "program", ["2023", "2024"], "1"],
  ["demograph_lob", "lob", ["2023", "2024"], "1"],
  ["demograph_region", "region", ["2023", "2024"], "1"],
  ["demograph_aligned", "aligned", ["2023", "2024"], "1"],
  ["demograph_firstName", "firstName", ["2023", "2024"], "1"],
  ["demograph_middleName", "middleName", ["2023", "2024"], "1"],
  ["demograph_lastName", "lastName", ["2023", "2024"], "1"],
  ["demograph_gender", "gender", ["2023"], "1"],
  ["demograph_genderAtBirth", "genderAtBirth", ["2024"], "1"],
  ["demograph_currentGender", "currentGenderIdentity", ["2024"], "1"],
  [
    "demograph_currentGenderComment",
    "currentGenderIdentityComment",
    ["2024"],
    "1",
  ],
  ["demograph_address", "address", ["2023", "2024"], "1"],
  ["demograph_addressTwo", "addressTwo", ["2023", "2024"], "1"],
  ["demograph_addressThree", "addressThree", ["2023", "2024"], "1"],
  ["demograph_city", "city", ["2023", "2024"], "1"],
  ["demograph_state", "state", ["2023", "2024"], "1"],
  ["demograph_county", "county", ["2023", "2024"], "1"],
  ["demograph_zip", "zip", ["2023", "2024"], "1"],
  ["demograph_mAddressOne", "mAddressOne", ["2023", "2024"], "1"],
  ["demograph_mAddTwo", "mAddTwo", ["2023", "2024"], "1"],
  ["demograph_mAddressThree", "mAddressThree", ["2023", "2024"], "1"],
  ["demograph_mCity", "mCity", ["2023", "2024"], "1"],
  ["demograph_mState", "mState", ["2023", "2024"], "1"],
  ["demograph_mZip", "mZip", ["2023", "2024"], "1"],
  ["demograph_mCounty", "mCounty", ["2023", "2024"], "1"],
  ["demograph_dob", "dob", ["2023", "2024"], "1"],
  ["demograph_mStatus", "mStatus", ["2023", "2024"], "1"],
  ["demograph_memberId", "memberId", ["2023", "2024"], "1"],
  ["demograph_medicaidId", "medicaidId", ["2024"], "1"],
  ["demograph_medicareId", "medicareId", ["2024"], "1"],
  ["demograph_uniqueId", "uniqueId", ["2024"], "1"],
  ["demograph_hicn", "hicn", ["2023"], "1"],
  ["demograph_email", "email", ["2023", "2024"], "1"],
  ["demograph_primary", "primary", ["2023", "2024"], "1"],
  ["demograph_secondary", "secondary", ["2023", "2024"], "1"],
  ["demograph_aContact", "aContact", ["2023", "2024"], "1"],
  ["demograph_ename", "ename", ["2023", "2024"], "1"],
  ["demograph_eNo", "eNo", ["2023", "2024"], "1"],
  // ["demograph_PrimaryCarePhy", "PrimaryCarePhy",["2023","2024"]],
  ["demograph_pName", "pName", ["2023", "2024"], "1"],
  ["demograph_pNameComment", "pNameComment", ["2024"], "1"],
  ["demograph_pPhone", "pPhone", ["2023", "2024"], "1"],
  ["demograph_pFax", "pFax", ["2023", "2024"], "1"],
  ["demograph_pFlag", "pFlag", ["2024"], "7"],
  // ["demograph_pSpeciality", "pSpeciality",["2023","2024"]],
  ["demograph_pAddOne", "pAddOne", ["2023", "2024"], "1"],
  // ["demograph_pAddTwo", "pAddTwo",["2023","2024"]],
  ["demograph_pCity", "pCity", ["2023", "2024"], "1"],
  ["demograph_pState", "pState", ["2023", "2024"], "1"],
  ["demograph_pZip", "pZip", ["2023", "2024"], "1"],
  ["demograph_pcpcounty", "pcpcounty", ["2023", "2024"], "1"],
  ["demograph_pcpNpi", "pcpNpi", ["2023", "2024"], "1"],
  ["demograph_pId", "pId", ["2023", "2024"], "1"],
  ["demograph_pOfcName", "pOfcName", ["2023", "2024"], "1"],
  ["demograph_raceCaucasian", "race.caucasian", ["2023", "2024"], "2"],
  [
    "demograph_raceAfricanAmerican",
    "race.africanAmerican",
    ["2023", "2024"],
    "2",
  ],
  ["demograph_raceAsian", "race.asian", ["2023", "2024"], "2"],
  ["demograph_raceLatino", "race.latino", ["2023", "2024"], "2"],
  [
    "demograph_raceNativeAmerican",
    "race.nativeAmerican",
    ["2023", "2024"],
    "2",
  ],
  ["demograph_raceNativeHawaiin", "race.nativeHawaiin", ["2023", "2024"], "2"],
  ["demograph_raceAlaskanNative", "race.alaskanNative", ["2023", "2024"], "2"],
  ["demograph_raceOther", "race.other", ["2023", "2024"], "2"],
  [
    "demograph_raceOtherSubDesc",
    "race.otherSubAnswer.describe",
    ["2023", "2024"],
    "1",
  ],
  ["demograph_raceComment", "race.comment", ["2023", "2024"], "1"],
  [
    "demograph_patientEthnicHispanic",
    "patientEthnicity.hispanic",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_patientEthnicNonHispanic",
    "patientEthnicity.nonHispanic",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_patientEthnicOther",
    "patientEthnicity.otherethnicity",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_patientEthnicPrefernot",
    "patientEthnicity.prefernot",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_patientEthnicComment",
    "patientEthnicity.comment",
    ["2023", "2024"],
    "1",
  ],
  ["demograph_prefLangEnglish", "preferredLang.english", ["2023", "2024"], "2"],
  ["demograph_prefLangOther", "preferredLang.other", ["2023", "2024"], "2"],
  [
    "demograph_prefLangOtherSubAfrican",
    "preferredLang.otherSubAnswer.african",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubArabic",
    "preferredLang.otherSubAnswer.arabic",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubChinese",
    "preferredLang.otherSubAnswer.chinese",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubFrench",
    "preferredLang.otherSubAnswer.french",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubFrenchCrelo",
    "preferredLang.otherSubAnswer.frenchCreole",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubGerman",
    "preferredLang.otherSubAnswer.german",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubGreek",
    "preferredLang.otherSubAnswer.greek",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubGujarati",
    "preferredLang.otherSubAnswer.gujarati",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubHebrew",
    "preferredLang.otherSubAnswer.hebrew",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubHindi",
    "preferredLang.otherSubAnswer.hindi",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubHungarian",
    "preferredLang.otherSubAnswer.hungarian",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubItalian",
    "preferredLang.otherSubAnswer.italian",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubJapanese",
    "preferredLang.otherSubAnswer.japanese",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubKorean",
    "preferredLang.otherSubAnswer.korean",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubPersian",
    "preferredLang.otherSubAnswer.persian",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubPolish",
    "preferredLang.otherSubAnswer.polish",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubPortuguse",
    "preferredLang.otherSubAnswer.portuguese",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubRussian",
    "preferredLang.otherSubAnswer.russian",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubScandinavian",
    "preferredLang.otherSubAnswer.scandinavian",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubSerbo",
    "preferredLang.otherSubAnswer.serbo",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubSpanish",
    "preferredLang.otherSubAnswer.spanish",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubTagalog",
    "preferredLang.otherSubAnswer.tagalog",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubUrdu",
    "preferredLang.otherSubAnswer.urdu",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubVietnamese",
    "preferredLang.otherSubAnswer.vietnamese",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubYiddish",
    "preferredLang.otherSubAnswer.yiddish",
    ["2023", "2024"],
    "2",
  ],
  [
    "demograph_prefLangOtherSubComment",
    "preferredLang.otherSubAnswer.comment",
    ["2023", "2024"],
    "1",
  ],
  ["demograph_prefLangComment", "preferredLang.comment", ["2023", "2024"], "1"],
  [
    "demograph_religious",
    "patientReligiousBelief.isPatientReligiousBelief",
    ["2024"],
    "3",
  ],
  [
    "demograph_religiousSubDesc",
    "patientReligiousBelief.isPatientReligiousBeliefSubAnswer.describe",
    ["2024"],
    "1",
  ],
  [
    "demograph_religiousComment",
    "patientReligiousBelief.comment",
    ["2024"],
    "1",
  ],
  // ["demograph_effectiveDate", "effectiveDate",["2023","2024"]],
  // ["demograph_termDate", "termDate",["2023","2024"]],
  // ["demograph_eligibilityDate", "eligibilityDate",["2023","2024"]],
  // ["demograph_aAddress", "aAddress",["2023","2024"]],
  // ["demograph_insh", "insh",["2023","2024"]],
  // ["demograph_memberIdTwo", "memberIdTwo",["2023","2024"]],
  // ["demograph_suffix", "suffix",["2023","2024"]],
  ["demograph_screenIha", "screenIha", ["2023", "2024"], "1"],
  // ["demograph_dualEligibility", "dualEligibility",["2023","2024"]],
  // ["demograph_medReview", "medReview",["2023","2024"]],
  // ["demograph_painAsmt", "painAsmt",["2023","2024"]],
  // ["demograph_funcStatus", "funcStatus",["2023","2024"]],
  // ["demograph_rankAll", "rankAll",["2023","2024"]],
  // ["demograph_careCoordinator", "careCoordinator",["2023","2024"]],
  // ["demograph_suspectCodes", "suspectCodes",["2023","2024"]],
  ["demograph_clientId", "clientId", ["2023", "2024"], "1"],
  // ["demograph_screeningIha", "screeningIha", ["2023"], "1"],
];

const demographics_optionsTreeMappers = [
  [{ demograph_raceCaucasian: "caucasian" }, ["2023", "2024"]],
  [{ demograph_raceAfricanAmerican: "africanAmerican" }, ["2023", "2024"]],
  [{ demograph_raceAsian: "asian" }, ["2023", "2024"]],
  [{ demograph_raceLatino: "latino" }, ["2023", "2024"]],
  [{ demograph_raceNativeAmerican: "nativeAmerican" }, ["2023", "2024"]],
  [{ demograph_raceNativeHawaiin: "nativeHawaiin" }, ["2023", "2024"]],
  [{ demograph_raceAlaskanNative: "alaskanNative" }, ["2023", "2024"]],
  [{ demograph_raceOther: "other" }, ["2023", "2024"]],

  [{ demograph_patientEthnicHispanic: "hispanic" }, ["2023", "2024"]],
  [{ demograph_patientEthnicNonHispanic: "nonHispanic" }, ["2023", "2024"]],
  [{ demograph_patientEthnicOther: "otherethnicity" }, ["2023", "2024"]],
  [{ demograph_patientEthnicPrefernot: "prefernot" }, ["2023", "2024"]],

  [{ demograph_prefLangEnglish: "english" }, ["2023", "2024"]],
  [{ demograph_prefLangOther: "other" }, ["2023", "2024"]],

  [{ demograph_prefLangOtherSubAfrican: "african" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubArabic: "arabic" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubChinese: "chinese" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubFrench: "french" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubFrenchCrelo: "frenchCreole" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubGerman: "german" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubGreek: "greek" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubGujarati: "gujarati" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubHebrew: "hebrew" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubHindi: "hindi" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubHungarian: "hungarian" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubItalian: "italian" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubJapanese: "japanese" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubKorean: "korean" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubPersian: "persian" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubPolish: "polish" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubPortuguse: "portuguese" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubRussian: "russian" }, ["2023", "2024"]],
  [
    { demograph_prefLangOtherSubScandinavian: "scandinavian" },
    ["2023", "2024"],
  ],
  [{ demograph_prefLangOtherSubSerbo: "serbo" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubSpanish: "spanish" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubTagalog: "tagalog" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubUrdu: "urdu" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubVietnamese: "vietnamese" }, ["2023", "2024"]],
  [{ demograph_prefLangOtherSubYiddish: "yiddish" }, ["2023", "2024"]],
];

const demographics_optionsTreeWrappers = [
  [
    "demograph_race",
    [
      "demograph_raceCaucasian",
      "demograph_raceAfricanAmerican",
      "demograph_raceAsian",
      "demograph_raceLatino",
      "demograph_raceNativeAmerican",
      "demograph_raceNativeHawaiin",
      "demograph_raceAlaskanNative",
      "demograph_raceOther",
    ],
    ["2023", "2024"],
  ],
  [
    "demograph_patientEthnic",
    [
      "demograph_patientEthnicHispanic",
      "demograph_patientEthnicNonHispanic",
      "demograph_patientEthnicOther",
      "demograph_patientEthnicPrefernot",
    ],
    ["2023", "2024"],
  ],
  [
    "demograph_prefLang",
    ["demograph_prefLangEnglish", "demograph_prefLangOther"],
    ["2023", "2024"],
  ],
  [
    "demograph_prefLangOtherSub",
    [
      "demograph_prefLangOtherSubAfrican",
      "demograph_prefLangOtherSubArabic",
      "demograph_prefLangOtherSubChinese",
      "demograph_prefLangOtherSubFrench",
      "demograph_prefLangOtherSubFrenchCrelo",
      "demograph_prefLangOtherSubGerman",
      "demograph_prefLangOtherSubGreek",
      "demograph_prefLangOtherSubGujarati",
      "demograph_prefLangOtherSubHebrew",
      "demograph_prefLangOtherSubHindi",
      "demograph_prefLangOtherSubHungarian",
      "demograph_prefLangOtherSubItalian",
      "demograph_prefLangOtherSubJapanese",
      "demograph_prefLangOtherSubKorean",
      "demograph_prefLangOtherSubPersian",
      "demograph_prefLangOtherSubPolish",
      "demograph_prefLangOtherSubPortuguse",
      "demograph_prefLangOtherSubRussian",
      "demograph_prefLangOtherSubScandinavian",
      "demograph_prefLangOtherSubSerbo",
      "demograph_prefLangOtherSubSpanish",
      "demograph_prefLangOtherSubTagalog",
      "demograph_prefLangOtherSubUrdu",
      "demograph_prefLangOtherSubVietnamese",
      "demograph_prefLangOtherSubYiddish",
    ],
    ["2023", "2024"],
  ],
];

export const getDemographicsData = (apiData, year) => {
  let nestedData = {};
  let flattenData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = getObjectData(apiData, questionPair[1]);

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedData, pointerLabels[1], year) || "";
  });

  let demograph_aligned = "";
  const temp_aligned = flattenData["demograph_aligned"];
  if (temp_aligned === "1") {
    demograph_aligned = "Y";
  } else if (temp_aligned === "2") {
    demograph_aligned = "N";
  }
  const demograph_pFlag = miscData["demograph_pFlag"] === "1" ? ["yes"] : [];

  flattenData["demograph_aligned"] = demograph_aligned;
  flattenData["demograph_pFlag"] = demograph_pFlag;

  return flattenData;
};

export const setDemographicsData = (formData, year) => {
  let flattenData = {};
  let nestedData = {};
  let payloadData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = formData[quesUIVariable] || "";

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let demograph_aligned = "";
  const temp_aligned = formData["demograph_aligned"];
  if (temp_aligned === "Y") {
    demograph_aligned = "1";
  } else if (temp_aligned === "N") {
    demograph_aligned = "2";
  }

  const demograph_pFlagSelected = formData?.["demograph_pFlag"] || [];
  const demograph_pFlag = demograph_pFlagSelected.includes("yes") ? "1" : "";
  flattenData["demograph_aligned"] = demograph_aligned;
  flattenData["demograph_pFlag"] = demograph_pFlag;

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getFlatmapByYear = (year) => {
  let temp = [];
  demographics_flatmap.forEach((questionPair) => {
    const quesYear = questionPair?.[2] || ["2023"];
    if (quesYear.includes(year)) {
      temp.push(questionPair);
    }
  });

  return temp;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  demographics_optionsTreeMappers.forEach((mapper) => {
    const quesYear = mapper?.[1] || ["2023"];
    if (quesYear.includes(year)) {
      const quesMap = mapper?.[0];
      temp = { ...temp, ...quesMap };
    }
  });

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  demographics_optionsTreeWrappers.forEach((wrapper) => {
    const quesYear = wrapper?.[2] || ["2023"];
    if (quesYear.includes(year)) {
      const [quesWrapHead, quesWrapElements] = wrapper;
      temp.push([quesWrapHead, quesWrapElements]);
    }
  });

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = getOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = setOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  let getSubTreeRegex = getSubTreeString(
    optionName,
    optionValue,
    treeWrappers,
    treeMappers,
  );
  return getSubTreeRegex;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let updatedData = deleteSubTreeData(formData, subTreeRegex);
  return updatedData;
};
