import {
  getObjectData,
  getOptionsSelected,
  setObjectData,
  setOptionsSelected,
} from "../../../../../../utils/helpers.DynamicForm";
import lodash from "lodash";

const family_hashmap = [
  ["familyHistory", "familyHistory.isFamilyHistory"],
  ["familyHistoryDatatableVal", "familyHistory.familyHistorySubAnswers"],
  ["familyHistoryComment", "familyHistory.comment"],
];

export const getfamilyData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  family_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  const getOptionsSelectedYesNo = (optionValue) => {
    let selectedValue;
    if (optionValue === "1") {
      selectedValue = ["yes"];
    } else if (optionValue === "2") {
      selectedValue = ["no"];
    } else {
      selectedValue = "";
    }

    return selectedValue;
  };

  const familyDataTable = tempDbData["familyHistoryDatatableVal"];
  const familyHistory = getOptionsSelectedYesNo(tempDbData["familyHistory"]);
  const familyHistoryComment = tempDbData["familyHistoryComment"];

  tempDbData = {
    ...tempDbData,

    //   specialistsDatatable,
    familyDataTable,

    familyHistory,
    familyHistoryComment,
  };

  return tempDbData;
};

export const setfamilyData = (formData) => {
  let tempData = {};

  let payloadData = {};

  const setOptionsSelectedYesNo = (optionValue) => {
    let selectedValue;
    if (optionValue?.includes("yes")) {
      selectedValue = "1";
    } else if (optionValue?.includes("no")) {
      selectedValue = "2";
    } else {
      selectedValue = "";
    }

    return selectedValue;
  };
  const familyHistoryDatatableVal = formData["familyDataTable"];
  const familyHistory = setOptionsSelectedYesNo(formData["familyHistory"]);
  const familyHistoryComment = formData["familyHistoryComment"];

  tempData = {
    ...tempData,

    familyHistory,
    familyHistoryComment,

    familyHistoryDatatableVal: familyHistoryDatatableVal || [],
  };

  family_hashmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  return payloadData;
};
