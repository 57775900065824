import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

import {
  ros_hashmap2024,
  ros_OptionsTreeWrappers2024,
  ros_optionsTreeMapObject2024,
} from "./reviewOfSystemData2024";
import {
  getMashedData_ros_3,
  setMashedData_ros_3,
  ros_optionsTreeWrappers,
  getOptionsMapper,
} from "./reviewOfSystemData2024_18";

export const getRosData2024 = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  ros_hashmap2024?.forEach((questionPair, index) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  ros_OptionsTreeWrappers2024.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const ros_respiratoryComment = tempDbData["ros_respiratoryComment"];
  const ros_respiratory = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryVal"],
  );
  const ros_respiratoryAcuteUpperSubAnswerDescribeComment =
    tempDbData["ros_respiratoryAcuteUpperSubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    tempDbData["ros_respiratoryDiagnosesComment"];

  const ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment"];

  const ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
    tempDbData[
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment"
    ];

  const ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc"];
  const ros_respiratoryAsthmaSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryAsthmaSubOtherTreatmentSubDesc"];
  const ros_respiratoryAsthmaActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryAsthmaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryAsthmaMedicationSubAnswerComment =
    tempDbData["ros_respiratoryAsthmaMedicationSubAnswerComment"];
  const ros_respiratoryAsthmaCurrentExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryAsthmaCurrentExacerbationVal"],
  );
  const ros_respiratoryAsthmaCurrentExacerbationComment =
    tempDbData["ros_respiratoryAsthmaCurrentExacerbationComment"];
  const ros_respiratoryAsthmaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryAsthmaSubAnswerDescribeComment"];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
    tempDbData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc =
    tempDbData[
      "ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc"
    ];
  const ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment"
    ];
  const ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
    tempDbData["ros_respiratoryChronicSputumFailureSubAnswerDescribeComment"];
  const ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc"];
  const ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryCopdSubAnswerDescribeComment =
    tempDbData["ros_respiratoryCopdSubAnswerDescribeComment"];
  const ros_respiratoryCopdTypeSubAnswerComment =
    tempDbData["ros_respiratoryCopdTypeSubAnswerComment"];
  const ros_respiratoryCopdSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryCopdSubOtherTreatmentSubDesc"];
  const ros_respiratoryCopdActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryCopdActiveTreatmentSubAnswerComment"];
  const ros_respiratoryCopdMedicationSubAnswerComment =
    tempDbData["ros_respiratoryCopdMedicationSubAnswerComment"];
  const ros_respiratoryPatientExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientExacerbationVal"],
  );
  const ros_respiratoryPatientExacerbationComment =
    tempDbData["ros_respiratoryPatientExacerbationComment"];
  const ros_respiratoryCopdSubPatientExacerbationComment =
    tempDbData["ros_respiratoryCopdSubPatientExacerbationComment"];
  const ros_respiratoryCysticFibrosisSubAnswerDescribeComment =
    tempDbData["ros_respiratoryCysticFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc"];
  const ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment"];
  const ros_respiratoryHypoventilationSubAnswerDescribeComment =
    tempDbData["ros_respiratoryHypoventilationSubAnswerDescribeComment"];
  const ros_respiratoryHypoventilationSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryHypoventilationSubOtherTreatmentSubDesc"];
  const ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment"];
  const ros_respiratoryHypoventilationActiveSelectSubAnswerComment =
    tempDbData["ros_respiratoryHypoventilationActiveSelectSubAnswerComment"];
  const ros_respiratoryHypoxemiaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryHypoxemiaSubAnswerDescribeComment"];
  const ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc"];
  const ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryHypoxemiaActiveSelectSubAnswerComment =
    tempDbData["ros_respiratoryHypoxemiaActiveSelectSubAnswerComment"];
  const ros_respiratoryPneumoniaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryPneumoniaSubAnswerDescribeComment"];
  const ros_respiratoryPneumoniaSubAnswerEtiologyComment =
    tempDbData["ros_respiratoryPneumoniaSubAnswerEtiologyComment"];
  const ros_respiratoryHistoryOfLungAbscess = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryHistoryOfLungAbscessVal"],
  );
  const ros_respiratoryHistoryOfLungAbscessComment =
    tempDbData["ros_respiratoryHistoryOfLungAbscessComment"];
  const ros_respiratoryHistoryOfEmpyema = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryHistoryOfEmpyemaVal"],
  );
  const ros_respiratoryHistoryOfEmpyemaComment =
    tempDbData["ros_respiratoryHistoryOfEmpyemaComment"];
  const ros_respiratoryPneumoniaSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryPneumoniaSubOtherTreatmentSubDesc"];
  const ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
    tempDbData["ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc"];
  const ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment =
    tempDbData["ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment =
    tempDbData["ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment"];
  const ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc"];
  const ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment =
    tempDbData[
      "ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment"
    ];
  const ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
    tempDbData[
      "ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc"
    ];
  const ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment"
    ];
  const ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratorySarcoidosisSubAnswerDescribeComment =
    tempDbData["ros_respiratorySarcoidosisSubAnswerDescribeComment"];
  const ros_respiratorySarcoidosisSubAnswerSpecifyComment =
    tempDbData["ros_respiratorySarcoidosisSubAnswerSpecifyComment"];
  const ros_respiratorySarcoidosisSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratorySarcoidosisSubOtherTreatmentSubDesc"];
  const ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment"];
  const ros_respiratorySleepApneaSubAnswerDescribeComment =
    tempDbData["ros_respiratorySleepApneaSubAnswerDescribeComment"];
  const ros_respiratorySleepApneaActiveSelectSubAnswer =
    getOptionsSelectedYesNo(
      tempDbData["ros_respiratorySleepApneaActiveSelectSubAnswerVal"],
    );
  const ros_respiratorySleepApneaActiveSelectSubAnswerComment =
    tempDbData["ros_respiratorySleepApneaActiveSelectSubAnswerComment"];
  const ros_respiratorySleepApneaSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratorySleepApneaSubOtherTreatmentSubDesc"];
  const ros_respiratorySleepApneaActiveTreatmentSubAnswerComment =
    tempDbData["ros_respiratorySleepApneaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryLungTransplantSubOtherTreatmentSubDesc =
    tempDbData["ros_respiratoryLungTransplantSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespOtherSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespOtherSubAnswerDescribeComment"];
  const ros_respiratoryOtherSubDesc = tempDbData["ros_respiratoryOtherSubDesc"];
  const ros_respiratoryOtherSubAnswerOther =
    tempDbData["ros_respiratoryOtherSubAnswerOther"];
  const ros_respiratoryRespOtherSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryRespOtherSubAnswerSupportedByComment"];

  const ros_respiratorySymptomsSubOtherSubDesc =
    tempDbData["ros_respiratorySymptomsSubOtherSubDesc"];
  const ros_respiratorySymptomsComment =
    tempDbData["ros_respiratorySymptomsComment"];

  //--respi checkbox
  const respiratory_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["respiratory_recoTakeMedicationsVal"],
      tempDbData["respiratory_recoCaseManagementVal"],
      tempDbData["respiratory_recoFollowUpClinicianVal"],
      tempDbData["respiratory_recoDMEVal"],
      tempDbData["respiratory_recoOtherVal"],
    ],
    ["takeMedications", "caseManagement", "followUpClinician", "DME", "other"],
  );

  const respiratory_RecommendationstakeMedicationsComment =
    tempDbData["respiratory_RecommendationstakeMedicationsCommentVal"];

  const respiratory_RecommendationscaseManagementComment =
    tempDbData["respiratory_RecommendationscaseManagementCommentVal"];

  const respiratory_RecommendationsfollowUpClinicianComment =
    tempDbData["respiratory_RecommendationsfollowUpClinicianCommentVal"];

  const respiratory_RecommendationsDMEComment =
    tempDbData["respiratory_RecommendationsDMECommentVal"];

  const respiratory_RecommendationsotherComment =
    tempDbData["respiratory_RecommendationsotherCommentVal"];

  const ros_integumentComment = tempDbData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    tempDbData["ros_integumentDiagnosesComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
    tempDbData["ros_integumentBasilCellCarcinomaSubAnswerDescribeComment"];
  const ros_integumentBasilCellCarcinomaSubLocation =
    tempDbData["ros_integumentBasilCellCarcinomaSubLocation"];
  const ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc"];
  const ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment"
    ];
  const ros_integument = getOptionsSelectedYesNo(
    tempDbData["ros_integumentVal"],
  );
  const ros_integumentDermatitisSubAnswerDescribeComment =
    tempDbData["ros_integumentDermatitisSubAnswerDescribeComment"];
  const ros_integumentDermatitisSubLocation =
    tempDbData["ros_integumentDermatitisSubLocation"];
  const ros_integumentDermatitisSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentDermatitisSubOtherTreatmentSubDesc"];
  const ros_integumentDermatitisActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentDermatitisActiveTreatmentSubAnswerComment"];
  const ros_integumentDermatitisSubAnswerWhatTypeComment =
    tempDbData["ros_integumentDermatitisSubAnswerWhatTypeComment"];
  const ros_integumentEczemaSubAnswerDescribeComment =
    tempDbData["ros_integumentEczemaSubAnswerDescribeComment"];
  const ros_integumentEczemaSubLocation =
    tempDbData["ros_integumentEczemaSubLocation"];
  const ros_integumentEczemaSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentEczemaSubOtherTreatmentSubDesc"];
  const ros_integumentEczemaActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentEczemaActiveTreatmentSubAnswerComment"];
  const ros_integumentOnychomycosisSubAnswerDescribeComment =
    tempDbData["ros_integumentOnychomycosisSubAnswerDescribeComment"];
  const ros_integumentOnychomycosisSubLocation =
    tempDbData["ros_integumentOnychomycosisSubLocation"];
  const ros_integumentOnychomycosisSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentOnychomycosisSubOtherTreatmentSubDesc"];
  const ros_integumentOnychomycosisActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentOnychomycosisActiveTreatmentSubAnswerComment"];
  const ros_integumentPsoriasisSubAnswerDescribeComment =
    tempDbData["ros_integumentPsoriasisSubAnswerDescribeComment"];
  const ros_integumentPsoriasisSubLocation =
    tempDbData["ros_integumentPsoriasisSubLocation"];
  const ros_integumentPsoriasisSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentPsoriasisSubOtherTreatmentSubDesc"];
  const ros_integumentPsoriasisActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentPsoriasisActiveTreatmentSubAnswerComment"];
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritis =
    getOptionsSelectedYesNo(
      tempDbData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal"],
    );
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
    tempDbData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_integumentSkinUlcerSubAnswerDescribeComment =
    tempDbData["ros_integumentSkinUlcerSubAnswerDescribeComment"];
  const ros_integumentSkinUlcerSubLocation =
    tempDbData["ros_integumentSkinUlcerSubLocation"];
  const ros_integumentSkinUlcerSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentSkinUlcerSubOtherTreatmentSubDesc"];
  const ros_integumentSkinUlcerActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentSkinUlcerActiveTreatmentSubAnswerComment"];
  const ros_integumentSkinUlcerSubEtiologySubDesc =
    tempDbData["ros_integumentSkinUlcerSubEtiologySubDesc"];
  const ros_integumentSkinUlcerSubAnswerEtiologyComment =
    tempDbData["ros_integumentSkinUlcerSubAnswerEtiologyComment"];
  const ros_integumentTineaPedisSubAnswerDescribeComment =
    tempDbData["ros_integumentTineaPedisSubAnswerDescribeComment"];
  const ros_integumentTineaPedisSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentTineaPedisSubOtherTreatmentSubDesc"];
  const ros_integumentTineaPedisActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentTineaPedisActiveTreatmentSubAnswerComment"];
  const ros_integumentTineaPedisSelectTreatmentSubAnswerComment =
    tempDbData["ros_integumentTineaPedisSelectTreatmentSubAnswerComment"];
  const ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerDescribeComment"];
  const ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc"];
  const ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment"
    ];
  const ros_integumentUrticarialDiseaseSubAnswerTypeComment =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerTypeComment"];
  const ros_integumentUrticarialDiseaseSubAnswerEtiology =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerEtiology"];
  const ros_integumentUrticarialDiseaseSubLocation =
    tempDbData["ros_integumentUrticarialDiseaseSubLocation"];
  const ros_integumentWoundSubAnswerDescribeComment =
    tempDbData["ros_integumentWoundSubAnswerDescribeComment"];
  const ros_integumentWoundSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentWoundSubOtherTreatmentSubDesc"];
  const ros_integumentWoundSubLocation =
    tempDbData["ros_integumentWoundSubLocation"];
  const ros_integumentWoundActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentWoundActiveTreatmentSubAnswerComment"];
  const ros_integumentWoundSubAnswerEtiologyComment =
    tempDbData["ros_integumentWoundSubAnswerEtiologyComment"];
  const ros_integumentOthersSubAnswerDescribeComment =
    tempDbData["ros_integumentOthersSubAnswerDescribeComment"];
  const ros_integumentOthersSubLocation =
    tempDbData["ros_integumentOthersSubLocation"];
  const ros_integumentOthersSubOtherTreatmentSubDesc =
    tempDbData["ros_integumentOthersSubOtherTreatmentSubDesc"];
  const ros_integumentOthersActiveTreatmentSubAnswerComment =
    tempDbData["ros_integumentOthersActiveTreatmentSubAnswerComment"];
  const ros_integumentOthersSubAnswerOther =
    tempDbData["ros_integumentOthersSubAnswerOther"];

  const integument_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["integument_recoTakeMedicationsAsPescribedVal"],
      tempDbData["integument_recoCaseManagementVal"],
      tempDbData["integument_recoFollowUpClinicianVal"],
      tempDbData["integument_recoDMEVal"],
      tempDbData["integument_recoOtherVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );

  const integument_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData[
      "integument_RecommendationstakeMedicationsAsPescribedCommentVal"
    ];

  const integument_RecommendationscaseManagementComment =
    tempDbData["integument_RecommendationscaseManagementCommentVal"];

  const integument_RecommendationsfollowUpClinicianComment =
    tempDbData["integument_RecommendationsfollowUpClinicianCommentVal"];

  const integument_RecommendationsDMEComment =
    tempDbData["integument_RecommendationsDMECommentVal"];

  const integument_RecommendationsotherComment =
    tempDbData["integument_RecommendationsotherCommentVal"];

  const ros_musculoskeletal = getOptionsSelectedYesNo(
    tempDbData["ros_musculoskeletalVal"],
  );
  const ros_musculoskeletalComment = tempDbData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    tempDbData["ros_musculoskeletalDiagnosesComment"];
  const ros_musculoskeletalDermatomyositisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalDermatomyositisSubAnswerDescribeComment"];
  const ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal"
      ],
    );
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalExtremityFractureSubAnswerDescribeComment"];
  const ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment"
    ];
  const ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
    tempDbData["ros_musculoskeletalExtremityFractureSubAnswerExtremityComment"];
  const ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureShoulderSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureArmSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureArmSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureForearmSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureWristSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureWristSubAnswerComment"];
  const ros_musculoskeletalHandSubAnswerComment =
    tempDbData["ros_musculoskeletalHandSubAnswerComment"];
  const ros_musculoskeletalFemoralShaftSubAnswerComment =
    tempDbData["ros_musculoskeletalFemoralShaftSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureTibiaSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureFibulaSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureAnkleSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureFootSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureFootSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureHipSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureHipSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal"
      ],
    );
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment"
    ];
  const ros_musculoskeletalGoutSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalGoutSubAnswerDescribeComment"];
  const ros_musculoskeletalGoutSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalGoutSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalGoutActiveTreatmentSubAnswerComment =
    tempDbData["ros_musculoskeletalGoutActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalHalluxValgusSubAnswerDescribeComment"];
  const ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment =
    tempDbData["ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment"];
  const ros_musculoskeletalHammerToesSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalHammerToesSubAnswerDescribeComment"];
  const ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment =
    tempDbData["ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalOsteomyelitisSubLocation =
    tempDbData["ros_musculoskeletalOsteomyelitisSubLocation"];
  const ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteoporosisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc"
    ];
  const ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalSclerodermaSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalSclerodermaSubAnswerDescribeComment"];
  const ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment =
    tempDbData["ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment"];
  const ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
    getOptionsSelectedYesNo(
      tempDbData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal"],
    );
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
    tempDbData[
      "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment =
    tempDbData[
      "ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc =
    tempDbData[
      "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc"
    ];
  const ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalSystemicLupusSubAnswerDescribeComment"];
  const ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalMuskoOtherSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalMuskoOtherSubAnswerDescribeComment"];
  const ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc =
    tempDbData["ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment =
    tempDbData["ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerother =
    tempDbData["ros_musculoskeletalMuskoOtherSubAnswerother"];
  const ros_musculoskeletalMuskoSymptomsSubOtherSubDesc =
    tempDbData["ros_musculoskeletalMuskoSymptomsSubOtherSubDesc"];
  const ros_musculoskeletalMuskoSymptomsComment =
    tempDbData["ros_musculoskeletalMuskoSymptomsComment"];
  const ros_musculoskeletalHadAnAmputationSubAnswer =
    tempDbData["ros_musculoskeletalHadAnAmputationSubAnswer"];
  const ros_musculoskeletalMuskoHadAnAmputation = getOptionsSelectedYesNo(
    tempDbData["ros_musculoskeletalMuskoHadAnAmputationVal"],
  );
  const ros_musculoskeletalMuskoHadAnAmputationComment =
    tempDbData["ros_musculoskeletalMuskoHadAnAmputationComment"];

  const musko_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["musko_recoDiscussPtOtVal"],
      tempDbData["musko_recoTakeMedicationsAsPescribedVal"],
      tempDbData["musko_recoCaseManagementVal"],
      tempDbData["musko_recoFollowUpClinicianVal"],
      tempDbData["musko_recoDMEVal"],
      tempDbData["musko_recoOtherVal"],
    ],
    [
      "discussPtOt",
      "takeMedicationsAsPescribed",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );
  const musko_RecommendationsdiscussPtOtComment =
    tempDbData["musko_RecommendationsdiscussPtOtCommentVal"];

  const musko_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["musko_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const musko_RecommendationscaseManagementComment =
    tempDbData["musko_RecommendationscaseManagementCommentVal"];

  const musko_RecommendationsfollowUpClinicianComment =
    tempDbData["musko_RecommendationsfollowUpClinicianCommentVal"];

  const musko_RecommendationsDMEComment =
    tempDbData["musko_RecommendationsDMECommentVal"];

  const musko_RecommendationsotherComment =
    tempDbData["musko_RecommendationsotherCommentVal"];

  const ros_hematology = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyVal"],
  );

  const ros_hematologyComment = tempDbData["ros_hematologyComment"];

  const ros_hematologyDiagnosesComment =
    tempDbData["ros_hematologyDiagnosesComment"];

  const ros_hematologyAidsSubAnswerDescribeComment =
    tempDbData["ros_hematologyAidsSubAnswerDescribeComment"];
  const ros_hematologyAidsSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyAidsSubOtherTreatmentSubDesc"];
  const ros_hematologyAidsActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyAidsActiveTreatmentSubAnswerComment"];
  const ros_hematologyAnemiaSubAnswerDescribeComment =
    tempDbData["ros_hematologyAnemiaSubAnswerDescribeComment"];
  const ros_hematologyAnemiaSubAnswerEtiologyComment =
    tempDbData["ros_hematologyAnemiaSubAnswerEtiologyComment"];
  const ros_hematologyAnemiaSubEtiologySubOtherSubDesc =
    tempDbData["ros_hematologyAnemiaSubEtiologySubOtherSubDesc"];
  const ros_hematologyAnemiaSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyAnemiaSubOtherTreatmentSubDesc"];
  const ros_hematologyAnemiaActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyAnemiaActiveTreatmentSubAnswerComment"];
  const ros_hematologyAnemiaSubYesPatientOnSubDesc =
    tempDbData["ros_hematologyAnemiaSubYesPatientOnSubDesc"];
  const ros_hematologyAnemiaSubAnswerYesPatientOnComment =
    tempDbData["ros_hematologyAnemiaSubAnswerYesPatientOnComment"];
  const ros_hematologyCDifficileSubAnswerDescribeComment =
    tempDbData["ros_hematologyCDifficileSubAnswerDescribeComment"];
  const ros_hematologyCDifficileSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyCDifficileSubOtherTreatmentSubDesc"];
  const ros_hematologyCDifficileActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyCDifficileActiveTreatmentSubAnswerComment"];
  const ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
    tempDbData["ros_hematologyCommunityAcquiredSubAnswerDescribeComment"];
  const ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc"];
  const ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment"
    ];

  const ros_hematologyHivSubAnswerPatientSymptomatic = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyHivSubAnswerPatientSymptomaticVal"],
  );
  const ros_hematologyHivSubAnswerActiveTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyHivSubAnswerActiveTreatmentVal"],
  );
  const ros_hematologyHivSubAnswerViralLoad =
    tempDbData["ros_hematologyHivSubAnswerViralLoad"];
  const ros_hematologyHivSubAnswerCFour =
    tempDbData["ros_hematologyHivSubAnswerCFour"];
  const ros_hematologyHivSubAnswerPatientSymptomaticComment =
    tempDbData["ros_hematologyHivSubAnswerPatientSymptomaticComment"];
  const ros_hematologyHivSubAnswerActiveTreatmentComment =
    tempDbData["ros_hematologyHivSubAnswerActiveTreatmentComment"];
  const ros_hematologyHivSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyHivSubOtherTreatmentSubDesc"];
  const ros_hematologyHivActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyHivActiveTreatmentSubAnswerComment"];
  const ros_hematologyHivSubAnswerDescribeComment =
    tempDbData["ros_hematologyHivSubAnswerDescribeComment"];

  const ros_hematologyHerpesZosterSubAnswerDescribeComment =
    tempDbData["ros_hematologyHerpesZosterSubAnswerDescribeComment"];
  const ros_hematologyHerpesZosterTypeSubAnswerComment =
    tempDbData["ros_hematologyHerpesZosterTypeSubAnswerComment"];
  const ros_hematologyHerpesZosterActiveSubOtherSubDesc =
    tempDbData["ros_hematologyHerpesZosterActiveSubOtherSubDesc"];
  const ros_hematologyHerpesZosterSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyHerpesZosterSubOtherTreatmentSubDesc"];
  const ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment"];
  const ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment =
    tempDbData["ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment"];
  const ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc =
    tempDbData["ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc"];

  const ros_hematologyMrsaInfectionSubAnswerDescribeComment =
    tempDbData["ros_hematologyMrsaInfectionSubAnswerDescribeComment"];
  const ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc"];
  const ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment"];

  const ros_hematologyImmuneDeficiencySubAnswerDescribeComment =
    tempDbData["ros_hematologyImmuneDeficiencySubAnswerDescribeComment"];
  const ros_hematologyImmuneDeficiencySubAnswerEtiology =
    tempDbData["ros_hematologyImmuneDeficiencySubAnswerEtiology"];
  const ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc"];
  const ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment"];

  const ros_hematologySepsisSubAnswerDescribeComment =
    tempDbData["ros_hematologySepsisSubAnswerDescribeComment"];
  const ros_hematologySepsisSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologySepsisSubOtherTreatmentSubDesc"];
  const ros_hematologySepsisActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologySepsisActiveTreatmentSubAnswerComment"];

  const ros_hematologySickleCellDiseaseSubAnswerDescribeComment =
    tempDbData["ros_hematologySickleCellDiseaseSubAnswerDescribeComment"];
  const ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment"
    ];
  const ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"];
  const ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment =
    tempDbData[
      "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment"
    ];

  const ros_hematologySickleCellTraitSubAnswerDescribeComment =
    tempDbData["ros_hematologySickleCellTraitSubAnswerDescribeComment"];
  const ros_hematologySickleCellTraitSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologySickleCellTraitSubOtherTreatmentSubDesc"];
  const ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment"];

  const ros_hematologyThalassemiaSubAnswerDescribeComment =
    tempDbData["ros_hematologyThalassemiaSubAnswerDescribeComment"];
  const ros_hematologyThalassemiaSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyThalassemiaSubOtherTreatmentSubDesc"];
  const ros_hematologyThalassemiaActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyThalassemiaActiveTreatmentSubAnswerComment"];

  const ros_hematologyThrombocytopeniaSubAnswerDescribeComment =
    tempDbData["ros_hematologyThrombocytopeniaSubAnswerDescribeComment"];
  const ros_hematologyThrombocytopeniaSubAnswerEtiology =
    tempDbData["ros_hematologyThrombocytopeniaSubAnswerEtiology"];
  const ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc"];
  const ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment"];

  const ros_hematologyTuberculosisSubAnswerDescribeComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerDescribeComment"];
  const ros_hematologyTuberculosisSubAnswerGivenBCGComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerGivenBCGComment"];
  const ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_hematologyTuberculosisSubAnswerTbInfectionComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerTbInfectionComment"];
  const ros_hematologyTuberculosisSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyTuberculosisSubOtherTreatmentSubDesc"];
  const ros_hematologyTuberculosisActiveTreatmentSubAnswerComment =
    tempDbData["ros_hematologyTuberculosisActiveTreatmentSubAnswerComment"];

  const ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
    tempDbData["ros_hematologyVitaminDDeficiencySubAnswerDescribeComment"];
  const ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc"];
  const ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment =
    tempDbData[
      "ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment"
    ];

  const ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc =
    tempDbData["ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc"];

  const ros_hematologyHemaOtherSubOtherSubDesc =
    tempDbData["ros_hematologyHemaOtherSubOtherSubDesc"];
  const ros_hematologyHemaOtherSubAnswerDescribeComment =
    tempDbData["ros_hematologyHemaOtherSubAnswerDescribeComment"];
  const ros_hematologyHemaOtherSubAnswerSupportedByComment =
    tempDbData["ros_hematologyHemaOtherSubAnswerSupportedByComment"];

  const ros_hematologyHemaOtherSubAnswerOther =
    tempDbData["ros_hematologyHemaOtherSubAnswerOther"];

  ////////HEMA CHECKBOX//////
  const hema_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["hema_recoTakeMedicationsAsPescribedVal"],
      tempDbData["hema_recoReportAbnormalBruisingVal"],
      tempDbData["hema_recoFollowUpWithDoctorVal"],
      tempDbData["hema_recoCaseManagementVal"],
      tempDbData["hema_recoFollowUpClinicianVal"],
      tempDbData["hema_recoDMEVal"],
      tempDbData["hema_recoOtherVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["hema_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const hema_RecommendationsreportAbnormalBruisingComment =
    tempDbData["hema_RecommendationsreportAbnormalBruisingCommentVal"];

  const hema_RecommendationsfollowUpWithDoctorComment =
    tempDbData["hema_RecommendationsfollowUpWithDoctorCommentVal"];

  const hema_RecommendationscaseManagementComment =
    tempDbData["hema_RecommendationscaseManagementCommentVal"];

  const hema_RecommendationsfollowUpClinicianComment =
    tempDbData["hema_RecommendationsfollowUpClinicianCommentVal"];

  const hema_RecommendationsDMEComment =
    tempDbData["hema_RecommendationsDMECommentVal"];

  const hema_RecommendationsotherComment =
    tempDbData["hema_RecommendationsotherCommentVal"];

  //cardio
  const ros_cardio = getOptionsSelectedYesNo(tempDbData["ros_cardioVal"]);
  const ros_cardioComment = tempDbData["ros_cardioComment"];
  const ros_cardiovascularRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCardioVal"],
      tempDbData["ros_otherCardioVal"],
      tempDbData["ros_ThirtyCardioVal"],
      tempDbData["ros_bloodPreCardioVal"],
      tempDbData["ros_heartHeCardioVal"],
      tempDbData["ros_caseManagementCardioVal"],
      tempDbData["ros_followupWithCardioVal"],
      tempDbData["ros_durableMedicalEquipCardioVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
      "casemangereferralfollowup",
      "FollowupClinicianSpecialist",
      "memberTouseDurableEquip",
    ],
  );
  const ros_cardioSubDiagnosesComment =
    tempDbData["ros_cardioSubDiagnosesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];
  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmTypeComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmTypeComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFlutterDesComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseCrDesComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseCrDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers =
    tempDbData[
      "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment"
    ];
  const ros_cardioSubDiagnosesAneurysmTreatmentComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmTreatmentComment"];
  const ros_cardioSubDiagnosesAnerysmSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAnerysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer"];
  const ros_cardioSubDiagnosesAnginaCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    tempDbData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaDesComment"];
  const ros_cardioSubDiagnosesAneurysmDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmDesComment"];
  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment =
    tempDbData[
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment"
    ];
  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
    tempDbData[
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub"
    ];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
    tempDbData["ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub"];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub"
    ];
  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];
  const ros_cardiovascularRecommendationsComment =
    tempDbData["ros_cardiovascularRecommendationsComment"];
  const ros_cardioSubDiagnosesCardioresTreatOtherTrSub =
    tempDbData["ros_cardioSubDiagnosesCardioresTreatOtherTrSub"];
  const ros_cardioSubDiagnosesCardiompTreatOtherTrSub =
    tempDbData["ros_cardioSubDiagnosesCardiompTreatOtherTrSub"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment"];
  const ros_cardioSubDiagnosesAneurysmSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAneurysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAnginaSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAnginaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesOtherSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesOtherSupotherSubAnswer"];

  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );

  const ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers"
    ];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDesComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathyDesComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathySupComment"];
  const ros_cardioSubDiagnosesCardiomyopathyTypeComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathyTypeComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSupComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureDesComment"];

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisSupComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisDesComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaSupComment"];
  const ros_cardioSubDiagnosesHypertensionSupComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionSupComment"];
  const ros_cardioSubDiagnosesHypertensionDesComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionDesComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionadequatelyControlledComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );

  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment"];
  const ros_cardioSubDiagnosesMyocardialBetaBlocker = getOptionsSelectedYesNo(
    tempDbData["ros_cardioSubDiagnosesMyocardialBetaBlocker"],
  );
  const ros_cardioSubDiagnosesMyocardialBetaBlockerComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialBetaBlockerComment"];
  const ros_cardioSubDiagnosesMyocardialIspateintComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialIspateintComment"];
  const ros_cardioSubDiagnosesMyocardialSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesMyocardialSupotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialSupComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialSupComment"];
  const ros_cardioSubDiagnosesMyocardialDesComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialDesComment"];
  const ros_cardioSubDiagnosesMyocardialDate =
    tempDbData["ros_cardioSubDiagnosesMyocardialDate"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment"];

  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
    tempDbData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupComment =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionSupComment"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesComment =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacement =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacement"],
    );
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement"];
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation"],
    );
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
    tempDbData[
      "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseSupComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseDesComment"];
  const ros_cardioSubDiagnosesOtherOtherSub =
    tempDbData["ros_cardioSubDiagnosesOtherOtherSub"];
  const ros_cardioSubDiagnosesOtherSupComment =
    tempDbData["ros_cardioSubDiagnosesOtherSupComment"];
  const ros_cardioSubDiagnosesOtherDesComment =
    tempDbData["ros_cardioSubDiagnosesOtherDesComment"];

  const ros_cardiovascularRecommendationsheartHealthyDietComment =
    tempDbData["ros_cardiovascularRecommendationsheartHealthyDietComment"];
  const ros_cardiovascularRecommendationsbloodPressureChecksComment =
    tempDbData["ros_cardiovascularRecommendationsbloodPressureChecksComment"];
  const ros_cardiovascularRecommendationsexerciseThirtyMinADayComment =
    tempDbData["ros_cardiovascularRecommendationsexerciseThirtyMinADayComment"];
  const ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment =
    tempDbData[
      "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment"
    ];
  const ros_cardiovascularRecommendationsotherComment =
    tempDbData["ros_cardiovascularRecommendationsotherComment"];
  const ros_cardiovascularRecommendationsmemberTouseDurableEquipComment =
    tempDbData[
      "ros_cardiovascularRecommendationsmemberTouseDurableEquipComment"
    ];
  const ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment =
    tempDbData[
      "ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment"
    ];

  const ros_cardiovascularRecommendationscasemangereferralfollowupComment =
    tempDbData[
      "ros_cardiovascularRecommendationscasemangereferralfollowupComment"
    ];

  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCardiompathysecondaryToHypertension"],
    );
  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial"
      ],
    );
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment =
    tempDbData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment"
    ];

  const ros_cardioSubDiagnosesCardiorespiratoryShockDesComment =
    tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockDesComment"];
  const ros_cardioSubDiagnosesHeartTransplant =
    tempDbData["ros_cardioSubDiagnosesHeartTransplant"];
  const ros_cardioSubDiagnosesSympComment =
    tempDbData["ros_cardioSubDiagnosesSympComment"];
  const ros_cardioSubDiagnosesSympOtherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesSympOtherSubAnswer"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment =
    tempDbData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment"
    ];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment =
    tempDbData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment"
    ];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment =
    tempDbData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
    tempDbData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment"
    ];

  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper"
      ],
    );
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment"];
  const ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment"];
  const ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
    tempDbData["ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub"];
  const ros_cardioSubDiagnosesHypertensionSympotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesHypertensionSympotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSympComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionSympComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment =
    tempDbData[
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment =
    tempDbData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment =
    tempDbData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment =
    tempDbData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment =
    tempDbData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
    tempDbData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment"];

  //gastro

  const ros_gastrorecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_gastrorecommendationstakemedicationsasprescribed"],
      tempDbData["ros_gastrorecommendationsother"],
      tempDbData["ros_gastrorecommendationscaseManagement"],
      tempDbData["ros_gastrorecommendationsfollowup"],
      tempDbData["ros_gastrorecommendationsuseDme"],
    ],
    [
      "takemedicationsasprescribed",
      "other",
      "caseManagement",
      "followup",
      "useDme",
    ],
  );
  const ros_gastrorecommendationscaseManagementComment =
    tempDbData["ros_gastrorecommendationscaseManagementComment"];
  const ros_gastrorecommendationsfollowupComment =
    tempDbData["ros_gastrorecommendationsfollowupComment"];
  const ros_gastrorecommendationsuseDmeComment =
    tempDbData["ros_gastrorecommendationsuseDmeComment"];
  const ros_gastrorecommendationsotherComment =
    tempDbData["ros_gastrorecommendationsotherComment"];
  const ros_gastrorecommendationstakemedicationsasprescribedComment =
    tempDbData["ros_gastrorecommendationstakemedicationsasprescribedComment"];
  const ros_gastroSubDiagnosesComment =
    tempDbData["ros_gastroSubDiagnosesComment"];
  const ros_gastro = getOptionsSelectedYesNo(tempDbData["ros_gastro"]);
  const ros_gastroComment = tempDbData["ros_gastroComment"];
  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    tempDbData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    tempDbData["ros_gastroSubDiagnosesdesbowelObstructionComment"];
  const ros_gastroSubDiagnosescachexiadesComment =
    tempDbData["ros_gastroSubDiagnosescachexiadesComment"];
  const ros_gastroSubDiagnosescachexiaSupComment =
    tempDbData["ros_gastroSubDiagnosescachexiaSupComment"];
  const ros_gastroSubDiagnosesceliacDiseaseGlueten = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );
  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];
  const ros_gastroSubDiagnosescirrhosisEndStage = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStageComment"];
  const ros_gastroSubDiagnosescirrhosisSupComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosesdescirrhosisComment =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescolonCrDesComment =
    tempDbData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    tempDbData["ros_gastroSubDiagnosescolonPolypsDesComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    tempDbData["ros_gastroSubDiagnosescolonSupComment"];
  const ros_gastroSubSymptomsOtherSub =
    tempDbData["ros_gastroSubSymptomsOtherSub"];
  const ros_gastroSubSymptomsComment =
    tempDbData["ros_gastroSubSymptomsComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisComment =
    tempDbData["ros_gastroSubDiagnosesdesdiverticulitisComment"];
  const ros_gastroSubDiagnosesdiverticulitisabscessVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitisabscessValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessValComment"];
  const ros_gastroSubDiagnosesdiverticulitisperforationVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisperforationValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationValComment"];

  const ros_gastroSubDiagnosesdiverticulitishighFiberDietVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment"];
  const ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment =
    tempDbData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment"];
  const ros_endocrineIsEndocrineRecommendationsComment =
    tempDbData["ros_endocrineIsEndocrineRecommendationsComment"];
  const ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescolonOtherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescolonOtherSubAnswerVal"];
  const ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgerdSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgerdSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal"];
  const ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal"
    ];
  const ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesotherSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesotherSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment"];
  const ros_gastroSubDiagnosesdiverticulitisSupComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisSupComment"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupComment =
    tempDbData["ros_gastroSubDiagnosesgallBladderDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesgallBladderDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesdesgallBladderDiseaseComment"];
  const ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesgastroparesisSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesgastroparesisSupportedByComment"];
  const ros_gastroSubDiagnosesgastroparesisDescribeComment =
    tempDbData["ros_gastroSubDiagnosesgastroparesisDescribeComment"];
  const ros_gastroSubDiagnosesgerdDescribeComment =
    tempDbData["ros_gastroSubDiagnosesgerdDescribeComment"];
  const ros_gastroSubDiagnosesgerdSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesgerdSupportedByComment"];
  const ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedDescribeComment =
    tempDbData["ros_gastroSubDiagnosesglBleedDescribeComment"];
  const ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesglBleedSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesglBleedSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDescribeComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisDescribeComment"];
  const ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnoseshepatitisSupportedByComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisSupportedByComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
    tempDbData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherDescribeComment =
    tempDbData["ros_gastroSubDiagnosesotherDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisDescribeComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesotherSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesotherSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesulcerDiseaseDescribeComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDessComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisDessComment"];
  const ros_gastroSubDiagnoseshepatitisTypeComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisTypeComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosis = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnoseshepatitisCirrhosis"],
  );
  const ros_gastroCiroalcoholRelated = getOptionsSelectedYesNo(
    tempDbData["ros_gastroCiroalcoholRelated"],
  );
  const ros_gastroCiroalcoholRelatedComment =
    tempDbData["ros_gastroCiroalcoholRelatedComment"];
  const ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice"],
    );
  const ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment =
    tempDbData["ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosisComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisCirrhosisComment"];
  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma"],
    );
  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet"],
    );
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment"
    ];
  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment"];

  const ros_gastroSubDiagnosespancreatitisSpecificDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosespancreatitisSpecificDiet"],
    );
  const ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol"],
    );
  const ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment =
    tempDbData["ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment"];
  const ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
    tempDbData[
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub"
    ];
  const ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment =
    tempDbData[
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment"
    ];

  const ros_gastroSubDiagnosespancreatitisSpecificDietComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisSpecificDietComment"];

  const ros_gastroSubDiagnosesulcerDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseComment"];
  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment"];

  const ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactria =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesulcerDiseaseHeliobactria"],
    );
  const ros_gastroSubDiagnosesotherOther =
    tempDbData["ros_gastroSubDiagnosesotherOther"];
  const ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesgerdActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesgerdActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment"];
  const ros_gastroDateOfTransplant = tempDbData["ros_gastroDateOfTransplant"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesglBleedActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubBlood =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdesglBleedActiveSubBlood"],
    );
  const ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment =
    tempDbData["ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment"];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns"];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment"];
  const ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
    tempDbData[
      "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub"
    ];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdescachexiaActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdescachexiaActiveSubTreComment"];
  const ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
    tempDbData["ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment =
    tempDbData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia"],
    );
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment =
    tempDbData["ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment"];

  //genito
  const ros_genito = getOptionsSelectedYesNo(tempDbData["ros_genito"]);
  const ros_genitoComment = tempDbData["ros_genitoComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];
  const ros_genitoVal = getOptionsSelectedYesNo(tempDbData["ros_genitoVal"]);
  const ros_genitoValComment = tempDbData["ros_genitoValComment"];
  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal"];
  const ros_genitoSubDiagnosesacuteRenalFailureEtiology =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureEtiology"];

  const ros_genitoSubDiagnosesbphDescribeComment =
    tempDbData["ros_genitoSubDiagnosesbphDescribeComment"];

  const ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesbhpActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesbhpActiveSubTreComment"];

  const ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdeschronicActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdeschronicActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeschronicActiveSubSec =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData["ros_genitoSubDiagnosesdeschronicActiveSubSec"],
    );
  const ros_genitoSubDiagnosesdeschronicActiveSubSecComment =
    tempDbData["ros_genitoSubDiagnosesdeschronicActiveSubSecComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
    tempDbData["ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment"];

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension"
      ],
    );

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
    tempDbData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment"
    ];
  const ros_genitoSubDiagnoseswhatStageComment =
    tempDbData["ros_genitoSubDiagnoseswhatStageComment"];
  const ros_genitoSubDiagnosesesrdDescribeComment =
    tempDbData["ros_genitoSubDiagnosesesrdDescribeComment"];

  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
    tempDbData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet"],
    );

  const ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment"];
  const ros_genitoSubDiagnosesdesesrdActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesesrdActiveSubTreComment"];
  const ros_genitoSubDiagnoActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnoActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesgynecoActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesgynecoActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeskidneyActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdeskidneyActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesfreqsActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesfreqsActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdeserectileActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdeserectileActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesErectileTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesErectileTreOtherSub"];
  const ros_genitoSubDiagnosesdesOtherActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesOtherActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesacuteActiveSubTreComment =
    tempDbData["ros_genitoSubDiagnosesdesacuteActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub =
    tempDbData["ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment =
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment"];
  const ros_genitoSubDiagnosesesrddialysisSchedule =
    tempDbData["ros_genitoSubDiagnosesesrddialysisSchedule"];
  const ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
    tempDbData["ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice"];
  const ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
    tempDbData["ros_genitoSubDiagnoseserectileDysfunctionDescribeComment"];

  const ros_genitoSubDiagnosesfrequentUtiDescribeComment =
    tempDbData["ros_genitoSubDiagnosesfrequentUtiDescribeComment"];

  const ros_genitoSubDiagnosesgynecologicalDescribeComment =
    tempDbData["ros_genitoSubDiagnosesgynecologicalDescribeComment"];

  const ros_genitoSubDiagnoseskidneyStonesDescribeComment =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesDescribeComment"];

  const ros_genitoSubDiagnoseskidneyStonesTypeComment =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesTypeComment"];

  const ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
    tempDbData["ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment"];

  const ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
    tempDbData["ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment"];

  const ros_genitoSubDiagnosesotherDescribeComment =
    tempDbData["ros_genitoSubDiagnosesotherDescribeComment"];

  const ros_genitoSubDiagnosesotherOther =
    tempDbData["ros_genitoSubDiagnosesotherOther"];
  const ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
    tempDbData["ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment"];
  const ros_genitoSubSymptomsComment =
    tempDbData["ros_genitoSubSymptomsComment"];
  const ros_genitoSubSymptomsOtherSub =
    tempDbData["ros_genitoSubSymptomsOtherSub"];
  const ros_genitoStress = getOptionsSelectedYesNo(
    tempDbData["ros_genitoStress"],
  );
  const ros_genitoStressComment = tempDbData["ros_genitoStressComment"];
  const ros_genitoStressSubComment = tempDbData["ros_genitoStressSubComment"];

  const ros_genitoRecommendationsotherGenitoComment =
    tempDbData["ros_genitoRecommendationsotherGenitoComment"];
  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    tempDbData[
      "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment"
    ];
  const ros_genitoRecommendationscasemanagementrefGenitoComment =
    tempDbData["ros_genitoRecommendationscasemanagementrefGenitoComment"];
  const ros_genitoRecommendationsmemberDurableGenitoComment =
    tempDbData["ros_genitoRecommendationsmemberDurableGenitoComment"];
  const ros_genitoRecommendationsfollowupwithclinispecGenitoComment =
    tempDbData["ros_genitoRecommendationsfollowupwithclinispecGenitoComment"];
  const ros_genitoRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedGenitoVal"],
      tempDbData["ros_otherGenitoVal"],
      tempDbData["ros_memberDurableGenitoVal"],
      tempDbData["ros_casemanagementrefGenitoVal"],
      tempDbData["ros_followupwithclinispecGenitoVal"],
    ],
    [
      "takeMedicationsAsPrescribedGenito",
      "otherGenito",
      "memberDurableGenito",
      "casemanagementrefGenito",
      "followupwithclinispecGenito",
    ],
  );
  const ros_genitoSubDiagnosesComment =
    tempDbData["ros_genitoSubDiagnosesComment"];

  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  // docancer
  const ros_diagnosisOfCancerSubSupOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubSupOtherValSubAnswer"];
  const ros_diagnosisOfCancerstageSpecificToTheCancer =
    tempDbData["ros_diagnosisOfCancerSubStageSpecCanVal"];
  const ros_diagnosisOfCancerspecialTypes =
    tempDbData["ros_diagnosisOfCancerSubSpecTyVal"];
  const ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment =
    tempDbData[
      "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment =
    tempDbData[
      "ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment =
    tempDbData[
      "ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment =
    tempDbData[
      "ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsComment =
    tempDbData["ros_diagnosisOfCancerRecommendationsComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaVal = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
  );

  const ros_diagnosisOfCancerComment =
    tempDbData["ros_diagnosisOfCancerCommentVal"];

  const ros_diagnosisOfCancerRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCancerVal"],
      tempDbData["ros_otherCancerVal"],
      tempDbData["ros_casemanagementCancerVal"],
      tempDbData["ros_followupWithCancerVal"],
      tempDbData["ros_durableMedicalCancerVal"],
    ],
    [
      "takemedicationsasprescribedCancer",
      "otherCancer",
      "CasemanagementfollowupreferralCancer",
      "followupwithclinispecCancer",
      "membertousedurablemedicalequipCancer",
    ],
  );
  const ros_diagnosisOfCancerRecommendationsotherCancerComment =
    tempDbData["ros_diagnosisOfCancerRecommendationsotherCancerComment"];
  const ros_diagnosisOfCancerros_activeTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubActiveTreatVal"],
  );
  const ros_diagnosisOfCancerros_Metastasis = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisVal"],
  );
  const ros_diagnosisOfCancerros_SeeASpec = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubSeeASpecVal"],
  );
  const ros_diagnosisOfCancerSubSeeASpecProVal =
    tempDbData["ros_diagnosisOfCancerSubSeeASpecProVal"];
  const ros_diagnosisOfCancerSubDescComment =
    tempDbData["ros_diagnosisOfCancerSubDescComment"];
  const ros_diagnosisOfCancerSubSupComment =
    tempDbData["ros_diagnosisOfCancerSubSupComment"];
  const ros_diagnosisOfCancerSubTypeComment =
    tempDbData["ros_diagnosisOfCancerSubTypeComment"];
  const ros_diagnosisOfCancerSubSideEffComment =
    tempDbData["ros_diagnosisOfCancerSubSideEffComment"];
  const ros_diagnosisOfCancerSubActiveTreatComment =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatComment"];
  const ros_diagnosisOfCancerros_SeeASpecComment =
    tempDbData["ros_diagnosisOfCancerros_SeeASpecComment"];
  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_diagnosisOfCancerros_MetastasisComment =
    tempDbData["ros_diagnosisOfCancerros_MetastasisComment"];
  const ros_diagnosisOfCancerros_activeTreatmentComment =
    tempDbData["ros_diagnosisOfCancerros_activeTreatmentComment"];
  const ros_diagnosisOfCancerSubMetastasisLocVal =
    tempDbData["ros_diagnosisOfCancerSubMetastasisLocVal"];
  const ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubTypeOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubTypeOtherValSubAnswer"];
  const ros_diagnosisOfCancer = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerVal"],
  );
  const ros_diagnosisOfCancertitl5Years = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancertitl5Years"],
  );
  const ros_diagnosisOfCancertitl5YearsComment =
    tempDbData["ros_diagnosisOfCancertitl5YearsComment"];

  //endo
  const ros_endocrineIsEndocrine = getOptionsSelectedYesNo(
    tempDbData["ros_endocrineIsEndocrine"],
  );
  const ros_endocrineIsEndocrineComment =
    tempDbData["ros_endocrineIsEndocrineComment"];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment"
    ];

  const ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment"
    ];

  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment"
    ];
  const ros_endocrineIsEndocrineSubneuroSubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubneuroSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubNeuroSubSympComment =
    tempDbData["ros_endocrineIsEndocrineSubNeuroSubSympComment"];
  const ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
    tempDbData["ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe"];
  const ros_endocrineIsEndocrineSubNeuroSubTreatComment =
    tempDbData["ros_endocrineIsEndocrineSubNeuroSubTreatComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist"
      ],
    );

  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment"
    ];

  const ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
    tempDbData["ros_endocrineIsEndocrineSubchronicKidneySubStageComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineNeuroSubPatientOftenComment =
    tempDbData["ros_endocrineIsEndocrineNeuroSubPatientOftenComment"];

  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment"
    ];

  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
    tempDbData["ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment"];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment"];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseotherDescribeComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherDescribeComment"];
  const ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer"];
  const ros_endocrineIsEndocrineDiagnoseotherSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherOther =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherOther"];

  const ros_endocrineIsEndocrineNoEdu =
    tempDbData["ros_endocrineIsEndocrineNoEdu"] == "1"
      ? ["educationProvidNo"]
      : [];
  const ros_endocrineIsEndocrineUnknownEdu =
    tempDbData["ros_endocrineIsEndocrineUnknownEdu"] == "1"
      ? ["educationProvidUnknown"]
      : [];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment"
    ];

  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub =
    getSelectedCheckboxesOthers(
      [
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubonceAYear"
        ],
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubtwiceAYear"
        ],
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubquarterly"
        ],
      ],
      ["onceAYear", "twiceAYear", "quarterly"],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub =
    getSelectedCheckboxesOthers(
      [
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubonceAYear"
        ],
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubtwiceAYear"
        ],
        tempDbData[
          "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubquarterly"
        ],
      ],
      ["onceAYear", "twiceAYear", "quarterly"],
    );
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
    tempDbData["ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
    tempDbData["ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c =
    tempDbData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c"];
  const ros_endocrineIsEndocrineSubdiabetesSubandDate =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubandDate"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet =
    getOptionsSelectedYesNo(
      tempDbData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet"],
    );
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest =
    getOptionsSelectedYesNo(
      tempDbData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest"],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment"
    ];
  const ros_endocrineIsEndocrineSubVascularSubPhysicalComment =
    tempDbData["ros_endocrineIsEndocrineSubVascularSubPhysicalComment"];
  const ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubVascularSubTreatComment =
    tempDbData["ros_endocrineIsEndocrineSubVascularSubTreatComment"];
  const ros_endocrineIsEndocrineSubAutonomicSubTreatComment =
    tempDbData["ros_endocrineIsEndocrineSubAutonomicSubTreatComment"];
  const ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
    tempDbData["ros_endocrineIsEndocrineSubchronicKidneySubTreatComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr"],
    );
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takeMedicationsAsPescribedEndoEndocrineVal"],
      tempDbData["ros_checkBloodSugarEndoEndorineVal"],
      tempDbData["ros_otherEndoEndocrineVal"],
      tempDbData["ros_durableMedicalEquipEndoEndocrineVal"],
      tempDbData["ros_followupspecEndoEndocrineVal"],
      tempDbData["ros_caseMangeEndoEndocrineVal"],
      tempDbData["ros_eGFREndoEndocrineVal"],
    ],
    [
      "takeMedicationsAsPescribedEndo",
      "checkBloodSugarEndo",
      "otherEndo",
      "durablemedicalequipEndo",
      "followupwithclinispecEndo",
      "casemangfollowEndo",
      "albuminRatioEndo",
    ],
  );
  const ros_endocrineIsEndocrineDiagnoseComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseComment"];

  const ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment"
    ];

  const ros_endocrineIsEndocrineRecommendationsotherEndoComment =
    tempDbData["ros_endocrineIsEndocrineRecommendationsotherEndoComment"];

  const ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment"
    ];

  const ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment"
    ];

  tempDbData = {
    ...tempData,
    ros_respiratoryComment: ros_respiratoryComment || "",
    ros_respiratory,

    ros_respiratoryAcuteUpperSubAnswerDescribeComment:
      ros_respiratoryAcuteUpperSubAnswerDescribeComment || "",
    ros_respiratoryDiagnosesComment,
    ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment:
      ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment || "",
    ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment:
      ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment ||
      "",
    ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc:
      ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc || "",
    ros_respiratoryAsthmaSubOtherTreatmentSubDesc:
      ros_respiratoryAsthmaSubOtherTreatmentSubDesc || "",
    ros_respiratoryAsthmaActiveTreatmentSubAnswerComment:
      ros_respiratoryAsthmaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryAsthmaMedicationSubAnswerComment:
      ros_respiratoryAsthmaMedicationSubAnswerComment || "",
    ros_respiratoryAsthmaCurrentExacerbation:
      ros_respiratoryAsthmaCurrentExacerbation || "",
    ros_respiratoryAsthmaCurrentExacerbationComment:
      ros_respiratoryAsthmaCurrentExacerbationComment || "",
    ros_respiratoryAsthmaSubAnswerDescribeComment:
      ros_respiratoryAsthmaSubAnswerDescribeComment || "",
    ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment:
      ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment || "",
    ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc:
      ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc || "",
    ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment:
      ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment || "",
    ros_respiratoryChronicSputumFailureSubAnswerDescribeComment:
      ros_respiratoryChronicSputumFailureSubAnswerDescribeComment || "",
    ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment:
      ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment || "",
    ros_respiratoryCopdSubAnswerDescribeComment:
      ros_respiratoryCopdSubAnswerDescribeComment || "",
    ros_respiratoryCopdTypeSubAnswerComment:
      ros_respiratoryCopdTypeSubAnswerComment || "",
    ros_respiratoryCopdSubOtherTreatmentSubDesc:
      ros_respiratoryCopdSubOtherTreatmentSubDesc || "",
    ros_respiratoryCopdActiveTreatmentSubAnswerComment:
      ros_respiratoryCopdActiveTreatmentSubAnswerComment || "",
    ros_respiratoryCopdMedicationSubAnswerComment:
      ros_respiratoryCopdMedicationSubAnswerComment || "",
    ros_respiratoryPatientExacerbation:
      ros_respiratoryPatientExacerbation || "",
    ros_respiratoryPatientExacerbationComment:
      ros_respiratoryPatientExacerbationComment || "",
    ros_respiratoryCopdSubPatientExacerbationComment:
      ros_respiratoryCopdSubPatientExacerbationComment || "",
    ros_respiratoryCysticFibrosisSubAnswerDescribeComment:
      ros_respiratoryCysticFibrosisSubAnswerDescribeComment || "",
    ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc:
      ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc || "",
    ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc:
      ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc || "",
    ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment:
      ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoventilationSubAnswerDescribeComment:
      ros_respiratoryHypoventilationSubAnswerDescribeComment || "",
    ros_respiratoryHypoventilationSubOtherTreatmentSubDesc:
      ros_respiratoryHypoventilationSubOtherTreatmentSubDesc || "",
    ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment:
      ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoventilationActiveSelectSubAnswerComment:
      ros_respiratoryHypoventilationActiveSelectSubAnswerComment || "",
    ros_respiratoryHypoxemiaSubAnswerDescribeComment:
      ros_respiratoryHypoxemiaSubAnswerDescribeComment || "",
    ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc:
      ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc || "",
    ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment:
      ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoxemiaActiveSelectSubAnswerComment:
      ros_respiratoryHypoxemiaActiveSelectSubAnswerComment || "",
    ros_respiratoryPneumoniaSubAnswerDescribeComment:
      ros_respiratoryPneumoniaSubAnswerDescribeComment || "",
    ros_respiratoryPneumoniaSubAnswerEtiologyComment:
      ros_respiratoryPneumoniaSubAnswerEtiologyComment || "",
    ros_respiratoryHistoryOfLungAbscess:
      ros_respiratoryHistoryOfLungAbscess || "",
    ros_respiratoryHistoryOfEmpyema: ros_respiratoryHistoryOfEmpyema || "",
    ros_respiratoryHistoryOfLungAbscessComment:
      ros_respiratoryHistoryOfLungAbscessComment || "",
    ros_respiratoryHistoryOfEmpyemaComment:
      ros_respiratoryHistoryOfEmpyemaComment || "",
    ros_respiratoryPneumoniaSubOtherTreatmentSubDesc:
      ros_respiratoryPneumoniaSubOtherTreatmentSubDesc || "",
    ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment:
      ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment:
      ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment || "",
    ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment:
      ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment || "",
    ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment || "",
    ros_respiratoryRespiratorDependenceSubAnswerDescribeComment:
      ros_respiratoryRespiratorDependenceSubAnswerDescribeComment || "",
    ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc:
      ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment:
      ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment || "",
    ros_respiratoryRespiratoryArrestSubAnswerDescribeComment:
      ros_respiratoryRespiratoryArrestSubAnswerDescribeComment || "",
    ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc:
      ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment:
      ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment || "",
    ros_respiratorySarcoidosisSubAnswerDescribeComment:
      ros_respiratorySarcoidosisSubAnswerDescribeComment || "",
    ros_respiratorySarcoidosisSubAnswerSpecifyComment:
      ros_respiratorySarcoidosisSubAnswerSpecifyComment || "",
    ros_respiratorySarcoidosisSubOtherTreatmentSubDesc:
      ros_respiratorySarcoidosisSubOtherTreatmentSubDesc || "",
    ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment:
      ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment || "",
    ros_respiratorySleepApneaSubAnswerDescribeComment:
      ros_respiratorySleepApneaSubAnswerDescribeComment || "",
    ros_respiratorySleepApneaActiveSelectSubAnswer:
      ros_respiratorySleepApneaActiveSelectSubAnswer || "",
    ros_respiratorySleepApneaActiveSelectSubAnswerComment:
      ros_respiratorySleepApneaActiveSelectSubAnswerComment || "",
    ros_respiratorySleepApneaSubOtherTreatmentSubDesc:
      ros_respiratorySleepApneaSubOtherTreatmentSubDesc || "",
    ros_respiratorySleepApneaActiveTreatmentSubAnswerComment:
      ros_respiratorySleepApneaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryLungTransplantSubOtherTreatmentSubDesc:
      ros_respiratoryLungTransplantSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespOtherSubAnswerDescribeComment:
      ros_respiratoryRespOtherSubAnswerDescribeComment || "",
    ros_respiratoryOtherSubDesc: ros_respiratoryOtherSubDesc || "",
    ros_respiratoryOtherSubAnswerOther:
      ros_respiratoryOtherSubAnswerOther || "",
    ros_respiratoryRespOtherSubAnswerSupportedByComment:
      ros_respiratoryRespOtherSubAnswerSupportedByComment || "",
    ros_respiratorySymptomsSubOtherSubDesc:
      ros_respiratorySymptomsSubOtherSubDesc || "",
    ros_respiratorySymptomsComment: ros_respiratorySymptomsComment || "",

    respiratory_Recommendations,
    respiratory_RecommendationstakeMedicationsComment,
    respiratory_RecommendationscaseManagementComment,
    respiratory_RecommendationsfollowUpClinicianComment,
    respiratory_RecommendationsDMEComment,
    respiratory_RecommendationsotherComment,

    ros_integumentComment: ros_integumentComment || "",
    ros_integumentDiagnosesComment: ros_integumentDiagnosesComment || "",
    ros_integumentBasilCellCarcinomaSubAnswerDescribeComment:
      ros_integumentBasilCellCarcinomaSubAnswerDescribeComment || "",
    ros_integumentBasilCellCarcinomaSubLocation:
      ros_integumentBasilCellCarcinomaSubLocation || "",
    ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc:
      ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc || "",
    ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment:
      ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment || "",
    ros_integument: ros_integument || "",
    ros_integumentDermatitisSubAnswerDescribeComment:
      ros_integumentDermatitisSubAnswerDescribeComment || "",
    ros_integumentDermatitisSubLocation:
      ros_integumentDermatitisSubLocation || "",
    ros_integumentDermatitisSubOtherTreatmentSubDesc:
      ros_integumentDermatitisSubOtherTreatmentSubDesc || "",
    ros_integumentDermatitisActiveTreatmentSubAnswerComment:
      ros_integumentDermatitisActiveTreatmentSubAnswerComment || "",
    ros_integumentDermatitisSubAnswerWhatTypeComment:
      ros_integumentDermatitisSubAnswerWhatTypeComment || "",
    ros_integumentEczemaSubAnswerDescribeComment:
      ros_integumentEczemaSubAnswerDescribeComment || "",
    ros_integumentEczemaSubLocation: ros_integumentEczemaSubLocation || "",
    ros_integumentEczemaSubOtherTreatmentSubDesc:
      ros_integumentEczemaSubOtherTreatmentSubDesc || "",
    ros_integumentEczemaActiveTreatmentSubAnswerComment:
      ros_integumentEczemaActiveTreatmentSubAnswerComment || "",
    ros_integumentOnychomycosisSubAnswerDescribeComment:
      ros_integumentOnychomycosisSubAnswerDescribeComment || "",
    ros_integumentOnychomycosisSubLocation:
      ros_integumentOnychomycosisSubLocation || "",
    ros_integumentOnychomycosisSubOtherTreatmentSubDesc:
      ros_integumentOnychomycosisSubOtherTreatmentSubDesc || "",
    ros_integumentOnychomycosisActiveTreatmentSubAnswerComment:
      ros_integumentOnychomycosisActiveTreatmentSubAnswerComment || "",
    ros_integumentPsoriasisSubAnswerDescribeComment:
      ros_integumentPsoriasisSubAnswerDescribeComment || "",
    ros_integumentPsoriasisSubLocation:
      ros_integumentPsoriasisSubLocation || "",
    ros_integumentPsoriasisSubOtherTreatmentSubDesc:
      ros_integumentPsoriasisSubOtherTreatmentSubDesc || "",
    ros_integumentPsoriasisActiveTreatmentSubAnswerComment:
      ros_integumentPsoriasisActiveTreatmentSubAnswerComment || "",
    ros_integumentPsoriasisSubAnswerPsoriaticArthritis:
      ros_integumentPsoriasisSubAnswerPsoriaticArthritis || "",
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment:
      ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment || "",
    ros_integumentSkinUlcerSubAnswerDescribeComment:
      ros_integumentSkinUlcerSubAnswerDescribeComment || "",
    ros_integumentSkinUlcerSubLocation:
      ros_integumentSkinUlcerSubLocation || "",
    ros_integumentSkinUlcerSubOtherTreatmentSubDesc:
      ros_integumentSkinUlcerSubOtherTreatmentSubDesc || "",
    ros_integumentSkinUlcerActiveTreatmentSubAnswerComment:
      ros_integumentSkinUlcerActiveTreatmentSubAnswerComment || "",
    ros_integumentSkinUlcerSubEtiologySubDesc:
      ros_integumentSkinUlcerSubEtiologySubDesc || "",
    ros_integumentSkinUlcerSubAnswerEtiologyComment:
      ros_integumentSkinUlcerSubAnswerEtiologyComment || "",
    ros_integumentTineaPedisSubAnswerDescribeComment:
      ros_integumentTineaPedisSubAnswerDescribeComment || "",
    ros_integumentTineaPedisSubOtherTreatmentSubDesc:
      ros_integumentTineaPedisSubOtherTreatmentSubDesc || "",
    ros_integumentTineaPedisActiveTreatmentSubAnswerComment:
      ros_integumentTineaPedisActiveTreatmentSubAnswerComment || "",
    ros_integumentTineaPedisSelectTreatmentSubAnswerComment:
      ros_integumentTineaPedisSelectTreatmentSubAnswerComment || "",
    ros_integumentUrticarialDiseaseSubAnswerDescribeComment:
      ros_integumentUrticarialDiseaseSubAnswerDescribeComment || "",
    ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc:
      ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc || "",
    ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment:
      ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment || "",
    ros_integumentUrticarialDiseaseSubAnswerTypeComment:
      ros_integumentUrticarialDiseaseSubAnswerTypeComment || "",
    ros_integumentUrticarialDiseaseSubAnswerEtiology:
      ros_integumentUrticarialDiseaseSubAnswerEtiology || "",
    ros_integumentUrticarialDiseaseSubLocation:
      ros_integumentUrticarialDiseaseSubLocation || "",
    ros_integumentWoundSubAnswerDescribeComment:
      ros_integumentWoundSubAnswerDescribeComment || "",
    ros_integumentWoundSubOtherTreatmentSubDesc:
      ros_integumentWoundSubOtherTreatmentSubDesc || "",
    ros_integumentWoundSubLocation: ros_integumentWoundSubLocation || "",
    ros_integumentWoundActiveTreatmentSubAnswerComment:
      ros_integumentWoundActiveTreatmentSubAnswerComment || "",
    ros_integumentWoundSubAnswerEtiologyComment:
      ros_integumentWoundSubAnswerEtiologyComment || "",
    ros_integumentOthersSubAnswerDescribeComment:
      ros_integumentOthersSubAnswerDescribeComment || "",
    ros_integumentOthersSubLocation: ros_integumentOthersSubLocation || "",
    ros_integumentOthersSubOtherTreatmentSubDesc:
      ros_integumentOthersSubOtherTreatmentSubDesc || "",
    ros_integumentOthersActiveTreatmentSubAnswerComment:
      ros_integumentOthersActiveTreatmentSubAnswerComment || "",
    ros_integumentOthersSubAnswerOther:
      ros_integumentOthersSubAnswerOther || "",
    integument_Recommendations,
    integument_RecommendationstakeMedicationsAsPescribedComment,
    integument_RecommendationscaseManagementComment,
    integument_RecommendationsfollowUpClinicianComment,
    integument_RecommendationsDMEComment,
    integument_RecommendationsotherComment,

    ros_musculoskeletal: ros_musculoskeletal || "",
    ros_musculoskeletalComment: ros_musculoskeletalComment || "",
    ros_musculoskeletalDiagnosesComment:
      ros_musculoskeletalDiagnosesComment || "",
    ros_musculoskeletalDermatomyositisSubAnswerDescribeComment:
      ros_musculoskeletalDermatomyositisSubAnswerDescribeComment || "",
    ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc:
      ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc:
      ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc:
      ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment:
      ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment ||
      "",
    ros_musculoskeletalExtremityFractureSubAnswerDescribeComment:
      ros_musculoskeletalExtremityFractureSubAnswerDescribeComment || "",
    ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment:
      ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment:
      ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc:
      ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment:
      ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureSubAnswerExtremityComment:
      ros_musculoskeletalExtremityFractureSubAnswerExtremityComment || "",
    ros_musculoskeletalExtremityFractureShoulderSubAnswerComment:
      ros_musculoskeletalExtremityFractureShoulderSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureArmSubAnswerComment:
      ros_musculoskeletalExtremityFractureArmSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureForearmSubAnswerComment:
      ros_musculoskeletalExtremityFractureForearmSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureWristSubAnswerComment:
      ros_musculoskeletalExtremityFractureWristSubAnswerComment || "",
    ros_musculoskeletalHandSubAnswerComment:
      ros_musculoskeletalHandSubAnswerComment || "",
    ros_musculoskeletalFemoralShaftSubAnswerComment:
      ros_musculoskeletalFemoralShaftSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureTibiaSubAnswerComment:
      ros_musculoskeletalExtremityFractureTibiaSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureFibulaSubAnswerComment:
      ros_musculoskeletalExtremityFractureFibulaSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureAnkleSubAnswerComment:
      ros_musculoskeletalExtremityFractureAnkleSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureFootSubAnswerComment:
      ros_musculoskeletalExtremityFractureFootSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureHipSubAnswerComment:
      ros_musculoskeletalExtremityFractureHipSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer:
      ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer ||
      "",
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment:
      ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment ||
      "",
    ros_musculoskeletalGoutSubAnswerDescribeComment:
      ros_musculoskeletalGoutSubAnswerDescribeComment || "",
    ros_musculoskeletalGoutSubOtherTreatmentSubDesc:
      ros_musculoskeletalGoutSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalGoutActiveTreatmentSubAnswerComment:
      ros_musculoskeletalGoutActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalHalluxValgusSubAnswerDescribeComment:
      ros_musculoskeletalHalluxValgusSubAnswerDescribeComment || "",
    ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc:
      ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment:
      ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment || "",
    ros_musculoskeletalHammerToesSubAnswerDescribeComment:
      ros_musculoskeletalHammerToesSubAnswerDescribeComment || "",
    ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment:
      ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment || "",
    ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc:
      ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment:
      ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints:
      ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment:
      ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment:
      ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteomyelitisSubLocation:
      ros_musculoskeletalOsteomyelitisSubLocation || "",
    ros_musculoskeletalOsteoporosisSubAnswerDescribeComment:
      ros_musculoskeletalOsteoporosisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment:
      ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints:
      ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment:
      ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment ||
      "",
    ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints:
      ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalSclerodermaSubAnswerDescribeComment:
      ros_musculoskeletalSclerodermaSubAnswerDescribeComment || "",
    ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc:
      ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment:
      ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment || "",
    ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc:
      ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder:
      ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder || "",
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment:
      ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment:
      ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment || "",
    ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc:
      ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc || "",
    ros_musculoskeletalSystemicLupusSubAnswerDescribeComment:
      ros_musculoskeletalSystemicLupusSubAnswerDescribeComment || "",
    ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc:
      ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalMuskoOtherSubAnswerDescribeComment:
      ros_musculoskeletalMuskoOtherSubAnswerDescribeComment || "",
    ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc:
      ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment:
      ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalMuskoOtherSubAnswerother:
      ros_musculoskeletalMuskoOtherSubAnswerother || "",
    ros_musculoskeletalMuskoSymptomsSubOtherSubDesc:
      ros_musculoskeletalMuskoSymptomsSubOtherSubDesc || "",
    ros_musculoskeletalMuskoSymptomsComment:
      ros_musculoskeletalMuskoSymptomsComment || "",
    ros_musculoskeletalHadAnAmputationSubAnswer:
      ros_musculoskeletalHadAnAmputationSubAnswer || "",
    ros_musculoskeletalMuskoHadAnAmputation:
      ros_musculoskeletalMuskoHadAnAmputation || "",
    ros_musculoskeletalMuskoHadAnAmputationComment:
      ros_musculoskeletalMuskoHadAnAmputationComment || "",
    musko_Recommendations,
    musko_RecommendationsdiscussPtOtComment,
    musko_RecommendationstakeMedicationsAsPescribedComment,
    musko_RecommendationscaseManagementComment,
    musko_RecommendationsfollowUpClinicianComment,
    musko_RecommendationsDMEComment,
    musko_RecommendationsotherComment,

    ros_hematology: ros_hematology || "",
    ros_hematologyComment: ros_hematologyComment || "",
    ros_hematologyDiagnosesComment: ros_hematologyDiagnosesComment || "",
    ros_hematologyAidsSubAnswerDescribeComment:
      ros_hematologyAidsSubAnswerDescribeComment || "",
    ros_hematologyAidsSubOtherTreatmentSubDesc:
      ros_hematologyAidsSubOtherTreatmentSubDesc || "",
    ros_hematologyAidsActiveTreatmentSubAnswerComment:
      ros_hematologyAidsActiveTreatmentSubAnswerComment || "",
    ros_hematologyAnemiaSubAnswerDescribeComment:
      ros_hematologyAnemiaSubAnswerDescribeComment || "",
    ros_hematologyAnemiaSubAnswerEtiologyComment:
      ros_hematologyAnemiaSubAnswerEtiologyComment || "",
    ros_hematologyAnemiaSubEtiologySubOtherSubDesc:
      ros_hematologyAnemiaSubEtiologySubOtherSubDesc || "",
    ros_hematologyAnemiaSubOtherTreatmentSubDesc:
      ros_hematologyAnemiaSubOtherTreatmentSubDesc || "",
    ros_hematologyAnemiaActiveTreatmentSubAnswerComment:
      ros_hematologyAnemiaActiveTreatmentSubAnswerComment || "",
    ros_hematologyAnemiaSubYesPatientOnSubDesc:
      ros_hematologyAnemiaSubYesPatientOnSubDesc || "",
    ros_hematologyAnemiaSubAnswerYesPatientOnComment:
      ros_hematologyAnemiaSubAnswerYesPatientOnComment || "",
    ros_hematologyCDifficileSubAnswerDescribeComment:
      ros_hematologyCDifficileSubAnswerDescribeComment || "",
    ros_hematologyCDifficileSubOtherTreatmentSubDesc:
      ros_hematologyCDifficileSubOtherTreatmentSubDesc || "",
    ros_hematologyCDifficileActiveTreatmentSubAnswerComment:
      ros_hematologyCDifficileActiveTreatmentSubAnswerComment || "",
    ros_hematologyCommunityAcquiredSubAnswerDescribeComment:
      ros_hematologyCommunityAcquiredSubAnswerDescribeComment || "",
    ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc:
      ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc || "",
    ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment:
      ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment || "",
    ros_hematologyHivSubAnswerPatientSymptomatic:
      ros_hematologyHivSubAnswerPatientSymptomatic || "",
    ros_hematologyHivSubAnswerActiveTreatment:
      ros_hematologyHivSubAnswerActiveTreatment || "",
    ros_hematologyHivSubAnswerViralLoad:
      ros_hematologyHivSubAnswerViralLoad || "",
    ros_hematologyHivSubAnswerCFour: ros_hematologyHivSubAnswerCFour || "",
    ros_hematologyHivSubAnswerPatientSymptomaticComment:
      ros_hematologyHivSubAnswerPatientSymptomaticComment || "",
    ros_hematologyHivSubAnswerActiveTreatmentComment:
      ros_hematologyHivSubAnswerActiveTreatmentComment || "",
    ros_hematologyHivSubOtherTreatmentSubDesc:
      ros_hematologyHivSubOtherTreatmentSubDesc || "",
    ros_hematologyHivActiveTreatmentSubAnswerComment:
      ros_hematologyHivActiveTreatmentSubAnswerComment || "",
    ros_hematologyHivSubAnswerDescribeComment:
      ros_hematologyHivSubAnswerDescribeComment || "",
    ros_hematologyHerpesZosterSubAnswerDescribeComment:
      ros_hematologyHerpesZosterSubAnswerDescribeComment || "",
    ros_hematologyHerpesZosterTypeSubAnswerComment:
      ros_hematologyHerpesZosterTypeSubAnswerComment || "",
    ros_hematologyHerpesZosterActiveSubOtherSubDesc:
      ros_hematologyHerpesZosterActiveSubOtherSubDesc || "",
    ros_hematologyHerpesZosterSubOtherTreatmentSubDesc:
      ros_hematologyHerpesZosterSubOtherTreatmentSubDesc || "",
    ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment:
      ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment || "",
    ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment:
      ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment || "",
    ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc:
      ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc || "",
    ros_hematologyMrsaInfectionSubAnswerDescribeComment:
      ros_hematologyMrsaInfectionSubAnswerDescribeComment || "",
    ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc:
      ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc || "",
    ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment:
      ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment || "",
    ros_hematologyImmuneDeficiencySubAnswerDescribeComment:
      ros_hematologyImmuneDeficiencySubAnswerDescribeComment || "",
    ros_hematologyImmuneDeficiencySubAnswerEtiology:
      ros_hematologyImmuneDeficiencySubAnswerEtiology || "",
    ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc:
      ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc || "",
    ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment:
      ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment || "",
    ros_hematologySepsisSubAnswerDescribeComment:
      ros_hematologySepsisSubAnswerDescribeComment || "",
    ros_hematologySepsisSubOtherTreatmentSubDesc:
      ros_hematologySepsisSubOtherTreatmentSubDesc || "",
    ros_hematologySepsisActiveTreatmentSubAnswerComment:
      ros_hematologySepsisActiveTreatmentSubAnswerComment || "",
    ros_hematologySickleCellDiseaseSubAnswerDescribeComment:
      ros_hematologySickleCellDiseaseSubAnswerDescribeComment || "",
    ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment:
      ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment || "",
    ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc:
      ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc || "",
    ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment:
      ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment || "",
    ros_hematologySickleCellTraitSubAnswerDescribeComment:
      ros_hematologySickleCellTraitSubAnswerDescribeComment || "",
    ros_hematologySickleCellTraitSubOtherTreatmentSubDesc:
      ros_hematologySickleCellTraitSubOtherTreatmentSubDesc || "",
    ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment:
      ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment || "",
    ros_hematologyThalassemiaSubAnswerDescribeComment:
      ros_hematologyThalassemiaSubAnswerDescribeComment || "",
    ros_hematologyThalassemiaSubOtherTreatmentSubDesc:
      ros_hematologyThalassemiaSubOtherTreatmentSubDesc || "",
    ros_hematologyThalassemiaActiveTreatmentSubAnswerComment:
      ros_hematologyThalassemiaActiveTreatmentSubAnswerComment || "",
    ros_hematologyThrombocytopeniaSubAnswerDescribeComment:
      ros_hematologyThrombocytopeniaSubAnswerDescribeComment || "",
    ros_hematologyThrombocytopeniaSubAnswerEtiology:
      ros_hematologyThrombocytopeniaSubAnswerEtiology || "",
    ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc:
      ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc || "",
    ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment:
      ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment || "",
    ros_hematologyTuberculosisSubAnswerDescribeComment:
      ros_hematologyTuberculosisSubAnswerDescribeComment || "",
    ros_hematologyTuberculosisSubAnswerGivenBCGComment:
      ros_hematologyTuberculosisSubAnswerGivenBCGComment || "",
    ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment:
      ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment || "",
    ros_hematologyTuberculosisSubAnswerTbInfectionComment:
      ros_hematologyTuberculosisSubAnswerTbInfectionComment || "",
    ros_hematologyTuberculosisSubOtherTreatmentSubDesc:
      ros_hematologyTuberculosisSubOtherTreatmentSubDesc || "",
    ros_hematologyTuberculosisActiveTreatmentSubAnswerComment:
      ros_hematologyTuberculosisActiveTreatmentSubAnswerComment || "",
    ros_hematologyVitaminDDeficiencySubAnswerDescribeComment:
      ros_hematologyVitaminDDeficiencySubAnswerDescribeComment || "",
    ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc:
      ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc || "",
    ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment:
      ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment || "",
    ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc:
      ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc || "",
    ros_hematologyHemaOtherSubOtherSubDesc:
      ros_hematologyHemaOtherSubOtherSubDesc || "",
    ros_hematologyHemaOtherSubAnswerDescribeComment:
      ros_hematologyHemaOtherSubAnswerDescribeComment || "",
    ros_hematologyHemaOtherSubAnswerSupportedByComment:
      ros_hematologyHemaOtherSubAnswerSupportedByComment || "",
    ros_hematologyHemaOtherSubAnswerOther:
      ros_hematologyHemaOtherSubAnswerOther || "",
    hema_Recommendations,
    hema_RecommendationstakeMedicationsAsPescribedComment,
    hema_RecommendationsreportAbnormalBruisingComment,
    hema_RecommendationsfollowUpWithDoctorComment,
    hema_RecommendationscaseManagementComment,
    hema_RecommendationsfollowUpClinicianComment,
    hema_RecommendationsDMEComment,
    hema_RecommendationsotherComment,

    ros_cardio,
    ros_cardioComment,
    ros_cardioSubDiagnosesComment,
    ros_cardiovascularRecommendations: ros_cardiovascularRecommendations,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,
    ros_cardioSubDiagnosesAneurysmDesComment,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmTypeComment,
    ros_cardioSubDiagnosesAtrialFlutterDesComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub,
    ros_cardioSubDiagnosesvalvulardiseaseCrDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers,
    ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment,
    ros_cardioSubDiagnosesAneurysmTreatmentComment,
    ros_cardioSubDiagnosesAnerysmSupotherSubAnswer,
    ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    ros_diagnosisOfCancerRecommendationsComment,
    ros_cardiovascularRecommendationsComment,
    ros_cardioSubDiagnosesAneurysmSupotherSubAnswer,
    ros_cardioSubDiagnosesCardioresTreatOtherTrSub,
    ros_cardioSubDiagnosesCardiompTreatOtherTrSub,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer,
    ros_cardioSubDiagnosesAnginaSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesOtherSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,
    ros_cardioSubDiagnosesCardiomyopathyDesComment,
    ros_cardioSubDiagnosesCardiomyopathySupComment,
    ros_cardioSubDiagnosesCardiomyopathyTypeComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHyperlipidemiaDesComment,
    ros_cardioSubDiagnosesHyperlipidemiaSupComment,
    ros_cardioSubDiagnosesHypertensionSupComment,
    ros_cardioSubDiagnosesHypertensionDesComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlledComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment,
    ros_cardioSubDiagnosesMyocardialBetaBlocker,
    ros_cardioSubDiagnosesMyocardialBetaBlockerComment,
    ros_cardioSubDiagnosesMyocardialIspateintComment,
    ros_cardioSubDiagnosesMyocardialSupotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialSupComment,
    ros_cardioSubDiagnosesMyocardialDesComment,
    ros_cardioSubDiagnosesMyocardialDate,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacement,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment,
    ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment,
    ros_cardioSubDiagnosesvalvulardiseaseSupComment,
    ros_cardioSubDiagnosesvalvulardiseaseDesComment,
    ros_cardioSubDiagnosesOtherOtherSub,
    ros_cardioSubDiagnosesOtherSupComment,
    ros_cardioSubDiagnosesOtherDesComment,
    ros_cardiovascularRecommendationsheartHealthyDietComment,
    ros_cardiovascularRecommendationsbloodPressureChecksComment,
    ros_cardiovascularRecommendationsexerciseThirtyMinADayComment,
    ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment,
    ros_cardiovascularRecommendationsotherComment,
    ros_cardiovascularRecommendationsmemberTouseDurableEquipComment,
    ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment,
    ros_cardiovascularRecommendationscasemangereferralfollowupComment,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertension,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockDesComment,
    ros_cardioSubDiagnosesHeartTransplant,
    ros_cardioSubDiagnosesSympComment,
    ros_cardioSubDiagnosesSympOtherSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment,
    ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment,
    ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment,
    ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesHypertensionSympotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSympComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment,

    ros_diagnosisOfCancer,
    ros_diagnosisOfCancerComment,
    ros_diagnosisOfCancerRecommendations: ros_diagnosisOfCancerRecommendations,
    ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment,
    ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment,
    ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment,
    ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment,
    ros_diagnosisOfCancerRecommendationsotherCancerComment,
    ros_diagnosisOfCancerSubSpecTyVal: ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerSubStageSpecCanVal:
      ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerros_activeTreatment,
    ros_diagnosisOfCancerros_Metastasis,
    ros_diagnosisOfCancerros_SeeASpec,
    ros_diagnosisOfCancerSubSeeASpecProVal,
    ros_diagnosisOfCancerSubMetastasisLocVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_diagnosisOfCancerros_activeTreatmentComment,
    ros_diagnosisOfCancerros_SeeASpecComment,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,
    ros_diagnosisOfCancerros_MetastasisComment,
    ros_diagnosisOfCancerSubSupOtherValSubAnswer,
    ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer,
    ros_diagnosisOfCancertitl5Years,
    ros_diagnosisOfCancertitl5YearsComment,
    ros_diagnosisOfCancerSubTypeOtherValSubAnswer,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_gastroComment,
    ros_genitoValComment,
    ros_gastro,
    ros_genitoVal,
    ros_endocrineIsEndocrineRecommendations:
      ros_endocrineIsEndocrineRecommendations,
    ros_genitoRecommendations: ros_genitoRecommendations,
    ros_gastrorecommendations: ros_gastrorecommendations,
    ros_gastrorecommendationscaseManagementComment,
    ros_gastrorecommendationsfollowupComment,
    ros_gastrorecommendationsuseDmeComment,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,
    ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment,
    ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment,
    ros_endocrineIsEndocrineRecommendationsotherEndoComment,
    ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment,
    ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment,
    ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment,
    ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment,
    ros_gastrorecommendationsotherComment,
    ros_gastrorecommendationstakemedicationsasprescribedComment,
    ros_gastroSubDiagnosescachexiaSupComment,
    ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_genitoSubDiagnosesComment,
    ros_endocrineIsEndocrineDiagnoseComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,
    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,
    ros_gastroSubDiagnosescolonSupComment,
    ros_gastroSubSymptomsOtherSub,
    ros_gastroSubSymptomsComment,
    ros_genitoSubDiagnosesacuteRenalFailureEti,
    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_endocrineIsEndocrineRecommendationsComment,
    ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal,
    ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal,
    ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosescolonOtherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgerdSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal,
    ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesotherSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment,
    ros_gastroSubDiagnosesdesdiverticulitisComment,
    ros_gastroSubDiagnosesdiverticulitisabscessVal,
    ros_gastroSubDiagnosesdiverticulitisabscessValComment,
    ros_gastroSubDiagnosesdiverticulitisperforationVal,
    ros_gastroSubDiagnosesdiverticulitisperforationValComment,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietVal,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment,
    ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment,
    ros_gastroSubDiagnosesdiverticulitisSupComment,
    ros_gastroSubDiagnosesgallBladderDiseaseSupComment,
    ros_gastroSubDiagnosesdesgallBladderDiseaseComment,
    ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByComment,
    ros_gastroSubDiagnosesgastroparesisDescribeComment,
    ros_gastroSubDiagnosesgerdDescribeComment,
    ros_gastroSubDiagnosesgerdSupportedByComment,
    ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedDescribeComment,
    ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDescribeComment,
    ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnoseshepatitisSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherDescribeComment,
    ros_gastroSubDiagnosespancreatitisDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDessComment,
    ros_gastroSubDiagnoseshepatitisTypeComment,
    ros_gastroSubDiagnoseshepatitisCirrhosis,
    ros_gastroCiroalcoholRelated,
    ros_gastroCiroalcoholRelatedComment,
    ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment,
    ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice,
    ros_gastroSubDiagnoseshepatitisCirrhosisComment,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment,
    ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment,
    ros_gastroSubDiagnosespancreatitisSpecificDiet,
    ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol,
    ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment,
    ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment,
    ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosespancreatitisSpecificDietComment,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosesulcerDiseaseComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactria,
    ros_gastroSubDiagnosesotherOther,
    ros_gastroDateOfTransplant,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment,
    ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgerdActiveSubTreComment,
    ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment,
    ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns,
    ros_gastroSubDiagnosesdesglBleedActiveSubBlood,
    ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesglBleedActiveSubTreComment,
    ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment,
    ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment,
    ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment,
    ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment,
    ros_gastroSubDiagnosesdescachexiaActiveSubTreComment,
    ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal,
    ros_genito,
    ros_genitoComment,
    ros_genitoSubDiagnosesacuteRenalFailureEtiology,
    ros_genitoSubDiagnosesbphDescribeComment,
    ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesbhpActiveSubTreComment,
    ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdeschronicActiveSubTreComment,
    ros_genitoSubDiagnosesdeschronicActiveSubSec,
    ros_genitoSubDiagnosesdeschronicActiveSubSecComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment,
    ros_genitoSubDiagnoseswhatStageComment,
    ros_genitoSubDiagnosesesrdDescribeComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet,
    ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment,
    ros_genitoSubDiagnosesdesesrdActiveSubTreComment,
    ros_genitoSubDiagnoActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment,
    ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment,
    ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesgynecoActiveSubTreComment,
    ros_genitoSubDiagnosesdeskidneyActiveSubTreComment,
    ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesfreqsActiveSubTreComment,
    ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdeserectileActiveSubTreComment,
    ros_genitoSubDiagnosesdesErectileTreOtherSub,
    ros_genitoSubDiagnosesdesOtherActiveSubTreComment,
    ros_genitoSubDiagnosesdesacuteActiveSubTreComment,
    ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub,
    ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment,
    ros_genitoSubDiagnosesesrddialysisSchedule,
    ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice,
    ros_genitoSubDiagnoseserectileDysfunctionDescribeComment,
    ros_genitoSubDiagnosesfrequentUtiDescribeComment,
    ros_genitoSubDiagnosesgynecologicalDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesTypeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment,
    ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment,
    ros_genitoSubDiagnosesotherDescribeComment,
    ros_genitoSubDiagnosesotherOther,
    ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment,
    ros_genitoSubSymptomsComment,
    ros_genitoSubSymptomsOtherSub,
    ros_genitoStress,
    ros_genitoStressComment,
    ros_genitoStressSubComment,
    ros_endocrineIsEndocrine,
    ros_endocrineIsEndocrineComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment,
    ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment,
    ros_genitoRecommendationsotherGenitoComment,
    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_genitoRecommendationscasemanagementrefGenitoComment,
    ros_genitoRecommendationsmemberDurableGenitoComment,
    ros_genitoRecommendationsfollowupwithclinispecGenitoComment,
    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubchronicKidneySubStageComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment,
    ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment,
    ros_endocrineIsEndocrineSubneuroSubdescribeSubComment,
    ros_endocrineIsEndocrineSubNeuroSubSympComment,
    ros_endocrineIsEndocrineSubNeuroSubTreatComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment,
    ros_endocrineIsEndocrineNeuroSubPatientOftenComment,
    ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns,
    ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnoseotherDescribeComment,
    ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseotherSupComment,
    ros_endocrineIsEndocrineDiagnoseotherOther,
    ros_endocrineIsEndocrineNoEdu,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment,
    ros_endocrineIsEndocrineUnknownEdu,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment,
    ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub,
    ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment,
    ros_endocrineIsEndocrineSubVascularSubPhysicalComment,
    ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubVascularSubTreatComment,
    ros_endocrineIsEndocrineSubAutonomicSubTreatComment,
    ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment,
    ros_endocrineIsEndocrineSubchronicKidneySubTreatComment,
    ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment,
    ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c,
    ros_endocrineIsEndocrineSubdiabetesSubandDate,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr,
  };

  const data18 = getMashedData_ros_3(apiData);
  tempDbData = { ...tempDbData, ...data18 };

  return tempDbData;
};

export const setRosData2024 = (formData) => {
  let tempData = {};

  ros_OptionsTreeWrappers2024.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  const ros_respiratoryComment = formData["ros_respiratoryComment"];
  const ros_respiratoryVal = setOptionsSelectedYesNo(
    formData["ros_respiratory"],
  );
  const ros_respiratoryAcuteUpperSubAnswerDescribeComment =
    formData["ros_respiratoryAcuteUpperSubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    formData["ros_respiratoryDiagnosesComment"];

  const ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment"];

  const ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
    formData[
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment"
    ];

  const ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc =
    formData["ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc"];
  const ros_respiratoryAsthmaSubOtherTreatmentSubDesc =
    formData["ros_respiratoryAsthmaSubOtherTreatmentSubDesc"];
  const ros_respiratoryAsthmaActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryAsthmaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryAsthmaMedicationSubAnswerComment =
    formData["ros_respiratoryAsthmaMedicationSubAnswerComment"];
  const ros_respiratoryAsthmaCurrentExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryAsthmaCurrentExacerbation"],
  );
  const ros_respiratoryAsthmaCurrentExacerbationComment =
    formData["ros_respiratoryAsthmaCurrentExacerbationComment"];
  const ros_respiratoryAsthmaSubAnswerDescribeComment =
    formData["ros_respiratoryAsthmaSubAnswerDescribeComment"];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
    formData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc =
    formData[
      "ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc"
    ];
  const ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment =
    formData[
      "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment"
    ];
  const ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
    formData["ros_respiratoryChronicSputumFailureSubAnswerDescribeComment"];
  const ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc =
    formData["ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc"];
  const ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment =
    formData[
      "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryCopdSubAnswerDescribeComment =
    formData["ros_respiratoryCopdSubAnswerDescribeComment"];
  const ros_respiratoryCopdTypeSubAnswerComment =
    formData["ros_respiratoryCopdTypeSubAnswerComment"];
  const ros_respiratoryCopdSubOtherTreatmentSubDesc =
    formData["ros_respiratoryCopdSubOtherTreatmentSubDesc"];
  const ros_respiratoryCopdActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryCopdActiveTreatmentSubAnswerComment"];
  const ros_respiratoryCopdMedicationSubAnswerComment =
    formData["ros_respiratoryCopdMedicationSubAnswerComment"];
  const ros_respiratoryPatientExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientExacerbation"],
  );
  const ros_respiratoryPatientExacerbationComment =
    formData["ros_respiratoryPatientExacerbationComment"];
  const ros_respiratoryCopdSubPatientExacerbationComment =
    formData["ros_respiratoryCopdSubPatientExacerbationComment"];

  const ros_respiratoryCysticFibrosisSubAnswerDescribeComment =
    formData["ros_respiratoryCysticFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc =
    formData["ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc"];
  const ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment"];
  const ros_respiratoryHypoventilationSubAnswerDescribeComment =
    formData["ros_respiratoryHypoventilationSubAnswerDescribeComment"];
  const ros_respiratoryHypoventilationSubOtherTreatmentSubDesc =
    formData["ros_respiratoryHypoventilationSubOtherTreatmentSubDesc"];
  const ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment"];
  const ros_respiratoryHypoventilationActiveSelectSubAnswerComment =
    formData["ros_respiratoryHypoventilationActiveSelectSubAnswerComment"];
  const ros_respiratoryHypoxemiaSubAnswerDescribeComment =
    formData["ros_respiratoryHypoxemiaSubAnswerDescribeComment"];
  const ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc =
    formData["ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc"];
  const ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryPneumoniaSubAnswerDescribeComment =
    formData["ros_respiratoryPneumoniaSubAnswerDescribeComment"];
  const ros_respiratoryPneumoniaSubAnswerEtiologyComment =
    formData["ros_respiratoryPneumoniaSubAnswerEtiologyComment"];
  const ros_respiratoryHistoryOfLungAbscessVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryHistoryOfLungAbscess"],
  );
  const ros_respiratoryHistoryOfLungAbscessComment =
    formData["ros_respiratoryHistoryOfLungAbscessComment"];

  const ros_respiratoryHistoryOfEmpyemaVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryHistoryOfEmpyema"],
  );
  const ros_respiratoryHistoryOfEmpyemaComment =
    formData["ros_respiratoryHistoryOfEmpyemaComment"];
  const ros_respiratoryPneumoniaSubOtherTreatmentSubDesc =
    formData["ros_respiratoryPneumoniaSubOtherTreatmentSubDesc"];
  const ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
    formData["ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc =
    formData["ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc"];
  const ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment"];
  const ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment =
    formData["ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment =
    formData["ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment"];
  const ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc =
    formData["ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc"];
  const ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment"];
  const ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment =
    formData["ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment"];
  const ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc =
    formData[
      "ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc"
    ];
  const ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment =
    formData[
      "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment"
    ];
  const ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
    formData["ros_respiratoryRespiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc =
    formData["ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment =
    formData[
      "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment"
    ];
  const ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
    formData["ros_respiratoryRespiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc =
    formData["ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment =
    formData["ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment"];
  const ros_respiratorySarcoidosisSubAnswerDescribeComment =
    formData["ros_respiratorySarcoidosisSubAnswerDescribeComment"];
  const ros_respiratorySarcoidosisSubAnswerSpecifyComment =
    formData["ros_respiratorySarcoidosisSubAnswerSpecifyComment"];
  const ros_respiratorySarcoidosisSubOtherTreatmentSubDesc =
    formData["ros_respiratorySarcoidosisSubOtherTreatmentSubDesc"];
  const ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment =
    formData["ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment"];
  const ros_respiratorySleepApneaSubAnswerDescribeComment =
    formData["ros_respiratorySleepApneaSubAnswerDescribeComment"];

  const ros_respiratoryHypoxemiaActiveSelectSubAnswerComment =
    formData["ros_respiratoryHypoxemiaActiveSelectSubAnswerComment"];
  const ros_respiratorySleepApneaActiveSelectSubAnswerVal =
    setOptionsSelectedYesNo(
      formData["ros_respiratorySleepApneaActiveSelectSubAnswer"],
    );
  const ros_respiratorySleepApneaActiveSelectSubAnswerComment =
    formData["ros_respiratorySleepApneaActiveSelectSubAnswerComment"];
  const ros_respiratorySleepApneaSubOtherTreatmentSubDesc =
    formData["ros_respiratorySleepApneaSubOtherTreatmentSubDesc"];
  const ros_respiratorySleepApneaActiveTreatmentSubAnswerComment =
    formData["ros_respiratorySleepApneaActiveTreatmentSubAnswerComment"];
  const ros_respiratoryLungTransplantSubOtherTreatmentSubDesc =
    formData["ros_respiratoryLungTransplantSubOtherTreatmentSubDesc"];
  const ros_respiratoryRespOtherSubAnswerDescribeComment =
    formData["ros_respiratoryRespOtherSubAnswerDescribeComment"];
  const ros_respiratoryOtherSubDesc = formData["ros_respiratoryOtherSubDesc"];
  const ros_respiratoryOtherSubAnswerOther =
    formData["ros_respiratoryOtherSubAnswerOther"];
  const ros_respiratoryRespOtherSubAnswerSupportedByComment =
    formData["ros_respiratoryRespOtherSubAnswerSupportedByComment"];
  const ros_respiratoryRespiratorySymptomsSubOtherSubDesc =
    formData["ros_respiratoryRespiratorySymptomsSubOtherSubDesc"];
  const ros_respiratorySymptomsComment =
    formData["ros_respiratorySymptomsComment"];
  const ros_respiratorySymptomsSubOtherSubDesc =
    formData["ros_respiratorySymptomsSubOtherSubDesc"];

  const respiratory_RecommendationSelected =
    formData["respiratory_Recommendations"];

  const respiratory_RecommendationsVal = setSelectedCheckboxes(
    respiratory_RecommendationSelected,
    [
      "respiratory_recoTakeMedicationsVal",
      "respiratory_recoCaseManagementVal",
      "respiratory_recoFollowUpClinicianVal",
      "respiratory_recoDMEVal",
      "respiratory_recoOtherVal",
    ],
    ["takeMedications", "caseManagement", "followUpClinician", "DME", "other"],
  );

  // const respiratory_RecommendationsComment =
  //   formData["respiratory_RecommendationsComment"];

  const respiratory_RecommendationstakeMedicationsCommentVal =
    formData["respiratory_RecommendationstakeMedicationsComment"];

  const respiratory_RecommendationscaseManagementCommentVal =
    formData["respiratory_RecommendationscaseManagementComment"];

  const respiratory_RecommendationsfollowUpClinicianCommentVal =
    formData["respiratory_RecommendationsfollowUpClinicianComment"];

  const respiratory_RecommendationsDMECommentVal =
    formData["respiratory_RecommendationsDMEComment"];

  const respiratory_RecommendationsotherCommentVal =
    formData["respiratory_RecommendationsotherComment"];

  const ros_integumentComment = formData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    formData["ros_integumentDiagnosesComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
    formData["ros_integumentBasilCellCarcinomaSubAnswerDescribeComment"];
  const ros_integumentBasilCellCarcinomaSubLocation =
    formData["ros_integumentBasilCellCarcinomaSubLocation"];
  const ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc =
    formData["ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc"];
  const ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment =
    formData["ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment"];
  const ros_integumentVal = setOptionsSelectedYesNo(formData["ros_integument"]);
  const ros_integumentDermatitisSubAnswerDescribeComment =
    formData["ros_integumentDermatitisSubAnswerDescribeComment"];
  const ros_integumentDermatitisSubLocation =
    formData["ros_integumentDermatitisSubLocation"];
  const ros_integumentDermatitisSubOtherTreatmentSubDesc =
    formData["ros_integumentDermatitisSubOtherTreatmentSubDesc"];
  const ros_integumentDermatitisActiveTreatmentSubAnswerComment =
    formData["ros_integumentDermatitisActiveTreatmentSubAnswerComment"];
  const ros_integumentDermatitisSubAnswerWhatTypeComment =
    formData["ros_integumentDermatitisSubAnswerWhatTypeComment"];
  const ros_integumentEczemaSubAnswerDescribeComment =
    formData["ros_integumentEczemaSubAnswerDescribeComment"];
  const ros_integumentEczemaSubLocation =
    formData["ros_integumentEczemaSubLocation"];
  const ros_integumentEczemaSubOtherTreatmentSubDesc =
    formData["ros_integumentEczemaSubOtherTreatmentSubDesc"];
  const ros_integumentEczemaActiveTreatmentSubAnswerComment =
    formData["ros_integumentEczemaActiveTreatmentSubAnswerComment"];
  const ros_integumentOnychomycosisSubAnswerDescribeComment =
    formData["ros_integumentOnychomycosisSubAnswerDescribeComment"];
  const ros_integumentOnychomycosisSubLocation =
    formData["ros_integumentOnychomycosisSubLocation"];
  const ros_integumentOnychomycosisSubOtherTreatmentSubDesc =
    formData["ros_integumentOnychomycosisSubOtherTreatmentSubDesc"];
  const ros_integumentOnychomycosisActiveTreatmentSubAnswerComment =
    formData["ros_integumentOnychomycosisActiveTreatmentSubAnswerComment"];
  const ros_integumentPsoriasisSubAnswerDescribeComment =
    formData["ros_integumentPsoriasisSubAnswerDescribeComment"];
  const ros_integumentPsoriasisSubLocation =
    formData["ros_integumentPsoriasisSubLocation"];
  const ros_integumentPsoriasisSubOtherTreatmentSubDesc =
    formData["ros_integumentPsoriasisSubOtherTreatmentSubDesc"];
  const ros_integumentPsoriasisActiveTreatmentSubAnswerComment =
    formData["ros_integumentPsoriasisActiveTreatmentSubAnswerComment"];
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal =
    setOptionsSelectedYesNo(
      formData["ros_integumentPsoriasisSubAnswerPsoriaticArthritis"],
    );
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
    formData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_integumentSkinUlcerSubAnswerDescribeComment =
    formData["ros_integumentSkinUlcerSubAnswerDescribeComment"];
  const ros_integumentSkinUlcerSubLocation =
    formData["ros_integumentSkinUlcerSubLocation"];
  const ros_integumentSkinUlcerSubOtherTreatmentSubDesc =
    formData["ros_integumentSkinUlcerSubOtherTreatmentSubDesc"];
  const ros_integumentSkinUlcerActiveTreatmentSubAnswerComment =
    formData["ros_integumentSkinUlcerActiveTreatmentSubAnswerComment"];
  const ros_integumentSkinUlcerSubEtiologySubDesc =
    formData["ros_integumentSkinUlcerSubEtiologySubDesc"];
  const ros_integumentSkinUlcerSubAnswerEtiologyComment =
    formData["ros_integumentSkinUlcerSubAnswerEtiologyComment"];
  const ros_integumentTineaPedisSubAnswerDescribeComment =
    formData["ros_integumentTineaPedisSubAnswerDescribeComment"];
  const ros_integumentTineaPedisSubOtherTreatmentSubDesc =
    formData["ros_integumentTineaPedisSubOtherTreatmentSubDesc"];
  const ros_integumentTineaPedisActiveTreatmentSubAnswerComment =
    formData["ros_integumentTineaPedisActiveTreatmentSubAnswerComment"];
  const ros_integumentTineaPedisSelectTreatmentSubAnswerComment =
    formData["ros_integumentTineaPedisSelectTreatmentSubAnswerComment"];
  const ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
    formData["ros_integumentUrticarialDiseaseSubAnswerDescribeComment"];
  const ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc =
    formData["ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc"];
  const ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment =
    formData["ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment"];
  const ros_integumentUrticarialDiseaseSubAnswerTypeComment =
    formData["ros_integumentUrticarialDiseaseSubAnswerTypeComment"];
  const ros_integumentUrticarialDiseaseSubAnswerEtiology =
    formData["ros_integumentUrticarialDiseaseSubAnswerEtiology"];
  const ros_integumentUrticarialDiseaseSubLocation =
    formData["ros_integumentUrticarialDiseaseSubLocation"];
  const ros_integumentWoundSubAnswerDescribeComment =
    formData["ros_integumentWoundSubAnswerDescribeComment"];
  const ros_integumentWoundSubOtherTreatmentSubDesc =
    formData["ros_integumentWoundSubOtherTreatmentSubDesc"];
  const ros_integumentWoundSubLocation =
    formData["ros_integumentWoundSubLocation"];
  const ros_integumentWoundActiveTreatmentSubAnswerComment =
    formData["ros_integumentWoundActiveTreatmentSubAnswerComment"];
  const ros_integumentWoundSubAnswerEtiologyComment =
    formData["ros_integumentWoundSubAnswerEtiologyComment"];
  const ros_integumentOthersSubAnswerDescribeComment =
    formData["ros_integumentOthersSubAnswerDescribeComment"];
  const ros_integumentOthersSubLocation =
    formData["ros_integumentOthersSubLocation"];
  const ros_integumentOthersSubOtherTreatmentSubDesc =
    formData["ros_integumentOthersSubOtherTreatmentSubDesc"];
  const ros_integumentOthersActiveTreatmentSubAnswerComment =
    formData["ros_integumentOthersActiveTreatmentSubAnswerComment"];
  const ros_integumentOthersSubAnswerOther =
    formData["ros_integumentOthersSubAnswerOther"];

  const integument_RecommendationSelected =
    formData["integument_Recommendations"];

  const integument_RecommendationsVal = setSelectedCheckboxes(
    integument_RecommendationSelected,
    [
      "integument_recoTakeMedicationsAsPescribedVal",
      "integument_recoCaseManagementVal",
      "integument_recoFollowUpClinicianVal",
      "integument_recoDMEVal",
      "integument_recoOtherVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );

  const integument_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["integument_RecommendationstakeMedicationsAsPescribedComment"];

  const integument_RecommendationscaseManagementCommentVal =
    formData["integument_RecommendationscaseManagementComment"];

  const integument_RecommendationsfollowUpClinicianCommentVal =
    formData["integument_RecommendationsfollowUpClinicianComment"];

  const integument_RecommendationsDMECommentVal =
    formData["integument_RecommendationsDMEComment"];

  const integument_RecommendationsotherCommentVal =
    formData["integument_RecommendationsotherComment"];

  const ros_musculoskeletalVal = setOptionsSelectedYesNo(
    formData["ros_musculoskeletal"],
  );
  const ros_musculoskeletalComment = formData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    formData["ros_musculoskeletalDiagnosesComment"];
  const ros_musculoskeletalDermatomyositisSubAnswerDescribeComment =
    formData["ros_musculoskeletalDermatomyositisSubAnswerDescribeComment"];
  const ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal =
    setOptionsSelectedYesNo(
      formData[
        "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder"
      ],
    );
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
    formData["ros_musculoskeletalExtremityFractureSubAnswerDescribeComment"];
  const ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
    formData["ros_musculoskeletalExtremityFractureSubAnswerExtremityComment"];
  const ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureShoulderSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureForearmSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureWristSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureWristSubAnswerComment"];
  const ros_musculoskeletalHandSubAnswerComment =
    formData["ros_musculoskeletalHandSubAnswerComment"];
  const ros_musculoskeletalFemoralShaftSubAnswerComment =
    formData["ros_musculoskeletalFemoralShaftSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureTibiaSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureFibulaSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureArmSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureArmSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureAnkleSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureFootSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureFootSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureHipSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureHipSubAnswerComment"];
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal =
    setOptionsSelectedYesNo(
      formData[
        "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer"
      ],
    );
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    formData[
      "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment"
    ];
  const ros_musculoskeletalGoutSubAnswerDescribeComment =
    formData["ros_musculoskeletalGoutSubAnswerDescribeComment"];
  const ros_musculoskeletalGoutSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalGoutSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalGoutActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalGoutActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
    formData["ros_musculoskeletalHalluxValgusSubAnswerDescribeComment"];
  const ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment =
    formData["ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment"];
  const ros_musculoskeletalHammerToesSubAnswerDescribeComment =
    formData["ros_musculoskeletalHammerToesSubAnswerDescribeComment"];
  const ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalOsteomyelitisSubLocation =
    formData["ros_musculoskeletalOsteomyelitisSubLocation"];
  const ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteoporosisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalSclerodermaSubAnswerDescribeComment =
    formData["ros_musculoskeletalSclerodermaSubAnswerDescribeComment"];
  const ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment"];
  const ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment =
    formData[
      "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal =
    setOptionsSelectedYesNo(
      formData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder"],
    );
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment"];
  const ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc =
    formData[
      "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc"
    ];
  const ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
    formData["ros_musculoskeletalSystemicLupusSubAnswerDescribeComment"];
  const ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerDescribeComment =
    formData["ros_musculoskeletalMuskoOtherSubAnswerDescribeComment"];
  const ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc =
    formData["ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc"];
  const ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment =
    formData["ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerother =
    formData["ros_musculoskeletalMuskoOtherSubAnswerother"];
  const ros_musculoskeletalMuskoSymptomsSubOtherSubDesc =
    formData["ros_musculoskeletalMuskoSymptomsSubOtherSubDesc"];
  const ros_musculoskeletalMuskoSymptomsComment =
    formData["ros_musculoskeletalMuskoSymptomsComment"];
  const ros_musculoskeletalHadAnAmputationSubAnswer =
    formData["ros_musculoskeletalHadAnAmputationSubAnswer"];
  const ros_musculoskeletalMuskoHadAnAmputationVal = setOptionsSelectedYesNo(
    formData["ros_musculoskeletalMuskoHadAnAmputation"],
  );
  const ros_musculoskeletalMuskoHadAnAmputationComment =
    formData["ros_musculoskeletalMuskoHadAnAmputationComment"];

  const musko_RecommendationSelected = formData["musko_Recommendations"];

  const musko_RecommendationsVal = setSelectedCheckboxes(
    musko_RecommendationSelected,
    [
      "musko_recoDiscussPtOtVal",
      "musko_recoTakeMedicationsAsPescribedVal",
      "musko_recoCaseManagementVal",
      "musko_recoFollowUpClinicianVal",
      "musko_recoDMEVal",
      "musko_recoOtherVal",
    ],
    [
      "discussPtOt",
      "takeMedicationsAsPescribed",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );
  const musko_RecommendationsdiscussPtOtCommentVal =
    formData["musko_RecommendationsdiscussPtOtComment"];

  const musko_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["musko_RecommendationstakeMedicationsAsPescribedComment"];

  const musko_RecommendationscaseManagementCommentVal =
    formData["musko_RecommendationscaseManagementComment"];

  const musko_RecommendationsfollowUpClinicianCommentVal =
    formData["musko_RecommendationsfollowUpClinicianComment"];

  const musko_RecommendationsDMECommentVal =
    formData["musko_RecommendationsDMEComment"];

  const musko_RecommendationsotherCommentVal =
    formData["musko_RecommendationsotherComment"];

  const ros_hematologyVal = setOptionsSelectedYesNo(formData["ros_hematology"]);
  const ros_hematologyComment = formData["ros_hematologyComment"];
  const ros_hematologyDiagnosesComment =
    formData["ros_hematologyDiagnosesComment"];

  const ros_hematologyAidsSubAnswerDescribeComment =
    formData["ros_hematologyAidsSubAnswerDescribeComment"];
  const ros_hematologyAidsSubOtherTreatmentSubDesc =
    formData["ros_hematologyAidsSubOtherTreatmentSubDesc"];
  const ros_hematologyAidsActiveTreatmentSubAnswerComment =
    formData["ros_hematologyAidsActiveTreatmentSubAnswerComment"];
  const ros_hematologyAnemiaSubAnswerDescribeComment =
    formData["ros_hematologyAnemiaSubAnswerDescribeComment"];
  const ros_hematologyAnemiaSubAnswerEtiologyComment =
    formData["ros_hematologyAnemiaSubAnswerEtiologyComment"];
  const ros_hematologyAnemiaSubEtiologySubOtherSubDesc =
    formData["ros_hematologyAnemiaSubEtiologySubOtherSubDesc"];
  const ros_hematologyAnemiaSubOtherTreatmentSubDesc =
    formData["ros_hematologyAnemiaSubOtherTreatmentSubDesc"];
  const ros_hematologyAnemiaActiveTreatmentSubAnswerComment =
    formData["ros_hematologyAnemiaActiveTreatmentSubAnswerComment"];
  const ros_hematologyAnemiaSubYesPatientOnSubDesc =
    formData["ros_hematologyAnemiaSubYesPatientOnSubDesc"];
  const ros_hematologyAnemiaSubAnswerYesPatientOnComment =
    formData["ros_hematologyAnemiaSubAnswerYesPatientOnComment"];
  const ros_hematologyCDifficileSubAnswerDescribeComment =
    formData["ros_hematologyCDifficileSubAnswerDescribeComment"];
  const ros_hematologyCDifficileSubOtherTreatmentSubDesc =
    formData["ros_hematologyCDifficileSubOtherTreatmentSubDesc"];
  const ros_hematologyCDifficileActiveTreatmentSubAnswerComment =
    formData["ros_hematologyCDifficileActiveTreatmentSubAnswerComment"];
  const ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
    formData["ros_hematologyCommunityAcquiredSubAnswerDescribeComment"];
  const ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc =
    formData["ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc"];
  const ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment =
    formData["ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment"];

  const ros_hematologyHivSubAnswerPatientSymptomaticVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyHivSubAnswerPatientSymptomatic"],
    );
  const ros_hematologyHivSubAnswerActiveTreatmentVal = setOptionsSelectedYesNo(
    formData["ros_hematologyHivSubAnswerActiveTreatment"],
  );
  const ros_hematologyHivSubAnswerViralLoad =
    formData["ros_hematologyHivSubAnswerViralLoad"];
  const ros_hematologyHivSubAnswerCFour =
    formData["ros_hematologyHivSubAnswerCFour"];
  const ros_hematologyHivSubAnswerPatientSymptomaticComment =
    formData["ros_hematologyHivSubAnswerPatientSymptomaticComment"];
  const ros_hematologyHivSubAnswerActiveTreatmentComment =
    formData["ros_hematologyHivSubAnswerActiveTreatmentComment"];
  const ros_hematologyHivSubOtherTreatmentSubDesc =
    formData["ros_hematologyHivSubOtherTreatmentSubDesc"];
  const ros_hematologyHivActiveTreatmentSubAnswerComment =
    formData["ros_hematologyHivActiveTreatmentSubAnswerComment"];
  const ros_hematologyHivSubAnswerDescribeComment =
    formData["ros_hematologyHivSubAnswerDescribeComment"];

  const ros_hematologyHerpesZosterSubAnswerDescribeComment =
    formData["ros_hematologyHerpesZosterSubAnswerDescribeComment"];
  const ros_hematologyHerpesZosterTypeSubAnswerComment =
    formData["ros_hematologyHerpesZosterTypeSubAnswerComment"];
  const ros_hematologyHerpesZosterActiveSubOtherSubDesc =
    formData["ros_hematologyHerpesZosterActiveSubOtherSubDesc"];
  const ros_hematologyHerpesZosterSubOtherTreatmentSubDesc =
    formData["ros_hematologyHerpesZosterSubOtherTreatmentSubDesc"];
  const ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment =
    formData["ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment"];
  const ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment =
    formData["ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment"];
  const ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc =
    formData["ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc"];

  const ros_hematologyMrsaInfectionSubAnswerDescribeComment =
    formData["ros_hematologyMrsaInfectionSubAnswerDescribeComment"];
  const ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc =
    formData["ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc"];
  const ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment =
    formData["ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment"];

  const ros_hematologyImmuneDeficiencySubAnswerDescribeComment =
    formData["ros_hematologyImmuneDeficiencySubAnswerDescribeComment"];
  const ros_hematologyImmuneDeficiencySubAnswerEtiology =
    formData["ros_hematologyImmuneDeficiencySubAnswerEtiology"];
  const ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc =
    formData["ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc"];
  const ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment =
    formData["ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment"];

  const ros_hematologySepsisSubAnswerDescribeComment =
    formData["ros_hematologySepsisSubAnswerDescribeComment"];
  const ros_hematologySepsisSubOtherTreatmentSubDesc =
    formData["ros_hematologySepsisSubOtherTreatmentSubDesc"];
  const ros_hematologySepsisActiveTreatmentSubAnswerComment =
    formData["ros_hematologySepsisActiveTreatmentSubAnswerComment"];

  const ros_hematologySickleCellDiseaseSubAnswerDescribeComment =
    formData["ros_hematologySickleCellDiseaseSubAnswerDescribeComment"];
  const ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment =
    formData["ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment"];
  const ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc =
    formData["ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc"];
  const ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment =
    formData["ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment"];

  const ros_hematologySickleCellTraitSubAnswerDescribeComment =
    formData["ros_hematologySickleCellTraitSubAnswerDescribeComment"];
  const ros_hematologySickleCellTraitSubOtherTreatmentSubDesc =
    formData["ros_hematologySickleCellTraitSubOtherTreatmentSubDesc"];
  const ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment =
    formData["ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment"];

  const ros_hematologyThalassemiaSubAnswerDescribeComment =
    formData["ros_hematologyThalassemiaSubAnswerDescribeComment"];
  const ros_hematologyThalassemiaSubOtherTreatmentSubDesc =
    formData["ros_hematologyThalassemiaSubOtherTreatmentSubDesc"];
  const ros_hematologyThalassemiaActiveTreatmentSubAnswerComment =
    formData["ros_hematologyThalassemiaActiveTreatmentSubAnswerComment"];

  const ros_hematologyThrombocytopeniaSubAnswerDescribeComment =
    formData["ros_hematologyThrombocytopeniaSubAnswerDescribeComment"];
  const ros_hematologyThrombocytopeniaSubAnswerEtiology =
    formData["ros_hematologyThrombocytopeniaSubAnswerEtiology"];
  const ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc =
    formData["ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc"];
  const ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment =
    formData["ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment"];

  const ros_hematologyTuberculosisSubAnswerDescribeComment =
    formData["ros_hematologyTuberculosisSubAnswerDescribeComment"];
  const ros_hematologyTuberculosisSubAnswerGivenBCGComment =
    formData["ros_hematologyTuberculosisSubAnswerGivenBCGComment"];
  const ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
    formData["ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_hematologyTuberculosisSubAnswerTbInfectionComment =
    formData["ros_hematologyTuberculosisSubAnswerTbInfectionComment"];
  const ros_hematologyTuberculosisSubOtherTreatmentSubDesc =
    formData["ros_hematologyTuberculosisSubOtherTreatmentSubDesc"];
  const ros_hematologyTuberculosisActiveTreatmentSubAnswerComment =
    formData["ros_hematologyTuberculosisActiveTreatmentSubAnswerComment"];

  const ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
    formData["ros_hematologyVitaminDDeficiencySubAnswerDescribeComment"];
  const ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc =
    formData["ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc"];
  const ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment =
    formData["ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment"];

  const ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc =
    formData["ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc"];

  const ros_hematologyHemaOtherSubOtherSubDesc =
    formData["ros_hematologyHemaOtherSubOtherSubDesc"];
  const ros_hematologyHemaOtherSubAnswerDescribeComment =
    formData["ros_hematologyHemaOtherSubAnswerDescribeComment"];
  const ros_hematologyHemaOtherSubAnswerSupportedByComment =
    formData["ros_hematologyHemaOtherSubAnswerSupportedByComment"];
  const ros_hematologyHemaOtherSubAnswerOther =
    formData["ros_hematologyHemaOtherSubAnswerOther"];

  const hema_RecommendationSelected = formData["hema_Recommendations"];

  const hema_RecommendationsVal = setSelectedCheckboxes(
    hema_RecommendationSelected,
    [
      "hema_recoTakeMedicationsAsPescribedVal",
      "hema_recoReportAbnormalBruisingVal",
      "hema_recoFollowUpWithDoctorVal",
      "hema_recoCaseManagementVal",
      "hema_recoFollowUpClinicianVal",
      "hema_recoDMEVal",
      "hema_recoOtherVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["hema_RecommendationstakeMedicationsAsPescribedComment"];

  const hema_RecommendationsreportAbnormalBruisingCommentVal =
    formData["hema_RecommendationsreportAbnormalBruisingComment"];

  const hema_RecommendationsfollowUpWithDoctorCommentVal =
    formData["hema_RecommendationsfollowUpWithDoctorComment"];

  const hema_RecommendationscaseManagementCommentVal =
    formData["hema_RecommendationscaseManagementComment"];

  const hema_RecommendationsfollowUpClinicianCommentVal =
    formData["hema_RecommendationsfollowUpClinicianComment"];

  const hema_RecommendationsDMECommentVal =
    formData["hema_RecommendationsDMEComment"];

  const hema_RecommendationsotherCommentVal =
    formData["hema_RecommendationsotherComment"];

  const ros_cardioVal = setOptionsSelectedYesNo(formData["ros_cardio"]);
  const ros_cardioComment = formData["ros_cardioComment"];
  const ros_cardiovascularRecommendationsSelected =
    formData["ros_cardiovascularRecommendations"];
  const ros_cardiovascularRecommendations = setSelectedCheckboxes(
    ros_cardiovascularRecommendationsSelected,
    [
      "ros_takemedicationsasprescribedCardioVal",
      "ros_otherCardioVal",
      "ros_ThirtyCardioVal",
      "ros_bloodPreCardioVal",
      "ros_heartHeCardioVal",
      "ros_caseManagementCardioVal",
      "ros_followupWithCardioVal",
      "ros_durableMedicalEquipCardioVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
      "casemangereferralfollowup",
      "FollowupClinicianSpecialist",
      "memberTouseDurableEquip",
    ],
  );

  const ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment =
    formData[
      "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment"
    ];

  const ros_cardiovascularRecommendationsotherComment =
    formData["ros_cardiovascularRecommendationsotherComment"];
  const ros_cardiovascularRecommendationsmemberTouseDurableEquipComment =
    formData["ros_cardiovascularRecommendationsmemberTouseDurableEquipComment"];
  const ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment =
    formData[
      "ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment"
    ];
  const ros_cardiovascularRecommendationscasemangereferralfollowupComment =
    formData[
      "ros_cardiovascularRecommendationscasemangereferralfollowupComment"
    ];
  const ros_cardiovascularRecommendationsexerciseThirtyMinADayComment =
    formData["ros_cardiovascularRecommendationsexerciseThirtyMinADayComment"];
  const ros_cardiovascularRecommendationsbloodPressureChecksComment =
    formData["ros_cardiovascularRecommendationsbloodPressureChecksComment"];
  const ros_cardiovascularRecommendationsheartHealthyDietComment =
    formData["ros_cardiovascularRecommendationsheartHealthyDietComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];

  const ros_cardioSubDiagnosesAneurysmDesComment =
    formData["ros_cardioSubDiagnosesAneurysmDesComment"];
  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment =
    formData["ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment"];

  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment =
    formData[
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment"
    ];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub =
    formData["ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub"];
  const ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub =
    formData[
      "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub"
    ];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub =
    formData[
      "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub"
    ];
  const ros_cardioSubDiagnosesComment =
    formData["ros_cardioSubDiagnosesComment"];

  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    formData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmTypeComment =
    formData["ros_cardioSubDiagnosesAneurysmTypeComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesComment =
    formData["ros_cardioSubDiagnosesAtrialFlutterDesComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment =
    formData["ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment"];
  const ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment =
    formData["ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseCrDesComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseCrDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers =
    formData[
      "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub"
    ];
  const ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment"
    ];
  const ros_cardioSubDiagnosesAneurysmTreatmentComment =
    formData["ros_cardioSubDiagnosesAneurysmTreatmentComment"];
  const ros_cardioSubDiagnosesAnerysmSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAnerysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer =
    formData["ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer"];
  const ros_cardioSubDiagnosesAnginaCrDesComment =
    formData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    formData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    formData["ros_cardioSubDiagnosesAnginaDesComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];

  const cardio_RecommendationsComment =
    formData["cardio_RecommendationsComment"];
  const ros_cardioSubDiagnosesAneurysmSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAneurysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardioresTreatOtherTrSub =
    formData["ros_cardioSubDiagnosesCardioresTreatOtherTrSub"];
  const ros_cardioSubDiagnosesCardiompTreatOtherTrSub =
    formData["ros_cardioSubDiagnosesCardiompTreatOtherTrSub"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAnginaSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAnginaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesOtherSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesOtherSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers"
    ];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );
  const ros_cardioSubDiagnosesCardiomyopathyDesComment =
    formData["ros_cardioSubDiagnosesCardiomyopathyDesComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupComment =
    formData["ros_cardioSubDiagnosesCardiomyopathySupComment"];
  const ros_cardioSubDiagnosesCardiomyopathyTypeComment =
    formData["ros_cardioSubDiagnosesCardiomyopathyTypeComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    setOptionsSelectedYesNo(
      formData[
        "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSupComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureDesComment"];

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );
  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisSupComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisDesComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment"];
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );
  const ros_cardioSubDiagnosesHyperlipidemiaDesComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiaDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiaSupComment"];

  const ros_cardioSubDiagnosesHypertensionSupComment =
    formData["ros_cardioSubDiagnosesHypertensionSupComment"];
  const ros_cardioSubDiagnosesHypertensionDesComment =
    formData["ros_cardioSubDiagnosesHypertensionDesComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
    formData["ros_cardioSubDiagnosesHypertensionadequatelyControlledComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment"];
  const ros_cardioSubDiagnosesMyocardialBetaBlockerComment =
    formData["ros_cardioSubDiagnosesMyocardialBetaBlockerComment"];
  const ros_cardioSubDiagnosesMyocardialBetaBlocker = setOptionsSelectedYesNo(
    formData["ros_cardioSubDiagnosesMyocardialBetaBlocker"],
  );
  const ros_cardioSubDiagnosesMyocardialIspateintComment =
    formData["ros_cardioSubDiagnosesMyocardialIspateintComment"];
  const ros_cardioSubDiagnosesMyocardialSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesMyocardialSupotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
    formData["ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialSupComment =
    formData["ros_cardioSubDiagnosesMyocardialSupComment"];
  const ros_cardioSubDiagnosesMyocardialDesComment =
    formData["ros_cardioSubDiagnosesMyocardialDesComment"];
  const ros_cardioSubDiagnosesMyocardialDate =
    formData["ros_cardioSubDiagnosesMyocardialDate"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
    formData["ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
    formData["ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment"];

  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
    formData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer"
    ];

  const ros_cardioSubDiagnosesPulmonaryHypertensionSupComment =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionSupComment"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesComment =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacement =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacement"],
    );
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
    formData["ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement"];
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation"],
    );
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
    formData[
      "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseSupComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseSupComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseDesComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseDesComment"];
  const ros_cardioSubDiagnosesOtherOtherSub =
    formData["ros_cardioSubDiagnosesOtherOtherSub"];
  const ros_cardioSubDiagnosesOtherSupComment =
    formData["ros_cardioSubDiagnosesOtherSupComment"];
  const ros_cardioSubDiagnosesOtherDesComment =
    formData["ros_cardioSubDiagnosesOtherDesComment"];

  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCardiompathysecondaryToHypertension"],
    );
  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
    formData[
      "ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial =
    setOptionsSelectedYesNo(
      formData[
        "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial"
      ],
    );
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment =
    formData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment"
    ];

  const ros_cardioSubDiagnosesCardiorespiratoryShockDesComment =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockDesComment"];
  const ros_cardioSubDiagnosesHeartTransplant =
    formData["ros_cardioSubDiagnosesHeartTransplant"];
  const ros_cardioSubDiagnosesSympComment =
    formData["ros_cardioSubDiagnosesSympComment"];
  const ros_cardioSubDiagnosesSympOtherSubAnswer =
    formData["ros_cardioSubDiagnosesSympOtherSubAnswer"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment =
    formData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment"
    ];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment =
    formData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment"
    ];
  const ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment =
    formData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer =
    formData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment =
    formData[
      "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment =
    formData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub =
    formData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper =
    setOptionsSelectedYesNo(
      formData[
        "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper"
      ],
    );
  const ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment =
    formData[
      "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer =
    formData["ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub =
    formData["ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre =
    formData["ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre"];
  const ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment =
    formData["ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment"];
  const ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub =
    formData["ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub"];
  const ros_cardioSubDiagnosesHypertensionSympotherSubAnswer =
    formData["ros_cardioSubDiagnosesHypertensionSympotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSympComment =
    formData["ros_cardioSubDiagnosesHypertensionSympComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment =
    formData[
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub =
    formData[
      "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment =
    formData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub =
    formData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment =
    formData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment =
    formData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment =
    formData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment"
    ];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub =
    formData[
      "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment"];

  const ros_diagnosisOfCancerRecommendationsSelected =
    formData["ros_diagnosisOfCancerRecommendations"];
  const ros_diagnosisOfCancerRecommendations = setSelectedCheckboxes(
    ros_diagnosisOfCancerRecommendationsSelected,
    [
      "ros_takemedicationsasprescribedCancerVal",
      "ros_otherCancerVal",
      "ros_casemanagementCancerVal",
      "ros_followupWithCancerVal",
      "ros_durableMedicalCancerVal",
    ],
    [
      "takemedicationsasprescribedCancer",
      "otherCancer",
      "CasemanagementfollowupreferralCancer",
      "followupwithclinispecCancer",
      "membertousedurablemedicalequipCancer",
    ],
  );

  const ros_diagnosisOfCancertitl5Years = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancertitl5Years"],
  );
  const ros_diagnosisOfCancertitl5YearsComment =
    formData["ros_diagnosisOfCancertitl5YearsComment"];

  const ros_gastrorecommendationsSelected =
    formData["ros_gastrorecommendations"];

  const ros_gastrorecommendationscaseManagementComment =
    formData["ros_gastrorecommendationscaseManagementComment"];

  const ros_gastrorecommendationsfollowupComment =
    formData["ros_gastrorecommendationsfollowupComment"];

  const ros_gastrorecommendationsuseDmeComment =
    formData["ros_gastrorecommendationsuseDmeComment"];
  const ros_gastroSubDiagnosesceliacDiseaseGlueten = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );

  const ros_gastrorecommendations = setSelectedCheckboxes(
    ros_gastrorecommendationsSelected,
    [
      "ros_gastrorecommendationstakemedicationsasprescribed",
      "ros_gastrorecommendationsother",
      "ros_gastrorecommendationscaseManagement",
      "ros_gastrorecommendationsfollowup",
      "ros_gastrorecommendationsuseDme",
    ],
    [
      "takemedicationsasprescribed",
      "other",
      "caseManagement",
      "followup",
      "useDme",
    ],
  );
  const ros_endocrineIsEndocrineRecommendationsSelected =
    formData["ros_endocrineIsEndocrineRecommendations"];
  const ros_endocrineIsEndocrineRecommendations = setSelectedCheckboxes(
    ros_endocrineIsEndocrineRecommendationsSelected,
    [
      "ros_takeMedicationsAsPescribedEndoEndocrineVal",
      "ros_checkBloodSugarEndoEndorineVal",
      "ros_otherEndoEndocrineVal",
      "ros_durableMedicalEquipEndoEndocrineVal",
      "ros_followupspecEndoEndocrineVal",
      "ros_caseMangeEndoEndocrineVal",
      "ros_eGFREndoEndocrineVal",
    ],
    [
      "takeMedicationsAsPescribedEndo",
      "checkBloodSugarEndo",
      "otherEndo",
      "durablemedicalequipEndo",
      "followupwithclinispecEndo",
      "casemangfollowEndo",
      "albuminRatioEndo",
    ],
  );
  const genito_recommendationsSelected = formData["ros_genitoRecommendations"];
  const ros_genitoRecommendations = setSelectedCheckboxes(
    genito_recommendationsSelected,
    [
      "ros_takemedicationsasprescribedGenitoVal",
      "ros_otherGenitoVal",
      "ros_memberDurableGenitoVal",
      "ros_casemanagementrefGenitoVal",
      "ros_followupwithclinispecGenitoVal",
    ],
    [
      "takeMedicationsAsPrescribedGenito",
      "otherGenito",
      "memberDurableGenito",
      "casemanagementrefGenito",
      "followupwithclinispecGenito",
    ],
  );
  const ros_diagnosisOfCancerSubDescComment =
    formData["ros_diagnosisOfCancerSubDescComment"];
  const ros_diagnosisOfCancerSubSupComment =
    formData["ros_diagnosisOfCancerSubSupComment"];
  const ros_diagnosisOfCancerSubTypeComment =
    formData["ros_diagnosisOfCancerSubTypeComment"];
  const ros_diagnosisOfCancerSubActiveTreatComment =
    formData["ros_diagnosisOfCancerSubActiveTreatComment"];
  const ros_diagnosisOfCancerSubSideEffComment =
    formData["ros_diagnosisOfCancerSubSideEffComment"];
  const ros_diagnosisOfCancerSubActiveTreatVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_activeTreatment"],
  );
  const ros_diagnosisOfCancerros_activeTreatmentComment =
    formData["ros_diagnosisOfCancerros_activeTreatmentComment"];
  const ros_diagnosisOfCancerros_SeeASpecComment =
    formData["ros_diagnosisOfCancerros_SeeASpecComment"];
  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    formData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_diagnosisOfCancerros_MetastasisComment =
    formData["ros_diagnosisOfCancerros_MetastasisComment"];
  const ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment =
    formData[
      "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment =
    formData[
      "ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment =
    formData[
      "ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment =
    formData[
      "ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment"
    ];
  const ros_diagnosisOfCancerRecommendationsotherCancerComment =
    formData["ros_diagnosisOfCancerRecommendationsotherCancerComment"];
  const ros_diagnosisOfCancerVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancer"],
  );
  const ros_diagnosisOfCancerros_MetastasisVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_Metastasis"],
  );
  const ros_diagnosisOfCancerros_SeeASpecVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_SeeASpec"],
  );
  const ros_diagnosisOfCancerros_MetastasisCachexiaVal =
    setOptionsSelectedYesNo(
      formData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
    );
  const ros_diagnosisOfCancerros_MetastasisLocVal =
    formData["ros_diagnosisOfCancerSubMetastasisLocVal"];
  const ros_diagnosisOfCancerros_SpecProVal =
    formData["ros_diagnosisOfCancerSubSeeASpecProVal"];
  const ros_diagnosisOfCancerCommentVal =
    formData["ros_diagnosisOfCancerComment"];
  const ros_gastroSubDiagnosescolonCrDesComment =
    formData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosesdescolonComment =
    formData["ros_gastroSubDiagnosesdescolonComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    formData["ros_gastroSubDiagnosescolonSupComment"];
  const ros_gastroSubSymptomsOtherSub =
    formData["ros_gastroSubSymptomsOtherSub"];
  const ros_gastroSubSymptomsComment = formData["ros_gastroSubSymptomsComment"];
  const ros_endoVal = setOptionsSelectedYesNo(formData["ros_endoVal"]);
  const ros_gastro = setOptionsSelectedYesNo(formData["ros_gastro"]);
  const ros_genitoVal = setOptionsSelectedYesNo(formData["ros_genitoVal"]);
  const ros_endoValComment = formData["ros_endoValComment"];
  const ros_gastroComment = formData["ros_gastroComment"];
  const ros_genitoValComment = formData["ros_genitoValComment"];
  const ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationsotherEndoComment =
    formData["ros_endocrineIsEndocrineRecommendationsotherEndoComment"];
  const ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment =
    formData["ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment"];
  const ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment"
    ];
  const ros_gastrorecommendationsotherComment =
    formData["ros_gastrorecommendationsotherComment"];
  const ros_gastrorecommendationstakemedicationsasprescribedComment =
    formData["ros_gastrorecommendationstakemedicationsasprescribedComment"];
  const ros_gastroSubDiagnosesComment =
    formData["ros_gastroSubDiagnosesComment"];
  const ros_gastroSubDiagnosescachexiadesComment =
    formData["ros_gastroSubDiagnosescachexiadesComment"];
  const ros_gastroSubDiagnosescachexiaSupComment =
    formData["ros_gastroSubDiagnosescachexiaSupComment"];
  const ros_genitoSubDiagnosesComment =
    formData["ros_genitoSubDiagnosesComment"];
  const ros_endocrineIsEndocrineDiagnoseComment =
    formData["ros_endocrineIsEndocrineDiagnoseComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    formData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];

  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    formData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    formData["ros_gastroSubDiagnosesdesbowelObstructionComment"];
  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];
  const ros_gastroSubDiagnosescirrhosisEndStage = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    formData["ros_gastroSubDiagnosescirrhosisEndStageComment"];
  const ros_gastroSubDiagnosesdescirrhosisComment =
    formData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescirrhosisSupComment =
    formData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    formData["ros_gastroSubDiagnosescolonPolypsDesComment"];
  const ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescolonOtherSubAnswerVal =
    formData["ros_gastroSubDiagnosescolonOtherSubAnswerVal"];
  const ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgerdSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgerdSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal"];
  const ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal"
    ];
  const ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesotherSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesotherSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
    formData["ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisComment =
    formData["ros_gastroSubDiagnosesdesdiverticulitisComment"];
  const ros_gastroSubDiagnosesdiverticulitisabscessVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitisabscessVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisabscessValComment =
    formData["ros_gastroSubDiagnosesdiverticulitisabscessValComment"];

  const ros_gastroSubDiagnosesdiverticulitisperforationVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitisperforationVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisperforationValComment =
    formData["ros_gastroSubDiagnosesdiverticulitisperforationValComment"];

  const ros_gastroSubDiagnosesdiverticulitishighFiberDietVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitishighFiberDietVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
    formData["ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment"];

  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment =
    formData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment"];
  const ros_gastroSubDiagnosesdiverticulitisSupComment =
    formData["ros_gastroSubDiagnosesdiverticulitisSupComment"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupComment =
    formData["ros_gastroSubDiagnosesgallBladderDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesgallBladderDiseaseComment =
    formData["ros_gastroSubDiagnosesdesgallBladderDiseaseComment"];
  const ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesgastroparesisSupportedByComment =
    formData["ros_gastroSubDiagnosesgastroparesisSupportedByComment"];
  const ros_gastroSubDiagnosesgastroparesisDescribeComment =
    formData["ros_gastroSubDiagnosesgastroparesisDescribeComment"];

  const ros_gastroSubDiagnosesgerdDescribeComment =
    formData["ros_gastroSubDiagnosesgerdDescribeComment"];
  const ros_gastroSubDiagnosesgerdSupportedByComment =
    formData["ros_gastroSubDiagnosesgerdSupportedByComment"];
  const ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedDescribeComment =
    formData["ros_gastroSubDiagnosesglBleedDescribeComment"];
  const ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedSupportedByComment =
    formData["ros_gastroSubDiagnosesglBleedSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDescribeComment =
    formData["ros_gastroSubDiagnoseshepatitisDescribeComment"];
  const ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnoseshepatitisSupportedByComment =
    formData["ros_gastroSubDiagnoseshepatitisSupportedByComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
    formData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherDescribeComment =
    formData["ros_gastroSubDiagnosesotherDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisDescribeComment =
    formData["ros_gastroSubDiagnosespancreatitisDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesotherSupportedByComment =
    formData["ros_gastroSubDiagnosesotherSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesulcerDiseaseDescribeComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByComment =
    formData["ros_gastroSubDiagnosespancreatitisSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDessComment =
    formData["ros_gastroSubDiagnoseshepatitisDessComment"];
  const ros_gastroSubDiagnoseshepatitisTypeComment =
    formData["ros_gastroSubDiagnoseshepatitisTypeComment"];
  const ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice"],
    );
  const ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment =
    formData["ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosis = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnoseshepatitisCirrhosis"],
  );
  const ros_gastroCiroalcoholRelated = setOptionsSelectedYesNo(
    formData["ros_gastroCiroalcoholRelated"],
  );
  const ros_gastroCiroalcoholRelatedComment =
    formData["ros_gastroCiroalcoholRelatedComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosisComment =
    formData["ros_gastroSubDiagnoseshepatitisCirrhosisComment"];

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma"],
    );

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
    formData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment"];

  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet"],
    );
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment"
    ];

  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
    formData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment"];

  const ros_gastroSubDiagnosespancreatitisSpecificDiet =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosespancreatitisSpecificDiet"],
    );
  const ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol"],
    );
  const ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment =
    formData["ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment"];
  const ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub =
    formData[
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub"
    ];
  const ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment =
    formData[
      "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment"
    ];

  const ros_gastroSubDiagnosespancreatitisSpecificDietComment =
    formData["ros_gastroSubDiagnosespancreatitisSpecificDietComment"];
  const ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment"];
  const ros_gastroSubDiagnosesulcerDiseaseComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactria =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesulcerDiseaseHeliobactria"],
    );
  const ros_gastroSubDiagnosesotherOther =
    formData["ros_gastroSubDiagnosesotherOther"];
  const ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment"];
  const ros_gastroDateOfTransplant = formData["ros_gastroDateOfTransplant"];
  const ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesgerdActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesgerdActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns =
    formData["ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesglBleedActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubBlood =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdesglBleedActiveSubBlood"],
    );
  const ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment =
    formData["ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment"];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment =
    formData["ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment"];
  const ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment"];
  const ros_gastroSubDiagnosesdescachexiaActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdescachexiaActiveSubTreComment"];
  const ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub =
    formData["ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub"];
  const ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment =
    formData["ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia"],
    );
  const ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment =
    formData["ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment"];
  const ros_genito = setOptionsSelectedYesNo(formData["ros_genito"]);
  const ros_genitoComment = formData["ros_genitoComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal =
    formData["ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal"];
  const ros_genitoSubDiagnosesacuteRenalFailureEtiology =
    formData["ros_genitoSubDiagnosesacuteRenalFailureEtiology"];

  const ros_genitoSubDiagnosesbphDescribeComment =
    formData["ros_genitoSubDiagnosesbphDescribeComment"];

  const ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesbhpActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesbhpActiveSubTreComment"];

  const ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdeschronicActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdeschronicActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeschronicActiveSubSec =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_genitoSubDiagnosesdeschronicActiveSubSec"],
    );
  const ros_genitoSubDiagnosesdeschronicActiveSubSecComment =
    formData["ros_genitoSubDiagnosesdeschronicActiveSubSecComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
    formData["ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment"];

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
    setOptionsSelectedYesNoUnknownOther(
      formData[
        "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension"
      ],
    );

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
    formData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment"
    ];
  const ros_genitoSubDiagnoseswhatStageComment =
    formData["ros_genitoSubDiagnoseswhatStageComment"];
  const ros_genitoSubDiagnosesesrdDescribeComment =
    formData["ros_genitoSubDiagnosesesrdDescribeComment"];

  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
    formData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet =
    setOptionsSelectedYesNo(
      formData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet"],
    );
  const ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment"];
  const ros_genitoSubDiagnosesdesesrdActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesesrdActiveSubTreComment"];
  const ros_genitoSubDiagnoActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnoActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesgynecoActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesgynecoActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeskidneyActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdeskidneyActiveSubTreComment"];
  const ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdesfreqsActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesfreqsActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesdeserectileActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdeserectileActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesErectileTreOtherSub =
    formData["ros_genitoSubDiagnosesdesErectileTreOtherSub"];
  const ros_genitoSubDiagnosesdesOtherActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesOtherActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesacuteActiveSubTreComment =
    formData["ros_genitoSubDiagnosesdesacuteActiveSubTreComment"];
  const ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub =
    formData["ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment =
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment"];
  const ros_genitoSubDiagnosesesrddialysisSchedule =
    formData["ros_genitoSubDiagnosesesrddialysisSchedule"];
  const ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
    formData["ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice"];
  const ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
    formData["ros_genitoSubDiagnoseserectileDysfunctionDescribeComment"];

  const ros_genitoSubDiagnosesfrequentUtiDescribeComment =
    formData["ros_genitoSubDiagnosesfrequentUtiDescribeComment"];

  const ros_genitoSubDiagnosesgynecologicalDescribeComment =
    formData["ros_genitoSubDiagnosesgynecologicalDescribeComment"];

  const ros_genitoSubDiagnoseskidneyStonesDescribeComment =
    formData["ros_genitoSubDiagnoseskidneyStonesDescribeComment"];

  const ros_genitoSubDiagnoseskidneyStonesTypeComment =
    formData["ros_genitoSubDiagnoseskidneyStonesTypeComment"];

  const ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
    formData["ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment"];

  const ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
    formData["ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment"];

  const ros_genitoSubDiagnosesotherDescribeComment =
    formData["ros_genitoSubDiagnosesotherDescribeComment"];

  const ros_genitoSubDiagnosesotherOther =
    formData["ros_genitoSubDiagnosesotherOther"];
  const ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
    formData["ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment"];
  const ros_genitoSubSymptomsComment = formData["ros_genitoSubSymptomsComment"];
  const ros_genitoSubSymptomsOtherSub =
    formData["ros_genitoSubSymptomsOtherSub"];
  const ros_genitoStress = setOptionsSelectedYesNo(
    formData["ros_genitoStress"],
  );
  const ros_genitoStressComment = formData["ros_genitoStressComment"];
  const ros_genitoStressSubComment = formData["ros_genitoStressSubComment"];

  const ros_endoComment = formData["ros_endoComment"];
  const ros_endocrineIsEndocrine = setOptionsSelectedYesNo(
    formData["ros_endocrineIsEndocrine"],
  );
  const ros_endocrineIsEndocrineComment =
    formData["ros_endocrineIsEndocrineComment"];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment"];
  const ros_genitoRecommendationsotherGenitoComment =
    formData["ros_genitoRecommendationsotherGenitoComment"];
  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    formData[
      "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment"
    ];
  const ros_genitoRecommendationscasemanagementrefGenitoComment =
    formData["ros_genitoRecommendationscasemanagementrefGenitoComment"];
  const ros_genitoRecommendationsmemberDurableGenitoComment =
    formData["ros_genitoRecommendationsmemberDurableGenitoComment"];
  const ros_genitoRecommendationsfollowupwithclinispecGenitoComment =
    formData["ros_genitoRecommendationsfollowupwithclinispecGenitoComment"];

  const ros_diagnosisOfCancerSubSupOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubSupOtherValSubAnswer"];

  const ros_diagnosisOfCancerstageSpecificToTheCancer =
    formData["ros_diagnosisOfCancerstageSpecificToTheCancer"];

  const ros_diagnosisOfCancerspecialTypes =
    formData["ros_diagnosisOfCancerspecialTypes"];
  const ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer"];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubStageComment =
    formData["ros_endocrineIsEndocrineSubchronicKidneySubStageComment"];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
    formData["ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment"
    ];
  const ros_endocrineIsEndocrineSubneuroSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubneuroSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubNeuroSubSympComment =
    formData["ros_endocrineIsEndocrineSubNeuroSubSympComment"];
  const ros_endocrineIsEndocrineSubNeuroSubTreatComment =
    formData["ros_endocrineIsEndocrineSubNeuroSubTreatComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
    setOptionsSelectedYesNoUnknownOther(
      formData[
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineNeuroSubPatientOftenComment =
    formData["ros_endocrineIsEndocrineNeuroSubPatientOftenComment"];
  const ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe =
    formData["ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe"];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment =
    formData["ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment"];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment =
    formData["ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment"];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub =
    formData["ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub"];
  const ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment =
    formData["ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment"];
  const ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns =
    formData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment =
    formData["ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment"];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
    formData["ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
    formData["ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment =
    formData["ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment"];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
    formData["ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment"];
  const ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseotherDescribeComment =
    formData["ros_endocrineIsEndocrineDiagnoseotherDescribeComment"];
  const ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer =
    formData["ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer"];
  const ros_endocrineIsEndocrineDiagnoseotherSupComment =
    formData["ros_endocrineIsEndocrineDiagnoseotherSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherOther =
    formData["ros_endocrineIsEndocrineDiagnoseotherOther"];
  const ros_endocrineIsEndocrineNoEduSelected =
    formData["ros_endocrineIsEndocrineNoEdu"];
  const ros_endocrineIsEndocrineNoEdu =
    ros_endocrineIsEndocrineNoEduSelected?.includes("educationProvidNo")
      ? "1"
      : "";
  const ros_endocrineIsEndocrineUnknownEduSelected =
    formData["ros_endocrineIsEndocrineUnknownEdu"];
  const ros_endocrineIsEndocrineUnknownEdu =
    ros_endocrineIsEndocrineUnknownEduSelected?.includes(
      "educationProvidUnknown",
    )
      ? "1"
      : "";
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubSelected =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubSelected =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub =
    setSelectedCheckboxesOthers(
      [
        ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubSelected,
      ],
      [
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubonceAYear",
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubtwiceAYear",
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubquarterly",
      ],
      ["onceAYear", "twiceAYear", "quarterly"],
    );

  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub =
    setSelectedCheckboxesOthers(
      [
        ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubSelected,
      ],
      [
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubonceAYear",
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubtwiceAYear",
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubquarterly",
      ],
      ["onceAYear", "twiceAYear", "quarterly"],
    );

  const ros_diagnosisOfCancerSubTypeOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubTypeOtherValSubAnswer"];
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment =
    formData["ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment"];
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub =
    formData["ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub"];
  const ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment =
    formData["ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment"];
  const ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment"
    ];
  const ros_endocrineIsEndocrineSubVascularSubPhysicalComment =
    formData["ros_endocrineIsEndocrineSubVascularSubPhysicalComment"];
  const ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubVascularSubTreatComment =
    formData["ros_endocrineIsEndocrineSubVascularSubTreatComment"];
  const ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubAutonomicSubTreatComment =
    formData["ros_endocrineIsEndocrineSubAutonomicSubTreatComment"];
  const ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubchronicKidneySubTreatComment =
    formData["ros_endocrineIsEndocrineSubchronicKidneySubTreatComment"];
  const ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment =
    formData["ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment"];
  const ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment =
    formData["ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment"];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub"
    ];
  const ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment"
    ];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c =
    formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c"];
  const ros_endocrineIsEndocrineSubdiabetesSubandDate =
    formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"];
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet =
    setOptionsSelectedYesNo(
      formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet"],
    );
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest =
    setOptionsSelectedYesNo(
      formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest"],
    );
  const ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr"],
    );
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub =
    formData["ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment =
    formData["ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment"
    ];

  tempData = {
    ...tempData,
    ros_respiratoryComment,
    ros_respiratoryVal,
    ros_respiratoryAcuteUpperSubAnswerDescribeComment,
    ros_respiratoryDiagnosesComment,
    ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc,
    ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment:
      ros_respiratoryAcuteUpperActiveTreatmentSubAnswerComment || "",
    ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment:
      ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment ||
      "",
    ros_respiratoryAsthmaSubOtherTreatmentSubDesc:
      ros_respiratoryAsthmaSubOtherTreatmentSubDesc || "",
    ros_respiratoryAsthmaActiveTreatmentSubAnswerComment:
      ros_respiratoryAsthmaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryAsthmaMedicationSubAnswerComment:
      ros_respiratoryAsthmaMedicationSubAnswerComment || "",
    ros_respiratoryAsthmaCurrentExacerbationVal:
      ros_respiratoryAsthmaCurrentExacerbationVal || "",
    ros_respiratoryAsthmaCurrentExacerbationComment:
      ros_respiratoryAsthmaCurrentExacerbationComment || "",
    ros_respiratoryAsthmaSubAnswerDescribeComment:
      ros_respiratoryAsthmaSubAnswerDescribeComment || "",
    ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment:
      ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment || "",
    ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc:
      ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc || "",
    ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment:
      ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswerComment || "",
    ros_respiratoryChronicSputumFailureSubAnswerDescribeComment:
      ros_respiratoryChronicSputumFailureSubAnswerDescribeComment || "",
    ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc:
      ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc || "",
    ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment:
      ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswerComment || "",
    ros_respiratoryCopdSubAnswerDescribeComment:
      ros_respiratoryCopdSubAnswerDescribeComment || "",
    ros_respiratoryCopdTypeSubAnswerComment:
      ros_respiratoryCopdTypeSubAnswerComment || "",
    ros_respiratoryCopdSubOtherTreatmentSubDesc:
      ros_respiratoryCopdSubOtherTreatmentSubDesc || "",
    ros_respiratoryCopdActiveTreatmentSubAnswerComment:
      ros_respiratoryCopdActiveTreatmentSubAnswerComment || "",
    ros_respiratoryCopdMedicationSubAnswerComment:
      ros_respiratoryCopdMedicationSubAnswerComment || "",
    ros_respiratoryPatientExacerbationVal:
      ros_respiratoryPatientExacerbationVal || "",
    ros_respiratoryPatientExacerbationComment:
      ros_respiratoryPatientExacerbationComment || "",
    ros_respiratoryCopdSubPatientExacerbationComment:
      ros_respiratoryCopdSubPatientExacerbationComment || "",
    ros_respiratoryCysticFibrosisSubAnswerDescribeComment:
      ros_respiratoryCysticFibrosisSubAnswerDescribeComment || "",
    ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc:
      ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc || "",
    ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment:
      ros_respiratoryCysticFibrosisActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoventilationSubAnswerDescribeComment:
      ros_respiratoryHypoventilationSubAnswerDescribeComment || "",
    ros_respiratoryHypoventilationSubOtherTreatmentSubDesc:
      ros_respiratoryHypoventilationSubOtherTreatmentSubDesc || "",
    ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment:
      ros_respiratoryHypoventilationActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoventilationActiveSelectSubAnswerComment:
      ros_respiratoryHypoventilationActiveSelectSubAnswerComment || "",
    ros_respiratoryHypoxemiaSubAnswerDescribeComment:
      ros_respiratoryHypoxemiaSubAnswerDescribeComment || "",
    ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc:
      ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc || "",
    ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment:
      ros_respiratoryHypoxemiaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryHypoxemiaActiveSelectSubAnswerComment:
      ros_respiratoryHypoxemiaActiveSelectSubAnswerComment || "",
    ros_respiratoryPneumoniaSubAnswerDescribeComment:
      ros_respiratoryPneumoniaSubAnswerDescribeComment || "",
    ros_respiratoryPneumoniaSubAnswerEtiologyComment:
      ros_respiratoryPneumoniaSubAnswerEtiologyComment || "",
    ros_respiratoryHistoryOfLungAbscessVal:
      ros_respiratoryHistoryOfLungAbscessVal || "",
    ros_respiratoryHistoryOfEmpyemaVal:
      ros_respiratoryHistoryOfEmpyemaVal || "",
    ros_respiratoryHistoryOfLungAbscessComment:
      ros_respiratoryHistoryOfLungAbscessComment || "",
    ros_respiratoryHistoryOfEmpyemaComment:
      ros_respiratoryHistoryOfEmpyemaComment || "",
    ros_respiratoryPneumoniaSubOtherTreatmentSubDesc:
      ros_respiratoryPneumoniaSubOtherTreatmentSubDesc || "",
    ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment:
      ros_respiratoryPneumoniaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment:
      ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment || "",
    ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment:
      ros_respiratoryPulmonaryEmbolismSubAnswerDescribeComment || "",
    ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswerComment || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc:
      ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc || "",
    ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment:
      ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswerComment || "",
    ros_respiratoryRespiratorDependenceSubAnswerDescribeComment:
      ros_respiratoryRespiratorDependenceSubAnswerDescribeComment || "",
    ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc:
      ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment:
      ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswerComment || "",
    ros_respiratoryRespiratoryArrestSubAnswerDescribeComment:
      ros_respiratoryRespiratoryArrestSubAnswerDescribeComment || "",
    ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc:
      ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment:
      ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswerComment || "",
    ros_respiratorySarcoidosisSubAnswerDescribeComment:
      ros_respiratorySarcoidosisSubAnswerDescribeComment || "",
    ros_respiratorySarcoidosisSubAnswerSpecifyComment:
      ros_respiratorySarcoidosisSubAnswerSpecifyComment || "",
    ros_respiratorySarcoidosisSubOtherTreatmentSubDesc:
      ros_respiratorySarcoidosisSubOtherTreatmentSubDesc || "",
    ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment:
      ros_respiratorySarcoidosisActiveTreatmentSubAnswerComment || "",
    ros_respiratorySleepApneaSubAnswerDescribeComment:
      ros_respiratorySleepApneaSubAnswerDescribeComment || "",
    ros_respiratorySleepApneaActiveSelectSubAnswerVal:
      ros_respiratorySleepApneaActiveSelectSubAnswerVal || "",
    ros_respiratorySleepApneaActiveSelectSubAnswerComment:
      ros_respiratorySleepApneaActiveSelectSubAnswerComment || "",
    ros_respiratorySleepApneaSubOtherTreatmentSubDesc:
      ros_respiratorySleepApneaSubOtherTreatmentSubDesc || "",
    ros_respiratorySleepApneaActiveTreatmentSubAnswerComment:
      ros_respiratorySleepApneaActiveTreatmentSubAnswerComment || "",
    ros_respiratoryLungTransplantSubOtherTreatmentSubDesc:
      ros_respiratoryLungTransplantSubOtherTreatmentSubDesc || "",
    ros_respiratoryRespOtherSubAnswerDescribeComment:
      ros_respiratoryRespOtherSubAnswerDescribeComment || "",
    ros_respiratoryOtherSubDesc: ros_respiratoryOtherSubDesc || "",
    ros_respiratoryOtherSubAnswerOther:
      ros_respiratoryOtherSubAnswerOther || "",
    ros_respiratoryRespOtherSubAnswerSupportedByComment:
      ros_respiratoryRespOtherSubAnswerSupportedByComment || "",
    ros_respiratorySymptomsSubOtherSubDesc:
      ros_respiratorySymptomsSubOtherSubDesc || "",
    ros_respiratorySymptomsComment: ros_respiratorySymptomsComment || "",

    ...respiratory_RecommendationsVal,
    respiratory_RecommendationstakeMedicationsCommentVal,
    respiratory_RecommendationsotherCommentVal,
    respiratory_RecommendationscaseManagementCommentVal,
    respiratory_RecommendationsfollowUpClinicianCommentVal,
    respiratory_RecommendationsDMECommentVal,

    ros_integumentComment: ros_integumentComment || "",
    ros_integumentDiagnosesComment: ros_integumentDiagnosesComment || "",
    ros_integumentBasilCellCarcinomaSubAnswerDescribeComment:
      ros_integumentBasilCellCarcinomaSubAnswerDescribeComment || "",
    ros_integumentBasilCellCarcinomaSubLocation:
      ros_integumentBasilCellCarcinomaSubLocation || "",
    ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc:
      ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc || "",
    ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment:
      ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswerComment || "",
    ros_integumentVal: ros_integumentVal || "",
    ros_integumentDermatitisSubAnswerDescribeComment:
      ros_integumentDermatitisSubAnswerDescribeComment || "",
    ros_integumentDermatitisSubLocation:
      ros_integumentDermatitisSubLocation || "",
    ros_integumentDermatitisSubOtherTreatmentSubDesc:
      ros_integumentDermatitisSubOtherTreatmentSubDesc || "",
    ros_integumentDermatitisActiveTreatmentSubAnswerComment:
      ros_integumentDermatitisActiveTreatmentSubAnswerComment || "",
    ros_integumentDermatitisSubAnswerWhatTypeComment:
      ros_integumentDermatitisSubAnswerWhatTypeComment || "",
    ros_integumentEczemaSubAnswerDescribeComment:
      ros_integumentEczemaSubAnswerDescribeComment || "",
    ros_integumentEczemaSubLocation: ros_integumentEczemaSubLocation || "",
    ros_integumentEczemaSubOtherTreatmentSubDesc:
      ros_integumentEczemaSubOtherTreatmentSubDesc || "",
    ros_integumentEczemaActiveTreatmentSubAnswerComment:
      ros_integumentEczemaActiveTreatmentSubAnswerComment || "",
    ros_integumentOnychomycosisSubAnswerDescribeComment:
      ros_integumentOnychomycosisSubAnswerDescribeComment || "",
    ros_integumentOnychomycosisSubLocation:
      ros_integumentOnychomycosisSubLocation || "",
    ros_integumentOnychomycosisSubOtherTreatmentSubDesc:
      ros_integumentOnychomycosisSubOtherTreatmentSubDesc || "",
    ros_integumentOnychomycosisActiveTreatmentSubAnswerComment:
      ros_integumentOnychomycosisActiveTreatmentSubAnswerComment || "",
    ros_integumentPsoriasisSubAnswerDescribeComment:
      ros_integumentPsoriasisSubAnswerDescribeComment || "",
    ros_integumentPsoriasisSubLocation:
      ros_integumentPsoriasisSubLocation || "",
    ros_integumentPsoriasisSubOtherTreatmentSubDesc:
      ros_integumentPsoriasisSubOtherTreatmentSubDesc || "",
    ros_integumentPsoriasisActiveTreatmentSubAnswerComment:
      ros_integumentPsoriasisActiveTreatmentSubAnswerComment || "",
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal:
      ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal || "",
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment:
      ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment || "",
    ros_integumentSkinUlcerSubAnswerDescribeComment:
      ros_integumentSkinUlcerSubAnswerDescribeComment || "",
    ros_integumentSkinUlcerSubLocation:
      ros_integumentSkinUlcerSubLocation || "",
    ros_integumentSkinUlcerSubOtherTreatmentSubDesc:
      ros_integumentSkinUlcerSubOtherTreatmentSubDesc || "",
    ros_integumentSkinUlcerActiveTreatmentSubAnswerComment:
      ros_integumentSkinUlcerActiveTreatmentSubAnswerComment || "",
    ros_integumentSkinUlcerSubEtiologySubDesc:
      ros_integumentSkinUlcerSubEtiologySubDesc || "",
    ros_integumentSkinUlcerSubAnswerEtiologyComment:
      ros_integumentSkinUlcerSubAnswerEtiologyComment || "",
    ros_integumentTineaPedisSubAnswerDescribeComment:
      ros_integumentTineaPedisSubAnswerDescribeComment || "",
    ros_integumentTineaPedisSubOtherTreatmentSubDesc:
      ros_integumentTineaPedisSubOtherTreatmentSubDesc || "",
    ros_integumentTineaPedisActiveTreatmentSubAnswerComment:
      ros_integumentTineaPedisActiveTreatmentSubAnswerComment || "",
    ros_integumentTineaPedisSelectTreatmentSubAnswerComment:
      ros_integumentTineaPedisSelectTreatmentSubAnswerComment || "",
    ros_integumentUrticarialDiseaseSubAnswerDescribeComment:
      ros_integumentUrticarialDiseaseSubAnswerDescribeComment || "",
    ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc:
      ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc || "",
    ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment:
      ros_integumentUrticarialDiseaseActiveTreatmentSubAnswerComment || "",
    ros_integumentUrticarialDiseaseSubAnswerTypeComment:
      ros_integumentUrticarialDiseaseSubAnswerTypeComment || "",
    ros_integumentUrticarialDiseaseSubAnswerEtiology:
      ros_integumentUrticarialDiseaseSubAnswerEtiology || "",
    ros_integumentUrticarialDiseaseSubLocation:
      ros_integumentUrticarialDiseaseSubLocation || "",
    ros_integumentWoundSubAnswerDescribeComment:
      ros_integumentWoundSubAnswerDescribeComment || "",
    ros_integumentWoundSubOtherTreatmentSubDesc:
      ros_integumentWoundSubOtherTreatmentSubDesc || "",
    ros_integumentWoundSubLocation: ros_integumentWoundSubLocation || "",
    ros_integumentWoundActiveTreatmentSubAnswerComment:
      ros_integumentWoundActiveTreatmentSubAnswerComment || "",
    ros_integumentWoundSubAnswerEtiologyComment:
      ros_integumentWoundSubAnswerEtiologyComment || "",
    ros_integumentOthersSubAnswerDescribeComment:
      ros_integumentOthersSubAnswerDescribeComment || "",
    ros_integumentOthersSubLocation: ros_integumentOthersSubLocation || "",
    ros_integumentOthersSubOtherTreatmentSubDesc:
      ros_integumentOthersSubOtherTreatmentSubDesc || "",
    ros_integumentOthersActiveTreatmentSubAnswerComment:
      ros_integumentOthersActiveTreatmentSubAnswerComment || "",
    ros_integumentOthersSubAnswerOther:
      ros_integumentOthersSubAnswerOther || "",
    ...integument_RecommendationsVal,
    integument_RecommendationstakeMedicationsAsPescribedCommentVal,
    integument_RecommendationscaseManagementCommentVal,
    integument_RecommendationsfollowUpClinicianCommentVal,
    integument_RecommendationsDMECommentVal,
    integument_RecommendationsotherCommentVal,

    ros_musculoskeletalVal: ros_musculoskeletalVal || "",
    ros_musculoskeletalComment: ros_musculoskeletalComment || "",
    ros_musculoskeletalDiagnosesComment:
      ros_musculoskeletalDiagnosesComment || "",
    ros_musculoskeletalDermatomyositisSubAnswerDescribeComment:
      ros_musculoskeletalDermatomyositisSubAnswerDescribeComment || "",
    ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc:
      ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc:
      ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc:
      ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment:
      ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswerComment ||
      "",
    ros_musculoskeletalExtremityFractureSubAnswerDescribeComment:
      ros_musculoskeletalExtremityFractureSubAnswerDescribeComment || "",
    ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment:
      ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment:
      ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc:
      ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment:
      ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureSubAnswerExtremityComment:
      ros_musculoskeletalExtremityFractureSubAnswerExtremityComment || "",
    ros_musculoskeletalExtremityFractureShoulderSubAnswerComment:
      ros_musculoskeletalExtremityFractureShoulderSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureForearmSubAnswerComment:
      ros_musculoskeletalExtremityFractureForearmSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureWristSubAnswerComment:
      ros_musculoskeletalExtremityFractureWristSubAnswerComment || "",
    ros_musculoskeletalHandSubAnswerComment:
      ros_musculoskeletalHandSubAnswerComment || "",
    ros_musculoskeletalFemoralShaftSubAnswerComment:
      ros_musculoskeletalFemoralShaftSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureTibiaSubAnswerComment:
      ros_musculoskeletalExtremityFractureTibiaSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureFibulaSubAnswerComment:
      ros_musculoskeletalExtremityFractureFibulaSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureArmSubAnswerComment:
      ros_musculoskeletalExtremityFractureArmSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureAnkleSubAnswerComment:
      ros_musculoskeletalExtremityFractureAnkleSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureFootSubAnswerComment:
      ros_musculoskeletalExtremityFractureFootSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureHipSubAnswerComment:
      ros_musculoskeletalExtremityFractureHipSubAnswerComment || "",
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal:
      ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal ||
      "",
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment:
      ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment ||
      "",
    ros_musculoskeletalGoutSubAnswerDescribeComment:
      ros_musculoskeletalGoutSubAnswerDescribeComment || "",
    ros_musculoskeletalGoutSubOtherTreatmentSubDesc:
      ros_musculoskeletalGoutSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalGoutActiveTreatmentSubAnswerComment:
      ros_musculoskeletalGoutActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalHalluxValgusSubAnswerDescribeComment:
      ros_musculoskeletalHalluxValgusSubAnswerDescribeComment || "",
    ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc:
      ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment:
      ros_musculoskeletalHalluxValgusTreatmentSubAnswerComment || "",
    ros_musculoskeletalHammerToesSubAnswerDescribeComment:
      ros_musculoskeletalHammerToesSubAnswerDescribeComment || "",
    ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment:
      ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswerComment || "",
    ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc:
      ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment:
      ros_musculoskeletalHammerToesActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints:
      ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment:
      ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment:
      ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalOsteomyelitisSubLocation:
      ros_musculoskeletalOsteomyelitisSubLocation || "",
    ros_musculoskeletalOsteoporosisSubAnswerDescribeComment:
      ros_musculoskeletalOsteoporosisSubAnswerDescribeComment || "",
    ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc:
      ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment:
      ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints:
      ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment:
      ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment || "",
    ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc:
      ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswerComment ||
      "",
    ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints:
      ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints || "",
    ros_musculoskeletalSclerodermaSubAnswerDescribeComment:
      ros_musculoskeletalSclerodermaSubAnswerDescribeComment || "",
    ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc:
      ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSclerodermaActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment:
      ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment || "",
    ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc:
      ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal:
      ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal || "",
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment:
      ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment || "",
    ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment:
      ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDiseaseComment || "",
    ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc:
      ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc || "",
    ros_musculoskeletalSystemicLupusSubAnswerDescribeComment:
      ros_musculoskeletalSystemicLupusSubAnswerDescribeComment || "",
    ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc:
      ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment:
      ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalMuskoOtherSubAnswerDescribeComment:
      ros_musculoskeletalMuskoOtherSubAnswerDescribeComment || "",
    ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc:
      ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc || "",
    ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment:
      ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswerComment || "",
    ros_musculoskeletalMuskoOtherSubAnswerother:
      ros_musculoskeletalMuskoOtherSubAnswerother || "",
    ros_musculoskeletalMuskoSymptomsSubOtherSubDesc:
      ros_musculoskeletalMuskoSymptomsSubOtherSubDesc || "",
    ros_musculoskeletalMuskoSymptomsComment:
      ros_musculoskeletalMuskoSymptomsComment || "",
    ros_musculoskeletalHadAnAmputationSubAnswer:
      ros_musculoskeletalHadAnAmputationSubAnswer || "",
    ros_musculoskeletalMuskoHadAnAmputationVal:
      ros_musculoskeletalMuskoHadAnAmputationVal || "",
    ros_musculoskeletalMuskoHadAnAmputationComment:
      ros_musculoskeletalMuskoHadAnAmputationComment || "",
    ...musko_RecommendationsVal,
    musko_RecommendationsdiscussPtOtCommentVal,
    musko_RecommendationstakeMedicationsAsPescribedCommentVal,
    musko_RecommendationscaseManagementCommentVal,
    musko_RecommendationsfollowUpClinicianCommentVal,
    musko_RecommendationsDMECommentVal,
    musko_RecommendationsotherCommentVal,

    ros_hematologyVal: ros_hematologyVal || "",
    ros_hematologyComment: ros_hematologyComment || "",
    ros_hematologyDiagnosesComment: ros_hematologyDiagnosesComment || "",
    ros_hematologyAidsSubAnswerDescribeComment:
      ros_hematologyAidsSubAnswerDescribeComment || "",
    ros_hematologyAidsSubOtherTreatmentSubDesc:
      ros_hematologyAidsSubOtherTreatmentSubDesc || "",
    ros_hematologyAidsActiveTreatmentSubAnswerComment:
      ros_hematologyAidsActiveTreatmentSubAnswerComment || "",
    ros_hematologyAnemiaSubAnswerDescribeComment:
      ros_hematologyAnemiaSubAnswerDescribeComment || "",
    ros_hematologyAnemiaSubAnswerEtiologyComment:
      ros_hematologyAnemiaSubAnswerEtiologyComment || "",
    ros_hematologyAnemiaSubEtiologySubOtherSubDesc:
      ros_hematologyAnemiaSubEtiologySubOtherSubDesc || "",
    ros_hematologyAnemiaSubOtherTreatmentSubDesc:
      ros_hematologyAnemiaSubOtherTreatmentSubDesc || "",
    ros_hematologyAnemiaActiveTreatmentSubAnswerComment:
      ros_hematologyAnemiaActiveTreatmentSubAnswerComment || "",
    ros_hematologyAnemiaSubYesPatientOnSubDesc:
      ros_hematologyAnemiaSubYesPatientOnSubDesc || "",
    ros_hematologyAnemiaSubAnswerYesPatientOnComment:
      ros_hematologyAnemiaSubAnswerYesPatientOnComment || "",
    ros_hematologyCDifficileSubAnswerDescribeComment:
      ros_hematologyCDifficileSubAnswerDescribeComment || "",
    ros_hematologyCDifficileSubOtherTreatmentSubDesc:
      ros_hematologyCDifficileSubOtherTreatmentSubDesc || "",
    ros_hematologyCDifficileActiveTreatmentSubAnswerComment:
      ros_hematologyCDifficileActiveTreatmentSubAnswerComment || "",
    ros_hematologyCommunityAcquiredSubAnswerDescribeComment:
      ros_hematologyCommunityAcquiredSubAnswerDescribeComment || "",
    ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc:
      ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc || "",
    ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment:
      ros_hematologyCommunityAcquiredActiveTreatmentSubAnswerComment || "",
    ros_hematologyHivSubAnswerPatientSymptomaticVal:
      ros_hematologyHivSubAnswerPatientSymptomaticVal || "",
    ros_hematologyHivSubAnswerActiveTreatmentVal:
      ros_hematologyHivSubAnswerActiveTreatmentVal || "",
    ros_hematologyHivSubAnswerViralLoad:
      ros_hematologyHivSubAnswerViralLoad || "",
    ros_hematologyHivSubAnswerCFour: ros_hematologyHivSubAnswerCFour || "",
    ros_hematologyHivSubAnswerPatientSymptomaticComment:
      ros_hematologyHivSubAnswerPatientSymptomaticComment || "",
    ros_hematologyHivSubAnswerActiveTreatmentComment:
      ros_hematologyHivSubAnswerActiveTreatmentComment || "",
    ros_hematologyHivSubOtherTreatmentSubDesc:
      ros_hematologyHivSubOtherTreatmentSubDesc || "",
    ros_hematologyHivActiveTreatmentSubAnswerComment:
      ros_hematologyHivActiveTreatmentSubAnswerComment || "",
    ros_hematologyHivSubAnswerDescribeComment:
      ros_hematologyHivSubAnswerDescribeComment || "",
    ros_hematologyHerpesZosterSubAnswerDescribeComment:
      ros_hematologyHerpesZosterSubAnswerDescribeComment || "",
    ros_hematologyHerpesZosterTypeSubAnswerComment:
      ros_hematologyHerpesZosterTypeSubAnswerComment || "",
    ros_hematologyHerpesZosterActiveSubOtherSubDesc:
      ros_hematologyHerpesZosterActiveSubOtherSubDesc || "",
    ros_hematologyHerpesZosterSubOtherTreatmentSubDesc:
      ros_hematologyHerpesZosterSubOtherTreatmentSubDesc || "",
    ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment:
      ros_hematologyHerpesZosterActiveTreatmentSubAnswerComment || "",
    ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment:
      ros_hematologyHerpesZosterHistoryOfTypeSubAnswerComment || "",
    ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc:
      ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc || "",
    ros_hematologyMrsaInfectionSubAnswerDescribeComment:
      ros_hematologyMrsaInfectionSubAnswerDescribeComment || "",
    ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc:
      ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc || "",
    ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment:
      ros_hematologyMrsaInfectionActiveTreatmentSubAnswerComment || "",
    ros_hematologyImmuneDeficiencySubAnswerDescribeComment:
      ros_hematologyImmuneDeficiencySubAnswerDescribeComment || "",
    ros_hematologyImmuneDeficiencySubAnswerEtiology:
      ros_hematologyImmuneDeficiencySubAnswerEtiology || "",
    ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc:
      ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc || "",
    ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment:
      ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswerComment || "",
    ros_hematologySepsisSubAnswerDescribeComment:
      ros_hematologySepsisSubAnswerDescribeComment || "",
    ros_hematologySepsisSubOtherTreatmentSubDesc:
      ros_hematologySepsisSubOtherTreatmentSubDesc || "",
    ros_hematologySepsisActiveTreatmentSubAnswerComment:
      ros_hematologySepsisActiveTreatmentSubAnswerComment || "",
    ros_hematologySickleCellDiseaseSubAnswerDescribeComment:
      ros_hematologySickleCellDiseaseSubAnswerDescribeComment || "",
    ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment:
      ros_hematologySickleCellDiseaseActiveTreatmentSubAnswerComment || "",
    ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc:
      ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc || "",
    ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment:
      ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswerComment || "",
    ros_hematologySickleCellTraitSubAnswerDescribeComment:
      ros_hematologySickleCellTraitSubAnswerDescribeComment || "",
    ros_hematologySickleCellTraitSubOtherTreatmentSubDesc:
      ros_hematologySickleCellTraitSubOtherTreatmentSubDesc || "",
    ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment:
      ros_hematologySickleCellTraitActiveTreatmentSubAnswerComment || "",
    ros_hematologyThalassemiaSubAnswerDescribeComment:
      ros_hematologyThalassemiaSubAnswerDescribeComment || "",
    ros_hematologyThalassemiaSubOtherTreatmentSubDesc:
      ros_hematologyThalassemiaSubOtherTreatmentSubDesc || "",
    ros_hematologyThalassemiaActiveTreatmentSubAnswerComment:
      ros_hematologyThalassemiaActiveTreatmentSubAnswerComment || "",
    ros_hematologyThrombocytopeniaSubAnswerDescribeComment:
      ros_hematologyThrombocytopeniaSubAnswerDescribeComment || "",
    ros_hematologyThrombocytopeniaSubAnswerEtiology:
      ros_hematologyThrombocytopeniaSubAnswerEtiology || "",
    ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc:
      ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc || "",
    ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment:
      ros_hematologyThrombocytopeniaActiveTreatmentSubAnswerComment || "",
    ros_hematologyTuberculosisSubAnswerDescribeComment:
      ros_hematologyTuberculosisSubAnswerDescribeComment || "",
    ros_hematologyTuberculosisSubAnswerGivenBCGComment:
      ros_hematologyTuberculosisSubAnswerGivenBCGComment || "",
    ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment:
      ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment || "",
    ros_hematologyTuberculosisSubAnswerTbInfectionComment:
      ros_hematologyTuberculosisSubAnswerTbInfectionComment || "",
    ros_hematologyTuberculosisSubOtherTreatmentSubDesc:
      ros_hematologyTuberculosisSubOtherTreatmentSubDesc || "",
    ros_hematologyTuberculosisActiveTreatmentSubAnswerComment:
      ros_hematologyTuberculosisActiveTreatmentSubAnswerComment || "",
    ros_hematologyVitaminDDeficiencySubAnswerDescribeComment:
      ros_hematologyVitaminDDeficiencySubAnswerDescribeComment || "",
    ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc:
      ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc || "",
    ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment:
      ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswerComment || "",
    ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc:
      ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc || "",
    ros_hematologyHemaOtherSubOtherSubDesc:
      ros_hematologyHemaOtherSubOtherSubDesc || "",
    ros_hematologyHemaOtherSubAnswerDescribeComment:
      ros_hematologyHemaOtherSubAnswerDescribeComment || "",
    ros_hematologyHemaOtherSubAnswerSupportedByComment:
      ros_hematologyHemaOtherSubAnswerSupportedByComment || "",
    ros_hematologyHemaOtherSubAnswerOther:
      ros_hematologyHemaOtherSubAnswerOther || "",

    ...hema_RecommendationsVal,
    hema_RecommendationstakeMedicationsAsPescribedCommentVal,
    hema_RecommendationsreportAbnormalBruisingCommentVal,
    hema_RecommendationsfollowUpWithDoctorCommentVal,
    hema_RecommendationscaseManagementCommentVal,
    hema_RecommendationsfollowUpClinicianCommentVal,
    hema_RecommendationsDMECommentVal,
    hema_RecommendationsotherCommentVal,
    hema_RecommendationsotherCommentVal,

    ...ros_cardiovascularRecommendations,
    ros_cardioVal,
    ros_cardioComment,
    ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment,
    ros_cardiovascularRecommendationsotherComment,
    ros_cardiovascularRecommendationsmemberTouseDurableEquipComment,
    ros_cardiovascularRecommendationsFollowupClinicianSpecialistComment,
    ros_cardiovascularRecommendationscasemangereferralfollowupComment,
    ros_cardiovascularRecommendationsexerciseThirtyMinADayComment,
    ros_cardiovascularRecommendationsbloodPressureChecksComment,
    ros_cardiovascularRecommendationsheartHealthyDietComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,
    ros_cardioSubDiagnosesComment,
    ros_cardioSubDiagnosesAneurysmDesComment,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDesComment,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub,
    ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmTypeComment,
    ros_cardioSubDiagnosesAtrialFlutterDesComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatComment,
    ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub,
    ros_cardioSubDiagnosesvalvulardiseaseCrDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers,
    ros_cardioSubDiagnosesvalvulardiseaseTreatmentComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub,
    ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientComment,
    ros_cardioSubDiagnosesAneurysmTreatmentComment,
    ros_cardioSubDiagnosesAnerysmSupotherSubAnswer,
    ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    cardio_RecommendationsComment,
    ros_cardioSubDiagnosesAneurysmSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiompTreatOtherTrSub,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatComment,
    ros_cardioSubDiagnosesCardioresTreatOtherTrSub,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer,
    ros_cardioSubDiagnosesAnginaSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesOtherSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,
    ros_cardioSubDiagnosesCardiomyopathyDesComment,
    ros_cardioSubDiagnosesCardiomyopathySupComment,
    ros_cardioSubDiagnosesCardiomyopathyTypeComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHyperlipidemiaDesComment,
    ros_cardioSubDiagnosesHyperlipidemiaSupComment,
    ros_cardioSubDiagnosesHypertensionSupComment,
    ros_cardioSubDiagnosesHypertensionDesComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlledComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment,
    ros_cardioSubDiagnosesMyocardialBetaBlocker,
    ros_cardioSubDiagnosesMyocardialBetaBlockerComment,
    ros_cardioSubDiagnosesMyocardialIspateintComment,
    ros_cardioSubDiagnosesMyocardialSupotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialSupComment,
    ros_cardioSubDiagnosesMyocardialDesComment,
    ros_cardioSubDiagnosesMyocardialDate,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacement,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment,
    ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment,
    ros_cardioSubDiagnosesvalvulardiseaseSupComment,
    ros_cardioSubDiagnosesvalvulardiseaseDesComment,
    ros_cardioSubDiagnosesOtherOtherSub,
    ros_cardioSubDiagnosesOtherSupComment,
    ros_cardioSubDiagnosesOtherDesComment,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertension,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockDesComment,
    ros_cardioSubDiagnosesHeartTransplant,
    ros_cardioSubDiagnosesSympComment,
    ros_cardioSubDiagnosesSympOtherSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLocComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAntiComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOneComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatComment,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreatOtherSub,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper,
    ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyperComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCardComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeriComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreComment,
    ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreComment,
    ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre,
    ros_cardioSubDiagnosesHypertensionDesActiveSubTreComment,
    ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesHypertensionSympotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSympComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSympComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDesComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwoComment,

    ros_diagnosisOfCancerRecommendations: ros_diagnosisOfCancerRecommendations,
    ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment,
    ros_diagnosisOfCancerRecommendationsCasemanagementfollowupreferralCancerComment,
    ros_diagnosisOfCancerRecommendationsfollowupwithclinispecCancerComment,
    ros_diagnosisOfCancerRecommendationsmembertousedurablemedicalequipCancerComment,
    ros_diagnosisOfCancerRecommendationsotherCancerComment,
    ros_diagnosisOfCancerSubSpecTyVal: ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerSubStageSpecCanVal:
      ros_diagnosisOfCancerstageSpecificToTheCancer,
    // ros_diagnosisOfCancerros_activeTreatment,
    // ros_diagnosisOfCancerros_Metastasis,
    // ros_diagnosisOfCancerros_SeeASpec,
    // ros_diagnosisOfCancerSubSeeASpecProVal,
    // ros_diagnosisOfCancerSubMetastasisLocVal,
    // ros_diagnosisOfCancerSubMetastasisCachexiaVal,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_diagnosisOfCancerros_activeTreatmentComment,
    ros_diagnosisOfCancerros_SeeASpecComment,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,
    ros_diagnosisOfCancerros_MetastasisComment,
    ros_diagnosisOfCancerSubTypeOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer,
    ros_diagnosisOfCancerSubSupOtherValSubAnswer,
    ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerVal,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_endoVal,
    ros_gastro,
    ros_genitoVal,
    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_diagnosisOfCancerSubActiveTreatVal:
      ros_diagnosisOfCancerSubActiveTreatVal,
    ros_diagnosisOfCancerSubMetastasisVal:
      ros_diagnosisOfCancerros_MetastasisVal,
    ros_diagnosisOfCancerSubSeeASpecVal: ros_diagnosisOfCancerros_SeeASpecVal,
    // ros_diagnosisOfCancerspecialTypes: ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerSubMetastasisLocVal:
      ros_diagnosisOfCancerros_MetastasisLocVal,
    // ros_diagnosisOfCancerSubStageSpecCanVal:
    //   ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerCommentVal: ros_diagnosisOfCancerCommentVal,
    ros_diagnosisOfCancertitl5Years,
    ros_diagnosisOfCancertitl5YearsComment,
    ...ros_diagnosisOfCancerRecommendations,
    ...ros_gastrorecommendations,
    ...ros_cardiovascularRecommendations,
    ...ros_genitoRecommendations,
    ...ros_endocrineIsEndocrineRecommendations,
    ros_diagnosisOfCancerSubSeeASpecProVal: ros_diagnosisOfCancerros_SpecProVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal:
      ros_diagnosisOfCancerros_MetastasisCachexiaVal,
    ros_endoValComment,
    ros_gastroComment,
    ros_genitoValComment,
    ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment,
    ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment,
    ros_endocrineIsEndocrineRecommendationsotherEndoComment,
    ros_endocrineIsEndocrineRecommendationsalbuminRatioEndoComment,
    ros_endocrineIsEndocrineRecommendationsdurablemedicalequipEndoComment,
    ros_endocrineIsEndocrineRecommendationscasemangfollowEndoComment,
    ros_endocrineIsEndocrineRecommendationsfollowupwithclinispecEndoComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectComment,
    ros_gastrorecommendationsotherComment,
    ros_gastrorecommendationstakemedicationsasprescribedComment,
    ros_gastroSubDiagnosesComment: ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosescachexiaSupComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,
    ros_gastrorecommendationscaseManagementComment,
    ros_gastrorecommendationsfollowupComment,
    ros_gastrorecommendationsuseDmeComment,
    ros_genitoSubDiagnosesComment,
    ros_endocrineIsEndocrineDiagnoseComment,
    ros_genitoSubDiagnosesacuteRenalFailureEti,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosesdescolonComment,
    ros_gastroSubDiagnosescolonSupComment,
    ros_gastroSubSymptomsOtherSub,
    ros_gastroSubSymptomsComment,
    ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal,
    ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal,
    ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal,
    ros_gastroSubDiagnosescolonOtherSubAnswerVal,
    ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgerdSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal,
    ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesotherSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment,
    ros_gastroSubDiagnosesdesdiverticulitisComment,
    ros_gastroSubDiagnosesdiverticulitisabscessVal,
    ros_gastroSubDiagnosesdiverticulitisabscessValComment,
    ros_gastroSubDiagnosesdiverticulitisperforationVal,
    ros_gastroSubDiagnosesdiverticulitisperforationValComment,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietVal,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment,
    ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDesComment,
    ros_gastroSubDiagnosesdiverticulitisSupComment,
    ros_gastroSubDiagnosesgallBladderDiseaseSupComment,
    ros_gastroSubDiagnosesdesgallBladderDiseaseComment,
    ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByComment,
    ros_gastroSubDiagnosesgastroparesisDescribeComment,
    ros_gastroSubDiagnosesgerdDescribeComment,
    ros_gastroSubDiagnosesgerdSupportedByComment,
    ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedDescribeComment,
    ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDescribeComment,
    ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnoseshepatitisSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherDescribeComment,
    ros_gastroSubDiagnosespancreatitisDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDessComment,
    ros_gastroSubDiagnoseshepatitisTypeComment,
    ros_gastroSubDiagnoseshepatitisCirrhosis,
    ros_gastroCiroalcoholRelated,
    ros_gastroCiroalcoholRelatedComment,
    ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice,
    ros_gastroSubDiagnosesdeshepatitisActiveSubJaundiceComment,
    ros_gastroSubDiagnoseshepatitisCirrhosisComment,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment,
    ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment,
    ros_gastroSubDiagnosespancreatitisSpecificDiet,
    ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol,
    ros_gastroSubDiagnosesdespancreatitisActiveSubAlcoholComment,
    ros_gastroSubDiagnosesdespancreatitisActiveSubTreComment,
    ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosespancreatitisSpecificDietComment,
    ros_gastroSubDiagnosesulcerDiseaseComment,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactria,
    ros_gastroSubDiagnosesotherOther,
    ros_gastroDateOfTransplant,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreComment,
    ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgerdActiveSubTreComment,
    ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesgastroparesisActiveSubTreComment,
    ros_gastroSubDiagnosesdesgallBladderActiveSubTreComment,
    ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdescolonPolypsActiveSubTreComment,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreComment,
    ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns,
    ros_gastroSubDiagnosesdesglBleedActiveSubTreComment,
    ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesglBleedActiveSubBloodComment,
    ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdeshepatitisActiveSubTreComment,
    ros_gastroSubDiagnosesdesglBleedActiveSubBlood,
    ros_gastroSubDiagnosesdescirrhosisActiveSubSelectComment,
    ros_gastroSubDiagnosesdescirrhosisActiveSubTreComment,
    ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelectComment,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia,
    ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemiaComment,
    ros_gastroSubDiagnosesdescachexiaActiveSubTreComment,
    ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub,
    ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreComment,
    ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub,
    ros_genito,
    ros_genitoComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal,
    ros_genitoSubDiagnosesacuteRenalFailureEtiology,
    ros_genitoSubDiagnosesbphDescribeComment,
    ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesbhpActiveSubTreComment,
    ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdeschronicActiveSubTreComment,
    ros_genitoSubDiagnosesdeschronicActiveSubSec,
    ros_genitoSubDiagnosesdeschronicActiveSubSecComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment,
    ros_genitoSubDiagnoseswhatStageComment,
    ros_genitoSubDiagnosesesrdDescribeComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet,
    ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment,
    ros_genitoSubDiagnosesdesesrdActiveSubTreComment,
    ros_genitoSubDiagnoActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesUrinaryActiveSubTreComment,
    ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesNephrosisActiveSubTreComment,
    ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesgynecoActiveSubTreComment,
    ros_genitoSubDiagnosesdeskidneyActiveSubTreComment,
    ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdesfreqsActiveSubTreComment,
    ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub,
    ros_genitoSubDiagnosesdeserectileActiveSubTreComment,
    ros_genitoSubDiagnosesdesErectileTreOtherSub,
    ros_genitoSubDiagnosesdesOtherActiveSubTreComment,
    ros_genitoSubDiagnosesdesacuteActiveSubTreComment,
    ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub,
    ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment,
    ros_genitoSubDiagnosesesrddialysisSchedule,
    ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice,
    ros_genitoSubDiagnoseserectileDysfunctionDescribeComment,
    ros_genitoSubDiagnosesfrequentUtiDescribeComment,
    ros_genitoSubDiagnosesgynecologicalDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesTypeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment,
    ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment,
    ros_genitoSubDiagnosesotherDescribeComment,
    ros_genitoSubDiagnosesotherOther,
    ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment,
    ros_genitoSubSymptomsComment,
    ros_genitoSubSymptomsOtherSub,
    ros_genitoStress,
    ros_genitoStressComment,
    ros_genitoStressSubComment,
    ros_endocrineIsEndocrine,
    ros_endocrineIsEndocrineComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe,
    ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment,
    ros_genitoRecommendationsotherGenitoComment,
    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_genitoRecommendationscasemanagementrefGenitoComment,
    ros_genitoRecommendationsmemberDurableGenitoComment,
    ros_genitoRecommendationsfollowupwithclinispecGenitoComment,

    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubchronicKidneySubStageComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment,
    ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubSubComment,
    ros_endocrineIsEndocrineSubneuroSubdescribeSubComment,
    ros_endocrineIsEndocrineSubNeuroSubSympComment,
    ros_endocrineIsEndocrineSubNeuroSubTreatComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment,
    ros_endocrineIsEndocrineNeuroSubPatientOftenComment,
    ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEtiComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns,
    ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment,
    ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnoseotherDescribeComment,
    ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseotherSupComment,
    ros_endocrineIsEndocrineDiagnoseotherOther,
    ros_endocrineIsEndocrineNoEdu,
    ros_endocrineIsEndocrineUnknownEdu,
    ...ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub,
    ...ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLocComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLocComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTypeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfrComment,
    ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubComplComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMetComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub,
    ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTestComment,
    ros_endocrineIsEndocrineSubVascularSubPhysicalComment,
    ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubVascularSubTreatComment,
    ros_endocrineIsEndocrineSubAutonomicSubTreatComment,
    ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubautonomicSubdescribeSubComment,
    ros_endocrineIsEndocrineSubchronicKidneySubTreatComment,
    ros_endocrineIsEndocrineSubPeripheralVasSubPatientOftenComment,
    ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphthaComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribeComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSympComment,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub,
    ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreComment,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c,
    ros_endocrineIsEndocrineSubdiabetesSubandDate,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest,
    ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr,
  };

  let payloadData = {};

  ros_hashmap2024?.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  const data18 = setMashedData_ros_3(formData);
  payloadData = { ...payloadData, ...data18 };

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};

const getOptionsSelected = (optionObject, optionArray) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return ros_optionsTreeMapObject2024[variable];
  });

  return selectedOption;
};

const setOptionsSelected = (optionsSelected, optionArray) => {
  let temp = {};

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(ros_optionsTreeMapObject2024[option])
        ? "1"
        : "";
  });
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["unknown"];
  } else {
    selectedValue = [""];
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let checkValue = optionValue?.[0] || "";

  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["partially"];
  } else {
    selectedValue = [""];
  }
  return selectedValue;
};

const setOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else if (optionValue === "partially") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else if (optionValue === "3") {
    selectedValue = "unknown";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else if (optionValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoUnknownOther = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["unknown"];
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNoUnknownOther = (optionValue) => {
  let selectedValue;
  if (optionValue?.includes("yes")) {
    selectedValue = "1";
  } else if (optionValue?.includes("no")) {
    selectedValue = "2";
  } else if (optionValue?.includes("unknown")) {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getSubTree2024 = (optionName, optionValue) => {
  let temp;
  let ros_optionsTreeMapObject2024 = getOptionsMapper();
  let ros_OptionsTreeWrappers2024 = [...ros_optionsTreeWrappers];
  const tempIndex = lodash.findIndex(
    ros_OptionsTreeWrappers2024,
    function (optTree) {
      return optTree[0] === optionName;
    },
  );

  const tempTreeNodes = ros_OptionsTreeWrappers2024?.[tempIndex]
    ? ros_OptionsTreeWrappers2024[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (ros_optionsTreeMapObject2024[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? `${temp}` : "";

  return temp;
};

export const deleteSubTree2024 = (formData, subTreeRegex, treeType = 1) => {
  /*
  1 - normal options tree
  2 - yes/no options tree
  */
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      if (treeType === 2) {
        let escapeRegex = `${subTreeRegex}Comment`;
        if (!node.includes(escapeRegex)) {
          delete formData[node];
        }
      } else {
        delete formData[node];
      }
    }
  });
  return formData;
};

export const getSelectedCheckboxesOthers = (optionKeyArr, optionValueArr) => {
  let temp = "";

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      temp = optionValueArr[index] || "";
    }
  });

  return temp;
};

export const setSelectedCheckboxesOthers = (
  optionArr,
  optionKeyArr,
  optionValueArr,
) => {
  let temp = {};

  optionValueArr.forEach((ele, index) => {
    temp[optionKeyArr[index]] = optionArr?.includes(ele) ? "1" : "";
  });

  return temp;
};
