let resetSuccess = false;
let changeSuccess = false;

function setResetSuccess(success) {
  resetSuccess = success;
}

function getResetSuccess() {
  return resetSuccess;
}

function setChangeSuccess(success) {
  changeSuccess = success;
}

function getChangeSuccess() {
  return changeSuccess;
}


export { setResetSuccess, getResetSuccess, setChangeSuccess, getChangeSuccess };
