import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { PublicRoutes } from "./unprotectedRoutes";
import { useUserContext } from "../contexts/UserContext";
import { RedirectToLogin } from "./redirectPageRouting";
import { getApi } from "../utils/axiosApiWrapper";
import { getProtectedRoutes } from "./protectedRoutes";
import Login from "../pages/Login/login";

const CollabDashboard = React.lazy(() =>
  import("../pages/CollabPhysicianDashboard/index"),
);
const AdminDashboard = React.lazy(() =>
  import("../pages/AdminDashboard/AdminDashboard"),
);
const ClientPortal = React.lazy(() =>
  import("../pages/ClientPortal/ClientPortal"),
);
const SchedulerDashboard = React.lazy(() =>
  import("../pages/SchedulerDashboard/index"),
);
const NurseDashboard = React.lazy(() =>
  import("../pages/NurseDashboard/index"),
);
const QADashboard = React.lazy(() => import("../pages/QaDashboard/index"));
const CoderDashboard = React.lazy(() =>
  import("../pages/CoderDashboard/index"),
);
// to-do : use react-router title setting here
// const setPageTitle = (pageTitle) => {
//   document.title = pageTitle;
// };

export default function AppRoutes() {
  const { hasLoggedIn, setHasLoggedIn } = useUserContext();
  const roleId = sessionStorage.getItem("roleId"); // Fetch roleId here
  const ProtectedRoutes = getProtectedRoutes(roleId);

  useEffect(() => {
    async function checkUserStatus() {
      const jwtToken = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
      if (jwtToken) {
        const response = await getApi("/users/getMe");
        if (response.status === 200) {
          setHasLoggedIn(true);
          return null;
  
        }
      }
      setHasLoggedIn(false);
    }

    checkUserStatus();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {PublicRoutes.map((e) => {
        return <Route key={e.path} exact path={e.path} element={e.component} />;
      })}

      {(roleId == 2 || roleId == 1) && (
        <Route
          path="/AdminDashboard/*"
          element={
            <AdminDashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </AdminDashboard>
          }
        ></Route>
      )}
      {(roleId == 3 || roleId == 4) && (
        <Route
          path="/SchedulerDashboard/*"
          element={
            <SchedulerDashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </SchedulerDashboard>
          }
        ></Route>
      )}
      {roleId == 5 && (
        <Route
          path="/NurseDashboard/*"
          element={
            <NurseDashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </NurseDashboard>
          }
        ></Route>
      )}
      {(roleId == 6 || roleId == 7) && (
        <Route
          path="/QADashboard/*"
          element={
            <QADashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </QADashboard>
          }
        ></Route>
      )}
      {(roleId == 8 || roleId == 9) && (
        <Route
          path="/CoderDashboard/*"
          element={
            <CoderDashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </CoderDashboard>
          }
        ></Route>
      )}
      {roleId == 10 && (
        <Route
          path="/ClientPortal/*"
          element={
            <ClientPortal>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </ClientPortal>
          }
        ></Route>
      )}
      {roleId == 12 && (
        <Route
          path="/PhysicianDashboard/*"
          element={
            <CollabDashboard>
              <Routes>
                {ProtectedRoutes.map((e) => {
                  return (
                    <Route
                      key={e.path}
                      path={e.path}
                      element={
                        <RedirectToLogin moveToLogin={hasLoggedIn}>
                          {e.component}
                        </RedirectToLogin>
                      }
                    />
                  );
                })}
              </Routes>
            </CollabDashboard>
          }
        ></Route>
      )}
    </Routes>
  );
}
