import React from "react";
import focusCaresLoader from "../../loader/focuscares_loader.gif";

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10000,
    }}
  >
    <img src={focusCaresLoader} alt="Loader" />
  </div>
);

export default Loader;
