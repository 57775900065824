import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

import {
  ros_hashmap,
  ros_OptionsTreeWrappers,
  ros_optionsTreeMapObject,
} from "./reviewOfSystemData";

export const getRosData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  ros_hashmap?.forEach((questionPair, index) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  ros_OptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const ros_eyes = getOptionsSelectedYesNo(tempDbData["ros_eyesVal"]);
  const ros_eyesComment = tempDbData["ros_eyesCommentVal"];
  const ros_eyesDiagComment = tempDbData["ros_eyesDiagCommentVal"];
  const ros_eyesDiagCataractSubWhEyeComment =
    tempDbData["ros_eyesDiagCataractSubWhEyeCommentVal"];
  const ros_eyesDiagCataractSubDescComment =
    tempDbData["ros_eyesDiagCataractSubDescCommentVal"];
  const ros_eyesDiagCataractSubSuppComment =
    tempDbData["ros_eyesDiagCataractSubSuppCommentVal"];
  const ros_eyesDiagCataractSubSuppSympSubComment =
    tempDbData["ros_eyesDiagCataractSubSuppSympSubCommentVal"];
  const ros_eyesDiagCataractSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagCataractSubSuppOtherSubDescVal"];
  const ros_eyesDiagCataractSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagCataractSubDiabetesVal"],
  );
  const ros_eyesDiagCataractSubDiabetesComment =
    tempDbData["ros_eyesDiagCataractSubDiabetesCommentVal"];

  const ros_eyesDiagGlaucomaSubWhEyeComment =
    tempDbData["ros_eyesDiagGlaucomaSubWhEyeCommentVal"];
  const ros_eyesDiagGlaucomaSubDescComment =
    tempDbData["ros_eyesDiagGlaucomaSubDescCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppComment =
    tempDbData["ros_eyesDiagGlaucomaSubSuppCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagGlaucomaSubSuppSympSubCommentVal"];
  const ros_eyesDiagGlaucomaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagGlaucomaSubSuppOtherSubDescVal"];
  const ros_eyesDiagGlaucomaSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagGlaucomaSubDiabetesVal"],
  );
  const ros_eyesDiagGlaucomaSubDiabetesComment =
    tempDbData["ros_eyesDiagGlaucomaSubDiabetesCommentVal"];
  const ros_eyesDiagGlaucomaSubDiabetesSubComment =
    tempDbData["ros_eyesDiagGlaucomaSubDiabetesSubCommentVal"];

  const ros_eyesDiagHyperopiaSubWhEyeComment =
    tempDbData["ros_eyesDiagHyperopiaSubWhEyeCommentVal"];
  const ros_eyesDiagHyperopiaSubDescComment =
    tempDbData["ros_eyesDiagHyperopiaSubDescCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppComment =
    tempDbData["ros_eyesDiagHyperopiaSubSuppCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagHyperopiaSubSuppSympSubCommentVal"];
  const ros_eyesDiagHyperopiaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagHyperopiaSubSuppOtherSubDescVal"];

  const ros_eyesDiagLegalBlindSubWhEyeComment =
    tempDbData["ros_eyesDiagLegalBlindSubWhEyeCommentVal"];
  const ros_eyesDiagLegalBlindSubDescComment =
    tempDbData["ros_eyesDiagLegalBlindSubDescCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppComment =
    tempDbData["ros_eyesDiagLegalBlindSubSuppCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppSympSubComment =
    tempDbData["ros_eyesDiagLegalBlindSubSuppSympSubCommentVal"];
  const ros_eyesDiagLegalBlindSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagLegalBlindSubSuppOtherSubDescVal"];
  const ros_eyesDiagLegalBlindSubComment =
    tempDbData["ros_eyesDiagLegalBlindSubCommentVal"];
  const ros_eyesDiagLegalBlindSubDescText =
    tempDbData["ros_eyesDiagLegalBlindSubDescTextVal"];

  const ros_eyesDiagMascDegenSubWhEyeComment =
    tempDbData["ros_eyesDiagMascDegenSubWhEyeCommentVal"];
  const ros_eyesDiagMascDegenSubDescComment =
    tempDbData["ros_eyesDiagMascDegenSubDescCommentVal"];
  const ros_eyesDiagMascDegenSubSuppComment =
    tempDbData["ros_eyesDiagMascDegenSubSuppCommentVal"];
  const ros_eyesDiagMascDegenSubSuppSympSubComment =
    tempDbData["ros_eyesDiagMascDegenSubSuppSympSubCommentVal"];
  const ros_eyesDiagMascDegenSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagMascDegenSubSuppOtherSubDescVal"];
  const ros_eyesDiagMascDegenSubComment =
    tempDbData["ros_eyesDiagMascDegenSubCommentVal"];

  const ros_eyesDiagMascDegenSubDesc2Comment =
    tempDbData["ros_eyesDiagMascDegenSubDesc2CommentVal"];
  const ros_eyesDiagMyopiaSubWhEyeComment =
    tempDbData["ros_eyesDiagMyopiaSubWhEyeCommentVal"];
  const ros_eyesDiagMyopiaSubDescComment =
    tempDbData["ros_eyesDiagMyopiaSubDescCommentVal"];
  const ros_eyesDiagMyopiaSubSuppComment =
    tempDbData["ros_eyesDiagMyopiaSubSuppCommentVal"];
  const ros_eyesDiagMyopiaSubSuppSympSubComment =
    tempDbData["ros_eyesDiagMyopiaSubSuppSympSubCommentVal"];
  const ros_eyesDiagMyopiaSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagMyopiaSubSuppOtherSubDescVal"];

  const ros_eyesDiagRetinalSubWhEyeComment =
    tempDbData["ros_eyesDiagRetinalSubWhEyeCommentVal"];
  const ros_eyesDiagRetinalSubDescComment =
    tempDbData["ros_eyesDiagRetinalSubDescCommentVal"];
  const ros_eyesDiagRetinalSubSuppComment =
    tempDbData["ros_eyesDiagRetinalSubSuppCommentVal"];
  const ros_eyesDiagRetinalSubSuppSympSubComment =
    tempDbData["ros_eyesDiagRetinalSubSuppSympSubCommentVal"];
  const ros_eyesDiagRetinalSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagRetinalSubSuppOtherSubDescVal"];
  const ros_eyesDiagRetinalSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagRetinalSubDiabetesVal"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemm = getOptionsSelectedYesNo(
    tempDbData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemmComment =
    tempDbData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemmCommentVal"];
  const ros_eyesDiagRetinalSubDiabetesComment =
    tempDbData["ros_eyesDiagRetinalSubDiabetesCommentVal"];

  const ros_eyesDiagOtherSubWhEyeComment =
    tempDbData["ros_eyesDiagOtherSubWhEyeCommentVal"];
  const ros_eyesDiagOtherSubDescComment =
    tempDbData["ros_eyesDiagOtherSubDescCommentVal"];
  const ros_eyesDiagOtherSubSuppComment =
    tempDbData["ros_eyesDiagOtherSubSuppCommentVal"];
  const ros_eyesDiagOtherSubSuppSympSubComment =
    tempDbData["ros_eyesDiagOtherSubSuppSympSubCommentVal"];
  const ros_eyesDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_eyesDiagOtherSubSuppOtherSubDescVal"];
  const ros_eyesDiagOtherSubOther = tempDbData["ros_eyesDiagOtherSubOtherVal"];

  const ros_ears = getOptionsSelectedYesNo(tempDbData["ros_earsVal"]);
  const ros_earsComment = tempDbData["ros_earsCommentVal"];
  const ros_earsDiagnosesComment = tempDbData["ros_earsDiagnosesCommentVal"];
  const ros_earsDiagDiffHearSubDescComment =
    tempDbData["ros_earsDiagDiffHearSubDescCommentVal"];

  const ros_earsDiagDiffHearSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagDiffHearSubSuppOtherSubDescVal"];
  const ros_earsDiagDiffHearSubSuppComment =
    tempDbData["ros_earsDiagDiffHearSubSuppCommentVal"];

  const ros_earsDiagLegalDeafSubDescComment =
    tempDbData["ros_earsDiagLegalDeafSubDescCommentVal"];
  const ros_earsDiagLegalDeafSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagLegalDeafSubSuppOtherSubDescVal"];
  const ros_earsDiagLegalDeafSubSuppComment =
    tempDbData["ros_earsDiagLegalDeafSubSuppCommentVal"];

  const ros_earsDiagTinnitusSubDescComment =
    tempDbData["ros_earsDiagTinnitusSubDescCommentVal"];
  const ros_earsDiagTinnitusSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagTinnitusSubSuppOtherSubDescVal"];
  const ros_earsDiagTinnitusSubSuppComment =
    tempDbData["ros_earsDiagTinnitusSubSuppCommentVal"];

  const ros_earsDiagVertigoSubDescComment =
    tempDbData["ros_earsDiagVertigoSubDescCommentVal"];
  const ros_earsDiagVertigoSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagVertigoSubSuppOtherSubDescVal"];
  const ros_earsDiagVertigoSubSuppComment =
    tempDbData["ros_earsDiagVertigoSubSuppCommentVal"];
  const ros_earsDiagVertigoSubLoseBal = getOptionsSelectedYesNo(
    tempDbData["ros_earsDiagVertigoSubLoseBalVal"],
  );
  const ros_earsDiagVertigoSubLoseBalComment =
    tempDbData["ros_earsDiagVertigoSubLoseBalCommentVal"];

  const ros_earsDiagOtherSubDescComment =
    tempDbData["ros_earsDiagOtherSubDescCommentVal"];
  const ros_earsDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_earsDiagOtherSubSuppOtherSubDescVal"];
  const ros_earsDiagOtherSubSuppComment =
    tempDbData["ros_earsDiagOtherSubSuppCommentVal"];

  const ros_earsDiagOtherSubOther = tempDbData["ros_earsDiagOtherSubOtherVal"];

  const ros_nose = getOptionsSelectedYesNo(tempDbData["ros_noseVal"]);

  const ros_noseDiagAllergySubDescComment =
    tempDbData["ros_noseDiagAllergySubDescCommentVal"];
  const ros_noseDiagChronicNasalSubDescComment =
    tempDbData["ros_noseDiagChronicNasalSubDescCommentVal"];
  const ros_noseDiagSeasonAllergySubDescComment =
    tempDbData["ros_noseDiagSeasonAllergySubDescCommentVal"];
  const ros_noseDiagSinusInfectSubDescComment =
    tempDbData["ros_noseDiagSinusInfectSubDescCommentVal"];
  const ros_noseDiagSinustisSubDescComment =
    tempDbData["ros_noseDiagSinustisSubDescCommentVal"];
  const ros_noseDiagOtherSubDescComment =
    tempDbData["ros_noseDiagOtherSubDescCommentVal"];

  const ros_noseDiagAllergySubSuppOtherSubDesc =
    tempDbData["ros_noseDiagAllergySubSuppOtherSubDescVal"];
  const ros_noseDiagAllergySubSuppComment =
    tempDbData["ros_noseDiagAllergySubSuppCommentVal"];
  const ros_noseDiagChronicNasalSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagChronicNasalSubSuppOtherSubDescVal"];
  const ros_noseDiagChronicNasalSubSuppComment =
    tempDbData["ros_noseDiagChronicNasalSubSuppCommentVal"];
  const ros_noseDiagNoseBleedSubComment =
    tempDbData["ros_noseDiagNoseBleedSubCommentVal"];
  const ros_noseDiagSeasonAllergySubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSeasonAllergySubSuppOtherSubDescVal"];
  const ros_noseDiagSeasonAllergySubSuppComment =
    tempDbData["ros_noseDiagSeasonAllergySubSuppCommentVal"];
  const ros_noseDiagSinusInfectSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSinusInfectSubSuppOtherSubDescVal"];
  const ros_noseDiagSinusInfectSubSuppComment =
    tempDbData["ros_noseDiagSinusInfectSubSuppCommentVal"];
  const ros_noseDiagSinusInfectSubExuComment =
    tempDbData["ros_noseDiagSinusInfectSubExuCommentVal"];

  const ros_noseDiagSinustisSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagSinustisSubSuppOtherSubDescVal"];
  const ros_noseDiagSinustisSubSuppComment =
    tempDbData["ros_noseDiagSinustisSubSuppCommentVal"];

  const ros_noseDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_noseDiagOtherSubSuppOtherSubDescVal"];
  const ros_noseDiagOtherSubSuppComment =
    tempDbData["ros_noseDiagOtherSubSuppCommentVal"];
  const ros_noseDiagOtherSubOther = tempDbData["ros_noseDiagOtherSubOtherVal"];

  const ros_noseDiagnosesComment = tempDbData["ros_noseDiagnosesCommentVal"];
  const ros_noseComment = tempDbData["ros_noseCommentVal"];

  const ros_mouth = getOptionsSelectedYesNo(tempDbData["ros_mouthVal"]);
  const ros_mouthDiagComment = tempDbData["ros_mouthDiagCommentVal"];
  const ros_mouthComment = tempDbData["ros_mouthCommentVal"];
  const ros_mouthDiagBleedGumsSubComment =
    tempDbData["ros_mouthDiagBleedGumsSubCommentVal"];
  const ros_mouthDiagDiffChewSubDescComment =
    tempDbData["ros_mouthDiagDiffChewSubDescCommentVal"];
  const ros_mouthDiagDiffChewSubBcsPain = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffChewSubBcsPainVal"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSub = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffChewSubBcsPainSubVal"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubComment =
    tempDbData["ros_mouthDiagDiffChewSubBcsPainSubCommentVal"];
  const ros_mouthDiagDiffChewSubBcsPainComment =
    tempDbData["ros_mouthDiagDiffChewSubBcsPainCommentVal"];

  const ros_mouthDiagDiffSwallowSubDescComment =
    tempDbData["ros_mouthDiagDiffSwallowSubDescCommentVal"];
  const ros_mouthDiagDiffSwallowSubHadStroke = getOptionsSelectedYesNo(
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeVal"],
  );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub =
    getOptionsSelectedYesNo(
      tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal"],
    );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubComment =
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubCommentVal"];
  const ros_mouthDiagDiffSwallowSubHadStrokeComment =
    tempDbData["ros_mouthDiagDiffSwallowSubHadStrokeCommentVal"];

  const ros_mouthDiagOtherSubDescComment =
    tempDbData["ros_mouthDiagOtherSubDescCommentVal"];
  const ros_mouthDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_mouthDiagOtherSubSuppOtherSubDescVal"];
  const ros_mouthDiagOtherSubSuppComment =
    tempDbData["ros_mouthDiagOtherSubSuppCommentVal"];
  const ros_mouthDiagOtherSubOther =
    tempDbData["ros_mouthDiagOtherSubOtherVal"];

  const ros_neck = getOptionsSelectedYesNo(tempDbData["ros_neckVal"]);
  const ros_neckDiagComment = tempDbData["ros_neckDiagCommentVal"];
  const ros_neckComment = tempDbData["ros_neckCommentVal"];
  const ros_neckDiagCarotidSubDescComment =
    tempDbData["ros_neckDiagCarotidSubDescCommentVal"];
  const ros_neckDiagCarotidSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagCarotidSubSuppOtherSubDescVal"];
  const ros_neckDiagCarotidSubSuppComment =
    tempDbData["ros_neckDiagCarotidSubSuppCommentVal"];
  const ros_neckDiagCarotidSubCsDescComment =
    tempDbData["ros_neckDiagCarotidSubCsDescCommentVal"];

  const ros_neckDiagParotidSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagParotidSubSuppOtherSubDescVal"];
  const ros_neckDiagParotidSubSuppComment =
    tempDbData["ros_neckDiagParotidSubSuppCommentVal"];
  const ros_neckDiagParotidSubDescComment =
    tempDbData["ros_neckDiagParotidSubDescCommentVal"];

  const ros_neckDiagOtherSubDescComment =
    tempDbData["ros_neckDiagOtherSubDescCommentVal"];
  const ros_neckDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_neckDiagOtherSubSuppOtherSubDescVal"];
  const ros_neckDiagOtherSubSuppComment =
    tempDbData["ros_neckDiagOtherSubSuppCommentVal"];
  const ros_neckDiagOtherSubOther = tempDbData["ros_neckDiagOtherSubOtherVal"];

  const ros_eyeENTNeckRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_eyeENTNeckRecommendationshearingEvaluationVal"],
      tempDbData["ros_eyeENTNeckRecommendationsdentalExamVal"],
      tempDbData["ros_eyeENTNeckRecommendationseyeExamVal"],
      tempDbData["ros_eyeENTNeckRecommendationsswallowingEvaluationVal"],
      tempDbData["ros_eyeENTNeckRecommendationstakeMedicationsVal"],
      tempDbData["ros_eyeENTNeckRecommendationsotherVal"],
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "other",
    ],
  );

  const ros_eyeENTNeckRecommendationshearingEvaluationComment =
    tempDbData["ros_eyeENTNeckRecommendationshearingEvaluationCommentVal"];
  const ros_eyeENTNeckRecommendationsdentalExamComment =
    tempDbData["ros_eyeENTNeckRecommendationsdentalExamCommentVal"];
  const ros_eyeENTNeckRecommendationseyeExamComment =
    tempDbData["ros_eyeENTNeckRecommendationseyeExamCommentVal"];
  const ros_eyeENTNeckRecommendationsswallowingEvaluationComment =
    tempDbData["ros_eyeENTNeckRecommendationsswallowingEvaluationCommentVal"];
  const ros_eyeENTNeckRecommendationstakeMedicationsComment =
    tempDbData["ros_eyeENTNeckRecommendationstakeMedicationsCommentVal"];
  const ros_eyeENTNeckRecommendationsotherComment =
    tempDbData["ros_eyeENTNeckRecommendationsotherCommentVal"];

  const ros_bowelMoveAbnormSubDiarrSubIshis = getOptionsSelectedYesNo(
    tempDbData["ros_bowelMoveAbnormSubDiarrSubIshisVal"],
  );
  const ros_bowelMoveAbnormSubDiarrSubIshisComment =
    tempDbData["ros_bowelMoveAbnormSubDiarrSubIshisCommentVal"];

  const ros_bowelMoveComment = tempDbData["ros_bowelMoveCommentVal"];
  const ros_bowelMoveAbnormSubComment =
    tempDbData["ros_bowelMoveAbnormSubCommentVal"];
  const ros_bowelMoveAbnormSubConstSubIfConstComment =
    tempDbData["ros_bowelMoveAbnormSubConstSubIfConstCommentVal"];
  const ros_bowelMoveAbnormSubDiarrSubComment =
    tempDbData["ros_bowelMoveAbnormSubDiarrSubCommentVal"];

  const ros_abdominal = getOptionsSelectedYesNo(tempDbData["ros_abdominalVal"]);
  const ros_abdominalIsyesComment = tempDbData["ros_abdominalIsyesCommentVal"];
  const ros_abdominalComment = tempDbData["ros_abdominalCommentVal"];

  const ros_rectal = getOptionsSelectedYesNo(tempDbData["ros_rectalVal"]);
  const ros_rectalIsrecIsyesmalComment =
    tempDbData["ros_rectalIsrecIsyesmalCommentVal"];
  const ros_rectalIsrecIsyesfemComment =
    tempDbData["ros_rectalIsrecIsyesfemCommentVal"];
  const ros_rectalComment = tempDbData["ros_rectalCommentVal"];

  const ros_lastBowelComment = tempDbData["ros_lastBowelCommentVal"];

  const ros_meetWithComment = tempDbData["ros_meetWithCommentVal"];

  const ros_gpcogScoIsgpcogs = tempDbData["ros_gpcogScoIsgpcogsVal"];
  const ros_gpcogScoIsmmsesc = tempDbData["ros_gpcogScoIsmmsescVal"];
  const ros_gpcogScoComment = tempDbData["ros_gpcogScoCommentVal"];

  const ros_noGpcogMmsePatientOrientPerson = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientPersonVal"],
  );
  const ros_noGpcogMmsePatientOrientPlace = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientPlaceVal"],
  );
  const ros_noGpcogMmsePatientOrientTime = getOptionsSelectedYesNo(
    tempDbData["ros_noGpcogMmsePatientOrientTimeVal"],
  );
  const ros_noGpcogMmsePatientDescribeNews = getOptionsSelectedYesNoPartially(
    tempDbData["ros_noGpcogMmsePatientDescribeNewsVal"],
  );
  const ros_noGpcogMmseRecallComment =
    tempDbData["ros_noGpcogMmseRecallCommentVal"];
  const ros_noGpcogMmsePatientDescribeNewsComment =
    tempDbData["ros_noGpcogMmsePatientDescribeNewsCommentVal"];
  const ros_noGpcogMmsePatientOrientPersonComment =
    tempDbData["ros_noGpcogMmsePatientOrientPersonCommentVal"];
  const ros_noGpcogMmsePatientOrientPlaceComment =
    tempDbData["ros_noGpcogMmsePatientOrientPlaceCommentVal"];
  const ros_noGpcogMmsePatientOrientTimeComment =
    tempDbData["ros_noGpcogMmsePatientOrientTimeCommentVal"];

  const ros_speechComment = tempDbData["ros_speechCommentVal"];

  const ros_sitToStandComment = tempDbData["ros_sitToStandCommentVal"];

  const ros_fingerToNoseAbnormalSubComment =
    tempDbData["ros_fingerToNoseAbnormalSubCommentVal"];
  const ros_fingerToNoseComment = tempDbData["ros_fingerToNoseCommentVal"];

  const ros_heelToToeAbnormalSubComment =
    tempDbData["ros_heelToToeAbnormalSubCommentVal"];
  const ros_heelToToeComment = tempDbData["ros_heelToToeCommentVal"];

  const ros_thumbToFingerAbnormalSubComment =
    tempDbData["ros_thumbToFingerAbnormalSubCommentVal"];
  const ros_thumbToFingerComment = tempDbData["ros_thumbToFingerCommentVal"];

  const ros_extremeMoveComment = tempDbData["ros_extremeMoveCommentVal"];

  const ros_affectComment = tempDbData["ros_affectCommentVal"];
  const ros_affectAbnormalSubDescComment =
    tempDbData["ros_affectAbnormalSubDescCommentVal"];

  const ros_over2WeeksLittleIntrstComment =
    tempDbData["ros_over2WeeksLittleIntrstCommentVal"];
  const ros_over2WeeksFeelDepressedComment =
    tempDbData["ros_over2WeeksFeelDepressedCommentVal"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubCommentVal"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubCommentVal"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal"];

  const ros_over2WeeksPhq2Score = tempDbData["ros_over2WeeksPhq2ScoreVal"];
  const ros_over2WeeksPhq9Score = tempDbData["ros_over2WeeksPhq9ScoreVal"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal"
    ];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere =
    tempDbData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal"
    ];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere =
    tempDbData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal"];

  const ros_neuroPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychVal"],
  );
  const ros_neuroPsychComment = tempDbData["ros_neuroPsychCommentVal"];

  const ros_nervousAnxious = getOptionsSelectedYesNo(
    tempDbData["ros_nervousAnxiousVal"],
  );
  const ros_nervousAnxiousComment = tempDbData["ros_nervousAnxiousCommentVal"];

  const ros_worryMuch = getOptionsSelectedYesNo(tempDbData["ros_worryMuchVal"]);
  const ros_worryMuchComment = tempDbData["ros_worryMuchCommentVal"];

  const ros_feelAfraid = getOptionsSelectedYesNo(
    tempDbData["ros_feelAfraidVal"],
  );
  const ros_feelAfraidComment = tempDbData["ros_feelAfraidCommentVal"];

  const ros_neuroPsychHistoryAudio = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychHistoryAudioVal"],
  );
  const ros_neuroPsychHistoryAudioComment =
    tempDbData["ros_neuroPsychHistoryAudioCommentVal"];

  const ros_neuroPsychHistoryVisual = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychHistoryVisualVal"],
  );
  const ros_neuroPsychHistoryVisualComment =
    tempDbData["ros_neuroPsychHistoryVisualCommentVal"];

  const ros_neuroPsychBehaviour = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychBehaviourVal"],
  );
  const ros_neuroPsychBehaviourComment =
    tempDbData["ros_neuroPsychBehaviourCommentVal"];

  const ros_neuroPsychDelirium = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychDeliriumVal"],
  );
  const ros_neuroPsychDeliriumComment =
    tempDbData["ros_neuroPsychDeliriumCommentVal"];

  const ros_neuroPsychHearVoices = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychHearVoicesVal"],
  );
  const ros_neuroPsychHearVoicesComment =
    tempDbData["ros_neuroPsychHearVoicesCommentVal"];

  const ros_neuroPsychHighLows = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychHighLowsVal"],
  );
  const ros_neuroPsychHighLowsComment =
    tempDbData["ros_neuroPsychHighLowsCommentVal"];

  const ros_neuroPsychEverFeel = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychEverFeelVal"],
  );
  const ros_neuroPsychEverFeelComment =
    tempDbData["ros_neuroPsychEverFeelCommentVal"];

  const ros_neuroPsychSubDiagComment =
    tempDbData["ros_neuroPsychSubDiagCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubDescComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDescCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubTypeCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal"];

  const ros_neuroPsychSubDiagAlcoholSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubSuppCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubDelirium = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDeliriumVal"],
  );
  const ros_neuroPsychSubDiagAlcoholSubDeliriumComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal"];

  const ros_neuroPsychSubDiagAlcoholSubPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagAlcoholSubPsychVal"],
  );
  const ros_neuroPsychSubDiagAlcoholSubPsychComment =
    tempDbData["ros_neuroPsychSubDiagAlcoholSubPsychCommentVal"];

  const ros_neuroPsychSubDiagAmyotropSubDescComment =
    tempDbData["ros_neuroPsychSubDiagAmyotropSubDescCommentVal"];

  const ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal"];

  const ros_neuroPsychSubDiagAmyotropSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAmyotropSubSuppCommentVal"];

  const ros_neuroPsychSubDiagAnxietySubDescComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubDescCommentVal"];
  const ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal"];
  const ros_neuroPsychSubDiagAnxietySubTypeComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubTypeCommentVal"];
  const ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagAnxietySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagAnxietySubSuppCommentVal"];

  const ros_neuroPsychSubDiagBipolarSubDescComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubDescCommentVal"];
  const ros_neuroPsychSubDiagBipolarSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubTypeCommentVal"];
  const ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagBipolarSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagBipolarSubSuppCommentVal"];

  const ros_neuroPsychSubDiagCerebHemmSubDescComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubDescCommentVal"];

  const ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubCommentVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal"];

  const ros_neuroPsychSubDiagDementiaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubDescCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDementiaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal"];
  const ros_neuroPsychSubDiagDementiaSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagDementiaSubTypeCommentVal"];

  const ros_neuroPsychSubDiagDrugsSubHistoryPsych = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagDrugsSubHistoryPsychVal"],
  );
  const ros_neuroPsychSubDiagDrugsSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubDescCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubTypeCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDrugsSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubHistoryPsychComment =
    tempDbData["ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal"];
  const ros_neuroPsychSubDiagDrugsSubWhatDrugs =
    tempDbData["ros_neuroPsychSubDiagDrugsSubWhatDrugsVal"];

  const ros_neuroPsychSubDiagDepressSubMajor = getOptionsSelectedYesNoUnknown(
    tempDbData["ros_neuroPsychSubDiagDepressSubMajorVal"],
  );
  const ros_neuroPsychSubDiagDepressSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubDescCommentVal"];
  const ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDepressSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubSuppCommentVal"];
  const ros_neuroPsychSubDiagDepressSubMajorComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubMajorCommentVal"];
  const ros_neuroPsychSubDiagDepressSubEpisodeComment =
    tempDbData["ros_neuroPsychSubDiagDepressSubEpisodeCommentVal"];

  const ros_neuroPsychSubDiagHemiSubDescComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubDescCommentVal"];
  const ros_neuroPsychSubDiagHemiSubHemiDescComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubHemiDescCommentVal"];
  const ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagHemiSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagHemiSubSuppCommentVal"];

  const ros_neuroPsychSubDiagIntelSubDescComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubDescCommentVal"];
  const ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagIntelSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubSuppCommentVal"];
  const ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal"];
  const ros_neuroPsychSubDiagIntelSubIntelDescComment =
    tempDbData["ros_neuroPsychSubDiagIntelSubIntelDescCommentVal"];

  const ros_neuroPsychSubDiagSeizureSubTreatment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubTreatmentVal"];
  const ros_neuroPsychSubDiagSeizureSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubDescCommentVal"];
  const ros_neuroPsychSubDiagSeizureSubTypeComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubTypeCommentVal"];
  const ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSeizureSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSeizureSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSpinalCordSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubDescCommentVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSecToComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal"];

  const ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCommentVal"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubComment =
    tempDbData["ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCommentVal"];

  const ros_neuroPsychSubDiagStrokeSubDescComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubDescCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagStrokeSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagStrokeSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSubduralSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubDescCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSubduralSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSubduralSubSuppCommentVal"];

  const ros_neuroPsychSubDiagTraumaBrainSubDescComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal"];

  const ros_neuroPsychSubDiagCerebPalsySubDescComment =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubDescCommentVal"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal"];

  const ros_neuroPsychSubDiagDelusionSubDescComment =
    tempDbData["ros_neuroPsychSubDiagDelusionSubDescCommentVal"];
  const ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDelusionSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDelusionSubSuppCommentVal"];

  const ros_neuroPsychSubDiagFibroSubDescComment =
    tempDbData["ros_neuroPsychSubDiagFibroSubDescCommentVal"];
  const ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagFibroSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagFibroSubSuppCommentVal"];

  const ros_neuroPsychSubDiagGuillSubDescComment =
    tempDbData["ros_neuroPsychSubDiagGuillSubDescCommentVal"];
  const ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagGuillSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagGuillSubSuppCommentVal"];

  const ros_neuroPsychSubDiagHuntingSubDescComment =
    tempDbData["ros_neuroPsychSubDiagHuntingSubDescCommentVal"];
  const ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagHuntingSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagHuntingSubSuppCommentVal"];

  const ros_neuroPsychSubDiagInsomniaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubDescCommentVal"];
  const ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagInsomniaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagInsomniaSubSuppCommentVal"];

  const ros_neuroPsychSubDiagMigraineSubDescComment =
    tempDbData["ros_neuroPsychSubDiagMigraineSubDescCommentVal"];
  const ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagMigraineSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagMigraineSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSclerosisSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubDescCommentVal"];
  const ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSclerosisSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSclerosisSubSuppCommentVal"];

  const ros_neuroPsychSubDiagDystrophySubDescComment =
    tempDbData["ros_neuroPsychSubDiagDystrophySubDescCommentVal"];
  const ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagDystrophySubSuppComment =
    tempDbData["ros_neuroPsychSubDiagDystrophySubSuppCommentVal"];

  const ros_neuroPsychSubDiagGravisSubDescComment =
    tempDbData["ros_neuroPsychSubDiagGravisSubDescCommentVal"];
  const ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagGravisSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagGravisSubSuppCommentVal"];

  const ros_neuroPsychSubDiagParkinsonSubDescComment =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubDescCommentVal"];
  const ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagParkinsonSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagParkinsonSubSuppCommentVal"];

  const ros_neuroPsychSubDiagPeriNeuroSubDescComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal"];
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal"],
  );
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment =
    tempDbData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal"];

  const ros_neuroPsychSubDiagRestlessLegSubDescComment =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubDescCommentVal"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal"];

  const ros_neuroPsychSubDiagSchizoSubDescComment =
    tempDbData["ros_neuroPsychSubDiagSchizoSubDescCommentVal"];
  const ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagSchizoSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagSchizoSubSuppCommentVal"];

  const ros_neuroPsychSubDiagTiaSubDescComment =
    tempDbData["ros_neuroPsychSubDiagTiaSubDescCommentVal"];
  const ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagTiaSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagTiaSubSuppCommentVal"];

  const ros_neuroPsychSubDiagOtherSubDescComment =
    tempDbData["ros_neuroPsychSubDiagOtherSubDescCommentVal"];
  const ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc =
    tempDbData["ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal"];
  const ros_neuroPsychSubDiagOtherSubSuppComment =
    tempDbData["ros_neuroPsychSubDiagOtherSubSuppCommentVal"];
  const ros_neuroPsychSubDiagOtherSubOther =
    tempDbData["ros_neuroPsychSubDiagOtherSubOtherVal"];

  ///GAIT CHECKBOX//
  const gait_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["gait_recoTakeMedicationsAsPescribedVal"],
      tempDbData["gait_recoOtherVal"],
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const gait_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["gait_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const gait_RecommendationsotherComment =
    tempDbData["gait_RecommendationsotherCommentVal"];

  //const gait_RecommendationsComment = tempDbData["gait_RecommendationsComment"];

  //--respi checkbox
  const respiratory_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["respiratory_recoTakeMedicationsVal"],
      tempDbData["respiratory_recoOtherVal"],
    ],
    ["takeMedications", "other"],
  );

  // const respiratory_RecommendationsComment =
  //   tempDbData["respiratory_RecommendationsComment"];

  const respiratory_RecommendationstakeMedicationsComment =
    tempDbData["respiratory_RecommendationstakeMedicationsCommentVal"];

  const respiratory_RecommendationsotherComment =
    tempDbData["respiratory_RecommendationsotherCommentVal"];

  ///INTEGUMENT CHECKBOX//
  const integument_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["integument_recoTakeMedicationsAsPescribedVal"],
      tempDbData["integument_recoOtherVal"],
    ],
    ["takeMedicationsAsPescribed", "other"],
  );

  const integument_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData[
      "integument_RecommendationstakeMedicationsAsPescribedCommentVal"
    ];

  const integument_RecommendationsotherComment =
    tempDbData["integument_RecommendationsotherCommentVal"];

  // const integument_RecommendationsComment =
  //   tempDbData["integument_RecommendationsComment"];

  /////////MUSKO CHECKBOX//
  const musko_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["musko_recoDiscussPtOtVal"],
      tempDbData["musko_recoTakeMedicationsAsPescribedVal"],
      tempDbData["musko_recoOtherVal"],
    ],
    ["discussPtOt", "takeMedicationsAsPescribed", "other"],
  );
  const musko_RecommendationsdiscussPtOtComment =
    tempDbData["musko_RecommendationsdiscussPtOtCommentVal"];

  const musko_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["musko_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const musko_RecommendationsotherComment =
    tempDbData["musko_RecommendationsotherCommentVal"];

  // const musko_RecommendationsComment =
  //   tempDbData["musko_RecommendationsComment"];

  ////////HEMA CHECKBOX//////
  const hema_Recommendations = getSelectedCheckboxes(
    [
      tempDbData["hema_recoTakeMedicationsAsPescribedVal"],
      tempDbData["hema_recoReportAbnormalBruisingVal"],
      tempDbData["hema_recoFollowUpWithDoctorVal"],
      tempDbData["hema_recoOtherVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedComment =
    tempDbData["hema_RecommendationstakeMedicationsAsPescribedCommentVal"];

  const hema_RecommendationsreportAbnormalBruisingComment =
    tempDbData["hema_RecommendationsreportAbnormalBruisingCommentVal"];

  const hema_RecommendationsfollowUpWithDoctorComment =
    tempDbData["hema_RecommendationsfollowUpWithDoctorCommentVal"];

  const hema_RecommendationsotherComment =
    tempDbData["hema_RecommendationsotherCommentVal"];

  // const hema_RecommendationsComment =
  //   tempDbData["hema_RecommendationsComment"];

  ros_OptionsTreeWrappers?.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]);
  });

  const ros_respiratory = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryVal"],
  );

  const ros_integument = getOptionsSelectedYesNo(
    tempDbData["ros_integumentVal"],
  );

  const ros_musculoskeletal = getOptionsSelectedYesNo(
    tempDbData["ros_musculoskeletalVal"],
  );

  const ros_hematology = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyVal"],
  );

  const ros_respiratoryAsthmaPatientController = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryAsthmaPatientControllerVal"],
  );
  const ros_respiratoryAsthmaRescueMedications = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryAsthmaRescueMedicationsVal"],
  );
  const ros_respiratoryAsthmaCurrentExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryAsthmaCurrentExacerbationVal"],
  );

  const ros_respiratoryPatientChronicBronchitis = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientChronicBronchitisVal"],
  );
  const ros_respiratoryPatientHaveEmphysema = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientHaveEmphysemaVal"],
  );
  const ros_respiratoryPatientBronchodilator = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientBronchodilatorVal"],
  );
  const ros_respiratoryPatientSteroids = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientSteroidsVal"],
  );
  const ros_respiratoryPatientExacerbation = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryPatientExacerbationVal"],
  );

  const ros_respiratoryHistoryOfLungAbscess = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryHistoryOfLungAbscessVal"],
  );
  const ros_respiratoryHistoryOfEmpyema = getOptionsSelectedYesNo(
    tempDbData["ros_respiratoryHistoryOfEmpyemaVal"],
  );

  const ros_integumentPsoriasisSubAnswerPsoriaticArthritis =
    getOptionsSelectedYesNo(
      tempDbData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal"],
    );

  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal"
      ],
    );

  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal"
      ],
    );

  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder =
    getOptionsSelectedYesNo(
      tempDbData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal"],
    );

  const ros_musculoskeletalMuskoHadAnAmputation = getOptionsSelectedYesNo(
    tempDbData["ros_musculoskeletalMuskoHadAnAmputationVal"],
  );

  const ros_hematologyAidsSubAnswerPatientUnderTreatment =
    getOptionsSelectedYesNo(
      tempDbData["ros_hematologyAidsSubAnswerPatientUnderTreatmentVal"],
    );

  const ros_hematologyHivSubAnswerPatientSymptomatic = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyHivSubAnswerPatientSymptomaticVal"],
  );

  const ros_hematologyHivSubAnswerActiveTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyHivSubAnswerActiveTreatmentVal"],
  );

  const ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticVal"
      ],
    );

  const ros_hematologyTuberculosisSubAnswerGivenBCG = getOptionsSelectedYesNo(
    tempDbData["ros_hematologyTuberculosisSubAnswerGivenBCG"],
  );

  const ros_hematologyTuberculosisSubAnswerActiveTuberculosis =
    getOptionsSelectedYesNo(
      tempDbData["ros_hematologyTuberculosisSubAnswerActiveTuberculosisVal"],
    );

  const ros_hematologyTuberculosisSubAnswerTbInfection =
    getOptionsSelectedYesNo(
      tempDbData["ros_hematologyTuberculosisSubAnswerTbInfectionVal"],
    );

  //GAIT COMMENT==================;
  const ros_gaitComment = tempDbData["ros_gaitComment"];

  //RESPIRATORY COMMENT---------------------------
  const ros_respiratoryComment = tempDbData["ros_respiratoryComment"];
  const ros_respiratoryAcutePulmonarySubAnswerDescribeComment =
    tempDbData["ros_respiratoryAcutePulmonarySubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    tempDbData["ros_respiratoryDiagnosesComment"];
  const ros_respiratoryAcutePulmonarySubAnswerSupportedByComment =
    tempDbData["ros_respiratoryAcutePulmonarySubAnswerSupportedByComment"];
  const ros_respiratoryAcuteUpperSubAnswerDescribeComment =
    tempDbData["ros_respiratoryAcuteUpperSubAnswerDescribeComment"];
  const ros_respiratoryAcuteUpperSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryAcuteUpperSubAnswerSupportedByComment"];
  const ros_respiratoryAsthmaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryAsthmaSubAnswerDescribeComment"];
  const ros_respiratoryAsthmaSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryAsthmaSubAnswerSupportedByComment"];
  const ros_respiratoryAsthmaPatientControllerComment =
    tempDbData["ros_respiratoryAsthmaPatientControllerComment"];
  const ros_respiratoryAsthmaRescueMedicationsComment =
    tempDbData["ros_respiratoryAsthmaRescueMedicationsComment"];
  const ros_respiratoryAsthmaCurrentExacerbationComment =
    tempDbData["ros_respiratoryAsthmaCurrentExacerbationComment"];
  const ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment =
    tempDbData[
      "ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment"
    ];
  const ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment =
    tempDbData[
      "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
    tempDbData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment =
    tempDbData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment"
    ];
  const ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
    tempDbData["ros_respiratoryChronicSputumFailureSubAnswerDescribeComment"];
  const ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment =
    tempDbData[
      "ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment"
    ];
  const ros_respiratoryCopdSubAnswerDescribeComment =
    tempDbData["ros_respiratoryCopdSubAnswerDescribeComment"];
  const ros_respiratoryCopdSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryCopdSubAnswerSupportedByComment"];
  const ros_respiratoryPatientChronicBronchitisComment =
    tempDbData["ros_respiratoryPatientChronicBronchitisComment"];
  const ros_respiratoryPatientHaveEmphysemaComment =
    tempDbData["ros_respiratoryPatientHaveEmphysemaComment"];
  const ros_respiratoryPatientBronchodilatorComment =
    tempDbData["ros_respiratoryPatientBronchodilatorComment"];
  const ros_respiratoryPatientSteroidsComment =
    tempDbData["ros_respiratoryPatientSteroidsComment"];
  const ros_respiratoryPatientExacerbationComment =
    tempDbData["ros_respiratoryPatientExacerbationComment"];
  const ros_respiratoryCysticFibrosisSubAnswerDescribeComment =
    tempDbData["ros_respiratoryCysticFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryCysticFibrosisSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryCysticFibrosisSubAnswerSupportedByComment"];
  const ros_respiratoryHypoventilationSubAnswerDescribeComment =
    tempDbData["ros_respiratoryHypoventilationSubAnswerDescribeComment"];
  const ros_respiratoryHypoventilationSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryHypoventilationSubAnswerSupportedByComment"];
  const ros_respiratoryHypoxemiaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryHypoxemiaSubAnswerDescribeComment"];
  const ros_respiratoryHypoxemiaSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryHypoxemiaSubAnswerSupportedByComment"];
  const ros_respiratoryPneumoniaSubAnswerDescribeComment =
    tempDbData["ros_respiratoryPneumoniaSubAnswerDescribeComment"];
  const ros_respiratoryPneumoniaSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryPneumoniaSubAnswerSupportedByComment"];
  const ros_respiratoryPneumoniaSubAnswerEtiologyComment =
    tempDbData["ros_respiratoryPneumoniaSubAnswerEtiologyComment"];
  const ros_respiratoryHistoryOfLungAbscessComment =
    tempDbData["ros_respiratoryHistoryOfLungAbscessComment"];
  const ros_respiratoryHistoryOfEmpyemaComment =
    tempDbData["ros_respiratoryHistoryOfEmpyemaComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
    tempDbData["ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment"];
  const ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment =
    tempDbData[
      "ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment"
    ];
  const ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment"];
  const ros_respiratorySarcoidosisSubAnswerDescribeComment =
    tempDbData["ros_respiratorySarcoidosisSubAnswerDescribeComment"];
  const ros_respiratorySarcoidosisSubAnswerSupportedByComment =
    tempDbData["ros_respiratorySarcoidosisSubAnswerSupportedByComment"];
  const ros_respiratorySarcoidosisSubAnswerSpecifyComment =
    tempDbData["ros_respiratorySarcoidosisSubAnswerSpecifyComment"];
  const ros_respiratorySleepApneaSubAnswerDescribeComment =
    tempDbData["ros_respiratorySleepApneaSubAnswerDescribeComment"];
  const ros_respiratorySleepApneaSubAnswerSupportedByComment =
    tempDbData["ros_respiratorySleepApneaSubAnswerSupportedByComment"];
  const ros_respiratoryRespOtherSubAnswerDescribeComment =
    tempDbData["ros_respiratoryRespOtherSubAnswerDescribeComment"];
  const ros_respiratoryRespOtherSubAnswerSupportedByComment =
    tempDbData["ros_respiratoryRespOtherSubAnswerSupportedByComment"];
  const ros_respiratoryOtherSubAnswerOther =
    tempDbData["ros_respiratoryOtherSubAnswerOther"];

  const ros_integumentComment = tempDbData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    tempDbData["ros_integumentDiagnosesComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
    tempDbData["ros_integumentBasilCellCarcinomaSubAnswerDescribeComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment =
    tempDbData["ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment"];
  const ros_integumentDermatitisSubAnswerDescribeComment =
    tempDbData["ros_integumentDermatitisSubAnswerDescribeComment"];
  const ros_integumentDermatitisSubAnswerSupportedByComment =
    tempDbData["ros_integumentDermatitisSubAnswerSupportedByComment"];
  const ros_integumentDermatitisSubAnswerWhatTypeComment =
    tempDbData["ros_integumentDermatitisSubAnswerWhatTypeComment"];
  const ros_integumentEczemaSubAnswerDescribeComment =
    tempDbData["ros_integumentEczemaSubAnswerDescribeComment"];
  const ros_integumentEczemaSubAnswerSupportedByComment =
    tempDbData["ros_integumentEczemaSubAnswerSupportedByComment"];
  const ros_integumentOnychomycosisSubAnswerDescribeComment =
    tempDbData["ros_integumentOnychomycosisSubAnswerDescribeComment"];
  const ros_integumentOnychomycosisSubAnswerSupportedByComment =
    tempDbData["ros_integumentOnychomycosisSubAnswerSupportedByComment"];
  const ros_integumentPsoriasisSubAnswerDescribeComment =
    tempDbData["ros_integumentPsoriasisSubAnswerDescribeComment"];
  const ros_integumentPsoriasisSubAnswerSupportedByComment =
    tempDbData["ros_integumentPsoriasisSubAnswerSupportedByComment"];
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
    tempDbData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_integumentSkinUlcerSubAnswerDescribeComment =
    tempDbData["ros_integumentSkinUlcerSubAnswerDescribeComment"];
  const ros_integumentSkinUlcerSubAnswerSupportedByComment =
    tempDbData["ros_integumentSkinUlcerSubAnswerSupportedByComment"];
  const ros_integumentSkinUlcerSubAnswerEtiologyComment =
    tempDbData["ros_integumentSkinUlcerSubAnswerEtiologyComment"];
  const ros_integumentTineaPedisSubAnswerDescribeComment =
    tempDbData["ros_integumentTineaPedisSubAnswerDescribeComment"];
  const ros_integumentTineaPedisSubAnswerSupportedByComment =
    tempDbData["ros_integumentTineaPedisSubAnswerSupportedByComment"];
  const ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerDescribeComment"];
  const ros_integumentUrticarialDiseaseSubAnswerSupportedByComment =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerSupportedByComment"];
  const ros_integumentUrticarialDiseaseSubAnswerEtiology =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerEtiology"];
  const ros_integumentUrticarialDiseaseSubAnswerTypeComment =
    tempDbData["ros_integumentUrticarialDiseaseSubAnswerTypeComment"];
  const ros_integumentWoundSubAnswerDescribeComment =
    tempDbData["ros_integumentWoundSubAnswerDescribeComment"];
  const ros_integumentWoundSubAnswerSupportedByComment =
    tempDbData["ros_integumentWoundSubAnswerSupportedByComment"];
  const ros_integumentWoundSubAnswerEtiologyComment =
    tempDbData["ros_integumentWoundSubAnswerEtiologyComment"];
  const ros_integumentOthersSubAnswerDescribeComment =
    tempDbData["ros_integumentOthersSubAnswerDescribeComment"];
  const ros_integumentOthersSubAnswerSupportedByComment =
    tempDbData["ros_integumentOthersSubAnswerSupportedByComment"];
  const ros_integumentOthersSubAnswerOther =
    tempDbData["ros_integumentOthersSubAnswerOther"];

  const ros_musculoskeletalComment = tempDbData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    tempDbData["ros_musculoskeletalDiagnosesComment"];
  const ros_musculoskeletalCollagenSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalCollagenSubAnswerDescribeComment"];
  const ros_musculoskeletalCollagenSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalCollagenSubAnswerSupportedByComment"];
  const ros_musculoskeletalCollagenSubAnswercollagenDescribeComment =
    tempDbData["ros_musculoskeletalCollagenSubAnswercollagenDescribeComment"];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalExtremityFractureSubAnswerDescribeComment"];
  const ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
    tempDbData["ros_musculoskeletalExtremityFractureSubAnswerExtremityComment"];
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment"
    ];
  const ros_musculoskeletalGoutSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalGoutSubAnswerDescribeComment"];
  const ros_musculoskeletalGoutSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalGoutSubAnswerSupportedByComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalHalluxValgusSubAnswerDescribeComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment"];
  const ros_musculoskeletalHammerToesSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalHammerToesSubAnswerDescribeComment"];
  const ros_musculoskeletalHammerToesSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalHammerToesSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalOsteoporosisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment"];
  const ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment =
    tempDbData[
      "ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints"];

  const ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment"
    ];

  const ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
    tempDbData["ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment =
    tempDbData[
      "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
    tempDbData[
      "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalSystemicLupusSubAnswerDescribeComment"];
  const ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerDescribeComment =
    tempDbData["ros_musculoskeletalMuskoOtherSubAnswerDescribeComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment =
    tempDbData["ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerother =
    tempDbData["ros_musculoskeletalMuskoOtherSubAnswerother"];
  const ros_musculoskeletalHadAnAmputationSubAnswer =
    tempDbData["ros_musculoskeletalHadAnAmputationSubAnswer"];

  const ros_hematologyComment = tempDbData["ros_hematologyComment"];
  const ros_hematologyDiagnosesComment =
    tempDbData["ros_hematologyDiagnosesComment"];
  const ros_hematologyAidsSubAnswerDescribeComment =
    tempDbData["ros_hematologyAidsSubAnswerDescribeComment"];
  const ros_hematologyAidsSubAnswerSupportedByComment =
    tempDbData["ros_hematologyAidsSubAnswerSupportedByComment"];
  const ros_hematologyAidsSubAnswerPatientUnderTreatmentComment =
    tempDbData["ros_hematologyAidsSubAnswerPatientUnderTreatmentComment"];
  const ros_hematologyAnemiaSubAnswerDescribeComment =
    tempDbData["ros_hematologyAnemiaSubAnswerDescribeComment"];
  const ros_hematologyAnemiaSubAnswerSupportedByComment =
    tempDbData["ros_hematologyAnemiaSubAnswerSupportedByComment"];
  const ros_hematologyAnemiaSubAnswerEtiologyComment =
    tempDbData["ros_hematologyAnemiaSubAnswerEtiologyComment"];
  const ros_hematologyAnemiaSubAnswerYesPatientOnComment =
    tempDbData["ros_hematologyAnemiaSubAnswerYesPatientOnComment"];
  const ros_hematologyCDifficileSubAnswerDescribeComment =
    tempDbData["ros_hematologyCDifficileSubAnswerDescribeComment"];
  const ros_hematologyCDifficileSubAnswerSupportedByComment =
    tempDbData["ros_hematologyCDifficileSubAnswerSupportedByComment"];
  const ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
    tempDbData["ros_hematologyCommunityAcquiredSubAnswerDescribeComment"];
  const ros_hematologyCommunityAcquiredSubAnswerSupportedByComment =
    tempDbData["ros_hematologyCommunityAcquiredSubAnswerSupportedByComment"];
  const ros_hematologyHivSubAnswerDescribeComment =
    tempDbData["ros_hematologyHivSubAnswerDescribeComment"];
  const ros_hematologyHivSubAnswerViralLoad =
    tempDbData["ros_hematologyHivSubAnswerViralLoad"];
  const ros_hematologyHivSubAnswerCFour =
    tempDbData["ros_hematologyHivSubAnswerCFour"];
  const ros_hematologyHivSubAnswerSupportedByComment =
    tempDbData["ros_hematologyHivSubAnswerSupportedByComment"];
  const ros_hematologyHivSubAnswerPatientSymptomaticComment =
    tempDbData["ros_hematologyHivSubAnswerPatientSymptomaticComment"];
  const ros_hematologyHivSubAnswerActiveTreatmentComment =
    tempDbData["ros_hematologyHivSubAnswerActiveTreatmentComment"];
  const ros_hematologyHerpesZosterSubAnswerDescribeComment =
    tempDbData["ros_hematologyHerpesZosterSubAnswerDescribeComment"];
  const ros_hematologyHerpesZosterSubAnswerSupportedByComment =
    tempDbData["ros_hematologyHerpesZosterSubAnswerSupportedByComment"];
  const ros_hematologyMrsaInfectionSubAnswerDescribeComment =
    tempDbData["ros_hematologyMrsaInfectionSubAnswerDescribeComment"];
  const ros_hematologyMrsaInfectionSubAnswerSupportedByComment =
    tempDbData["ros_hematologyMrsaInfectionSubAnswerSupportedByComment"];
  const ros_hematologyImmuneDeficiencySubAnswerDescribeComment =
    tempDbData["ros_hematologyImmuneDeficiencySubAnswerDescribeComment"];
  const ros_hematologyImmuneDeficiencySubAnswerEtiology =
    tempDbData["ros_hematologyImmuneDeficiencySubAnswerEtiology"];
  const ros_hematologyImmuneDeficiencySubAnswerSupportedByComment =
    tempDbData["ros_hematologyImmuneDeficiencySubAnswerSupportedByComment"];
  const ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment =
    tempDbData[
      "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment"
    ];
  const ros_hematologySepsisSubAnswerDescribeComment =
    tempDbData["ros_hematologySepsisSubAnswerDescribeComment"];
  const ros_hematologySepsisSubAnswerSupportedByComment =
    tempDbData["ros_hematologySepsisSubAnswerSupportedByComment"];
  const ros_hematologySickleCellDiseaseSubAnswerDescribeComment =
    tempDbData["ros_hematologySickleCellDiseaseSubAnswerDescribeComment"];
  const ros_hematologySickleCellDiseaseSubAnswerSupportedByComment =
    tempDbData["ros_hematologySickleCellDiseaseSubAnswerSupportedByComment"];
  const ros_hematologySickleCellTraitSubAnswerDescribeComment =
    tempDbData["ros_hematologySickleCellTraitSubAnswerDescribeComment"];
  const ros_hematologySickleCellTraitSubAnswerSupportedByComment =
    tempDbData["ros_hematologySickleCellTraitSubAnswerSupportedByComment"];
  const ros_hematologyThalassemiaSubAnswerDescribeComment =
    tempDbData["ros_hematologyThalassemiaSubAnswerDescribeComment"];
  const ros_hematologyThalassemiaSubAnswerSupportedByComment =
    tempDbData["ros_hematologyThalassemiaSubAnswerSupportedByComment"];
  const ros_hematologyThrombocytopeniaSubAnswerDescribeComment =
    tempDbData["ros_hematologyThrombocytopeniaSubAnswerDescribeComment"];
  const ros_hematologyThrombocytopeniaSubAnswerEtiology =
    tempDbData["ros_hematologyThrombocytopeniaSubAnswerEtiology"];
  const ros_hematologyThrombocytopeniaSubAnswerSupportedByComment =
    tempDbData["ros_hematologyThrombocytopeniaSubAnswerSupportedByComment"];
  const ros_hematologyTuberculosisSubAnswerDescribeComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerDescribeComment"];
  const ros_hematologyTuberculosisSubAnswerSupportedByComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerSupportedByComment"];
  const ros_hematologyTuberculosisSubAnswerGivenBCGComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerGivenBCGComment"];
  const ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_hematologyTuberculosisSubAnswerTbInfectionComment =
    tempDbData["ros_hematologyTuberculosisSubAnswerTbInfectionComment"];

  const ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
    tempDbData["ros_hematologyVitaminDDeficiencySubAnswerDescribeComment"];

  const ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment =
    tempDbData["ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment"];
  const ros_hematologyHemaOtherSubAnswerDescribeComment =
    tempDbData["ros_hematologyHemaOtherSubAnswerDescribeComment"];
  const ros_hematologyHemaOtherSubAnswerSupportedByComment =
    tempDbData["ros_hematologyHemaOtherSubAnswerSupportedByComment"];
  const ros_hematologyHemaOtherSubAnswerOther =
    tempDbData["ros_hematologyHemaOtherSubAnswerOther"];

  const ros_respiratoryAcutePulmonarySubOtherSubDesc =
    tempDbData["ros_respiratoryAcutePulmonarySubOtherSubDesc"];

  const ros_respiratoryAcuteUpperSubOtherSubDesc =
    tempDbData["ros_respiratoryAcuteUpperSubOtherSubDesc"];

  const ros_respiratoryAsthmaSubOtherSubDesc =
    tempDbData["ros_respiratoryAsthmaSubOtherSubDesc"];

  const ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
    tempDbData[
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment"
    ];

  const ros_diagnosisOfCancer = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerVal"],
  );

  const ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc =
    tempDbData["ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc"];

  const ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc =
    tempDbData["ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc"];

  const ros_respiratoryChronicSputumFailureSubOtherSubDesc =
    tempDbData["ros_respiratoryChronicSputumFailureSubOtherSubDesc"];

  const ros_respiratoryCopdSubOtherSubDesc =
    tempDbData["ros_respiratoryCopdSubOtherSubDesc"];

  const ros_respiratoryCopdSubPatientBronchodilatorComment =
    tempDbData["ros_respiratoryCopdSubPatientBronchodilatorComment"];

  const ros_musculoskeletalMuskoHadAnAmputationComment =
    tempDbData["ros_musculoskeletalMuskoHadAnAmputationComment"];

  const ros_respiratoryCopdSubPatientSteroidsComment =
    tempDbData["ros_respiratoryCopdSubPatientSteroidsComment"];

  const ros_respiratoryCopdSubPatientExacerbationComment =
    tempDbData["ros_respiratoryCopdSubPatientExacerbationComment"];

  const ros_respiratoryCysticFibrosisSubDesc =
    tempDbData["ros_respiratoryCysticFibrosisSubDesc"];

  const ros_respiratoryHypoventilationSubDesc =
    tempDbData["ros_respiratoryHypoventilationSubDesc"];

  const ros_respiratoryHypoxemiaSubDesc =
    tempDbData["ros_respiratoryHypoxemiaSubDesc"];

  const ros_respiratoryPneumoniaSubDesc =
    tempDbData["ros_respiratoryPneumoniaSubDesc"];

  const ros_respiratoryPulmonaryFibrosisSubDesc =
    tempDbData["ros_respiratoryPulmonaryFibrosisSubDesc"];

  const ros_respiratoryRespiratorDependenceSubDesc =
    tempDbData["ros_respiratoryRespiratorDependenceSubDesc"];

  const ros_respiratoryRespiratoryArrestSubDesc =
    tempDbData["ros_respiratoryRespiratoryArrestSubDesc"];

  const ros_respiratorySarcoidosisSubDesc =
    tempDbData["ros_respiratorySarcoidosisSubDesc"];

  const ros_respiratorySleepApneaSubDesc =
    tempDbData["ros_respiratorySleepApneaSubDesc"];

  const ros_respiratoryOtherSubDesc = tempDbData["ros_respiratoryOtherSubDesc"];

  const ros_integumentBasilCellCarcinomaSubOtherSubDesc =
    tempDbData["ros_integumentBasilCellCarcinomaSubOtherSubDesc"];

  const ros_integumentDermatitisSubOtherSubDesc =
    tempDbData["ros_integumentDermatitisSubOtherSubDesc"];

  const ros_integumentEczemaSubOtherSubDesc =
    tempDbData["ros_integumentEczemaSubOtherSubDesc"];

  const ros_integumentOnychomycosisSubOtherSubDesc =
    tempDbData["ros_integumentOnychomycosisSubOtherSubDesc"];

  const ros_integumentPsoriasisSubOtherSubDesc =
    tempDbData["ros_integumentPsoriasisSubOtherSubDesc"];

  const ros_integumentSkinUlcerSubOtherSubDesc =
    tempDbData["ros_integumentSkinUlcerSubOtherSubDesc"];

  const ros_integumentSkinUlcerSubEtiologySubDesc =
    tempDbData["ros_integumentSkinUlcerSubEtiologySubDesc"];

  const ros_integumentTineaPedisSubOtherSubDesc =
    tempDbData["ros_integumentTineaPedisSubOtherSubDesc"];

  const ros_integumentUrticarialDiseaseSubOtherSubDesc =
    tempDbData["ros_integumentUrticarialDiseaseSubOtherSubDesc"];

  const ros_integumentWoundSubOtherSubDesc =
    tempDbData["ros_integumentWoundSubOtherSubDesc"];

  const ros_integumentIntegumentOtherSubOtherSubDesc =
    tempDbData["ros_integumentIntegumentOtherSubOtherSubDesc"];

  const ros_hematologyAidsSubOtherSubDesc =
    tempDbData["ros_hematologyAidsSubOtherSubDesc"];

  const ros_hematologyAidsSubPatientUnderTreatmentSubWhere =
    tempDbData["ros_hematologyAidsSubPatientUnderTreatmentSubWhere"];

  const ros_hematologyAnemiaSubEtiologySubOtherSubDesc =
    tempDbData["ros_hematologyAnemiaSubEtiologySubOtherSubDesc"];

  const ros_hematologyAnemiaSubotherSubDesc =
    tempDbData["ros_hematologyAnemiaSubotherSubDesc"];

  const ros_hematologyAnemiaSubYesPatientOnSubDesc =
    tempDbData["ros_hematologyAnemiaSubYesPatientOnSubDesc"];

  const ros_hematologyCDifficileSubOtherSubDesc =
    tempDbData["ros_hematologyCDifficileSubOtherSubDesc"];

  const ros_hematologyCommunityAcquiredSubOtherSubDesc =
    tempDbData["ros_hematologyCommunityAcquiredSubOtherSubDesc"];

  const ros_hematologyHivSubOtherSubDesc =
    tempDbData["ros_hematologyHivSubOtherSubDesc"];

  const ros_hematologyHerpesZosterSubOtherSubDesc =
    tempDbData["ros_hematologyHerpesZosterSubOtherSubDesc"];

  const ros_hematologyMrsaInfectionSubOtherSubDesc =
    tempDbData["ros_hematologyMrsaInfectionSubOtherSubDesc"];

  const ros_hematologyImmuneDeficiencySubOtherSubDesc =
    tempDbData["ros_hematologyImmuneDeficiencySubOtherSubDesc"];

  const ros_hematologySepsisSubOtherSubDesc =
    tempDbData["ros_hematologySepsisSubOtherSubDesc"];

  const ros_hematologySickleCellDiseaseSubDesc =
    tempDbData["ros_hematologySickleCellDiseaseSubDesc"];

  const ros_hematologySickleCellTraitSubOtherSubDesc =
    tempDbData["ros_hematologySickleCellTraitSubOtherSubDesc"];

  const ros_hematologyThalassemiaSubOtherSubDesc =
    tempDbData["ros_hematologyThalassemiaSubOtherSubDesc"];

  const ros_hematologyThrombocytopeniaSubOtherSubDesc =
    tempDbData["ros_hematologyThrombocytopeniaSubOtherSubDesc"];

  const ros_hematologyTuberculosisSubOtherSubDesc =
    tempDbData["ros_hematologyTuberculosisSubOtherSubDesc"];

  const ros_hematologyVitaminDDeficiencySubOtherSubDesc =
    tempDbData["ros_hematologyVitaminDDeficiencySubOtherSubDesc"];

  const ros_hematologyHemaOtherSubOtherSubDesc =
    tempDbData["ros_hematologyHemaOtherSubOtherSubDesc"];

  const ros_musculoskeletalCollagenSubOtherSubDesc =
    tempDbData["ros_musculoskeletalCollagenSubOtherSubDesc"];

  const ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc =
    tempDbData["ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc"];

  const ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
    tempDbData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc"
    ];

  const ros_musculoskeletalExtremityFractureSubOtherSubDesc =
    tempDbData["ros_musculoskeletalExtremityFractureSubOtherSubDesc"];

  const ros_musculoskeletalGoutSubOtherSubDesc =
    tempDbData["ros_musculoskeletalGoutSubOtherSubDesc"];

  const ros_musculoskeletalHalluxValgusSubOtherSubDesc =
    tempDbData["ros_musculoskeletalHalluxValgusSubOtherSubDesc"];

  const ros_musculoskeletalHammerToesSubOtherSubDesc =
    tempDbData["ros_musculoskeletalHammerToesSubOtherSubDesc"];

  const ros_musculoskeletalOsteoarthritisSubOtherSubDesc =
    tempDbData["ros_musculoskeletalOsteoarthritisSubOtherSubDesc"];

  const ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment"
    ];

  const ros_musculoskeletalOsteomyelitisSubOtherSubDesc =
    tempDbData["ros_musculoskeletalOsteomyelitisSubOtherSubDesc"];

  const ros_musculoskeletalOsteoporosisSubOtherSubDesc =
    tempDbData["ros_musculoskeletalOsteoporosisSubOtherSubDesc"];

  const ros_musculoskeletalPyogenicArthritisSubOtherSubDesc =
    tempDbData["ros_musculoskeletalPyogenicArthritisSubOtherSubDesc"];

  const ros_musculoskeletalRheumatoidArthritisSubDesc =
    tempDbData["ros_musculoskeletalRheumatoidArthritisSubDesc"];

  // const ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms =
  //   tempDbData["ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms"];

  const ros_musculoskeletalSpinalStenosisSubOtherSubDesc =
    tempDbData["ros_musculoskeletalSpinalStenosisSubOtherSubDesc"];

  const ros_musculoskeletalSystemicLupusSubOtherSubDesc =
    tempDbData["ros_musculoskeletalSystemicLupusSubOtherSubDesc"];

  const ros_musculoskeletalMuskoSubOtherSubDesc =
    tempDbData["ros_musculoskeletalMuskoSubOtherSubDesc"];

  const ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
    tempDbData[
      "ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment"
    ];

  const ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureSubOther =
    tempDbData["ros_musculoskeletalExtremityFractureSubOther"];

  const ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureShoulderSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureArmSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureArmSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureForearmSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureWristSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureWristSubAnswerComment"];

  const ros_musculoskeletalHandSubAnswerComment =
    tempDbData["ros_musculoskeletalHandSubAnswerComment"];

  const ros_musculoskeletalFemoralShaftSubAnswerComment =
    tempDbData["ros_musculoskeletalFemoralShaftSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureTibiaSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureFibulaSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureAnkleSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureFootSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureFootSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureHipSubAnswerComment =
    tempDbData["ros_musculoskeletalExtremityFractureHipSubAnswerComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaVal = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
  );

  const ros_diagnosisOfCancerComment =
    tempDbData["ros_diagnosisOfCancerCommentVal"];

  const ros_diagnosisOfCancerRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCancerVal"],
      tempDbData["ros_otherCancerVal"],
    ],
    ["takemedicationsasprescribedCancer", "otherCancer"],
  );

  const ros_genitoRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedGenitoVal"],
      tempDbData["ros_otherGenitoVal"],
    ],
    ["takeMedicationsAsPrescribedGenito", "otherGenito"],
  );

  const ros_genitoSubDiagnosesComment =
    tempDbData["ros_genitoSubDiagnosesComment"];

  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  const ros_gastrorecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_gastrorecommendationstakemedicationsasprescribed"],
      tempDbData["ros_gastrorecommendationsother"],
    ],
    ["takemedicationsasprescribed", "other"],
  );

  const ros_gastrorecommendationsotherComment =
    tempDbData["ros_gastrorecommendationsotherComment"];

  const ros_gastrorecommendationstakemedicationsasprescribedComment =
    tempDbData["ros_gastrorecommendationstakemedicationsasprescribedComment"];

  const ros_gastroSubDiagnosesComment =
    tempDbData["ros_gastroSubDiagnosesComment"];

  const ros_endocrineIsEndocrineRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takeMedicationsAsPescribedEndoEndocrineVal"],
      tempDbData["ros_checkBloodSugarEndoEndorineVal"],
      tempDbData["ros_otherEndoEndocrineVal"],
    ],
    ["takeMedicationsAsPescribedEndo", "checkBloodSugarEndo", "otherEndo"],
  );
  const ros_endocrineIsEndocrineDiagnoseComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseComment"];

  const ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment"
    ];
  const ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment =
    tempDbData[
      "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment"
    ];

  const ros_endocrineIsEndocrineRecommendationsotherEndoComment =
    tempDbData["ros_endocrineIsEndocrineRecommendationsotherEndoComment"];

  const ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment =
    tempDbData[
      "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment"
    ];

  const ros_diagnosisOfCancerRecommendationsotherCancerComment =
    tempDbData["ros_diagnosisOfCancerRecommendationsotherCancerComment"];

  const ros_diagnosisOfCancerspecialTypes =
    tempDbData["ros_diagnosisOfCancerSubSpecTyVal"];

  const ros_diagnosisOfCancerstageSpecificToTheCancer =
    tempDbData["ros_diagnosisOfCancerSubStageSpecCanVal"];

  const ros_diagnosisOfCancerros_activeTreatment = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubActiveTreatVal"],
  );

  const ros_diagnosisOfCancerros_Metastasis = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubMetastasisVal"],
  );

  const ros_diagnosisOfCancerros_SeeASpec = getOptionsSelectedYesNo(
    tempDbData["ros_diagnosisOfCancerSubSeeASpecVal"],
  );
  const ros_diagnosisOfCancerSubSeeASpecProVal =
    tempDbData["ros_diagnosisOfCancerSubSeeASpecProVal"];

  const ros_diagnosisOfCancerSubDescComment =
    tempDbData["ros_diagnosisOfCancerSubDescComment"];

  const ros_diagnosisOfCancerSubSupComment =
    tempDbData["ros_diagnosisOfCancerSubSupComment"];

  const ros_diagnosisOfCancerSubTypeComment =
    tempDbData["ros_diagnosisOfCancerSubTypeComment"];

  const ros_diagnosisOfCancerSubSideEffComment =
    tempDbData["ros_diagnosisOfCancerSubSideEffComment"];

  const ros_diagnosisOfCancerSubActiveTreatComment =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatComment"];

  const ros_diagnosisOfCancerros_SeeASpecComment =
    tempDbData["ros_diagnosisOfCancerros_SeeASpecComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    tempDbData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_diagnosisOfCancerros_MetastasisComment =
    tempDbData["ros_diagnosisOfCancerros_MetastasisComment"];

  const ros_diagnosisOfCancerros_activeTreatmentComment =
    tempDbData["ros_diagnosisOfCancerros_activeTreatmentComment"];

  const ros_diagnosisOfCancerSubMetastasisLocVal =
    tempDbData["ros_diagnosisOfCancerSubMetastasisLocVal"];

  const ros_cardio = getOptionsSelectedYesNo(tempDbData["ros_cardioVal"]);

  const ros_cardioComment = tempDbData["ros_cardioComment"];

  const ros_cardiovascularRecommendations = getSelectedCheckboxes(
    [
      tempDbData["ros_takemedicationsasprescribedCardioVal"],
      tempDbData["ros_otherCardioVal"],
      tempDbData["ros_ThirtyCardioVal"],
      tempDbData["ros_bloodPreCardioVal"],
      tempDbData["ros_heartHeCardioVal"],
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
    ],
  );

  // const ros_cardioSubDiagnoses =tempDbData["ros_cardioSubDiagnoses"]
  const ros_cardioSubDiagnosesComment =
    tempDbData["ros_cardioSubDiagnosesComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];

  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmSupComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmSupComment"];

  const ros_cardioSubDiagnosesAnginaCrDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    tempDbData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    tempDbData["ros_cardioSubDiagnosesAnginaDesComment"];
  const ros_endoVal = getOptionsSelectedYesNo(tempDbData["ros_endoVal"]);
  const ros_gastro = getOptionsSelectedYesNo(tempDbData["ros_gastro"]);
  const ros_genitoVal = getOptionsSelectedYesNo(tempDbData["ros_genitoVal"]);

  const ros_endoValComment = tempDbData["ros_endoValComment"];
  const ros_gastroComment = tempDbData["ros_gastroComment"];
  const ros_genitoValComment = tempDbData["ros_genitoValComment"];

  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];

  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    tempDbData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    tempDbData["ros_gastroSubDiagnosesdesbowelObstructionComment"];

  const ros_gastroSubDiagnosescachexiadesComment =
    tempDbData["ros_gastroSubDiagnosescachexiadesComment"];

  const ros_gastroSubDiagnosescachexiaSupComment =
    tempDbData["ros_gastroSubDiagnosescachexiaSupComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGlueten = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );

  const ros_cardioSubDiagnosesAneurysmDesComment =
    tempDbData["ros_cardioSubDiagnosesAneurysmDesComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];
  const ros_gastroSubDiagnosescirrhosisEndStage = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStageComment"];

  const ros_gastroSubDiagnosescirrhosisSupComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosesdescirrhosisComment =
    tempDbData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescolonCrDesComment =
    tempDbData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    tempDbData["ros_gastroSubDiagnosescolonPolypsDesComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    tempDbData["ros_gastroSubDiagnosescolonSupComment"];

  const ros_gastroSubDiagnosesdesdiverticulitisComment =
    tempDbData["ros_gastroSubDiagnosesdesdiverticulitisComment"];
  const ros_gastroSubDiagnosesdiverticulitisabscessVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisabscessValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisabscessValComment"];

  const ros_gastroSubDiagnosesdiverticulitisperforationVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisperforationValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisperforationValComment"];

  const ros_gastroSubDiagnosesdiverticulitishighFiberDietVal =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"
    ];

  const ros_diagnosisOfCancerRecommendationsComment =
    tempDbData["ros_diagnosisOfCancerRecommendationsComment"];
  const ros_cardiovascularRecommendationsComment =
    tempDbData["ros_cardiovascularRecommendationsComment"];

  const ros_endocrineIsEndocrineRecommendationsComment =
    tempDbData["ros_endocrineIsEndocrineRecommendationsComment"];

  const ros_cardioSubDiagnosesAneurysmSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAneurysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAnginaSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAnginaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupComment =
    tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesOtherSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesOtherSupotherSubAnswer"];

  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );

  const ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers =
    tempDbData[
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers"
    ];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment"
    ];
  const ros_cardioSubDiagnosesCardiomyopathyDesComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathyDesComment"];
  const ros_cardioSubDiagnosesCardiomyopathyTypeComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathyTypeComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupComment =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathySupComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    tempDbData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureSupComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesComment =
    tempDbData["ros_cardioSubDiagnosesCongestiveHeartFailureDesComment"];

  const ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisDesComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisSupComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment =
    tempDbData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment"
    ];
  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );
  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    tempDbData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];

  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaDesComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupComment =
    tempDbData["ros_cardioSubDiagnosesHyperlipidemiaSupComment"];
  const ros_cardioSubDiagnosesHypertensionSupComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionSupComment"];
  const ros_cardioSubDiagnosesHypertensionDesComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionDesComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
    tempDbData["ros_cardioSubDiagnosesHypertensionadequatelyControlledComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );

  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment"];
  const ros_cardioSubDiagnosesMyocardialDesComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialDesComment"];
  const ros_cardioSubDiagnosesMyocardialSupComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialSupComment"];
  const ros_cardioSubDiagnosesMyocardialSupotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesMyocardialSupotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialDate =
    tempDbData["ros_cardioSubDiagnosesMyocardialDate"];
  const ros_cardioSubDiagnosesMyocardialBetaBlocker = getOptionsSelectedYesNo(
    tempDbData["ros_cardioSubDiagnosesMyocardialBetaBlocker"],
  );
  const ros_cardioSubDiagnosesMyocardialBetaBlockerComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialBetaBlockerComment"];
  const ros_cardioSubDiagnosesMyocardialIspateintComment =
    tempDbData["ros_cardioSubDiagnosesMyocardialIspateintComment"];
  const ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
    tempDbData["ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment"];
  const ros_cardiohistoryDiabetesisHistoryDiabetesComment =
    tempDbData["ros_cardiohistoryDiabetesisHistoryDiabetesComment"];
  const ros_cardiopvdDescribeComment =
    tempDbData["ros_cardiopvdDescribeComment"];
  const ros_cardiohistoryDiabetesisHistoryDiabetes = getOptionsSelectedYesNo(
    tempDbData["ros_cardiohistoryDiabetesisHistoryDiabetes"],
  );
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
    tempDbData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer"
    ];

  const ros_cardioSubDiagnosesPulmonaryHypertensionSupComment =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionSupComment"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesComment =
    tempDbData["ros_cardioSubDiagnosesPulmonaryHypertensionDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacement =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacement"],
    );
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement"];
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation"],
    );
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
    tempDbData[
      "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseSupComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseSupComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseDesComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseDesComment"];
  const ros_cardioSubDiagnosesOtherOtherSub =
    tempDbData["ros_cardioSubDiagnosesOtherOtherSub"];
  const ros_cardioSubDiagnosesOtherSupComment =
    tempDbData["ros_cardioSubDiagnosesOtherSupComment"];
  const ros_cardioSubDiagnosesOtherDesComment =
    tempDbData["ros_cardioSubDiagnosesOtherDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseCrDesComment =
    tempDbData["ros_cardioSubDiagnosesvalvulardiseaseCrDesComment"];

  const ros_cardiovascularRecommendationsheartHealthyDietComment =
    tempDbData["ros_cardiovascularRecommendationsheartHealthyDietComment"];
  const ros_cardiovascularRecommendationsbloodPressureChecksComment =
    tempDbData["ros_cardiovascularRecommendationsbloodPressureChecksComment"];
  const ros_cardiovascularRecommendationsexerciseThirtyMinADayComment =
    tempDbData["ros_cardiovascularRecommendationsexerciseThirtyMinADayComment"];
  const ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment =
    tempDbData[
      "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment"
    ];
  const ros_cardiovascularRecommendationsotherComment =
    tempDbData["ros_cardiovascularRecommendationsotherComment"];
  const ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescolonOtherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosescolonOtherSubAnswerVal"];
  const ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgerdSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgerdSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal"];
  const ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal"
    ];
  const ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesotherSupotherSubAnswerVal =
    tempDbData["ros_gastroSubDiagnosesotherSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
    tempDbData["ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment"];
  const ros_gastroSubDiagnosesdiverticulitisSupComment =
    tempDbData["ros_gastroSubDiagnosesdiverticulitisSupComment"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupComment =
    tempDbData["ros_gastroSubDiagnosesgallBladderDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesgallBladderDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesdesgallBladderDiseaseComment"];
  const ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesgastroparesisSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesgastroparesisSupportedByComment"];
  const ros_gastroSubDiagnosesgastroparesisDescribeComment =
    tempDbData["ros_gastroSubDiagnosesgastroparesisDescribeComment"];
  const ros_gastroSubDiagnosesgerdDescribeComment =
    tempDbData["ros_gastroSubDiagnosesgerdDescribeComment"];
  const ros_gastroSubDiagnosesgerdSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesgerdSupportedByComment"];
  const ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedDescribeComment =
    tempDbData["ros_gastroSubDiagnosesglBleedDescribeComment"];
  const ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesglBleedSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesglBleedSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDescribeComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisDescribeComment"];
  const ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnoseshepatitisSupportedByComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisSupportedByComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
    tempDbData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherDescribeComment =
    tempDbData["ros_gastroSubDiagnosesotherDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisDescribeComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesotherSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesotherSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesulcerDiseaseDescribeComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDessComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisDessComment"];
  const ros_gastroSubDiagnoseshepatitisTypeComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisTypeComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosis = getOptionsSelectedYesNo(
    tempDbData["ros_gastroSubDiagnoseshepatitisCirrhosis"],
  );

  const ros_gastroSubDiagnoseshepatitisCirrhosisComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisCirrhosisComment"];

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma"],
    );

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
    tempDbData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet"],
    );
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
    tempDbData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment"
    ];

  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodes =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodes"],
    );

  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment"];

  const ros_gastroSubDiagnosespancreatitisSpecificDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosespancreatitisSpecificDiet"],
    );

  const ros_gastroSubDiagnosespancreatitisSpecificDietComment =
    tempDbData["ros_gastroSubDiagnosespancreatitisSpecificDietComment"];

  const ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesulcerDiseaseComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment =
    tempDbData["ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactria =
    getOptionsSelectedYesNo(
      tempDbData["ros_gastroSubDiagnosesulcerDiseaseHeliobactria"],
    );
  const ros_gastroSubDiagnosesotherOther =
    tempDbData["ros_gastroSubDiagnosesotherOther"];

  const ros_genito = getOptionsSelectedYesNo(tempDbData["ros_genito"]);
  const ros_genitoComment = tempDbData["ros_genitoComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal"];
  const ros_genitoSubDiagnosesacuteRenalFailureEtiology =
    tempDbData["ros_genitoSubDiagnosesacuteRenalFailureEtiology"];
  const ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesbphSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesbphSupportedByComment"];
  const ros_genitoSubDiagnosesbphDescribeComment =
    tempDbData["ros_genitoSubDiagnosesbphDescribeComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
    tempDbData["ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment =
    tempDbData["ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer =
    tempDbData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes"
      ],
    );
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment =
    tempDbData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment"
    ];

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension"
      ],
    );

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
    tempDbData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment"
    ];
  const ros_genitoSubDiagnoseswhatStageComment =
    tempDbData["ros_genitoSubDiagnoseswhatStageComment"];
  const ros_genitoSubDiagnosesesrdDescribeComment =
    tempDbData["ros_genitoSubDiagnosesesrdDescribeComment"];
  const ros_genitoSubDiagnosesesrdSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesesrdSupportedByComment"];
  const ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisComment =
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysisComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
    tempDbData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet =
    getOptionsSelectedYesNo(
      tempDbData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet"],
    );
  const ros_genitoSubDiagnosesesrdPatientOnDialysis = getOptionsSelectedYesNo(
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysis"],
  );
  const ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment =
    tempDbData["ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment"];
  const ros_genitoSubDiagnosesesrddialysisSchedule =
    tempDbData["ros_genitoSubDiagnosesesrddialysisSchedule"];
  const ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
    tempDbData["ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice"];
  const ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
    tempDbData["ros_genitoSubDiagnoseserectileDysfunctionDescribeComment"];
  const ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer =
    tempDbData[
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment =
    tempDbData["ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment"];

  const ros_genitoSubDiagnosesfrequentUtiDescribeComment =
    tempDbData["ros_genitoSubDiagnosesfrequentUtiDescribeComment"];
  const ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesfrequentUtiSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesfrequentUtiSupportedByComment"];

  const ros_genitoSubDiagnosesgynecologicalDescribeComment =
    tempDbData["ros_genitoSubDiagnosesgynecologicalDescribeComment"];
  const ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesgynecologicalSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesgynecologicalSupportedByComment"];

  const ros_genitoSubDiagnoseskidneyStonesDescribeComment =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesDescribeComment"];
  const ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnoseskidneyStonesSupportedByComment =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesSupportedByComment"];
  const ros_genitoSubDiagnoseskidneyStonesTypeComment =
    tempDbData["ros_genitoSubDiagnoseskidneyStonesTypeComment"];

  const ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
    tempDbData["ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment"];
  const ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
    tempDbData[
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment"];

  const ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
    tempDbData["ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment"];
  const ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
    tempDbData[
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment"];

  const ros_genitoSubDiagnosesotherDescribeComment =
    tempDbData["ros_genitoSubDiagnosesotherDescribeComment"];
  const ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer =
    tempDbData["ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesotherSupportedByComment =
    tempDbData["ros_genitoSubDiagnosesotherSupportedByComment"];
  const ros_genitoSubDiagnosesotherOther =
    tempDbData["ros_genitoSubDiagnosesotherOther"];
  const ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
    tempDbData["ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment"];
  const ros_genitoStress = getOptionsSelectedYesNo(
    tempDbData["ros_genitoStress"],
  );
  const ros_genitoStressComment = tempDbData["ros_genitoStressComment"];
  const ros_genitoStressSubComment = tempDbData["ros_genitoStressSubComment"];

  const ros_endoComment = tempDbData["ros_endoComment"];
  const ros_endocrineIsEndocrine = getOptionsSelectedYesNo(
    tempDbData["ros_endocrineIsEndocrine"],
  );
  const ros_endocrineIsEndocrineComment =
    tempDbData["ros_endocrineIsEndocrineComment"];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment"];
  const ros_genitoRecommendationsotherGenitoComment =
    tempDbData["ros_genitoRecommendationsotherGenitoComment"];
  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    tempDbData[
      "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment"
    ];
  const ros_diagnosisOfCancerSubSupOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubSupOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer"];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment =
    tempDbData[
      "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin"
      ],
    );
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment =
    tempDbData[
      "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment"
    ];
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin"
      ],
    );
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment =
    tempDbData[
      "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubtypeComment =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubtypeComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"];
  const ros_endocrineIsEndocrineSubdiabetesSubandDate =
    tempDbData["ros_endocrineIsEndocrineSubdiabetesSubandDate"];
  const ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician"
      ],
    );
  const ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator"
      ],
    );
  const ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
      ],
    );
  const ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease =
    getOptionsSelectedYesNoUnknownOther(
      tempDbData[
        "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease"
      ],
    );
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
    tempDbData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherDescribeComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherDescribeComment"];
  const ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer"];
  const ros_endocrineIsEndocrineDiagnoseotherSupComment =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherOther =
    tempDbData["ros_endocrineIsEndocrineDiagnoseotherOther"];

  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
    tempDbData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment"
    ];

  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
    getOptionsSelectedYesNo(
      tempDbData["ros_cardioSubDiagnosesCardiompathysecondaryToHypertension"],
    );
  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
    tempDbData[
      "ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial =
    getOptionsSelectedYesNo(
      tempDbData[
        "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial"
      ],
    );
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment =
    tempDbData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment"
    ];

  const ros_diagnosisOfCancerSubTypeOtherValSubAnswer =
    tempDbData["ros_diagnosisOfCancerSubTypeOtherValSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockDesComment =
    tempDbData["ros_cardioSubDiagnosesCardiorespiratoryShockDesComment"];
  tempDbData = {
    ...tempData,
    ros_respiratoryAcutePulmonarySubOtherSubDesc,
    ros_musculoskeletalMuskoHadAnAmputationComment,
    ros_respiratoryAcuteUpperSubOtherSubDesc,
    ros_respiratoryAsthmaSubOtherSubDesc,
    ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment,
    ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc,
    ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc,
    ros_respiratoryChronicSputumFailureSubOtherSubDesc,
    ros_respiratoryCopdSubOtherSubDesc,
    ros_respiratoryCopdSubPatientBronchodilatorComment,
    ros_respiratoryCopdSubPatientSteroidsComment,
    ros_respiratoryCopdSubPatientExacerbationComment,
    ros_respiratoryCysticFibrosisSubDesc,
    ros_respiratoryHypoventilationSubDesc,
    ros_respiratoryHypoxemiaSubDesc,
    ros_respiratoryPneumoniaSubDesc,
    ros_respiratoryPulmonaryFibrosisSubDesc,
    ros_respiratoryRespiratorDependenceSubDesc,
    ros_respiratoryRespiratoryArrestSubDesc,
    ros_respiratorySarcoidosisSubDesc,
    ros_musculoskeletalExtremityFractureSubOtherSubDesc,
    ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment,
    ros_hematologyVitaminDDeficiencySubAnswerDescribeComment,
    ros_respiratorySleepApneaSubDesc,
    ros_respiratoryOtherSubDesc,
    ros_integumentEczemaSubOtherSubDesc,
    ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment,
    ros_integumentDermatitisSubOtherSubDesc,
    ros_integumentBasilCellCarcinomaSubOtherSubDesc,
    ros_integumentOnychomycosisSubOtherSubDesc,
    ros_integumentPsoriasisSubOtherSubDesc,
    ros_integumentSkinUlcerSubOtherSubDesc,
    ros_integumentSkinUlcerSubEtiologySubDesc,
    ros_integumentTineaPedisSubOtherSubDesc,
    ros_integumentUrticarialDiseaseSubOtherSubDesc,
    ros_integumentWoundSubOtherSubDesc,
    ros_integumentIntegumentOtherSubOtherSubDesc,
    ros_hematologyAidsSubOtherSubDesc,
    ros_hematologyAidsSubPatientUnderTreatmentSubWhere,
    ros_hematologyAnemiaSubEtiologySubOtherSubDesc,
    ros_hematologyAnemiaSubotherSubDesc,
    ros_hematologyCDifficileSubOtherSubDesc,
    ros_hematologyAnemiaSubYesPatientOnSubDesc,
    ros_hematologyCommunityAcquiredSubOtherSubDesc,
    ros_hematologyHivSubOtherSubDesc,
    ros_hematologyHerpesZosterSubOtherSubDesc,
    ros_hematologyMrsaInfectionSubOtherSubDesc,
    ros_hematologyImmuneDeficiencySubOtherSubDesc,
    ros_hematologySickleCellDiseaseSubDesc,
    ros_hematologySepsisSubOtherSubDesc,
    ros_hematologySickleCellTraitSubOtherSubDesc,
    ros_hematologyThalassemiaSubOtherSubDesc,
    ros_hematologyThrombocytopeniaSubOtherSubDesc,
    ros_hematologyTuberculosisSubOtherSubDesc,
    ros_hematologyVitaminDDeficiencySubOtherSubDesc,
    ros_hematologyHemaOtherSubOtherSubDesc,
    ros_musculoskeletalCollagenSubOtherSubDesc,
    ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc,
    ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc,
    ros_musculoskeletalHalluxValgusSubOtherSubDesc,
    ros_musculoskeletalGoutSubOtherSubDesc,
    ros_musculoskeletalHammerToesSubOtherSubDesc,
    ros_musculoskeletalOsteoarthritisSubOtherSubDesc,
    ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment,
    ros_musculoskeletalOsteomyelitisSubOtherSubDesc,
    ros_musculoskeletalOsteoporosisSubOtherSubDesc,
    ros_musculoskeletalPyogenicArthritisSubOtherSubDesc,
    ros_musculoskeletalRheumatoidArthritisSubDesc,
    // ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms,
    ros_musculoskeletalSpinalStenosisSubOtherSubDesc,
    ros_musculoskeletalSystemicLupusSubOtherSubDesc,
    ros_musculoskeletalMuskoSubOtherSubDesc,
    ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment,
    ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment,
    ros_musculoskeletalExtremityFractureSubOther,
    ros_musculoskeletalExtremityFractureShoulderSubAnswerComment,
    ros_musculoskeletalExtremityFractureForearmSubAnswerComment,
    ros_musculoskeletalExtremityFractureArmSubAnswerComment,
    ros_musculoskeletalHandSubAnswerComment,
    ros_musculoskeletalExtremityFractureWristSubAnswerComment,
    ros_musculoskeletalExtremityFractureTibiaSubAnswerComment,
    ros_musculoskeletalFemoralShaftSubAnswerComment,
    ros_musculoskeletalExtremityFractureFibulaSubAnswerComment,
    ros_musculoskeletalExtremityFractureAnkleSubAnswerComment,
    ros_musculoskeletalExtremityFractureFootSubAnswerComment,
    ros_musculoskeletalExtremityFractureHipSubAnswerComment,
    ros_eyes,
    ros_eyesComment,
    ros_eyesDiagComment,
    ros_eyesDiagCataractSubWhEyeComment,
    ros_eyesDiagCataractSubDescComment,
    ros_eyesDiagCataractSubSuppComment,
    ros_eyesDiagCataractSubSuppSympSubComment,
    ros_eyesDiagCataractSubSuppOtherSubDesc,
    ros_eyesDiagCataractSubDiabetes,
    ros_eyesDiagCataractSubDiabetesComment,
    ros_eyesDiagGlaucomaSubWhEyeComment,
    ros_eyesDiagGlaucomaSubDescComment,
    ros_eyesDiagGlaucomaSubSuppComment,
    ros_eyesDiagGlaucomaSubSuppSympSubComment,
    ros_eyesDiagGlaucomaSubSuppOtherSubDesc,
    ros_eyesDiagGlaucomaSubDiabetes,
    ros_eyesDiagGlaucomaSubDiabetesComment,
    ros_eyesDiagGlaucomaSubDiabetesSubComment,
    ros_eyesDiagHyperopiaSubWhEyeComment,
    ros_eyesDiagHyperopiaSubDescComment,
    ros_eyesDiagHyperopiaSubSuppComment,
    ros_eyesDiagHyperopiaSubSuppSympSubComment,
    ros_eyesDiagHyperopiaSubSuppOtherSubDesc,
    ros_eyesDiagLegalBlindSubWhEyeComment,
    ros_eyesDiagLegalBlindSubDescComment,
    ros_eyesDiagLegalBlindSubSuppComment,
    ros_eyesDiagLegalBlindSubSuppSympSubComment,
    ros_eyesDiagLegalBlindSubSuppOtherSubDesc,
    ros_eyesDiagLegalBlindSubComment,
    ros_eyesDiagLegalBlindSubDescText,
    ros_eyesDiagMascDegenSubWhEyeComment,
    ros_eyesDiagMascDegenSubDescComment,
    ros_eyesDiagMascDegenSubSuppComment,
    ros_eyesDiagMascDegenSubSuppSympSubComment,
    ros_eyesDiagMascDegenSubSuppOtherSubDesc,
    ros_eyesDiagMascDegenSubComment,
    ros_eyesDiagMascDegenSubDesc2Comment,
    ros_eyesDiagMyopiaSubWhEyeComment,
    ros_eyesDiagMyopiaSubDescComment,
    ros_eyesDiagMyopiaSubSuppComment,
    ros_eyesDiagMyopiaSubSuppSympSubComment,
    ros_eyesDiagMyopiaSubSuppOtherSubDesc,
    ros_eyesDiagRetinalSubWhEyeComment,
    ros_eyesDiagRetinalSubDescComment,
    ros_eyesDiagRetinalSubSuppComment,
    ros_eyesDiagRetinalSubSuppSympSubComment,
    ros_eyesDiagRetinalSubSuppOtherSubDesc,
    ros_eyesDiagRetinalSubDiabetes,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemm,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemmComment,
    ros_eyesDiagRetinalSubDiabetesComment,
    ros_eyesDiagOtherSubWhEyeComment,
    ros_eyesDiagOtherSubDescComment,
    ros_eyesDiagOtherSubSuppComment,
    ros_eyesDiagOtherSubSuppSympSubComment,
    ros_eyesDiagOtherSubSuppOtherSubDesc,
    ros_eyesDiagOtherSubOther,
    ros_ears,
    ros_earsComment,
    ros_earsDiagnosesComment,
    ros_earsDiagDiffHearSubDescComment,
    ros_earsDiagDiffHearSubSuppOtherSubDesc,
    ros_earsDiagDiffHearSubSuppComment,
    ros_earsDiagLegalDeafSubDescComment,
    ros_earsDiagLegalDeafSubSuppOtherSubDesc,
    ros_earsDiagLegalDeafSubSuppComment,
    ros_earsDiagTinnitusSubDescComment,
    ros_earsDiagTinnitusSubSuppOtherSubDesc,
    ros_earsDiagTinnitusSubSuppComment,
    ros_earsDiagVertigoSubDescComment,
    ros_earsDiagVertigoSubSuppOtherSubDesc,
    ros_earsDiagVertigoSubSuppComment,
    ros_earsDiagVertigoSubLoseBal,
    ros_earsDiagVertigoSubLoseBalComment,
    ros_earsDiagOtherSubDescComment,
    ros_earsDiagOtherSubSuppOtherSubDesc,
    ros_earsDiagOtherSubSuppComment,
    ros_earsDiagOtherSubOther,
    ros_nose,
    ros_noseDiagnosesComment,
    ros_noseComment,
    ros_noseDiagAllergySubDescComment,
    ros_noseDiagChronicNasalSubDescComment,
    ros_noseDiagSeasonAllergySubDescComment,
    ros_noseDiagSinusInfectSubDescComment,
    ros_noseDiagSinustisSubDescComment,
    ros_noseDiagOtherSubDescComment,
    ros_noseDiagAllergySubSuppOtherSubDesc,
    ros_noseDiagAllergySubSuppComment,
    ros_noseDiagChronicNasalSubSuppOtherSubDesc,
    ros_noseDiagChronicNasalSubSuppComment,
    ros_noseDiagNoseBleedSubComment,
    ros_noseDiagSeasonAllergySubSuppOtherSubDesc,
    ros_noseDiagSeasonAllergySubSuppComment,
    ros_noseDiagSinusInfectSubSuppOtherSubDesc,
    ros_noseDiagSinusInfectSubSuppComment,
    ros_noseDiagSinusInfectSubExuComment,
    ros_noseDiagSinustisSubSuppOtherSubDesc,
    ros_noseDiagSinustisSubSuppComment,
    ros_noseDiagOtherSubSuppOtherSubDesc,
    ros_noseDiagOtherSubSuppComment,
    ros_noseDiagOtherSubOther,
    ros_mouth,
    ros_mouthDiagComment,
    ros_mouthComment,
    ros_mouthDiagBleedGumsSubComment,
    ros_mouthDiagDiffChewSubDescComment,
    ros_mouthDiagDiffChewSubBcsPain,
    ros_mouthDiagDiffChewSubBcsPainSub,
    ros_mouthDiagDiffChewSubBcsPainSubComment,
    ros_mouthDiagDiffChewSubBcsPainComment,
    ros_mouthDiagDiffSwallowSubDescComment,
    ros_mouthDiagDiffSwallowSubHadStroke,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubComment,
    ros_mouthDiagDiffSwallowSubHadStrokeComment,
    ros_mouthDiagOtherSubDescComment,
    ros_mouthDiagOtherSubSuppOtherSubDesc,
    ros_mouthDiagOtherSubSuppComment,
    ros_mouthDiagOtherSubOther,
    ros_neck,
    ros_neckDiagComment,
    ros_neckComment,
    ros_neckDiagCarotidSubDescComment,
    ros_neckDiagCarotidSubSuppOtherSubDesc,
    ros_neckDiagCarotidSubSuppComment,
    ros_neckDiagCarotidSubCsDescComment,
    ros_neckDiagParotidSubSuppOtherSubDesc,
    ros_neckDiagParotidSubSuppComment,
    ros_neckDiagParotidSubDescComment,
    ros_neckDiagOtherSubDescComment,
    ros_neckDiagOtherSubSuppOtherSubDesc,
    ros_neckDiagOtherSubSuppComment,
    ros_neckDiagOtherSubOther,
    ros_eyeENTNeckRecommendations,
    ros_eyeENTNeckRecommendationshearingEvaluationComment,
    ros_eyeENTNeckRecommendationsdentalExamComment,
    ros_eyeENTNeckRecommendationseyeExamComment,
    ros_eyeENTNeckRecommendationsswallowingEvaluationComment,
    ros_eyeENTNeckRecommendationstakeMedicationsComment,
    ros_eyeENTNeckRecommendationsotherComment,
    ros_bowelMoveAbnormSubDiarrSubIshis,
    ros_bowelMoveAbnormSubDiarrSubIshisComment,
    ros_bowelMoveComment,
    ros_bowelMoveAbnormSubComment,
    ros_bowelMoveAbnormSubConstSubIfConstComment,
    ros_bowelMoveAbnormSubDiarrSubComment,
    ros_abdominal,
    ros_abdominalIsyesComment,
    ros_abdominalComment,
    ros_rectal,
    ros_rectalIsrecIsyesmalComment,
    ros_rectalIsrecIsyesfemComment,
    ros_rectalComment,
    ros_lastBowelComment,
    ros_meetWithComment,
    ros_gpcogScoIsgpcogs,
    ros_gpcogScoIsmmsesc,
    ros_gpcogScoComment,
    ros_noGpcogMmsePatientOrientPerson,
    ros_noGpcogMmsePatientOrientPlace,
    ros_noGpcogMmsePatientOrientTime,
    ros_noGpcogMmsePatientDescribeNews,
    ros_noGpcogMmseRecallComment,
    ros_noGpcogMmsePatientDescribeNewsComment,
    ros_noGpcogMmsePatientOrientPersonComment,
    ros_noGpcogMmsePatientOrientPlaceComment,
    ros_noGpcogMmsePatientOrientTimeComment,
    ros_speechComment,
    ros_fingerToNoseAbnormalSubComment,
    ros_fingerToNoseComment,
    ros_heelToToeAbnormalSubComment,
    ros_heelToToeComment,
    ros_thumbToFingerAbnormalSubComment,
    ros_thumbToFingerComment,
    ros_sitToStandComment,
    ros_extremeMoveComment,
    ros_affectComment,
    ros_affectAbnormalSubDescComment,
    ros_over2WeeksLittleIntrstComment,
    ros_over2WeeksFeelDepressedComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment,
    ros_over2WeeksPhq2Score,
    ros_over2WeeksPhq9Score,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere,
    ros_neuroPsych,
    ros_neuroPsychComment,
    ros_nervousAnxious,
    ros_nervousAnxiousComment,
    ros_worryMuch,
    ros_worryMuchComment,
    ros_feelAfraid,
    ros_feelAfraidComment,
    ros_neuroPsychHistoryAudio,
    ros_neuroPsychHistoryAudioComment,
    ros_neuroPsychHistoryVisual,
    ros_neuroPsychHistoryVisualComment,
    ros_neuroPsychBehaviour,
    ros_neuroPsychBehaviourComment,
    ros_neuroPsychDelirium,
    ros_neuroPsychDeliriumComment,
    ros_neuroPsychHearVoices,
    ros_neuroPsychHearVoicesComment,
    ros_neuroPsychHighLows,
    ros_neuroPsychHighLowsComment,
    ros_neuroPsychEverFeel,
    ros_neuroPsychEverFeelComment,
    ros_neuroPsychSubDiagComment,
    ros_neuroPsychSubDiagAlcoholSubDescComment,
    ros_neuroPsychSubDiagAlcoholSubTypeComment,
    ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAlcoholSubSuppComment,
    ros_neuroPsychSubDiagAlcoholSubDelirium,
    ros_neuroPsychSubDiagAlcoholSubDeliriumComment,
    ros_neuroPsychSubDiagAlcoholSubPsych,
    ros_neuroPsychSubDiagAlcoholSubPsychComment,
    ros_neuroPsychSubDiagAmyotropSubDescComment,
    ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAmyotropSubSuppComment,
    ros_neuroPsychSubDiagAnxietySubDescComment,
    ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc,
    ros_neuroPsychSubDiagAnxietySubTypeComment,
    ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagAnxietySubSuppComment,
    ros_neuroPsychSubDiagBipolarSubDescComment,
    ros_neuroPsychSubDiagBipolarSubTypeComment,
    ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagBipolarSubSuppComment,
    ros_neuroPsychSubDiagCerebHemmSubDescComment,
    ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubComment,
    ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagCerebHemmSubSuppComment,
    ros_neuroPsychSubDiagDementiaSubDescComment,
    ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDementiaSubSuppComment,
    ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeComment,
    ros_neuroPsychSubDiagDementiaSubTypeVascComment,
    ros_neuroPsychSubDiagDementiaSubTypeComment,
    ros_neuroPsychSubDiagDrugsSubHistoryPsych,
    ros_neuroPsychSubDiagDrugsSubDescComment,
    ros_neuroPsychSubDiagDrugsSubTypeComment,
    ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDrugsSubSuppComment,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychComment,
    ros_neuroPsychSubDiagDrugsSubWhatDrugs,
    ros_neuroPsychSubDiagDepressSubMajor,
    ros_neuroPsychSubDiagDepressSubDescComment,
    ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDepressSubSuppComment,
    ros_neuroPsychSubDiagDepressSubMajorComment,
    ros_neuroPsychSubDiagDepressSubEpisodeComment,
    ros_neuroPsychSubDiagHemiSubDescComment,
    ros_neuroPsychSubDiagHemiSubHemiDescComment,
    ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagHemiSubSuppComment,
    ros_neuroPsychSubDiagIntelSubDescComment,
    ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagIntelSubSuppComment,
    ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc,
    ros_neuroPsychSubDiagIntelSubIntelDescComment,
    ros_neuroPsychSubDiagSeizureSubTreatment,
    ros_neuroPsychSubDiagSeizureSubDescComment,
    ros_neuroPsychSubDiagSeizureSubTypeComment,
    ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSeizureSubSuppComment,
    ros_neuroPsychSubDiagSpinalCordSubDescComment,
    ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSpinalCordSubSuppComment,
    ros_neuroPsychSubDiagSpinalCordSubSecToComment,
    ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubComment,
    ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubComment,
    ros_neuroPsychSubDiagStrokeSubDescComment,
    ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment,
    ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagStrokeSubSuppComment,
    ros_neuroPsychSubDiagSubduralSubDescComment,
    ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment,
    ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSubduralSubSuppComment,
    ros_neuroPsychSubDiagTraumaBrainSubDescComment,
    ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment,
    ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagTraumaBrainSubSuppComment,
    ros_neuroPsychSubDiagCerebPalsySubDescComment,
    ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagCerebPalsySubSuppComment,
    ros_neuroPsychSubDiagDelusionSubDescComment,
    ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDelusionSubSuppComment,
    ros_neuroPsychSubDiagFibroSubDescComment,
    ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagFibroSubSuppComment,
    ros_neuroPsychSubDiagGuillSubDescComment,
    ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagGuillSubSuppComment,
    ros_neuroPsychSubDiagHuntingSubDescComment,
    ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagHuntingSubSuppComment,
    ros_neuroPsychSubDiagInsomniaSubDescComment,
    ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagInsomniaSubSuppComment,
    ros_neuroPsychSubDiagMigraineSubDescComment,
    ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagMigraineSubSuppComment,
    ros_neuroPsychSubDiagSclerosisSubDescComment,
    ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSclerosisSubSuppComment,
    ros_neuroPsychSubDiagDystrophySubDescComment,
    ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc,
    ros_neuroPsychSubDiagDystrophySubSuppComment,
    ros_neuroPsychSubDiagGravisSubDescComment,
    ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagGravisSubSuppComment,
    ros_neuroPsychSubDiagParkinsonSubDescComment,
    ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagParkinsonSubSuppComment,
    ros_neuroPsychSubDiagPeriNeuroSubDescComment,
    ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagPeriNeuroSubSuppComment,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetes,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment,
    ros_neuroPsychSubDiagRestlessLegSubDescComment,
    ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagRestlessLegSubSuppComment,
    ros_neuroPsychSubDiagSchizoSubDescComment,
    ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagSchizoSubSuppComment,
    ros_neuroPsychSubDiagTiaSubDescComment,
    ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagTiaSubSuppComment,
    ros_neuroPsychSubDiagOtherSubDescComment,
    ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc,
    ros_neuroPsychSubDiagOtherSubSuppComment,
    ros_neuroPsychSubDiagOtherSubOther,

    gait_Recommendations,
    //gait_RecommendationsComment,
    gait_RecommendationstakeMedicationsAsPescribedComment,
    gait_RecommendationsotherComment,
    respiratory_Recommendations,
    // respiratory_RecommendationsComment,
    respiratory_RecommendationstakeMedicationsComment,
    respiratory_RecommendationsotherComment,

    integument_Recommendations,
    // integument_RecommendationsComment,
    integument_RecommendationstakeMedicationsAsPescribedComment,
    integument_RecommendationsotherComment,

    musko_Recommendations,
    // musko_RecommendationsComment,
    musko_RecommendationsdiscussPtOtComment,
    musko_RecommendationstakeMedicationsAsPescribedComment,
    musko_RecommendationsotherComment,

    hema_Recommendations,
    // hema_RecommendationsComment,
    hema_RecommendationstakeMedicationsAsPescribedComment,
    hema_RecommendationsreportAbnormalBruisingComment,
    hema_RecommendationsfollowUpWithDoctorComment,
    hema_RecommendationsotherComment,

    ros_gaitComment: ros_gaitComment || "",
    ros_respiratoryComment: ros_respiratoryComment || "",
    ros_respiratoryAcutePulmonarySubAnswerDescribeComment:
      ros_respiratoryAcutePulmonarySubAnswerDescribeComment || "",
    ros_respiratoryDiagnosesComment,
    ros_respiratoryAcutePulmonarySubAnswerSupportedByComment,
    ros_respiratoryAcuteUpperSubAnswerDescribeComment,
    ros_respiratoryAcuteUpperSubAnswerSupportedByComment,
    ros_respiratoryAsthmaSubAnswerDescribeComment,
    ros_respiratoryAsthmaSubAnswerSupportedByComment,
    ros_respiratoryAsthmaPatientControllerComment,
    ros_respiratoryAsthmaRescueMedicationsComment,
    ros_respiratoryAsthmaCurrentExacerbationComment,
    ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment,
    ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment,
    ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment,
    ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment,
    ros_respiratoryChronicSputumFailureSubAnswerDescribeComment,
    ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment,
    ros_respiratoryCopdSubAnswerDescribeComment,
    ros_respiratoryCopdSubAnswerSupportedByComment,
    ros_respiratoryPatientChronicBronchitisComment,
    ros_respiratoryPatientHaveEmphysemaComment,
    ros_respiratoryPatientBronchodilatorComment,
    ros_respiratoryPatientSteroidsComment,
    ros_respiratoryPatientExacerbationComment,
    ros_respiratoryCysticFibrosisSubAnswerDescribeComment,
    ros_respiratoryCysticFibrosisSubAnswerSupportedByComment,
    ros_respiratoryHypoventilationSubAnswerDescribeComment,
    ros_respiratoryHypoventilationSubAnswerSupportedByComment,
    ros_respiratoryHypoxemiaSubAnswerDescribeComment,
    ros_respiratoryHypoxemiaSubAnswerSupportedByComment,
    ros_respiratoryPneumoniaSubAnswerDescribeComment,
    ros_respiratoryPneumoniaSubAnswerSupportedByComment,
    ros_respiratoryPneumoniaSubAnswerEtiologyComment,
    ros_respiratoryHistoryOfLungAbscessComment,
    ros_respiratoryHistoryOfEmpyemaComment,
    ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment,
    ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment,
    ros_respiratoryRespiratorDependenceSubAnswerDescribeComment,
    ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment,
    ros_respiratoryRespiratoryArrestSubAnswerDescribeComment,
    ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment,
    ros_respiratorySarcoidosisSubAnswerDescribeComment,
    ros_respiratorySarcoidosisSubAnswerSupportedByComment,
    ros_respiratorySarcoidosisSubAnswerSpecifyComment,
    ros_respiratorySleepApneaSubAnswerDescribeComment,
    ros_respiratorySleepApneaSubAnswerSupportedByComment,
    ros_respiratoryRespOtherSubAnswerDescribeComment,
    ros_respiratoryRespOtherSubAnswerSupportedByComment,
    ros_respiratoryOtherSubAnswerOther,

    ros_integumentComment,
    ros_integumentDiagnosesComment,
    ros_integumentBasilCellCarcinomaSubAnswerDescribeComment,
    ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment,
    ros_integumentDermatitisSubAnswerDescribeComment,
    ros_integumentDermatitisSubAnswerSupportedByComment,
    ros_integumentDermatitisSubAnswerWhatTypeComment,
    ros_integumentEczemaSubAnswerDescribeComment,
    ros_integumentEczemaSubAnswerSupportedByComment,
    ros_integumentOnychomycosisSubAnswerDescribeComment,
    ros_integumentOnychomycosisSubAnswerSupportedByComment,
    ros_integumentPsoriasisSubAnswerDescribeComment,
    ros_integumentPsoriasisSubAnswerSupportedByComment,
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment,
    ros_integumentSkinUlcerSubAnswerDescribeComment,
    ros_integumentSkinUlcerSubAnswerSupportedByComment,
    ros_integumentSkinUlcerSubAnswerEtiologyComment,
    ros_integumentTineaPedisSubAnswerDescribeComment,
    ros_integumentTineaPedisSubAnswerSupportedByComment,
    ros_integumentUrticarialDiseaseSubAnswerDescribeComment,
    ros_integumentUrticarialDiseaseSubAnswerSupportedByComment,
    ros_integumentUrticarialDiseaseSubAnswerEtiology,
    ros_integumentUrticarialDiseaseSubAnswerTypeComment,
    ros_integumentWoundSubAnswerDescribeComment,
    ros_integumentWoundSubAnswerSupportedByComment,
    ros_integumentWoundSubAnswerEtiologyComment,
    ros_integumentOthersSubAnswerDescribeComment,
    ros_integumentOthersSubAnswerSupportedByComment,
    ros_integumentOthersSubAnswerOther,

    ros_musculoskeletalComment,
    ros_musculoskeletalDiagnosesComment,
    ros_musculoskeletalCollagenSubAnswerDescribeComment:
      ros_musculoskeletalCollagenSubAnswerDescribeComment || "",
    ros_musculoskeletalCollagenSubAnswerSupportedByComment:
      ros_musculoskeletalCollagenSubAnswerSupportedByComment || "",
    ros_musculoskeletalCollagenSubAnswercollagenDescribeComment:
      ros_musculoskeletalCollagenSubAnswercollagenDescribeComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment || "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment:
      ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment ||
      "",
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment,
    ros_musculoskeletalExtremityFractureSubAnswerDescribeComment,
    ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment,
    ros_musculoskeletalExtremityFractureSubAnswerExtremityComment,
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment,
    ros_musculoskeletalGoutSubAnswerDescribeComment,
    ros_musculoskeletalGoutSubAnswerSupportedByComment,
    ros_musculoskeletalHalluxValgusSubAnswerDescribeComment,
    ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment,
    ros_musculoskeletalHammerToesSubAnswerDescribeComment,
    ros_musculoskeletalHammerToesSubAnswerSupportedByComment,
    ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment,
    ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment,
    ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints,
    ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment,
    ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment,
    ros_musculoskeletalOsteoporosisSubAnswerDescribeComment,
    ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment,
    ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment,
    ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints,
    ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment,
    ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment,
    ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints,
    ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment,
    ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment,
    ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment,
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment,
    ros_musculoskeletalSystemicLupusSubAnswerDescribeComment,
    ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment,
    ros_musculoskeletalMuskoOtherSubAnswerDescribeComment,
    ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment,
    ros_musculoskeletalMuskoOtherSubAnswerother,
    ros_musculoskeletalHadAnAmputationSubAnswer,

    ros_hematologyComment,
    ros_hematologyDiagnosesComment,
    ros_hematologyAidsSubAnswerDescribeComment,
    ros_hematologyAidsSubAnswerSupportedByComment,
    ros_hematologyAidsSubAnswerPatientUnderTreatmentComment,
    ros_hematologyAnemiaSubAnswerDescribeComment,
    ros_hematologyAnemiaSubAnswerSupportedByComment,
    ros_hematologyAnemiaSubAnswerEtiologyComment,
    ros_hematologyAnemiaSubAnswerYesPatientOnComment,
    ros_hematologyCDifficileSubAnswerDescribeComment,
    ros_hematologyCDifficileSubAnswerSupportedByComment,
    ros_hematologyCommunityAcquiredSubAnswerDescribeComment,
    ros_hematologyCommunityAcquiredSubAnswerSupportedByComment,
    ros_hematologyHivSubAnswerDescribeComment,
    ros_hematologyHivSubAnswerSupportedByComment,
    ros_hematologyHivSubAnswerViralLoad,
    ros_hematologyHivSubAnswerCFour,
    ros_hematologyHivSubAnswerPatientSymptomaticComment,
    ros_hematologyHivSubAnswerActiveTreatmentComment,
    ros_hematologyHerpesZosterSubAnswerDescribeComment,
    ros_hematologyHerpesZosterSubAnswerSupportedByComment,
    ros_hematologyMrsaInfectionSubAnswerDescribeComment,
    ros_hematologyMrsaInfectionSubAnswerSupportedByComment,
    ros_hematologyImmuneDeficiencySubAnswerDescribeComment,
    ros_hematologyImmuneDeficiencySubAnswerEtiology,
    ros_hematologyImmuneDeficiencySubAnswerSupportedByComment,
    ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment,
    ros_hematologySepsisSubAnswerDescribeComment,
    ros_hematologySepsisSubAnswerSupportedByComment,
    ros_hematologySickleCellDiseaseSubAnswerDescribeComment,
    ros_hematologySickleCellDiseaseSubAnswerSupportedByComment,
    ros_hematologySickleCellTraitSubAnswerDescribeComment,
    ros_hematologySickleCellTraitSubAnswerSupportedByComment,
    ros_hematologyThalassemiaSubAnswerDescribeComment,
    ros_hematologyThalassemiaSubAnswerSupportedByComment,
    ros_hematologyThrombocytopeniaSubAnswerDescribeComment,
    ros_hematologyThrombocytopeniaSubAnswerEtiology,
    ros_hematologyThrombocytopeniaSubAnswerSupportedByComment,
    ros_hematologyTuberculosisSubAnswerDescribeComment,
    ros_hematologyTuberculosisSubAnswerSupportedByComment,
    ros_hematologyTuberculosisSubAnswerGivenBCGComment,
    ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment,
    ros_hematologyTuberculosisSubAnswerTbInfectionComment,
    ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment,
    ros_hematologyHemaOtherSubAnswerDescribeComment,
    ros_hematologyHemaOtherSubAnswerSupportedByComment,
    ros_hematologyHemaOtherSubAnswerOther,
    //-------------RESPIRATORY COMMENT--------

    //----------------------------------------------------------------//
    ros_respiratory,
    ros_musculoskeletal,
    ros_hematology,

    ros_respiratoryAsthmaPatientController,
    ros_respiratoryAsthmaRescueMedications,
    ros_respiratoryAsthmaCurrentExacerbation,

    ros_respiratoryPatientChronicBronchitis,
    ros_respiratoryPatientHaveEmphysema,
    ros_respiratoryPatientBronchodilator,
    ros_respiratoryPatientSteroids,
    ros_respiratoryPatientExacerbation,

    ros_respiratoryHistoryOfLungAbscess,
    ros_respiratoryHistoryOfEmpyema,

    ros_integument,

    ros_integumentPsoriasisSubAnswerPsoriaticArthritis,

    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder,

    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer,

    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder,

    ros_musculoskeletalMuskoHadAnAmputation,

    ros_hematologyAidsSubAnswerPatientUnderTreatment,

    ros_hematologyHivSubAnswerPatientSymptomatic,
    ros_hematologyHivSubAnswerActiveTreatment,

    ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic,

    ros_hematologyTuberculosisSubAnswerGivenBCG,
    ros_hematologyTuberculosisSubAnswerActiveTuberculosis,
    ros_hematologyTuberculosisSubAnswerTbInfection,

    ros_diagnosisOfCancer,
    ros_diagnosisOfCancerComment,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_endoValComment,
    ros_gastroComment,
    ros_genitoValComment,

    ros_cardio,
    ros_endoVal,
    ros_gastro,
    ros_genitoVal,

    // ros_cardioSubDiagnoses,

    ros_cardioComment,
    ros_cardioSubDiagnosesComment,

    ros_cardiovascularRecommendations: ros_cardiovascularRecommendations,

    ros_diagnosisOfCancerRecommendations: ros_diagnosisOfCancerRecommendations,
    ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment,
    ros_diagnosisOfCancerRecommendationsotherCancerComment,
    ros_diagnosisOfCancerspecialTypes: ros_diagnosisOfCancerspecialTypes,
    ros_diagnosisOfCancerstageSpecificToTheCancer:
      ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerros_activeTreatment,
    ros_diagnosisOfCancerros_Metastasis,
    ros_diagnosisOfCancerros_SeeASpec,
    ros_diagnosisOfCancerSubSeeASpecProVal,
    ros_diagnosisOfCancerSubMetastasisLocVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_diagnosisOfCancerros_activeTreatmentComment,
    ros_diagnosisOfCancerros_SeeASpecComment,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,
    ros_diagnosisOfCancerros_MetastasisComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,

    ros_endocrineIsEndocrineRecommendations:
      ros_endocrineIsEndocrineRecommendations,
    ros_genitoRecommendations: ros_genitoRecommendations,
    ros_gastrorecommendations: ros_gastrorecommendations,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,

    ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment,
    ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment,
    ros_endocrineIsEndocrineRecommendationsotherEndoComment,

    ros_gastrorecommendationsotherComment,
    ros_gastrorecommendationstakemedicationsasprescribedComment,
    ros_gastroSubDiagnosescachexiaSupComment,

    // ros_gastroSubDiagnosesComment
    ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_genitoSubDiagnosesComment,
    ros_endocrineIsEndocrineDiagnoseComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,

    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,

    ros_gastroSubDiagnosescolonSupComment,

    ros_genitoSubDiagnosesacuteRenalFailureEti,

    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_cardioSubDiagnosesAneurysmDesComment,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmSupComment,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    ros_diagnosisOfCancerRecommendationsComment,
    ros_cardiovascularRecommendationsComment,
    ros_endocrineIsEndocrineRecommendationsComment,
    ros_cardioSubDiagnosesAneurysmSupotherSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer,
    ros_cardioSubDiagnosesAnginaSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesOtherSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,
    ros_cardioSubDiagnosesCardiomyopathyDesComment,
    ros_cardioSubDiagnosesCardiomyopathyTypeComment,
    ros_cardioSubDiagnosesCardiomyopathySupComment,
    ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHyperlipidemiaDesComment,
    ros_cardioSubDiagnosesHyperlipidemiaSupComment,
    ros_cardioSubDiagnosesHypertensionSupComment,
    ros_cardioSubDiagnosesHypertensionDesComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlledComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment,
    ros_cardioSubDiagnosesMyocardialDesComment,
    ros_cardioSubDiagnosesMyocardialDate,
    ros_cardioSubDiagnosesMyocardialSupComment,
    ros_cardioSubDiagnosesMyocardialSupotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialBetaBlocker,
    ros_cardioSubDiagnosesMyocardialBetaBlockerComment,
    ros_cardioSubDiagnosesMyocardialIspateintComment,
    ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment,
    ros_cardiohistoryDiabetesisHistoryDiabetesComment,
    ros_cardiopvdDescribeComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer,
    ros_cardiohistoryDiabetesisHistoryDiabetes,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacement,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment,
    ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment,
    ros_cardioSubDiagnosesvalvulardiseaseSupComment,
    ros_cardioSubDiagnosesvalvulardiseaseDesComment,
    ros_cardioSubDiagnosesOtherOtherSub,
    ros_cardioSubDiagnosesOtherSupComment,
    ros_cardioSubDiagnosesOtherDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseCrDesComment,
    ros_cardiovascularRecommendationsheartHealthyDietComment,
    ros_cardiovascularRecommendationsbloodPressureChecksComment,
    ros_cardiovascularRecommendationsexerciseThirtyMinADayComment,
    ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment,
    ros_cardiovascularRecommendationsotherComment,
    ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal,
    ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal,
    ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosescolonOtherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgerdSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal,
    ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesotherSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment,
    ros_gastroSubDiagnosesdesdiverticulitisComment,
    ros_gastroSubDiagnosesdiverticulitisabscessVal,
    ros_gastroSubDiagnosesdiverticulitisabscessValComment,
    ros_gastroSubDiagnosesdiverticulitisperforationVal,
    ros_gastroSubDiagnosesdiverticulitisperforationValComment,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietVal,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment,
    ros_gastroSubDiagnosesdiverticulitisSupComment,
    ros_gastroSubDiagnosesgallBladderDiseaseSupComment,
    ros_gastroSubDiagnosesdesgallBladderDiseaseComment,
    ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByComment,
    ros_gastroSubDiagnosesgastroparesisDescribeComment,
    ros_gastroSubDiagnosesgerdDescribeComment,
    ros_gastroSubDiagnosesgerdSupportedByComment,
    ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedDescribeComment,
    ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDescribeComment,
    ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnoseshepatitisSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherDescribeComment,
    ros_gastroSubDiagnosespancreatitisDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDessComment,
    ros_gastroSubDiagnoseshepatitisTypeComment,
    ros_gastroSubDiagnoseshepatitisCirrhosis,
    ros_gastroSubDiagnoseshepatitisCirrhosisComment,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment,
    ros_gastroSubDiagnosespancreatitisRecurrentEpisodes,

    ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment,

    ros_gastroSubDiagnosespancreatitisSpecificDiet,

    ros_gastroSubDiagnosespancreatitisSpecificDietComment,
    ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactria,
    ros_gastroSubDiagnosesotherOther,
    // ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal,

    ros_genito,
    ros_genitoComment,
    ros_genitoSubDiagnosesacuteRenalFailureEtiology,
    ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesbphSupportedByComment,
    ros_genitoSubDiagnosesbphDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment,
    ros_genitoSubDiagnoseswhatStageComment,
    ros_genitoSubDiagnosesesrdDescribeComment,
    ros_genitoSubDiagnosesesrdSupportedByComment,
    ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesesrdPatientOnDialysisComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet,
    ros_genitoSubDiagnosesesrdPatientOnDialysis,
    ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment,
    ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment,
    ros_genitoSubDiagnosesesrddialysisSchedule,
    ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice,
    ros_genitoSubDiagnoseserectileDysfunctionDescribeComment,
    ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment,
    ros_genitoSubDiagnosesfrequentUtiDescribeComment,
    ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesfrequentUtiSupportedByComment,
    ros_genitoSubDiagnosesgynecologicalDescribeComment,
    ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesgynecologicalSupportedByComment,
    ros_genitoSubDiagnoseskidneyStonesDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseskidneyStonesSupportedByComment,
    ros_genitoSubDiagnoseskidneyStonesTypeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment,
    ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment,
    ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment,
    ros_genitoSubDiagnosesotherDescribeComment,
    ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesotherSupportedByComment,
    ros_genitoSubDiagnosesotherOther,
    ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment,
    ros_genitoStress,
    ros_genitoStressComment,
    ros_genitoStressSubComment,
    ros_endoComment,
    ros_endocrineIsEndocrine,
    ros_endocrineIsEndocrineComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment,
    ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment,
    ros_genitoRecommendationsotherGenitoComment,
    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_diagnosisOfCancerSubSupOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer,
    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce,
    ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment,
    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe,
    ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubtypeComment,
    ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue,
    ros_endocrineIsEndocrineSubdiabetesSubandDate,
    ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician,
    ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment,
    ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator,
    ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment,
    ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar,
    ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment,
    ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment,
    ros_endocrineIsEndocrineDiagnoseotherDescribeComment,
    ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseotherSupComment,
    ros_endocrineIsEndocrineDiagnoseotherOther,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertension,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment,
    ros_diagnosisOfCancerSubTypeOtherValSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockDesComment,
  };

  return tempDbData;
};

export const setRosData = (formData) => {
  let tempData = {};

  ros_OptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  const ros_eyesVal = setOptionsSelectedYesNo(formData["ros_eyes"]);
  const ros_eyesCommentVal = formData["ros_eyesComment"];
  const ros_eyesDiagCommentVal = formData["ros_eyesDiagComment"];
  const ros_eyesDiagCataractSubWhEyeCommentVal =
    formData["ros_eyesDiagCataractSubWhEyeComment"];
  const ros_eyesDiagCataractSubDescCommentVal =
    formData["ros_eyesDiagCataractSubDescComment"];
  const ros_eyesDiagCataractSubSuppCommentVal =
    formData["ros_eyesDiagCataractSubSuppComment"];
  const ros_eyesDiagCataractSubSuppSympSubCommentVal =
    formData["ros_eyesDiagCataractSubSuppSympSubComment"];
  const ros_eyesDiagCataractSubSuppOtherSubDescVal =
    formData["ros_eyesDiagCataractSubSuppOtherSubDesc"];
  const ros_eyesDiagCataractSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagCataractSubDiabetes"],
  );
  const ros_eyesDiagCataractSubDiabetesCommentVal =
    formData["ros_eyesDiagCataractSubDiabetesComment"];

  const ros_eyesDiagGlaucomaSubWhEyeCommentVal =
    formData["ros_eyesDiagGlaucomaSubWhEyeComment"];
  const ros_eyesDiagGlaucomaSubDescCommentVal =
    formData["ros_eyesDiagGlaucomaSubDescComment"];
  const ros_eyesDiagGlaucomaSubSuppCommentVal =
    formData["ros_eyesDiagGlaucomaSubSuppComment"];
  const ros_eyesDiagGlaucomaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagGlaucomaSubSuppSympSubComment"];
  const ros_eyesDiagGlaucomaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagGlaucomaSubSuppOtherSubDesc"];
  const ros_eyesDiagGlaucomaSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagGlaucomaSubDiabetes"],
  );
  const ros_eyesDiagGlaucomaSubDiabetesCommentVal =
    formData["ros_eyesDiagGlaucomaSubDiabetesComment"];
  const ros_eyesDiagGlaucomaSubDiabetesSubCommentVal =
    formData["ros_eyesDiagGlaucomaSubDiabetesSubComment"];

  const ros_eyesDiagHyperopiaSubWhEyeCommentVal =
    formData["ros_eyesDiagHyperopiaSubWhEyeComment"];
  const ros_eyesDiagHyperopiaSubDescCommentVal =
    formData["ros_eyesDiagHyperopiaSubDescComment"];
  const ros_eyesDiagHyperopiaSubSuppCommentVal =
    formData["ros_eyesDiagHyperopiaSubSuppComment"];
  const ros_eyesDiagHyperopiaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagHyperopiaSubSuppSympSubComment"];
  const ros_eyesDiagHyperopiaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagHyperopiaSubSuppOtherSubDesc"];

  const ros_eyesDiagLegalBlindSubWhEyeCommentVal =
    formData["ros_eyesDiagLegalBlindSubWhEyeComment"];
  const ros_eyesDiagLegalBlindSubDescCommentVal =
    formData["ros_eyesDiagLegalBlindSubDescComment"];
  const ros_eyesDiagLegalBlindSubSuppCommentVal =
    formData["ros_eyesDiagLegalBlindSubSuppComment"];
  const ros_eyesDiagLegalBlindSubSuppSympSubCommentVal =
    formData["ros_eyesDiagLegalBlindSubSuppSympSubComment"];
  const ros_eyesDiagLegalBlindSubSuppOtherSubDescVal =
    formData["ros_eyesDiagLegalBlindSubSuppOtherSubDesc"];
  const ros_eyesDiagLegalBlindSubCommentVal =
    formData["ros_eyesDiagLegalBlindSubSubComment"];
  const ros_eyesDiagLegalBlindSubDescTextVal =
    formData["ros_eyesDiagLegalBlindSubDescText"];

  const ros_eyesDiagMascDegenSubWhEyeCommentVal =
    formData["ros_eyesDiagMascDegenSubWhEyeComment"];
  const ros_eyesDiagMascDegenSubDescCommentVal =
    formData["ros_eyesDiagMascDegenSubDescComment"];
  const ros_eyesDiagMascDegenSubSuppCommentVal =
    formData["ros_eyesDiagMascDegenSubSuppComment"];
  const ros_eyesDiagMascDegenSubSuppSympSubCommentVal =
    formData["ros_eyesDiagMascDegenSubSuppSympSubComment"];
  const ros_eyesDiagMascDegenSubSuppOtherSubDescVal =
    formData["ros_eyesDiagMascDegenSubSuppOtherSubDesc"];
  const ros_eyesDiagMascDegenSubCommentVal =
    formData["ros_eyesDiagMascDegenSubComment"];
  const ros_eyesDiagMascDegenSubDesc2Val =
    formData["ros_eyesDiagMascDegenSubDesc2"];
  const ros_eyesDiagMascDegenSubDesc2CommentVal =
    formData["ros_eyesDiagMascDegenSubDesc2Comment"];

  const ros_eyesDiagMyopiaSubWhEyeCommentVal =
    formData["ros_eyesDiagMyopiaSubWhEyeComment"];
  const ros_eyesDiagMyopiaSubDescCommentVal =
    formData["ros_eyesDiagMyopiaSubDescComment"];
  const ros_eyesDiagMyopiaSubSuppCommentVal =
    formData["ros_eyesDiagMyopiaSubSuppComment"];
  const ros_eyesDiagMyopiaSubSuppSympSubCommentVal =
    formData["ros_eyesDiagMyopiaSubSuppSympSubComment"];
  const ros_eyesDiagMyopiaSubSuppOtherSubDescVal =
    formData["ros_eyesDiagMyopiaSubSuppOtherSubDesc"];

  const ros_eyesDiagRetinalSubWhEyeCommentVal =
    formData["ros_eyesDiagRetinalSubWhEyeComment"];
  const ros_eyesDiagRetinalSubDescCommentVal =
    formData["ros_eyesDiagRetinalSubDescComment"];
  const ros_eyesDiagRetinalSubSuppCommentVal =
    formData["ros_eyesDiagRetinalSubSuppComment"];
  const ros_eyesDiagRetinalSubSuppSympSubCommentVal =
    formData["ros_eyesDiagRetinalSubSuppSympSubComment"];
  const ros_eyesDiagRetinalSubSuppOtherSubDescVal =
    formData["ros_eyesDiagRetinalSubSuppOtherSubDesc"];
  const ros_eyesDiagRetinalSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagRetinalSubDiabetes"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal = setOptionsSelectedYesNo(
    formData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemm"],
  );
  const ros_eyesDiagRetinalSubDiabetesSubIsVitHemmCommentVal =
    formData["ros_eyesDiagRetinalSubDiabetesSubIsVitHemmComment"];
  const ros_eyesDiagRetinalSubDiabetesCommentVal =
    formData["ros_eyesDiagRetinalSubDiabetesComment"];

  const ros_eyesDiagOtherSubWhEyeCommentVal =
    formData["ros_eyesDiagOtherSubWhEyeComment"];
  const ros_eyesDiagOtherSubDescCommentVal =
    formData["ros_eyesDiagOtherSubDescComment"];
  const ros_eyesDiagOtherSubSuppCommentVal =
    formData["ros_eyesDiagOtherSubSuppComment"];
  const ros_eyesDiagOtherSubSuppSympSubCommentVal =
    formData["ros_eyesDiagOtherSubSuppSympSubComment"];
  const ros_eyesDiagOtherSubSuppOtherSubDescVal =
    formData["ros_eyesDiagOtherSubSuppOtherSubDesc"];
  const ros_eyesDiagOtherSubOtherVal = formData["ros_eyesDiagOtherSubOther"];

  const ros_earsVal = setOptionsSelectedYesNo(formData["ros_ears"]);
  const ros_earsCommentVal = formData["ros_earsComment"];
  const ros_earsDiagnosesCommentVal = formData["ros_earsDiagnosesComment"];
  const ros_earsDiagDiffHearSubDescCommentVal =
    formData["ros_earsDiagDiffHearSubDescComment"];
  const ros_earsDiagDiffHearSubSuppOtherSubDescVal =
    formData["ros_earsDiagDiffHearSubSuppOtherSubDesc"];
  const ros_earsDiagDiffHearSubSuppCommentVal =
    formData["ros_earsDiagDiffHearSubSuppComment"];

  const ros_earsDiagLegalDeafSubDescCommentVal =
    formData["ros_earsDiagLegalDeafSubDescComment"];
  const ros_earsDiagLegalDeafSubSuppOtherSubDescVal =
    formData["ros_earsDiagLegalDeafSubSuppOtherSubDesc"];
  const ros_earsDiagLegalDeafSubSuppCommentVal =
    formData["ros_earsDiagLegalDeafSubSuppComment"];

  const ros_earsDiagTinnitusSubDescCommentVal =
    formData["ros_earsDiagTinnitusSubDescComment"];
  const ros_earsDiagTinnitusSubSuppOtherSubDescVal =
    formData["ros_earsDiagTinnitusSubSuppOtherSubDesc"];
  const ros_earsDiagTinnitusSubSuppCommentVal =
    formData["ros_earsDiagTinnitusSubSuppComment"];

  const ros_earsDiagVertigoSubDescCommentVal =
    formData["ros_earsDiagVertigoSubDescComment"];
  const ros_earsDiagVertigoSubSuppOtherSubDescVal =
    formData["ros_earsDiagVertigoSubSuppOtherSubDesc"];
  const ros_earsDiagVertigoSubSuppCommentVal =
    formData["ros_earsDiagVertigoSubSuppComment"];
  const ros_earsDiagVertigoSubLoseBalVal = setOptionsSelectedYesNo(
    formData["ros_earsDiagVertigoSubLoseBal"],
  );
  const ros_earsDiagVertigoSubLoseBalCommentVal =
    formData["ros_earsDiagVertigoSubLoseBalComment"];

  const ros_earsDiagOtherSubDescCommentVal =
    formData["ros_earsDiagOtherSubDescComment"];
  const ros_earsDiagOtherSubSuppOtherSubDescVal =
    formData["ros_earsDiagOtherSubSuppOtherSubDesc"];
  const ros_earsDiagOtherSubSuppCommentVal =
    formData["ros_earsDiagOtherSubSuppComment"];

  const ros_earsDiagOtherSubOtherVal = formData["ros_earsDiagOtherSubOther"];

  const ros_noseVal = setOptionsSelectedYesNo(formData["ros_nose"]);

  const ros_noseDiagAllergySubDescCommentVal =
    formData["ros_noseDiagAllergySubDescComment"];
  const ros_noseDiagChronicNasalSubDescCommentVal =
    formData["ros_noseDiagChronicNasalSubDescComment"];
  const ros_noseDiagSeasonAllergySubDescCommentVal =
    formData["ros_noseDiagSeasonAllergySubDescComment"];
  const ros_noseDiagSinusInfectSubDescCommentVal =
    formData["ros_noseDiagSinusInfectSubDescComment"];
  const ros_noseDiagSinustisSubDescCommentVal =
    formData["ros_noseDiagSinustisSubDescComment"];
  const ros_noseDiagOtherSubDescCommentVal =
    formData["ros_noseDiagOtherSubDescComment"];

  const ros_noseDiagAllergySubSuppOtherSubDescVal =
    formData["ros_noseDiagAllergySubSuppOtherSubDesc"];
  const ros_noseDiagAllergySubSuppCommentVal =
    formData["ros_noseDiagAllergySubSuppComment"];
  const ros_noseDiagChronicNasalSubSuppOtherSubDescVal =
    formData["ros_noseDiagChronicNasalSubSuppOtherSubDesc"];
  const ros_noseDiagChronicNasalSubSuppCommentVal =
    formData["ros_noseDiagChronicNasalSubSuppComment"];
  const ros_noseDiagNoseBleedSubCommentVal =
    formData["ros_noseDiagNoseBleedSubComment"];
  const ros_noseDiagSeasonAllergySubSuppOtherSubDescVal =
    formData["ros_noseDiagSeasonAllergySubSuppOtherSubDesc"];
  const ros_noseDiagSeasonAllergySubSuppCommentVal =
    formData["ros_noseDiagSeasonAllergySubSuppComment"];
  const ros_noseDiagSinusInfectSubSuppOtherSubDescVal =
    formData["ros_noseDiagSinusInfectSubSuppOtherSubDesc"];
  const ros_noseDiagSinusInfectSubSuppCommentVal =
    formData["ros_noseDiagSinusInfectSubSuppComment"];
  const ros_noseDiagSinusInfectSubExuCommentVal =
    formData["ros_noseDiagSinusInfectSubExuComment"];

  const ros_noseDiagSinustisSubSuppOtherSubDescVal =
    formData["ros_noseDiagSinustisSubSuppOtherSubDesc"];
  const ros_noseDiagSinustisSubSuppCommentVal =
    formData["ros_noseDiagSinustisSubSuppComment"];

  const ros_noseDiagOtherSubSuppOtherSubDescVal =
    formData["ros_noseDiagOtherSubSuppOtherSubDesc"];
  const ros_noseDiagOtherSubSuppCommentVal =
    formData["ros_noseDiagOtherSubSuppComment"];
  const ros_noseDiagOtherSubOtherVal = formData["ros_noseDiagOtherSubOther"];

  const ros_noseDiagnosesCommentVal = formData["ros_noseDiagnosesComment"];
  const ros_noseCommentVal = formData["ros_noseComment"];

  const ros_mouthVal = setOptionsSelectedYesNo(formData["ros_mouth"]);
  const ros_mouthDiagCommentVal = formData["ros_mouthDiagComment"];
  const ros_mouthCommentVal = formData["ros_mouthComment"];
  const ros_mouthDiagBleedGumsSubCommentVal =
    formData["ros_mouthDiagBleedGumsSubComment"];
  const ros_mouthDiagDiffChewSubDescCommentVal =
    formData["ros_mouthDiagDiffChewSubDescComment"];
  const ros_mouthDiagDiffChewSubBcsPainVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffChewSubBcsPain"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffChewSubBcsPainSub"],
  );
  const ros_mouthDiagDiffChewSubBcsPainSubCommentVal =
    formData["ros_mouthDiagDiffChewSubBcsPainSubComment"];
  const ros_mouthDiagDiffChewSubBcsPainCommentVal =
    formData["ros_mouthDiagDiffChewSubBcsPainComment"];

  const ros_mouthDiagDiffSwallowSubDescCommentVal =
    formData["ros_mouthDiagDiffSwallowSubDescComment"];
  const ros_mouthDiagDiffSwallowSubHadStrokeVal = setOptionsSelectedYesNo(
    formData["ros_mouthDiagDiffSwallowSubHadStroke"],
  );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal =
    setOptionsSelectedYesNo(
      formData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub"],
    );
  const ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubCommentVal =
    formData["ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubComment"];
  const ros_mouthDiagDiffSwallowSubHadStrokeCommentVal =
    formData["ros_mouthDiagDiffSwallowSubHadStrokeComment"];

  const ros_mouthDiagOtherSubDescCommentVal =
    formData["ros_mouthDiagOtherSubDescComment"];
  const ros_mouthDiagOtherSubSuppOtherSubDescVal =
    formData["ros_mouthDiagOtherSubSuppOtherSubDesc"];
  const ros_mouthDiagOtherSubSuppCommentVal =
    formData["ros_mouthDiagOtherSubSuppComment"];
  const ros_mouthDiagOtherSubOtherVal = formData["ros_mouthDiagOtherSubOther"];

  const ros_neckVal = setOptionsSelectedYesNo(formData["ros_neck"]);
  const ros_neckDiagCommentVal = formData["ros_neckDiagComment"];
  const ros_neckCommentVal = formData["ros_neckComment"];
  const ros_neckDiagCarotidSubDescCommentVal =
    formData["ros_neckDiagCarotidSubDescComment"];
  const ros_neckDiagCarotidSubSuppOtherSubDescVal =
    formData["ros_neckDiagCarotidSubSuppOtherSubDesc"];
  const ros_neckDiagCarotidSubSuppCommentVal =
    formData["ros_neckDiagCarotidSubSuppComment"];
  const ros_neckDiagCarotidSubCsDescCommentVal =
    formData["ros_neckDiagCarotidSubCsDescComment"];

  const ros_neckDiagParotidSubSuppOtherSubDescVal =
    formData["ros_neckDiagParotidSubSuppOtherSubDesc"];
  const ros_neckDiagParotidSubSuppCommentVal =
    formData["ros_neckDiagParotidSubSuppComment"];
  const ros_neckDiagParotidSubDescCommentVal =
    formData["ros_neckDiagParotidSubDescComment"];

  const ros_neckDiagOtherSubDescCommentVal =
    formData["ros_neckDiagOtherSubDescComment"];
  const ros_neckDiagOtherSubSuppOtherSubDescVal =
    formData["ros_neckDiagOtherSubSuppOtherSubDesc"];
  const ros_neckDiagOtherSubSuppCommentVal =
    formData["ros_neckDiagOtherSubSuppComment"];
  const ros_neckDiagOtherSubOtherVal = formData["ros_neckDiagOtherSubOther"];

  const ros_eyeENTNeckRecommendSelected =
    formData["ros_eyeENTNeckRecommendations"] || [];

  const ros_eyeENTNeckRecommendationsVal = setSelectedCheckboxes(
    ros_eyeENTNeckRecommendSelected,
    [
      "ros_eyeENTNeckRecommendationshearingEvaluationVal",
      "ros_eyeENTNeckRecommendationsdentalExamVal",
      "ros_eyeENTNeckRecommendationseyeExamVal",
      "ros_eyeENTNeckRecommendationsswallowingEvaluationVal",
      "ros_eyeENTNeckRecommendationstakeMedicationsVal",
      "ros_eyeENTNeckRecommendationsotherVal",
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "other",
    ],
  );

  const ros_eyeENTNeckRecommendationshearingEvaluationCommentVal =
    formData["ros_eyeENTNeckRecommendationshearingEvaluationComment"];
  const ros_eyeENTNeckRecommendationsdentalExamCommentVal =
    formData["ros_eyeENTNeckRecommendationsdentalExamComment"];
  const ros_eyeENTNeckRecommendationseyeExamCommentVal =
    formData["ros_eyeENTNeckRecommendationseyeExamComment"];
  const ros_eyeENTNeckRecommendationsswallowingEvaluationCommentVal =
    formData["ros_eyeENTNeckRecommendationsswallowingEvaluationComment"];
  const ros_eyeENTNeckRecommendationstakeMedicationsCommentVal =
    formData["ros_eyeENTNeckRecommendationstakeMedicationsComment"];
  const ros_eyeENTNeckRecommendationsotherCommentVal =
    formData["ros_eyeENTNeckRecommendationsotherComment"];

  const ros_bowelMoveAbnormSubDiarrSubIshisVal = setOptionsSelectedYesNo(
    formData["ros_bowelMoveAbnormSubDiarrSubIshis"],
  );
  const ros_bowelMoveAbnormSubDiarrSubIshisCommentVal =
    formData["ros_bowelMoveAbnormSubDiarrSubIshisComment"];

  const ros_bowelMoveCommentVal = formData["ros_bowelMoveComment"];
  const ros_bowelMoveAbnormSubCommentVal =
    formData["ros_bowelMoveAbnormSubComment"];
  const ros_bowelMoveAbnormSubConstSubIfConstCommentVal =
    formData["ros_bowelMoveAbnormSubConstSubIfConstComment"];
  const ros_bowelMoveAbnormSubDiarrSubCommentVal =
    formData["ros_bowelMoveAbnormSubDiarrSubComment"];

  const ros_abdominalVal = setOptionsSelectedYesNo(formData["ros_abdominal"]);
  const ros_abdominalIsyesCommentVal = formData["ros_abdominalIsyesComment"];
  const ros_abdominalCommentVal = formData["ros_abdominalComment"];

  const ros_rectalVal = setOptionsSelectedYesNo(formData["ros_rectal"]);
  const ros_rectalIsrecIsyesmalCommentVal =
    formData["ros_rectalIsrecIsyesmalComment"];
  const ros_rectalIsrecIsyesfemCommentVal =
    formData["ros_rectalIsrecIsyesfemComment"];
  const ros_rectalCommentVal = formData["ros_rectalComment"];

  const ros_lastBowelCommentVal = formData["ros_lastBowelComment"];

  const ros_meetWithCommentVal = formData["ros_meetWithComment"];

  const ros_gpcogScoIsgpcogsVal = formData["ros_gpcogScoIsgpcogs"];
  const ros_gpcogScoIsmmsescVal = formData["ros_gpcogScoIsmmsesc"];
  const ros_gpcogScoCommentVal = formData["ros_gpcogScoComment"];

  const ros_noGpcogMmsePatientOrientPersonVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientPerson"],
  );
  const ros_noGpcogMmsePatientOrientPlaceVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientPlace"],
  );
  const ros_noGpcogMmsePatientOrientTimeVal = setOptionsSelectedYesNo(
    formData["ros_noGpcogMmsePatientOrientTime"],
  );
  const ros_noGpcogMmsePatientDescribeNewsVal =
    setOptionsSelectedYesNoPartially(
      formData["ros_noGpcogMmsePatientDescribeNews"],
    );

  const ros_noGpcogMmseRecallCommentVal =
    formData["ros_noGpcogMmseRecallComment"];
  const ros_noGpcogMmsePatientDescribeNewsCommentVal =
    formData["ros_noGpcogMmsePatientDescribeNewsComment"];
  const ros_noGpcogMmsePatientOrientPersonCommentVal =
    formData["ros_noGpcogMmsePatientOrientPersonComment"];
  const ros_noGpcogMmsePatientOrientPlaceCommentVal =
    formData["ros_noGpcogMmsePatientOrientPlaceComment"];
  const ros_noGpcogMmsePatientOrientTimeCommentVal =
    formData["ros_noGpcogMmsePatientOrientTimeComment"];

  const ros_speechCommentVal = formData["ros_speechComment"];

  const ros_sitToStandCommentVal = formData["ros_sitToStandComment"];

  const ros_fingerToNoseAbnormalSubCommentVal =
    formData["ros_fingerToNoseAbnormalSubComment"];
  const ros_fingerToNoseCommentVal = formData["ros_fingerToNoseComment"];

  const ros_heelToToeAbnormalSubCommentVal =
    formData["ros_heelToToeAbnormalSubComment"];
  const ros_heelToToeCommentVal = formData["ros_heelToToeComment"];

  const ros_thumbToFingerAbnormalSubCommentVal =
    formData["ros_thumbToFingerAbnormalSubComment"];
  const ros_thumbToFingerCommentVal = formData["ros_thumbToFingerComment"];

  const ros_extremeMoveCommentVal = formData["ros_extremeMoveComment"];

  const ros_affectCommentVal = formData["ros_affectComment"];
  const ros_affectAbnormalSubDescCommentVal =
    formData["ros_affectAbnormalSubDescComment"];

  const ros_over2WeeksLittleIntrstCommentVal =
    formData["ros_over2WeeksLittleIntrstComment"];
  const ros_over2WeeksFeelDepressedCommentVal =
    formData["ros_over2WeeksFeelDepressedComment"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal =
    formData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubComment"
    ];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment"];

  const ros_over2WeeksPhq2ScoreVal = formData["ros_over2WeeksPhq2Score"];
  const ros_over2WeeksPhq9ScoreVal = formData["ros_over2WeeksPhq9Score"];

  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate"];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal =
    formData[
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere"
    ];
  const ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal =
    formData["ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere"];

  const ros_neuroPsychVal = setOptionsSelectedYesNo(formData["ros_neuroPsych"]);
  const ros_neuroPsychCommentVal = formData["ros_neuroPsychComment"];

  const ros_nervousAnxiousVal = setOptionsSelectedYesNo(
    formData["ros_nervousAnxious"],
  );
  const ros_nervousAnxiousCommentVal = formData["ros_nervousAnxiousComment"];

  const ros_worryMuchVal = setOptionsSelectedYesNo(formData["ros_worryMuch"]);
  const ros_worryMuchCommentVal = formData["ros_worryMuchComment"];

  const ros_feelAfraidVal = setOptionsSelectedYesNo(formData["ros_feelAfraid"]);
  const ros_feelAfraidCommentVal = formData["ros_feelAfraidComment"];

  const ros_neuroPsychHistoryAudioVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychHistoryAudio"],
  );
  const ros_neuroPsychHistoryAudioCommentVal =
    formData["ros_neuroPsychHistoryAudioComment"];

  const ros_neuroPsychHistoryVisualVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychHistoryVisual"],
  );
  const ros_neuroPsychHistoryVisualCommentVal =
    formData["ros_neuroPsychHistoryVisualComment"];

  const ros_neuroPsychBehaviourVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychBehaviour"],
  );
  const ros_neuroPsychBehaviourCommentVal =
    formData["ros_neuroPsychBehaviourComment"];

  const ros_neuroPsychDeliriumVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychDelirium"],
  );
  const ros_neuroPsychDeliriumCommentVal =
    formData["ros_neuroPsychDeliriumComment"];

  const ros_neuroPsychHearVoicesVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychHearVoices"],
  );
  const ros_neuroPsychHearVoicesCommentVal =
    formData["ros_neuroPsychHearVoicesComment"];

  const ros_neuroPsychHighLowsVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychHighLows"],
  );
  const ros_neuroPsychHighLowsCommentVal =
    formData["ros_neuroPsychHighLowsComment"];

  const ros_neuroPsychEverFeelVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychEverFeel"],
  );
  const ros_neuroPsychEverFeelCommentVal =
    formData["ros_neuroPsychEverFeelComment"];

  const ros_neuroPsychSubDiagCommentVal =
    formData["ros_neuroPsychSubDiagComment"];

  const ros_neuroPsychSubDiagAlcoholSubDescCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubDescComment"];

  const ros_neuroPsychSubDiagAlcoholSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubTypeComment"];

  const ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc"];

  const ros_neuroPsychSubDiagAlcoholSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubSuppComment"];

  const ros_neuroPsychSubDiagAlcoholSubDeliriumVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagAlcoholSubDelirium"],
  );
  const ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubDeliriumComment"];

  const ros_neuroPsychSubDiagAlcoholSubPsychVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagAlcoholSubPsych"],
  );
  const ros_neuroPsychSubDiagAlcoholSubPsychCommentVal =
    formData["ros_neuroPsychSubDiagAlcoholSubPsychComment"];

  const ros_neuroPsychSubDiagAmyotropSubDescCommentVal =
    formData["ros_neuroPsychSubDiagAmyotropSubDescComment"];

  const ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc"];

  const ros_neuroPsychSubDiagAmyotropSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAmyotropSubSuppComment"];

  const ros_neuroPsychSubDiagAnxietySubDescCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubDescComment"];
  const ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc"];
  const ros_neuroPsychSubDiagAnxietySubTypeCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubTypeComment"];
  const ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagAnxietySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagAnxietySubSuppComment"];

  const ros_neuroPsychSubDiagBipolarSubDescCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubDescComment"];
  const ros_neuroPsychSubDiagBipolarSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubTypeComment"];
  const ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagBipolarSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagBipolarSubSuppComment"];

  const ros_neuroPsychSubDiagCerebHemmSubDescCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubDescComment"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubComment"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagCerebHemmSubSuppComment"];

  const ros_neuroPsychSubDiagDementiaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubDescComment"];
  const ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDementiaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubSuppComment"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeComment"];
  const ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubTypeVascComment"];
  const ros_neuroPsychSubDiagDementiaSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagDementiaSubTypeComment"];

  const ros_neuroPsychSubDiagDrugsSubHistoryPsychVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagDrugsSubHistoryPsych"],
  );
  const ros_neuroPsychSubDiagDrugsSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubDescComment"];
  const ros_neuroPsychSubDiagDrugsSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubTypeComment"];
  const ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDrugsSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubSuppComment"];
  const ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal =
    formData["ros_neuroPsychSubDiagDrugsSubHistoryPsychComment"];
  const ros_neuroPsychSubDiagDrugsSubWhatDrugsVal =
    formData["ros_neuroPsychSubDiagDrugsSubWhatDrugs"];

  const ros_neuroPsychSubDiagDepressSubMajorVal =
    setOptionsSelectedYesNoUnknown(
      formData["ros_neuroPsychSubDiagDepressSubMajor"],
    );
  const ros_neuroPsychSubDiagDepressSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubDescComment"];
  const ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDepressSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubSuppComment"];
  const ros_neuroPsychSubDiagDepressSubMajorCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubMajorComment"];
  const ros_neuroPsychSubDiagDepressSubEpisodeCommentVal =
    formData["ros_neuroPsychSubDiagDepressSubEpisodeComment"];

  const ros_neuroPsychSubDiagHemiSubDescCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubDescComment"];
  const ros_neuroPsychSubDiagHemiSubHemiDescCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubHemiDescComment"];
  const ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagHemiSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagHemiSubSuppComment"];

  const ros_neuroPsychSubDiagIntelSubDescCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubDescComment"];
  const ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagIntelSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubSuppComment"];
  const ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal =
    formData["ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc"];
  const ros_neuroPsychSubDiagIntelSubIntelDescCommentVal =
    formData["ros_neuroPsychSubDiagIntelSubIntelDescComment"];

  const ros_neuroPsychSubDiagSeizureSubTreatmentVal =
    formData["ros_neuroPsychSubDiagSeizureSubTreatment"];
  const ros_neuroPsychSubDiagSeizureSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubDescComment"];
  const ros_neuroPsychSubDiagSeizureSubTypeCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubTypeComment"];
  const ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSeizureSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSeizureSubSuppComment"];

  const ros_neuroPsychSubDiagSpinalCordSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubDescComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSecToComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubComment"];
  const ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCommentVal =
    formData["ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubComment"];

  const ros_neuroPsychSubDiagStrokeSubDescCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubDescComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubComment"];
  const ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagStrokeSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagStrokeSubSuppComment"];

  const ros_neuroPsychSubDiagSubduralSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubDescComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubComment"];
  const ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSubduralSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSubduralSubSuppComment"];

  const ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubDescComment"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubComment"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagTraumaBrainSubSuppComment"];

  const ros_neuroPsychSubDiagCerebPalsySubDescCommentVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubDescComment"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagCerebPalsySubSuppComment"];

  const ros_neuroPsychSubDiagDelusionSubDescCommentVal =
    formData["ros_neuroPsychSubDiagDelusionSubDescComment"];
  const ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDelusionSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDelusionSubSuppComment"];

  const ros_neuroPsychSubDiagFibroSubDescCommentVal =
    formData["ros_neuroPsychSubDiagFibroSubDescComment"];
  const ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagFibroSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagFibroSubSuppComment"];

  const ros_neuroPsychSubDiagGuillSubDescCommentVal =
    formData["ros_neuroPsychSubDiagGuillSubDescComment"];
  const ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagGuillSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagGuillSubSuppComment"];

  const ros_neuroPsychSubDiagHuntingSubDescCommentVal =
    formData["ros_neuroPsychSubDiagHuntingSubDescComment"];
  const ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagHuntingSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagHuntingSubSuppComment"];

  const ros_neuroPsychSubDiagInsomniaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagInsomniaSubDescComment"];
  const ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagInsomniaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagInsomniaSubSuppComment"];

  const ros_neuroPsychSubDiagMigraineSubDescCommentVal =
    formData["ros_neuroPsychSubDiagMigraineSubDescComment"];
  const ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagMigraineSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagMigraineSubSuppComment"];

  const ros_neuroPsychSubDiagSclerosisSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSclerosisSubDescComment"];
  const ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSclerosisSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSclerosisSubSuppComment"];

  const ros_neuroPsychSubDiagDystrophySubDescCommentVal =
    formData["ros_neuroPsychSubDiagDystrophySubDescComment"];
  const ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagDystrophySubSuppCommentVal =
    formData["ros_neuroPsychSubDiagDystrophySubSuppComment"];

  const ros_neuroPsychSubDiagGravisSubDescCommentVal =
    formData["ros_neuroPsychSubDiagGravisSubDescComment"];
  const ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagGravisSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagGravisSubSuppComment"];

  const ros_neuroPsychSubDiagParkinsonSubDescCommentVal =
    formData["ros_neuroPsychSubDiagParkinsonSubDescComment"];
  const ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagParkinsonSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagParkinsonSubSuppComment"];

  const ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubDescComment"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubSuppComment"];
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal = setOptionsSelectedYesNo(
    formData["ros_neuroPsychSubDiagPeriNeuroSubDiabetes"],
  );
  const ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal =
    formData["ros_neuroPsychSubDiagPeriNeuroSubDiabetesComment"];

  const ros_neuroPsychSubDiagRestlessLegSubDescCommentVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubDescComment"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagRestlessLegSubSuppComment"];

  const ros_neuroPsychSubDiagSchizoSubDescCommentVal =
    formData["ros_neuroPsychSubDiagSchizoSubDescComment"];
  const ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagSchizoSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagSchizoSubSuppComment"];

  const ros_neuroPsychSubDiagTiaSubDescCommentVal =
    formData["ros_neuroPsychSubDiagTiaSubDescComment"];
  const ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagTiaSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagTiaSubSuppComment"];

  const ros_neuroPsychSubDiagOtherSubDescCommentVal =
    formData["ros_neuroPsychSubDiagOtherSubDescComment"];
  const ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal =
    formData["ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc"];
  const ros_neuroPsychSubDiagOtherSubSuppCommentVal =
    formData["ros_neuroPsychSubDiagOtherSubSuppComment"];
  const ros_neuroPsychSubDiagOtherSubOtherVal =
    formData["ros_neuroPsychSubDiagOtherSubOther"];

  const gait_RecommendationSelected = formData["gait_Recommendations"];

  const gait_RecommendationsVal = setSelectedCheckboxes(
    gait_RecommendationSelected,
    ["gait_recoTakeMedicationsAsPescribedVal", "gait_recoOtherVal"],
    ["takeMedicationsAsPescribed", "other"],
  );

  //const gait_RecommendationsComment = formData["gait_RecommendationsComment"];

  const gait_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["gait_RecommendationstakeMedicationsAsPescribedComment"];

  const gait_RecommendationsotherCommentVal =
    formData["gait_RecommendationsotherComment"];

  const respiratory_RecommendationSelected =
    formData["respiratory_Recommendations"];

  const respiratory_RecommendationsVal = setSelectedCheckboxes(
    respiratory_RecommendationSelected,
    ["respiratory_recoTakeMedicationsVal", "respiratory_recoOtherVal"],
    ["takeMedications", "other"],
  );

  // const respiratory_RecommendationsComment =
  //   formData["respiratory_RecommendationsComment"];

  const respiratory_RecommendationstakeMedicationsCommentVal =
    formData["respiratory_RecommendationstakeMedicationsComment"];

  const respiratory_RecommendationsotherCommentVal =
    formData["respiratory_RecommendationsotherComment"];

  const integument_RecommendationSelected =
    formData["integument_Recommendations"];

  const integument_RecommendationsVal = setSelectedCheckboxes(
    integument_RecommendationSelected,
    ["integument_recoTakeMedicationsAsPescribedVal", "integument_recoOtherVal"],
    ["takeMedicationsAsPescribed", "other"],
  );

  const integument_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["integument_RecommendationstakeMedicationsAsPescribedComment"];

  const integument_RecommendationsotherCommentVal =
    formData["integument_RecommendationsotherComment"];

  // const integument_RecommendationsComment =
  //   formData["integument_RecommendationsComment"];

  const musko_RecommendationSelected = formData["musko_Recommendations"];

  const musko_RecommendationsVal = setSelectedCheckboxes(
    musko_RecommendationSelected,
    [
      "musko_recoDiscussPtOtVal",
      "musko_recoTakeMedicationsAsPescribedVal",
      "musko_recoOtherVal",
    ],
    ["discussPtOt", "takeMedicationsAsPescribed", "other"],
  );
  const musko_RecommendationsdiscussPtOtCommentVal =
    formData["musko_RecommendationsdiscussPtOtComment"];

  const musko_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["musko_RecommendationstakeMedicationsAsPescribedComment"];

  const musko_RecommendationsotherCommentVal =
    formData["musko_RecommendationsotherComment"];

  // const musko_RecommendationsComment = formData["musko_RecommendationsComment"];

  const hema_RecommendationSelected = formData["hema_Recommendations"];

  const hema_RecommendationsVal = setSelectedCheckboxes(
    hema_RecommendationSelected,
    [
      "hema_recoTakeMedicationsAsPescribedVal",
      "hema_recoReportAbnormalBruisingVal",
      "hema_recoFollowUpWithDoctorVal",
      "hema_recoOtherVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "reportAbnormalBruising",
      "followUpWithDoctor",
      "other",
    ],
  );
  const hema_RecommendationstakeMedicationsAsPescribedCommentVal =
    formData["hema_RecommendationstakeMedicationsAsPescribedComment"];

  const hema_RecommendationsreportAbnormalBruisingCommentVal =
    formData["hema_RecommendationsreportAbnormalBruisingComment"];

  const hema_RecommendationsfollowUpWithDoctorCommentVal =
    formData["hema_RecommendationsfollowUpWithDoctorComment"];

  const hema_RecommendationsotherCommentVal =
    formData["hema_RecommendationsotherComment"];

  // const hema_RecommendationsComment = formData["hema_RecommendationsComment"];

  //============================COMMMMMMM====================
  //GAIT================
  const ros_gaitComment = formData["ros_gaitComment"];

  //RESPIR=============
  const ros_respiratoryComment = formData["ros_respiratoryComment"];
  const ros_respiratoryAcutePulmonarySubAnswerDescribeComment =
    formData["ros_respiratoryAcutePulmonarySubAnswerDescribeComment"];
  const ros_respiratoryDiagnosesComment =
    formData["ros_respiratoryDiagnosesComment"];
  const ros_respiratoryAcutePulmonarySubAnswerSupportedByComment =
    formData["ros_respiratoryAcutePulmonarySubAnswerSupportedByComment"];
  const ros_respiratoryAcuteUpperSubAnswerDescribeComment =
    formData["ros_respiratoryAcuteUpperSubAnswerDescribeComment"];
  const ros_respiratoryAcuteUpperSubAnswerSupportedByComment =
    formData["ros_respiratoryAcuteUpperSubAnswerSupportedByComment"];
  const ros_respiratoryAsthmaSubAnswerDescribeComment =
    formData["ros_respiratoryAsthmaSubAnswerDescribeComment"];
  const ros_respiratoryAsthmaSubAnswerSupportedByComment =
    formData["ros_respiratoryAsthmaSubAnswerSupportedByComment"];
  const ros_respiratoryAsthmaPatientControllerComment =
    formData["ros_respiratoryAsthmaPatientControllerComment"];
  const ros_respiratoryAsthmaRescueMedicationsComment =
    formData["ros_respiratoryAsthmaRescueMedicationsComment"];
  const ros_respiratoryAsthmaCurrentExacerbationComment =
    formData["ros_respiratoryAsthmaCurrentExacerbationComment"];
  const ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment =
    formData["ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment"];
  const ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment =
    formData[
      "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment =
    formData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment"
    ];
  const ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment =
    formData[
      "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment"
    ];
  const ros_respiratoryChronicSputumFailureSubAnswerDescribeComment =
    formData["ros_respiratoryChronicSputumFailureSubAnswerDescribeComment"];
  const ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment =
    formData["ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment"];
  const ros_respiratoryCopdSubAnswerDescribeComment =
    formData["ros_respiratoryCopdSubAnswerDescribeComment"];
  const ros_respiratoryCopdSubAnswerSupportedByComment =
    formData["ros_respiratoryCopdSubAnswerSupportedByComment"];
  const ros_respiratoryPatientChronicBronchitisComment =
    formData["ros_respiratoryPatientChronicBronchitisComment"];
  const ros_respiratoryPatientHaveEmphysemaComment =
    formData["ros_respiratoryPatientHaveEmphysemaComment"];
  const ros_respiratoryPatientBronchodilatorComment =
    formData["ros_respiratoryPatientBronchodilatorComment"];
  const ros_respiratoryPatientSteroidsComment =
    formData["ros_respiratoryPatientSteroidsComment"];
  const ros_respiratoryPatientExacerbationComment =
    formData["ros_respiratoryPatientExacerbationComment"];
  const ros_respiratoryCysticFibrosisSubAnswerDescribeComment =
    formData["ros_respiratoryCysticFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryCysticFibrosisSubAnswerSupportedByComment =
    formData["ros_respiratoryCysticFibrosisSubAnswerSupportedByComment"];
  const ros_respiratoryHypoventilationSubAnswerDescribeComment =
    formData["ros_respiratoryHypoventilationSubAnswerDescribeComment"];
  const ros_respiratoryHypoventilationSubAnswerSupportedByComment =
    formData["ros_respiratoryHypoventilationSubAnswerSupportedByComment"];
  const ros_respiratoryHypoxemiaSubAnswerDescribeComment =
    formData["ros_respiratoryHypoxemiaSubAnswerDescribeComment"];
  const ros_respiratoryHypoxemiaSubAnswerSupportedByComment =
    formData["ros_respiratoryHypoxemiaSubAnswerSupportedByComment"];
  const ros_respiratoryPneumoniaSubAnswerDescribeComment =
    formData["ros_respiratoryPneumoniaSubAnswerDescribeComment"];
  const ros_respiratoryPneumoniaSubAnswerSupportedByComment =
    formData["ros_respiratoryPneumoniaSubAnswerSupportedByComment"];
  const ros_respiratoryPneumoniaSubAnswerEtiologyComment =
    formData["ros_respiratoryPneumoniaSubAnswerEtiologyComment"];
  const ros_respiratoryHistoryOfLungAbscessComment =
    formData["ros_respiratoryHistoryOfLungAbscessComment"];
  const ros_respiratoryHistoryOfEmpyemaComment =
    formData["ros_respiratoryHistoryOfEmpyemaComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment =
    formData["ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment"];
  const ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment =
    formData["ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment"];
  const ros_respiratoryRespiratorDependenceSubAnswerDescribeComment =
    formData["ros_respiratoryRespiratorDependenceSubAnswerDescribeComment"];
  const ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment =
    formData["ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment"];
  const ros_respiratoryRespiratoryArrestSubAnswerDescribeComment =
    formData["ros_respiratoryRespiratoryArrestSubAnswerDescribeComment"];
  const ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment =
    formData["ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment"];
  const ros_respiratorySarcoidosisSubAnswerDescribeComment =
    formData["ros_respiratorySarcoidosisSubAnswerDescribeComment"];
  const ros_respiratorySarcoidosisSubAnswerSupportedByComment =
    formData["ros_respiratorySarcoidosisSubAnswerSupportedByComment"];
  const ros_respiratorySarcoidosisSubAnswerSpecifyComment =
    formData["ros_respiratorySarcoidosisSubAnswerSpecifyComment"];
  const ros_respiratorySleepApneaSubAnswerDescribeComment =
    formData["ros_respiratorySleepApneaSubAnswerDescribeComment"];
  const ros_respiratorySleepApneaSubAnswerSupportedByComment =
    formData["ros_respiratorySleepApneaSubAnswerSupportedByComment"];
  const ros_respiratoryRespOtherSubAnswerDescribeComment =
    formData["ros_respiratoryRespOtherSubAnswerDescribeComment"];
  const ros_respiratoryRespOtherSubAnswerSupportedByComment =
    formData["ros_respiratoryRespOtherSubAnswerSupportedByComment"];
  const ros_respiratoryOtherSubAnswerOther =
    formData["ros_respiratoryOtherSubAnswerOther"];

  const ros_integumentComment = formData["ros_integumentComment"];
  const ros_integumentDiagnosesComment =
    formData["ros_integumentDiagnosesComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerDescribeComment =
    formData["ros_integumentBasilCellCarcinomaSubAnswerDescribeComment"];
  const ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment =
    formData["ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment"];
  const ros_integumentDermatitisSubAnswerDescribeComment =
    formData["ros_integumentDermatitisSubAnswerDescribeComment"];
  const ros_integumentDermatitisSubAnswerSupportedByComment =
    formData["ros_integumentDermatitisSubAnswerSupportedByComment"];
  const ros_integumentDermatitisSubAnswerWhatTypeComment =
    formData["ros_integumentDermatitisSubAnswerWhatTypeComment"];
  const ros_integumentEczemaSubAnswerDescribeComment =
    formData["ros_integumentEczemaSubAnswerDescribeComment"];
  const ros_integumentEczemaSubAnswerSupportedByComment =
    formData["ros_integumentEczemaSubAnswerSupportedByComment"];
  const ros_integumentOnychomycosisSubAnswerDescribeComment =
    formData["ros_integumentOnychomycosisSubAnswerDescribeComment"];
  const ros_integumentOnychomycosisSubAnswerSupportedByComment =
    formData["ros_integumentOnychomycosisSubAnswerSupportedByComment"];
  const ros_integumentPsoriasisSubAnswerDescribeComment =
    formData["ros_integumentPsoriasisSubAnswerDescribeComment"];
  const ros_integumentPsoriasisSubAnswerSupportedByComment =
    formData["ros_integumentPsoriasisSubAnswerSupportedByComment"];
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment =
    formData["ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment"];
  const ros_integumentSkinUlcerSubAnswerDescribeComment =
    formData["ros_integumentSkinUlcerSubAnswerDescribeComment"];
  const ros_integumentSkinUlcerSubAnswerSupportedByComment =
    formData["ros_integumentSkinUlcerSubAnswerSupportedByComment"];
  const ros_integumentSkinUlcerSubAnswerEtiologyComment =
    formData["ros_integumentSkinUlcerSubAnswerEtiologyComment"];
  const ros_integumentTineaPedisSubAnswerDescribeComment =
    formData["ros_integumentTineaPedisSubAnswerDescribeComment"];
  const ros_integumentTineaPedisSubAnswerSupportedByComment =
    formData["ros_integumentTineaPedisSubAnswerSupportedByComment"];
  const ros_integumentUrticarialDiseaseSubAnswerDescribeComment =
    formData["ros_integumentUrticarialDiseaseSubAnswerDescribeComment"];
  const ros_integumentUrticarialDiseaseSubAnswerEtiology =
    formData["ros_integumentUrticarialDiseaseSubAnswerEtiology"];
  const ros_integumentUrticarialDiseaseSubAnswerSupportedByComment =
    formData["ros_integumentUrticarialDiseaseSubAnswerSupportedByComment"];
  const ros_integumentUrticarialDiseaseSubAnswerTypeComment =
    formData["ros_integumentUrticarialDiseaseSubAnswerTypeComment"];
  const ros_integumentWoundSubAnswerDescribeComment =
    formData["ros_integumentWoundSubAnswerDescribeComment"];
  const ros_integumentWoundSubAnswerSupportedByComment =
    formData["ros_integumentWoundSubAnswerSupportedByComment"];
  const ros_integumentWoundSubAnswerEtiologyComment =
    formData["ros_integumentWoundSubAnswerEtiologyComment"];
  const ros_integumentOthersSubAnswerDescribeComment =
    formData["ros_integumentOthersSubAnswerDescribeComment"];
  const ros_integumentOthersSubAnswerSupportedByComment =
    formData["ros_integumentOthersSubAnswerSupportedByComment"];
  const ros_integumentOthersSubAnswerOther =
    formData["ros_integumentOthersSubAnswerOther"];

  const ros_musculoskeletalComment = formData["ros_musculoskeletalComment"];
  const ros_musculoskeletalDiagnosesComment =
    formData["ros_musculoskeletalDiagnosesComment"];
  const ros_musculoskeletalCollagenSubAnswerDescribeComment =
    formData["ros_musculoskeletalCollagenSubAnswerDescribeComment"];
  const ros_musculoskeletalCollagenSubAnswerSupportedByComment =
    formData["ros_musculoskeletalCollagenSubAnswerSupportedByComment"];
  const ros_musculoskeletalCollagenSubAnswercollagenDescribeComment =
    formData["ros_musculoskeletalCollagenSubAnswercollagenDescribeComment"];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment"
    ];
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment"
    ];
  const ros_musculoskeletalExtremityFractureSubAnswerDescribeComment =
    formData["ros_musculoskeletalExtremityFractureSubAnswerDescribeComment"];
  const ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment =
    formData["ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment"];
  const ros_musculoskeletalExtremityFractureSubAnswerExtremityComment =
    formData["ros_musculoskeletalExtremityFractureSubAnswerExtremityComment"];
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment =
    formData[
      "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment"
    ];
  const ros_musculoskeletalGoutSubAnswerDescribeComment =
    formData["ros_musculoskeletalGoutSubAnswerDescribeComment"];
  const ros_musculoskeletalGoutSubAnswerSupportedByComment =
    formData["ros_musculoskeletalGoutSubAnswerSupportedByComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerDescribeComment =
    formData["ros_musculoskeletalHalluxValgusSubAnswerDescribeComment"];
  const ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment =
    formData["ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment"];
  const ros_musculoskeletalHammerToesSubAnswerDescribeComment =
    formData["ros_musculoskeletalHammerToesSubAnswerDescribeComment"];
  const ros_musculoskeletalHammerToesSubAnswerSupportedByComment =
    formData["ros_musculoskeletalHammerToesSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment =
    formData["ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment =
    formData["ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment"];
  const ros_musculoskeletalOsteoporosisSubAnswerDescribeComment =
    formData["ros_musculoskeletalOsteoporosisSubAnswerDescribeComment"];
  const ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment =
    formData["ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment"];
  const ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints"];

  const ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment =
    formData["ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment"];

  const ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment =
    formData["ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment =
    formData["ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints =
    formData["ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints"];
  const ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment =
    formData[
      "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment"
    ];
  const ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment"];
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment =
    formData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment"];
  const ros_musculoskeletalSystemicLupusSubAnswerDescribeComment =
    formData["ros_musculoskeletalSystemicLupusSubAnswerDescribeComment"];
  const ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment =
    formData["ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment"];

  const ros_hematologyVitaminDDeficiencySubAnswerDescribeComment =
    formData["ros_hematologyVitaminDDeficiencySubAnswerDescribeComment"];

  const ros_musculoskeletalMuskoOtherSubAnswerDescribeComment =
    formData["ros_musculoskeletalMuskoOtherSubAnswerDescribeComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment =
    formData["ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment"];
  const ros_musculoskeletalMuskoOtherSubAnswerother =
    formData["ros_musculoskeletalMuskoOtherSubAnswerother"];
  const ros_musculoskeletalHadAnAmputationSubAnswer =
    formData["ros_musculoskeletalHadAnAmputationSubAnswer"];

  const ros_hematologyComment = formData["ros_hematologyComment"];
  const ros_hematologyDiagnosesComment =
    formData["ros_hematologyDiagnosesComment"];
  const ros_hematologyAidsSubAnswerDescribeComment =
    formData["ros_hematologyAidsSubAnswerDescribeComment"];
  const ros_hematologyAidsSubAnswerSupportedByComment =
    formData["ros_hematologyAidsSubAnswerSupportedByComment"];
  const ros_hematologyAidsSubAnswerPatientUnderTreatmentComment =
    formData["ros_hematologyAidsSubAnswerPatientUnderTreatmentComment"];
  const ros_hematologyAnemiaSubAnswerDescribeComment =
    formData["ros_hematologyAnemiaSubAnswerDescribeComment"];
  const ros_hematologyAnemiaSubAnswerSupportedByComment =
    formData["ros_hematologyAnemiaSubAnswerSupportedByComment"];
  const ros_hematologyAnemiaSubAnswerEtiologyComment =
    formData["ros_hematologyAnemiaSubAnswerEtiologyComment"];
  const ros_hematologyAnemiaSubAnswerYesPatientOnComment =
    formData["ros_hematologyAnemiaSubAnswerYesPatientOnComment"];
  const ros_hematologyCDifficileSubAnswerDescribeComment =
    formData["ros_hematologyCDifficileSubAnswerDescribeComment"];
  const ros_hematologyCDifficileSubAnswerSupportedByComment =
    formData["ros_hematologyCDifficileSubAnswerSupportedByComment"];
  const ros_hematologyCommunityAcquiredSubAnswerDescribeComment =
    formData["ros_hematologyCommunityAcquiredSubAnswerDescribeComment"];
  const ros_hematologyCommunityAcquiredSubAnswerSupportedByComment =
    formData["ros_hematologyCommunityAcquiredSubAnswerSupportedByComment"];
  const ros_hematologyHivSubAnswerDescribeComment =
    formData["ros_hematologyHivSubAnswerDescribeComment"];
  const ros_hematologyHivSubAnswerViralLoad =
    formData["ros_hematologyHivSubAnswerViralLoad"];
  const ros_hematologyHivSubAnswerCFour =
    formData["ros_hematologyHivSubAnswerCFour"];
  const ros_hematologyHivSubAnswerSupportedByComment =
    formData["ros_hematologyHivSubAnswerSupportedByComment"];
  const ros_hematologyHivSubAnswerPatientSymptomaticComment =
    formData["ros_hematologyHivSubAnswerPatientSymptomaticComment"];
  const ros_hematologyHivSubAnswerActiveTreatmentComment =
    formData["ros_hematologyHivSubAnswerActiveTreatmentComment"];

  const ros_musculoskeletalMuskoHadAnAmputationComment =
    formData["ros_musculoskeletalMuskoHadAnAmputationComment"];

  const ros_hematologyHerpesZosterSubAnswerDescribeComment =
    formData["ros_hematologyHerpesZosterSubAnswerDescribeComment"];
  const ros_hematologyHerpesZosterSubAnswerSupportedByComment =
    formData["ros_hematologyHerpesZosterSubAnswerSupportedByComment"];
  const ros_hematologyMrsaInfectionSubAnswerDescribeComment =
    formData["ros_hematologyMrsaInfectionSubAnswerDescribeComment"];
  const ros_hematologyMrsaInfectionSubAnswerSupportedByComment =
    formData["ros_hematologyMrsaInfectionSubAnswerSupportedByComment"];
  const ros_hematologyImmuneDeficiencySubAnswerDescribeComment =
    formData["ros_hematologyImmuneDeficiencySubAnswerDescribeComment"];
  const ros_hematologyImmuneDeficiencySubAnswerEtiology =
    formData["ros_hematologyImmuneDeficiencySubAnswerEtiology"];
  const ros_hematologyImmuneDeficiencySubAnswerSupportedByComment =
    formData["ros_hematologyImmuneDeficiencySubAnswerSupportedByComment"];
  const ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment =
    formData[
      "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment"
    ];
  const ros_hematologySepsisSubAnswerDescribeComment =
    formData["ros_hematologySepsisSubAnswerDescribeComment"];
  const ros_hematologySepsisSubAnswerSupportedByComment =
    formData["ros_hematologySepsisSubAnswerSupportedByComment"];
  const ros_hematologySickleCellDiseaseSubAnswerDescribeComment =
    formData["ros_hematologySickleCellDiseaseSubAnswerDescribeComment"];
  const ros_hematologySickleCellDiseaseSubAnswerSupportedByComment =
    formData["ros_hematologySickleCellDiseaseSubAnswerSupportedByComment"];
  const ros_hematologySickleCellTraitSubAnswerDescribeComment =
    formData["ros_hematologySickleCellTraitSubAnswerDescribeComment"];
  const ros_hematologySickleCellTraitSubAnswerSupportedByComment =
    formData["ros_hematologySickleCellTraitSubAnswerSupportedByComment"];
  const ros_hematologyThalassemiaSubAnswerDescribeComment =
    formData["ros_hematologyThalassemiaSubAnswerDescribeComment"];
  const ros_hematologyThalassemiaSubAnswerSupportedByComment =
    formData["ros_hematologyThalassemiaSubAnswerSupportedByComment"];
  const ros_hematologyThrombocytopeniaSubAnswerDescribeComment =
    formData["ros_hematologyThrombocytopeniaSubAnswerDescribeComment"];
  const ros_hematologyThrombocytopeniaSubAnswerEtiology =
    formData["ros_hematologyThrombocytopeniaSubAnswerEtiology"];
  const ros_hematologyThrombocytopeniaSubAnswerSupportedByComment =
    formData["ros_hematologyThrombocytopeniaSubAnswerSupportedByComment"];
  const ros_hematologyTuberculosisSubAnswerDescribeComment =
    formData["ros_hematologyTuberculosisSubAnswerDescribeComment"];
  const ros_hematologyTuberculosisSubAnswerSupportedByComment =
    formData["ros_hematologyTuberculosisSubAnswerSupportedByComment"];
  const ros_hematologyTuberculosisSubAnswerGivenBCGComment =
    formData["ros_hematologyTuberculosisSubAnswerGivenBCGComment"];
  const ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment =
    formData["ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment"];
  const ros_hematologyTuberculosisSubAnswerTbInfectionComment =
    formData["ros_hematologyTuberculosisSubAnswerTbInfectionComment"];
  const ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment =
    formData["ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment"];
  const ros_hematologyHemaOtherSubAnswerDescribeComment =
    formData["ros_hematologyHemaOtherSubAnswerDescribeComment"];
  const ros_hematologyHemaOtherSubAnswerSupportedByComment =
    formData["ros_hematologyHemaOtherSubAnswerSupportedByComment"];
  const ros_hematologyHemaOtherSubAnswerOther =
    formData["ros_hematologyHemaOtherSubAnswerOther"];

  const ros_musculoskeletalMuskoHadAnAmputationVal = setOptionsSelectedYesNo(
    formData["ros_musculoskeletalMuskoHadAnAmputation"],
  );
  const ros_respiratoryAsthmaPatientControllerVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryAsthmaPatientController"],
  );
  const ros_respiratoryAsthmaRescueMedicationsVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryAsthmaRescueMedications"],
  );
  const ros_respiratoryAsthmaCurrentExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryAsthmaCurrentExacerbation"],
  );
  const ros_respiratoryPatientChronicBronchitisVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientChronicBronchitis"],
  );
  const ros_respiratoryPatientHaveEmphysemaVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientHaveEmphysema"],
  );
  const ros_respiratoryPatientBronchodilatorVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientBronchodilator"],
  );
  const ros_respiratoryPatientSteroidsVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientSteroids"],
  );
  const ros_respiratoryPatientExacerbationVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryPatientExacerbation"],
  );
  const ros_respiratoryHistoryOfLungAbscessVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryHistoryOfLungAbscess"],
  );
  const ros_respiratoryHistoryOfEmpyemaVal = setOptionsSelectedYesNo(
    formData["ros_respiratoryHistoryOfEmpyema"],
  );
  const ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal =
    setOptionsSelectedYesNo(
      formData["ros_integumentPsoriasisSubAnswerPsoriaticArthritis"],
    );
  const ros_hematologyAidsSubAnswerPatientUnderTreatmentVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyAidsSubAnswerPatientUnderTreatment"],
    );
  const ros_hematologyHivSubAnswerPatientSymptomaticVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyHivSubAnswerPatientSymptomatic"],
    );
  const ros_hematologyHivSubAnswerActiveTreatmentVal = setOptionsSelectedYesNo(
    formData["ros_hematologyHivSubAnswerActiveTreatment"],
  );
  const ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic"],
    );
  const ros_hematologyTuberculosisSubAnswerGivenBCG = setOptionsSelectedYesNo(
    formData["ros_hematologyTuberculosisSubAnswerGivenBCG"],
  );
  const ros_hematologyTuberculosisSubAnswerActiveTuberculosisVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyTuberculosisSubAnswerActiveTuberculosis"],
    );
  const ros_hematologyTuberculosisSubAnswerTbInfectionVal =
    setOptionsSelectedYesNo(
      formData["ros_hematologyTuberculosisSubAnswerTbInfection"],
    );
  const ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal =
    setOptionsSelectedYesNo(
      formData[
        "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder"
      ],
    );
  const ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal =
    setOptionsSelectedYesNo(
      formData[
        "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer"
      ],
    );
  const ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal =
    setOptionsSelectedYesNo(
      formData["ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder"],
    );
  const ros_respiratoryVal = setOptionsSelectedYesNo(
    formData["ros_respiratory"],
  );
  const ros_integumentVal = setOptionsSelectedYesNo(formData["ros_integument"]);
  const ros_musculoskeletalVal = setOptionsSelectedYesNo(
    formData["ros_musculoskeletal"],
  );
  const ros_hematologyVal = setOptionsSelectedYesNo(formData["ros_hematology"]);

  const ros_respiratoryAcutePulmonarySubOtherSubDesc =
    formData["ros_respiratoryAcutePulmonarySubOtherSubDesc"];

  const ros_respiratoryAcuteUpperSubOtherSubDesc =
    formData["ros_respiratoryAcuteUpperSubOtherSubDesc"];

  const ros_respiratoryAsthmaSubOtherSubDesc =
    formData["ros_respiratoryAsthmaSubOtherSubDesc"];

  const ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment =
    formData[
      "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment"
    ];

  const ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc =
    formData["ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc"];

  const ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc =
    formData["ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc"];

  const ros_respiratoryChronicSputumFailureSubOtherSubDesc =
    formData["ros_respiratoryChronicSputumFailureSubOtherSubDesc"];

  const ros_respiratoryCopdSubOtherSubDesc =
    formData["ros_respiratoryCopdSubOtherSubDesc"];

  const ros_respiratoryCopdSubPatientBronchodilatorComment =
    formData["ros_respiratoryCopdSubPatientBronchodilatorComment"];

  const ros_respiratoryCopdSubPatientSteroidsComment =
    formData["ros_respiratoryCopdSubPatientSteroidsComment"];

  const ros_respiratoryCopdSubPatientExacerbationComment =
    formData["ros_respiratoryCopdSubPatientExacerbationComment"];

  const ros_respiratoryCysticFibrosisSubDesc =
    formData["ros_respiratoryCysticFibrosisSubDesc"];

  const ros_respiratoryHypoventilationSubDesc =
    formData["ros_respiratoryHypoventilationSubDesc"];

  const ros_respiratoryHypoxemiaSubDesc =
    formData["ros_respiratoryHypoxemiaSubDesc"];

  const ros_respiratoryPneumoniaSubDesc =
    formData["ros_respiratoryPneumoniaSubDesc"];

  const ros_respiratoryPulmonaryFibrosisSubDesc =
    formData["ros_respiratoryPulmonaryFibrosisSubDesc"];

  const ros_respiratoryRespiratorDependenceSubDesc =
    formData["ros_respiratoryRespiratorDependenceSubDesc"];

  const ros_respiratoryRespiratoryArrestSubDesc =
    formData["ros_respiratoryRespiratoryArrestSubDesc"];

  const ros_respiratorySarcoidosisSubDesc =
    formData["ros_respiratorySarcoidosisSubDesc"];

  const ros_respiratorySleepApneaSubDesc =
    formData["ros_respiratorySleepApneaSubDesc"];

  const ros_integumentDermatitisSubOtherSubDesc =
    formData["ros_integumentDermatitisSubOtherSubDesc"];

  const ros_respiratoryOtherSubDesc = formData["ros_respiratoryOtherSubDesc"];

  const ros_integumentBasilCellCarcinomaSubOtherSubDesc =
    formData["ros_integumentBasilCellCarcinomaSubOtherSubDesc"];

  const ros_integumentEczemaSubOtherSubDesc =
    formData["ros_integumentEczemaSubOtherSubDesc"];

  const ros_integumentOnychomycosisSubOtherSubDesc =
    formData["ros_integumentOnychomycosisSubOtherSubDesc"];

  const ros_integumentPsoriasisSubOtherSubDesc =
    formData["ros_integumentPsoriasisSubOtherSubDesc"];

  const ros_integumentSkinUlcerSubOtherSubDesc =
    formData["ros_integumentSkinUlcerSubOtherSubDesc"];

  const ros_integumentSkinUlcerSubEtiologySubDesc =
    formData["ros_integumentSkinUlcerSubEtiologySubDesc"];

  const ros_integumentTineaPedisSubOtherSubDesc =
    formData["ros_integumentTineaPedisSubOtherSubDesc"];

  const ros_integumentUrticarialDiseaseSubOtherSubDesc =
    formData["ros_integumentUrticarialDiseaseSubOtherSubDesc"];

  const ros_integumentWoundSubOtherSubDesc =
    formData["ros_integumentWoundSubOtherSubDesc"];

  const ros_integumentIntegumentOtherSubOtherSubDesc =
    formData["ros_integumentIntegumentOtherSubOtherSubDesc"];

  const ros_hematologyAidsSubOtherSubDesc =
    formData["ros_hematologyAidsSubOtherSubDesc"];

  const ros_hematologyAidsSubPatientUnderTreatmentSubWhere =
    formData["ros_hematologyAidsSubPatientUnderTreatmentSubWhere"];

  const ros_hematologyAnemiaSubEtiologySubOtherSubDesc =
    formData["ros_hematologyAnemiaSubEtiologySubOtherSubDesc"];

  const ros_hematologyAnemiaSubotherSubDesc =
    formData["ros_hematologyAnemiaSubotherSubDesc"];

  const ros_hematologyAnemiaSubYesPatientOnSubDesc =
    formData["ros_hematologyAnemiaSubYesPatientOnSubDesc"];

  const ros_hematologyCDifficileSubOtherSubDesc =
    formData["ros_hematologyCDifficileSubOtherSubDesc"];

  const ros_hematologyCommunityAcquiredSubOtherSubDesc =
    formData["ros_hematologyCommunityAcquiredSubOtherSubDesc"];

  const ros_hematologyHivSubOtherSubDesc =
    formData["ros_hematologyHivSubOtherSubDesc"];

  const ros_hematologyHerpesZosterSubOtherSubDesc =
    formData["ros_hematologyHerpesZosterSubOtherSubDesc"];

  const ros_hematologyMrsaInfectionSubOtherSubDesc =
    formData["ros_hematologyMrsaInfectionSubOtherSubDesc"];

  const ros_hematologyImmuneDeficiencySubOtherSubDesc =
    formData["ros_hematologyImmuneDeficiencySubOtherSubDesc"];

  const ros_hematologySepsisSubOtherSubDesc =
    formData["ros_hematologySepsisSubOtherSubDesc"];

  const ros_hematologySickleCellDiseaseSubDesc =
    formData["ros_hematologySickleCellDiseaseSubDesc"];

  const ros_hematologySickleCellTraitSubOtherSubDesc =
    formData["ros_hematologySickleCellTraitSubOtherSubDesc"];

  const ros_hematologyThalassemiaSubOtherSubDesc =
    formData["ros_hematologyThalassemiaSubOtherSubDesc"];

  const ros_hematologyThrombocytopeniaSubOtherSubDesc =
    formData["ros_hematologyThrombocytopeniaSubOtherSubDesc"];

  const ros_hematologyTuberculosisSubOtherSubDesc =
    formData["ros_hematologyTuberculosisSubOtherSubDesc"];

  const ros_hematologyVitaminDDeficiencySubOtherSubDesc =
    formData["ros_hematologyVitaminDDeficiencySubOtherSubDesc"];

  const ros_hematologyHemaOtherSubOtherSubDesc =
    formData["ros_hematologyHemaOtherSubOtherSubDesc"];

  const ros_musculoskeletalCollagenSubOtherSubDesc =
    formData["ros_musculoskeletalCollagenSubOtherSubDesc"];

  const ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc =
    formData["ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc"];

  const ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc =
    formData[
      "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc"
    ];

  const ros_musculoskeletalExtremityFractureSubOtherSubDesc =
    formData["ros_musculoskeletalExtremityFractureSubOtherSubDesc"];

  const ros_musculoskeletalGoutSubOtherSubDesc =
    formData["ros_musculoskeletalGoutSubOtherSubDesc"];

  const ros_musculoskeletalHalluxValgusSubOtherSubDesc =
    formData["ros_musculoskeletalHalluxValgusSubOtherSubDesc"];

  const ros_musculoskeletalHammerToesSubOtherSubDesc =
    formData["ros_musculoskeletalHammerToesSubOtherSubDesc"];

  const ros_musculoskeletalOsteoarthritisSubOtherSubDesc =
    formData["ros_musculoskeletalOsteoarthritisSubOtherSubDesc"];

  const ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment =
    formData[
      "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment"
    ];

  const ros_musculoskeletalOsteomyelitisSubOtherSubDesc =
    formData["ros_musculoskeletalOsteomyelitisSubOtherSubDesc"];

  const ros_musculoskeletalOsteoporosisSubOtherSubDesc =
    formData["ros_musculoskeletalOsteoporosisSubOtherSubDesc"];

  const ros_musculoskeletalPyogenicArthritisSubOtherSubDesc =
    formData["ros_musculoskeletalPyogenicArthritisSubOtherSubDesc"];

  const ros_musculoskeletalRheumatoidArthritisSubDesc =
    formData["ros_musculoskeletalRheumatoidArthritisSubDesc"];

  // const ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms =
  //   formData["ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms"];

  const ros_musculoskeletalSpinalStenosisSubOtherSubDesc =
    formData["ros_musculoskeletalSpinalStenosisSubOtherSubDesc"];

  const ros_musculoskeletalSystemicLupusSubOtherSubDesc =
    formData["ros_musculoskeletalSystemicLupusSubOtherSubDesc"];

  const ros_musculoskeletalMuskoSubOtherSubDesc =
    formData["ros_musculoskeletalMuskoSubOtherSubDesc"];

  const ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureSubOther =
    formData["ros_musculoskeletalExtremityFractureSubOther"];

  const ros_musculoskeletalExtremityFractureShoulderSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureShoulderSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureArmSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureArmSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureForearmSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureForearmSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureWristSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureWristSubAnswerComment"];

  const ros_musculoskeletalHandSubAnswerComment =
    formData["ros_musculoskeletalHandSubAnswerComment"];

  const ros_musculoskeletalFemoralShaftSubAnswerComment =
    formData["ros_musculoskeletalFemoralShaftSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureTibiaSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureTibiaSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureFibulaSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureFibulaSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureAnkleSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureAnkleSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureFootSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureFootSubAnswerComment"];

  const ros_musculoskeletalExtremityFractureHipSubAnswerComment =
    formData["ros_musculoskeletalExtremityFractureHipSubAnswerComment"];

  const ros_diagnosisOfCancerRecommendationsSelected =
    formData["ros_diagnosisOfCancerRecommendations"];
  const ros_diagnosisOfCancerRecommendations = setSelectedCheckboxes(
    ros_diagnosisOfCancerRecommendationsSelected,
    ["ros_takemedicationsasprescribedCancerVal", "ros_otherCancerVal"],
    ["takemedicationsasprescribedCancer", "otherCancer"],
  );

  const gastro_recommendationsSelected = formData["ros_gastrorecommendations"];

  const ros_gastroSubDiagnosesceliacDiseaseGlueten = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosesceliacDiseaseGlueten"],
  );

  const ros_gastrorecommendations = setSelectedCheckboxes(
    gastro_recommendationsSelected,
    [
      "ros_gastrorecommendationstakemedicationsasprescribed",
      "ros_gastrorecommendationsother",
    ],
    ["takemedicationsasprescribed", "other"],
  );

  const ros_endocrineIsEndocrineRecommendationsSelected =
    formData["ros_endocrineIsEndocrineRecommendations"];

  const ros_endocrineIsEndocrineRecommendations = setSelectedCheckboxes(
    ros_endocrineIsEndocrineRecommendationsSelected,
    [
      "ros_takeMedicationsAsPescribedEndoEndocrineVal",
      "ros_checkBloodSugarEndoEndorineVal",
      "ros_otherEndoEndocrineVal",
    ],
    ["takeMedicationsAsPescribedEndo", "checkBloodSugarEndo", "otherEndo"],
  );

  const genito_recommendationsSelected = formData["ros_genitoRecommendations"];

  const ros_genitoRecommendations = setSelectedCheckboxes(
    genito_recommendationsSelected,
    ["ros_takemedicationsasprescribedGenitoVal", "ros_otherGenitoVal"],
    ["takeMedicationsAsPrescribedGenito", "otherGenito"],
  );

  const ros_diagnosisOfCancerSubDescComment =
    formData["ros_diagnosisOfCancerSubDescComment"];
  const ros_diagnosisOfCancerSubSupComment =
    formData["ros_diagnosisOfCancerSubSupComment"];
  const ros_diagnosisOfCancerSubTypeComment =
    formData["ros_diagnosisOfCancerSubTypeComment"];

  const ros_diagnosisOfCancerSubActiveTreatComment =
    formData["ros_diagnosisOfCancerSubActiveTreatComment"];
  const ros_diagnosisOfCancerSubSideEffComment =
    formData["ros_diagnosisOfCancerSubSideEffComment"];

  const ros_diagnosisOfCancerros_activeTreatmentComment =
    formData["ros_diagnosisOfCancerros_activeTreatmentComment"];

  const ros_diagnosisOfCancerros_SeeASpecComment =
    formData["ros_diagnosisOfCancerros_SeeASpecComment"];

  const ros_diagnosisOfCancerSubMetastasisCachexiaValComment =
    formData["ros_diagnosisOfCancerSubMetastasisCachexiaValComment"];
  const ros_diagnosisOfCancerros_MetastasisComment =
    formData["ros_diagnosisOfCancerros_MetastasisComment"];

  const ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment =
    formData[
      "ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment"
    ];

  const ros_diagnosisOfCancerRecommendationsotherCancerComment =
    formData["ros_diagnosisOfCancerRecommendationsotherCancerComment"];

  const ros_diagnosisOfCancerVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancer"],
  );

  const ros_diagnosisOfCancerSubActiveTreatVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_activeTreatment"],
  );

  const ros_diagnosisOfCancerros_MetastasisVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_Metastasis"],
  );

  const ros_diagnosisOfCancerros_SeeASpecVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerros_SeeASpec"],
  );

  const ros_MetastasisCachexiaVal = setOptionsSelectedYesNo(
    formData["ros_diagnosisOfCancerSubMetastasisCachexiaVal"],
  );

  const ros_MetastasisLocVal =
    formData["ros_diagnosisOfCancerSubMetastasisLocVal"];

  const ros_SpecProVal = formData["ros_diagnosisOfCancerSubSeeASpecProVal"];

  const ros_diagnosisOfCancerCommentVal =
    formData["ros_diagnosisOfCancerComment"];

  const ros_diagnosisOfCancerspecialTypes =
    formData["ros_diagnosisOfCancerspecialTypes"];

  const ros_diagnosisOfCancerstageSpecificToTheCancer =
    formData["ros_diagnosisOfCancerstageSpecificToTheCancer"];

  const ros_cardioVal = setOptionsSelectedYesNo(formData["ros_cardio"]);
  const ros_cardioComment = formData["ros_cardioComment"];

  const ros_cardiovascularRecommendationsSelected =
    formData["ros_cardiovascularRecommendations"];

  const ros_gastroSubDiagnosescolonCrDesComment =
    formData["ros_gastroSubDiagnosescolonCrDesComment"];
  const ros_gastroSubDiagnosesdescolonComment =
    formData["ros_gastroSubDiagnosesdescolonComment"];
  const ros_gastroSubDiagnosescolonSupComment =
    formData["ros_gastroSubDiagnosescolonSupComment"];

  const ros_cardiovascularRecommendations = setSelectedCheckboxes(
    ros_cardiovascularRecommendationsSelected,
    [
      "ros_takemedicationsasprescribedCardioVal",
      "ros_otherCardioVal",
      "ros_ThirtyCardioVal",
      "ros_bloodPreCardioVal",
      "ros_heartHeCardioVal",
    ],
    [
      "takeMedicationsAsPescribed",
      "other",
      "exerciseThirtyMinADay",
      "bloodPressureChecks",
      "heartHealthyDiet",
    ],
  );

  const ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment =
    formData[
      "ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment"
    ];

  const ros_cardiovascularRecommendationsotherComment =
    formData["ros_cardiovascularRecommendationsotherComment"];
  const ros_cardiovascularRecommendationsexerciseThirtyMinADayComment =
    formData["ros_cardiovascularRecommendationsexerciseThirtyMinADayComment"];
  const ros_cardiovascularRecommendationsbloodPressureChecksComment =
    formData["ros_cardiovascularRecommendationsbloodPressureChecksComment"];
  const ros_cardiovascularRecommendationsheartHealthyDietComment =
    formData["ros_cardiovascularRecommendationsheartHealthyDietComment"];

  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment"];

  const ros_endoVal = setOptionsSelectedYesNo(formData["ros_endoVal"]);
  const ros_gastro = setOptionsSelectedYesNo(formData["ros_gastro"]);
  const ros_genitoVal = setOptionsSelectedYesNo(formData["ros_genitoVal"]);

  const ros_endoValComment = formData["ros_endoValComment"];
  const ros_gastroComment = formData["ros_gastroComment"];
  const ros_genitoValComment = formData["ros_genitoValComment"];

  const ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment"
    ];

  const ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment =
    formData[
      "ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment"
    ];

  const ros_endocrineIsEndocrineRecommendationsotherEndoComment =
    formData["ros_endocrineIsEndocrineRecommendationsotherEndoComment"];

  const ros_gastrorecommendationsotherComment =
    formData["ros_gastrorecommendationsotherComment"];

  const ros_gastrorecommendationstakemedicationsasprescribedComment =
    formData["ros_gastrorecommendationstakemedicationsasprescribedComment"];
  const ros_gastroSubDiagnosesComment =
    formData["ros_gastroSubDiagnosesComment"];
  const ros_gastroSubDiagnosescachexiadesComment =
    formData["ros_gastroSubDiagnosescachexiadesComment"];
  const ros_gastroSubDiagnosescachexiaSupComment =
    formData["ros_gastroSubDiagnosescachexiaSupComment"];

  const ros_genitoSubDiagnosesComment =
    formData["ros_genitoSubDiagnosesComment"];
  const ros_endocrineIsEndocrineDiagnoseComment =
    formData["ros_endocrineIsEndocrineDiagnoseComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureEti =
    formData["ros_genitoSubDiagnosesacuteRenalFailureEti"];

  const ros_genitoSubDiagnosesacuteRenalFailuredesComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailuredesComment"];

  const ros_gastroSubDiagnosesbowelObstructionSupComment =
    formData["ros_gastroSubDiagnosesbowelObstructionSupComment"];
  const ros_gastroSubDiagnosesdesbowelObstructionComment =
    formData["ros_gastroSubDiagnosesdesbowelObstructionComment"];

  const ros_cardioSubDiagnosesAneurysmDesComment =
    formData["ros_cardioSubDiagnosesAneurysmDesComment"];

  const ros_cardioSubDiagnosesComment =
    formData["ros_cardioSubDiagnosesComment"];

  const ros_gastroSubDiagnosesceliacDiseaseGluetenComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseGluetenComment"];
  const ros_gastroSubDiagnosesceliacDiseaseSupComment =
    formData["ros_gastroSubDiagnosesceliacDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesceliacDiseaseComment =
    formData["ros_gastroSubDiagnosesdesceliacDiseaseComment"];

  const ros_gastroSubDiagnosescirrhosisEndStage = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnosescirrhosisEndStage"],
  );
  const ros_gastroSubDiagnosescirrhosisEndStageComment =
    formData["ros_gastroSubDiagnosescirrhosisEndStageComment"];

  const ros_gastroSubDiagnosesdescirrhosisComment =
    formData["ros_gastroSubDiagnosesdescirrhosisComment"];
  const ros_gastroSubDiagnosescirrhosisSupComment =
    formData["ros_gastroSubDiagnosescirrhosisSupComment"];
  const ros_gastroSubDiagnosescolonPolypsDesComment =
    formData["ros_gastroSubDiagnosescolonPolypsDesComment"];

  const ros_cardioSubDiagnosesAneurysmCrDesComment =
    formData["ros_cardioSubDiagnosesAneurysmCrDesComment"];
  const ros_cardioSubDiagnosesAneurysmSupComment =
    formData["ros_cardioSubDiagnosesAneurysmSupComment"];

  const ros_cardioSubDiagnosesAnginaCrDesComment =
    formData["ros_cardioSubDiagnosesAnginaCrDesComment"];
  const ros_cardioSubDiagnosesAnginaSupComment =
    formData["ros_cardioSubDiagnosesAnginaSupComment"];
  const ros_cardioSubDiagnosesAnginaDesComment =
    formData["ros_cardioSubDiagnosesAnginaDesComment"];

  const ros_cardioSubDiagnosesAtrialFibrillationDesComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationDesComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationTypeComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationTypeComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment =
    formData["ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment"];

  const cardio_RecommendationsComment =
    formData["cardio_RecommendationsComment"];
  const ros_cardioSubDiagnosesAneurysmSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAneurysmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAnginaSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAnginaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockSupComment =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockSupComment"];
  const ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer"];
  const ros_cardioSubDiagnosesHypertensionSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer"];
  const ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer"];
  const ros_cardioSubDiagnosesOtherSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesOtherSupotherSubAnswer"];
  const ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers =
    formData[
      "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers"
    ];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest"],
    );
  const ros_cardioSubDiagnosesCardiomyopathyDesComment =
    formData["ros_cardioSubDiagnosesCardiomyopathyDesComment"];
  const ros_cardioSubDiagnosesCardiomyopathyTypeComment =
    formData["ros_cardioSubDiagnosesCardiomyopathyTypeComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupComment =
    formData["ros_cardioSubDiagnosesCardiomyopathySupComment"];
  const ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer =
    formData["ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE"],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension =
    setOptionsSelectedYesNo(
      formData[
        "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension"
      ],
    );
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment =
    formData[
      "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureSupComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureSupComment"];
  const ros_cardioSubDiagnosesCongestiveHeartFailureDesComment =
    formData["ros_cardioSubDiagnosesCongestiveHeartFailureDesComment"];

  const ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisDesComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisDesComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisSupComment"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer"];
  const ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment =
    formData[
      "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment"
    ];

  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistent =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistent"],
    );
  const ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment =
    formData["ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment"];

  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment"];
  const ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin"],
    );
  const ros_cardioSubDiagnosesHyperlipidemiaDesComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiaDesComment"];
  const ros_cardioSubDiagnosesHyperlipidemiaSupComment =
    formData["ros_cardioSubDiagnosesHyperlipidemiaSupComment"];

  const ros_cardioSubDiagnosesHypertensionSupComment =
    formData["ros_cardioSubDiagnosesHypertensionSupComment"];
  const ros_cardioSubDiagnosesHypertensionDesComment =
    formData["ros_cardioSubDiagnosesHypertensionDesComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlledComment =
    formData["ros_cardioSubDiagnosesHypertensionadequatelyControlledComment"];
  const ros_cardioSubDiagnosesHypertensionadequatelyControlled =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_cardioSubDiagnosesHypertensionadequatelyControlled"],
    );
  const ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment"];
  const ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment =
    formData["ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment"];
  const ros_cardioSubDiagnosesMyocardialDesComment =
    formData["ros_cardioSubDiagnosesMyocardialDesComment"];
  const ros_cardioSubDiagnosesMyocardialDate =
    formData["ros_cardioSubDiagnosesMyocardialDate"];
  const ros_cardioSubDiagnosesMyocardialSupotherSubAnswer =
    formData["ros_cardioSubDiagnosesMyocardialSupotherSubAnswer"];
  const ros_cardioSubDiagnosesMyocardialSupComment =
    formData["ros_cardioSubDiagnosesMyocardialSupComment"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment =
    formData["ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment"];
  const ros_cardioSubDiagnosesMyocardialBetaBlocker = setOptionsSelectedYesNo(
    formData["ros_cardioSubDiagnosesMyocardialBetaBlocker"],
  );
  const ros_cardioSubDiagnosesMyocardialBetaBlockerComment =
    formData["ros_cardioSubDiagnosesMyocardialBetaBlockerComment"];
  const ros_cardioSubDiagnosesMyocardialIspateintComment =
    formData["ros_cardioSubDiagnosesMyocardialIspateintComment"];
  const ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer =
    formData["ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer"];

  const ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment =
    formData["ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment"];
  const ros_cardiohistoryDiabetesisHistoryDiabetesComment =
    formData["ros_cardiohistoryDiabetesisHistoryDiabetesComment"];
  const ros_cardiopvdDescribeComment = formData["ros_cardiopvdDescribeComment"];
  const ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer =
    formData[
      "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer"
    ];
  const ros_cardiohistoryDiabetesisHistoryDiabetes = setOptionsSelectedYesNo(
    formData["ros_cardiohistoryDiabetesisHistoryDiabetes"],
  );
  const ros_cardioSubDiagnosesPulmonaryHypertensionSupComment =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionSupComment"];
  const ros_cardioSubDiagnosesPulmonaryHypertensionDesComment =
    formData["ros_cardioSubDiagnosesPulmonaryHypertensionDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacement =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacement"],
    );
  const ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement =
    formData["ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement"];
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation"],
    );
  const ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment =
    formData[
      "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment"
    ];
  const ros_cardioSubDiagnosesvalvulardiseaseSupComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseSupComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseDesComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseDesComment"];
  const ros_cardioSubDiagnosesOtherOtherSub =
    formData["ros_cardioSubDiagnosesOtherOtherSub"];
  const ros_cardioSubDiagnosesOtherSupComment =
    formData["ros_cardioSubDiagnosesOtherSupComment"];
  const ros_cardioSubDiagnosesOtherDesComment =
    formData["ros_cardioSubDiagnosesOtherDesComment"];
  const ros_cardioSubDiagnosesvalvulardiseaseCrDesComment =
    formData["ros_cardioSubDiagnosesvalvulardiseaseCrDesComment"];
  const ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescolonOtherSubAnswerVal =
    formData["ros_gastroSubDiagnosescolonOtherSubAnswerVal"];
  const ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgerdSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgerdSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal"];
  const ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal"
    ];
  const ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosesotherSupotherSubAnswerVal =
    formData["ros_gastroSubDiagnosesotherSupotherSubAnswerVal"];
  const ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment =
    formData["ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment"];
  const ros_gastroSubDiagnosesdesdiverticulitisComment =
    formData["ros_gastroSubDiagnosesdesdiverticulitisComment"];
  const ros_gastroSubDiagnosesdiverticulitisabscessVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitisabscessVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisabscessValComment =
    formData["ros_gastroSubDiagnosesdiverticulitisabscessValComment"];

  const ros_gastroSubDiagnosesdiverticulitisperforationVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitisperforationVal"],
    );

  const ros_gastroSubDiagnosesdiverticulitisperforationValComment =
    formData["ros_gastroSubDiagnosesdiverticulitisperforationValComment"];

  const ros_gastroSubDiagnosesdiverticulitishighFiberDietVal =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesdiverticulitishighFiberDietVal"],
    );
  const ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment =
    formData["ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment"];

  const ros_gastroSubDiagnosesdiverticulitisSupComment =
    formData["ros_gastroSubDiagnosesdiverticulitisSupComment"];
  const ros_gastroSubDiagnosesgallBladderDiseaseSupComment =
    formData["ros_gastroSubDiagnosesgallBladderDiseaseSupComment"];
  const ros_gastroSubDiagnosesdesgallBladderDiseaseComment =
    formData["ros_gastroSubDiagnosesdesgallBladderDiseaseComment"];
  const ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesgastroparesisSupportedByComment =
    formData["ros_gastroSubDiagnosesgastroparesisSupportedByComment"];
  const ros_gastroSubDiagnosesgastroparesisDescribeComment =
    formData["ros_gastroSubDiagnosesgastroparesisDescribeComment"];

  const ros_gastroSubDiagnosesgerdDescribeComment =
    formData["ros_gastroSubDiagnosesgerdDescribeComment"];
  const ros_gastroSubDiagnosesgerdSupportedByComment =
    formData["ros_gastroSubDiagnosesgerdSupportedByComment"];
  const ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedDescribeComment =
    formData["ros_gastroSubDiagnosesglBleedDescribeComment"];
  const ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesglBleedSupportedByComment =
    formData["ros_gastroSubDiagnosesglBleedSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDescribeComment =
    formData["ros_gastroSubDiagnoseshepatitisDescribeComment"];
  const ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnoseshepatitisSupportedByComment =
    formData["ros_gastroSubDiagnoseshepatitisSupportedByComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment =
    formData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment"];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherDescribeComment =
    formData["ros_gastroSubDiagnosesotherDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisDescribeComment =
    formData["ros_gastroSubDiagnosespancreatitisDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesotherSupportedByComment =
    formData["ros_gastroSubDiagnosesotherSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseSupportedByComment"];
  const ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe"
    ];
  const ros_gastroSubDiagnosesulcerDiseaseDescribeComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseDescribeComment"];
  const ros_gastroSubDiagnosespancreatitisSupportedByComment =
    formData["ros_gastroSubDiagnosespancreatitisSupportedByComment"];
  const ros_gastroSubDiagnoseshepatitisDessComment =
    formData["ros_gastroSubDiagnoseshepatitisDessComment"];
  const ros_gastroSubDiagnoseshepatitisTypeComment =
    formData["ros_gastroSubDiagnoseshepatitisTypeComment"];
  const ros_gastroSubDiagnoseshepatitisCirrhosis = setOptionsSelectedYesNo(
    formData["ros_gastroSubDiagnoseshepatitisCirrhosis"],
  );

  const ros_gastroSubDiagnoseshepatitisCirrhosisComment =
    formData["ros_gastroSubDiagnoseshepatitisCirrhosisComment"];

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma"],
    );

  const ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment =
    formData["ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment"];

  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment"
    ];
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet"],
    );
  const ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment =
    formData[
      "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment"
    ];
  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodes =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodes"],
    );

  const ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment =
    formData["ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment"];

  const ros_gastroSubDiagnosespancreatitisSpecificDiet =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosespancreatitisSpecificDiet"],
    );

  const ros_gastroSubDiagnosespancreatitisSpecificDietComment =
    formData["ros_gastroSubDiagnosespancreatitisSpecificDietComment"];
  const ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe =
    formData["ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe"];
  const ros_gastroSubDiagnosesulcerDiseaseComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment =
    formData["ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment"];
  const ros_gastroSubDiagnosesulcerDiseaseHeliobactria =
    setOptionsSelectedYesNo(
      formData["ros_gastroSubDiagnosesulcerDiseaseHeliobactria"],
    );
  const ros_gastroSubDiagnosesotherOther =
    formData["ros_gastroSubDiagnosesotherOther"];

  const ros_genito = setOptionsSelectedYesNo(formData["ros_genito"]);
  const ros_genitoComment = formData["ros_genitoComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupComment =
    formData["ros_genitoSubDiagnosesacuteRenalFailureSupComment"];
  const ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal =
    formData["ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal"];
  const ros_genitoSubDiagnosesacuteRenalFailureEtiology =
    formData["ros_genitoSubDiagnosesacuteRenalFailureEtiology"];
  const ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesbphSupportedByComment =
    formData["ros_genitoSubDiagnosesbphSupportedByComment"];
  const ros_genitoSubDiagnosesbphDescribeComment =
    formData["ros_genitoSubDiagnosesbphDescribeComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment =
    formData["ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment"];

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer =
    formData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment =
    formData["ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment"];
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes =
    setOptionsSelectedYesNoUnknownOther(
      formData["ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes"],
    );
  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment =
    formData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment"
    ];

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension =
    setOptionsSelectedYesNoUnknownOther(
      formData[
        "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension"
      ],
    );

  const ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment =
    formData[
      "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment"
    ];
  const ros_genitoSubDiagnoseswhatStageComment =
    formData["ros_genitoSubDiagnoseswhatStageComment"];
  const ros_genitoSubDiagnosesesrdDescribeComment =
    formData["ros_genitoSubDiagnosesesrdDescribeComment"];
  const ros_genitoSubDiagnosesesrdSupportedByComment =
    formData["ros_genitoSubDiagnosesesrdSupportedByComment"];
  const ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisComment =
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysisComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment =
    formData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment"];
  const ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet =
    setOptionsSelectedYesNo(
      formData["ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet"],
    );
  const ros_genitoSubDiagnosesesrdPatientOnDialysis = setOptionsSelectedYesNo(
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysis"],
  );
  const ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment =
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment"];
  const ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment =
    formData["ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment"];
  const ros_genitoSubDiagnosesesrddialysisSchedule =
    formData["ros_genitoSubDiagnosesesrddialysisSchedule"];
  const ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice =
    formData["ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice"];
  const ros_genitoSubDiagnoseserectileDysfunctionDescribeComment =
    formData["ros_genitoSubDiagnoseserectileDysfunctionDescribeComment"];
  const ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer =
    formData[
      "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment =
    formData["ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment"];

  const ros_genitoSubDiagnosesfrequentUtiDescribeComment =
    formData["ros_genitoSubDiagnosesfrequentUtiDescribeComment"];
  const ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesfrequentUtiSupportedByComment =
    formData["ros_genitoSubDiagnosesfrequentUtiSupportedByComment"];

  const ros_genitoSubDiagnosesgynecologicalDescribeComment =
    formData["ros_genitoSubDiagnosesgynecologicalDescribeComment"];
  const ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesgynecologicalSupportedByComment =
    formData["ros_genitoSubDiagnosesgynecologicalSupportedByComment"];

  const ros_genitoSubDiagnoseskidneyStonesDescribeComment =
    formData["ros_genitoSubDiagnoseskidneyStonesDescribeComment"];
  const ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnoseskidneyStonesSupportedByComment =
    formData["ros_genitoSubDiagnoseskidneyStonesSupportedByComment"];
  const ros_genitoSubDiagnoseskidneyStonesTypeComment =
    formData["ros_genitoSubDiagnoseskidneyStonesTypeComment"];

  const ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment =
    formData["ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment"];
  const ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer =
    formData[
      "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment =
    formData["ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment"];

  const ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment =
    formData["ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment"];
  const ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer =
    formData[
      "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer"
    ];
  const ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment =
    formData["ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment"];

  const ros_genitoSubDiagnosesotherDescribeComment =
    formData["ros_genitoSubDiagnosesotherDescribeComment"];
  const ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer =
    formData["ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer"];
  const ros_genitoSubDiagnosesotherSupportedByComment =
    formData["ros_genitoSubDiagnosesotherSupportedByComment"];
  const ros_genitoSubDiagnosesotherOther =
    formData["ros_genitoSubDiagnosesotherOther"];
  const ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment =
    formData["ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment"];
  const ros_genitoStress = setOptionsSelectedYesNo(
    formData["ros_genitoStress"],
  );
  const ros_genitoStressComment = formData["ros_genitoStressComment"];
  const ros_genitoStressSubComment = formData["ros_genitoStressSubComment"];

  // const ros_otherGenitoVal = formData["ros_otherGenitoVal"];
  const ros_endoComment = formData["ros_endoComment"];
  const ros_endocrineIsEndocrine = setOptionsSelectedYesNo(
    formData["ros_endocrineIsEndocrine"],
  );
  const ros_endocrineIsEndocrineComment =
    formData["ros_endocrineIsEndocrineComment"];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe"
    ];
  const ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment =
    formData[
      "ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment"];
  const ros_genitoRecommendationsotherGenitoComment =
    formData["ros_genitoRecommendationsotherGenitoComment"];
  const ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment =
    formData[
      "ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment"
    ];

  const ros_diagnosisOfCancerSubSupOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubSupOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer"];
  const ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer"];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment =
    formData[
      "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin"
      ],
    );
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment =
    formData[
      "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment"
    ];
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin"
      ],
    );
  const ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment =
    formData[
      "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment =
    formData["ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe =
    formData["ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe"];
  const ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubtypeComment =
    formData["ros_endocrineIsEndocrineSubdiabetesSubtypeComment"];
  const ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue =
    formData["ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue"];
  const ros_endocrineIsEndocrineSubdiabetesSubandDate =
    formData["ros_endocrineIsEndocrineSubdiabetesSubandDate"];
  const ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician =
    setOptionsSelectedYesNo(
      formData["ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician"],
    );
  const ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator"
      ],
    );
  const ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar"
      ],
    );
  const ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment"
    ];
  const ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment =
    formData["ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment"];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment =
    formData[
      "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe =
    formData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease =
    setOptionsSelectedYesNoUnknownOther(
      formData[
        "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease"
      ],
    );
  const ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment =
    formData[
      "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment =
    formData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer"
    ];
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce"
      ],
    );
  const ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment =
    formData[
      "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment"];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment =
    formData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment"
    ];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe =
    formData[
      "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe"
    ];
  const ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment =
    formData["ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment =
    formData["ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment =
    formData["ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment"];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist =
    setOptionsSelectedYesNo(
      formData[
        "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist"
      ],
    );
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment =
    formData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer =
    formData[
      "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer"
    ];
  const ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment =
    formData["ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherDescribeComment =
    formData["ros_endocrineIsEndocrineDiagnoseotherDescribeComment"];
  const ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer =
    formData["ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer"];
  const ros_endocrineIsEndocrineDiagnoseotherSupComment =
    formData["ros_endocrineIsEndocrineDiagnoseotherSupComment"];
  const ros_endocrineIsEndocrineDiagnoseotherOther =
    formData["ros_endocrineIsEndocrineDiagnoseotherOther"];
  const ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment"
    ];
  const ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment =
    formData[
      "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment"
    ];

  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertension =
    setOptionsSelectedYesNo(
      formData["ros_cardioSubDiagnosesCardiompathysecondaryToHypertension"],
    );
  const ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment =
    formData[
      "ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment"
    ];
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial =
    setOptionsSelectedYesNo(
      formData[
        "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial"
      ],
    );
  const ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment =
    formData[
      "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment"
    ];
  const ros_diagnosisOfCancerSubTypeOtherValSubAnswer =
    formData["ros_diagnosisOfCancerSubTypeOtherValSubAnswer"];
  const ros_cardioSubDiagnosesCardiorespiratoryShockDesComment =
    formData["ros_cardioSubDiagnosesCardiorespiratoryShockDesComment"];
  tempData = {
    ...tempData,
    ros_respiratoryAcutePulmonarySubOtherSubDesc,
    ros_respiratoryAcuteUpperSubOtherSubDesc,
    ros_respiratoryAsthmaSubOtherSubDesc,
    ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbationComment,
    ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc,
    ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc,
    ros_respiratoryChronicSputumFailureSubOtherSubDesc,
    ros_respiratoryCopdSubOtherSubDesc,
    ros_respiratoryCopdSubPatientBronchodilatorComment,
    ros_respiratoryCopdSubPatientExacerbationComment,
    ros_respiratoryCysticFibrosisSubDesc,
    ros_respiratoryHypoventilationSubDesc,
    ros_respiratoryHypoxemiaSubDesc,
    ros_respiratoryPneumoniaSubDesc,
    ros_respiratoryPulmonaryFibrosisSubDesc,
    ros_respiratoryRespiratorDependenceSubDesc,
    ros_respiratoryRespiratoryArrestSubDesc,
    ros_respiratorySarcoidosisSubDesc,
    ros_respiratorySleepApneaSubDesc,
    ros_respiratoryOtherSubDesc,
    ros_integumentBasilCellCarcinomaSubOtherSubDesc,
    ros_integumentDermatitisSubOtherSubDesc,
    ros_integumentEczemaSubOtherSubDesc,
    ros_integumentOnychomycosisSubOtherSubDesc,
    ros_integumentPsoriasisSubOtherSubDesc,
    ros_integumentSkinUlcerSubOtherSubDesc,
    ros_integumentSkinUlcerSubEtiologySubDesc,
    ros_integumentTineaPedisSubOtherSubDesc,
    ros_integumentUrticarialDiseaseSubOtherSubDesc,
    ros_musculoskeletalMuskoHadAnAmputationComment,
    ros_integumentWoundSubOtherSubDesc,
    ros_hematologyAidsSubOtherSubDesc,
    ros_hematologyAidsSubPatientUnderTreatmentSubWhere,
    ros_hematologyAnemiaSubEtiologySubOtherSubDesc,
    ros_hematologyAnemiaSubYesPatientOnSubDesc,
    ros_hematologyAnemiaSubotherSubDesc,
    ros_hematologyCDifficileSubOtherSubDesc,
    ros_hematologyCommunityAcquiredSubOtherSubDesc,
    ros_hematologyHivSubOtherSubDesc,
    ros_hematologyMrsaInfectionSubOtherSubDesc,
    ros_hematologyHerpesZosterSubOtherSubDesc,
    ros_hematologyImmuneDeficiencySubOtherSubDesc,
    ros_hematologySepsisSubOtherSubDesc,
    ros_hematologySickleCellDiseaseSubDesc,
    ros_hematologySickleCellTraitSubOtherSubDesc,
    ros_hematologyHemaOtherSubOtherSubDesc,
    ros_respiratoryCopdSubPatientSteroidsComment,
    ros_integumentIntegumentOtherSubOtherSubDesc,
    ros_hematologyThrombocytopeniaSubOtherSubDesc,
    ros_hematologyThalassemiaSubOtherSubDesc,
    ros_hematologyTuberculosisSubOtherSubDesc,
    ros_hematologyVitaminDDeficiencySubOtherSubDesc,
    ros_musculoskeletalCollagenSubOtherSubDesc,
    ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc,
    ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc,
    ros_hematologyVitaminDDeficiencySubAnswerDescribeComment,
    ros_musculoskeletalExtremityFractureSubOtherSubDesc,
    ros_musculoskeletalRheumatoidArthritisSubAnswerSymptomsComment,
    ros_musculoskeletalGoutSubOtherSubDesc,
    ros_musculoskeletalHalluxValgusSubOtherSubDesc,
    ros_musculoskeletalHammerToesSubOtherSubDesc,
    ros_musculoskeletalOsteoarthritisSubOtherSubDesc,
    ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswerComment,
    ros_musculoskeletalOsteomyelitisSubOtherSubDesc,
    ros_musculoskeletalOsteoporosisSubOtherSubDesc,
    ros_musculoskeletalPyogenicArthritisSubOtherSubDesc,
    ros_musculoskeletalRheumatoidArthritisSubDesc,
    // ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms,
    ros_musculoskeletalSpinalStenosisSubOtherSubDesc,
    ros_musculoskeletalSystemicLupusSubOtherSubDesc,
    ros_musculoskeletalMuskoSubOtherSubDesc,
    ros_musculoskeletalExtremityFractureActiveTypeSubAnswerComment,
    ros_musculoskeletalExtremityFractureHistoryOfSubAnswerComment,
    ros_musculoskeletalExtremityFractureSubOther,
    ros_musculoskeletalExtremityFractureShoulderSubAnswerComment,
    ros_musculoskeletalExtremityFractureArmSubAnswerComment,
    ros_musculoskeletalExtremityFractureForearmSubAnswerComment,
    ros_musculoskeletalExtremityFractureWristSubAnswerComment,
    ros_musculoskeletalHandSubAnswerComment,
    ros_musculoskeletalFemoralShaftSubAnswerComment,
    ros_musculoskeletalExtremityFractureTibiaSubAnswerComment,
    ros_musculoskeletalExtremityFractureFibulaSubAnswerComment,
    ros_musculoskeletalExtremityFractureAnkleSubAnswerComment,
    ros_musculoskeletalExtremityFractureFootSubAnswerComment,
    ros_musculoskeletalExtremityFractureHipSubAnswerComment,
    ros_eyesVal,
    ros_eyesCommentVal,
    ros_eyesDiagCommentVal,
    ros_eyesDiagCataractSubWhEyeCommentVal,
    ros_eyesDiagCataractSubDescCommentVal,
    ros_eyesDiagCataractSubSuppCommentVal,
    ros_eyesDiagCataractSubSuppSympSubCommentVal,
    ros_eyesDiagCataractSubSuppOtherSubDescVal,
    ros_eyesDiagCataractSubDiabetesVal,
    ros_eyesDiagCataractSubDiabetesCommentVal,
    ros_eyesDiagGlaucomaSubWhEyeCommentVal,
    ros_eyesDiagGlaucomaSubDescCommentVal,
    ros_eyesDiagGlaucomaSubSuppCommentVal,
    ros_eyesDiagGlaucomaSubSuppSympSubCommentVal,
    ros_eyesDiagGlaucomaSubSuppOtherSubDescVal,
    ros_eyesDiagGlaucomaSubDiabetesVal,
    ros_eyesDiagGlaucomaSubDiabetesCommentVal,
    ros_eyesDiagGlaucomaSubDiabetesSubCommentVal,
    ros_eyesDiagHyperopiaSubWhEyeCommentVal,
    ros_eyesDiagHyperopiaSubDescCommentVal,
    ros_eyesDiagHyperopiaSubSuppCommentVal,
    ros_eyesDiagHyperopiaSubSuppSympSubCommentVal,
    ros_eyesDiagHyperopiaSubSuppOtherSubDescVal,
    ros_eyesDiagLegalBlindSubWhEyeCommentVal,
    ros_eyesDiagLegalBlindSubDescCommentVal,
    ros_eyesDiagLegalBlindSubSuppCommentVal,
    ros_eyesDiagLegalBlindSubSuppSympSubCommentVal,
    ros_eyesDiagLegalBlindSubSuppOtherSubDescVal,
    ros_eyesDiagLegalBlindSubCommentVal,
    ros_eyesDiagLegalBlindSubDescTextVal,
    ros_eyesDiagMascDegenSubWhEyeCommentVal,
    ros_eyesDiagMascDegenSubDescCommentVal,
    ros_eyesDiagMascDegenSubSuppCommentVal,
    ros_eyesDiagMascDegenSubSuppSympSubCommentVal,
    ros_eyesDiagMascDegenSubSuppOtherSubDescVal,
    ros_eyesDiagMascDegenSubCommentVal,
    ros_eyesDiagMascDegenSubDesc2Val,
    ros_eyesDiagMascDegenSubDesc2CommentVal,
    ros_eyesDiagMyopiaSubWhEyeCommentVal,
    ros_eyesDiagMyopiaSubDescCommentVal,
    ros_eyesDiagMyopiaSubSuppCommentVal,
    ros_eyesDiagMyopiaSubSuppSympSubCommentVal,
    ros_eyesDiagMyopiaSubSuppOtherSubDescVal,
    ros_eyesDiagRetinalSubWhEyeCommentVal,
    ros_eyesDiagRetinalSubDescCommentVal,
    ros_eyesDiagRetinalSubSuppCommentVal,
    ros_eyesDiagRetinalSubSuppSympSubCommentVal,
    ros_eyesDiagRetinalSubSuppOtherSubDescVal,
    ros_eyesDiagRetinalSubDiabetesVal,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemmVal,
    ros_eyesDiagRetinalSubDiabetesSubIsVitHemmCommentVal,
    ros_eyesDiagRetinalSubDiabetesCommentVal,
    ros_eyesDiagOtherSubWhEyeCommentVal,
    ros_eyesDiagOtherSubDescCommentVal,
    ros_eyesDiagOtherSubSuppCommentVal,
    ros_eyesDiagOtherSubSuppSympSubCommentVal,
    ros_eyesDiagOtherSubSuppOtherSubDescVal,
    ros_eyesDiagOtherSubOtherVal,
    ros_earsVal,
    ros_earsCommentVal,
    ros_earsDiagnosesCommentVal,
    ros_earsDiagDiffHearSubDescCommentVal,
    ros_earsDiagDiffHearSubSuppOtherSubDescVal,
    ros_earsDiagDiffHearSubSuppCommentVal,
    ros_earsDiagLegalDeafSubDescCommentVal,
    ros_earsDiagLegalDeafSubSuppOtherSubDescVal,
    ros_earsDiagLegalDeafSubSuppCommentVal,
    ros_earsDiagTinnitusSubDescCommentVal,
    ros_earsDiagTinnitusSubSuppOtherSubDescVal,
    ros_earsDiagTinnitusSubSuppCommentVal,
    ros_earsDiagVertigoSubDescCommentVal,
    ros_earsDiagVertigoSubSuppOtherSubDescVal,
    ros_earsDiagVertigoSubSuppCommentVal,
    ros_earsDiagVertigoSubLoseBalVal,
    ros_earsDiagVertigoSubLoseBalCommentVal,
    ros_earsDiagOtherSubDescCommentVal,
    ros_earsDiagOtherSubSuppOtherSubDescVal,
    ros_earsDiagOtherSubSuppCommentVal,
    ros_earsDiagOtherSubOtherVal,
    ros_noseVal,
    ros_noseDiagnosesCommentVal,
    ros_noseCommentVal,
    ros_noseDiagAllergySubDescCommentVal,
    ros_noseDiagChronicNasalSubDescCommentVal,
    ros_noseDiagSeasonAllergySubDescCommentVal,
    ros_noseDiagSinusInfectSubDescCommentVal,
    ros_noseDiagSinustisSubDescCommentVal,
    ros_noseDiagOtherSubDescCommentVal,
    ros_noseDiagAllergySubSuppOtherSubDescVal,
    ros_noseDiagAllergySubSuppCommentVal,
    ros_noseDiagChronicNasalSubSuppOtherSubDescVal,
    ros_noseDiagChronicNasalSubSuppCommentVal,
    ros_noseDiagNoseBleedSubCommentVal,
    ros_noseDiagSeasonAllergySubSuppOtherSubDescVal,
    ros_noseDiagSeasonAllergySubSuppCommentVal,
    ros_noseDiagSinusInfectSubSuppOtherSubDescVal,
    ros_noseDiagSinusInfectSubSuppCommentVal,
    ros_noseDiagSinusInfectSubExuCommentVal,
    ros_noseDiagSinustisSubSuppOtherSubDescVal,
    ros_noseDiagSinustisSubSuppCommentVal,
    ros_noseDiagOtherSubSuppOtherSubDescVal,
    ros_noseDiagOtherSubSuppCommentVal,
    ros_noseDiagOtherSubOtherVal,
    ros_mouthVal,
    ros_mouthDiagCommentVal,
    ros_mouthCommentVal,
    ros_mouthDiagBleedGumsSubCommentVal,
    ros_mouthDiagDiffChewSubDescCommentVal,
    ros_mouthDiagDiffChewSubBcsPainVal,
    ros_mouthDiagDiffChewSubBcsPainSubVal,
    ros_mouthDiagDiffChewSubBcsPainSubCommentVal,
    ros_mouthDiagDiffChewSubBcsPainCommentVal,
    ros_mouthDiagDiffSwallowSubDescCommentVal,
    ros_mouthDiagDiffSwallowSubHadStrokeVal,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubVal,
    ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSubCommentVal,
    ros_mouthDiagDiffSwallowSubHadStrokeCommentVal,
    ros_mouthDiagOtherSubDescCommentVal,
    ros_mouthDiagOtherSubSuppOtherSubDescVal,
    ros_mouthDiagOtherSubSuppCommentVal,
    ros_mouthDiagOtherSubOtherVal,
    ros_neckVal,
    ros_neckDiagCommentVal,
    ros_neckCommentVal,
    ros_neckDiagCarotidSubDescCommentVal,
    ros_neckDiagCarotidSubSuppOtherSubDescVal,
    ros_neckDiagCarotidSubSuppCommentVal,
    ros_neckDiagCarotidSubCsDescCommentVal,
    ros_neckDiagParotidSubSuppOtherSubDescVal,
    ros_neckDiagParotidSubSuppCommentVal,
    ros_neckDiagParotidSubDescCommentVal,
    ros_neckDiagOtherSubDescCommentVal,
    ros_neckDiagOtherSubSuppOtherSubDescVal,
    ros_neckDiagOtherSubSuppCommentVal,
    ros_neckDiagOtherSubOtherVal,
    ...ros_eyeENTNeckRecommendationsVal,
    ros_eyeENTNeckRecommendationshearingEvaluationCommentVal,
    ros_eyeENTNeckRecommendationsdentalExamCommentVal,
    ros_eyeENTNeckRecommendationseyeExamCommentVal,
    ros_eyeENTNeckRecommendationsswallowingEvaluationCommentVal,
    ros_eyeENTNeckRecommendationstakeMedicationsCommentVal,
    ros_eyeENTNeckRecommendationsotherCommentVal,
    ros_bowelMoveAbnormSubDiarrSubIshisVal,
    ros_bowelMoveAbnormSubDiarrSubIshisCommentVal,
    ros_bowelMoveCommentVal,
    ros_bowelMoveAbnormSubCommentVal,
    ros_bowelMoveAbnormSubConstSubIfConstCommentVal,
    ros_bowelMoveAbnormSubDiarrSubCommentVal,
    ros_abdominalVal,
    ros_abdominalIsyesCommentVal,
    ros_abdominalCommentVal,
    ros_rectalVal,
    ros_rectalIsrecIsyesmalCommentVal,
    ros_rectalIsrecIsyesfemCommentVal,
    ros_rectalCommentVal,
    ros_lastBowelCommentVal,
    ros_meetWithCommentVal,
    ros_gpcogScoIsgpcogsVal,
    ros_gpcogScoIsmmsescVal,
    ros_gpcogScoCommentVal,
    ros_noGpcogMmsePatientOrientPersonVal,
    ros_noGpcogMmsePatientOrientPlaceVal,
    ros_noGpcogMmsePatientOrientTimeVal,
    ros_noGpcogMmsePatientDescribeNewsVal,
    ros_noGpcogMmseRecallCommentVal,
    ros_noGpcogMmsePatientDescribeNewsCommentVal,
    ros_noGpcogMmsePatientOrientPersonCommentVal,
    ros_noGpcogMmsePatientOrientPlaceCommentVal,
    ros_noGpcogMmsePatientOrientTimeCommentVal,
    ros_speechCommentVal,
    ros_fingerToNoseAbnormalSubCommentVal,
    ros_fingerToNoseCommentVal,
    ros_heelToToeAbnormalSubCommentVal,
    ros_heelToToeCommentVal,
    ros_thumbToFingerAbnormalSubCommentVal,
    ros_thumbToFingerCommentVal,
    ros_sitToStandCommentVal,
    ros_extremeMoveCommentVal,
    ros_affectCommentVal,
    ros_affectAbnormalSubDescCommentVal,
    ros_over2WeeksLittleIntrstCommentVal,
    ros_over2WeeksFeelDepressedCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfCommentVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityCommentVal,
    ros_over2WeeksPhq2ScoreVal,
    ros_over2WeeksPhq9ScoreVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMiniVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMildVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerateVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevereVal,
    ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevereVal,
    ros_neuroPsychVal,
    ros_neuroPsychCommentVal,
    ros_nervousAnxiousVal,
    ros_nervousAnxiousCommentVal,
    ros_worryMuchVal,
    ros_worryMuchCommentVal,
    ros_feelAfraidVal,
    ros_feelAfraidCommentVal,
    ros_neuroPsychHistoryAudioVal,
    ros_neuroPsychHistoryAudioCommentVal,
    ros_neuroPsychHistoryVisualVal,
    ros_neuroPsychHistoryVisualCommentVal,
    ros_neuroPsychBehaviourVal,
    ros_neuroPsychBehaviourCommentVal,
    ros_neuroPsychDeliriumVal,
    ros_neuroPsychDeliriumCommentVal,
    ros_neuroPsychHearVoicesVal,
    ros_neuroPsychHearVoicesCommentVal,
    ros_neuroPsychHighLowsVal,
    ros_neuroPsychHighLowsCommentVal,
    ros_neuroPsychEverFeelVal,
    ros_neuroPsychEverFeelCommentVal,
    ros_neuroPsychSubDiagCommentVal,
    ros_neuroPsychSubDiagAlcoholSubDescCommentVal,
    ros_neuroPsychSubDiagAlcoholSubTypeCommentVal,
    ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAlcoholSubSuppCommentVal,
    ros_neuroPsychSubDiagAlcoholSubDeliriumVal,
    ros_neuroPsychSubDiagAlcoholSubDeliriumCommentVal,
    ros_neuroPsychSubDiagAlcoholSubPsychVal,
    ros_neuroPsychSubDiagAlcoholSubPsychCommentVal,
    ros_neuroPsychSubDiagAmyotropSubDescCommentVal,
    ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAmyotropSubSuppCommentVal,
    ros_neuroPsychSubDiagAnxietySubDescCommentVal,
    ros_neuroPsychSubDiagAnxietySubTypeOtherSubDescVal,
    ros_neuroPsychSubDiagAnxietySubTypeCommentVal,
    ros_neuroPsychSubDiagAnxietySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagAnxietySubSuppCommentVal,
    ros_neuroPsychSubDiagBipolarSubDescCommentVal,
    ros_neuroPsychSubDiagBipolarSubTypeCommentVal,
    ros_neuroPsychSubDiagBipolarSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagBipolarSubSuppCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubDescCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSubCommentVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagCerebHemmSubSuppCommentVal,
    ros_neuroPsychSubDiagDementiaSubDescCommentVal,
    ros_neuroPsychSubDiagDementiaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDementiaSubSuppCommentVal,
    ros_neuroPsychSubDiagDementiaSubTypeVascSubTypeCommentVal,
    ros_neuroPsychSubDiagDementiaSubTypeVascCommentVal,
    ros_neuroPsychSubDiagDementiaSubTypeCommentVal,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychVal,
    ros_neuroPsychSubDiagDrugsSubDescCommentVal,
    ros_neuroPsychSubDiagDrugsSubTypeCommentVal,
    ros_neuroPsychSubDiagDrugsSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDrugsSubSuppCommentVal,
    ros_neuroPsychSubDiagDrugsSubHistoryPsychCommentVal,
    ros_neuroPsychSubDiagDrugsSubWhatDrugsVal,
    ros_neuroPsychSubDiagDepressSubMajorVal,
    ros_neuroPsychSubDiagDepressSubDescCommentVal,
    ros_neuroPsychSubDiagDepressSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDepressSubSuppCommentVal,
    ros_neuroPsychSubDiagDepressSubMajorCommentVal,
    ros_neuroPsychSubDiagDepressSubEpisodeCommentVal,
    ros_neuroPsychSubDiagHemiSubDescCommentVal,
    ros_neuroPsychSubDiagHemiSubHemiDescCommentVal,
    ros_neuroPsychSubDiagHemiSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagHemiSubSuppCommentVal,
    ros_neuroPsychSubDiagIntelSubDescCommentVal,
    ros_neuroPsychSubDiagIntelSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagIntelSubSuppCommentVal,
    ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDescVal,
    ros_neuroPsychSubDiagIntelSubIntelDescCommentVal,
    ros_neuroPsychSubDiagSeizureSubTreatmentVal,
    ros_neuroPsychSubDiagSeizureSubDescCommentVal,
    ros_neuroPsychSubDiagSeizureSubTypeCommentVal,
    ros_neuroPsychSubDiagSeizureSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSeizureSubSuppCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubDescCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSecToCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppParesisSubCommentVal,
    ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSubCommentVal,
    ros_neuroPsychSubDiagStrokeSubDescCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppSenseFindSubCommentVal,
    ros_neuroPsychSubDiagStrokeSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagStrokeSubSuppCommentVal,
    ros_neuroPsychSubDiagSubduralSubDescCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppSenseFindSubCommentVal,
    ros_neuroPsychSubDiagSubduralSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSubduralSubSuppCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubDescCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSubCommentVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagTraumaBrainSubSuppCommentVal,
    ros_neuroPsychSubDiagCerebPalsySubDescCommentVal,
    ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagCerebPalsySubSuppCommentVal,
    ros_neuroPsychSubDiagDelusionSubDescCommentVal,
    ros_neuroPsychSubDiagDelusionSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDelusionSubSuppCommentVal,
    ros_neuroPsychSubDiagFibroSubDescCommentVal,
    ros_neuroPsychSubDiagFibroSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagFibroSubSuppCommentVal,
    ros_neuroPsychSubDiagGuillSubDescCommentVal,
    ros_neuroPsychSubDiagGuillSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagGuillSubSuppCommentVal,
    ros_neuroPsychSubDiagHuntingSubDescCommentVal,
    ros_neuroPsychSubDiagHuntingSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagHuntingSubSuppCommentVal,
    ros_neuroPsychSubDiagInsomniaSubDescCommentVal,
    ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagInsomniaSubSuppCommentVal,
    ros_neuroPsychSubDiagMigraineSubDescCommentVal,
    ros_neuroPsychSubDiagMigraineSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagMigraineSubSuppCommentVal,
    ros_neuroPsychSubDiagSclerosisSubDescCommentVal,
    ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSclerosisSubSuppCommentVal,
    ros_neuroPsychSubDiagDystrophySubDescCommentVal,
    ros_neuroPsychSubDiagDystrophySubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagDystrophySubSuppCommentVal,
    ros_neuroPsychSubDiagGravisSubDescCommentVal,
    ros_neuroPsychSubDiagGravisSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagGravisSubSuppCommentVal,
    ros_neuroPsychSubDiagParkinsonSubDescCommentVal,
    ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagParkinsonSubSuppCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubDescCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagPeriNeuroSubSuppCommentVal,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesVal,
    ros_neuroPsychSubDiagPeriNeuroSubDiabetesCommentVal,
    ros_neuroPsychSubDiagRestlessLegSubDescCommentVal,
    ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagRestlessLegSubSuppCommentVal,
    ros_neuroPsychSubDiagSchizoSubDescCommentVal,
    ros_neuroPsychSubDiagSchizoSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagSchizoSubSuppCommentVal,
    ros_neuroPsychSubDiagTiaSubDescCommentVal,
    ros_neuroPsychSubDiagTiaSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagTiaSubSuppCommentVal,
    ros_neuroPsychSubDiagOtherSubDescCommentVal,
    ros_neuroPsychSubDiagOtherSubSuppOtherSubDescVal,
    ros_neuroPsychSubDiagOtherSubSuppCommentVal,
    ros_neuroPsychSubDiagOtherSubOtherVal,
    ros_musculoskeletalMuskoHadAnAmputationVal,
    ros_musculoskeletalVal,
    ros_hematologyVal,
    ros_integumentVal,
    ros_respiratoryAsthmaPatientControllerVal,
    ros_respiratoryAsthmaRescueMedicationsVal,
    ros_respiratoryAsthmaCurrentExacerbationVal,
    ros_respiratoryPatientChronicBronchitisVal,
    ros_respiratoryPatientHaveEmphysemaVal,
    ros_respiratoryPatientBronchodilatorVal,
    ros_respiratoryPatientSteroidsVal,
    ros_respiratoryPatientExacerbationVal,
    ros_respiratoryHistoryOfLungAbscessVal,
    ros_respiratoryHistoryOfEmpyemaVal,
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisVal,
    ros_hematologyAidsSubAnswerPatientUnderTreatmentVal,
    ros_hematologyHivSubAnswerPatientSymptomaticVal,
    ros_hematologyHivSubAnswerActiveTreatmentVal,
    ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticVal,
    ros_hematologyTuberculosisSubAnswerGivenBCG,
    ros_hematologyTuberculosisSubAnswerActiveTuberculosisVal,
    ros_hematologyTuberculosisSubAnswerTbInfectionVal,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderVal,
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerVal,
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderVal,
    ros_respiratoryVal,
    ...gait_RecommendationsVal,
    //gait_RecommendationsComment,
    gait_RecommendationstakeMedicationsAsPescribedCommentVal,
    gait_RecommendationsotherCommentVal,

    ...respiratory_RecommendationsVal,
    //respiratory_RecommendationsComment,
    respiratory_RecommendationstakeMedicationsCommentVal,
    respiratory_RecommendationsotherCommentVal,

    ...integument_RecommendationsVal,
    // integument_RecommendationsComment,
    integument_RecommendationstakeMedicationsAsPescribedCommentVal,
    integument_RecommendationsotherCommentVal,

    ...musko_RecommendationsVal,
    // musko_RecommendationsComment,
    musko_RecommendationsdiscussPtOtCommentVal,
    musko_RecommendationstakeMedicationsAsPescribedCommentVal,
    musko_RecommendationsotherCommentVal,

    ...hema_RecommendationsVal,
    // hema_RecommendationsComment,
    hema_RecommendationstakeMedicationsAsPescribedCommentVal,
    hema_RecommendationsreportAbnormalBruisingCommentVal,
    hema_RecommendationsfollowUpWithDoctorCommentVal,
    hema_RecommendationsotherCommentVal,

    ros_gaitComment,
    ros_respiratoryComment,
    ros_respiratoryAcutePulmonarySubAnswerDescribeComment,
    ros_respiratoryDiagnosesComment,
    ros_respiratoryAcutePulmonarySubAnswerSupportedByComment,
    ros_respiratoryAcuteUpperSubAnswerDescribeComment,
    ros_respiratoryAcuteUpperSubAnswerSupportedByComment,
    ros_respiratoryAsthmaSubAnswerDescribeComment,
    ros_respiratoryAsthmaSubAnswerSupportedByComment,
    ros_respiratoryAsthmaPatientControllerComment,
    ros_respiratoryAsthmaRescueMedicationsComment,
    ros_respiratoryAsthmaCurrentExacerbationComment,
    ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribeComment,
    ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedByComment,
    ros_respiratoryChronicRespiratoryFailureSubAnswerDescribeComment,
    ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedByComment,
    ros_respiratoryChronicSputumFailureSubAnswerDescribeComment,
    ros_respiratoryChronicSputumFailureSubAnswerSupportedByComment,
    ros_respiratoryCopdSubAnswerDescribeComment,
    ros_respiratoryCopdSubAnswerSupportedByComment,
    ros_respiratoryPatientChronicBronchitisComment,
    ros_respiratoryPatientHaveEmphysemaComment,
    ros_respiratoryPatientBronchodilatorComment,
    ros_respiratoryPatientSteroidsComment,
    ros_respiratoryPatientExacerbationComment,
    ros_respiratoryCysticFibrosisSubAnswerDescribeComment,
    ros_respiratoryCysticFibrosisSubAnswerSupportedByComment,
    ros_respiratoryHypoventilationSubAnswerDescribeComment,
    ros_respiratoryHypoventilationSubAnswerSupportedByComment,
    ros_respiratoryHypoxemiaSubAnswerDescribeComment,
    ros_respiratoryHypoxemiaSubAnswerSupportedByComment,
    ros_respiratoryPneumoniaSubAnswerDescribeComment,
    ros_respiratoryPneumoniaSubAnswerSupportedByComment,
    ros_respiratoryPneumoniaSubAnswerEtiologyComment,
    ros_respiratoryHistoryOfLungAbscessComment,
    ros_respiratoryHistoryOfEmpyemaComment,
    ros_respiratoryPulmonaryFibrosisSubAnswerDescribeComment,
    ros_respiratoryPulmonaryFibrosisSubAnswerSupportedByComment,
    ros_respiratoryRespiratorDependenceSubAnswerDescribeComment,
    ros_respiratoryRespiratorDependenceSubAnswerSupportedByComment,
    ros_respiratoryRespiratoryArrestSubAnswerDescribeComment,
    ros_respiratoryRespiratoryArrestSubAnswerSupportedByComment,
    ros_respiratorySarcoidosisSubAnswerDescribeComment,
    ros_respiratorySarcoidosisSubAnswerSupportedByComment,
    ros_respiratorySarcoidosisSubAnswerSpecifyComment,
    ros_respiratorySleepApneaSubAnswerDescribeComment,
    ros_respiratorySleepApneaSubAnswerSupportedByComment,
    ros_respiratoryRespOtherSubAnswerDescribeComment,
    ros_respiratoryRespOtherSubAnswerSupportedByComment,
    ros_respiratoryOtherSubAnswerOther,

    ros_integumentComment,
    ros_integumentDiagnosesComment,
    ros_integumentBasilCellCarcinomaSubAnswerDescribeComment,
    ros_integumentBasilCellCarcinomaSubAnswerSupportedByComment,
    ros_integumentDermatitisSubAnswerDescribeComment,
    ros_integumentDermatitisSubAnswerSupportedByComment,
    ros_integumentDermatitisSubAnswerWhatTypeComment,
    ros_integumentEczemaSubAnswerDescribeComment,
    ros_integumentEczemaSubAnswerSupportedByComment,
    ros_integumentOnychomycosisSubAnswerDescribeComment,
    ros_integumentOnychomycosisSubAnswerSupportedByComment,
    ros_integumentPsoriasisSubAnswerDescribeComment,
    ros_integumentPsoriasisSubAnswerSupportedByComment,
    ros_integumentPsoriasisSubAnswerPsoriaticArthritisComment,
    ros_integumentSkinUlcerSubAnswerDescribeComment,
    ros_integumentSkinUlcerSubAnswerSupportedByComment,
    ros_integumentSkinUlcerSubAnswerEtiologyComment,
    ros_integumentTineaPedisSubAnswerDescribeComment,
    ros_integumentTineaPedisSubAnswerSupportedByComment,
    ros_integumentUrticarialDiseaseSubAnswerDescribeComment,
    ros_integumentUrticarialDiseaseSubAnswerEtiology,
    ros_integumentUrticarialDiseaseSubAnswerSupportedByComment,
    ros_integumentUrticarialDiseaseSubAnswerTypeComment,
    ros_integumentWoundSubAnswerDescribeComment,
    ros_integumentWoundSubAnswerSupportedByComment,
    ros_integumentWoundSubAnswerEtiologyComment,
    ros_integumentOthersSubAnswerDescribeComment,
    ros_integumentOthersSubAnswerSupportedByComment,
    ros_integumentOthersSubAnswerOther,

    ros_musculoskeletalComment,
    ros_musculoskeletalDiagnosesComment,
    ros_musculoskeletalCollagenSubAnswerDescribeComment,
    ros_musculoskeletalCollagenSubAnswerSupportedByComment,
    ros_musculoskeletalCollagenSubAnswercollagenDescribeComment,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribeComment,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedByComment,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladderComment,
    ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDiseaseComment,
    ros_musculoskeletalExtremityFractureSubAnswerDescribeComment,
    ros_musculoskeletalExtremityFractureSubAnswerSupportedByComment,
    ros_musculoskeletalExtremityFractureSubAnswerExtremityComment,
    ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswerComment,
    ros_musculoskeletalGoutSubAnswerDescribeComment,
    ros_musculoskeletalGoutSubAnswerSupportedByComment,
    ros_musculoskeletalHalluxValgusSubAnswerDescribeComment,
    ros_musculoskeletalHalluxValgusSubAnswerSupportedByComment,
    ros_musculoskeletalHammerToesSubAnswerDescribeComment,
    ros_musculoskeletalHammerToesSubAnswerSupportedByComment,
    ros_musculoskeletalOsteoarthritisSubAnswerDescribeComment,
    ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints,
    ros_musculoskeletalOsteoarthritisSubAnswerSupportedByComment,
    ros_musculoskeletalOsteomyelitisSubAnswerDescribeComment,
    ros_musculoskeletalOsteomyelitisSubAnswerSupportedByComment,
    ros_musculoskeletalOsteoporosisSubAnswerDescribeComment,
    ros_musculoskeletalOsteoporosisSubAnswerSupportedByComment,
    ros_musculoskeletalPyogenicArthritisSubAnswerDescribeComment,
    ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints,
    ros_musculoskeletalPyogenicArthritisSubAnswerSupportedByComment,
    ros_musculoskeletalRheumatoidArthritisSubAnswerDescribeComment,
    ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints,
    ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedByComment,
    ros_musculoskeletalSpinalStenosisSubAnswerDescribeComment,
    ros_musculoskeletalSpinalStenosisSubAnswerSupportedByComment,
    ros_musculoskeletalSpinalStenosisSubAnswerNormalBladderComment,
    ros_musculoskeletalSystemicLupusSubAnswerDescribeComment,
    ros_musculoskeletalSystemicLupusSubAnswerSupportedByComment,
    ros_musculoskeletalMuskoOtherSubAnswerDescribeComment,
    ros_musculoskeletalMuskoOtherSubAnswerSupportedByComment,
    ros_musculoskeletalMuskoOtherSubAnswerother,
    ros_musculoskeletalHadAnAmputationSubAnswer,

    ros_hematologyComment,
    ros_hematologyDiagnosesComment,
    ros_hematologyAidsSubAnswerDescribeComment,
    ros_hematologyAidsSubAnswerSupportedByComment,
    ros_hematologyAidsSubAnswerPatientUnderTreatmentComment,
    ros_hematologyAnemiaSubAnswerDescribeComment,
    ros_hematologyAnemiaSubAnswerSupportedByComment,
    ros_hematologyAnemiaSubAnswerEtiologyComment,
    ros_hematologyAnemiaSubAnswerYesPatientOnComment,
    ros_hematologyCDifficileSubAnswerDescribeComment,
    ros_hematologyCDifficileSubAnswerSupportedByComment,
    ros_hematologyCommunityAcquiredSubAnswerDescribeComment,
    ros_hematologyCommunityAcquiredSubAnswerSupportedByComment,
    ros_hematologyHivSubAnswerDescribeComment,
    ros_hematologyHivSubAnswerSupportedByComment,
    ros_hematologyHivSubAnswerViralLoad,
    ros_hematologyHivSubAnswerCFour,
    ros_hematologyHivSubAnswerPatientSymptomaticComment,
    ros_hematologyHivSubAnswerActiveTreatmentComment,
    ros_hematologyHerpesZosterSubAnswerDescribeComment,
    ros_hematologyHerpesZosterSubAnswerSupportedByComment,
    ros_hematologyMrsaInfectionSubAnswerDescribeComment,
    ros_hematologyMrsaInfectionSubAnswerSupportedByComment,
    ros_hematologyImmuneDeficiencySubAnswerDescribeComment,
    ros_hematologyImmuneDeficiencySubAnswerEtiology,
    ros_hematologyImmuneDeficiencySubAnswerSupportedByComment,
    ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunisticComment,
    ros_hematologySepsisSubAnswerDescribeComment,
    ros_hematologySepsisSubAnswerSupportedByComment,
    ros_hematologySickleCellDiseaseSubAnswerDescribeComment,
    ros_hematologySickleCellDiseaseSubAnswerSupportedByComment,
    ros_hematologySickleCellTraitSubAnswerDescribeComment,
    ros_hematologySickleCellTraitSubAnswerSupportedByComment,
    ros_hematologyThalassemiaSubAnswerDescribeComment,
    ros_hematologyThalassemiaSubAnswerSupportedByComment,
    ros_hematologyThrombocytopeniaSubAnswerDescribeComment,
    ros_hematologyThrombocytopeniaSubAnswerEtiology,
    ros_hematologyThrombocytopeniaSubAnswerSupportedByComment,
    ros_hematologyTuberculosisSubAnswerDescribeComment,
    ros_hematologyTuberculosisSubAnswerSupportedByComment,
    ros_hematologyTuberculosisSubAnswerGivenBCGComment,
    ros_hematologyTuberculosisSubAnswerActiveTuberculosisComment,
    ros_hematologyTuberculosisSubAnswerTbInfectionComment,
    ros_hematologyVitaminDDeficiencySubAnswerSupportedByComment,
    ros_hematologyHemaOtherSubAnswerDescribeComment,
    ros_hematologyHemaOtherSubAnswerSupportedByComment,
    ros_hematologyHemaOtherSubAnswerOther,

    ros_diagnosisOfCancerVal,
    ros_gastroSubDiagnosesbowelObstructionSupComment,
    ros_gastroSubDiagnosesdesbowelObstructionComment,
    ros_endoVal,
    ros_gastro,
    ros_genitoVal,
    ros_genitoSubDiagnosesacuteRenalFailuredesComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupComment,
    ros_diagnosisOfCancerSubActiveTreatVal:
      ros_diagnosisOfCancerSubActiveTreatVal,
    ros_diagnosisOfCancerSubMetastasisVal:
      ros_diagnosisOfCancerros_MetastasisVal,
    ros_diagnosisOfCancerSubSeeASpecVal: ros_diagnosisOfCancerros_SeeASpecVal,
    ros_diagnosisOfCancerSubSpecTyVal: ros_diagnosisOfCancerspecialTypes,

    ros_diagnosisOfCancerSubStageSpecCanVal:
      ros_diagnosisOfCancerstageSpecificToTheCancer,
    ros_diagnosisOfCancerCommentVal: ros_diagnosisOfCancerCommentVal,

    ...ros_diagnosisOfCancerRecommendations,
    ...ros_gastrorecommendations,
    ...ros_cardiovascularRecommendations,
    ...ros_genitoRecommendations,
    ...ros_endocrineIsEndocrineRecommendations,
    ros_diagnosisOfCancerSubDescComment,
    ros_diagnosisOfCancerSubSupComment,
    ros_diagnosisOfCancerSubTypeComment,
    ros_diagnosisOfCancerSubActiveTreatComment,
    ros_diagnosisOfCancerSubSideEffComment,
    ros_diagnosisOfCancerros_activeTreatmentComment,
    ros_diagnosisOfCancerros_SeeASpecComment,

    ros_diagnosisOfCancerros_MetastasisComment,

    ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment:
      ros_diagnosisOfCancerRecommendationstakemedicationsasprescribedCancerComment,

    ros_diagnosisOfCancerRecommendationsotherCancerComment:
      ros_diagnosisOfCancerRecommendationsotherCancerComment,

    ros_diagnosisOfCancerSubSeeASpecProVal: ros_SpecProVal,
    ros_diagnosisOfCancerSubMetastasisLocVal: ros_MetastasisLocVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaVal: ros_MetastasisCachexiaVal,
    ros_diagnosisOfCancerSubMetastasisCachexiaValComment,

    ros_cardioVal,
    ros_cardioComment,

    ros_cardiovascularRecommendationstakeMedicationsAsPescribedComment,

    ros_cardiovascularRecommendationsotherComment,

    ros_cardiovascularRecommendationsexerciseThirtyMinADayComment,

    ros_cardiovascularRecommendationsbloodPressureChecksComment,

    ros_cardiovascularRecommendationsheartHealthyDietComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupComment,

    ros_cardioSubDiagnosesComment,

    ros_cardioSubDiagnosesAneurysmDesComment,

    ros_endoValComment,
    ros_gastroComment,
    ros_genitoValComment,

    ros_endocrineIsEndocrineRecommendationstakeMedicationsAsPescribedEndoComment,
    ros_endocrineIsEndocrineRecommendationscheckBloodSugarEndoComment,
    ros_endocrineIsEndocrineRecommendationsotherEndoComment,
    ros_gastrorecommendationsotherComment,
    ros_gastrorecommendationstakemedicationsasprescribedComment,
    ros_gastroSubDiagnosesComment: ros_gastroSubDiagnosesComment,
    ros_gastroSubDiagnosesdescirrhosisComment,
    ros_gastroSubDiagnosescirrhosisSupComment,
    ros_gastroSubDiagnosescachexiadesComment,
    ros_gastroSubDiagnosescachexiaSupComment,
    ros_gastroSubDiagnosescolonPolypsDesComment,
    ros_gastroSubDiagnosesceliacDiseaseGlueten,
    ros_genitoSubDiagnosesComment,
    ros_endocrineIsEndocrineDiagnoseComment,
    ros_genitoSubDiagnosesacuteRenalFailureEti,
    ros_gastroSubDiagnosesceliacDiseaseGluetenComment,
    ros_gastroSubDiagnosesceliacDiseaseSupComment,
    ros_gastroSubDiagnosesdesceliacDiseaseComment,
    ros_gastroSubDiagnosescirrhosisEndStage,
    ros_gastroSubDiagnosescirrhosisEndStageComment,
    ros_gastroSubDiagnosescolonCrDesComment,
    ros_gastroSubDiagnosesdescolonComment,
    ros_gastroSubDiagnosescolonSupComment,
    ros_cardioSubDiagnosesAneurysmCrDesComment,
    ros_cardioSubDiagnosesAneurysmSupComment,
    ros_cardioSubDiagnosesAnginaCrDesComment,
    ros_cardioSubDiagnosesAnginaSupComment,
    ros_cardioSubDiagnosesAnginaDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationDesComment,
    ros_cardioSubDiagnosesAtrialFibrillationTypeComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationIspatientTakingComment,
    cardio_RecommendationsComment,
    ros_cardioSubDiagnosesAneurysmSupotherSubAnswer,
    ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer,
    ros_cardioSubDiagnosesAnginaSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockSupComment,
    ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer,
    ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer,
    ros_cardioSubDiagnosesHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer,
    ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer,
    ros_cardioSubDiagnosesOtherSupotherSubAnswer,
    ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrestComment,
    ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest,
    ros_cardioSubDiagnosesCardiomyopathyDesComment,
    ros_cardioSubDiagnosesCardiomyopathyTypeComment,
    ros_cardioSubDiagnosesCardiomyopathySupComment,
    ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer,
    ros_cardioSubDiagnosesCongestiveHeartFailureSpecifyComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlockerComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACEComment,
    ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertensionComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureCrDesComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureSupComment,
    ros_cardioSubDiagnosesCongestiveHeartFailureDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistent,
    ros_cardioSubDiagnosesDeepVeinThrombosisPersistentComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisCrDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisDesComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSubComment,
    ros_cardioSubDiagnosesDeepVeinThrombosisSupComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatinComment,
    ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin,
    ros_cardioSubDiagnosesHyperlipidemiaDesComment,
    ros_cardioSubDiagnosesHyperlipidemiaSupComment,
    ros_cardioSubDiagnosesHypertensionSupComment,
    ros_cardioSubDiagnosesHypertensionDesComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlledComment,
    ros_cardioSubDiagnosesHypertensionadequatelyControlled,
    ros_cardioSubDiagnosesIschemicHeartDiseaseSupComment,
    ros_cardioSubDiagnosesIschemicHeartDiseaseDesComment,
    ros_cardioSubDiagnosesMyocardialDesComment,
    ros_cardioSubDiagnosesMyocardialDate,
    ros_cardioSubDiagnosesMyocardialSupotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialSupComment,
    ros_cardioSubDiagnosesMyocardialBetaBlocker,
    ros_cardioSubDiagnosesMyocardialBetaBlockerComment,
    ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer,
    ros_cardioSubDiagnosesMyocardialIspateintComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseDesComment,
    ros_cardiohistoryDiabetesisHistoryDiabetesComment,
    ros_cardiopvdDescribeComment,
    ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer,
    ros_cardiohistoryDiabetesisHistoryDiabetes,
    ros_cardioSubDiagnosesPulmonaryHypertensionSupComment,
    ros_cardioSubDiagnosesPulmonaryHypertensionDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacement,
    ros_cardioSubDiagnosesvalvulardiseaseValveReplacementComment,
    ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation,
    ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulationComment,
    ros_cardioSubDiagnosesvalvulardiseaseSupComment,
    ros_cardioSubDiagnosesvalvulardiseaseDesComment,
    ros_cardioSubDiagnosesOtherOtherSub,
    ros_cardioSubDiagnosesOtherSupComment,
    ros_cardioSubDiagnosesOtherDesComment,
    ros_cardioSubDiagnosesvalvulardiseaseCrDesComment,
    ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal,
    ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal,
    ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal,
    ros_gastroSubDiagnosescolonOtherSubAnswerVal,
    ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgallBladderDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgastroparesisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgerdSupotherSubAnswerVal,
    ros_gastroSubDiagnosesgibleedSupotherSubAnswerVal,
    ros_gastroSubDiagnoseshepatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosespancreatitisSupotherSubAnswerVal,
    ros_gastroSubDiagnosesulcerDiseaseSupotherSubAnswerVal,
    ros_gastroSubDiagnosesotherSupotherSubAnswerVal,
    ros_gastroSubDiagnosescirrhosisEndStageSubAnswerComment,
    ros_gastroSubDiagnosesdesdiverticulitisComment,
    ros_gastroSubDiagnosesdiverticulitisabscessVal,
    ros_gastroSubDiagnosesdiverticulitisabscessValComment,
    ros_gastroSubDiagnosesdiverticulitisperforationVal,
    ros_gastroSubDiagnosesdiverticulitisperforationValComment,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietVal,
    ros_gastroSubDiagnosesdiverticulitishighFiberDietValComment,
    ros_gastroSubDiagnosesdiverticulitisSupComment,
    ros_gastroSubDiagnosesgallBladderDiseaseSupComment,
    ros_gastroSubDiagnosesdesgallBladderDiseaseComment,
    ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesgastroparesisSupportedByComment,
    ros_gastroSubDiagnosesgastroparesisDescribeComment,
    ros_gastroSubDiagnosesgerdDescribeComment,
    ros_gastroSubDiagnosesgerdSupportedByComment,
    ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedDescribeComment,
    ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesglBleedSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDescribeComment,
    ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnoseshepatitisSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribeComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherDescribeComment,
    ros_gastroSubDiagnosespancreatitisDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesotherSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByComment,
    ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseDescribeComment,
    ros_gastroSubDiagnosespancreatitisSupportedByComment,
    ros_gastroSubDiagnoseshepatitisDessComment,
    ros_gastroSubDiagnoseshepatitisTypeComment,
    ros_gastroSubDiagnoseshepatitisCirrhosis,
    ros_gastroSubDiagnoseshepatitisCirrhosisComment,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma,
    ros_gastroSubDiagnoseshepatitisHepatocellularCarcinomaComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDietComment,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet,
    ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribeComment,
    ros_gastroSubDiagnosespancreatitisRecurrentEpisodes,
    ros_gastroSubDiagnosespancreatitisRecurrentEpisodesComment,
    ros_gastroSubDiagnosespancreatitisSpecificDiet,
    ros_gastroSubDiagnosespancreatitisSpecificDietComment,
    ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe,
    ros_gastroSubDiagnosesulcerDiseaseComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactriaComment,
    ros_gastroSubDiagnosesulcerDiseaseHeliobactria,
    ros_gastroSubDiagnosesotherOther,
    ros_genito,
    ros_genitoComment,
    ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal,
    ros_genitoSubDiagnosesacuteRenalFailureEtiology,
    ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesbphSupportedByComment,
    ros_genitoSubDiagnosesbphDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseDescribeComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetesComment,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension,
    ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertensionComment,
    ros_genitoSubDiagnoseswhatStageComment,
    ros_genitoSubDiagnosesesrdDescribeComment,
    ros_genitoSubDiagnosesesrdSupportedByComment,
    ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesesrdPatientOnDialysisComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDietComment,
    ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet,
    ros_genitoSubDiagnosesesrdPatientOnDialysis,
    ros_genitoSubDiagnosesesrdPatientOnDialysisLocationComment,
    ros_genitoSubDiagnosesesrdPatientOnDialysisTypeComment,
    ros_genitoSubDiagnosesesrddialysisSchedule,
    ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice,
    ros_genitoSubDiagnoseserectileDysfunctionDescribeComment,
    ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseserectileDysfunctionSupportedByComment,
    ros_genitoSubDiagnosesfrequentUtiDescribeComment,
    ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesfrequentUtiSupportedByComment,
    ros_genitoSubDiagnosesgynecologicalDescribeComment,
    ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesgynecologicalSupportedByComment,
    ros_genitoSubDiagnoseskidneyStonesDescribeComment,
    ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer,
    ros_genitoSubDiagnoseskidneyStonesSupportedByComment,
    ros_genitoSubDiagnoseskidneyStonesTypeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisDescribeComment,
    ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByComment,
    ros_genitoSubDiagnosesurinaryIncontinenceDescribeComment,
    ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesurinaryIncontinenceSupportedByComment,
    ros_genitoSubDiagnosesotherDescribeComment,
    ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer,
    ros_genitoSubDiagnosesotherSupportedByComment,
    ros_genitoSubDiagnosesotherOther,
    ros_genitoSubDiagnosesurinaryIncontinenceUiDescribeComment,
    ros_genitoStress,
    ros_genitoStressComment,
    ros_genitoStressSubComment,
    // ros_otherGenitoVal,
    ros_endoComment,
    ros_endocrineIsEndocrine,
    ros_endocrineIsEndocrineComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAce,
    ros_endocrineIsEndocrineSubAnswerDiagnosesPatientOnAceIsPatientOnAceComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByOtherSubAnswerDescribe,
    ros_endocrineIsEndocrineSubchronicKidneySubdescribeSubComment,
    ros_endocrineIsEndocrineSubAnswerDiagnosesChronicKidneySubAnswerSupportedByComment,
    ros_genitoRecommendationsotherGenitoComment,
    ros_genitoRecommendationstakeMedicationsAsPrescribedGenitoComment,
    ros_diagnosisOfCancerSubSupOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer,
    ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer,
    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce,
    ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAceComment,
    ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirinComment,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin,
    ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatinComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSubComment,
    ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubdiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe,
    ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSubComment,
    ros_endocrineIsEndocrineSubdiabetesSubtypeComment,
    ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue,
    ros_endocrineIsEndocrineSubdiabetesSubandDate,
    ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDieticianComment,
    ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician,
    ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator,
    ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducatorComment,
    ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar,
    ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugarComment,
    ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubComment,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease,
    ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDiseaseComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce,
    ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAceComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubComment,
    ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe,
    ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribeComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribeComment,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupComment,
    ros_endocrineIsEndocrineDiagnoseotherDescribeComment,
    ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer,
    ros_endocrineIsEndocrineDiagnoseotherSupComment,
    ros_endocrineIsEndocrineDiagnoseotherOther,
    ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSubComment,
    ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSubComment,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertension,
    ros_cardioSubDiagnosesCardiompathysecondaryToHypertensionComment,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial,
    ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrialComment,
    ros_diagnosisOfCancerSubTypeOtherValSubAnswer,
    ros_cardioSubDiagnosesCardiorespiratoryShockDesComment,
    //  ros_genitoSubDiagnosesacuteRenalFailureSupComment
  };

  let payloadData = {};

  ros_hashmap?.forEach((questionPair) => {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
  });

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};

const getOptionsSelected = (optionObject, optionArray) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return ros_optionsTreeMapObject[variable];
  });

  return selectedOption;
};

const setOptionsSelected = (optionsSelected, optionArray) => {
  let temp = {};

  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(ros_optionsTreeMapObject[option])
        ? "1"
        : "";
  });
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["unknown"];
  } else {
    selectedValue = [""];
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["partially"];
  } else {
    selectedValue = [""];
  }
  return selectedValue;
};

const setOptionsSelectedYesNoPartially = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "partially") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else if (optionValue === "3") {
    selectedValue = "unknown";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNoUnknown = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelectedYesNoUnknownOther = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["unknown"];
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNoUnknownOther = (optionValue) => {
  let selectedValue;
  if (optionValue?.includes("yes")) {
    selectedValue = "1";
  } else if (optionValue?.includes("no")) {
    selectedValue = "2";
  } else if (optionValue?.includes("unknown")) {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getSubTree = (optionName, optionValue) => {
  let temp;
  const tempIndex = lodash.findIndex(
    ros_OptionsTreeWrappers,
    function (optTree) {
      return optTree[0] === optionName;
    },
  );

  const tempTreeNodes = ros_OptionsTreeWrappers?.[tempIndex]
    ? ros_OptionsTreeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (ros_optionsTreeMapObject[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex, treeType = 1) => {
  /*
  1 - normal options tree
  2 - yes/no options tree
  */
  let treeKeys = Object.keys(formData);
  let escapeRegex = treeType === 2 ? `${subTreeRegex}Comment` : "";

  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex) && node !== escapeRegex) {
      delete formData[node];
    }
  });
  return formData;
};

export const getSelectedCheckboxesOthers = (optionKeyArr, optionValueArr) => {
  let temp = "";

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      temp = optionValueArr[index] || "";
    }
  });

  return temp;
};

export const setSelectedCheckboxesOthers = (
  optionArr,
  optionKeyArr,
  optionValueArr,
) => {
  let temp = {};

  optionValueArr.forEach((ele, index) => {
    temp[optionKeyArr[index]] = optionArr?.includes(ele) ? "1" : "";
  });

  return temp;
};
