import React from "react";

const NotFoundTrap = React.lazy(() =>
  import("../pages/NotFoundTrap/NotFoundTrap"),
);
const ForgotPassword = React.lazy(() =>
  import("../pages/Login/ForgotPassword/ForgotPassword"),
);
const ResetPassword = React.lazy(() =>
  import("../pages/Login/ResetPassword/ResetPassword"),
);
const ChangePassword = React.lazy(() =>
  import("../pages/Login/ChangePassword/ChangePassword"),
);
const OTPScreen = React.lazy(() => import("../pages/OTP/OTPScreen"));

const ClientLogin = React.lazy(() => import("../pages/Login/login"));

export const PublicRoutes = [
  { path: "*", component: <NotFoundTrap />, title: "Not Found" },
  { path: `/Account/ForgotPassword`, component: <ForgotPassword /> },
  { path: `/Account/Otp`, component: <OTPScreen /> },
  { path: `/Account/ChangePassword`, component: <ChangePassword /> },
  { path: `/Account/ResetPassword`, component: <ResetPassword /> },
  { path: `/Client`, component: <ClientLogin /> },
];
