import {
  createContext,
  useContext,
  useEffect,
  useState,
  useLocation,
} from "react";
import toast from "react-hot-toast";
import { userCategoriesList } from "../components/Dashboard/ReactDND/userCategories";
import AlertContext from "./AlertContext";
import Loader from "../components/Loader/Loader";
import { getApi, postApi, putApi } from "../utils/axiosApiWrapper";
import _ from "lodash";
import Labels from "../resource_files/Labels.resx";
//import Labels from "../../../../resource_files/Labels.resx";
//import { initialWordList } from "../../../../components/Dashboard/DynamicFormMUI/staticForm";
import { initialWordList } from "../components/Dashboard/DynamicFormMUI/staticForm";
//import { setRosData2024 } from "./Sections/ROS/reviewOfSystemMapping2024";
import { setRosData2024 } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ROS/reviewOfSystemMapping2024";
import { setRosData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ROS/reviewOfSystemMapping";
import { setPainData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Pain/painMapping";
import { setCovidScreeningData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/CovidScreening/covidScreeningMapping";
import { setSelfAssessData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/SelfAssessment/selfAssessmentMapping";
import { setfamilyData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/FamilyHistory/familyHistoryMapping";
import { setMedicalData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/MedicalHistory/medicalHistoryMapping";
import { setActivitiesData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Activities/activitiesMapping";
import { setVitalData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Vital/vitalMapping";
import { setMinicogData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Minicog/minicogMapping";
import { setScreeningsData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Screenings/screeningsMapping";
import { setExamReviewData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/ExamReview/examReviewMapping";
import { setHomeSafetyData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/HomeSafety/homeSafetyMapping";
import { setPreventiveData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/preventiveCare/preventiveMapping";
import { setAllergiesData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Allergies/allergiesMapping";
import { setDemographicsData } from "../components/Dashboard/MemberList/Fill_HRA/Sections/Demographics/demographicsMapping";
const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: "",
    lastName: "",
    firstName: "",
    roleId: "",
    roleName: "",
    homePage: "",
    signatureStatus: "",
  });
  const [sidebarData, setSidebarData] = useState([]);
  const [clientData, setClientData] = useState();
  const [assessmentData, setAssessmentData] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const [getAssessmentData, setGetAssessmentData] = useState(null);
  // const [getAssessmentData, setGetAssessmentData] = useState(
  //   () => async () => {},
  // );
  const [programTypesOptions, setProgramTypesOptions] = useState([]);
  const [lobTypesOptions, setLobTypesOptions] = useState([]);
  const [lobOptionData, setLobOptionData] = useState([]);
  const [hasLoggedIn, setHasLoggedIn] = useState(true);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hraStatusData, setHraStatusData] = useState([]);
  const [clinicianDataList, setClinicianDataList] = useState([]);
  const [visitTypeOptionsData, setVisitTypeOptionsData] = useState([]);

  const [_memberIdForQueryForm, _setMemberIdForQueryForm] = useState("");
  const [memberIdForFormNames, setMemberIdForFormNames] = useState();
  const [memberDatasForNames, setMemberDatasForNames] = useState({});
  const [apiResponseData, setApiResponseData] = useState(null);
  const [selectedClientIdContext, setSelectedClientIdContext] = useState("");
  const [selectedMultipleRowId, setSelectedMultipleRowId] = useState("");

  const alertContext = useContext(AlertContext);
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear =
    localStorage.getItem("clientYear") || sessionStorage.getItem("clientYear");
  const storedScreeningTypes = localStorage.getItem("clientScreening");
  const storedPlanId = localStorage.getItem("selectedClientId") || "";

  const [autoSaveRosData, setAutoSaveRosData] = useState({});
  const [autoSavePainData, setAutoSavePainData] = useState({});
  const [autoSaveCovidScreeningData, setAutoSaveCovidScreeningData] = useState(
    {},
  );
  const [autoSaveSelfAssessmentData, setAutoSaveSelfAssessmentData] = useState(
    {},
  );
  const [autoSaveFamilyHistoryData, setAutoSaveFamilyHistoryData] = useState(
    {},
  );
  const [autoSaveMedicalHistory, setAutoSaveMedicalHistory] = useState({});
  const [autoSaveActivitiesData, setAutoSaveActivitiesData] = useState({});
  const [autoSaveVitalData, setAutoSaveVitalData] = useState({});
  const [autoSaveMinicogData, setAutoSaveMiniCogData] = useState({});
  const [autoSaveScreeningNeeded, setAutoSaveScreeningNeeded] = useState({});
  const [autoSaveExamReviewData, setAutoSaveExamReviewData] = useState({});
  const [autoSaveHomeSafetyData, setAutoSaveHomeSafety] = useState({});
  const [autoSavePatientData, setAutoSavePatientData] = useState({});
  const [autoSavePreventiveCareData, setAutoSavePreventiveCareData] = useState(
    {},
  );
  const [autoSaveAllergiesData, setAutoSaveAllergiesData] = useState({});
  const [autoSaveDemographicsData, setAutoSaveDemographicsData] = useState({});
  const [autoSavePrevDocData, setAutoSavePrevDocData] = useState({});
  const [autoSavePedsDemographicsData, setAutoSavePedsDemographicsData] =
    useState({});
  const [autoSavePedsAssessInfo, setAutoSavePedsAssessInfo] = useState({});
  const [autoSavePedsPreventativeCare, setAutoSavePedsPreventativeCare] =
    useState({});
  const [autoSavePedsSocialSafty, setAutoSavePedsSocialSafty] = useState({});
  const [autoSavePedsVital, setAutoSavePedsVital] = useState({});
  const [autoSavePedsPatientSumm, setAutoSavePedsPatientSumm] = useState({});

  const [autoSavePedsVoc, setAutoSavePedsVoc] = useState({});
  const [autoSavePedsCovidScreening, setAutoSavePedsCovidScreening] = useState(
    {},
  );

  const [autoSavepedsallergiesRowData, setAutoSavePedsAllergiesRowData] =
    useState({});
  const [autoSavefirstLevelCommentObj, setAutoSavePedsFirstLevelCommentObj] =
    useState({});
  const [
    autoSavecounterMedicationsRowData,
    setAutoSavePedsCounterMedicationsRowData,
  ] = useState();
  const [
    autoSavecounterMedicationsRowDataQues15,
    setAutoSavePedsCounterMedicationsRowDataQues15,
  ] = useState();
  const [
    autoSavesecondLevelTextFieldAnswer,
    setAutoSavePedsSecondLevelTextFieldAnswer,
  ] = useState({});
  const [autoSavesecondLevelCommentObj, setAutoSavePedsSecondLevelCommentObj] =
    useState({});
  const [currSection, setCurrSection] = useState(0);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [sideBarSectionDisable, setSideBarSectionDisable] = useState(undefined);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [clinicianPrimary, setClinicianPrimary] = useState(null);
  const [dateOfRefNurse, setDateOfRefNurse] = useState("");
  const [adminPrimary, setAdminPrimary] = useState(null);
  const [dateOfRefAdmin, setDateOfRefAdmin] = useState("");

  const sectionMap2023 = {
    1: ["demographics", "Demographics"],
    2: ["previously", "Previously Documented Conditions"],
    3: ["covid", "Covid Screening"],
    4: ["self", "Self-Assessment and Social History"],
    5: ["activities", "Activities of Daily Living"],
    6: ["medical", "Medical History"],
    7: ["family", "Family History"],
    8: ["preventive", "Preventive Care"],
    9: ["allergies", "Allergies / Medications"],
    10: ["review", "Review Of Systems And Diagnoses"],
    11: ["pain", "Pain"],
    12: ["vital", "Vital Signs"],
    13: ["exam", "Exam Review"],
    14: ["screenings", "Screening Needed"],
    15: ["mini", "Mini - Cog"],
    16: ["home", "Home Safety & Personal Goals"],
    17: ["patient", "Patient Summary"],
  };

  const sectionMap2024 = {
    1: ["demographics", "Demographics"],
    2: ["previously", "Previously Documented Conditions"],
    3: ["covid", "Covid Screening"],
    4: ["self", "Self-Assessment and Social History"],
    5: ["activities", "Activities of Daily Living"],
    6: ["medical", "Medical History"],
    7: ["family", "Family History"],
    8: ["preventive", "Preventive Care"],
    9: ["allergies", "Allergies / Medications"],
    10: ["review", "Assessment Of Diagnoses"],
    11: ["pain", "Pain"],
    12: ["vital", "Vital Signs"],
    13: ["exam", "Exam Review"],
    14: ["screenings", "Screening Needed"],
    15: ["mini", "Mini - Cog"],
    16: ["home", "Home Safety & Personal Goals"],
    17: ["patient", "Patient Summary"],
  };
  const updateAssessmentData = (data) => {
    setAssessmentData(data);
  };
  const roleId = sessionStorage.getItem("roleId");
  const updateRoles = (newRoles) => {
    setRoles(newRoles);
  };

  const getAssessment = (data, memberIdForQueryForm) => {
    // console.log(data,"dataaa")
    setGetAssessmentData(data?.data?.data);
    if (memberIdForQueryForm) {
      _setMemberIdForQueryForm(memberIdForQueryForm);
    }
    setMemberIdForFormNames(data?.data?.data?.memberId);
    setMemberDatasForNames(data?.data?.data);
  };
  const [currentColor, setCurrentColor] = useState(
    localStorage.getItem("themeColor") || "#1076BC",
  );
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [selectedTimeZoneUtc, setSelectedTimeZoneUtc] = useState("UTC");
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    JSON.parse(localStorage.getItem("selectedTimeZone")) || {
      value: "Eastern Standard Time",
      abbr: "EST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Eastern Time (US & Canada)",
      utc: ["America/New_York"],
      isEnabled: true,
    },
  );

  /* HRA status options */
  const fetchHRAStatusOptions = async () => {
    try {
      const response = await getApi("/hra-status");
      const data = await response.data.data;
      setHraStatusData(data);
    } catch (error) {
      return;
    }
  };

  /* fetch all the datas based on the plan id */
  const fetchApiResponseData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      setApiResponseData(response?.data?.data);
    }
  };

  /* visit type */
  const fetchVisitTypeOptions = async () => {
    try {
      const response = await getApi("/visitTypes/get-visit-types");
      const data = await response.data.data;
      setVisitTypeOptionsData(data);
    } catch (error) {
      return;
    }
  };

  /* fetch all lob data */
  const fetchLobData = async () => {
    try {
      const response = await getApi("/lobTypes/list");
      const responseData = await response.data.data;

      if (responseData && Array.isArray(responseData)) {
        const lobTypesOptions = responseData.map((lobType) => lobType.lobName);
        setLobOptionData(lobTypesOptions);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  /* get all clinician data */
  const fetchAllCliniciansData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");

      if (!_.isNull(response?.data?.data)) {
        if (!_.isNull(response?.data?.data[0])) {
          const clinicianList =
            (response?.data?.data || [] || [null]).find((obj) => obj.clinician)
              ?.clinician || [];
          setClinicianDataList(clinicianList);
        }
      }
    }
  };

  const fetchAllSchedulerDataForScheduler = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "3,4",
      });
      setSchedulerDataList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const storedThemeColor = localStorage.getItem("themeColor");
    setCurrentColor(storedThemeColor || "#1076BC");
    const root = document.documentElement;
    root.style.setProperty("--mainTheme", storedThemeColor || "#1076BC");
  }, []);

  useEffect(() => {
    if (!_.isUndefined(storedPlan) && !_.isEmpty(storedPlanId)) {
      fetchLobData();
      fetchApiResponseData();
      fetchHRAStatusOptions();
      fetchAllCliniciansData();
      fetchVisitTypeOptions();
      fetchAllSchedulerDataForScheduler();
    }
  }, [storedPlan]);
  let userCategories = userCategoriesList;

  const handleBadgeClick = () => {
    setIsDialogOpen(true);
    setClientData({});
    localStorage.removeItem("clientData");
    localStorage.removeItem("clientYear");
    localStorage.removeItem("clientPlan");
    localStorage.removeItem("clientScreening");
    localStorage.removeItem("isAllSelected");
  };
  const handleClientDataSubmit = () => {
    const client = clientData ? clientData?.clientName : null;
    const age = clientData ? clientData?.ageGroup : null;
    if (client && age) {
      const newValue = {
        clientName: client,
        ageGroup: age,
        isSelected: true,
      };

      setClientData(() => newValue);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleClientDataChange = (e, multiVal = null) => {
    if (multiVal[0] === "dropDownInput") {
      const { value } = e.target;
      if (multiVal[1] === "clients") {
        let newUserCategories = userCategories.clients.map((el) => {
          if (value.includes(el[0])) {
            let temp = {
              ...clientData,
            };
            const newValue = { clientName: value, ...temp };
            setClientData(() => newValue);
            return [el[0], true];
          }
          return [el[0], false];
        });
        userCategories = newUserCategories;
      } else if (multiVal[1] === "ageGroup") {
        let newUserCategories = userCategories.ageGroup.map((el) => {
          if (value.includes(el[0])) {
            const newValue = {
              ageGroup: value,
              ...clientData,
            };
            setClientData(() => newValue);
            return [el[0], true];
          }
          return [el[0], false];
        });
        userCategories = newUserCategories;
      }
    }
  };

  const handleSelectionDataSubmit = () => {
    const plan = clientData ? clientData.plan : storedPlan;
    const year = clientData ? clientData.year : storedYear;
    const screeningTypes = clientData
      ? clientData.screeningTypes
      : storedScreeningTypes;

    if (plan && year && screeningTypes) {
      const newValue = {
        plan: plan,
        year: year,
        screeningTypes: screeningTypes,
        isAllSelected: true,
      };
      localStorage.setItem("isAllSelected", true);
      setClientData(newValue);
    }
    if (plan && year && screeningTypes && isDialogOpen === true) {
      const newValue = {
        plan: plan,
        year: year,
        screeningTypes: screeningTypes,
        isAllSelected: true,
      };
      setClientData(newValue);
      setIsDialogOpen(false);
    }
  };

  const handleSelectionDataChange = (value, multiVal = null) => {
    if (multiVal && multiVal[0] === "dropDownInput") {
      let newUserCategories;

      if (multiVal[1] === "plan") {
        newUserCategories = userCategories.plan.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, plan: value }));
      } else if (multiVal[1] === "year") {
        newUserCategories = userCategories.year.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, year: value }));
      } else if (multiVal[1] === "screeningTypes") {
        newUserCategories = userCategories.screeningTypes.map((el) => [
          el[0],
          value === el[0],
        ]);
        setClientData((prevData) => ({ ...prevData, screeningTypes: value }));
      }

      setClientData((prevData) => ({
        ...prevData,
        year: value,
        screeningTypes: "All",
      }));
      userCategories = newUserCategories;
    }
  };

  // useEffect(() => {

  //     const fetchProgramTypes = async () => {
  //       if(alertContext.filterDialog == true){
  //       try {
  //         const response = await getApi("/programTypes/list");

  //         if (response.status === 200) {
  //           const responseData = await response.data.data;

  //           if (responseData && Array.isArray(responseData)) {
  //             const options = responseData.map(
  //               (programType) => programType.programName,
  //             );
  //             setProgramTypesOptions(options);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error in fetching options:", error);
  //       }
  //     }
  //     };

  //     fetchProgramTypes();

  // }, [alertContext.filterDialog]);

  // useEffect(() => {
  //   if (lobTypesOptions.length === 0) {
  //     const fetchLobTypes = async () => {
  //       try {
  //         const response = await getApi("/lobTypes/list");

  //         if (response.status === 200) {
  //           const responseData = await response.data.data;

  //           if (responseData && Array.isArray(responseData)) {
  //             const options = responseData.map((lobType) => lobType.lobName);
  //             setLobTypesOptions(options);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error in fetching options:", error);
  //       }
  //     };

  //     fetchLobTypes();
  //   }
  // }, [lobTypesOptions, setLobTypesOptions]);
  //const location = useLocation();
  const assessmentId = sessionStorage.getItem("assessmentId");
  // location?.state?.assessmentId
  //   ? location?.state?.assessmentId
  //   : sessionStorage.getItem("assessmentId");
  const tabId = sessionStorage.getItem("tabId"); //location?.state?.tabId
  // ? location?.state?.tabId
  // : sessionStorage.getItem("tabId");
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        // setSignSubmitDialog(false);
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        // toast.remove();
        // toast.success("Assessment Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };
  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};

      let tempData = autoSavePatientData;

      const patientData = {};

      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
        consentedToVideo:
          tempData?.visitTypeSaveBtn == 2
            ? tempData?.consentedToVideoSaveBtn
              ? tempData?.consentedToVideoSaveBtn
              : ""
            : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(0);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        toast.remove();
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      }
      // else {
      //   // setToasterOpen(true);
      //   // setToasterSeverity(Labels.ERROR);
      //   // setToasterMessage("Error saving data");

      // }
    } catch (error) {
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      // setToasterMessage("Error saving data");
      return;
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      // Object.keys(formData).forEach((key) => {
      //   const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
      //   formDataCamelCase[camelCaseKey] = formData[key];
      // });
      // let tempData = patientSummaryRef.current.getPayloadData();
      // if (!Boolean(tempData)) {
      let tempData = autoSavePatientData;

      const patientData = {};

      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";

      patientData.memberConsentedToCompleteVirtualVisit =
        tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          ? tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          : "";

      patientData.membersIdentityConfirmedWithID =
        tempData?.membersIdentityConfirmedWithIDSaveBtn
          ? tempData?.membersIdentityConfirmedWithIDSaveBtn
          : "";

      patientData.comment = tempData?.commentSaveBtn
        ? tempData?.commentSaveBtn
        : "";

      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      // if (tabId == 1) {
      //   updateHraStatusToInProgress();
      // }
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(0);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        toast.remove();
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
        const roleId = sessionStorage.getItem("roleId");
      } else {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.ERROR);
        // setToasterMessage("Error saving data");
      }
    } catch (error) {
      // setToasterOpen(true);
      // setToasterSeverity(Labels.ERROR);
      // setToasterMessage("Error saving data");
      console.log(error);
    }
  };

  const handleSave = () => {
    if (clientYear === "2024") {
      handlePatientSave2024();
    } else {
      handlePatientSave();
    }
  };
  //for fill Hra
  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = assessmentId;

      let sectionMap = {};
      if (clientYear === "2023") {
        sectionMap = { ...sectionMap2023 };
      } else if (clientYear === "2024") {
        sectionMap = { ...sectionMap2024 };
      }

      let sectionData = sectionMap[sectionId];
      let sectionName = sectionData[1];
      let tempData = {};
      if (sectionId === 1) {
        // tempData = demographicsRef?.current?.getPayloadData(); //getSectionFormData(sectionData);
        // if (!Boolean(tempData)) {
        tempData = autoSaveDemographicsData;
        // }
      } else if (sectionId === 4) {
        // tempData = selfAssessRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setSelfAssessData(autoSaveSelfAssessmentData, clientYear);
        // }
      } else if (sectionId === 7) {
        // tempData = familyHistoryRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setfamilyData(autoSaveFamilyHistoryData, clientYear);
        //}
        const subAnswers = tempData?.familyHistory?.familyHistorySubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 6) {
        // tempData = medicalRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setMedicalData(autoSaveMedicalHistory, clientYear);
        //}
        const subAnswers = tempData?.specialists?.specialistsSubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 10) {
        // tempData = rosRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        if (clientYear === "2024") {
          tempData = setRosData2024(autoSaveRosData);
        } else {
          tempData = setRosData(autoSaveRosData);
        }
        //}
      } else if (sectionId === 14) {
        // tempData = screeningsRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setScreeningsData(autoSaveScreeningNeeded, clientYear);
        // }
      } else if (sectionId === 13) {
        // tempData = examReviewRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setExamReviewData(autoSaveExamReviewData, clientYear);
        // }
      } else if (sectionId === 15) {
        // tempData = minicogRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setMinicogData(autoSaveMinicogData, clientYear);
        //  }
        const subAnswers = tempData?.threeWordRecall;

        if (
          clientYear === "2024" &&
          subAnswers &&
          typeof subAnswers.wordList === "string"
        ) {
          subAnswers.wordList = { ...initialWordList };
          if (subAnswers["0"]) {
            delete subAnswers["0"];
          }
        }

        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: "Mini - Cog",
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 16) {
        // tempData = homeSafetyRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setHomeSafetyData(autoSaveHomeSafetyData, clientYear);
        // }
      } else if (sectionId === 3) {
        // tempData = covidScreeningRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        tempData = setCovidScreeningData(
          autoSaveCovidScreeningData,
          clientYear,
        );
        // }
      } else if (sectionId === 5) {
        // tempData = activitiesRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        if (clientYear == "2024") {
          tempData = autoSaveActivitiesData;
        } else {
          tempData = setActivitiesData(autoSaveActivitiesData, clientYear);
        }
        //}
      } else if (sectionId === 11) {
        // tempData = painRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setPainData(autoSavePainData, clientYear);
        // }
      } else if (sectionId === 12) {
        // tempData = vitalRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setVitalData(autoSaveVitalData, clientYear);
        //}
      } else if (sectionId === 9) {
        // tempData = allergiesRef?.current?.getPayloadData();
        // //setAllergiesData
        // if (!Boolean(tempData)) {
        tempData = setAllergiesData(autoSaveAllergiesData, clientYear);
        // }
        const subAnswers = tempData?.allergies?.allergiesSubAnswers;
        for (let i = 0; i < subAnswers?.length; i++) {
          delete subAnswers[i]["id"];
        }

        const subAnswersMed = tempData?.medications?.medication;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          delete subAnswersMed[i]["id"];
          if (clientYear === "2024") {
            delete subAnswersMed[i]["prescribingPhysician"];
          }
        }
        const subAnswersCounterMed =
          tempData?.counterMedications?.counterMedicationsSubAnswers;
        for (let i = 0; i < subAnswersCounterMed?.length; i++) {
          delete subAnswersCounterMed[i]["id"];
        }
      } else if (sectionId === 17) {
        handleSave();
      } else if (sectionId === 2) {
        // tempData = previouslyRef?.current?.getPayloadData();

        // if (!Boolean(tempData)) {
        tempData = autoSavePrevDocData; //setAllergiesData(autoSaveAllergiesData, clientYear);
        // }
        if (clientYear === "2023") {
          delete tempData.suspectCodes;
        }

        const view =
          tempData?.viewSubAnswers?.length > 0
            ? "1"
            : "2" || tempData?.suspectCodes?.length > 0
            ? "1"
            : "2";
        tempData = {
          ...tempData,
          view,
        };
        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: sectionName,
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 8) {
        // tempData = preventiveRef?.current?.getPayloadData();
        // if (!Boolean(tempData)) {
        tempData = setPreventiveData(autoSavePreventiveCareData, clientYear);
        // }
        const subAnswersMed =
          tempData?.preventiveCare?.pastThreeYearsSubAnswers;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          if (clientYear === "2024") {
            delete subAnswersMed[i]["educationCompleted"];
          }
        }
        if (clientYear === "2024") {
          delete tempData?.recommendations;
        }
      }

      payload = {
        id: assessId,
        year: clientYear,
        data: {
          name: sectionName,
          data: {
            ...tempData,
          },
        },
      };

      if (sectionId !== 17) {
        const response = await putApi("/hra/assessment", payload);

        if (tabId == 1) {
          updateHraStatusToInProgress();
        }
        setCurrSection(0);
        return response;
      }
    } catch (error) {
      return;
    }
  };

  // for peds form
  const onSavepedsDemographicsData = async (
    demographicsFormState = autoSavePedsDemographicsData,
  ) => {
    const payload = {
      plan: demographicsFormState?.plan ? demographicsFormState?.plan : "",
      program: demographicsFormState?.program
        ? demographicsFormState?.program
        : "",
      lob: demographicsFormState?.lob ? demographicsFormState?.lob : "",
      region: demographicsFormState?.region
        ? demographicsFormState?.region
        : "",
      aligned: demographicsFormState?.aligned
        ? demographicsFormState?.aligned
        : "",
      name: demographicsFormState?.name ? demographicsFormState?.name : "",
      gender: demographicsFormState?.gender
        ? demographicsFormState?.gender
        : "",
      addressOne: demographicsFormState?.addressOne
        ? demographicsFormState?.addressOne
        : "",
      addressTwo: demographicsFormState?.addressTwo
        ? demographicsFormState?.addressTwo
        : "",
      addressThree: demographicsFormState?.addressThree
        ? demographicsFormState?.addressThree
        : "",
      city: demographicsFormState?.city ? demographicsFormState?.city : "",
      state: demographicsFormState?.state ? demographicsFormState?.state : "",
      zip: demographicsFormState?.zip ? demographicsFormState?.zip : "",
      mAddressOne: demographicsFormState?.mAddressOne
        ? demographicsFormState?.mAddressOne
        : "",
      mAddressTwo: demographicsFormState?.mAddressTwo
        ? demographicsFormState?.mAddressTwo
        : "",
      mAddressThree: demographicsFormState?.mAddressThree
        ? demographicsFormState?.mAddressThree
        : "",
      mCity: demographicsFormState?.mCity ? demographicsFormState?.mCity : "",
      mState: demographicsFormState?.mState
        ? demographicsFormState?.mState
        : "",
      mZip: demographicsFormState?.mZip ? demographicsFormState?.mZip : "",
      dob: demographicsFormState?.dob ? demographicsFormState?.dob : "",
      age: demographicsFormState?.age ? demographicsFormState?.age : "",
      maritalStatus: demographicsFormState?.maritalStatus
        ? demographicsFormState?.maritalStatus
        : "",
      memberIdentificationNo: demographicsFormState?.memberIdentificationNo
        ? demographicsFormState?.memberIdentificationNo
        : "",

      hicn: demographicsFormState?.hicn ? demographicsFormState?.hicn : "",
      email: demographicsFormState?.email ? demographicsFormState?.email : "",
      phoneNumber: demographicsFormState?.phoneNumber
        ? demographicsFormState?.phoneNumber
        : "",
      cellNumber: demographicsFormState?.cellNumber
        ? demographicsFormState?.cellNumber
        : "",
      alternateContactNumber: demographicsFormState?.alternateContactNumber
        ? demographicsFormState?.alternateContactNumber
        : "",
      emergencyContact: demographicsFormState?.emergencyContact
        ? demographicsFormState?.emergencyContact
        : "",
      ePhoneNumber: demographicsFormState?.ePhoneNumber
        ? demographicsFormState?.ePhoneNumber
        : "",
      primaryCarePhysician: demographicsFormState?.primaryCarePhysician
        ? demographicsFormState?.primaryCarePhysician
        : "",
      pPhoneNumber: demographicsFormState?.pPhoneNumber
        ? demographicsFormState?.pPhoneNumber
        : "",
      guardianName: demographicsFormState?.guardianName
        ? demographicsFormState?.guardianName
        : "",
      relationshipToChild: demographicsFormState?.relationshipToChild
        ? demographicsFormState?.relationshipToChild
        : "",
      gPhoneNumber: demographicsFormState?.gPhoneNumber
        ? demographicsFormState?.gPhoneNumber
        : "",
      gCellNumber: demographicsFormState?.gCellNumber
        ? demographicsFormState?.gCellNumber
        : "",
      gEmail: demographicsFormState?.gEmail
        ? demographicsFormState?.gEmail
        : "",
      pcpAddress: demographicsFormState?.pcpAddress
        ? demographicsFormState?.pcpAddress
        : "",
      pcpCity: demographicsFormState?.pcpCity
        ? demographicsFormState?.pcpCity
        : "",
      pcpState: demographicsFormState?.pcpState
        ? demographicsFormState?.pcpState
        : "",
      pcpZip: demographicsFormState?.pcpZip
        ? demographicsFormState?.pcpZip
        : "",
      pcpCountry: demographicsFormState?.pcpCountry
        ? demographicsFormState?.pcpCountry
        : "",
      faxNumber: demographicsFormState?.faxNumber
        ? demographicsFormState?.faxNumber
        : "",
      pcpNpi: demographicsFormState?.pcpNpi
        ? demographicsFormState?.pcpNpi
        : "",
      officeId: demographicsFormState?.officeId
        ? demographicsFormState?.officeId
        : "",
      officeName: demographicsFormState?.officeName
        ? demographicsFormState?.officeName
        : "",
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        ...payload,

        currentGender: {
          gender: demographicsFormState?.currentGenderIdentity
            ? demographicsFormState?.currentGenderIdentity
            : "", //"",
          comment: demographicsFormState?.currentGenderComment
            ? demographicsFormState?.currentGenderComment
            : "",
        }, //physicianComment
        uniqueIdentificationNo: demographicsFormState?.uniqueIdentificationNo
          ? demographicsFormState?.uniqueIdentificationNo
          : "",
        physicianComment: demographicsFormState?.physicianComment
          ? demographicsFormState?.physicianComment
          : "",
        medicaidId: demographicsFormState?.medicaidId
          ? demographicsFormState?.medicaidId
          : "",
        medicareId: demographicsFormState?.medicareId
          ? demographicsFormState?.medicareId
          : "",
        pFlag: demographicsFormState?.pFlag ? demographicsFormState?.pFlag : "",
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Child Demographics",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);
      setCurrSection(0);
      if (response.status === 200) {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };
  let payload = {};
  const getpayloadByQuestionId = (questionNumber, answerObj) => {
    const firstLevelCommentObj = autoSavefirstLevelCommentObj;
    const secondLevelCommentObj = autoSavesecondLevelCommentObj;
    const secondLevelTextFieldAnswer = autoSavesecondLevelTextFieldAnswer;
    const counterMedicationsRowData = autoSavecounterMedicationsRowData;
    const counterMedicationsRowDataQues15 =
      autoSavecounterMedicationsRowDataQues15;
    const allergiesRowData = autoSavepedsallergiesRowData;

    if (
      questionNumber == 1 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 1)
    ) {
      payload = {
        ...payload,
        legalGuradian: {
          legalGuradianPermission: answerObj?.legalGuradian
            ? answerObj?.legalGuradian
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.legalGuradian == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 2 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 2)
    ) {
      payload = {
        ...payload,
        legalRepresentative: {
          authorizedLegalRepresentative: answerObj?.legalRepresentative
            ? answerObj?.legalRepresentative
            : "",
          //  == "yes"
          //   ? "1"
          //   : answerObj.legalRepresentative == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 3 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 3)
    ) {
      payload = {
        ...payload,
        fosterCare: {
          fosterCareProgram: answerObj?.fosterCare ? answerObj?.fosterCare : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.fosterCare == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 4 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 4)
    ) {
      payload = {
        ...payload,
        preferredLanguage: {
          english: answerObj?.preferredLanguage == "english" ? "1" : "2", //"Y or N",
          other: answerObj?.preferredLanguage == "other" ? "1" : "2", //"Y or N",
          otherSubAnswer: {
            africanLanguages:
              answerObj?.demograph_prefLangOtherSub == "africanLanguages"
                ? "1"
                : "2",
            arabic:
              answerObj?.demograph_prefLangOtherSub == "arabic" ? "1" : "2",
            chinese:
              answerObj?.demograph_prefLangOtherSub == "chinese" ? "1" : "2",
            french:
              answerObj?.demograph_prefLangOtherSub == "french" ? "1" : "2",
            frenchCreole:
              answerObj?.demograph_prefLangOtherSub == "frenchCreole" //"frenchCrelo"
                ? "1"
                : "2",
            german:
              answerObj?.demograph_prefLangOtherSub == "german" ? "1" : "2",
            greek: answerObj?.demograph_prefLangOtherSub == "greek" ? "1" : "2",
            gujarati:
              answerObj?.demograph_prefLangOtherSub == "gujarati" ? "1" : "2",
            hebrew:
              answerObj?.demograph_prefLangOtherSub == "hebrew" ? "1" : "2",
            hindi: answerObj?.demograph_prefLangOtherSub == "hindi" ? "1" : "2",
            hungarian:
              answerObj?.demograph_prefLangOtherSub == "hungarian" ? "1" : "2",
            italian:
              answerObj?.demograph_prefLangOtherSub == "italian" ? "1" : "2",
            japanese:
              answerObj?.demograph_prefLangOtherSub == "japanese" ? "1" : "2",
            korean:
              answerObj?.demograph_prefLangOtherSub == "korean" ? "1" : "2",
            persian:
              answerObj?.demograph_prefLangOtherSub == "persian" ? "1" : "2",
            polish:
              answerObj?.demograph_prefLangOtherSub == "polish" ? "1" : "2",
            portuguese:
              answerObj?.demograph_prefLangOtherSub == "portuguese" ? "1" : "2",
            russian:
              answerObj?.demograph_prefLangOtherSub == "russian" ? "1" : "2",
            scandinavianLanguages:
              answerObj?.demograph_prefLangOtherSub == "scandinavianLanguages"
                ? "1"
                : "2",
            serboCroatian:
              answerObj?.demograph_prefLangOtherSub == "serboCroatian"
                ? "1"
                : "2",
            spanish:
              answerObj?.demograph_prefLangOtherSub == "spanish" ? "1" : "2",
            comment: "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 5 ||
      (firstLevelCommentObj[questionNumber - 1] && questionNumber == 5)
    ) {
      payload = {
        ...payload,
        race: {
          caucasian: answerObj?.race == "caucasian" ? "1" : "2",
          africanAmerican: answerObj?.race == "africanAmerican" ? "1" : "2",
          asian: answerObj?.race == "asian" ? "1" : "2",
          latino: answerObj?.race == "latino" ? "1" : "2",
          nativeAmerican: answerObj?.race == "nativeAmerican" ? "1" : "2",
          nativeHawaiian: answerObj?.race == "nativeHawaiian" ? "1" : "2",
          alaskanNative: answerObj?.race == "alaskanNative" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 7 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 7)
    ) {
      payload = {
        ...payload,
        childExperienced: {
          childWitnessedEvent: answerObj?.childExperienced
            ? answerObj?.childExperienced
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj?.childExperienced == "no"
          //   ? "2"
          //   : "",
          childWitnessedEventYesSubAnswer: {
            event: {
              carAccident:
                answerObj?.Whatwastheevent == "carAccident" ? "1" : "2", //"Y or N",
              fire: answerObj?.Whatwastheevent == "fire" ? "1" : "2", //"Y or N",
              storm: answerObj?.Whatwastheevent == "storm" ? "1" : "2", //"Y or N",
              physicalIllness:
                answerObj?.Whatwastheevent == "physicalIllness" ? "1" : "2", //"Y or N",
              sexualAssualt:
                answerObj?.Whatwastheevent == "sexualAssualt" ? "1" : "2", //"Y or N",
              comment: secondLevelCommentObj.Whatwastheevent
                ? secondLevelCommentObj.Whatwastheevent
                : "",
            },
            describe: answerObj?.describe ? answerObj?.describe : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 8 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 8)
    ) {
      payload = {
        ...payload,
        memberAgeEvent: {
          specialAge: answerObj?.memberAgeEvent
            ? answerObj?.memberAgeEvent
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 9 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 9)
    ) {
      payload = {
        ...payload,
        childReportComplaints: {
          notTrue: answerObj?.childReportComplaints == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childReportComplaints == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childReportComplaints == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue:
            answerObj?.childReportComplaints == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 10 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 10)
    ) {
      payload = {
        ...payload,
        childAvoidsThings: {
          notTrue: answerObj?.childAvoidsThings == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childAvoidsThings == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childAvoidsThings == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childAvoidsThings == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 11 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 11)
    ) {
      payload = {
        ...payload,
        childStartlesEasily: {
          notTrue: answerObj?.childStartlesEasily == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childStartlesEasily == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childStartlesEasily == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childStartlesEasily == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 12 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 12)
    ) {
      payload = {
        ...payload,
        childGetsUpset: {
          notTrue: answerObj?.childGetsUpset == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue: answerObj?.childGetsUpset == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childGetsUpset == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childGetsUpset == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 13 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 13)
    ) {
      payload = {
        ...payload,
        childMedicine: {
          childMedicinePrescribed: answerObj?.childMedicine
            ? answerObj?.childMedicine
            : "", //== "yes" ? "1" : "2",
          childMedicinePrescribedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "1" : "2", //"Y or N",
              comment:
                secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  ? secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 15 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 15)
    ) {
      payload = {
        ...payload,
        counterMedications: {
          supplements: answerObj?.counterMedications
            ? answerObj?.counterMedications
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.counterMedications == "no"
          //   ? "N"
          //   : "", //"Y or N",
          supplementsYesSubAnswer: counterMedicationsRowDataQues15?.map(
            (row) => {
              return {
                prescription: row?.description || "",
                doseUnits: row?.doseOrUnits || "",
                route: row?.route || "",
                reason: row?.reasons || "",
                status: row?.status || "",
                frequency: row?.frequency || "",
                // comment: "",
              };
            },
          ),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 16 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 16)
    ) {
      payload = {
        ...payload,
        childNeedMedicalCare: {
          childMedicalCare: answerObj?.childNeedMedicalCare
            ? answerObj?.childNeedMedicalCare
            : "", //== "yes" ? "Y" : "N",
          childMedicalCareYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.medical_care_condition_that_has_lasted
                  ? secondLevelCommentObj.medical_care_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 17 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 17)
    ) {
      payload = {
        ...payload,
        childAbility: {
          childAbilityLimited: answerObj?.childAbility
            ? answerObj?.childAbility
            : "", // == "yes" ? "Y" : "N",
          childAbilityLimitedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.childAbility_condition_that_has_lasted
                  ? secondLevelCommentObj.childAbility_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 18 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 18)
    ) {
      payload = {
        ...payload,
        childNeed: {
          childNeedTherapy: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          childNeedTherapyYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment: secondLevelCommentObj.childNeed_condition_that_has_lasted
                ? secondLevelCommentObj.childNeed_condition_that_has_lasted
                : "",
            },
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 19 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 19)
    ) {
      payload = {
        ...payload,
        childDevelopment: {
          childDevelopmentCounseling: answerObj?.childDevelopment
            ? answerObj?.childDevelopment
            : "", //== "yes" ? "Y" : "N",
          childDevelopmentCounselingYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 20 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 20)
    ) {
      payload = {
        ...payload,
        childReceiveSupport: {
          nursingCare:
            answerObj?.childReceiveSupport == "nursingCare" ? "1" : "2",
          personalCareAttendant:
            answerObj?.childReceiveSupport == "personalCareAttendant"
              ? "1"
              : "2",
          homeHealthAide:
            answerObj?.childReceiveSupport == "homeHealthAide" ? "1" : "2",
          no: answerObj?.childReceiveSupport == "no" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 21 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 21)
    ) {
      payload = {
        ...payload,
        childMedicalCheckup: {
          childMedicalCheckup: answerObj?.childMedicalCheckup
            ? answerObj?.childMedicalCheckup
            : "",
          //  == "Y"
          //   ? "1"
          //   : answerObj.childMedicalCheckup == "N"
          //   ? "2"
          //   : answerObj.childMedicalCheckup == "DoesnotKnow"
          //   ? "3"
          //   : "", //  "Y or N or DoesnotKnow",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 22 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 22)
    ) {
      payload = {
        ...payload,
        childsHeightWeight: {
          childsHeightWeight: answerObj?.childsHeightWeight
            ? answerObj?.childsHeightWeight
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.childsHeightWeight == "no"
          //   ? "N"
          //   : "", //or N",
          childsHeightWeightYesSubAnswer: {
            childsHeight: {
              feet:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightfeet
                    ? answerObj?.childsHeightfeet
                    : ""
                  : "", //"",
              inch:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightInches
                    ? answerObj?.childsHeightInches
                    : ""
                  : "",
            },
            childsWeight:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.childsWeight
                  ? answerObj?.childsWeight
                  : ""
                : "",
            calculateBmi:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.calculateBmi
                  ? answerObj?.calculateBmi
                  : ""
                : "",
          },
        },
      };
    } else if (
      questionNumber == 23 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 23)
    ) {
      payload = {
        ...payload,
        describeChildsWeight: {
          childsWeight: answerObj?.describeChildsWeight
            ? answerObj?.describeChildsWeight
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 24 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 24)
    ) {
      payload = {
        ...payload,
        femaleChildren: {
          yes: answerObj?.femaleChildren == "yes" ? "1" : "2", // "Y or N",
          no: answerObj?.femaleChildren == "no" ? "1" : "2", //"Y or N",
          doesnotKnow: answerObj?.femaleChildren == "doesnotKnow" ? "1" : "2", //"Y or N","N/A"
          na: answerObj?.femaleChildren == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 25 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 25)
    ) {
      payload = {
        ...payload,
        enoughFoodForFamily: {
          never: answerObj?.enoughFoodForFamily == "never" ? "1" : "2", //"Y or N",
          sometimes: answerObj?.enoughFoodForFamily == "sometimes" ? "1" : "2", //"Y or N",
          always: answerObj?.enoughFoodForFamily == "always" ? "1" : "2", //"Y or N",
          declineToAnswer:
            answerObj?.enoughFoodForFamily == "declineToAnswer" ? "1" : "2",
          // na: answerObj?.enoughFoodForFamily == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 26 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 26)
    ) {
      payload = {
        ...payload,
        communityResources: {
          communityResourcesAvail: answerObj?.communityResources
            ? answerObj?.communityResources
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.communityResources == "no"
          //   ? "N"
          //   : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 27 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 27)
    ) {
      payload = {
        ...payload,
        childHaveAnyConditions: {
          asthma: answerObj?.childHaveAnyConditions?.includes("asthma")
            ? "1"
            : "2", //Y or N",
          asthmaSubAnswer: {
            childSeeASpecialist: answerObj?.childSeeASpecialist
              ? answerObj?.childSeeASpecialist
              : "",
            // == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "", //"Y or N",
            childSeeASpecialistYesSubAnswer: {
              yesName: secondLevelTextFieldAnswer["ifYesName"]
                ? secondLevelTextFieldAnswer["ifYesName"]
                : "", //"",
            },
            comment: secondLevelCommentObj.childSeeASpecialist
              ? secondLevelCommentObj.childSeeASpecialist
              : "", //"",
          },
          diabetes: answerObj?.childHaveAnyConditions?.includes("diabetes")
            ? "1"
            : "2", //"Y or N",
          diabetesSubAnswer: {
            childsBloodSugar: answerObj?.childsBloodSugar
              ? answerObj?.childsBloodSugar
              : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "",
            comment: secondLevelCommentObj?.childsBloodSugar
              ? secondLevelCommentObj?.childsBloodSugar
              : "",
          },
          sickleCellDisease: answerObj?.childHaveAnyConditions?.includes(
            "sickleCellDisease",
          )
            ? "1"
            : "2", //"Y or N",
          sickleCellDiseaseSubAnswer: {
            hgbAonec: answerObj?.hgbAonec ? answerObj?.hgbAonec : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.hgbAonec == "no"
            //   ? "2"
            //   : "", //"Y or N",
            hgbAonecYesSubAnswer: {
              lastHgbAonec: secondLevelTextFieldAnswer["ifYesHgbA1c"]
                ? secondLevelTextFieldAnswer["ifYesHgbA1c"]
                : "", //"",
            },
            comment: secondLevelCommentObj?.hgbAonec
              ? secondLevelCommentObj?.hgbAonec
              : "",
          },
          hemophilia: answerObj?.childHaveAnyConditions?.includes("hemophilia")
            ? "1"
            : "2", //"Y or N",
          hemophiliaSubAnswer: {
            specialDiet: answerObj?.specialDiet ? answerObj?.specialDiet : "",
            specialDietYesSubAnswer: {
              yesDiet: secondLevelTextFieldAnswer["ifYesDiet"]
                ? secondLevelTextFieldAnswer["ifYesDiet"]
                : "", //"",,
            },
            comment: secondLevelCommentObj?.specialDiet
              ? secondLevelCommentObj?.specialDiet
              : "",
          },
          ddAdhd: answerObj?.childHaveAnyConditions?.includes("ddAdhd")
            ? "1"
            : "2", //"Y or N",
          substanceUse: answerObj?.childHaveAnyConditions?.includes(
            "substanceUse",
          )
            ? "1"
            : "2", //"Y or N",
        },
      };
    } else if (
      questionNumber == 28 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 28)
    ) {
      payload = {
        ...payload,
        additionalInformation: {
          additionalInformationChild: answerObj?.additionalInformation
            ? answerObj?.additionalInformation
            : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      storedYear == "2024" &&
      (questionNumber == 29 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 29))
    ) {
      payload = {
        ...payload,
        patientReligiousBelief: {
          isPatientReligiousBelief: answerObj?.patientReligiousBelief
            ? answerObj?.patientReligiousBelief
            : "", //: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          isPatientReligiousBeliefSubAnswer: {
            describe: answerObj?.spiritual_beliefs
              ? answerObj?.spiritual_beliefs
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      questionNumber == 14 &&
      (counterMedicationsRowData?.length >= 1 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 14))
    ) {
      payload = {
        ...payload,
        medication: {
          prescriptionMedicationList: counterMedicationsRowData?.map((row) => {
            return {
              prescription: row?.description || "",
              doseUnits: row?.doseOrUnits || "",
              route: row?.route || "",
              reason: row?.reasons || "",
              status: row?.status || "",
              frequency: row?.frequency || "",
              comment: firstLevelCommentObj[questionNumber]
                ? firstLevelCommentObj[questionNumber]
                : "",
            };
          }),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      (allergiesRowData?.length >= 1 && questionNumber == 6) ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 6)
    ) {
      const childAllergiesYesSubAnswer = allergiesRowData?.map((allergies) => {
        const obj = {
          substance: allergies?.substance ? allergies?.substance : "",
          reaction: allergies?.reaction ? allergies?.reaction : "",
        };
        return obj;
      });
      payload = {
        ...payload,
        allergies: {
          childAllergies: answerObj?.allergies ? answerObj?.allergies : "", //"1",
          childAllergiesYesSubAnswer:
            answerObj?.allergies == "1" ? childAllergiesYesSubAnswer : [],
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
  };
  const onSavePedsAssesmentInfoData = async (
    answeredJson = autoSavePedsAssessInfo,
  ) => {
    //Object.keys(answeredJson).forEach((key) => {
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29,
    ].forEach((key) => {
      getpayloadByQuestionId(Number(key), answeredJson[key]);
    });
    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Assessment Information",
          data: payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);
      setCurrSection(0);
      if (response.status === 200) {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
      } else {
        // toast.error("Error saving data", {});
      }
    } catch (error) {}
  };

  const onSavePatientSummaryData = async (
    _autoSavePedsPatientSumm = autoSavePedsPatientSumm,
  ) => {
    try {
      const {
        evaluationDate,
        examFinishDate,
        memberAcknowledge,
        assessorComents,
        disclosureStatement,
        visitType,
        consentToVideo,
        driverLicense,
        comment,
        providerSignature,
      } = _autoSavePedsPatientSumm;
      const _payload = {
        assessorsComments: assessorComents ? assessorComents : "",
        memberAcknowledgement: memberAcknowledge == "Y" ? "1" : "2",
        disclosure: disclosureStatement == "Y" ? "1" : "2",
        evaluationDate: evaluationDate,
        examFinishDate: examFinishDate,
        providerSignature: providerSignature ? providerSignature : "",
        visitType: {
          isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
          consentedToVideo: consentToVideo == "Y" ? "1" : "2",
        },
      };
      let payload2024 = {};
      if (storedYear == "2024") {
        delete _payload.disclosure;
        delete _payload.visitType;
        payload2024 = {
          ..._payload,
          visitType: {
            //driverLicense
            isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
            memberConsented: consentToVideo == "Y" ? "1" : "2",
            memberIdentified: driverLicense == "Y" ? "1" : "2",
            comment: comment ? comment : "",
          },
          providerSignature: providerSignature ? providerSignature : "",
          addAddendum: "",
          addendumSignature: "",
        };
      }

      let payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Patient Summary",

          data: storedYear == "2024" ? { ...payload2024 } : { ..._payload },
        },
        year: storedYear,
      };

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
          toast.success("Assessment Updated Successfully", {});
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const onSaveValidationOfConsentData = async (autoSaveValidationOfConsent) => {
    try {
      const {
        legalGuardian,
        legalGuardianComment,
        shareAssessment,
        shareAssessmentComment,
        fosterCareProgram,
        fosterCareProgramComment,
      } = autoSaveValidationOfConsent;

      const _payload = {
        legalGuardian: legalGuardian,
        legalGuardianComment: legalGuardianComment,
        shareAssessment: shareAssessment,
        shareAssessmentComment: shareAssessmentComment,
        fosterCareProgram: fosterCareProgram,
        fosterCareProgramComment: fosterCareProgramComment,
      };

      let payload = {
        id: assessmentId,
        data: {
          name: "Validation of Consent",
          data: { ..._payload },
        },
        year: storedYear,
      };

      const response = await putApi("/hra/assessment", payload);
      setCurrSection(3);
      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        //const roleId = sessionStorage.getItem("roleId");

        // else {
        //   navigate("/AdminDashboard/MemberList");
        // }
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  const onSavePedsCovidScreeningData = async (autoSavePedsCovidScreening) => {
    try {
      const {
        covid_dignosedWithCoronaVal,
        covid_dignosedWithCoronaCommentVal,
        covid_highRiskVal,
        covid_highRiskCommentVal,
        covid_symptomsFeverOrChillsVal,
        covid_symptomsCoughVal,
        covid_symptomsShortnessOfBreathVal,
        covid_symptomsFatigueVal,
        covid_symptomsMuscleOrBodyAchesVal,
        covid_symptomsHeadacheVal,
        covid_symptomsLossOfTasteVal,
        covid_symptomsSoreThroatVal,
        covid_symptomsCongestionVal,
        covid_symptomsNauseaVal,
        covid_symptomsDiarrheaVal,
        covid_symptomsFluVal,
        covid_symptomsNoneVal,
        covid_symptomsCommentVal,
      } = autoSavePedsCovidScreening;
      const _payload = {
        covid_dignosedWithCoronaVal: covid_dignosedWithCoronaVal,
        covid_dignosedWithCoronaCommentVal: covid_dignosedWithCoronaCommentVal,
        covid_highRiskVal: covid_highRiskVal,
        covid_highRiskCommentVal: covid_highRiskCommentVal,
        covid_symptomsFeverOrChillsVal: covid_symptomsFeverOrChillsVal,
        covid_symptomsCoughVal: covid_symptomsCoughVal,
        covid_symptomsShortnessOfBreathVal: covid_symptomsShortnessOfBreathVal,
        covid_symptomsFatigueVal: covid_symptomsFatigueVal,
        covid_symptomsMuscleOrBodyAchesVal: covid_symptomsMuscleOrBodyAchesVal,
        covid_symptomsHeadacheVal: covid_symptomsHeadacheVal,
        covid_symptomsLossOfTasteVal: covid_symptomsLossOfTasteVal,
        covid_symptomsSoreThroatVal: covid_symptomsSoreThroatVal,
        covid_symptomsCongestionVal: covid_symptomsCongestionVal,
        covid_symptomsNauseaVal: covid_symptomsNauseaVal,
        covid_symptomsDiarrheaVal: covid_symptomsDiarrheaVal,
        covid_symptomsFluVal: covid_symptomsFluVal,
        covid_symptomsNoneVal: covid_symptomsNoneVal,
        covid_symptomsCommentVal: covid_symptomsCommentVal,
      };
      let payload = {
        id: assessmentId,
        data: {
          name: "Validation of Consent",
          data: { ..._payload },
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", payload);
      setCurrSection(4);
      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        //const roleId = sessionStorage.getItem("roleId");

        // else {
        //   navigate("/AdminDashboard/MemberList");
        // }
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          setUser,
          sidebarData,
          setSidebarData,
          clientData,
          setClientData,
          userCategories,
          handleClientDataChange,
          handleClientDataSubmit,
          setCurrentColor,
          currentColor,
          selectedTimeZone,
          setSelectedTimeZone,
          assessmentData,
          updateAssessmentData,
          getAssessmentData: () => getAssessmentData,
          // getAssessmentData,
          setGetAssessmentData,
          getAssessment,
          handleSelectionDataSubmit,
          handleSelectionDataChange,
          handleBadgeClick,
          isDialogOpen,
          setIsDialogOpen,
          programTypesOptions,
          lobTypesOptions,
          hasLoggedIn,
          setHasLoggedIn,
          roles,
          updateRoles,
          memberIdForFormNames,
          _memberIdForQueryForm,
          memberDatasForNames,
          apiResponseData,
          setApiResponseData,
          setSelectedClientIdContext,
          selectedClientIdContext,
          setSelectedMultipleRowId,
          selectedMultipleRowId,
          lobOptionData,
          setLobOptionData,
          clinicianDataList,
          setClinicianDataList,
          hraStatusData,
          setHraStatusData,
          visitTypeOptionsData,
          setVisitTypeOptionsData,
          selectedTimeZoneUtc,
          setSelectedTimeZoneUtc,
          _setMemberIdForQueryForm,
          autoSaveRosData,
          setAutoSaveRosData,
          autoSavePainData,
          setAutoSavePainData,
          autoSaveCovidScreeningData,
          setAutoSaveCovidScreeningData,
          autoSaveSelfAssessmentData,
          setAutoSaveSelfAssessmentData,
          autoSaveFamilyHistoryData,
          setAutoSaveFamilyHistoryData,
          autoSaveMedicalHistory,
          setAutoSaveMedicalHistory,
          autoSaveActivitiesData,
          setAutoSaveActivitiesData,
          autoSaveVitalData,
          setAutoSaveVitalData,
          autoSaveMinicogData,
          setAutoSaveMiniCogData,
          autoSaveScreeningNeeded,
          setAutoSaveScreeningNeeded,
          autoSaveExamReviewData,
          setAutoSaveExamReviewData,
          autoSaveHomeSafetyData,
          setAutoSaveHomeSafety,
          autoSavePatientData,
          setAutoSavePatientData,
          autoSavePreventiveCareData,
          setAutoSavePreventiveCareData,
          autoSaveAllergiesData,
          setAutoSaveAllergiesData,
          autoSaveDemographicsData,
          setAutoSaveDemographicsData,
          autoSavePrevDocData,
          setAutoSavePrevDocData,
          currSection,
          setCurrSection,
          toasterMessage,
          setToasterMessage,
          toasterSeverity,
          setToasterSeverity,
          toasterOpen,
          setToasterOpen,
          saveSectionFormData,
          sideBarSectionDisable,
          setSideBarSectionDisable,
          autoSavePedsDemographicsData,
          setAutoSavePedsDemographicsData,
          autoSavePedsAssessInfo,
          setAutoSavePedsAssessInfo,
          autoSavePedsPreventativeCare,
          setAutoSavePedsPreventativeCare,
          autoSavePedsSocialSafty,
          setAutoSavePedsSocialSafty,
          setAutoSavePedsVital,
          autoSavePedsVital,
          autoSavePedsPatientSumm,
          setAutoSavePedsPatientSumm,
          autoSavepedsallergiesRowData,
          setAutoSavePedsAllergiesRowData,
          autoSavefirstLevelCommentObj,
          setAutoSavePedsFirstLevelCommentObj,
          autoSavecounterMedicationsRowData,
          setAutoSavePedsCounterMedicationsRowData,
          autoSavesecondLevelTextFieldAnswer,
          setAutoSavePedsSecondLevelTextFieldAnswer,
          autoSavecounterMedicationsRowDataQues15,
          setAutoSavePedsCounterMedicationsRowDataQues15,
          autoSavesecondLevelCommentObj,
          setAutoSavePedsSecondLevelCommentObj,
          onSavepedsDemographicsData,
          onSavePedsAssesmentInfoData,
          onSavePatientSummaryData,
          schedulerDataList,
          setSchedulerDataList,
          clinicianPrimary,
          setClinicianPrimary,
          dateOfRefNurse,
          setDateOfRefNurse,
          adminPrimary,
          setAdminPrimary,
          dateOfRefAdmin,
          setDateOfRefAdmin,
          onSaveValidationOfConsentData,
          autoSavePedsVoc,
          setAutoSavePedsVoc,

          onSavePedsCovidScreeningData,
          autoSavePedsCovidScreening,
          setAutoSavePedsCovidScreening,

          profileImg,
          setProfileImg,
        }}
      >
        {children}
      </UserContext.Provider>
      {isLoading && <Loader />}
    </>
  );
};
