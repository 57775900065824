import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";

const Input = (props) => {
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    props.onChangeHandler(event);

    // Save the cursor position
    setCursorPosition(inputRef.current.selectionStart);
  };

  useEffect(() => {
    // Set the cursor position after the value changes
    inputRef.current.selectionStart = cursorPosition;
    inputRef.current.selectionEnd = cursorPosition;
  }, [props.value, cursorPosition]);

  return (
    <div>
      <Box
        sx={{
          boxShadow: 3,
          background: "white",
          width: props.width,
          height: props.height,
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          marginLeft: props.ml,
          marginRight: props.mr,
          marginBottom: props.mb,
          marginTop: props.mt,
        }}
      >
        <IconTextField
          ref={inputRef}
          type={props.type}
          iconEnd={props.inputEndIcon}
          name={props.name}
          value={props.value}
          dataXpath={props.dataXpath}
          onChange={handleInputChange}
          passwordToggle={props.passwordToggle}
          // type={props.passwordVisible || props.confirmPasswordVisible ? 'text' : 'password'}
          // passwordToggle={props.passwordToggle}
          // confirmPasswordToggle={props.confirmPasswordToggle}
          inputProps={{
            style: {
              height: props.height,
              width: props.inputWidth,
              margin: 0,
              padding: 0,
              paddingLeft: "10px",
            },
          }}
          iconStart={props.inputIcon}
          placeholder={props.placeholder}
          error={Boolean(props?.error)}
          helperText={props.error}
        />
      </Box>
    </div>
  );
};

const IconTextField = React.forwardRef(
  (
    {
      iconStart,
      iconEnd,
      InputProps,
      passwordVisible,
      passwordToggle,
      dataXpath,
      ...props
    },
    ref,
  ) => {
    return (
      <TextField
        ref={ref}
        variant="standard"
        {...props}
        id={dataXpath}
        InputProps={{
          disableUnderline: true,
          ...InputProps,
          startAdornment: iconStart ? (
            <InputAdornment sx={{ marginRight: "0px" }} position="start">
              {iconStart}
            </InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment sx={{ marginLeft: "0px" }} position="end">
              {iconEnd}
            </InputAdornment>
          ) : null,
        }}
      />
    );
  },
);

export default Input;
