// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  font-size: 12px;
  background-color: var(--mainTheme);
  color: var(--colorWhite);
  border-radius: 100px;
  border: none;
  height: 30px;
  min-width: 80px;
}

button.MuiButtonBase-root {
  min-width: 30px;
}

.custom-button {
  /* if datatable contains action buttons applied this styles */
  margin-left: 5px;
  /* Your existing button styles */
  /* Add a custom style for the disabled state */
}
.custom-button.disabled {
  pointer-events: none; /* Disable click events */
  opacity: 0.5; /* Apply a visual cue for disabled state */
  cursor: not-allowed; /* Change the cursor type */
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kCAAA;EACA,wBAAA;EACA,oBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACF;;AACA;EACE,eAAA;AAEF;;AAAA;EACE,6DAAA;EACA,gBAAA;EACA,gCAAA;EAEA,8CAAA;AAEF;AADE;EACE,oBAAA,EAAA,yBAAA;EACA,YAAA,EAAA,0CAAA;EACA,mBAAA,EAAA,2BAAA;AAGJ","sourcesContent":["button {\r\n  font-size: 12px;\r\n  background-color: var(--mainTheme);\r\n  color: var(--colorWhite);\r\n  border-radius: 100px;\r\n  border: none;\r\n  height: 30px;\r\n  min-width: 80px; // Breaks the calendar, when adding props to global elements precausions must be taken(check if it breaks something else)\r\n}\r\nbutton.MuiButtonBase-root {\r\n  min-width: 30px;\r\n}\r\n.custom-button {\r\n  /* if datatable contains action buttons applied this styles */\r\n  margin-left: 5px;\r\n  /* Your existing button styles */\r\n\r\n  /* Add a custom style for the disabled state */\r\n  &.disabled {\r\n    pointer-events: none; /* Disable click events */\r\n    opacity: 0.5; /* Apply a visual cue for disabled state */\r\n    cursor: not-allowed; /* Change the cursor type */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
