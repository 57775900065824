import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

export const RedirectToHome = ({ children, moveToHome }) => {
  // to-do : get homepage from backend
  // moveToHome && console.log("user logged in & moving to memberlist");
  return moveToHome ? <Navigate to="/AdminDashboard/MemberList" /> : children;
};

export const RedirectToLogin = ({ children, moveToLogin }) => {
  // to-do : use Outlet here
  // console.log(moveToLogin,"moving to login");
  // !moveToLogin && console.log("user is not logged in & moving to login page");
  // return moveToLogin ? <Outlet/> : <Navigate to="/Account/Login" />;
  return moveToLogin ? children : <Navigate to="/" />;
};

RedirectToHome.propTypes = {
  children: PropTypes.node,
  moveToHome: PropTypes.bool,
};

RedirectToLogin.propTypes = {
  children: PropTypes.node,
  moveToLogin: PropTypes.bool,
};
