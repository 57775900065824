const Labels = {
  SUCCESS: "success",
  ERROR: "error",
  Reset_Successful: "resetSuccessful",
  Username: "Username",
  Admin: "ADMIN",
  LeadScheduler: "LEAD SCHEDULER",
  Scheduler: "SCHEDULER",
};

export default Labels;
