export const inputTypesList = [
  {
    id: 1,
    name: "input_box_text",
    type: "text",
    label: "Text",
    editToggle: false,
    xs: 4,
    required: "no",
    placeholder: "",
    defaultValue: "",
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 2,
    name: "input_box_number",
    type: "number",
    label: "Number",
    editToggle: false,
    xs: 4,
    required: "no",
    placeholder: "",
    defaultValue: "",
    //   min: 0,
    //   max: 100,
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 3,
    name: "radio_button",
    label: "Radio",
    placeholder: "",
    editToggle: false,
    defaultValue: "",
    type: "radio",
    color: "primary",
    options: [
      ["", ""],
      ["", ""],
    ],
    xs: 6,
    required: "no",
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 4,
    name: "date",
    label: "Date",
    placeholder: "",
    type: "date",
    editToggle: false,
    xs: 4,
    defaultValue: new Date().toISOString().split("T")[0],
    value: new Date().toISOString().split("T")[0],
    fullWidth: true,
    required: "no",
    // minDate:"",
    // maxDate:"",
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 5,
    name: "dropdown",
    label: "Dropdown",
    placeholder: "",
    editToggle: false,
    defaultValue: "",
    type: "select",
    options: [
      ["", ""],
      ["", ""],
    ],
    xs: 6,
    required: "no",
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 6,
    name: "options_tree",
    type: "options_tree",
    label: "Options",
    fullWidth: true,
    required: true,
    xs: 12,
    isCommentEnabled: true,
    isConfigured: false,
    isModified: false,
    hasSubQuestions: true,
    subQuestions: [
      {
        depth: 1,
        subQuestionId: 1,
        hasSubQuestions: false,
        subQuestions: [],
        editToggle: false,
        isCommentEnabled: false,
        // isConfigured: false,
        // isModified: false,
      },
    ],
  },
  {
    id: 7,
    name: "input_label",
    type: "label",
    label: "Label",
    editToggle: false,
    xs: 12,
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 8,
    name: "input_button",
    type: "button",
    label: "Button",
    editToggle: false,
    xs: 3,
    isSingleButton: false,
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 9,
    name: "input_heading",
    type: "heading",
    label: "Heading",
    editToggle: false,
    xs: 3,
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 10,
    name: "check_box",
    label: "Checkbox",
    placeholder: "",
    editToggle: false,
    defaultValue: "",
    type: "checkbox",
    color: "primary",
    options: [["", ""]],
    xs: 12,
    required: "no",
    // isEnabledClients: [
    //   ["VPHP", true],
    //   ["OHP", true],
    //   ["SWHR", false],
    //   ["ALLIANT", false],
    //   ["HA", false],
    //   ["SHP", false],
    // ],
    // isEnabledPlans: [
    //   ["Medicare", true],
    //   ["MedicAid", true],
    // ],
    // isAgeGroup: [
    //   ["Above 65", true],
    //   ["Below 65", true],
    // ],
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 11,
    name: "data_table",
    label: "Data Table",
    editToggle: false,
    type: "datatable",
    tablelist: [
      [
        {
          id: 1,
          label: "Allergies",
          name: "HRAForm_Allergies",
          columns: [
            {
              label: "Substance",
              type: "text",
            },
            {
              label: "Reaction",
              type: "text",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],

      [
        {
          id: 2,
          label: "BMI Index Chart",
          name: "HRAForm_BMI_Index_Chart",
          columns: [
            {
              label: "BMI",
              type: "text",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 3,
          label: "Counter Medications",
          name: "HRAForm_Counter_Medications",
          columns: [
            {
              label: "Date",
              type: "date",
            },
            {
              label: "Description",
              type: "text",
            },
            {
              label: "Dose Per Units",
              type: "text",
            },
            {
              label: "Route",
              type: "select",
            },
            {
              label: "Frequency",
              type: "text",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],
      [
        {
          id: 4,
          label: "Medical History Specialists",
          name: "HRAForm_Medical_History_Speciality",
          columns: [
            {
              label: "Member Specialist",
              type: "select",
            },
            {
              label: "Speciality",
              type: "text",
            },
            {
              label: "For",
              type: "text",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],
      [
        {
          id: 5,
          label: "Medications",
          name: "HRAForm_Medications",
          columns: [
            {
              label: "Diagnoses",
              type: "text",
            },
            {
              label: "label Name",
              type: "select",
            },
            {
              label: "Dose Per Units",
              type: "text",
            },
            {
              label: "Route",
              type: "select",
            },
            {
              label: "Frequency",
              type: "select",
            },
            {
              label: "Prescribing Physician",
              type: "text",
            },
            {
              label: "Status",
              type: "togglebutton",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],

      [
        {
          id: 6,
          label: "Mini Cog Versions",
          name: "HRAForm_Minicog_Version",
          columns: [
            {
              label: "Version 1",
              type: "text",
            },
            {
              label: "Version 2",
              type: "text",
            },
            {
              label: "Version 3",
              type: "text",
            },
            {
              label: "Version 4",
              type: "text",
            },
            {
              label: "Version 5",
              type: "text",
            },
            {
              label: "Version 6",
              type: "text",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 7,
          label: "Preventive Care",
          name: "HRAForm_Preventive_Care",
          columns: [
            {
              label: "Screen",
              type: "text",
            },
            {
              label: "Answer",
              type: "select",
            },
            {
              label: "Date",
              type: "text",
            },
            {
              label: "Method",
              type: "text",
            },
            {
              label: "Recommendation",
              type: "checkbox",
            },
            {
              label: "Education Completed",
              type: "checkbox",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 8,
          label: "Previously Documented Conditions",
          name: "HRAForm_Previously_Documented_Conditions",
          columns: [
            {
              label: "Diagnosis",
              type: "text",
            },
            {
              label: "Prior HCC",
              type: "text",
            },
            {
              label: "Diagnosis Code",
              type: "text",
            },
            {
              label: "Date of Service",
              type: "date",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],
      [
        {
          id: 9,
          label: "Prior Assessments",
          name: "HRAForm_Prior_Assessments",
          columns: [
            {
              label: "Date Time",
              type: "date",
            },
            {
              label: "Document",
              type: "button",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 10,
          label: "Stages Of Kidney Disease",
          name: "HRAForm_Stage_Of_Kidney_Disease",
          columns: [
            {
              label: "Stages",
              type: "text",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 11,
          label: "Alcohol Usage",
          name: "HRAForm_Alcohol_Usage",
          columns: [
            {
              label: "How many drinks",
              type: "select",
            },
            {
              label: "How often",
              type: "select",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 12,
          name: "Family History",
          columns: [
            {
              label: "Family Member",
              type: "select",
            },
            {
              label: "Medical Condition",
              type: "text",
            },
            {
              label: "Cause of Death",
              type: "text",
            },
          ],
          isReadOnly: false,
        },
        false,
      ],

      [
        {
          id: 13,
          label: "Depression Severity",
          name: "Depression_Severity",
          columns: [
            {
              label: "Score",
              type: "number",
            },
            {
              label: "Depression Severity",
              type: "checkbox",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],

      [
        {
          id: 14,
          label: "Word Recall",
          name: "Word_Recall",
          columns: [
            {
              label: "Word Recall",
              type: "text",
            },
            {
              label: "Points",
              type: "select",
            },
            {
              label: "Description",
              type: "text",
            },
          ],
          isReadOnly: true,
        },
        false,
      ],
      [
        {
          id: 14,
          label: "Activities Chart",
          name: "activities_chart",
          columns: [
            {
              label: "Score",
              type: "text",
            },
            {
              label: "Indication",
              type: "text",
            },
          ],
          isReadOnly: true,
        },
      ],
    ],
    xs: 12,
    required: "no",
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 12,
    name: "image",
    label: "Image",
    placeholder: "",
    editToggle: false,
    defaultValue: "",
    type: "image",
    src: "",
    alt: "",
    xs: 6,
    required: "no",
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
  {
    id: 13,
    name: "horizontal_line",
    label: "Horizontal Line",
    placeholder: "",
    editToggle: false,
    defaultValue: "",
    type: "line",
    xs: 12,
    required: "no",
    isCommentEnabled: false,
    isConfigured: false,
    isModified: false,
  },
];
