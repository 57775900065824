import "./button.scss";

const Button = (props) => {
  const { disabled, ...otherProps } = props;

  return (
    <button
      aria-hidden="false"
      onClick={props.onClick}
      className={`custom-button ${props.disabled ? "disabled" : ""}`}
      id={props.dataXpath}
      type={props.type}
      style={{
        marginLeft: props.ml,
        marginRight: props.mr,
        marginBottom: props.mb,
        marginTop: props.mt,
        borderRadius: props.borderRadius,
        width: props.width,
        height: props.height,
        color: props.color,
        background: props.background,
        fontSize: props.fontSize,
        border: props.border,
        fontFamily: props.fontFamily,
        cursor: props.cursor,
        display: props.display,
        disabled: props.disabled,
        variant: props.variant,
        onClick: props.onClick,
        position: props.position,
        bottom: props.bottom,
        right: props.right,
        zIndex: props.zIndex,
        padding: props.padding,
      }}
    >
      {props.children}
    </button>
  );
};

export default Button;
