import { createContext, useState } from "react";

const AlertContext = createContext();

export const AlertProvider = (props) => {
  const [filterDialog, setFilterDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false); //client
  const [editClinicianDialog, setEditClinicianDialog] = useState(false); //clinician
  const [editAdminDialog, setAdminEditDialog] = useState(false); //admin
  const [addClientDialog, setaddClientDialog] = useState(false);
  const [vphpFilterDialog, setVphpFilterDialog] = useState(false);
  const [addVphpDialog, setAddVphpDialog] = useState(false);
  const [filterDialogData, setFilterDialogData] = useState(null);

  return (
    <AlertContext.Provider
      value={{
        filterDialog,
        setFilterDialog,
        addDialog,
        setAddDialog,
        editDialog,
        setEditDialog,
        editClinicianDialog,
        setEditClinicianDialog,
        vphpFilterDialog,
        setVphpFilterDialog,
        addVphpDialog,
        setAddVphpDialog,
        addClientDialog,
        setaddClientDialog,
        editAdminDialog,
        setAdminEditDialog,
        filterDialogData,
        setFilterDialogData,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
