import React from "react";

const MemberListPage = React.lazy(() =>
  import("../pages/AdminDashboard/MemberListPage/MemberListPage"),
);
const AddMembersPage = React.lazy(() =>
  import("../pages/AdminDashboard/AddMembersPage/AddMembersPage"),
);
const MemberProfile = React.lazy(() =>
  import("../components/AdminDashboard/memberProfile"),
);
const DragNDrop = React.lazy(() =>
  import("../pages/AdminDashboard/DragNDrop/DragNDrop"),
);
const DynamicForms = React.lazy(() =>
  import("../pages/AdminDashboard/DynamicForms/DynamicForms"),
);
const Reports = React.lazy(() =>
  import("../pages/AdminDashboard/Reports/Reports"),
);
const Scheduler = React.lazy(() =>
  import("../pages/AdminDashboard/Scheduler/Scheduler"),
);
const ViewAdministrators = React.lazy(() =>
  import("../pages/AdminDashboard/ViewAdministrators/ViewAdministrators"),
);
const ViewClients = React.lazy(() =>
  import("../pages/AdminDashboard/ViewClients/ViewClients"),
);
const ViewClincians = React.lazy(() =>
  import("../pages/AdminDashboard/ViewClinicians/ViewClinicians"),
);
const ViewReports = React.lazy(() =>
  import("../pages/AdminDashboard/ViewReports/ViewReports"),
);
const Nurse = React.lazy(() =>
  import("../pages/NurseDashboard/NurseDashboard"),
);

const ClientReports = React.lazy(() =>
  import("../pages/ClientPortal/Reports/Reports"),
);
const ClientDashboard = React.lazy(() =>
  import("../pages/ClientPortal/Dashboard/Dashboard"),
);
const ClientProfile = React.lazy(() =>
  import("../pages/ClientPortal/ViewClients/ClientProfile"),
);
const ClientMembersList = React.lazy(() =>
  import("../pages/ClientPortal/MemberListPage/MemberListPage"),
);
// const ClientMemberProfile = React.lazy(() =>
//   import("../components/clientPortal/memberProfile"),
// );

const Calendar = React.lazy(() => import("../pages/NurseDashboard/Calendar"));
const QADashboardList = React.lazy(() => import("../pages/QaDashboard/qalist"));
const FillHraForm = React.lazy(() =>
  import("../pages/AdminDashboard/MemberListPage/FillHraForm"),
);
const PriorFillHraForm = React.lazy(() =>
  import("../components/Dashboard/MemberList/Fill_HRA/PriorFillHra"),
);
const CoderDashboardList = React.lazy(() =>
  import("../pages/CoderDashboard/coderlist"),
);

export const AdminRoutes = [
  {
    path: "MemberList",
    title: "Admin Dashboard",
    component: <MemberListPage />,
  },
  {
    path: "AddNewMember",
    title: "Add Members",
    component: <AddMembersPage />,
  },
  {
    path: "ViewClinicians",
    title: "View Clinicians",
    component: <ViewClincians />,
  },
  {
    path: "ViewClients",
    title: "View Clients",
    component: <ViewClients />,
  },
  {
    path: "ViewAdministrators",
    title: "View Administrators",
    component: <ViewAdministrators />,
  },
  { path: "Reports", title: "Reports", component: <Reports /> },
  { path: "Scheduler", title: "Schedulers", component: <Scheduler /> },
  {
    path: "ImportFiles",
    title: "Import Files",
    component: <ViewReports />,
  },
  {
    path: "CreateHRAForm",
    title: "Create HRA Form",
    component: <DragNDrop />,
  },
  {
    path: "PreviewHRAForm",
    title: "Preview",
    component: <DynamicForms />,
  },
  {
    path: "FillHRAForm",
    title: "Fill HRA Form",
    component: <FillHraForm />,
  },
  {
    path: "memberProfile",
    title: "Members Profile",
    component: <MemberProfile />,
  },
  {
    path: "PriorAssessment",
    title: "",
    component: <PriorFillHraForm />,
  },
];
export const QARoutes = [
  { path: "QAList", title: "", component: <QADashboardList /> },
  {
    path: "FillHRAForm",
    title: "Fill HRA Form",
    component: <FillHraForm />,
  },
];
export const CoderRoutes = [
  { path: "Coderlist", title: "", component: <CoderDashboardList /> },
  {
    path: "FillHRAForm",
    title: "Fill HRA Form",
    component: <FillHraForm />,
  },
];
export const SchedulerRoutes = [
  { path: "Scheduler", title: "", component: <Scheduler /> },
];
export const NurseRoutes = [
  { path: "Nurselist", title: "", component: <Nurse /> },
  { path: "Calendar", title: "", component: <Calendar /> },
  { path: "AddNewMember", title: "", component: <AddMembersPage /> },
  {
    path: "FillHRAForm",
    title: "Fill HRA Form",
    component: <FillHraForm />,
  },
  {
    path: "PriorAssessment",
    title: "",
    component: <PriorFillHraForm />,
  },
];

export const ClientRoutes = [
  { path: "ClientDashboard", title: "", component: <ClientDashboard /> },
  { path: "MemberList", title: "", component: <ClientMembersList /> },
  { path: "ProfileDetails", title: "", component: <ClientProfile /> },
  { path: "Reports", title: "Fill HRA Form", component: <Reports /> },
  { path: "memberProfile", title: "", component: <MemberProfile /> },
];

export const getProtectedRoutes = (roleId) => {
  let routes = [];
  if (roleId == 2 || roleId == 1) routes = [...routes, ...AdminRoutes];
  if (roleId == 3 || roleId == 4) routes = [...routes, ...SchedulerRoutes];
  if (roleId == 5) routes = [...routes, ...NurseRoutes];
  if (roleId == 6 || roleId == 7) routes = [...routes, ...QARoutes];
  if (roleId == 8 || roleId == 9) routes = [...routes, ...CoderRoutes];
  if (roleId == 10) routes = [...routes, ...ClientRoutes];

  return routes;
};
