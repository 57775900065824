import {
  deleteSubTreeData,
  getObjectData,
  getOptionsSelected,
  getOptionsSelectedYesNo,
  getOptionsSelectedYesNoDontKnow,
  getOptionsSelectedYesNoPartially,
  getOptionsSelectedYesNoUnknown,
  getSubTreeString,
  setObjectData,
  setOptionsSelected,
  setOptionsSelectedYesNo,
  setOptionsSelectedYesNoDontKnow,
  setOptionsSelectedYesNoPartially,
  setOptionsSelectedYesNoUnknown,
} from "../../../../../../utils/helpers.DynamicForm";

const medical_flatmap = [
  ["medical_assistNeed", "assistiveDevices.isNone", ["2023", "2024"], "3"],
  [
    "medical_assistNeedComment",
    "assistiveDevices.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_assistNeedSubDevicesComment",
    "assistiveDevices.subAnswers.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_assistNeedSubDevicesSubisCane",
    "assistiveDevices.subAnswers.isCane",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisWalker",
    "assistiveDevices.subAnswers.isWalker",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisProsthesis",
    "assistiveDevices.subAnswers.isProsthesis",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygen",
    "assistiveDevices.subAnswers.isOxygen",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDescSubisPRN",
    "assistiveDevices.subAnswers.oxygenSubAnswers.describe.isPRN",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDescSubisContinuous",
    "assistiveDevices.subAnswers.oxygenSubAnswers.describe.isContinuous",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDescSubisDay",
    "assistiveDevices.subAnswers.oxygenSubAnswers.describe.isDay",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDescSubisNight",
    "assistiveDevices.subAnswers.oxygenSubAnswers.describe.isNight",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDescComment",
    "assistiveDevices.subAnswers.oxygenSubAnswers.describe.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubLitres",
    "assistiveDevices.subAnswers.oxygenSubAnswers.Litres",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_assistNeedSubDevicesSubisWheelChair",
    "assistiveDevices.subAnswers.isWheelChair",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisurinal",
    "assistiveDevices.subAnswers.isurinal",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisBedPan",
    "assistiveDevices.subAnswers.isBedPan",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisCPAP",
    "assistiveDevices.subAnswers.isCPAP",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOther",
    "assistiveDevices.subAnswers.isOther",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_assistNeedSubDevicesSubisOtherSubDesc",
    "assistiveDevices.subAnswers.otherSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_assistNeedSubDevicesSubisBedsideCommode",
    "assistiveDevices.subAnswers.isBedsideCommode",
    ["2023", "2024"],
    "2",
  ],
  ["medical_specialists", "specialists.isSpecialists", ["2023", "2024"], "3"],
  [
    "medical_specialistsSubDatatable",
    "specialists.specialistsSubAnswers",
    ["2023", "2024"],
    "1",
  ],
  ["medical_specialistsComment", "specialists.comment", ["2023", "2024"], "1"],
  [
    "medical_past12MonthPcpIsNone",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isNone",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpIsOne",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isOne",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpIsTwo",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isTwo",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpIsThree",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isThree",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpIsFour",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isFour",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpIsFiveOrMore",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.isFiveOrMore",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthPcpComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.pcp.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomNone",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isNone",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomOne",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOne",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomOneSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisit",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.edVisit",
    ["2024"],
    "4",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisitComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.edVisitSubAnswers.noSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.edVisitSubAnswers.noSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.edVisitSubAnswers.unknownSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isOneSubAnswers.edVisitSubAnswers.unknownSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomTwo",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwo",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisit",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.edVisit",
    ["2024"],
    "4",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisitComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.edVisitSubAnswers.noSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.edVisitSubAnswers.noSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.edVisitSubAnswers.unknownSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isTwoSubAnswers.edVisitSubAnswers.unknownSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomThree",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThree",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisit",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.edVisit",
    ["2024"],
    "4",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisitComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.edVisitSubAnswers.noSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.edVisitSubAnswers.noSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.edVisitSubAnswers.unknownSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isThreeSubAnswers.edVisitSubAnswers.unknownSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFour",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFour",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomFourSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisit",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.edVisit",
    ["2024"],
    "4",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisitComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.edVisitSubAnswers.noSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.edVisitSubAnswers.noSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.edVisitSubAnswers.unknownSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFourSubAnswers.edVisitSubAnswers.unknownSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMore",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMore",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisit",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.edVisit",
    ["2024"],
    "4",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.comment",
    ["2024"],
    "1",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.edVisitSubAnswers.noSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.edVisitSubAnswers.noSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.edVisitSubAnswers.unknownSubAnswers.isEducationProvided",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.isFiveOrMoreSubAnswers.edVisitSubAnswers.unknownSubAnswers.edVisitFollowUp",
    ["2024"],
    "7",
  ],
  [
    "medical_past12MonthEmerRoomComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightIsNone",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isNone",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsOne",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isOne",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsOneSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isOneSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightIsTwo",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isTwo",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsTwoSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isTwoSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightIsThree",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isThree",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsThreeSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isThreeSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightIsFour",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isFour",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsFourSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isFourSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightIsFiveOrMore",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isFiveOrMore",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHospOvernightIsFiveOrMoreSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.isFiveOrMoreSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHospOvernightComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hospitalOvernight.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeisNone",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isNone",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisOne",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isOne",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisOneSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isOneSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeisTwo",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isTwo",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisTwoSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isTwoSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeisThree",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isThree",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisThreeSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isThreeSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeisFour",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isFour",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisFourSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isFourSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeisFiveOrMore",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isFiveOrMore",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthNursHomeisFiveOrMoreSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.isFiveOrMoreSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthNursHomeComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.nursingHome.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryisNone",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isNone",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisOne",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isOne",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisOneSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isOneSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryisTwo",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isTwo",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisTwoSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isTwoSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryisThree",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isThree",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisThreeSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isThreeSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryisFour",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isFour",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisFourSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isFourSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryisFiveOrMore",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isFiveOrMore",
    ["2023", "2024"],
    "2",
  ],
  [
    "medical_past12MonthHadSurgeryisFiveOrMoreSubDesc",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.isFiveOrMoreSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_past12MonthHadSurgeryComment",
    "pastTwelveMonths.pastTwelveMonthsSubAnswers.hadSurgery.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_hospPrior",
    "hospitalizedPrior.isHospitalizedPrior",
    ["2023", "2024"],
    "3",
  ],
  [
    "medical_hospPriorSubDesc",
    "hospitalizedPrior.hospitalizedPriorSubAnswers.describe",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_hospPriorComment",
    "hospitalizedPrior.comment",
    ["2023", "2024"],
    "1",
  ],
  [
    "medical_healthSubPhysicalTherapist",
    "healthServices.healthServicesSubAnswers[0].physicalTherapist",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubOccupationalTherapist",
    "healthServices.healthServicesSubAnswers[1].occupationalTherapist",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubDietician",
    "healthServices.healthServicesSubAnswers[2].dietician",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubSocialWorker",
    "healthServices.healthServicesSubAnswers[3].socialWorker",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubPharmacist",
    "healthServices.healthServicesSubAnswers[4].pharmacist",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubSpeechTherapist",
    "healthServices.healthServicesSubAnswers[5].speechTherapist",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubChiropractor",
    "healthServices.healthServicesSubAnswers[6].shiropractor",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubPersonalCareWorker",
    "healthServices.healthServicesSubAnswers[7].personalCareWorker",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubMealsOnWheels",
    "healthServices.healthServicesSubAnswers[8].mealsOnWheels",
    ["2023"],
    "3",
  ],
  [
    "medical_healthSubAdultDayCare",
    "healthServices.healthServicesSubAnswers[9].adultDayCare",
    ["2023"],
    "3",
  ],
  [
    "medical_treatmentsSubChemotherapy",
    "treatments.treatmentsSubAnswers[0].chemotherapy",
    ["2023"],
    "4",
  ],
  [
    "medical_treatmentsSubCatheterCare",
    "treatments.treatmentsSubAnswers[1].catheterCare",
    ["2023"],
    "4",
  ],
  [
    "medical_treatmentsSubOxygen",
    "treatments.treatmentsSubAnswers[2].oxygen",
    ["2023"],
    "4",
  ],
  [
    "medical_treatmentsSubWoundCare",
    "treatments.treatmentsSubAnswers[3].woundCare",
    ["2023"],
    "4",
  ],
  [
    "medical_treatmentsSubRegularInjections",
    "treatments.treatmentsSubAnswers[4].regularInjections",
    ["2023"],
    "4",
  ],
  [
    "medical_treatmentsSubTubeFeedings",
    "treatments.treatmentsSubAnswers[5].tubeFeedings",
    ["2023"],
    "4",
  ],
  [
    "medical_healthSubPhysicalTherapistComment",
    "healthServices.healthServicesSubAnswers[0].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubOccupationalTherapistComment",
    "healthServices.healthServicesSubAnswers[1].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubDieticianComment",
    "healthServices.healthServicesSubAnswers[2].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubSocialWorkerComment",
    "healthServices.healthServicesSubAnswers[3].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubPharmacistComment",
    "healthServices.healthServicesSubAnswers[4].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubSpeechTherapistComment",
    "healthServices.healthServicesSubAnswers[5].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubChiropractorComment",
    "healthServices.healthServicesSubAnswers[6].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubPersonalCareWorkerComment",
    "healthServices.healthServicesSubAnswers[7].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubMealsOnWheelsComment",
    "healthServices.healthServicesSubAnswers[8].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubAdultDayCareComment",
    "healthServices.healthServicesSubAnswers[9].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubChemotherapyComment",
    "treatments.treatmentsSubAnswers[0].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubCatheterCareComment",
    "treatments.treatmentsSubAnswers[1].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubOxygenComment",
    "treatments.treatmentsSubAnswers[2].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubWoundCareComment",
    "treatments.treatmentsSubAnswers[3].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubRegularInjectionsComment",
    "treatments.treatmentsSubAnswers[4].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_treatmentsSubTubeFeedingsComment",
    "treatments.treatmentsSubAnswers[5].comment",
    ["2023"],
    "1",
  ],
  [
    "medical_healthSubPhysicalTherapist",
    "healthServices.physicalTherapist",
    ["2024"],
    "2",
  ],
  [
    "medical_healthSubOccupationalTherapist",
    "healthServices.occupationalTherapist",
    ["2024"],
    "2",
  ],
  ["medical_healthSubDietician", "healthServices.dietician", ["2024"], "2"],
  [
    "medical_healthSubSocialWorker",
    "healthServices.socialWorker",
    ["2024"],
    "2",
  ],
  ["medical_healthSubPharmacist", "healthServices.pharmacist", ["2024"], "2"],
  [
    "medical_healthSubSpeechTherapist",
    "healthServices.speechTherapist",
    ["2024"],
    "2",
  ],
  [
    "medical_healthSubChiropractor",
    "healthServices.shiropractor",
    ["2024"],
    "2",
  ],
  [
    "medical_healthSubPersonalCareWorker",
    "healthServices.personalCareWorker",
    ["2024"],
    "2",
  ],
  [
    "medical_healthSubMealsOnWheels",
    "healthServices.mealsOnWheels",
    ["2024"],
    "2",
  ],
  [
    "medical_healthSubAdultDayCare",
    "healthServices.adultDayCare",
    ["2024"],
    "2",
  ],
  ["medical_healthSubEyeExam", "healthServices.eyeExam", ["2024"], "2"],
  ["medical_healthSubDentalExam", "healthServices.dentalExam", ["2024"], "2"],
  ["medical_healthSubNone", "healthServices.none", ["2024"], "2"],
  [
    "medical_treatmentsSubChemotherapy",
    "treatments.chemotherapy",
    ["2024"],
    "2",
  ],
  [
    "medical_treatmentsSubCatheterCare",
    "treatments.catheterCare",
    ["2024"],
    "2",
  ],
  ["medical_treatmentsSubOxygen", "treatments.oxygen", ["2024"], "2"],
  ["medical_treatmentsSubWoundCare", "treatments.woundCare", ["2024"], "2"],
  [
    "medical_treatmentsSubRegularInjections",
    "treatments.regularInjections",
    ["2024"],
    "2",
  ],
  [
    "medical_treatmentsSubTubeFeedings",
    "treatments.tubeFeedings",
    ["2024"],
    "2",
  ],
  ["medical_treatmentsSubNone", "treatments.none", ["2024"], "2"],
  ["medical_healthComment", "healthServices.comment", ["2024"], "1"],
  ["medical_treatmentsComment", "treatments.comment", ["2024"], "1"],
];

const medical_optionsTreeMappers = [
  [{ medical_assistNeedSubDevicesSubisCane: "cane" }, ["2023", "2024"]],
  [{ medical_assistNeedSubDevicesSubisWalker: "walker" }, ["2023", "2024"]],
  [
    { medical_assistNeedSubDevicesSubisProsthesis: "prosthesis" },
    ["2023", "2024"],
  ],
  [{ medical_assistNeedSubDevicesSubisOxygen: "oxygen" }, ["2023", "2024"]],
  [
    { medical_assistNeedSubDevicesSubisWheelChair: "wheelChair" },
    ["2023", "2024"],
  ],
  [{ medical_assistNeedSubDevicesSubisurinal: "urinal" }, ["2023", "2024"]],
  [{ medical_assistNeedSubDevicesSubisBedPan: "bedPan" }, ["2023", "2024"]],
  [{ medical_assistNeedSubDevicesSubisCPAP: "cpap" }, ["2023", "2024"]],
  [{ medical_assistNeedSubDevicesSubisOther: "other" }, ["2023", "2024"]],
  [
    { medical_assistNeedSubDevicesSubisBedsideCommode: "besideCommode" },
    ["2023", "2024"],
  ],
  [{ medical_past12MonthPcpIsNone: "none" }, ["2023", "2024"]],
  [{ medical_past12MonthPcpIsOne: "1" }, ["2023", "2024"]],
  [{ medical_past12MonthPcpIsTwo: "2" }, ["2023", "2024"]],
  [{ medical_past12MonthPcpIsThree: "3" }, ["2023", "2024"]],
  [{ medical_past12MonthPcpIsFour: "4" }, ["2023", "2024"]],
  [{ medical_past12MonthPcpIsFiveOrMore: "5" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomNone: "none" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomOne: "1" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomTwo: "2" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomThree: "3" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomFour: "4" }, ["2023", "2024"]],
  [{ medical_past12MonthEmerRoomFiveOrMore: "5" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsNone: "none" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsOne: "1" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsTwo: "2" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsThree: "3" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsFour: "4" }, ["2023", "2024"]],
  [{ medical_past12MonthHospOvernightIsFiveOrMore: "5" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisNone: "none" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisOne: "1" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisTwo: "2" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisThree: "3" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisFour: "4" }, ["2023", "2024"]],
  [{ medical_past12MonthNursHomeisFiveOrMore: "5" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisNone: "none" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisOne: "1" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisTwo: "2" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisThree: "3" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisFour: "4" }, ["2023", "2024"]],
  [{ medical_past12MonthHadSurgeryisFiveOrMore: "5" }, ["2023", "2024"]],
  [
    { medical_assistNeedSubDevicesSubisOxygenSubDescSubisPRN: "prn" },
    ["2023", "2024"],
  ],
  [
    {
      medical_assistNeedSubDevicesSubisOxygenSubDescSubisContinuous:
        "continuous",
    },
    ["2023", "2024"],
  ],
  [
    { medical_assistNeedSubDevicesSubisOxygenSubDescSubisDay: "day" },
    ["2023", "2024"],
  ],
  [
    { medical_assistNeedSubDevicesSubisOxygenSubDescSubisNight: "night" },
    ["2023", "2024"],
  ],
  [{ medical_healthSubPhysicalTherapist: "physicalTherapist" }, ["2024"]],
  [
    { medical_healthSubOccupationalTherapist: "occupationalTherapist" },
    ["2024"],
  ],
  [{ medical_healthSubDietician: "dietician" }, ["2024"]],
  [{ medical_healthSubSocialWorker: "socialWorker" }, ["2024"]],
  [{ medical_healthSubPharmacist: "pharmacist" }, ["2024"]],
  [{ medical_healthSubSpeechTherapist: "speechTherapist" }, ["2024"]],
  [{ medical_healthSubChiropractor: "chiropractor" }, ["2024"]],
  [{ medical_healthSubPersonalCareWorker: "personalCareWorker" }, ["2024"]],
  [{ medical_healthSubMealsOnWheels: "mealsOnWheels" }, ["2024"]],
  [{ medical_healthSubAdultDayCare: "adultDayCare" }, ["2024"]],
  [{ medical_healthSubEyeExam: "eyeExam" }, ["2024"]],
  [{ medical_healthSubDentalExam: "dentalExam" }, ["2024"]],
  [{ medical_healthSubNone: "none" }, ["2024"]],
  [{ medical_treatmentsSubChemotherapy: "chemotherapy" }, ["2024"]],
  [{ medical_treatmentsSubCatheterCare: "catheterCare" }, ["2024"]],
  [{ medical_treatmentsSubOxygen: "oxygen" }, ["2024"]],
  [{ medical_treatmentsSubWoundCare: "woundCare" }, ["2024"]],
  [{ medical_treatmentsSubRegularInjections: "regularInjections" }, ["2024"]],
  [{ medical_treatmentsSubTubeFeedings: "tubeFeedings" }, ["2024"]],
  [{ medical_treatmentsSubNone: "none" }, ["2024"]],
];

const medical_optionsTreeWrappers = [
  [
    "medical_assistNeedSubDevices",
    [
      "medical_assistNeedSubDevicesSubisCane",
      "medical_assistNeedSubDevicesSubisWalker",
      "medical_assistNeedSubDevicesSubisProsthesis",
      "medical_assistNeedSubDevicesSubisOxygen",
      "medical_assistNeedSubDevicesSubisWheelChair",
      "medical_assistNeedSubDevicesSubisurinal",
      "medical_assistNeedSubDevicesSubisBedPan",
      "medical_assistNeedSubDevicesSubisCPAP",
      "medical_assistNeedSubDevicesSubisOther",
      "medical_assistNeedSubDevicesSubisBedsideCommode",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_past12MonthPcp",
    [
      "medical_past12MonthPcpIsNone",
      "medical_past12MonthPcpIsOne",
      "medical_past12MonthPcpIsTwo",
      "medical_past12MonthPcpIsThree",
      "medical_past12MonthPcpIsFour",
      "medical_past12MonthPcpIsFiveOrMore",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_past12MonthEmerRoom",
    [
      "medical_past12MonthEmerRoomNone",
      "medical_past12MonthEmerRoomOne",
      "medical_past12MonthEmerRoomTwo",
      "medical_past12MonthEmerRoomThree",
      "medical_past12MonthEmerRoomFour",
      "medical_past12MonthEmerRoomFiveOrMore",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_past12MonthHospOvernight",
    [
      "medical_past12MonthHospOvernightIsNone",
      "medical_past12MonthHospOvernightIsOne",
      "medical_past12MonthHospOvernightIsTwo",
      "medical_past12MonthHospOvernightIsThree",
      "medical_past12MonthHospOvernightIsFour",
      "medical_past12MonthHospOvernightIsFiveOrMore",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_past12MonthNursHome",
    [
      "medical_past12MonthNursHomeisNone",
      "medical_past12MonthNursHomeisOne",
      "medical_past12MonthNursHomeisTwo",
      "medical_past12MonthNursHomeisFour",
      "medical_past12MonthNursHomeisThree",
      "medical_past12MonthNursHomeisFiveOrMore",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_past12MonthHadSurgery",
    [
      "medical_past12MonthHadSurgeryisNone",
      "medical_past12MonthHadSurgeryisOne",
      "medical_past12MonthHadSurgeryisTwo",
      "medical_past12MonthHadSurgeryisThree",
      "medical_past12MonthHadSurgeryisFour",
      "medical_past12MonthHadSurgeryisFiveOrMore",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_assistNeedSubDevicesSubisOxygenSubDesc",
    [
      "medical_assistNeedSubDevicesSubisOxygenSubDescSubisPRN",
      "medical_assistNeedSubDevicesSubisOxygenSubDescSubisContinuous",
      "medical_assistNeedSubDevicesSubisOxygenSubDescSubisDay",
      "medical_assistNeedSubDevicesSubisOxygenSubDescSubisNight",
    ],
    ["2023", "2024"],
  ],
  [
    "medical_health",
    [
      "medical_healthSubPhysicalTherapist",
      "medical_healthSubOccupationalTherapist",
      "medical_healthSubDietician",
      "medical_healthSubSocialWorker",
      "medical_healthSubPharmacist",
      "medical_healthSubSpeechTherapist",
      "medical_healthSubChiropractor",
      "medical_healthSubPersonalCareWorker",
      "medical_healthSubMealsOnWheels",
      "medical_healthSubAdultDayCare",
      "medical_healthSubEyeExam",
      "medical_healthSubDentalExam",
      "medical_healthSubNone",
    ],
    ["2024"],
  ],
  [
    "medical_treatments",
    [
      "medical_treatmentsSubChemotherapy",
      "medical_treatmentsSubCatheterCare",
      "medical_treatmentsSubOxygen",
      "medical_treatmentsSubWoundCare",
      "medical_treatmentsSubRegularInjections",
      "medical_treatmentsSubTubeFeedings",
      "medical_treatmentsSubNone",
    ],
    ["2024"],
  ],
  // [
  //   "medical_past12MonthEmerRoomOneSubEduVisit",
  //   [
  //     "medical_past12MonthEmerRoomOneSubEduVisitSubYes",
  //     "medical_past12MonthEmerRoomOneSubEduVisitSubNo",
  //     "medical_past12MonthEmerRoomOneSubEduVisitSubUnknown",
  //   ],
  //   ["2024"],
  // ],
  // [
  //   "medical_past12MonthEmerRoomTwoSubEduVisit",
  //   [
  //     "medical_past12MonthEmerRoomTwoSubEduVisitSubYes",
  //     "medical_past12MonthEmerRoomTwoSubEduVisitSubNo",
  //     "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknown",
  //   ],
  //   ["2024"],
  // ],
  // [
  //   "medical_past12MonthEmerRoomThreeSubEduVisit",
  //   [
  //     "medical_past12MonthEmerRoomThreeSubEduVisitSubYes",
  //     "medical_past12MonthEmerRoomThreeSubEduVisitSubNo",
  //     "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknown",
  //   ],
  //   ["2024"],
  // ],
  // [
  //   "medical_past12MonthEmerRoomFourSubEduVisit",
  //   [
  //     "medical_past12MonthEmerRoomFourSubEduVisitSubYes",
  //     "medical_past12MonthEmerRoomFourSubEduVisitSubNo",
  //     "medical_past12MonthEmerRoomFourSubEduVisitSubUnknown",
  //   ],
  //   ["2024"],
  // ],
  // [
  //   "medical_past12MonthEmerRoomFiveOrMoreSubEduVisit",
  //   [
  //     "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubYes",
  //     "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNo",
  //     "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknown",
  //   ],
  //   ["2024"],
  // ],
];

export const getMedicalData = (apiData, year) => {
  let nestedData = {};
  let flattenData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = getObjectData(apiData, questionPair[1]);

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedData, pointerLabels[1], year) || "";
  });

  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided =
    miscData["medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided"] ===
    "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup =
    miscData["medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup"] ===
    "1"
      ? ["followUpEdVisit"]
      : [];
  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];

  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided =
    miscData["medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided"] ===
    "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup =
    miscData["medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup"] ===
    "1"
      ? ["followUpEdVisit"]
      : [];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];

  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];

  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup =
    miscData["medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup"] ===
    "1"
      ? ["followUpEdVisit"]
      : [];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];

  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];

  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup =
    miscData["medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup"] ===
    "1"
      ? ["followUpEdVisit"]
      : [];
  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];

  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];

  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided =
    miscData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided"
    ] === "1"
      ? ["educationProvided"]
      : [];

  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup =
    miscData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup"
    ] === "1"
      ? ["followUpEdVisit"]
      : [];

  flattenData = {
    ...flattenData,
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup,
  };

  return flattenData;
};

export const setMedicalData = (formData, year) => {
  let flattenData = {};
  let nestedData = {};
  let payloadData = {};
  let miscData = {};

  let flatmap = getFlatmapByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[3] || "1";
    const value = formData[quesUIVariable] || "";

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoDontKnow(value);
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvidedSelected =
    formData["medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided"];
  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided =
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowupSelected =
    formData["medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup"];
  const medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup =
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided"
    ];
  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided =
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowupSelected =
    formData["medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup"];
  const medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup =
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvidedSelected =
    formData["medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided"];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided =
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowupSelected =
    formData["medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup"];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup =
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided"
    ];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided =
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowupSelected =
    formData["medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup"];
  const medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup =
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvidedSelected =
    formData["medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided"];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided =
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowupSelected =
    formData["medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup"];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup =
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided"
    ];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided =
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowupSelected =
    formData[
      "medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup"
    ];
  const medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup =
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvidedSelected =
    formData["medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided"];
  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided =
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowupSelected =
    formData["medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup"];
  const medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup =
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided"
    ];
  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided =
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowupSelected =
    formData["medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup"];
  const medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup =
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided"
    ];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided =
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowupSelected =
    formData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup"
    ];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup =
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvidedSelected =
    formData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided"
    ];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided =
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvidedSelected?.includes(
      "educationProvided",
    )
      ? "1"
      : "";

  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowupSelected =
    formData[
      "medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup"
    ];
  const medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup =
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowupSelected?.includes(
      "followUpEdVisit",
    )
      ? "1"
      : "";

  flattenData = {
    ...flattenData,
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomOneSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomOneSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomTwoSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomTwoSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomThreeSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomThreeSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomFourSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomFourSubEduVisitSubUnknownSubFollowup,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubEduProvided,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubNoSubFollowup,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubEduProvided,
    medical_past12MonthEmerRoomFiveOrMoreSubEduVisitSubUnknownSubFollowup,
  };

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getFlatmapByYear = (year) => {
  let temp = [];
  medical_flatmap.forEach((questionPair) => {
    const quesYear = questionPair?.[2] || ["2023"];
    if (quesYear.includes(year)) {
      temp.push(questionPair);
    }
  });

  return temp;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  medical_optionsTreeMappers.forEach((mapper) => {
    const quesYear = mapper?.[1] || ["2023"];
    if (quesYear.includes(year)) {
      const quesMap = mapper?.[0];
      temp = { ...temp, ...quesMap };
    }
  });

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  medical_optionsTreeWrappers.forEach((wrapper) => {
    const quesYear = wrapper?.[2] || ["2023"];
    if (quesYear.includes(year)) {
      const [quesWrapHead, quesWrapElements] = wrapper;
      temp.push([quesWrapHead, quesWrapElements]);
    }
  });

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = getOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let temp = setOptionsSelected(tempData, keyArray, treeMappers);
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  let getSubTreeRegex = getSubTreeString(
    optionName,
    optionValue,
    treeWrappers,
    treeMappers,
  );
  return getSubTreeRegex;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let updatedData = deleteSubTreeData(formData, subTreeRegex);
  return updatedData;
};
