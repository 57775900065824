export const userCategoriesList = {
  clients: [
    ["VPHP", false],
    ["OHP", false],
    ["SWHR", false],
    ["ALLIANT", false],
    ["HA", false],
    ["SHP", false],
  ],

  ageGroup: [
    ["Above 18", false],
    ["Below 18", false],
  ],

  plan: [
    ["Alliant Health Plans", false],
    ["DEMO", false],
    ["Health Alliance", false],
    ["OPH-OPTIMA", false],
    ["SAM Health", false],
    ["SouthWestern Health", false],
    ["VPHP", false],
    ["VPHP 1", false],
  ],
  year: [
    ["2019", false],
    ["2020", false],
    ["2021", false],
    ["2022", false],
    ["2023", false],
  ],
  screeningTypes: [
    ["All", false],
    ["HRA", false],
    ["Screening", false],
  ],
};

export const languageOptions = {
  options: [
    "African Languages",
    "Arabic",
    "Chinese",
    "French",
    "French Creole",
    "English",
    "German",
    "Greek",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Italian",
    "Japanese",
    "Korean",
    "Persian",
    "Polish",
    "Portuguese",
    "Russian",
    "Scandinavian Languages",
    "Serbo-Croatian",
    "Spanish",
    "Tagalog",
    "Urdu",
    "Vietnamese",
    "Yiddish",
  ],
};

export const stateNames = {
  options: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
};
