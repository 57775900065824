import lodash from "lodash";

import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const activities_hashmap = [
  [
    "activities_gettingInOrOutOfBedNoVal",
    "difficultyWithActivities.gettingInOrOutOfBed.no",
  ],
  [
    "activities_gettingInOrOutOfBedNeedSomeHelpVal",
    "difficultyWithActivities.gettingInOrOutOfBed.needSomeHelp",
  ],
  [
    "activities_gettingInOrOutOfBedNeedTotalHelpVal",
    "difficultyWithActivities.gettingInOrOutOfBed.needTotalHelp",
  ],
  [
    "activities_gettingInOrOutOfBedCommentVal",
    "difficultyWithActivities.gettingInOrOutOfBed.comment",
  ],

  [
    "activities_gettingInOrOutOfChairsNoVal",
    "difficultyWithActivities.gettingInOrOutOfChairs.no",
  ],
  [
    "activities_gettingInOrOutOfChairsNeedSomeHelpVal",
    "difficultyWithActivities.gettingInOrOutOfChairs.needSomeHelp",
  ],
  [
    "activities_gettingInOrOutOfChairsTotalHelpVal",
    "difficultyWithActivities.gettingInOrOutOfChairs.needTotalHelp",
  ],
  [
    "activities_gettingInOrOutOfChairsCommentVal",
    "difficultyWithActivities.gettingInOrOutOfChairs.comment",
  ],

  ["activities_toiletingNoVal", "difficultyWithActivities.toileting.no"],
  [
    "activities_toiletingNeedSomeHelpVal",
    "difficultyWithActivities.toileting.needSomeHelp",
  ],
  [
    "activities_toiletingNeedTotalHelpVal",
    "difficultyWithActivities.toileting.needTotalHelp",
  ],
  [
    "activities_toiletingCommentVal",
    "difficultyWithActivities.toileting.comment",
  ],

  ["activities_bathingNoVal", "difficultyWithActivities.bathing.no"],
  [
    "activities_bathingNeedSomeHelpVal",
    "difficultyWithActivities.bathing.needSomeHelp",
  ],
  [
    "activities_bathingNeedTotalHelpVal",
    "difficultyWithActivities.bathing.needTotalHelp",
  ],
  ["activities_bathingCommentVal", "difficultyWithActivities.bathing.comment"],

  ["activities_dressingNoVal", "difficultyWithActivities.dressing.no"],
  [
    "activities_dressingNeedSomeHelpVal",
    "difficultyWithActivities.dressing.needSomeHelp",
  ],
  [
    "activities_dressingNeedTotalHelpVal",
    "difficultyWithActivities.dressing.needTotalHelp",
  ],
  [
    "activities_dressingCommentVal",
    "difficultyWithActivities.dressing.comment",
  ],

  ["activities_eatingNoVal", "difficultyWithActivities.eating.no"],
  [
    "activities_eatingNeedSomeHelpVal",
    "difficultyWithActivities.eating.needSomeHelp",
  ],
  [
    "activities_eatingNeedTotalHelpVal",
    "difficultyWithActivities.eating.needTotalHelp",
  ],
  ["activities_eatingCommentVal", "difficultyWithActivities.eating.comment"],

  ["activities_walkingNoVal", "difficultyWithActivities.walking.no"],
  [
    "activities_walkingSubNeedSomeHelpVal",
    "difficultyWithActivities.walking.needSomeHelp",
  ],
  [
    "activities_walkingSubNeededTotalHelpVal",
    "difficultyWithActivities.walking.neededTotalHelp",
  ],
  ["activities_walkingCommentVal", "difficultyWithActivities.walking.comment"],

  [
    "activities_walkingSubNeedSomeHelpSubAnswersHouseholdOnlyVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.householdOnly",
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswersLessThanOneBlockVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.lessThanOneBlock",
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswersOneBlockVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.oneBlock",
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswersTwoOrMoreVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.twoOrMore",
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswersNonAmbulatoryVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.nonAmbulatory",
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswersCommentVal",
    "difficultyWithActivities.walking.needSomeHelpSubAnswers.comment",
  ],

  [
    "activities_walkingSubNeededTotalHelpSubAnswersHouseholdOnlyVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.householdOnly",
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswersLessThanOneBlockVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.lessThanOneBlock",
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswersOneBlockVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.oneBlock",
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswersTwoOrMoreVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.twoOrMore",
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswersNonAmbulatoryVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.nonAmbulatory",
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswersCommentVal",
    "difficultyWithActivities.walking.neededTotalHelpSubAnswer.comment",
  ],

  [
    "activities_goingUpOrDownStairsNoVal",
    "difficultyWithActivities.goingUpOrDownStairs.no",
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelp",
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelp",
  ],
  [
    "activities_goingUpOrDownStairsCommentVal",
    "difficultyWithActivities.goingUpOrDownStairs.comment",
  ],

  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersNoneVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelpSubAnswers.none",
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersThreeToFiveVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelpSubAnswers.threeToFive",
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersSixToTenVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelpSubAnswers.sixToTen",
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersMoreThanTenVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelpSubAnswers.moreThanTen",
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersCommentVal",
    "difficultyWithActivities.goingUpOrDownStairs.needSomeHelpSubAnswers.comment",
  ],

  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersNoneVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelpSubAnswer.none",
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersThreeToFiveVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelpSubAnswer.threeToFive",
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersSixToTenVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelpSubAnswer.sixToTen",
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersMoreThanTenVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelpSubAnswer.moreThanTen",
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersCommentVal",
    "difficultyWithActivities.goingUpOrDownStairs.neededTotalHelpSubAnswer.comment",
  ],
  [
    "activities_activitiesTableComment",
    "difficultyWithActivities.totalScoreComment",
  ],
  ["activities_evidencedBasedComment", "difficultyWithActivities.comment"],
];

const activities_optionsTreeMapObject = {
  activities_gettingInOrOutOfBedNoVal: "no",
  activities_gettingInOrOutOfBedNeedSomeHelpVal: "needSomeHelp",
  activities_gettingInOrOutOfBedNeedTotalHelpVal: "needTotalHelp",

  activities_gettingInOrOutOfChairsNoVal: "no",
  activities_gettingInOrOutOfChairsNeedSomeHelpVal: "needSomeHelp",
  activities_gettingInOrOutOfChairsTotalHelpVal: "needTotalHelp",

  activities_toiletingNoVal: "no",
  activities_toiletingNeedSomeHelpVal: "needSomeHelp",
  activities_toiletingNeedTotalHelpVal: "needTotalHelp",

  activities_bathingNoVal: "no",
  activities_bathingNeedSomeHelpVal: "needSomeHelp",
  activities_bathingNeedTotalHelpVal: "needTotalHelp",

  activities_dressingNoVal: "no",
  activities_dressingNeedSomeHelpVal: "needSomeHelp",
  activities_dressingNeedTotalHelpVal: "needTotalHelp",

  activities_eatingNoVal: "no",
  activities_eatingNeedSomeHelpVal: "needSomeHelp",
  activities_eatingNeedTotalHelpVal: "needTotalHelp",

  activities_walkingNoVal: "no",
  activities_walkingSubNeedSomeHelpVal: "needSomeHelp",
  activities_walkingSubNeededTotalHelpVal: "neededTotalHelp",

  activities_walkingSubNeedSomeHelpSubAnswersHouseholdOnlyVal: "householdOnly",
  activities_walkingSubNeedSomeHelpSubAnswersLessThanOneBlockVal:
    "lessThanOneBlock",
  activities_walkingSubNeedSomeHelpSubAnswersOneBlockVal: "oneBlock",
  activities_walkingSubNeedSomeHelpSubAnswersTwoOrMoreVal: "twoOrMore",
  activities_walkingSubNeedSomeHelpSubAnswersNonAmbulatoryVal: "nonAmbulatory",

  activities_walkingSubNeededTotalHelpSubAnswersHouseholdOnlyVal:
    "householdOnly",
  activities_walkingSubNeededTotalHelpSubAnswersLessThanOneBlockVal:
    "lessThanOneBlock",
  activities_walkingSubNeededTotalHelpSubAnswersOneBlockVal: "oneBlock",
  activities_walkingSubNeededTotalHelpSubAnswersTwoOrMoreVal: "twoOrMore",
  activities_walkingSubNeededTotalHelpSubAnswersNonAmbulatoryVal:
    "nonAmbulatory",

  activities_goingUpOrDownStairsNoVal: "no",
  activities_goingUpOrDownStairsSubNeedSomeHelpVal: "needSomeHelp",
  activities_goingUpOrDownStairsSubNeededTotalHelpVal: "neededTotalHelp",

  activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersNoneVal: "none",
  activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersThreeToFiveVal:
    "threeToFive",
  activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersSixToTenVal:
    "sixToTen",
  activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersMoreThanTenVal:
    "moreThanTen",

  activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersNoneVal: "none",
  activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersThreeToFiveVal:
    "threeToFive",
  activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersSixToTenVal:
    "sixToTen",
  activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersMoreThanTenVal:
    "moreThanTen",
};

const activitiesOptionsTreeWrappers = [
  [
    "activities_gettingInOrOutOfBed",
    [
      "activities_gettingInOrOutOfBedNoVal",
      "activities_gettingInOrOutOfBedNeedSomeHelpVal",
      "activities_gettingInOrOutOfBedNeedTotalHelpVal",
    ],
  ],
  [
    "activities_gettingInOrOutOfChairs",
    [
      "activities_gettingInOrOutOfChairsNoVal",
      "activities_gettingInOrOutOfChairsNeedSomeHelpVal",
      "activities_gettingInOrOutOfChairsTotalHelpVal",
    ],
  ],
  [
    "activities_toileting",
    [
      "activities_toiletingNoVal",
      "activities_toiletingNeedSomeHelpVal",
      "activities_toiletingNeedTotalHelpVal",
    ],
  ],
  [
    "activities_bathing",
    [
      "activities_bathingNoVal",
      "activities_bathingNeedSomeHelpVal",
      "activities_bathingNeedTotalHelpVal",
    ],
  ],
  [
    "activities_dressing",
    [
      "activities_dressingNoVal",
      "activities_dressingNeedSomeHelpVal",
      "activities_dressingNeedTotalHelpVal",
    ],
  ],
  [
    "activities_eating",
    [
      "activities_eatingNoVal",
      "activities_eatingNeedSomeHelpVal",
      "activities_eatingNeedTotalHelpVal",
    ],
  ],
  [
    "activities_walking",
    [
      "activities_walkingNoVal",
      "activities_walkingSubNeedSomeHelpVal",
      "activities_walkingSubNeededTotalHelpVal",
    ],
  ],
  [
    "activities_walkingSubNeedSomeHelpSubAnswers",
    [
      "activities_walkingSubNeedSomeHelpSubAnswersHouseholdOnlyVal",
      "activities_walkingSubNeedSomeHelpSubAnswersLessThanOneBlockVal",
      "activities_walkingSubNeedSomeHelpSubAnswersOneBlockVal",
      "activities_walkingSubNeedSomeHelpSubAnswersTwoOrMoreVal",
      "activities_walkingSubNeedSomeHelpSubAnswersNonAmbulatoryVal",
    ],
  ],
  [
    "activities_walkingSubNeededTotalHelpSubAnswers",
    [
      "activities_walkingSubNeededTotalHelpSubAnswersHouseholdOnlyVal",
      "activities_walkingSubNeededTotalHelpSubAnswersLessThanOneBlockVal",
      "activities_walkingSubNeededTotalHelpSubAnswersOneBlockVal",
      "activities_walkingSubNeededTotalHelpSubAnswersTwoOrMoreVal",
      "activities_walkingSubNeededTotalHelpSubAnswersNonAmbulatoryVal",
    ],
  ],
  [
    "activities_goingUpOrDownStairs",
    [
      "activities_goingUpOrDownStairsNoVal",
      "activities_goingUpOrDownStairsSubNeedSomeHelpVal",
      "activities_goingUpOrDownStairsSubNeededTotalHelpVal",
    ],
  ],
  [
    "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswers",
    [
      "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersNoneVal",
      "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersThreeToFiveVal",
      "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersSixToTenVal",
      "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersMoreThanTenVal",
    ],
  ],
  [
    "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswers",
    [
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersNoneVal",
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersThreeToFiveVal",
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersSixToTenVal",
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersMoreThanTenVal",
    ],
  ],
];

export const getActivitiesData = (apiData) => {
  let tempDbData = {};
  let tempData = {};

  activities_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  activitiesOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData[pointerLabels[0]] = getWrapper(tempDbData, pointerLabels[1]) || "";
  });

  const activities_gettingInOrOutOfBedComment =
    tempDbData["activities_gettingInOrOutOfBedCommentVal"];

  const activities_gettingInOrOutOfChairsComment =
    tempDbData["activities_gettingInOrOutOfChairsCommentVal"];

  const activities_toiletingComment =
    tempDbData["activities_toiletingCommentVal"];

  const activities_bathingComment = tempDbData["activities_bathingCommentVal"];

  const activities_dressingComment =
    tempDbData["activities_dressingCommentVal"];

  const activities_eatingComment = tempDbData["activities_eatingCommentVal"];

  const activities_walkingComment = tempDbData["activities_walkingCommentVal"];

  const activities_walkNeedSomeHelpSubAnswersComment =
    tempDbData["activities_walkNeedSomeHelpSubAnswersCommentVal"];

  const activities_walkingSubNeedSomeHelpSubAnswersComment =
    tempDbData["activities_walkingSubNeedSomeHelpSubAnswersCommentVal"];

  const activities_walkingSubNeededTotalHelpSubAnswersComment =
    tempDbData["activities_walkingSubNeededTotalHelpSubAnswersCommentVal"];

  const activities_walkNeededTotalHelpSubAnswersComment =
    tempDbData["activities_walkNeededTotalHelpSubAnswersCommentVal"];

  const activities_goingUpOrDownStairsComment =
    tempDbData["activities_goingUpOrDownStairsCommentVal"];

  const activities_stairsNeedSomeHelpSubAnswersComment =
    tempDbData["activities_stairsNeedSomeHelpSubAnswersCommentVal"];

  const activities_stairsNeededTotalHelpSubAnswersComment =
    tempDbData["activities_stairsNeededTotalHelpSubAnswersCommentVal"];

  const activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersComment =
    tempDbData[
      "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersCommentVal"
    ];

  const activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersComment =
    tempDbData[
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersCommentVal"
    ];
  const activities_activitiesTableComment =
    tempDbData["activities_activitiesTableComment"];
  const activities_evidencedBasedComment =
    tempDbData["activities_evidencedBasedComment"];

  tempDbData = {
    ...tempData,
    activities_gettingInOrOutOfBedComment:
      activities_gettingInOrOutOfBedComment || "",
    activities_gettingInOrOutOfChairsComment:
      activities_gettingInOrOutOfChairsComment || "",
    activities_toiletingComment: activities_toiletingComment || "",
    activities_bathingComment: activities_bathingComment || "",
    activities_dressingComment: activities_dressingComment || "",

    activities_eatingComment: activities_eatingComment || "",
    activities_walkingComment: activities_walkingComment || "",
    activities_walkNeedSomeHelpSubAnswersComment:
      activities_walkNeedSomeHelpSubAnswersComment || "",
    activities_walkingSubNeedSomeHelpSubAnswersComment:
      activities_walkingSubNeedSomeHelpSubAnswersComment || "",
    activities_walkingSubNeededTotalHelpSubAnswersComment:
      activities_walkingSubNeededTotalHelpSubAnswersComment || "",
    activities_walkNeededTotalHelpSubAnswersComment:
      activities_walkNeededTotalHelpSubAnswersComment || "",
    activities_goingUpOrDownStairsComment:
      activities_goingUpOrDownStairsComment || "",

    activities_stairsNeedSomeHelpSubAnswersComment:
      activities_stairsNeedSomeHelpSubAnswersComment || "",
    activities_stairsNeededTotalHelpSubAnswersComment:
      activities_stairsNeededTotalHelpSubAnswersComment || "",
    activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersComment:
      activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersComment || "",
    activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersComment:
      activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersComment || "",
    activities_activitiesTableComment: activities_activitiesTableComment || "",
    activities_evidencedBasedComment: activities_evidencedBasedComment || "",
  };

  return tempDbData;
};

export const setActivitiesData = (formData) => {
  let tempData = {};

  activitiesOptionsTreeWrappers.forEach((pointerLabels) => {
    tempData = {
      ...tempData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1]),
    };
  });

  let payloadData = {};

  const activities_gettingInOrOutOfBedCommentVal =
    formData["activities_gettingInOrOutOfBedComment"];

  const activities_gettingInOrOutOfChairsCommentVal =
    formData["activities_gettingInOrOutOfChairsComment"];

  const activities_toiletingCommentVal =
    formData["activities_toiletingComment"];

  const activities_bathingCommentVal = formData["activities_bathingComment"];

  const activities_dressingCommentVal = formData["activities_dressingComment"];

  const activities_eatingCommentVal = formData["activities_eatingComment"];

  const activities_walkingCommentVal = formData["activities_walkingComment"];
  const activities_walkNeedSomeHelpSubAnswersCommentVal =
    formData["activities_walkNeedSomeHelpSubAnswersComment"];
  const activities_walkNeededTotalHelpSubAnswersCommentVal =
    formData["activities_walkNeededTotalHelpSubAnswersComment"];
  const activities_goingUpOrDownStairsCommentVal =
    formData["activities_goingUpOrDownStairsComment"];
  const activities_stairsNeedSomeHelpSubAnswersCommentVal =
    formData["activities_stairsNeedSomeHelpSubAnswersComment"];
  const activities_stairsNeededTotalHelpSubAnswersCommentVal =
    formData["activities_stairsNeededTotalHelpSubAnswersComment"];

  const activities_walkingSubNeedSomeHelpSubAnswersCommentVal =
    formData["activities_walkingSubNeedSomeHelpSubAnswersComment"];

  const activities_walkingSubNeededTotalHelpSubAnswersCommentVal =
    formData["activities_walkingSubNeededTotalHelpSubAnswersComment"];

  const activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersCommentVal =
    formData["activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersComment"];

  const activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersCommentVal =
    formData[
      "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersComment"
    ];

  const activities_activitiesTableComment =
    formData["activities_activitiesTableComment"];
  const activities_evidencedBasedComment =
    formData["activities_evidencedBasedComment"];

  tempData = {
    ...tempData,
    activities_gettingInOrOutOfBedCommentVal,
    activities_gettingInOrOutOfChairsCommentVal,
    activities_toiletingCommentVal,
    activities_bathingCommentVal,
    activities_dressingCommentVal,
    activities_eatingCommentVal,
    activities_walkingCommentVal,
    activities_walkNeedSomeHelpSubAnswersCommentVal,
    activities_walkNeededTotalHelpSubAnswersCommentVal,
    activities_goingUpOrDownStairsCommentVal,
    activities_stairsNeedSomeHelpSubAnswersCommentVal,
    activities_stairsNeededTotalHelpSubAnswersCommentVal,
    activities_walkingSubNeedSomeHelpSubAnswersCommentVal,
    activities_walkingSubNeededTotalHelpSubAnswersCommentVal,
    activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswersCommentVal,
    activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswersCommentVal,
    activities_activitiesTableComment,
    activities_evidencedBasedComment,
  };

  activities_hashmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getWrapper = (tempData, keyArray) => {
  let temp = getOptionsSelected(tempData, keyArray);
  return temp;
};

const setWrapper = (tempData, keyArray) => {
  let temp = setOptionsSelected(tempData, keyArray);
  return temp;
};

const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = "yes";
  } else if (optionValue === "2") {
    selectedValue = "no";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelected = (optionObject, optionArray, multiSelect = false) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return activities_optionsTreeMapObject[variable];
    });
  } else {
    selectedOption = activities_optionsTreeMapObject[selectedVariable[0]];
  }
  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  multiSelect = false,
) => {
  let temp = {};

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(
        activities_optionsTreeMapObject[option],
      )
        ? "1"
        : "";
    });
  } else {
    optionArray.forEach((option) => {
      temp[option] =
        activities_optionsTreeMapObject[option] === optionsSelected ? "1" : "";
    });
  }
  return temp;
};

export const getSubTree = (optionName, optionValue) => {
  let temp;
  const tempIndex = lodash.findIndex(
    activitiesOptionsTreeWrappers,
    function (optTree) {
      return optTree[0] === optionName;
    },
  );

  const tempTreeNodes = activitiesOptionsTreeWrappers?.[tempIndex]
    ? activitiesOptionsTreeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (activities_optionsTreeMapObject[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";
  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};
