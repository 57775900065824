import {
  deleteSubTreeData,
  getObjectData,
  // getOptionsSelected,
  getOptionsSelectedYesNo,
  getSubTreeString,
  setObjectData,
  // setOptionsSelected,
  setOptionsSelectedYesNo,
} from "../../../../../../utils/helpers.DynamicForm";
import lodash, { flatten } from "lodash";
/*

============= FLATMAP =============

for get/set the nested level DB-JSON data back & forth using lodash
It's an array of arrays. Inner array has 3 values -> [ flatmap_value,db_json_path,year_list_array ]
<- example ->
[ home_fallenNoneVal, fallen.none, [2023,2024] ]

======= OPTIONS TREE MAPPER =======

for get/set the options tree selected value by comparing with formdata json
It's an object where key-value pair will be flatmap value-value to be assigned in options tree if it is "1"
it must match your options tree's corresponding toggle button's value field
<- example ->
{ home_fallenNoneVal:none }

======= OPTIONS TREE WRAPPER =======

for populate the selected value(s) of an options tree by combining list of flatmap values.
It's an array of arrays. Inner array has 2 elements -> [grouped_string,[list_of_flattened_values_to_be_grouped]]
this grouped string must be assigned to your options tree's name field.

<- example ->
[
  "home_fallen",
  [
    "home_fallenNoneVal",
    "home_fallenOnceVal",
    "home_fallenTwiceVal",
    "home_fallenThreeVal",
    "home_fallenMoreThreeVal",
  ],
],

*/

const homeSafety_flatmap = [
  ["home_fallenNoneVal", "fallen.none", ["2023", "2024"]],
  ["home_fallenOnceVal", "fallen.once", ["2023", "2024"]],
  [
    "home_fallenOnceSubWryFallVal",
    "fallen.onceSubAnswers.worryAboutFalling.isWorryAboutFalling",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubWryFallCommentVal",
    "fallen.onceSubAnswers.worryAboutFalling.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubWrisFallVal",
    "fallen.onceSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
    ["2023"],
  ],
  [
    "home_fallenOnceSubWrisFallCommentVal",
    "fallen.onceSubAnswers.worriesAboutFalling.comment",
    ["2023"],
  ],
  [
    "home_fallenOnceSubFracVal",
    "fallen.onceSubAnswers.fracture.isFracture",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubFracCommentVal",
    "fallen.onceSubAnswers.fracture.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubFracSubByFallVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubFracSubByFallCommentVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubFracSubOstMedVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
    ["2023", "2024"],
  ],
  [
    "home_fallenOnceSubFracSubOstMedCommentVal",
    "fallen.onceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
    ["2023", "2024"],
  ],
  ["home_fallenTwiceVal", "fallen.twice", ["2023", "2024"]],
  [
    "home_fallenTwiceSubWryFallVal",
    "fallen.twiceSubAnswers.worryAboutFalling.isWorryAboutFalling",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubWryFallCommentVal",
    "fallen.twiceSubAnswers.worryAboutFalling.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubWrisFallVal",
    "fallen.twiceSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
    ["2023"],
  ],
  [
    "home_fallenTwiceSubWrisFallCommentVal",
    "fallen.twiceSubAnswers.worriesAboutFalling.comment",
    ["2023"],
  ],
  [
    "home_fallenTwiceSubFracVal",
    "fallen.twiceSubAnswers.fracture.isFracture",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubFracCommentVal",
    "fallen.twiceSubAnswers.fracture.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubFracSubByFallVal",
    "fallen.twiceSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubFracSubByFallCommentVal",
    "fallen.twiceSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubFracSubOstMedVal",
    "fallen.twiceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
    ["2023", "2024"],
  ],
  [
    "home_fallenTwiceSubFracSubOstMedCommentVal",
    "fallen.twiceSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
    ["2023", "2024"],
  ],
  ["home_fallenThreeVal", "fallen.threeTimes", ["2023", "2024"]],
  [
    "home_fallenThreeSubWryFallVal",
    "fallen.threeTimesSubAnswers.worryAboutFalling.isWorryAboutFalling",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubWryFallCommentVal",
    "fallen.threeTimesSubAnswers.worryAboutFalling.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubWrisFallVal",
    "fallen.threeTimesSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
    ["2023"],
  ],
  [
    "home_fallenThreeSubWrisFallCommentVal",
    "fallen.threeTimesSubAnswers.worriesAboutFalling.comment",
    ["2023"],
  ],
  [
    "home_fallenThreeSubFracVal",
    "fallen.threeTimesSubAnswers.fracture.isFracture",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubFracCommentVal",
    "fallen.threeTimesSubAnswers.fracture.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubFracSubByFallVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubFracSubByFallCommentVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubFracSubOstMedVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
    ["2023", "2024"],
  ],
  [
    "home_fallenThreeSubFracSubOstMedCommentVal",
    "fallen.threeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
    ["2023", "2024"],
  ],
  ["home_fallenMoreThreeVal", "fallen.MoreThanthreeTimes", ["2023", "2024"]],
  [
    "home_fallenMoreThreeSubWryFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.worryAboutFalling.isWorryAboutFalling",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubWryFallCommentVal",
    "fallen.MoreThanthreeTimesSubAnswers.worryAboutFalling.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubWrisFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.worriesAboutFalling.isWorriesAboutFalling",
    ["2023"],
  ],
  [
    "home_fallenMoreThreeSubWrisFallCommentVal",
    "fallen.MoreThanthreeTimesSubAnswers.worriesAboutFalling.comment",
    ["2023"],
  ],
  [
    "home_fallenMoreThreeSubFracVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFracture",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubFracCommentVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubFracSubByFallVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.isDueToFall",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubFracSubByFallCommentVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.dueToFall.comment",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubFracSubOstMedVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.isOsteoporosisMed",
    ["2023", "2024"],
  ],
  [
    "home_fallenMoreThreeSubFracSubOstMedCommentVal",
    "fallen.MoreThanthreeTimesSubAnswers.fracture.isFractureSubAnswers.osteoporosisMed.comment",
    ["2023", "2024"],
  ],
  ["home_fallenCommentVal", "fallen.comment", ["2023", "2024"]],
  ["home_timedUpGoNotApplicable", "tug.notApplicable.notApplicable", ["2024"]],
  ["home_timedUpGoNotApplicableComment", "tug.notApplicable.comment", ["2024"]],
  ["home_timeInSeconds", "directions.timeInSeconds", ["2024"]],

  // ["home_memberGaitNormal", "memberGait.normal", ["2024"]],
  // ["home_memberGaitAbnormal", "memberGait.abnormal", ["2024"]],
  // [
  //   "home_memberGaitAbnormalSubDesc",
  //   "memberGait.abnormalSubAnswers.describe",
  //   ["2024"],
  // ],

  // ["home_memberGaitComment", "memberGait.comment", ["2024"]],

  ["home_fallRisk", "fallRisk.fallRisk", ["2024"]],
  ["home_fallRiskComment", "fallRisk.comment", ["2024"]],

  ["home_planFurther", "furtherEducation.furtherEducation", ["2024"]],
  ["home_planFurtherComment", "furtherEducation.comment", ["2024"]],

  [
    "home_obstInHouseVal",
    "homeSafety.obstaclesInHouse.isObstaclesInHouse",
    ["2023", "2024"],
  ],
  [
    "home_obstInHouseCommentVal",
    "homeSafety.obstaclesInHouse.comment",
    ["2023", "2024"],
  ],
  [
    "home_elecCordsVal",
    "homeSafety.electricalCords.isElectricalCords",
    ["2023", "2024"],
  ],
  [
    "home_elecCordsCommentVal",
    "homeSafety.electricalCords.comment",
    ["2023", "2024"],
  ],
  ["home_noMatsVal", "homeSafety.noSlipMats.isNoSlipMats", ["2023", "2024"]],
  ["home_noMatsCommentVal", "homeSafety.noSlipMats.comment", ["2023", "2024"]],
  [
    "home_adeLightVal",
    "homeSafety.adequateLighting.isAdequateLighting",
    ["2023", "2024"],
  ],
  [
    "home_adeLightCommentVal",
    "homeSafety.adequateLighting.comment",
    ["2023", "2024"],
  ],
  [
    "home_handRailVal",
    "homeSafety.handrailsOnStaircases.isHandrailsOnStaircases",
    ["2023", "2024"],
  ],
  [
    "home_handRailCommentVal",
    "homeSafety.handrailsOnStaircases.comment",
    ["2023", "2024"],
  ],
  [
    "home_hotWaterVal",
    "homeSafety.hotWaterHeater.isHotWaterHeater",
    ["2023", "2024"],
  ],
  [
    "home_hotWaterCommentVal",
    "homeSafety.hotWaterHeater.comment",
    ["2023", "2024"],
  ],
  [
    "home_smokeDetVal",
    "homeSafety.smokeDetectors.isSmokeDetectors",
    ["2023", "2024"],
  ],
  [
    "home_smokeDetCommentVal",
    "homeSafety.smokeDetectors.comment",
    ["2023", "2024"],
  ],
  [
    "home_co2DetVal",
    "homeSafety.carbonMonoxideDetectors.isCarbonMonoxideDetectors",
    ["2023", "2024"],
  ],
  [
    "home_co2DetCommentVal",
    "homeSafety.carbonMonoxideDetectors.comment",
    ["2023", "2024"],
  ],
  [
    "home_escRouteVal",
    "homeSafety.establishedEscapeRoute.isEstablishedEscapeRoute",
    ["2023", "2024"],
  ],
  [
    "home_escRouteCommentVal",
    "homeSafety.establishedEscapeRoute.comment",
    ["2023", "2024"],
  ],
  [
    "home_eventOfEmergency",
    "homeSafety.eventOfEmergency.isEventOfEmergency",
    ["2024"],
  ],
  [
    "home_eventOfEmergencyComment",
    "homeSafety.eventOfEmergency.comment",
    ["2024"],
  ],
  [
    "home_memberEducated",
    "memberEducatedonHomeSafety.memberEducatedonHomeSafety",
    ["2024"],
  ],
  [
    "home_memberEducatedComment",
    "memberEducatedonHomeSafety.comment",
    ["2024"],
  ],

  [
    "home_improveLifeQuality",
    "improveQualityOfLife.improveQualityOfLife",
    ["2024"],
  ],

  ["home_improveLifeQualityComment", "improveQualityOfLife.comment", ["2024"]],

  [
    "home_improveLifeQualitySelect",
    "workOnItDropdown.workOnItDropdown",
    ["2024"],
  ],

  [
    "home_improveLifeQualitySelectComment",
    "workOnItDropdown.comment",
    ["2024"],
  ],
  ["home_preventingComment", "preventFromMakingChanges.comment", ["2024"]],
  [
    "home_preventingeducationNeeded",
    "preventFromMakingChanges.educationNeeded",
    ["2024"],
  ],

  [
    "home_preventingsocialSupport",
    "preventFromMakingChanges.socialSupport",
    ["2024"],
  ],
  [
    "home_preventingenvironmentalFactors",
    "preventFromMakingChanges.environmentalFactors",
    ["2024"],
  ],
  ["home_preventingstress", "preventFromMakingChanges.stress", ["2024"]],
  [
    "home_preventingselfConfidence",
    "preventFromMakingChanges.selfConfidence",
    ["2024"],
  ],
  ["home_preventingother", "preventFromMakingChanges.other", ["2024"]],
  ["home_preventingnone", "preventFromMakingChanges.none", ["2024"]],
  [
    "home_preventinglackOfMotivation",
    "preventFromMakingChanges.lackOfMotivation",
    ["2024"],
  ],
  [
    "home_preventingnotAPriority",
    "preventFromMakingChanges.notAPriority",
    ["2024"],
  ],
  [
    "home_preventingmemberNonVerbal",
    "preventFromMakingChanges.memberNonVerbal",
    ["2024"],
  ],
  [
    "home_preventingmemberRefused",
    "preventFromMakingChanges.memberRefused",
    ["2024"],
  ],

  [
    "home_wishToChangeVal",
    "wishTochangeOrimprove.wishTochangeOrimprove",
    ["2023"],
  ],
  ["home_wishToChangeCommentVal", "wishTochangeOrimprove.comment", ["2023"]],
  ["home_qualityOfLifeVal", "qualityOfLife.qualityOfLife", ["2023"]],
  ["home_qualityOfLifeCommentVal", "qualityOfLife.comment", ["2023"]],
  [
    "home_abusedBySomeoneVal",
    "abusedBySomeone.isAbusedBySomeone",
    ["2023", "2024"],
  ],
  [
    "home_abusedBySomeoneCommentVal",
    "abusedBySomeone.comment",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingVal",
    "feelingLikeHarming.isFeelingLikeHarming",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingSubAnsWhoVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.whoDoYouFeel",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingSubAnsAtVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.atThisMoment",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingSubAnsSafeVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.safePlace",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingSubAnsAssistVal",
    "feelingLikeHarming.isFeelingLikeHarmingSubAnswers.assistYou",
    ["2023", "2024"],
  ],
  [
    "home_feelingLikeHarmingCommentVal",
    "feelingLikeHarming.comment",
    ["2023", "2024"],
  ],
  [
    "home_afraidOfAnyOneVal",
    "afraidOfAnyone.isAfraidOfAnyone",
    ["2023", "2024"],
  ],
  [
    "home_afraidOfAnyOneSubAnsWhoVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.whoAreYouAfraidOf",
    ["2023", "2024"],
  ],
  [
    "home_afraidOfAnyOneSubAnsAtVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.whoIsHurtingYou",
    ["2023", "2024"],
  ],
  [
    "home_afraidOfAnyOneSubAnsSafeVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.safePlace",
    ["2023", "2024"],
  ],
  [
    "home_afraidOfAnyOneSubAnsAssistVal",
    "afraidOfAnyone.isAfraidOfAnyoneSubAnswers.assistYou",
    ["2023", "2024"],
  ],
  ["home_afraidOfAnyOneCommentVal", "afraidOfAnyone.comment", ["2023", "2024"]],
  ["home_directionsComment", "directions.comment", ["2024"]],
];

const homeSafety_optionsTreeMappers2023 = {
  home_fallenNoneVal: "none",
  home_fallenOnceVal: "once",
  home_fallenTwiceVal: "twice",
  home_fallenThreeVal: "threeTimes",
  home_fallenMoreThreeVal: "moreThanThreeTimes",
};

const homeSafety_optionsTreeWrappers2023 = [
  [
    "home_fallen",
    [
      "home_fallenNoneVal",
      "home_fallenOnceVal",
      "home_fallenTwiceVal",
      "home_fallenThreeVal",
      "home_fallenMoreThreeVal",
    ],
  ],
];

const homeSafety_optionsTreeMappers2024 = {
  home_fallenNoneVal: "none",
  home_fallenOnceVal: "once",
  home_fallenTwiceVal: "twice",
  home_fallenThreeVal: "threeTimes",
  home_fallenMoreThreeVal: "moreThanThreeTimes",
  // home_memberGaitNormal: "normal",
  // home_memberGaitAbnormal: "abnormal",
  home_preventingeducationNeeded: "educationNeeded",
  home_preventingsocialSupport: "socialSupport",
  home_preventingenvironmentalFactors: "environmentalFactors",
  home_preventingstress: "stress",
  home_preventingselfConfidence: "selfConfidence",
  home_preventingother: "other",
  home_preventingnone: "none",
  home_preventinglackOfMotivation: "lackOfMotivation",
  home_preventingnotAPriority: "notPriority",
  home_preventingmemberNonVerbal: "memberNonverbal",
  home_preventingmemberRefused: "memberRefused",
};

const homeSafety_optionsTreeWrappers2024 = [
  [
    "home_fallen",
    [
      "home_fallenNoneVal",
      "home_fallenOnceVal",
      "home_fallenTwiceVal",
      "home_fallenThreeVal",
      "home_fallenMoreThreeVal",
    ],
  ],

  // ["home_memberGait", ["home_memberGaitNormal", "home_memberGaitAbnormal"]],

  [
    "home_preventing",
    [
      "home_preventingeducationNeeded",
      "home_preventingsocialSupport",
      "home_preventingenvironmentalFactors",
      "home_preventingstress",
      "home_preventingselfConfidence",
      "home_preventingother",
      "home_preventingnone",
      "home_preventinglackOfMotivation",
      "home_preventingnotAPriority",
      "home_preventingmemberNonVerbal",
      "home_preventingmemberRefused",
    ],
  ],
];

export const getHomeSafetyData = (apiData, year) => {
  let nestedData = {};
  let flattenData = {};
  let flatmap = [];

  homeSafety_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });
  flatmap.forEach((questionPair) => {
    nestedData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });
  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedData, pointerLabels[1], year) || "";
  });

  const home_fallenComment = nestedData["home_fallenCommentVal"];
  const home_fallenOnceSubWryFall = getOptionsSelectedYesNo(
    nestedData["home_fallenOnceSubWryFallVal"],
  );
  const home_fallenOnceSubWryFallComment =
    nestedData["home_fallenOnceSubWryFallCommentVal"];
  const home_fallenOnceSubWrisFall = getOptionsSelectedYesNo(
    nestedData["home_fallenOnceSubWrisFallVal"],
  );
  const home_fallenOnceSubWrisFallComment =
    nestedData["home_fallenOnceSubWrisFallCommentVal"];
  const home_fallenOnceSubFrac = getOptionsSelectedYesNo(
    nestedData["home_fallenOnceSubFracVal"],
  );
  const home_fallenOnceSubFracComment =
    nestedData["home_fallenOnceSubFracCommentVal"];
  const home_fallenOnceSubFracSubByFall = getOptionsSelectedYesNo(
    nestedData["home_fallenOnceSubFracSubByFallVal"],
  );
  const home_fallenOnceSubFracSubByFallComment =
    nestedData["home_fallenOnceSubFracSubByFallCommentVal"];
  const home_fallenOnceSubFracSubOstMed = getOptionsSelectedYesNo(
    nestedData["home_fallenOnceSubFracSubOstMedVal"],
  );
  const home_fallenOnceSubFracSubOstMedComment =
    nestedData["home_fallenOnceSubFracSubOstMedCommentVal"];

  const home_fallenTwiceSubWryFall = getOptionsSelectedYesNo(
    nestedData["home_fallenTwiceSubWryFallVal"],
  );
  const home_fallenTwiceSubWryFallComment =
    nestedData["home_fallenTwiceSubWryFallCommentVal"];
  const home_fallenTwiceSubWrisFall = getOptionsSelectedYesNo(
    nestedData["home_fallenTwiceSubWrisFallVal"],
  );
  const home_fallenTwiceSubWrisFallComment =
    nestedData["home_fallenTwiceSubWrisFallCommentVal"];
  const home_fallenTwiceSubFrac = getOptionsSelectedYesNo(
    nestedData["home_fallenTwiceSubFracVal"],
  );
  const home_fallenTwiceSubFracComment =
    nestedData["home_fallenTwiceSubFracCommentVal"];
  const home_fallenTwiceSubFracSubByFall = getOptionsSelectedYesNo(
    nestedData["home_fallenTwiceSubFracSubByFallVal"],
  );
  const home_fallenTwiceSubFracSubByFallComment =
    nestedData["home_fallenTwiceSubFracSubByFallCommentVal"];
  const home_fallenTwiceSubFracSubOstMed = getOptionsSelectedYesNo(
    nestedData["home_fallenTwiceSubFracSubOstMedVal"],
  );
  const home_fallenTwiceSubFracSubOstMedComment =
    nestedData["home_fallenTwiceSubFracSubOstMedCommentVal"];

  const home_fallenThreeSubWryFall = getOptionsSelectedYesNo(
    nestedData["home_fallenThreeSubWryFallVal"],
  );
  const home_fallenThreeSubWryFallComment =
    nestedData["home_fallenThreeSubWryFallCommentVal"];
  const home_fallenThreeSubWrisFall = getOptionsSelectedYesNo(
    nestedData["home_fallenThreeSubWrisFallVal"],
  );
  const home_fallenThreeSubWrisFallComment =
    nestedData["home_fallenThreeSubWrisFallCommentVal"];
  const home_fallenThreeSubFrac = getOptionsSelectedYesNo(
    nestedData["home_fallenThreeSubFracVal"],
  );
  const home_fallenThreeSubFracComment =
    nestedData["home_fallenThreeSubFracCommentVal"];
  const home_fallenThreeSubFracSubByFall = getOptionsSelectedYesNo(
    nestedData["home_fallenThreeSubFracSubByFallVal"],
  );
  const home_fallenThreeSubFracSubByFallComment =
    nestedData["home_fallenThreeSubFracSubByFallCommentVal"];
  const home_fallenThreeSubFracSubOstMed = getOptionsSelectedYesNo(
    nestedData["home_fallenThreeSubFracSubOstMedVal"],
  );
  const home_fallenThreeSubFracSubOstMedComment =
    nestedData["home_fallenThreeSubFracSubOstMedCommentVal"];

  const home_fallenMoreThreeSubWryFall = getOptionsSelectedYesNo(
    nestedData["home_fallenMoreThreeSubWryFallVal"],
  );
  const home_fallenMoreThreeSubWryFallComment =
    nestedData["home_fallenMoreThreeSubWryFallCommentVal"];
  const home_fallenMoreThreeSubWrisFall = getOptionsSelectedYesNo(
    nestedData["home_fallenMoreThreeSubWrisFallVal"],
  );
  const home_fallenMoreThreeSubWrisFallComment =
    nestedData["home_fallenMoreThreeSubWrisFallCommentVal"];
  const home_fallenMoreThreeSubFrac = getOptionsSelectedYesNo(
    nestedData["home_fallenMoreThreeSubFracVal"],
  );
  const home_fallenMoreThreeSubFracComment =
    nestedData["home_fallenMoreThreeSubFracCommentVal"];
  const home_fallenMoreThreeSubFracSubByFall = getOptionsSelectedYesNo(
    nestedData["home_fallenMoreThreeSubFracSubByFallVal"],
  );
  const home_fallenMoreThreeSubFracSubByFallComment =
    nestedData["home_fallenMoreThreeSubFracSubByFallCommentVal"];
  const home_fallenMoreThreeSubFracSubOstMed = getOptionsSelectedYesNo(
    nestedData["home_fallenMoreThreeSubFracSubOstMedVal"],
  );
  const home_fallenMoreThreeSubFracSubOstMedComment =
    nestedData["home_fallenMoreThreeSubFracSubOstMedCommentVal"];

  const home_memberGaitAbnormalSubDesc =
    nestedData["home_memberGaitAbnormalSubDesc"];

  const home_obstInHouseComment = nestedData["home_obstInHouseCommentVal"];
  const home_elecCords = getOptionsSelectedYesNo(
    nestedData["home_elecCordsVal"],
  );
  const home_elecCordsComment = nestedData["home_elecCordsCommentVal"];
  // const home_noMats = getOptionsSelectedYesNo(nestedData["home_noMatsVal"]);
  const home_noMats = nestedData["home_noMatsVal"] === "1" ? ["slipmats"] : "";
  const home_noMatsComment = nestedData["home_noMatsCommentVal"];
  const home_adeLight =
    nestedData["home_adeLightVal"] === "1" ? ["adequateLighting"] : "";

  // const home_adeLight = getOptionsSelectedYesNo(nestedData["home_adeLightVal"]);
  const home_adeLightComment = nestedData["home_adeLightCommentVal"];
  const home_handRail = getOptionsSelectedYesNo(nestedData["home_handRailVal"]);
  const home_handRailComment = nestedData["home_handRailCommentVal"];
  const home_hotWater = getOptionsSelectedYesNo(nestedData["home_hotWaterVal"]);
  const home_hotWaterComment = nestedData["home_hotWaterCommentVal"];
  // const home_smokeDet = getOptionsSelectedYesNo(nestedData["home_smokeDetVal"]);
  const home_smokeDet =
    nestedData["home_smokeDetVal"] === "1" ? ["smokeDetectors"] : "";
  const home_smokeDetComment = nestedData["home_smokeDetCommentVal"];
  const home_co2Det = getOptionsSelectedYesNo(nestedData["home_co2DetVal"]);
  const home_co2DetComment = nestedData["home_co2DetCommentVal"];
  const home_escRoute = getOptionsSelectedYesNo(nestedData["home_escRouteVal"]);
  const home_escRouteComment = nestedData["home_escRouteCommentVal"];

  const home_wishToChange = nestedData["home_wishToChangeVal"];
  const home_wishToChangeComment = nestedData["home_wishToChangeCommentVal"];

  const home_qualityOfLife = nestedData["home_qualityOfLifeVal"];
  const home_qualityOfLifeComment = nestedData["home_qualityOfLifeCommentVal"];

  const home_abusedBySomeone = getOptionsSelectedYesNo(
    nestedData["home_abusedBySomeoneVal"],
  );
  const home_abusedBySomeoneComment =
    nestedData["home_abusedBySomeoneCommentVal"];
  const home_feelingLikeHarming = getOptionsSelectedYesNo(
    nestedData["home_feelingLikeHarmingVal"],
  );
  const home_feelingLikeHarmingComment =
    nestedData["home_feelingLikeHarmingCommentVal"];
  const home_feelingLikeHarmingSubAnsWho =
    nestedData["home_feelingLikeHarmingSubAnsWhoVal"];
  const home_feelingLikeHarmingSubAnsAt =
    nestedData["home_feelingLikeHarmingSubAnsAtVal"];
  const home_feelingLikeHarmingSubAnsSafe =
    nestedData["home_feelingLikeHarmingSubAnsSafeVal"];
  const home_feelingLikeHarmingSubAnsAssist =
    nestedData["home_feelingLikeHarmingSubAnsAssistVal"];

  const home_afraidOfAnyOne = getOptionsSelectedYesNo(
    nestedData["home_afraidOfAnyOneVal"],
  );
  const home_afraidOfAnyOneComment =
    nestedData["home_afraidOfAnyOneCommentVal"];
  const home_afraidOfAnyOneSubAnsWho =
    nestedData["home_afraidOfAnyOneSubAnsWhoVal"];
  const home_afraidOfAnyOneSubAnsAt =
    nestedData["home_afraidOfAnyOneSubAnsAtVal"];
  const home_afraidOfAnyOneSubAnsSafe =
    nestedData["home_afraidOfAnyOneSubAnsSafeVal"];
  const home_afraidOfAnyOneSubAnsAssist =
    nestedData["home_afraidOfAnyOneSubAnsAssistVal"];

  flattenData = {
    ...flattenData,
    home_fallenComment: home_fallenComment || "",
    home_fallenOnceSubWryFall: home_fallenOnceSubWryFall || "",
    home_fallenOnceSubWryFallComment: home_fallenOnceSubWryFallComment || "",
    home_fallenOnceSubWrisFall: home_fallenOnceSubWrisFall || "",
    home_fallenOnceSubWrisFallComment: home_fallenOnceSubWrisFallComment || "",
    home_fallenOnceSubFrac: home_fallenOnceSubFrac || "",
    home_fallenOnceSubFracComment: home_fallenOnceSubFracComment || "",
    home_fallenOnceSubFracSubByFall: home_fallenOnceSubFracSubByFall || "",
    home_fallenOnceSubFracSubByFallComment:
      home_fallenOnceSubFracSubByFallComment || "",
    home_fallenOnceSubFracSubOstMed: home_fallenOnceSubFracSubOstMed || "",
    home_fallenOnceSubFracSubOstMedComment:
      home_fallenOnceSubFracSubOstMedComment || "",
    home_fallenTwiceSubWryFall: home_fallenTwiceSubWryFall || "",
    home_fallenTwiceSubWryFallComment: home_fallenTwiceSubWryFallComment || "",
    home_fallenTwiceSubWrisFall: home_fallenTwiceSubWrisFall || "",
    home_fallenTwiceSubWrisFallComment:
      home_fallenTwiceSubWrisFallComment || "",
    home_fallenTwiceSubFrac: home_fallenTwiceSubFrac || "",
    home_fallenTwiceSubFracComment: home_fallenTwiceSubFracComment || "",
    home_fallenTwiceSubFracSubByFall: home_fallenTwiceSubFracSubByFall || "",
    home_fallenTwiceSubFracSubByFallComment:
      home_fallenTwiceSubFracSubByFallComment || "",
    home_fallenTwiceSubFracSubOstMed: home_fallenTwiceSubFracSubOstMed || "",
    home_fallenTwiceSubFracSubOstMedComment:
      home_fallenTwiceSubFracSubOstMedComment || "",
    home_fallenThreeSubWryFall: home_fallenThreeSubWryFall || "",
    home_fallenThreeSubWryFallComment: home_fallenThreeSubWryFallComment || "",
    home_fallenThreeSubWrisFall: home_fallenThreeSubWrisFall || "",
    home_fallenThreeSubWrisFallComment:
      home_fallenThreeSubWrisFallComment || "",
    home_fallenThreeSubFrac: home_fallenThreeSubFrac || "",
    home_fallenThreeSubFracComment: home_fallenThreeSubFracComment || "",
    home_fallenThreeSubFracSubByFall: home_fallenThreeSubFracSubByFall || "",
    home_fallenThreeSubFracSubByFallComment:
      home_fallenThreeSubFracSubByFallComment || "",
    home_fallenThreeSubFracSubOstMed: home_fallenThreeSubFracSubOstMed || "",
    home_fallenThreeSubFracSubOstMedComment:
      home_fallenThreeSubFracSubOstMedComment || "",
    home_fallenMoreThreeSubWryFall: home_fallenMoreThreeSubWryFall || "",
    home_fallenMoreThreeSubWryFallComment:
      home_fallenMoreThreeSubWryFallComment || "",
    home_fallenMoreThreeSubWrisFall: home_fallenMoreThreeSubWrisFall || "",
    home_fallenMoreThreeSubWrisFallComment:
      home_fallenMoreThreeSubWrisFallComment || "",
    home_memberGaitAbnormalSubDesc: home_memberGaitAbnormalSubDesc || "",
    home_fallenMoreThreeSubFrac: home_fallenMoreThreeSubFrac || "",
    home_fallenMoreThreeSubFracComment:
      home_fallenMoreThreeSubFracComment || "",
    home_fallenMoreThreeSubFracSubByFall:
      home_fallenMoreThreeSubFracSubByFall || "",
    home_fallenMoreThreeSubFracSubByFallComment:
      home_fallenMoreThreeSubFracSubByFallComment || "",
    home_fallenMoreThreeSubFracSubOstMed:
      home_fallenMoreThreeSubFracSubOstMed || "",
    home_fallenMoreThreeSubFracSubOstMedComment:
      home_fallenMoreThreeSubFracSubOstMedComment || "",
    home_obstInHouseComment: home_obstInHouseComment || "",
    home_elecCords: home_elecCords || "",
    home_elecCordsComment: home_elecCordsComment || "",
    home_noMats: home_noMats || "",
    home_noMatsComment: home_noMatsComment || "",
    home_adeLight: home_adeLight || "",
    home_adeLightComment: home_adeLightComment || "",
    home_handRail: home_handRail || "",
    home_handRailComment: home_handRailComment || "",
    home_hotWater: home_hotWater || "",
    home_hotWaterComment: home_hotWaterComment || "",
    home_smokeDet: home_smokeDet || "",
    home_smokeDetComment: home_smokeDetComment || "",
    home_co2Det: home_co2Det || "",
    home_co2DetComment: home_co2DetComment || "",
    home_escRoute: home_escRoute || "",
    home_escRouteComment: home_escRouteComment || "",
    home_wishToChange: home_wishToChange || "",
    home_wishToChangeComment: home_wishToChangeComment || "",
    home_qualityOfLife: home_qualityOfLife || "",
    home_qualityOfLifeComment: home_qualityOfLifeComment || "",
    home_abusedBySomeone: home_abusedBySomeone || "",
    home_abusedBySomeoneComment: home_abusedBySomeoneComment || "",
    home_feelingLikeHarming: home_feelingLikeHarming || "",
    home_feelingLikeHarmingComment: home_feelingLikeHarmingComment || "",
    home_feelingLikeHarmingSubAnsWho: home_feelingLikeHarmingSubAnsWho || "",
    home_feelingLikeHarmingSubAnsAt: home_feelingLikeHarmingSubAnsAt || "",
    home_feelingLikeHarmingSubAnsSafe: home_feelingLikeHarmingSubAnsSafe || "",
    home_feelingLikeHarmingSubAnsAssist:
      home_feelingLikeHarmingSubAnsAssist || "",
    home_afraidOfAnyOne: home_afraidOfAnyOne || "",
    home_afraidOfAnyOneComment: home_afraidOfAnyOneComment || "",
    home_afraidOfAnyOneSubAnsWho: home_afraidOfAnyOneSubAnsWho || "",
    home_afraidOfAnyOneSubAnsAt: home_afraidOfAnyOneSubAnsAt || "",
    home_afraidOfAnyOneSubAnsSafe: home_afraidOfAnyOneSubAnsSafe || "",
    home_afraidOfAnyOneSubAnsAssist: home_afraidOfAnyOneSubAnsAssist || "",
  };

  if (year === "2023") {
    const home_obstInHouse = getOptionsSelectedYesNo(
      nestedData["home_obstInHouseVal"],
    );
    flattenData = {
      ...flattenData,
      home_obstInHouse,
    };
  } else if (year === "2024") {
    const home_obstInHouse =
      nestedData["home_obstInHouseVal"] === "1" ? ["fallHazards"] : "";
    const home_timedUpGoNotApplicable =
      nestedData["home_timedUpGoNotApplicable"] === "1"
        ? ["notApplicable"]
        : "";

    const home_timedUpGoNotApplicableComment =
      nestedData["home_timedUpGoNotApplicableComment"];
    const home_timeInSeconds = nestedData["home_timeInSeconds"];
    const home_memberGaitComment = nestedData["home_memberGaitComment"];
    const home_preventingComment = nestedData["home_preventingComment"];

    const home_fallRisk =
      nestedData["home_fallRisk"] === "1" ? ["fallRisk"] : "";
    const home_fallRiskComment = nestedData["home_fallRiskComment"];
    const home_planFurther =
      nestedData["home_planFurther"] === "1" ? ["planFurther"] : "";
    const home_planFurtherComment = nestedData["home_planFurtherComment"];

    // const home_eventOfEmergency = getOptionsSelectedYesNo(
    //   nestedData["home_eventOfEmergency"],
    // );
    const home_eventOfEmergency =
      nestedData["home_eventOfEmergency"] === "1" ? ["emergency"] : "";
    const home_eventOfEmergencyComment =
      nestedData["home_eventOfEmergencyComment"];

    const home_memberEducated =
      nestedData["home_memberEducated"] === "1" ? ["memberEducated"] : "";
    const home_memberEducatedComment = nestedData["home_memberEducatedComment"];

    const home_improveLifeQuality = nestedData["home_improveLifeQuality"];

    const home_improveLifeQualityComment =
      nestedData["home_improveLifeQualityComment"];

    const home_improveLifeQualitySelect =
      nestedData["home_improveLifeQualitySelect"];

    const home_improveLifeQualitySelectComment =
      nestedData["home_improveLifeQualitySelectComment"];
    const home_directionsComment = nestedData["home_directionsComment"];

    flattenData = {
      ...flattenData,
      home_timedUpGoNotApplicable,
      home_timedUpGoNotApplicableComment,
      home_timeInSeconds,
      home_memberGaitComment,
      home_preventingComment,
      home_fallRisk,
      home_fallRiskComment,
      home_planFurther,
      home_planFurtherComment,
      home_eventOfEmergency,
      home_eventOfEmergencyComment,
      home_memberEducated,
      home_memberEducatedComment,
      home_improveLifeQuality,
      home_improveLifeQualityComment,
      home_improveLifeQualitySelect,
      home_improveLifeQualitySelectComment,
      home_directionsComment,
      home_obstInHouse,
    };
  }

  return flattenData;
};

export const setHomeSafetyData = (formData, year) => {
  let flattenData = {};
  // let flatmap = [];

  // homeSafety_flatmap.forEach((quesData) => {
  //   let yearList = quesData?.[2] || [];
  //   if (yearList.includes(year)) {
  //     flatmap.push(quesData);
  //   }
  // });

  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });
  let payloadData = {};

  const home_fallenCommentVal = formData["home_fallenComment"];
  const home_fallenOnceSubWryFallVal = setOptionsSelectedYesNo(
    formData["home_fallenOnceSubWryFall"],
  );
  const home_fallenOnceSubWryFallCommentVal =
    formData["home_fallenOnceSubWryFallComment"];
  const home_fallenOnceSubWrisFallVal = setOptionsSelectedYesNo(
    formData["home_fallenOnceSubWrisFall"],
  );
  const home_fallenOnceSubWrisFallCommentVal =
    formData["home_fallenOnceSubWrisFallComment"];
  const home_fallenOnceSubFracVal = setOptionsSelectedYesNo(
    formData["home_fallenOnceSubFrac"],
  );
  const home_fallenOnceSubFracCommentVal =
    formData["home_fallenOnceSubFracComment"];
  const home_fallenOnceSubFracSubByFallVal = setOptionsSelectedYesNo(
    formData["home_fallenOnceSubFracSubByFall"],
  );
  const home_fallenOnceSubFracSubByFallCommentVal =
    formData["home_fallenOnceSubFracSubByFallComment"];
  const home_fallenOnceSubFracSubOstMedVal = setOptionsSelectedYesNo(
    formData["home_fallenOnceSubFracSubOstMed"],
  );
  const home_fallenOnceSubFracSubOstMedCommentVal =
    formData["home_fallenOnceSubFracSubOstMedComment"];

  const home_fallenTwiceSubWryFallVal = setOptionsSelectedYesNo(
    formData["home_fallenTwiceSubWryFall"],
  );
  const home_fallenTwiceSubWryFallCommentVal =
    formData["home_fallenTwiceSubWryFallComment"];
  const home_fallenTwiceSubWrisFallVal = setOptionsSelectedYesNo(
    formData["home_fallenTwiceSubWrisFall"],
  );
  const home_fallenTwiceSubWrisFallCommentVal =
    formData["home_fallenTwiceSubWrisFallComment"];
  const home_fallenTwiceSubFracVal = setOptionsSelectedYesNo(
    formData["home_fallenTwiceSubFrac"],
  );
  const home_fallenTwiceSubFracCommentVal =
    formData["home_fallenTwiceSubFracComment"];
  const home_fallenTwiceSubFracSubByFallVal = setOptionsSelectedYesNo(
    formData["home_fallenTwiceSubFracSubByFall"],
  );
  const home_fallenTwiceSubFracSubByFallCommentVal =
    formData["home_fallenTwiceSubFracSubByFallComment"];
  const home_fallenTwiceSubFracSubOstMedVal = setOptionsSelectedYesNo(
    formData["home_fallenTwiceSubFracSubOstMed"],
  );
  const home_fallenTwiceSubFracSubOstMedCommentVal =
    formData["home_fallenTwiceSubFracSubOstMedComment"];

  const home_fallenThreeSubWryFallVal = setOptionsSelectedYesNo(
    formData["home_fallenThreeSubWryFall"],
  );
  const home_fallenThreeSubWryFallCommentVal =
    formData["home_fallenThreeSubWryFallComment"];
  const home_fallenThreeSubWrisFallVal = setOptionsSelectedYesNo(
    formData["home_fallenThreeSubWrisFall"],
  );
  const home_fallenThreeSubWrisFallCommentVal =
    formData["home_fallenThreeSubWrisFallComment"];
  const home_fallenThreeSubFracVal = setOptionsSelectedYesNo(
    formData["home_fallenThreeSubFrac"],
  );
  const home_fallenThreeSubFracCommentVal =
    formData["home_fallenThreeSubFracComment"];
  const home_fallenThreeSubFracSubByFallVal = setOptionsSelectedYesNo(
    formData["home_fallenThreeSubFracSubByFall"],
  );
  const home_fallenThreeSubFracSubByFallCommentVal =
    formData["home_fallenThreeSubFracSubByFallComment"];
  const home_fallenThreeSubFracSubOstMedVal = setOptionsSelectedYesNo(
    formData["home_fallenThreeSubFracSubOstMed"],
  );
  const home_fallenThreeSubFracSubOstMedCommentVal =
    formData["home_fallenThreeSubFracSubOstMedComment"];

  const home_fallenMoreThreeSubWryFallVal = setOptionsSelectedYesNo(
    formData["home_fallenMoreThreeSubWryFall"],
  );
  const home_fallenMoreThreeSubWryFallCommentVal =
    formData["home_fallenMoreThreeSubWryFallComment"];
  const home_fallenMoreThreeSubWrisFallVal = setOptionsSelectedYesNo(
    formData["home_fallenMoreThreeSubWrisFall"],
  );
  const home_fallenMoreThreeSubWrisFallCommentVal =
    formData["home_fallenMoreThreeSubWrisFallComment"];

  const home_memberGaitAbnormalSubDesc =
    formData["home_memberGaitAbnormalSubDesc"];
  const home_fallenMoreThreeSubFracVal = setOptionsSelectedYesNo(
    formData["home_fallenMoreThreeSubFrac"],
  );
  const home_fallenMoreThreeSubFracCommentVal =
    formData["home_fallenMoreThreeSubFracComment"];
  const home_fallenMoreThreeSubFracSubByFallVal = setOptionsSelectedYesNo(
    formData["home_fallenMoreThreeSubFracSubByFall"],
  );
  const home_fallenMoreThreeSubFracSubByFallCommentVal =
    formData["home_fallenMoreThreeSubFracSubByFallComment"];
  const home_fallenMoreThreeSubFracSubOstMedVal = setOptionsSelectedYesNo(
    formData["home_fallenMoreThreeSubFracSubOstMed"],
  );
  const home_fallenMoreThreeSubFracSubOstMedCommentVal =
    formData["home_fallenMoreThreeSubFracSubOstMedComment"];

  const home_obstInHouseCommentVal = formData["home_obstInHouseComment"];
  const home_elecCordsVal = setOptionsSelectedYesNo(formData["home_elecCords"]);
  const home_elecCordsCommentVal = formData["home_elecCordsComment"];
  const home_noMatsSelected = formData["home_noMats"];
  const home_noMatsVal = home_noMatsSelected?.includes("slipmats") ? "1" : "";
  // const home_noMatsVal = setOptionsSelectedYesNo(formData["home_noMats"]);
  const home_noMatsCommentVal = formData["home_noMatsComment"];
  // const home_adeLightVal = setOptionsSelectedYesNo(formData["home_adeLight"]);
  const home_adeLightSelected = formData["home_adeLight"];
  const home_adeLightVal = home_adeLightSelected?.includes("adequateLighting")
    ? "1"
    : "";
  const home_adeLightCommentVal = formData["home_adeLightComment"];
  const home_handRailVal = setOptionsSelectedYesNo(formData["home_handRail"]);
  const home_handRailCommentVal = formData["home_handRailComment"];
  const home_hotWaterVal = setOptionsSelectedYesNo(formData["home_hotWater"]);
  const home_hotWaterCommentVal = formData["home_hotWaterComment"];
  // const home_smokeDetVal = setOptionsSelectedYesNo(formData["home_smokeDet"]);
  const home_smokeDetSelected = formData["home_smokeDet"];
  const home_smokeDetVal = home_smokeDetSelected?.includes("smokeDetectors")
    ? "1"
    : "";
  const home_smokeDetCommentVal = formData["home_smokeDetComment"];
  const home_co2DetVal = setOptionsSelectedYesNo(formData["home_co2Det"]);
  const home_co2DetCommentVal = formData["home_co2DetComment"];
  const home_escRouteVal = setOptionsSelectedYesNo(formData["home_escRoute"]);
  const home_escRouteCommentVal = formData["home_escRouteComment"];

  const home_wishToChangeVal = formData["home_wishToChange"];
  const home_wishToChangeCommentVal = formData["home_wishToChangeComment"];
  const home_qualityOfLifeVal = formData["home_qualityOfLife"];
  const home_qualityOfLifeCommentVal = formData["home_qualityOfLifeComment"];
  const home_abusedBySomeoneVal = setOptionsSelectedYesNo(
    formData["home_abusedBySomeone"],
  );
  const home_abusedBySomeoneCommentVal =
    formData["home_abusedBySomeoneComment"];
  const home_feelingLikeHarmingVal = setOptionsSelectedYesNo(
    formData["home_feelingLikeHarming"],
  );
  const home_feelingLikeHarmingCommentVal =
    formData["home_feelingLikeHarmingComment"];
  const home_feelingLikeHarmingSubAnsWhoVal =
    formData["home_feelingLikeHarmingSubAnsWho"];
  const home_feelingLikeHarmingSubAnsAtVal =
    formData["home_feelingLikeHarmingSubAnsAt"];
  const home_feelingLikeHarmingSubAnsSafeVal =
    formData["home_feelingLikeHarmingSubAnsSafe"];
  const home_feelingLikeHarmingSubAnsAssistVal =
    formData["home_feelingLikeHarmingSubAnsAssist"];

  const home_afraidOfAnyOneVal = setOptionsSelectedYesNo(
    formData["home_afraidOfAnyOne"],
  );
  const home_afraidOfAnyOneCommentVal = formData["home_afraidOfAnyOneComment"];
  const home_afraidOfAnyOneSubAnsWhoVal =
    formData["home_afraidOfAnyOneSubAnsWho"];
  const home_afraidOfAnyOneSubAnsAtVal =
    formData["home_afraidOfAnyOneSubAnsAt"];
  const home_afraidOfAnyOneSubAnsSafeVal =
    formData["home_afraidOfAnyOneSubAnsSafe"];
  const home_afraidOfAnyOneSubAnsAssistVal =
    formData["home_afraidOfAnyOneSubAnsAssist"];

  flattenData = {
    ...flattenData,
    home_fallenCommentVal,
    home_fallenOnceSubWryFallVal,
    home_fallenOnceSubWryFallCommentVal,
    home_fallenOnceSubWrisFallVal,
    home_fallenOnceSubWrisFallCommentVal,
    home_fallenOnceSubFracVal,
    home_fallenOnceSubFracCommentVal,
    home_fallenOnceSubFracSubByFallVal,
    home_fallenOnceSubFracSubByFallCommentVal,
    home_fallenOnceSubFracSubOstMedVal,
    home_fallenOnceSubFracSubOstMedCommentVal,
    home_fallenTwiceSubWryFallVal,
    home_fallenTwiceSubWryFallCommentVal,
    home_fallenTwiceSubWrisFallVal,
    home_fallenTwiceSubWrisFallCommentVal,
    home_fallenTwiceSubFracVal,
    home_fallenTwiceSubFracCommentVal,
    home_fallenTwiceSubFracSubByFallVal,
    home_fallenTwiceSubFracSubByFallCommentVal,
    home_fallenTwiceSubFracSubOstMedVal,
    home_fallenTwiceSubFracSubOstMedCommentVal,
    home_fallenThreeSubWryFallVal,
    home_fallenThreeSubWryFallCommentVal,
    home_fallenThreeSubWrisFallVal,
    home_fallenThreeSubWrisFallCommentVal,
    home_fallenThreeSubFracVal,
    home_fallenThreeSubFracCommentVal,
    home_fallenThreeSubFracSubByFallVal,
    home_fallenThreeSubFracSubByFallCommentVal,
    home_fallenThreeSubFracSubOstMedVal,
    home_fallenThreeSubFracSubOstMedCommentVal,
    home_fallenMoreThreeSubWryFallVal,
    home_fallenMoreThreeSubWryFallCommentVal,
    home_fallenMoreThreeSubWrisFallVal,
    home_fallenMoreThreeSubWrisFallCommentVal,
    home_memberGaitAbnormalSubDesc,
    home_fallenMoreThreeSubFracVal,
    home_fallenMoreThreeSubFracCommentVal,
    home_fallenMoreThreeSubFracSubByFallVal,
    home_fallenMoreThreeSubFracSubByFallCommentVal,
    home_fallenMoreThreeSubFracSubOstMedVal,
    home_fallenMoreThreeSubFracSubOstMedCommentVal,
    home_obstInHouseCommentVal,
    home_elecCordsVal,
    home_elecCordsCommentVal,
    home_noMatsVal,
    home_noMatsCommentVal,
    home_adeLightVal,
    home_adeLightCommentVal,
    home_handRailVal,
    home_handRailCommentVal,
    home_hotWaterVal,
    home_hotWaterCommentVal,
    home_smokeDetVal,
    home_smokeDetCommentVal,
    home_co2DetVal,
    home_co2DetCommentVal,
    home_escRouteVal,
    home_escRouteCommentVal,
    home_wishToChangeVal,
    home_wishToChangeCommentVal,
    home_qualityOfLifeVal,
    home_qualityOfLifeCommentVal,
    home_abusedBySomeoneVal,
    home_abusedBySomeoneCommentVal,
    home_feelingLikeHarmingVal,
    home_feelingLikeHarmingCommentVal,
    home_feelingLikeHarmingSubAnsWhoVal,
    home_feelingLikeHarmingSubAnsAtVal,
    home_feelingLikeHarmingSubAnsSafeVal,
    home_feelingLikeHarmingSubAnsAssistVal,
    home_afraidOfAnyOneVal,
    home_afraidOfAnyOneCommentVal,
    home_afraidOfAnyOneSubAnsWhoVal,
    home_afraidOfAnyOneSubAnsAtVal,
    home_afraidOfAnyOneSubAnsSafeVal,
    home_afraidOfAnyOneSubAnsAssistVal,
  };
  if (year === "2023") {
    const home_obstInHouseVal = setOptionsSelectedYesNo(
      formData["home_obstInHouse"],
    );
    flattenData = {
      ...flattenData,
      home_obstInHouseVal,
    };
  } else if (year === "2024") {
    const home_obstInHouseSelected = formData["home_obstInHouse"];
    const home_obstInHouseVal = home_obstInHouseSelected?.includes(
      "fallHazards",
    )
      ? "1"
      : "";
    const home_timedUpGoNotApplicableSelected =
      formData["home_timedUpGoNotApplicable"];
    const home_timedUpGoNotApplicable =
      home_timedUpGoNotApplicableSelected?.includes("notApplicable") ? "1" : "";
    const home_timedUpGoNotApplicableComment =
      formData["home_timedUpGoNotApplicableComment"];
    const home_timeInSeconds = formData["home_timeInSeconds"];
    const home_memberGaitComment = formData["home_memberGaitComment"];
    const home_preventingComment = formData["home_preventingComment"];
    const home_fallRiskSelected = formData["home_fallRisk"];
    const home_planFurtherSelected = formData["home_planFurther"];
    const home_fallRisk = home_fallRiskSelected?.includes("fallRisk")
      ? "1"
      : "";
    const home_fallRiskComment = formData["home_fallRiskComment"];
    const home_planFurther = home_planFurtherSelected?.includes("planFurther")
      ? "1"
      : "";
    const home_planFurtherComment = formData["home_planFurtherComment"];
    // const home_eventOfEmergency = setOptionsSelectedYesNo(
    //   formData["home_eventOfEmergency"],
    // );
    const home_eventOfEmergencySelected = formData["home_eventOfEmergency"];
    const home_eventOfEmergency = home_eventOfEmergencySelected?.includes(
      "emergency",
    )
      ? "1"
      : "";
    const home_eventOfEmergencyComment =
      formData["home_eventOfEmergencyComment"];
    const home_memberEducatedSelected = formData["home_memberEducated"];
    const home_memberEducated = home_memberEducatedSelected?.includes(
      "memberEducated",
    )
      ? "1"
      : "";
    const home_memberEducatedComment = formData["home_memberEducatedComment"];
    const home_improveLifeQuality = formData["home_improveLifeQuality"];
    const home_improveLifeQualityComment =
      formData["home_improveLifeQualityComment"];
    const home_improveLifeQualitySelect =
      formData["home_improveLifeQualitySelect"];
    const home_improveLifeQualitySelectComment =
      formData["home_improveLifeQualitySelectComment"];
    const home_directionsComment = formData["home_directionsComment"];
    flattenData = {
      ...flattenData,
      home_timedUpGoNotApplicable,
      home_timedUpGoNotApplicableComment,
      home_timeInSeconds,
      home_memberGaitComment,
      home_preventingComment,
      home_fallRisk,
      home_fallRiskComment,
      home_planFurther,
      home_planFurtherComment,
      home_eventOfEmergency,
      home_eventOfEmergencyComment,
      home_memberEducated,
      home_memberEducatedComment,
      home_improveLifeQuality,
      home_improveLifeQualityComment,
      home_improveLifeQualitySelect,
      home_improveLifeQualitySelectComment,
      home_directionsComment,
      home_obstInHouseVal,
    };
  }

  let flatmap = [];

  homeSafety_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    // if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

export const getSubTree = (optionName, optionValue, year) => {
  let temp;
  let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  const tempIndex = lodash.findIndex(treeWrappers, function (optTree) {
    return optTree[0] === optionName;
  });

  const tempTreeNodes = treeWrappers?.[tempIndex]
    ? treeWrappers[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMappers[node] === optionValue) {
      temp = node;
    }
  });

  temp = temp && temp !== "" ? temp.substring(0, temp.length - 3) : "";
  temp = temp && temp !== "" ? `${temp}Sub` : "";

  return temp;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let treeKeys = Object.keys(formData);
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex)) {
      delete formData[node];
    }
  });
  return formData;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...homeSafety_optionsTreeWrappers2023];
  } else if (year === "2024") {
    temp = [...homeSafety_optionsTreeWrappers2024];
  }

  return temp;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...homeSafety_optionsTreeMappers2023 };
  } else if (year === "2024") {
    temp = { ...homeSafety_optionsTreeMappers2024 };
  }

  return temp;
};

export const getOptionsSelected = (optionObject, optionArray, year) => {
  let treeMappers = getTreeMapperByYear(year);
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable.map((variable) => {
    return treeMappers[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (optionsSelected, optionArray, year) => {
  let temp = {};
  let treeMappers = getTreeMapperByYear(year);
  optionArray.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMappers[option])
        ? "1"
        : "";
  });
  return temp;
};
