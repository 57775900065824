import axiosWrapper from "./axiosWrapper";

export const generateRTTReport = (data) =>
  axiosWrapper.post("/realTimeScripts/generate", {
    ...data,
  });

export const getRTTByAssessmentId = (id) =>
  axiosWrapper.get(`/realTimeScripts//view?assessmentId=${id}`);

export const updateRTTReport = (data) =>
  axiosWrapper.put("/realTimeScripts/update", {
    ...data,
  });
