import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  getExamReviewData,
  setExamReviewData,
} from "../components/Dashboard/MemberList/Fill_HRA/Sections/ExamReview/examReviewMapping";
import { getInputsDataByID } from "../components/Dashboard/MemberList/Fill_HRA/fillHraFormData";
import { useUserContext } from "./UserContext";
import { putApi } from "../utils/axiosApiWrapper";
import {
  getCovidScreeningData,
  setCovidScreeningData,
} from "../components/Dashboard/MemberList/Fill_HRA/Sections/CovidScreening/covidScreeningMapping";

// const sectionMapping = {
//   13: ["exam", "Exam review"],
// };

export const FillHraFormContext = createContext({
  demographicsFormData: null,
  setDemographicsFormData: () => null,
  previouslyFormData: null,
  setPreviouslyFormData: () => null,
  covidScreeningFormData: null,
  setCovidScreeningFormData: () => null,
  selfFormData: null,
  setSelfFormData: () => null,
  dailyLivingFormData: null,
  setDailyLivingFormData: () => null,
  medicalFormData: null,
  setMedicalFormData: () => null,
  familyFormData: null,
  setFamilyFormData: () => null,
  preventiveFormData: null,
  setPreventiveFormData: () => null,
  allergyFormData: null,
  setAllergyFormData: () => null,
  rosFormData: null,
  setROSFormData: () => null,
  painFormData: null,
  setPainFormData: () => null,
  vitalFormData: null,
  setVitalFormData: () => null,
  examReviewFormData: null,
  setExamReviewFormData: () => null,
  screeningsFormData: null,
  setScreeningsFormData: () => null,
  miniCogFormData: null,
  setMiniCogFormData: () => null,
  homeSafetyFormData: null,
  setHomeSafetyFormData: () => null,
  patientFormData: null,
  setPatientFormData: () => null,
  showComment: null,
  setShowComment: () => null,
  getSectionInputsData: () => null,
  saveSectionFormData: () => null,
  getReroute: () => null,
  getStateValue: () => null,
  setStateValue: () => null,
  getDisableFlag: () => null,
});

export const FillHraFormProvider = ({ children }) => {
  const { assessmentData, user } = useUserContext();

  const [fullAssessData, setFullAssessData] = useState({});
  const [demographicsFormData, setDemographicsFormData] = useState({});
  const [previouslyFormData, setPreviouslyFormData] = useState({});
  const [covidScreeningFormData, setCovidScreeningFormData] = useState({});
  const [selfFormData, setSelfFormData] = useState({});
  const [dailyLivingFormData, setDailyLivingFormData] = useState({});
  const [medicalFormData, setMedicalFormData] = useState({});
  const [familyFormData, setFamilyFormData] = useState({});
  const [preventiveFormData, setPreventiveFormData] = useState({});
  const [allergyFormData, setAllergyFormData] = useState({});
  const [rosFormData, setROSFormData] = useState({});
  const [painFormData, setPainFormData] = useState({});
  const [vitalFormData, setVitalFormData] = useState({});
  const [examReviewFormData, setExamReviewFormData] = useState({});
  const [screeningsFormData, setScreeningsFormData] = useState({});
  const [miniCogFormData, setMiniCogFormData] = useState({});
  const [homeSafetyFormData, setHomeSafetyFormData] = useState({});
  const [patientFormData, setPatientFormData] = useState({});
  const [showComment, setShowComment] = useState({});

  useEffect(() => {
    const tempData = assessmentData?.data?.[0]?.assessment?.[0] || [];
    setFullAssessData(tempData);
  }, []);

  useEffect(() => {
    getSectionDbData(13);
  }, [fullAssessData]);

  const getSectionInputsData = (sectionId) => {
    let temp = getInputsDataByID(sectionId);
    return temp[0] || {};
  };

  const getStateValue = (sectionId, key) => {
    if (sectionId === 13) {
      return examReviewFormData[key] || "";
    }
    if (sectionId === 16) {
      return homeSafetyFormData[key] || "";
    }
    if (sectionId === 3) {
      return covidScreeningFormData[key] || "";
    }
  };

  const setStateValue = (sectionId, key, value) => {
    if (sectionId === 13) {
      const updatedFormData = { ...examReviewFormData };
      updatedFormData[key] = value;

      setExamReviewFormData(updatedFormData);
    } else if (sectionId === 16) {
      const updatedFormData = { ...homeSafetyFormData };
      updatedFormData[key] = value;

      setHomeSafetyFormData(updatedFormData);
    } else if (sectionId === 3) {
      const updatedFormData = { ...covidScreeningFormData };
      updatedFormData[key] = value;

      setCovidScreeningFormData(updatedFormData);
    }
  };

  const getSectionDbData = (sectionId) => {
    if (sectionId === 13) {
      let tempData = fullAssessData?.["exam"]?.data || {};
      let sectionDBData =
        tempData && Object.keys(tempData).length > 0
          ? getExamReviewData(tempData)
          : {};
      setExamReviewFormData(sectionDBData);
    }
    if (sectionId === 3) {
      let tempData = fullAssessData?.["covid"]?.data || {};
      let sectionDBData =
        tempData && Object.keys(tempData).length > 0
          ? getCovidScreeningData(tempData)
          : {};
      setCovidScreeningFormData(sectionDBData);
    }
  };

  const getReroute = () => {
    let path;
    const roleId = sessionStorage.getItem("roleId");
    if (roleId === "5") {
      path = "/NurseDashboard/Nurselist";
    } else {
      path = "/AdminDashboard/MemberList";
    }
    return path;
  };

  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = fullAssessData._id;
      if (sectionId === 13) {
        let tempData = setExamReviewData(examReviewFormData);
        payload = {
          id: assessId,
          data: {
            name: "Exam Review",
            data: {
              ...tempData,
            },
          },
        };
      }
      if (sectionId === 3) {
        let tempData = setCovidScreeningData(covidScreeningFormData);
        payload = {
          id: assessId,
          data: {
            name: "Covid Screening",
            data: {
              ...tempData,
            },
          },
        };
      }

      const response = await putApi("/hra/assessment", payload);
      return response;
    } catch (error) {
      return;
    }
  };

  const getDisableFlag = () => {
    const userRoleId = user.roleId || sessionStorage.getItem("roleId");
    return !(
      userRoleId &&
      (userRoleId == 1 || userRoleId == 2 || userRoleId == 5)
    );
  };

  const value = {
    demographicsFormData,
    setDemographicsFormData,
    previouslyFormData,
    setPreviouslyFormData,
    covidScreeningFormData,
    setCovidScreeningFormData,
    selfFormData,
    setSelfFormData,
    dailyLivingFormData,
    setDailyLivingFormData,
    medicalFormData,
    setMedicalFormData,
    familyFormData,
    setFamilyFormData,
    preventiveFormData,
    setPreventiveFormData,
    allergyFormData,
    setAllergyFormData,
    rosFormData,
    setROSFormData,
    painFormData,
    setPainFormData,
    vitalFormData,
    setVitalFormData,
    examReviewFormData,
    setExamReviewFormData,
    screeningsFormData,
    setScreeningsFormData,
    miniCogFormData,
    setMiniCogFormData,
    homeSafetyFormData,
    setHomeSafetyFormData,
    patientFormData,
    setPatientFormData,
    showComment,
    setShowComment,
    getSectionInputsData,
    saveSectionFormData,
    getReroute,
    getStateValue,
    setStateValue,
    getDisableFlag,
  };

  return (
    <FillHraFormContext.Provider value={value}>
      {children}
    </FillHraFormContext.Provider>
  );
};

export const useFillHraContext = () => {
  return useContext(FillHraFormContext);
};

export default FillHraFormContext;
